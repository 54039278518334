import axios from "axios";
import { ConfigManager } from "../../config/ConfigManager";
import { store } from "../../store/rootReducer";
import { Logger } from "../../logging/logging";


export const getLadderData = async() => {
    const projectId =  ConfigManager.projectId
    try{
        const openToweriQUrl = ConfigManager.openToweriQUrl!;
        let baseUrl = new URL(`${openToweriQUrl}/v1.0/${projectId}/Site/Ladder`);
        const ladder_data :any= await axios.get(baseUrl.href, {
            headers:{
            Authorization :store.getState().auth.accessTokenStatePrivateAPI.accessToken as any,
          }
        });
        return ladder_data;
    }catch(e){
        Logger.error(`Error while calling Get Api for ladder in ${projectId}`,e)
    }
    return null;
}

export const putLadderApi = async (ladderId:string,data:{ladderId:string,coordinates:[{x:number,y:number,z:number}]}) => {
    const projectId =  ConfigManager.projectId
    try{
        const openToweriQUrl = ConfigManager.openToweriQUrl!;
        let baseUrl = new URL(`${openToweriQUrl}/v1.0/${projectId}/Site/Ladder/${ladderId}`);
        const res :any= await axios.put(baseUrl.href,JSON.stringify (data),{
                headers: {
                    Authorization:store.getState().auth.accessTokenStatePrivateAPI.accessToken as any,
                    "Content-Type": "application/json",
                },
            });
        return res;
    }catch(e){
        Logger.error(`Error while calling Put Api for ladder in ${projectId}`,e)
    }
    return null;
}

export const postSingleLadderApi =  async (data:{ladderId:string,coordinates:[{x:number,y:number,z:number}]}) => {
    const projectId =  ConfigManager.projectId
    try{
        const openToweriQUrl = ConfigManager.openToweriQUrl!;
        let baseUrl = new URL(`${openToweriQUrl}/v1.0/${projectId}/Site/Ladder`);
        const res :any= await axios.post(baseUrl.href, JSON.stringify(data),{
                headers: {
                    Authorization:store.getState().auth.accessTokenStatePrivateAPI.accessToken as any,
                    "Content-Type": "application/json",
                },
            });
        return res;
    }catch(e){
        Logger.error(`Error while calling Post Api for ladder in ${projectId}`,e)
    }
    return null;
}

export const postUpdateAllLadderData = async (data:{ladderId:string,coordinates:[{x:number,y:number,z:number}]}) => {
    const projectId =  ConfigManager.projectId
    try{
        const openToweriQUrl = ConfigManager.openToweriQUrl!;
        let baseUrl = new URL(`${openToweriQUrl}/v1.0/${projectId}/Site/Ladders`);
        const res :any= await axios.post(baseUrl.href, JSON.stringify(data),{
                headers: {
                    Authorization:store.getState().auth.accessTokenStatePrivateAPI.accessToken as any,
                    "Content-Type": "application/json",
                },
            });
        return res;
    }catch(e){
        Logger.error(`Error while calling Post Api for ladder in ${projectId}`,e)
    }
    return null;
}
