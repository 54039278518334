import { Cartographic } from "@itwin/core-common";
import { BeButton, BeButtonEvent, DecorateContext, Decorator, EventHandled, HitDetail, IModelApp, IModelConnection, Marker, MarkerFillStyle, NotifyMessageDetails, OutputMessagePriority, OutputMessageType } from "@itwin/core-frontend";
import { Point2d, Point3d } from "@itwin/core-geometry";
// import App from "../../components/App";
import { StagePanelLocation, SyncUiEventDispatcher, UiFramework } from "@itwin/appui-react";
import { WidgetState } from "@itwin/appui-react";
import { DefectData, DefectsTable } from "../../components/Tables/DefectsTable";

import "./PinTagElements.scss";
import { Id64String } from "@itwin/core-bentley";
import axios from "axios";
import { Logger } from "../../api/logging";
import { ConfigManager } from "../../../config/ConfigManager";
import { DTVActions } from "../../../store/Actions";
import { store } from "../../../store/rootReducer";
import { getAccessToken } from "../../../services/auth/AuthContext";
import { PropertyTableType } from "../../../store/States";
import { addToBuilt3DObjectIdsMap } from "../../../store/detectedData/apiDataActionTypes";
import DefectsClient from "../../api/defects";
import PinTagClient from "../../api/pinTagClient";
import { DigitalTwinViewerApp } from "../../../api/DigitalTwinViewerApp";
import { SyncUiEventIds, licenseProductIds } from "../../../store/redux-types";
import { deductLicense } from "../../components/LicenseWorkflow";
import { AnnotationDecorator } from "./AnnotationsDecorator";

//As this decorator is doing more than show defect markers,
//this needs to be renamed to Pin Annotation Decorator 
//and change naming convention for all variables (pending)
export class DefectsDecorator implements Decorator {

    //-----------------------------------------------------
    private static _defectData: any;                              // Is named defect data , but holds the data for both defects and Pin Annotations based on use and context.    
    public useCachedDecorations: true | undefined = true;           
    public static _markers: Marker[] = [];                        // Holds all the current markers created and instanciated
    public static selectedMarkerJson: any | undefined;            // The marker object that has been click on and selected.
    public publicData: any[] = [];
    public objectIdMap: Map<Id64String, string> = new Map<string, Id64String>();
    public static lastCreatedPinDefect: Marker | undefined;       // holds the refrence to the pin that was newly created.
    public static createdDefectStore: Marker[] = [];              // this stores all the pins created by user. This is used to clear previsous stores from view when saving is done.
    private static currentHoverElementInfo: any | undefined;      // Holds the data for the element and mousueposition of the lement the mouse is over.
   //-----------------------------------------------------
    constructor() {
       //this adds a listner that will, on mouse move will return the current element its is hovering over.
       //This is needed for the On MarkerHover div popup feature.
        document.addEventListener('mousemove', function (e) {
        if (e != null && e.target != null) {//test
            DefectsDecorator.currentHoverElementInfo = { element: e.target as HTMLInputElement, posX: e.clientX, posY: e.clientY }
            let ele = document.getElementsByClassName(DefectsDecorator.currentHoverElementInfo.element.parentElement?.className) as HTMLSelectElement;
            DefectsDecorator.currentHoverElementInfo = { element: e.target as HTMLInputElement, parent: ele, posX: e.clientX, posY: e.clientY }
        }
     },false);
   }
  //-----------------------------------------------------
  public decorate(context: DecorateContext): void
  {
          //This will capture the current Element that the mouse is hovering over as well as the current positon of the mouse.
    if (DefectsDecorator._markers.length === 0) {
        this.loadPinDefectMarkers(/*context,*/ DefectsDecorator._defectData);
        IModelApp.viewManager.selectedView?.invalidateCachedDecorations(this);
    }
  
    DefectsDecorator._markers?.forEach((marker) => {
      marker.addDecoration(context);
    });
  }
  //-----------------------------------------------------
    /*
     * Delete and deallocate all entites   
    */
    public terminate() {
        this.clearClassElements("pin-tag-popup");//clear the popu div as well.
        DefectsDecorator._markers.length = 0;
        DefectsDecorator._defectData = undefined;
        IModelApp.viewManager.invalidateDecorationsAllViews();
    }

    //-----------------------------------------------------
    /*
     * InvalidateCache
    */
    public refreshInvalidateCache() {
        IModelApp.viewManager.invalidateCachedDecorationsAllViews(this);
        IModelApp.viewManager.invalidateDecorationsAllViews();
    }
    //-----------------------------------------------------
    /*
     * Remove Object from the array of markers by marker object.
     * Takes a Marker object as param and comapres position to 
     * remove relevant marker.
     */ 
    public removeDefectMarkerObject(object: Marker) {
        if (object != undefined) {
            if (DefectsDecorator._markers.length > 0) {
                for (let i = 0; i < DefectsDecorator._markers.length; i++) {
                    if (DefectsDecorator._markers[i].position == object.position) {
                        DefectsDecorator._markers.splice(i, 1);
                    }
                }
            }
        }
        IModelApp.viewManager.selectedView?.invalidateCachedDecorations(this);
    }
    //----------------------------------------------------
    /*
     * To make them appear unselected
     */
    public static resetMarkersImage() {
        if (DefectsDecorator._markers != undefined && DefectsDecorator._markers.length > 0) {
            DefectsDecorator._markers.forEach((e) => {
                const im = e.image as HTMLImageElement;
                if (im.src.includes("defect-camera-icon-select")) {
                    e.setImageUrl("image/defect-camera-icon2.png");
                } else if (im.src.includes("image/location-coloured")) {
                    e.setImageUrl("image/location.SVG");
                }
            });
        }
    }
    //-----------------------------------------------------
    /*
     * Creates a marker on Point, .
     */ 
    public async addMarkerOnPoint(point: Point3d) {
        const marker = new Marker(point, new Point2d(22, 22));
      marker.position = point;
      marker.setImageUrl("image/location.SVG");

        // As per the structure for markers 
        // this allows for mouse event to triger on interaction with the same marker refrence
        marker.onMouseLeave = () => {
        };
        marker.onMouseEnter = (_ev: BeButtonEvent) => {
        };
        marker.onMouseButton = (_ev: BeButtonEvent) => {
            return true;
        };

        DefectsDecorator.lastCreatedPinDefect = marker;
        DefectsDecorator.createdDefectStore.push(marker);
        DefectsDecorator._markers.push(marker);
        const vp = IModelApp.viewManager.selectedView;
        if (vp === undefined) return;
        const nextId = vp.iModel.transientIds.getNext();
        // this.objectIdMap.set(`defectMarker#${DefectsDecorator._markers.length-1}`, nextId);
        IModelApp.viewManager.selectedView?.invalidateCachedDecorations(this);
    }

    //----------------------------------------------------------------------------------------
    /*
     * All elements under the class name psc will be cleared
     * This helps refresh the page with new elements witouth appending
     * the page with duplicates.
     */
    //-------------------------------------------------------------------------------------
    /*
     * Remove Html element base on Id 
     */
    private async clearElement (id : any) {
        var elem = document.getElementById(id);
        elem?.parentNode?.removeChild(elem);
    }
    //-------------------------------------------------------------------------------------
    /*
     * Remove Html element base on Class name 
     * matches the id to delete , so make sure the 
     * element has an id assigned to it.
     */
    private async clearClassElements (className: string) {
        let elements = document.getElementsByClassName(className);
        let idArray: string[] = [];
        if (elements) {
            for (let i = 0; i < elements.length; i++) {
                idArray.push(elements[i].id.toString());
            }
            for (let j = 0; j < idArray.length; j++) {
                this.clearElement(idArray[j]);
            }
        }
    }
  //-----------------------------------------------------  
  /*
  * Takes the Data from the API as parameter and loads the Defect Markers 
  * when Display Detected Defects button is pressed.
  */ 
  public async loadPinDefectMarkers(data : any[]): Promise<void> {
          DefectsDecorator._defectData = data;
      let json;
      if (data ) {
         json = data;
      }
    if (!json) {
      return;
    }
    const mlPoints = json;
    let type = "";
    if (/*context &&*/ mlPoints) {//Iterate over the Pin Defects data.
      for (let i = 0; i < mlPoints.length; i++) {
          let cart: Cartographic, iModel: IModelConnection, utmXY: Point3d, heightBuffer: number, spatialPoint: Point3d, ecefX: number, ecefY: number, ecefZ: number, oldCentroidPtInGlobal: Point3d;
          if (mlPoints[i].className === "AI_ML_Defect") {
            cart = Cartographic.fromDegrees( {longitude: mlPoints[i].longitude, latitude: mlPoints[i].latitude, height: mlPoints[i].altitude});
            // let utmXY = this.LatLong2UTM(mlPoints[i].latitude, mlPoints[i].longitude, 10);
            iModel = UiFramework.getIModelConnection()!;
            utmXY = await iModel.cartographicToSpatial(cart);
            heightBuffer = iModel.spatialToCartographicFromEcef(new Point3d(utmXY.x, utmXY.y, mlPoints[i].altitude)).height;
            spatialPoint = new Point3d(utmXY.x, utmXY.y, (mlPoints[i].altitude - heightBuffer) + mlPoints[i].altitude);
          } else {
                mlPoints[i].pinInformation[0].toString().split('.')[0].length
                let pins = mlPoints[i].pinInformation.replace(/\[|\]/g,'').split(',');
            ecefX = Number(parseFloat(pins[0]));
            ecefY = Number(parseFloat(pins[1]));
            ecefZ = Number(parseFloat(pins[2]));
                
                spatialPoint = new Point3d(ecefX, ecefY, ecefZ);
          }

        const marker = new Marker(spatialPoint, new Point2d(22, 22));
                marker.visible = true;

        if (mlPoints[i].userData === undefined) {
            mlPoints[i].userData = { marker, className: mlPoints[i].className };
        }

        if (mlPoints[i].className === "AI_ML_Defect") {
            marker.setImageUrl("image/defect-camera-icon.png");
            type = "detected"
        }
        else {
            marker.setImageUrl("image/location.SVG");
            type = "manual"
        }

           //-----When marker hover mouse leaves 
        marker.onMouseLeave = () => {
            marker.label = "";
            marker.imageSize = {x: 38, y: 38};
        };
          //-----When marked is hovered over
        marker.onMouseEnter = (_ev: BeButtonEvent) => {
            marker.label = mlPoints[i].criticality + ": " + mlPoints[i].defect;
            marker.labelBaseline = "bottom";
            marker.labelOffset = {x: 0, y: 20};
            marker.imageSize = {x: 48, y: 48};
            return true;
        };
           //-----When marker is cliced
        marker.onMouseButton = (_ev: BeButtonEvent) => {
            let type = ""
            if (_ev.button === BeButton.Data) {
                DefectsDecorator._markers.forEach((e) => {
                    const im = e.image as HTMLImageElement;
                    if (im.src.includes("defect-camera-icon-select")) {
                        e.setImageUrl("image/defect-camera-icon2.png");
                        type = "detected"
                    } else if (im.src.includes("image/location-coloured")) {
                        e.setImageUrl("image/location.SVG");
                        type = "manual"
                    }
                });

                const im = marker.image as HTMLImageElement;
                if (im.src.includes("defect-camera-icon")) {
                    marker.setImageUrl("image/defect-camera-icon-select.png");
                    type = "detected"
                }
                else {
                    marker.setImageUrl("image/location-coloured.SVG");
                    type = "manual"
                    AnnotationDecorator.selectedMarkerJson = mlPoints[i];
                }

                DefectsDecorator.selectedMarkerJson = mlPoints[i];

                const iModel = UiFramework.getIModelConnection()!;
                iModel!.selectionSet.emptyAll();
                const selName = `defectMarker#${mlPoints[i].defectId}#${type}`;
                let getId: Id64String = "";
                this.objectIdMap.forEach((e, i)=>{if(e==selName){getId=i;return;}});
                if(getId.length)iModel!.selectionSet.add(getId!);
        
                // show defect widget
                if (im.src.includes("defect-camera-icon")) {
                    // FrontstageManager.activeFrontstageDef?.getStagePanelDef(StagePanelLocation.Right)?.
                    // findWidgetDef("PropertyListWidget")?.setWidgetState(WidgetState.Open);
                    SyncUiEventDispatcher.dispatchSyncUiEvent("defectselected");
                    // SyncUiEventDispatcher.dispatchSyncUiEvent(SyncUiEventIds.Defect_Annotation_Selected);

                } else {
                    // FrontstageManager.activeFrontstageDef?.getStagePanelDef(StagePanelLocation.Right)?.
                    // findWidgetDef("PropertyListWidget")?.setWidgetState(WidgetState.Open);
                    // SyncUiEventDispatcher.dispatchSyncUiEvent("pin-selected");
                    // SyncUiEventDispatcher.dispatchSyncUiEvent(SyncUiEventIds.Defect_Annotation_Selected);

                }
            }
            else if (_ev.button === BeButton.Reset) {
                const im = marker.image as HTMLImageElement;
                DefectsDecorator.selectedMarkerJson = undefined;

                if (im.src.includes("defect-camera-icon")) {
                    // FrontstageManager.activeFrontstageDef?.getStagePanelDef(StagePanelLocation.Right)?.findWidgetDef("PropertyListWidget")?.setWidgetState(WidgetState.Hidden);
                    SyncUiEventDispatcher.dispatchSyncUiEvent("defectunselected");
                }
                else {
                    // FrontstageManager.activeFrontstageDef?.getStagePanelDef(StagePanelLocation.Right)?.findWidgetDef("PropertyListWidget")?.setWidgetState(WidgetState.Hidden);
                    SyncUiEventDispatcher.dispatchSyncUiEvent("pin-unselected");
                }
            }
            else {
               return false;
            }
            return true;
        };
        
          DefectsDecorator._markers.push(marker);
          this.publicData.push(mlPoints[i]);
          const vp = IModelApp.viewManager.selectedView;
          if (vp === undefined) return;
          const nextId = vp.iModel.transientIds.getNext();
          this.objectIdMap.set(nextId, `defectMarker#${mlPoints[i].defectId}#${type}`);
        }
    }
  }

  /** Return true if supplied Id represents a pickable decoration created by this decorator. */
  public testDecorationHit(_id: string): boolean {
    return Array.from(this.objectIdMap.values()).includes(_id);
  }
 


}





