
import { BeButtonEvent, EventHandled, IModelApp, NotifyMessageDetails, OutputMessagePriority, OutputMessageType, PrimitiveTool, SelectionTool, Viewport } from "@itwin/core-frontend";
import { Point2d, Point3d,Point3dArray,Point3dArrayCarrier,PointString3d,Vector3d} from "@itwin/core-geometry";
import { ColorDef} from "@itwin/core-common";
import { UiFramework, WidgetState } from "@itwin/appui-react";
// import App from "../App";
import GratingsClient from "../../api/gratingsClient";
import { HighlightGratingTool } from "./HighlightGratingTool";
import { DTVActions } from "../../../store/Actions";
import { store } from "../../../store/rootReducer";
import { DecoratorHelper } from "../../tools/decorators/DecoratorHelper";
import { PolygonShapeDecorator } from "../../tools/decorators/PolygonShapeDecorator";

//------------------------------------------------------------------------------------
//  ╔═╗┌─┐┌┐┌┌┬┐┌─┐┬┌┐┌┌─┐┬─┐┌─┐
//  ║  │ ││││ │ ├─┤││││├┤ ├┬┘└─┐
//  ╚═╝└─┘┘└┘ ┴ ┴ ┴┴┘└┘└─┘┴└─└─┘
//------------------------------------------------------------------------------------
class Pipe {

    constructor() {
        this.pointA = new Point3d(0);
        this.pointB = new Point3d(0);
    }
    pointA: Point3d;
    pointB: Point3d;
}
class PolygonInfo {

    constructor() {
        this.name = "";
        this.poligonPoints = [];

        this.jointPipes = [];
        this.endToendJointPipes = []
        this.edgePipes = [];
        //this.area = 0;
    }
    name: string;                                               // The name of the polygon
    poligonPoints: Point3d[];                                   // Points that make a polygon/grating shape.
    faceLoacation: any;                                         // The face btween wich the polygon exists, ie : FaceA, FaceB, Mountname | format : {first, second ,mount}
    //Supporting Members
    endToendJointPipes: Pipe[];                                 // The joint pipes that create a stringth end to end joints for the grating that connect to the relevant mount pipe members.
    jointPipes: Pipe[];                                         // The point data for pipes that will make pipes visualising joints.
    edgePipes: Pipe[];                                          // The points data for pipes that will make grating edges.

    //area: number;                                             // Area of the polygon in Sq units // WIP future use case
}
//------------------------------------------------------------------------------------
class EdgeSnappingInfo {                                        
    constructor() {                                             //Associative data for point and its projected point on neearest pipe, can also be used to represent joints 
        this.polygonPoint = new Point3d(0);
        this.projectedPoint = new Point3d(0);
        this.distanceBetweenPoandPP = 0;
        this.pipePoints = [];
    }
    polygonPoint: Point3d;                                      //the initial point.
    projectedPoint: Point3d;                                    //the position the point will snap to.
    distanceBetweenPoandPP: number;                             //the distance between where the point is and where the point will snapto : the closest pipe.
    pipePoints: Point3d[]
}
//------------------------------------------------------------------------------------
class Edge {
    constructor() {
        this.points = [];                                       //Points that make an edge of a polygon.
        this.edgeDir = new Vector3d(0);                        

        this.joints = [];                                       
    }
    points: Point3d[];                                          //An edge will comprise of 2 points.
    edgeDir: Vector3d;                                          //The direction of the vector the two points will make wrt each other.
    joints: EdgeSnappingInfo[];                                 //The associated joints  that attach the edge of the polygon to the mount pipes.
}
//------------------------------------------------------------------------------------
 /* Tool to create Polygonal shapes For Grating Classification and Storage. 
  * Features:- 
  * - Create Grating
  * - Detect Grating location , Elevation 
  * - Detect Correct associative mount pipes for Processing
  * - Auto Generate , Supporting Pipes that attach the grating to the Mount Pipes.
  * - Save the Grating data to database.
  * https://dev.azure.com/bentleycs/TowerSight/_workitems/edit/883820
  */ 
//------------------------------------------------------------------------------------
export class AddEditPolygonShapeTool extends PrimitiveTool {

    //Static variables will persist over multiple instances. Make sure to manage and monitor how and when they update, allocate or deallocate.
    public static override toolId = "AddEditPolygonShapeTool";      // An external qualifier the gets passed into App.tool.run();
    public static override iconSpec = "icon-edit-all";

    private static _mountingsData: any | undefined;              // Fetched data for mountPipes and Equipment.
    private static points: Point3d[] = [];                      // Hold the temp buffer of points that will create a polygon shape 
    
    private static polygonDecorator: PolygonShapeDecorator;     // This is the polygon Decorator that will be added to viewManager and should hold the current Decorator context.

    private static isEditMode: boolean = false;                 // Decide what functionality the AddPolygonTool is supposed to provide. If false, it uses points to create polygon , if true will take point to update exitstin point positions.

    private static _poligonInfoArray: PolygonInfo[] = [];       // Keeps track of all the points that were generated to create the polygon. Will be reused to recreate the polygons for updates.
    private static _pointCountPerPolygon: number = 3;           // Is used in PolygonShapeControl and polygonTransformWidget to get ui updates to the point count that will make the grating polygon.
    //------------------------------------------------------------------------------------
    /*
    * Clear all dependencies and references used in this tool for Geometry generation
    * see: SampleToolWidget.tsx->Delete Selected Grating (button).
    */
    public static terminateTool() {
        AddEditPolygonShapeTool.points = [];
        AddEditPolygonShapeTool._poligonInfoArray = [];
        
        AddEditPolygonShapeTool.polygonDecorator?.clearGeometry();
        //AddEditPolygonShapeTool.markerHelperDecorator?.terminateMarkers();
    }
    //------------------------------------------------------------------------------------
    /*  delete the polygon object locally.
     */ 
    public static deletePolygonByName(name: string) {
        for (let p = 0; p < AddEditPolygonShapeTool._poligonInfoArray.length; p++) {
            if (AddEditPolygonShapeTool._poligonInfoArray[p].name == name) {                  
                AddEditPolygonShapeTool._poligonInfoArray.splice(p, 1);
            }
        }
        AddEditPolygonShapeTool.polygonDecorator.clearPolygonByName(name);
        AddEditPolygonShapeTool.polygonDecorator.clearJointGeometry();
        AddEditPolygonShapeTool.polygonDecorator.clearGratingSupportGeometry();
        AddEditPolygonShapeTool.polygonDecorator.deleteSpheresMarkerSFromDecorator();
    }
    //------------------------------------------------------------------------------------
    /* Get set  the populated PolygonInfo Points[] as a static access variable.
     */ 
    public static get poligonInfoArray(): PolygonInfo[] {
        return AddEditPolygonShapeTool._poligonInfoArray;
    }
    /* Was created to be used to transfer prefeteched saved polyogoninfo data from HighlightTool to the static
     * variable of this class.The Handoff is duringthe transistion from HighlightGratingto EditGrating in
     * SampletoolWidget EditEquipment functonality.
     */ 
    public static set poligonInfoArray(p: PolygonInfo[]) {
        AddEditPolygonShapeTool._poligonInfoArray = p;
    }
    //------------------------------------------------------------------------------------
    /* Allows to set this data externally if not internally by the importMoutingsData() function.
     * Was created to be used to transfer prefetched mountings data from HighlightTool to the static
     * variable of this class. The Handoff is duringthe transistion from HighlightGratingto EditGrating in
     * SampletoolWidget EditEquipment functonality.
     */ 
    public static set mountingsData(m : any) {
        AddEditPolygonShapeTool._mountingsData = m;
    }
    //------------------------------------------------------------------------------------
    /*
     * set get the point count that determines the number of points that the tool
     * will provide the user to make polygons with. This is set via UI inputs in .
    */
    public static set pointCountPerPolygon(count: number) {
        AddEditPolygonShapeTool._pointCountPerPolygon = count;
    }
    public static get pointCountPerPolygon(): number{
       return AddEditPolygonShapeTool._pointCountPerPolygon;
    }
    //------------------------------------------------------------------------------------
    /* Sets mode to Create or Edit. False to create , true to edit
     */
    public static setEditMode(editMode: boolean) {
        AddEditPolygonShapeTool.isEditMode = editMode
    }
    //------------------------------------------------------------------------------------
    // ╦  ┌─┐┌─┐┌┬┐   ╔═╗┌─┐┬  ┬┌─┐   ╔╦╗┌─┐┬  ┌─┐┌┬┐┌─┐
    // ║  │ │├─┤ ││   ╚═╗├─┤└┐┌┘├┤     ║║├┤ │  ├┤  │ ├┤
    // ╩═╝└─┘┴ ┴─┴┘┘  ╚═╝┴ ┴ └┘ └─┘┘  ═╩╝└─┘┴─┘└─┘ ┴ └─┘
    //------------------------------------------------------------------------------------
    /* Import the mounts data , for our use case we need the horizontal pipe information
     * for the use cases of Snapping and disjointing avoidance.
     */
    private static async importMoutingsData() {
        const token = store.getState().auth.accessTokenStatePrivateAPI.accessToken!;
        const a = await GratingsClient.getMountingsDataJason(token);
        AddEditPolygonShapeTool._mountingsData = a;
    }
    //------------------------------------------------------------------------------------
    /*
     * Delete the grating Info from database by Name only.
     * Searches for relevant mount assocated with grating and 
     * runs the procedure.
     */ 
    public static async deleteSavedGratingByName(gratingId: string ) {
        for (let mount in AddEditPolygonShapeTool._mountingsData.mounts) {
            for (let eqp in AddEditPolygonShapeTool._mountingsData.mounts[mount]) {
                if (eqp == "grating") {
                    for (let grat in AddEditPolygonShapeTool._mountingsData.mounts[mount][eqp]) {
                        if (grat == gratingId) {
                            //delete the grating info from database.
                            AddEditPolygonShapeTool.deleteSavedGratingByNameAndMountId(mount, gratingId);
                            IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Info, " Saved Grating " + gratingId + "Deleted" ,"", OutputMessageType.Toast));
                        }
                    }
                }
            }
        }
    }
    //------------------------------------------------------------------------------------
    /*
     * Delete the grating object from database. By mount and grating id.
     */ 
    public static async deleteSavedGratingByNameAndMountId(mountId : string , gratingId : string) {
        const token = store.getState().auth.accessTokenStatePrivateAPI.accessToken!;
        let data = { mountId: mountId, gratingId: gratingId };
        GratingsClient.deleteGrating(token, data);
    }
    //------------------------------------------------------------------------------------
    /* Save Data via the api put command, 
     */
    public static async saveExportGratingsData() {
        const token = store.getState().auth.accessTokenStatePrivateAPI.accessToken!;
        let status: string = "";
        let savedGratingName: string = "";

        //Aggregate all the diffrent pipe types;
        for (let d = 0; d < AddEditPolygonShapeTool._poligonInfoArray.length; d++)
        {
            if (AddEditPolygonShapeTool._poligonInfoArray[d].name == PolygonShapeDecorator.lastSelectedPolygonShape.name) {//save only the selected object.

                let pipes: any[] = [];

                for (let ep = 0; ep < AddEditPolygonShapeTool._poligonInfoArray[d].edgePipes.length; ep++) {
                    let pipe = { supportingPipesId: "edgepipe" + ep.toString(), startPoint: AddEditPolygonShapeTool._poligonInfoArray[d].edgePipes[ep].pointA, endPoint: AddEditPolygonShapeTool._poligonInfoArray[d].edgePipes[ep].pointB};
                    pipes.push(pipe);
                }
                for (let ee = 0; ee < AddEditPolygonShapeTool._poligonInfoArray[d].endToendJointPipes.length; ee++) {
                    let pipe = { supportingPipesId: "endtoend" + ee.toString(), startPoint: AddEditPolygonShapeTool._poligonInfoArray[d].endToendJointPipes[ee].pointA, endPoint: AddEditPolygonShapeTool._poligonInfoArray[d].endToendJointPipes[ee].pointB};
                    pipes.push(pipe);
                }
                for (let je = 0; je < AddEditPolygonShapeTool._poligonInfoArray[d].jointPipes.length; je++) {
                    let pipe = { supportingPipesId: "jointpipe" + je.toString(), startPoint: AddEditPolygonShapeTool._poligonInfoArray[d].jointPipes[je].pointA, endPoint: AddEditPolygonShapeTool._poligonInfoArray[d].jointPipes[je].pointB};
                    pipes.push(pipe);
                }

                if (AddEditPolygonShapeTool._poligonInfoArray[d].name == PolygonShapeDecorator.lastSelectedPolygonShape?.name) {
                    let data: any;
                    if (pipes.length > 0) {
                            data = {
                                "mountId": AddEditPolygonShapeTool._poligonInfoArray[d].faceLoacation.mount,
                                "faceId": AddEditPolygonShapeTool._poligonInfoArray[d].faceLoacation.first,
                                "mountPipeType": "Grating",
                                "mountPipeId": "H-LegC02",
                                "grating": {
                                    "gratingId": AddEditPolygonShapeTool._poligonInfoArray[d].name,//for now , once deleting feature is created Create new mount with correct uniqe IDs
                                    "utm": AddEditPolygonShapeTool._poligonInfoArray[d].poligonPoints,
                                    "faceLocation": AddEditPolygonShapeTool._poligonInfoArray[d].faceLoacation.first + "," + AddEditPolygonShapeTool._poligonInfoArray[d].faceLoacation.second,
                                    "supportingPipes": pipes//if pipe are available
                                },
                            }
                            //await AddEditPolygonShapeTool.timer(1000); // then the created Promise can be awaited
                            status = await GratingsClient.putGratingDataJason(token, data);
                            savedGratingName = data.grating.gratingId;
                    }
                    else {
                        data = {
                            "mountId": AddEditPolygonShapeTool._poligonInfoArray[d].faceLoacation.mount,
                            "faceId": AddEditPolygonShapeTool._poligonInfoArray[d].faceLoacation.first,
                            "mountPipeType": "Grating",
                            "mountPipeId": "H-LegC02",
                            "grating": {
                                "gratingId": AddEditPolygonShapeTool._poligonInfoArray[d].name,
                                "utm": AddEditPolygonShapeTool._poligonInfoArray[d].poligonPoints,
                                "faceLocation": AddEditPolygonShapeTool._poligonInfoArray[d].faceLoacation.first + "," + AddEditPolygonShapeTool._poligonInfoArray[d].faceLoacation.second,
                            },
                        }
                        status = await GratingsClient.putGratingDataJason(token, data);
                        savedGratingName = data.grating.gratingId;
                    }
                }
            }
        }

        if (status == "Success.") {
            IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Info, "Grating '" + savedGratingName + "' Saved", "", OutputMessageType.Toast));
            //if succefull check if it overwrite existing data for the same location. And display it.
            for (let mount in AddEditPolygonShapeTool._mountingsData.mounts) {
                for (let eqp in AddEditPolygonShapeTool._mountingsData.mounts[mount]) {
                    if (eqp == "grating") {
                        for (let grat in AddEditPolygonShapeTool._mountingsData.mounts[mount][eqp]) {
                            if (grat == savedGratingName) {
                                IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Info, "Over Writing Grating Information " + savedGratingName, "", OutputMessageType.Toast));
                            }
                        }
                    }
                }
            }
        }
        else {
            IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Warning, "Could Not Save", "", OutputMessageType.Toast));
        }

    }

    //------------------------------------------------------------------------------------
    // ╔═╗┬  ┌─┐┬  ┬┌─┐┌┬┐┬┌─┐┌┐┌  ╔═╗┌┐┌┌─┐┌─┐┌─┐┬┌┐┌┌─┐  
    // ║╣ │  ├┤ └┐┌┘├─┤ │ ││ ││││  ╚═╗│││├─┤├─┘├─┘│││││ ┬
    // ╚═╝┴─┘└─┘ └┘ ┴ ┴ ┴ ┴└─┘┘└┘  ╚═╝┘└┘┴ ┴┴  ┴  ┴┘└┘└─┘
    //------------------------------------------------------------------------------------
    /* Find majority elevation(elevation that is matching with the most number of points)
     * value has room for minor variation about the mean.
     * Since we compare occurrences for the same/similar value , we cull by the 'precision' amount
     * to improve the range for which we can match the values for elevation. currently precision to 1 decimal place
     * for best results.
     */
    private static getBestProbableElivationOfPoints(points: Point3d[], precision: number): number
    {
        if (points.length == 0) {
            return 0;
        }
        var modeMap = {};
        var maxEl = parseFloat(points[0].z.toFixed(precision)), maxCount = 1;
        for (var i = 0; i < points.length; i++) { //Map based O(n) complexity comparison op.
            var el = parseFloat(points[i].z.toFixed(precision));
            if (modeMap[el] == null) {
                modeMap[el] = 1;
            }
            else {
                modeMap[el]++;
            }
            if (modeMap[el] > maxCount) {
                maxEl = el;
                maxCount = modeMap[el];
            }
        }
        return maxEl;//value with the maximum occurrences.
    }
    //------------------------------------------------------------------------------------
    /* Find all the pipe points that match closest to the elevation value sent in as the input param
     * will return a map with name , Starting and ending points of the relevant pipes for the same/closest elevation.
     */
    private static getMountPipesForSameElevation(elevatinoPoint: number): Map<string, Point3d> | any | undefined {

        let ple = elevatinoPoint

        const iModel = UiFramework.getIModelConnection()!;
        const o = iModel.spatialToCartographicFromEcef(iModel.projectExtents.high!);

        let min: number = 999999999;
        //let sameElevationPoints: Point3d[] = [];
        let pipesMap = new Map();
        let lowestDiffmountname;

        let minElevation = 9999999999999;
        let lowestDiffPipeName;

        let mountName = "";

        if (AddEditPolygonShapeTool._mountingsData != null) {
            for (let mount in AddEditPolygonShapeTool._mountingsData.mounts) {
                for (let facesLoc in AddEditPolygonShapeTool._mountingsData.mounts[mount].horizont_pipe) {
                    for (let hpipes in AddEditPolygonShapeTool._mountingsData.mounts[mount].horizont_pipe[facesLoc]) {
                        let p = AddEditPolygonShapeTool._mountingsData.mounts[mount].horizont_pipe[facesLoc][hpipes]
                        let ppEleZ = parseFloat(DecoratorHelper.ExtractSpatialXYZ(o, p.utm[0][0], p.utm[0][1], p.utm[0][2], iModel).z.toFixed(1))//get the z elevation value in ecef,cap the pricision for better matching

                        let minDiff = (ple - ppEleZ)//get the diffract between elevations
                        if (minDiff < 0) { minDiff *= -1; }//make the value unsigned
                        if (min > minDiff) // 
                        {
                            min = minDiff; // get the lowest difference
                            lowestDiffmountname = mount; // get the mount name with the lowest difference in elevation.

                            if (minElevation > ppEleZ) {
                                minElevation = ppEleZ;//store the lowest elevation distance for subject elevation point.
                                lowestDiffPipeName = hpipes;//get the pipe name with the lowest Difference.
                            }
                        }
                    }
                }
            }

            let pipe2Points: Point3d[] = [];
            for (let mount in AddEditPolygonShapeTool._mountingsData.mounts) {
                for (let facesLoc in AddEditPolygonShapeTool._mountingsData.mounts[mount].horizont_pipe) {
                    for (let hpipes in AddEditPolygonShapeTool._mountingsData.mounts[mount].horizont_pipe[facesLoc]) {
                        if (lowestDiffmountname == mount) {//search and get the data for the lowestdiff selected mount via its name
                            let p = AddEditPolygonShapeTool._mountingsData.mounts[mount].horizont_pipe[facesLoc][hpipes];
                            let ecef1Val = DecoratorHelper.ExtractSpatialXYZ(o, p.utm[0][0], p.utm[0][1], p.utm[0][2], iModel);//convert values to realty ecef
                            let ecef2Val = DecoratorHelper.ExtractSpatialXYZ(o, p.utm[1][0], p.utm[1][1], p.utm[1][2], iModel);

                            pipe2Points = [];
                            pipe2Points.push(ecef1Val);
                            pipe2Points.push(ecef2Val);//push the start and end point of pipe.

                            if (hpipes[hpipes.length - 1] == lowestDiffPipeName[lowestDiffPipeName.length - 1]){//Match the lower detected pipe of the top and botom pipe for each mount.
                                pipesMap.set(hpipes, pipe2Points);
                                mountName = mount;
                            }
                        }
                    }
                }
            }
            return {
                pipes: pipesMap,//return data needed for snapping of point to these pipes. The lookup to snap will only be limited to these pipes that or of the same elevation.
                mount: mountName//also return the relevant mount detected for that elevation
            };
        }
        else {//Exception messaging on missing data
            IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Error, "Error Fetching Mountings Data for Pre-process", "", OutputMessageType.Toast));
            IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Info, "Skipping Pre-processing", "", OutputMessageType.Toast));
            return undefined;
        }
    }
    //------------------------------------------------------------------------------------
    /* Takes the name of the Polygon/Grating to snap it to the nearest elevation/mount plane it looks up
     * Params : Name of Polygon(in this instance the name of the selected polygon) see: PolygonTransformWidget->snapToElevationPlane(),
     * the boolean flag to enable or disable averaging the elevation and using it to generate the plane.
     * 
     * see : PolygonTransformWidget.tsx->snapToElevationPlane();
     */ 
    public static snapGratingPointToNearestPlaneByName(name: string,aproximateElevation : boolean)
    {
        let ple: number = 0;
        let pointsIndex
        for (let pa = 0; pa < AddEditPolygonShapeTool.poligonInfoArray.length; pa++) {//Travese the poligonInfo array and its polygonpoints sub object array.
            if (AddEditPolygonShapeTool.poligonInfoArray[pa].name == name) {
                pointsIndex = pa;
                //the detected probable elevation of polygonPoints,keep the pricison(second param) at 1 for best matching.
                ple = AddEditPolygonShapeTool.getBestProbableElivationOfPoints(AddEditPolygonShapeTool.poligonInfoArray[pa].poligonPoints, 1)
            }
        }

        let pipesMap: any | undefined;//will store a map object <string ,[point3D,point3D]>
        if (ple != 0) {
            pipesMap = AddEditPolygonShapeTool.getMountPipesForSameElevation(ple).pipes;
        }


        if (pipesMap != undefined) {
            let planePoints: Point3d[] = []
            for (const [key, value] of pipesMap!) {
                planePoints.push(value[1]);//select the starting or ending points does not matter , in this case the ending point of the pipe.

            }

            if (aproximateElevation) {
                // Check if the approx elevation flag is enabled if yes, approximate the elevation for all pipes and apply it to the plane that is generated.
                // If not enabled use the raw values to create the plane and the normal is subject to being non coplanar to the base surface. Might need to Explore using mount data 
                // insted of pipe data. See : projectPointsToPlane();
                let eleX = AddEditPolygonShapeTool.getBestProbableElivationOfPoints(planePoints, 1);

                for (let pp = 0; pp < planePoints.length; pp++) {

                    planePoints[pp].x = planePoints[pp].x;
                    planePoints[pp].y = planePoints[pp].y;
                    planePoints[pp].z = eleX;//Aprox elevation, this will esentially flatten the plane wrt up dir, and the normal will be (0,0,1). !Hack to fix skewed normals.Needs more targeted normals.
                }
            }

            if (AddEditPolygonShapeTool.poligonInfoArray[pointsIndex].poligonPoints) {
                let newPoints = this.projectPointsToPlane(planePoints, AddEditPolygonShapeTool.poligonInfoArray[pointsIndex].poligonPoints);//project the points to the plane.

                for (let pa = 0; pa < AddEditPolygonShapeTool.poligonInfoArray.length; pa++) {
                    if (AddEditPolygonShapeTool.poligonInfoArray[pa].name == name) {

                        AddEditPolygonShapeTool.poligonInfoArray[pa].poligonPoints = newPoints;
                        AddEditPolygonShapeTool.createPoligonFromPolygonInfoArray(AddEditPolygonShapeTool.poligonInfoArray);
                    }
                }
            }
        }        
    }
    //------------------------------------------------------------------------------------
    // ╔═╗┬─┐┌─┐┌┬┐┬┌┐┌┌─┐  ╔═╗┌─┐┬┌┐┌┌┬┐  ┌┬┐┌─┐  ╔╦╗┌─┐┬ ┬┌┐┌┌┬┐  ┌─┐┬┌─┐┌─┐  ╔═╗┌─┐┌─┐
    // ║ ╦├┬┘├─┤ │ │││││ ┬  ╠═╝│ │││││ │    │ │ │  ║║║│ ││ ││││ │   ├─┘│├─┘├┤   ║ ║├─┘└─┐
    // ╚═╝┴└─┴ ┴ ┴ ┴┘└┘└─┘  ╩  └─┘┴┘└┘ ┴    ┴ └─┘  ╩ ╩└─┘└─┘┘└┘ ┴   ┴  ┴┴  └─┘  ╚═╝┴  └─┘
    //------------------------------------------------------------------------------------
    /*
     * Calculates the non-orthogonal projection of a point to all the pipes sent in as the parameter
     * in this case the pipes have been preprocessed and selected so that they are of the relevant mount and plane.
     * returns a point that is project on the pipe that is closest to the point wrt all the pipes.
     * See : gratingtool.pptx spec sheet in attachements tab: https://dev.azure.com/bentleycs/TowerSight/_workitems/edit/883820
     */ 
    private static calcProjectedGratingPointToNearestPipe(point: Point3d, pipesMap: Map<string, Point3d>): EdgeSnappingInfo[] {
        let p = point;
        let sp: Point3d;
        let ep: Point3d;
        let selectedDistance: number = 0;
        let selectedStartPoint: Point3d;
        let snapInfoArray: EdgeSnappingInfo[] = []; 
        let distancesToPipe: number[] = [];

        //only populate the second index of the array if the diffrence between the two points(in distances from pipes) is small.
        //indicating that the point could be in the middle two pipes. In wich case we might need to draw connectors to both using 2 projected points.
        let nearestTwoProjectedPoints: Point3d[] = [];

        let snapinfos: EdgeSnappingInfo[] = [];
        if (pipesMap != undefined) {

            //Please view the doc on calculations 
            for (const [key, value] of pipesMap) {//iterate over all values in the map
                sp = value[0];
                ep = value[1];

                let distA = point.distance(sp);
                let distB = point.distance(ep);

                let dir: Vector3d;
                    if (distA > distB) {
                        dir = new Vector3d(sp.minus(ep).x, sp.minus(ep).y, sp.minus(ep).z).normalize()!;/*use ep to project*/
                        selectedDistance = distB;
                        selectedStartPoint = ep;
                    }
                    else {
                        dir = new Vector3d(ep.minus(sp).x, ep.minus(sp).y, ep.minus(sp).z).normalize()!;/*use sp to project*/
                        selectedDistance = distA;
                        selectedStartPoint = sp;
                    }

                    let dirM = new Vector3d(dir.x * selectedDistance, dir.y * selectedDistance, dir.z * selectedDistance)
                    let pPoint = new Point3d(selectedStartPoint.x + dirM.x, selectedStartPoint.y + dirM.y, selectedStartPoint.z + dirM.z);//The projected point
                    let d = pPoint.distance(point);// the distance between the projected point and the Polygon Point

                    distancesToPipe.push(d);

                   // if (smallesDistnceToPipe > d) { smallesDistnceToPipe = d; }//collect the smallest distance of this point from all the pipes in consideration.
                    let snap = new EdgeSnappingInfo();
                    snap.polygonPoint = point;
                    snap.projectedPoint = pPoint;
                    snap.distanceBetweenPoandPP = d;
                    snap.pipePoints.push(sp); snap.pipePoints.push(ep);//push starting and ending points
                    snapInfoArray.push(snap);
            }

            distancesToPipe = distancesToPipe.sort((a, b) => a - b);//sort the distances where the first index will be the smalles value;
            let distanceFactor = distancesToPipe[1] - distancesToPipe[0];

            snapinfos = [];
            for (let s = 0; s < snapInfoArray.length; s++) {
                if (snapInfoArray[s].distanceBetweenPoandPP == distancesToPipe[0]) {
                    snapinfos.push(snapInfoArray[s]);
                }
                //if distance factor is close to the same on both end of the polygon point , then send out two projected points
                //to create a projected joint pipe on both sides of the point. a a 3 point polygon see: https://dev.azure.com/bentleycs/TowerSight/_workitems/edit/883820
                if (distanceFactor < 0.2 && snapInfoArray[s].distanceBetweenPoandPP == distancesToPipe[1]) {
                    snapinfos.push(snapInfoArray[s]);//return a Snapping info contaner object for use. In our case we will use the .projectedPoint member.
                }
            }
            return snapinfos;//return populated
        }
        return snapinfos;//Error case empty;
    }
    //------------------------------------------------------------------------------------
    /* Takes the name of the selected Grating/Polygon object and snaps its points 
     * to there respective nearest pipe members on the mount of the same elevation.
     * 
     * see : PolygonTransformWidget.tsx->snapPointstoPipes();
     */ 
    public static snapGratingPointsToPipesByName(name: string) {//This function is not in use, as we are using createJointAndSupportingMembersByName() instead.

        let ple: number = 0;
        let pointsIndex
        for (let pa = 0; pa < AddEditPolygonShapeTool.poligonInfoArray.length; pa++) {//Traverse the poligonInfo array and its polygonpoints sub object array.
            if (AddEditPolygonShapeTool.poligonInfoArray[pa].name == name) {
                pointsIndex = pa;
                ple = AddEditPolygonShapeTool.getBestProbableElivationOfPoints(AddEditPolygonShapeTool.poligonInfoArray[pa].poligonPoints, 1)//the detected probable elevation of polygonPoints
            }
        }

        let pipesMap = AddEditPolygonShapeTool.getMountPipesForSameElevation(ple).pipes;//get the relevant pipe info for the same elevation

        for (let pa = 0; pa < AddEditPolygonShapeTool.poligonInfoArray.length; pa++) {//Traverse the poligonInfo array and its polygonpoints sub object array.
            if (AddEditPolygonShapeTool.poligonInfoArray[pa].name == name)
            {
                ple = AddEditPolygonShapeTool.getBestProbableElivationOfPoints(AddEditPolygonShapeTool.poligonInfoArray[pa].poligonPoints, 1)//the detected probable elevation of polygonPoints
                for (let pp = 0; pp < AddEditPolygonShapeTool.poligonInfoArray[pa].poligonPoints.length; pp++){

                    let ppo = AddEditPolygonShapeTool.poligonInfoArray[pa].poligonPoints[pp];//polygonPoint
                    let ppp = AddEditPolygonShapeTool.calcProjectedGratingPointToNearestPipe(AddEditPolygonShapeTool.poligonInfoArray[pa].poligonPoints[pp], pipesMap!);//projected Point
                    AddEditPolygonShapeTool.poligonInfoArray[pa].poligonPoints[pp] = ppp[0].projectedPoint;

                }
            }
        }
        AddEditPolygonShapeTool.createPoligonFromPolygonInfoArray(AddEditPolygonShapeTool.poligonInfoArray);
    }
    //------------------------------------------------------------------------------------
    // ╔═╗┬ ┬┌┬┐┌─┐  ╔═╗┌─┐┌┐┌  ╔═╗┬┌─┐┌─┐┌─┐
    // ╠═╣│ │ │ │ │  ║ ╦├┤ │││  ╠═╝│├─┘├┤ └─┐
    // ╩ ╩└─┘ ┴ └─┘  ╚═╝└─┘┘└┘  ╩  ┴┴  └─┘└─┘
    //------------------------------------------------------------------------------------
    /* Runs extesive detection with projections and intersection tests to generate pipes automatically for the created polygon.
     * The Auto pipes are used to create supporting member that help the polygon/grating avoid being disjoint from the parent mount. 
     *  
     * see : PolygonTransformWidget.tsx->snapPointstoPipes();
     */
    public static createJointsAndSupportingMembersByName(name: string , snapPolyGonToPipes : boolean)
    {
        let ple: number = 0;
        for (let pa = 0; pa < AddEditPolygonShapeTool.poligonInfoArray.length; pa++) {//Traverse the poligonInfo array and its polygonpoints sub object array.
            if (AddEditPolygonShapeTool.poligonInfoArray[pa].name == name) {
                ple = AddEditPolygonShapeTool.getBestProbableElivationOfPoints(AddEditPolygonShapeTool.poligonInfoArray[pa].poligonPoints, 1)//the detected probable elevation of polygonPoints
            }
        }

        let pipesMap = AddEditPolygonShapeTool.getMountPipesForSameElevation(ple).pipes;//get the relevant pipe info for the same elevation

        for (let pa = 0; pa < AddEditPolygonShapeTool.poligonInfoArray.length; pa++) {//Traverse the poligonInfo array and its polygonpoints sub object array.
            if (AddEditPolygonShapeTool.poligonInfoArray[pa].name == name)
            {
                AddEditPolygonShapeTool.poligonInfoArray[pa].jointPipes = [];
                AddEditPolygonShapeTool.poligonInfoArray[pa].edgePipes = [];

                for (let pp = 0; pp < AddEditPolygonShapeTool.poligonInfoArray[pa].poligonPoints.length; pp++)
                {
                    let ppo = AddEditPolygonShapeTool.poligonInfoArray[pa].poligonPoints[pp];//polygonPoint.
                    let ppp = AddEditPolygonShapeTool.calcProjectedGratingPointToNearestPipe(AddEditPolygonShapeTool.poligonInfoArray[pa].poligonPoints[pp], pipesMap!);//project the polygon point to nearest pipes

                    let epi = new Pipe();
                    epi.pointA = ppo;
                    if (pp + 1 < AddEditPolygonShapeTool.poligonInfoArray[pa].poligonPoints.length) {//failsafe check 
                        epi.pointB = AddEditPolygonShapeTool.poligonInfoArray[pa].poligonPoints[pp + 1];
                    }
                    if (pp == AddEditPolygonShapeTool.poligonInfoArray[pa].poligonPoints.length - 1) {
                        epi.pointA = AddEditPolygonShapeTool.poligonInfoArray[pa].poligonPoints[AddEditPolygonShapeTool.poligonInfoArray[pa].poligonPoints.length - 1];
                        epi.pointB = AddEditPolygonShapeTool.poligonInfoArray[pa].poligonPoints[0];
                    }
                    
                    AddEditPolygonShapeTool.poligonInfoArray[pa].edgePipes.push(epi);//push the poligon points to form edge supporting pipes

                    let jpi = new Pipe();
                    jpi.pointA = ppo;
                    jpi.pointB = ppp[0].projectedPoint;
                    AddEditPolygonShapeTool.poligonInfoArray[pa].jointPipes.push(jpi);//orignal point.
                    
                    if (ppp.length == 2) {
                        let jpi = new Pipe();
                        jpi.pointA = ppo;
                        jpi.pointB = ppp[1].projectedPoint;
                        AddEditPolygonShapeTool.poligonInfoArray[pa].jointPipes.push(jpi);
                    };
                }

                //------
                //Processes the data and generated data that represent Edges for boundary representation(B-rep)
                let edges: Edge[] = [];//store the edge(B-rep) information in this array
                for (let p1 = 0; p1 < AddEditPolygonShapeTool.poligonInfoArray[pa].poligonPoints.length - 1; p1++)
                {
                    let ea = AddEditPolygonShapeTool.poligonInfoArray[pa].poligonPoints[p1];
                    let eb = AddEditPolygonShapeTool.poligonInfoArray[pa].poligonPoints[p1 + 1];
                    //let edir = new Vector3d(ea.x - eb.x, ea.y - eb.y, ea.z - eb.z).normalize();
                    let edir = new Vector3d(ea.x - eb.x, ea.y - eb.y, 0).normalize();//use z = 0 to imporove matches when comapring in scenarios below

                    let edge: Edge = new Edge();
                    edge.points.push(ea)
                    edge.points.push(eb)
                    edge.edgeDir = edir!;
                    edges.push(edge);

                    if (p1+1 == (AddEditPolygonShapeTool.poligonInfoArray[pa].poligonPoints.length - 1)) {//push the dir for the last edge.
                        ea = AddEditPolygonShapeTool.poligonInfoArray[pa].poligonPoints[AddEditPolygonShapeTool.poligonInfoArray[pa].poligonPoints.length -1];
                        eb = AddEditPolygonShapeTool.poligonInfoArray[pa].poligonPoints[0];
                        //let edir = new Vector3d(eA.x - eb.x, eA.y - eb.y, eA.z - eb.z).normalize();
                        edir = new Vector3d(ea.x - eb.x, ea.y - eb.y, 0).normalize();//use z = 0 to imporove matches when comapring in scenarios below

                        let edge: Edge = new Edge();
                        edge.points.push(ea)
                        edge.points.push(eb)
                        edge.edgeDir = edir!;
                        edges.push(edge);
                    }
                }
                //------
                let jPA: Point3d = new Point3d(0);
                let joA: Point3d = new Point3d(0);
                let dirA: Vector3d = new Vector3d(0);
                let twoFaceJointPipe: Pipe[] = []
                //Calculates which joints will belog to witch edges 
                //This is based on which joints are the most colinear to the edge line/vector.
                for (let e = 0; e < edges.length; e++)
                {
                    for (let j = 0; j < AddEditPolygonShapeTool.poligonInfoArray[pa].jointPipes.length; j++)
                    {
                        joA = AddEditPolygonShapeTool.poligonInfoArray[pa].jointPipes[j].pointA;
                        jPA = AddEditPolygonShapeTool.poligonInfoArray[pa].jointPipes[j].pointB;
                        //dirA = new Vector3d(jPA.x - joA.x, jPA.y - joA.y, jPA.z - joA.z).normalize()!;//jo->jp dir vector.
                        dirA = new Vector3d(jPA.x - joA.x, jPA.y - joA.y, 0).normalize()!;//jo->jp dir vector.
                        if (dirA == undefined) {dirA = new Vector3d(0,0,0)}

                        let scalar = dirA.dotProduct(edges[e].edgeDir!);//get the cosine value of the dot product to estimate how colinear the two directons are.
                        if (scalar < 0) { scalar *= -1; }//make value unsigned

                        if (joA.x == edges[e].points[0].x || joA.x == edges[e].points[1].x) {//if the joint inital point equals any edge point then it belongs to that polygon edge.
                            //if the scalar value is close to 1 then joint vector is aproaching a relative parallel to the polygon edge vector.We give a marjin of 0.2 error for matches.
                            if (scalar > 0.8)
                            {//this detects which edge needs to have the endtoend jointpipe generated for, below.
                                let joint = new EdgeSnappingInfo();
                                joint.polygonPoint = joA;
                                joint.projectedPoint = jPA;
                                joint.distanceBetweenPoandPP = joA.distance(jPA);
                                edges[e].joints.push(joint);
                            }
                        }
                    }                 
                }

                //if the joint pipes have the same origin point , this will detect if there are two ended joints with two pipes.(In case a polygon point is in the middle of two mount pipes)
                //use the two joint pipes to create one pipe from its projected points stored in pointB(outer end that connects to the mount pipe), in earlier procedures. 
                let matchsum: number = 0;//used to avoid redundant comparions that are not required.
                let jointIndicesToDelete: number[] = [];
                for (let j = 0; j < AddEditPolygonShapeTool.poligonInfoArray[pa].jointPipes.length; j++)
                {
                    for (let k = 0; k < AddEditPolygonShapeTool.poligonInfoArray[pa].jointPipes.length; k++) {
                        if (AddEditPolygonShapeTool.poligonInfoArray[pa].jointPipes[j].pointA == AddEditPolygonShapeTool.poligonInfoArray[pa].jointPipes[k].pointA) {
                            if (j != k && matchsum != j+k) {//match sum helps check if were not comparing the same index with the same data or matching the same data again.
                                
                                let twoFacePipe: Pipe = new Pipe();
                                twoFacePipe.pointA = AddEditPolygonShapeTool.poligonInfoArray[pa].jointPipes[j].pointB;//These are the base values for the two point of the end to end joint
                                twoFacePipe.pointB = AddEditPolygonShapeTool.poligonInfoArray[pa].jointPipes[k].pointB;//(.pointB store the outer end points/projected points that connects to the mount pipe)

                                //Start transforming the pipe to match/align with the tip from where it is to be created.
                                let pArray: Point3d[] = [];
                                pArray.push(twoFacePipe.pointA);
                                pArray.push(twoFacePipe.pointB);
                                //Prerequisites for transformation.
                                let cent = AddEditPolygonShapeTool.calcCenteroid(pArray);
                                let origin = AddEditPolygonShapeTool.poligonInfoArray[pa].jointPipes[j].pointA;
                                let distance = cent.distance(origin);
                                let dir = new Vector3d(origin.x - cent.x, origin.y - cent.y, origin.z - cent.z).normalize()!;
                                //translate to align the points to the parent point from which they are projected out towards the pipe.
                                twoFacePipe.pointA = new Point3d(twoFacePipe.pointA.x + (dir.x * distance), twoFacePipe.pointA.y + (dir.y * distance), twoFacePipe.pointA.z + (dir.z * distance));
                                twoFacePipe.pointB = new Point3d(twoFacePipe.pointB.x + (dir.x * distance), twoFacePipe.pointB.y + (dir.y * distance), twoFacePipe.pointB.z + (dir.z * distance));
                                let names = AddEditPolygonShapeTool.poligonInfoArray[pa].faceLoacation;
                                //Pipe A //Pipe B 
                                let pipePointA1 = pipesMap!.get(names.first)![0];//use the names as key in the map accesed above to get which two pipes to use for the intersection test
                                let pipePointA2 = pipesMap!.get(names.first)![1];               
                                let pipePointB1 = pipesMap!.get(names.second)![0];
                                let pipePointB2 = pipesMap!.get(names.second)![1];
                                //run intersection test with the base aligned joints to fit the two encompassing pipes. As also done with the end to end joints below.
                                let nPo1 = AddEditPolygonShapeTool.calcIntersectionOfTwoLines2d(new Point2d(twoFacePipe.pointA.x, twoFacePipe.pointA.y), new Point2d(twoFacePipe.pointB.x, twoFacePipe.pointB.y), new Point2d(pipePointA1.x, pipePointA1.y), new Point2d(pipePointA2.x, pipePointA2.y));
                                let nPo2 = AddEditPolygonShapeTool.calcIntersectionOfTwoLines2d(new Point2d(twoFacePipe.pointA.x, twoFacePipe.pointA.y), new Point2d(twoFacePipe.pointB.x, twoFacePipe.pointB.y), new Point2d(pipePointB1.x, pipePointB1.y), new Point2d(pipePointB2.x, pipePointB2.y));
                                //use xy from the in 2Dintersection test and the z comp is from the projecteion point generated earlier.To best match the location of the new end to end line points. 
                                twoFacePipe.pointA = new Point3d(nPo1.x, nPo1.y, AddEditPolygonShapeTool.poligonInfoArray[pa].jointPipes[k].pointB.z);
                                twoFacePipe.pointB = new Point3d(nPo2.x, nPo2.y, AddEditPolygonShapeTool.poligonInfoArray[pa].jointPipes[j].pointB.z);
                                //-----------------------------------------------------------------------------------
                                twoFaceJointPipe.push(twoFacePipe);//store new combined pipe.
                                //remove the two joints that are were projected , to avoid overlap of geometry.
                                jointIndicesToDelete.push(j);
                                jointIndicesToDelete.push(k);

                                //This will enable the snapping of the polygon shape to the pipes after the pipes are genrated
                                //This adds a layer of robustness to the existing so that all elements match each other on how they should be placed and positioned.
                                //step snapPolyGonPointToPipes 1a of 2
                                if (snapPolyGonToPipes) {
                                    for (let pip = 0; pip < AddEditPolygonShapeTool.poligonInfoArray[pa].poligonPoints.length; pip++) {
                                        if (AddEditPolygonShapeTool.poligonInfoArray[pa].poligonPoints[pip] == AddEditPolygonShapeTool.poligonInfoArray[pa].jointPipes[j].pointA) {
                                            let pipesMap = new Map();
                                            let pipe2Points: Point3d[] = [];
                                            pipe2Points.push(twoFacePipe.pointA);
                                            pipe2Points.push(twoFacePipe.pointB);//push the start and end point of pipe.
                                            pipesMap.set("testPipe", pipe2Points);
                                            //get the projected point 
                                            let result = AddEditPolygonShapeTool.calcProjectedGratingPointToNearestPipe(AddEditPolygonShapeTool.poligonInfoArray[pa].poligonPoints[pip], pipesMap)
                                            AddEditPolygonShapeTool.poligonInfoArray[pa].poligonPoints[pip] = result[0].projectedPoint
                                        }
                                    }
                                }

                                matchsum = j+k;//helps avoid running into the same comparison sets.
                            }
                        }
                    }

                    //Joints that are detected to create the combined end to end joint pipe must be removed so that they dont overlap with the end to end pipe geometry..
                    for (let e = 0; e < edges.length; e++) {
                        if (edges[e].joints.length >= 2) {
                            for (let ji = 0; ji < edges[e].joints.length; ji++) {
                                if (AddEditPolygonShapeTool.poligonInfoArray[pa].jointPipes[j].pointA == edges[e].joints[ji].polygonPoint) {
                                    jointIndicesToDelete.push(j);
                                }
                            }
                        }
                    }
                }

                //------------------------------------------------------------------------------------------------------
                //Use the edges information to draw intersecting lines with the mount pipes with the relvant sides/edges of the polygon..
                let jointFPipes: Pipe[] = [];//array of end to end joint pipes.
                for (let e = 0; e < edges.length; e++)
                {
                    if (edges[e].joints.length >= 2)//Detection: if there are 2 detected joints that are parrlel/colinear to the edge, create a end to end pipe for it only and not for the others.(This will convert relvant joint pipes to endToendJointPipe)
                    {
                        if (pipesMap != undefined)//Handle Exception: this should exist if mountings data got succefully fetched from the backend wich will populate the map.
                        {
                            //Use the names detected and stored using onDataButtonDown()->getFaceLocationForGrating(). 
                            let names = AddEditPolygonShapeTool.poligonInfoArray[pa].faceLoacation;
                            //Pipe A
                            let pipePointA1 = pipesMap.get(names.first)![0];//use the names as key in the map accessed above to get which two pipes to use for the intersection test
                            let pipePointA2 = pipesMap.get(names.first)![1];
                            //Pipe B
                            let pipePointB1 = pipesMap.get(names.second)![0];
                            let pipePointB2 = pipesMap.get(names.second)![1];

                            let ePointA = edges[e].points[0];//use the polygons edge line to intersect with the relevant mountpipe
                            let ePointB = edges[e].points[1];

                            let intersect1 = AddEditPolygonShapeTool.calcIntersectionOfTwoLines2d(new Point2d(pipePointA1.x, pipePointA1.y), new Point2d(pipePointA2.x, pipePointA2.y), new Point2d(ePointA.x, ePointA.y), new Point2d(ePointB.x, ePointB.y));
                            let intersect2 = AddEditPolygonShapeTool.calcIntersectionOfTwoLines2d(new Point2d(pipePointB1.x, pipePointB1.y), new Point2d(pipePointB2.x, pipePointB2.y), new Point2d(ePointA.x, ePointA.y), new Point2d(ePointB.x, ePointB.y));

                            //calc the distance between the porjected point and the interseted point
                            let d1a = AddEditPolygonShapeTool.distance2d(intersect1.x,edges[e].joints[0].projectedPoint.x,intersect1.y,edges[e].joints[0].projectedPoint.y);
                            let d1b = AddEditPolygonShapeTool.distance2d(intersect1.x,edges[e].joints[1].projectedPoint.x,intersect1.y,edges[e].joints[1].projectedPoint.y)
                            let d2a = AddEditPolygonShapeTool.distance2d(intersect2.x,edges[e].joints[0].projectedPoint.x,intersect2.y,edges[e].joints[0].projectedPoint.y)
                            let d2b = AddEditPolygonShapeTool.distance2d(intersect2.x,edges[e].joints[1].projectedPoint.x,intersect2.y,edges[e].joints[1].projectedPoint.y)

                            // use the smallest distance  from intersection point and porjected point to know which 
                            // z value correspoinds to which 2d point generated from calcIntersectionOfTwoLines2d() above.
                            if (intersect1.x != 0 && intersect2.x != 0) {
                                let endToendjointPipe: Pipe = new Pipe();
                                //use the z comp  from the projecteion point generated earlier.
                                // To best match the location of the new end to end line points.
                                d1a < d1b ? endToendjointPipe.pointA = new Point3d(intersect1.x, intersect1.y, edges[e].joints[0].projectedPoint.z) ://else
                                            endToendjointPipe.pointA = new Point3d(intersect1.x, intersect1.y, edges[e].joints[1].projectedPoint.z)                      
                                d2a < d2b ? endToendjointPipe.pointB = new Point3d(intersect2.x, intersect2.y, edges[e].joints[0].projectedPoint.z) ://else
                                            endToendjointPipe.pointB = new Point3d(intersect2.x, intersect2.y, edges[e].joints[1].projectedPoint.z);
                                
                                jointFPipes.push(endToendjointPipe);

                                //This will enable the snapping of the polygon shape to the pipes after the pipes are genrated
                                //This adds a layer of robustness to the existing so that all elements match each other on how they should be placed and positioned.
                                //step snapPolyGonToPipes 1b of 2
                                if (snapPolyGonToPipes) {
                                    //translate the points to projected position on there relevant pipes.
                                    for (let pip = 0; pip < AddEditPolygonShapeTool.poligonInfoArray[pa].poligonPoints.length; pip++) {
                                        //Match the polygon point to its edge point and if it matches do the operation of end to end pipe projection only to those points.
                                        if (AddEditPolygonShapeTool.poligonInfoArray[pa].poligonPoints[pip] == edges[e].points[0] || AddEditPolygonShapeTool.poligonInfoArray[pa].poligonPoints[pip] == edges[e].points[1]) {
                                            let pipesMap = new Map();
                                            let pipe2Points: Point3d[] = [];
                                            pipe2Points.push(endToendjointPipe.pointA);
                                            pipe2Points.push(endToendjointPipe.pointB);//push the start and end point of pipe.
                                            pipesMap.set("testPipe", pipe2Points);
                                            //get the projected point 
                                            let result = AddEditPolygonShapeTool.calcProjectedGratingPointToNearestPipe(AddEditPolygonShapeTool.poligonInfoArray[pa].poligonPoints[pip], pipesMap)
                                            AddEditPolygonShapeTool.poligonInfoArray[pa].poligonPoints[pip] = result[0].projectedPoint//replace the orignal point with the projected point;
                                        }
                                    }
                                }
                            }
                        }
                    }    
                }

                //This will enable the snapping of the polygon shape to the pipes after the pipes are genrated
                //This adds a layer of robustness to the existing so that all elements match each other on how they should be placed and positioned.
                //step snapPolyGonPointToPipes 2 of 2
                if (snapPolyGonToPipes) {
                    //Empty the array for a new set of data
                    AddEditPolygonShapeTool.poligonInfoArray[pa].jointPipes = [];
                    AddEditPolygonShapeTool.poligonInfoArray[pa].edgePipes = [];
                    //this will recreate the edge pipes so that they also match the newly updated polygon in step 1 of 2
                    for (let pp = 0; pp < AddEditPolygonShapeTool.poligonInfoArray[pa].poligonPoints.length; pp++) {
                        let ppo = AddEditPolygonShapeTool.poligonInfoArray[pa].poligonPoints[pp];//polygonPoint.
                        let ppp = AddEditPolygonShapeTool.calcProjectedGratingPointToNearestPipe(AddEditPolygonShapeTool.poligonInfoArray[pa].poligonPoints[pp], pipesMap!);//projected Point

                        let epi = new Pipe();
                        epi.pointA = ppo;
                        if (pp + 1 < AddEditPolygonShapeTool.poligonInfoArray[pa].poligonPoints.length) {
                            epi.pointB = AddEditPolygonShapeTool.poligonInfoArray[pa].poligonPoints[pp + 1];
                        }
                        if (pp == AddEditPolygonShapeTool.poligonInfoArray[pa].poligonPoints.length - 1) {
                            epi.pointA = AddEditPolygonShapeTool.poligonInfoArray[pa].poligonPoints[AddEditPolygonShapeTool.poligonInfoArray[pa].poligonPoints.length - 1];
                            epi.pointB = AddEditPolygonShapeTool.poligonInfoArray[pa].poligonPoints[0];
                        }

                        AddEditPolygonShapeTool.poligonInfoArray[pa].edgePipes.push(epi);//push the poligon points to form edge supporting pipes

                        let jpi = new Pipe();
                        jpi.pointA = ppo;
                        jpi.pointB = ppp[0].projectedPoint;
                        AddEditPolygonShapeTool.poligonInfoArray[pa].jointPipes.push(jpi);//orignal point.

                        if (ppp.length == 2) {
                            let jpi = new Pipe();
                            jpi.pointA = ppo;
                            jpi.pointB = ppp[1].projectedPoint;
                            AddEditPolygonShapeTool.poligonInfoArray[pa].jointPipes.push(jpi);
                        };
                    }
                }


                //use the twoFaceJointPipe info to add to existing jointFPipes pipes for visualisation.
                for (let j = 0; j < twoFaceJointPipe.length; j++) {
                    jointFPipes.push(twoFaceJointPipe[j]);
                }

                //---Delete overlapping pipe data.----
                //Delete the detected joints above and keep the others.               
                //deleting properly increase complexity slightly as the splice mechanism rearranges the indices every splice so the search needs to start from the top again to be done correctly.
                let tempBuffer = JSON.parse(JSON.stringify(AddEditPolygonShapeTool.poligonInfoArray[pa].jointPipes));//create  deep copy.
                for (let j = 0; j < AddEditPolygonShapeTool.poligonInfoArray[pa].jointPipes.length; j++) {
                    for (let d = 0; d < jointIndicesToDelete.length; d++) {
                        if (tempBuffer[jointIndicesToDelete[d]].pointA[0] == AddEditPolygonShapeTool.poligonInfoArray[pa].jointPipes[j].pointA.x) {
                            AddEditPolygonShapeTool.poligonInfoArray[pa].jointPipes[j].pointA = new Point3d(-1);//mark the values of the indices to delete.
                            AddEditPolygonShapeTool.poligonInfoArray[pa].jointPipes[j].pointB = new Point3d(-1);
                        }
                    }
                }
                //Delete the joint pipes that have been replaced
                for (let j = 0; j < AddEditPolygonShapeTool.poligonInfoArray[pa].jointPipes.length; j++) {
                    if (AddEditPolygonShapeTool.poligonInfoArray[pa].jointPipes[j].pointA.x == -1) {//delete the marked indices.
                        AddEditPolygonShapeTool.poligonInfoArray[pa].jointPipes.splice(j, 1);
                        j = -1;//move the iterator index to -1 so that it  starts from 0 again.
                    }
                }
                //Delete the Edge pipes that have been replaced.
                for (let ep = 0; ep < AddEditPolygonShapeTool.poligonInfoArray[pa].edgePipes.length; ep++) {
                    for (let jf = 0; jf < jointFPipes.length; jf++) {
     
                            let joA = AddEditPolygonShapeTool.poligonInfoArray[pa].edgePipes[ep].pointA;
                            let jPA = AddEditPolygonShapeTool.poligonInfoArray[pa].edgePipes[ep].pointB;
                            let edir = new Vector3d(jPA.x - joA.x, jPA.y - joA.y, 0).normalize()!;//jo->jp dir vector.

                            let joAF = jointFPipes[jf].pointA;
                            let jPAF = jointFPipes[jf].pointB;
                            let fedir = new Vector3d(jPAF.x - joAF.x, jPAF.y - joAF.y, 0).normalize()!;//jo->jp dir vector.

                            let scalar = edir.dotProduct(fedir);
                            if (scalar < 0) { scalar *= -1; }//make value unsigned

                        if (scalar > 0.99) {//if the dot is more than 1 then the value is in the same direction and hence should be removed. As it will be replaced by endToendJointPipes.
                                AddEditPolygonShapeTool.poligonInfoArray[pa].edgePipes.splice(ep, 1);//remove the index element from the array.
                                ep = 0;//restart the loop as the array has been resized.
                            }                     
                    }
                }
                //-------------------------------
                AddEditPolygonShapeTool.poligonInfoArray[pa].endToendJointPipes = jointFPipes;
            }
        }

        AddEditPolygonShapeTool.createPoligonFromPolygonInfoArray(AddEditPolygonShapeTool.poligonInfoArray);
        //--Delete pipe data in decorator to avoid overlapping geometries 
        AddEditPolygonShapeTool.polygonDecorator.clearJointGeometry();
        AddEditPolygonShapeTool.polygonDecorator.clearGratingSupportGeometry();

        //create the gemetries and Visualise the pipes for the entire data.
        //Goes over the data stored in PolgoninfoArray and loads up the pipes that are the supporting members.
        for (let p = 0; p < AddEditPolygonShapeTool.poligonInfoArray.length; p++) {
            //--//Create Joint and Edge pipes in the decorator.
            AddEditPolygonShapeTool.createPipesFromPipeData(AddEditPolygonShapeTool.poligonInfoArray[p].endToendJointPipes, ColorDef.from(224, 224, 0));//create Full length pipes for grating joints.
            AddEditPolygonShapeTool.createPipesFromPipeData(AddEditPolygonShapeTool.poligonInfoArray[p].jointPipes, ColorDef.from(224,0 , 224));//create pipes for grating joints.
            AddEditPolygonShapeTool.createPipesFromPipeData(AddEditPolygonShapeTool.poligonInfoArray[p].edgePipes, ColorDef.from(0, 224, 0));//create pipes for supporting edge members.
        }

    }

    //------------------------------------------------------------------------------------
    // ╔═╗┌─┐┌─┐┌─┐  ┬  ┌─┐┌─┐┌─┐┌┬┐┬┌─┐┌┐┌  ╔╦╗┌─┐┌┬┐┌─┐┌─┐┌┬┐┬┌─┐┌┐┌
    // ╠╣ ├─┤│  ├┤   │  │ ││  ├─┤ │ ││ ││││   ║║├┤  │ ├┤ │   │ ││ ││││
    // ╚  ┴ ┴└─┘└─┘  ┴─┘└─┘└─┘┴ ┴ ┴ ┴└─┘┘└┘  ═╩╝└─┘ ┴ └─┘└─┘ ┴ ┴└─┘┘└┘
    //------------------------------------------------------------------------------------
    /* Detect the location for the grating , by ascertaining between which 2 pipe segments 
     * does the grating lie. Require mountings data to work.
     * Return a string with names of the 2 pipes.
     * See : gratingtool.pptx spec sheet in attachements tab: https://dev.azure.com/bentleycs/TowerSight/_workitems/edit/883820
     */
    private static getFaceLocationForGrating(points: Point3d[]) {
        let ple: number = 0;
        ple = AddEditPolygonShapeTool.getBestProbableElivationOfPoints(points, 1)//the detected probable elevation of polygonPoints
        let northDir: Vector3d = new Vector3d(0, 1, 0);//(UTM)north is always in the Y direction
        let normalDir: Vector3d = new Vector3d(0, 0, 1);

        let top: string = "";
        let bottom: string = "";
        let mountName: string = "";

        let mdata = AddEditPolygonShapeTool.getMountPipesForSameElevation(ple)//get the pipes for the detected elevation.
        let pipesMap = mdata.pipes;//get the relevant pipe info for the same elevation
        mountName = mdata.mount

        let sectorMidPointBuffer = new Map();
        if (pipesMap != undefined) {
            let planePoints: Point3d[] = [];
            for (const [key, value] of pipesMap!) {
                planePoints.push(value[0]);
                planePoints.push(value[1]);
                let ret: Point3d = new Point3d(0);
                ret.x = (value[0].x + (value[1].x)) * 0.5;
                ret.y = (value[0].y + (value[1].y)) * 0.5;
                ret.z = (value[0].z + (value[1].z)) * 0.5;
                let midpoint = ret;
                sectorMidPointBuffer.set(key, midpoint);
            }
            let poc = AddEditPolygonShapeTool.calcCenteroid(points);
            let pic = AddEditPolygonShapeTool.calcCenteroid(planePoints);
            let subjectDir = new Vector3d(poc.x - pic.x, poc.y - pic.y, poc.z - pic.z).normalize();

            //let gratingAngle = AddEditPolygonShapeTool.calcAngleBetween3DVectors(northDir, subjectDir!);//angle that the grating centroid and the north make from the mount center.
            let gratingAngle = AddEditPolygonShapeTool.calAngleBetweenVectorsOnTheSamePlane(northDir, subjectDir!, normalDir);

            let sectorAngleBuffer = new Map();
            sectorAngleBuffer.set("SubjectAngle", gratingAngle)
            for (const [key, value] of sectorMidPointBuffer!) {
                let midPDir = new Vector3d(value.x - pic.x, value.y - pic.y, value.z - pic.z).normalize();
                //let sectorAngle = AddEditPolygonShapeTool.calcAngleBetween3DVectors(northDir, midPDir!);
                let sectorAngle = AddEditPolygonShapeTool.calAngleBetweenVectorsOnTheSamePlane(northDir, midPDir!, normalDir);
                sectorAngleBuffer.set(key, sectorAngle);
            }
            const sectorAngleBufferSorted = new Map([...sectorAngleBuffer.entries()].sort((a, b) => b[1] - a[1]));//sort the map by value

            let count: number = 0;
            for (const [key, value] of sectorAngleBufferSorted!) 
            {//Find the keys in between which the value lies.  [top] grating/subjectAngle [bottom]  
                if (key == "SubjectAngle") {
                    top = Array.from(sectorAngleBufferSorted.keys())[count - 1];
                    bottom = Array.from(sectorAngleBufferSorted.keys())[count + 1];
                }
                if (key == "SubjectAngle" && count == 0) {
                    top = Array.from(sectorAngleBufferSorted.keys())[sectorAngleBufferSorted.size - 1];
                    bottom = Array.from(sectorAngleBufferSorted.keys())[count + 1];
                }
                if (key == "SubjectAngle" && count == sectorAngleBufferSorted.size - 1) {
                    top = Array.from(sectorAngleBufferSorted.keys())[count - 1];
                    bottom = Array.from(sectorAngleBufferSorted.keys())[0];
                }
                count++;
            }
        }

        IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Info, top + " - " + bottom + " - " + mountName, "", OutputMessageType.Toast));

        return {// Output the two pipe / faceloaction names between which the grating exists.
            first: top,
            second: bottom,
            mount: mountName
        };
    }

    //------------------------------------------------------------------------------------
    // ╔╦╗┌─┐┌┬┐┬ ┬  ╦ ╦┌┬┐┬┬  ┌─┐
    // ║║║├─┤ │ ├─┤  ║ ║ │ ││  └─┐
    // ╩ ╩┴ ┴ ┴ ┴ ┴  ╚═╝ ┴ ┴┴─┘└─┘
    //------------------------------------------------------------------------------------
    /* Utilizes Plane Points (3 points) and polygon/grating or any point set.
     * Projects the point set to the plane calculated from the 3 plane points.
     * ie: moves the points set orthogonally to the planes surface.
     * See : gratingtool.pptx spec sheet in attachements tab: https://dev.azure.com/bentleycs/TowerSight/_workitems/edit/883820
    */
    private static projectPointsToPlane(planePoints: Point3d[], points: Point3d[]) {
        let av = new Point3d(planePoints[1].x - planePoints[0].x, planePoints[1].y - planePoints[0].y, planePoints[1].z - planePoints[0].z);
        let bv = new Point3d(planePoints[2].x - planePoints[0].x, planePoints[2].y - planePoints[0].y, planePoints[2].z - planePoints[0].z);
        //let n = Vector3d.createCrossProduct(bv.x,bv.y,bv.z, av.x,av.y,av.z).normalize();//normal to the plane inverted
        let n = Vector3d.createCrossProduct(av.x, av.y, av.z, bv.x, bv.y, bv.z).normalize();//normal to the plane

        let newPoints: Point3d[] = [];

        //let normal: any;
        let u1 = Vector3d.createCrossProductToPoints(planePoints[2], planePoints[1], planePoints[0]).normalize();

        let anyPlanePoint = planePoints[2];//pick any one point that lies on the plane.

        for (let i = 0; i < points.length; i++) {

            let v = points[i].minus(anyPlanePoint);//dir from planePoint to point
            let dist = (v.x * n!.x + v.y * n!.y + v.z * n!.z);//distance from surface
            let nP = new Point3d((points[i].x - (n!.x * dist)), (points[i].y - (n!.y * dist)), (points[i].z - (n!.z * dist)));//translation to surface in dirtection of normal
            newPoints.push(nP);
        }
        return newPoints;
    }
    //------------------------------------------------------------------------------------
    /*
     * Detects the intersection point of two lines and there extended vectors in 2d.
     * @params : Lines1 -> a1 __ a2 | Line2 -> b1 __ b2.
     * Add elevation z to the output to match data. (In this tool we get Z from the 3d point projection with calcProjectedGratingPointToNearestPipe())
     */
    private static calcIntersectionOfTwoLines2d(a1: Point2d, a2: Point2d, b1: Point2d, b2: Point2d): Point2d {
        // Check if none of the lines are of length 0
        if ((a1.x === a2.x && a1.y === a2.y) || (b1.x === b2.x && b1.y === b2.y)) { return new Point2d(0); }

        const denominator = ((b2.y - b1.y) * (a2.x - a1.x) - (b2.x - b1.x) * (a2.y - a1.y))
        // Lines are parallel will not intersect
        if (denominator === 0) { return new Point2d(0); }

        let ua = ((b2.x - b1.x) * (a1.y - b1.y) - (b2.y - b1.y) * (a1.x - b1.x)) / denominator
        //let ub = ((a2.x - a1.x) * (a1.y - b1.y) - (a2.y - a1.y) * (a1.x - b1.x)) / denominator
        // Return a object with the x and y coordinates of the intersection
        let x = a1.x + ua * (a2.x - a1.x)
        let y = a1.y + ua * (a2.y - a1.y)

        return new Point2d(x, y);
    }
    //------------------------------------------------------------------------------------
    /* Project point p on to line ab;
     * This is a 2d operation so z is not in use. 
     * Add elevation z to the output to match data.
     *
     * Not in use.
     */ 
    private static calcProjectPointOnLine2D(p: Point2d, a : Point2d, b: Point2d) {//project in 2d space. where z is up/elevation
        let atob = { x: b.x - a.x, y: b.y - a.y };
        let atop = { x: p.x - a.x, y: p.y - a.y };
        let len = atob.x * atob.x + atob.y * atob.y;
        let dot = atop.x * atob.x + atop.y * atob.y;
        let t = Math.min(1, Math.max(0, dot / len));

        dot = (b.x - a.x) * (p.y - a.y) - (b.y - a.y) * (p.x - a.x);

        return {
            point: {
                x: a.x + atob.x * t,
                y: a.y + atob.y * t
            },
            left: dot < 1,
            dot: dot,
            t: t
        };
    }
    //------------------------------------------------------------------------------------
    /* Calulate the projection of a point onto a line.
     * Uses vectors and distance calulations to tranform a point onto antother vector/line
     * 
     * Not in use, as we are using non-orthogonal projection.
     * See : gratingtool.pptx spec sheet in attachements tab: https://dev.azure.com/bentleycs/TowerSight/_workitems/edit/883820
     *     : calcProjectedGratingPointToNearestPipe()
     */ 
    private static calcProjectPointOnLine3D(linePointA: Point3d, linePointB: Point3d, subject: Point3d) : Point3d {

        let m1 = new Vector3d(subject.x - linePointA.x, subject.y - linePointA.y, subject.z - linePointA.z);
        let m2 = new Vector3d(subject.x - linePointB.x, subject.y - linePointB.y, subject.z - linePointB.z);
        let pa = new Vector3d(m1.x, m1.y, m1.z);
        let pb = new Vector3d(m2.x, m2.y, m2.z);

        let dot1 = pa!.x * pb!.x + pa!.y * pb!.y + pa!.z * pb!.z;
        let dot2 = pb!.x * pb!.x + pb!.y * pb!.y + pb!.z * pb!.z;

        let div = dot1;// / dot2;
        let mul = new Vector3d(pb!.x * div, pb!.y * div, pb!.z * div);
        //return new Point3d(subject.x + mul!.x, subject.y + mul!.y, subject.z + mul.z);
        return new Point3d(subject.x + mul.x, subject.y + mul.y, subject.z + mul.z);
    }
    //------------------------------------------------------------------------------------
    /* Calc the Centroid for the points array passed in.
    */
    private static calcCenteroid(points: Point3d[]): Point3d {
        let centroid = new Point3d(0);
        for (let i = 0; i < points.length; i++) {
            centroid.x += points[i].x;
            centroid.y += points[i].y;
            centroid.z += points[i].z;
        }
        centroid.x = centroid.x / points.length;
        centroid.y = centroid.y / points.length;
        centroid.z = centroid.z / points.length;
        return centroid;
    }
    //------------------------------------------------------------------------------------
    /* Calculate the positive relative angle between two vectors. 
     * Does not take into account the winding order or direction
     * Non determinant based solution. Not in use atm.
     *
     * Not in use,
     */
    private static calcAngleBetween3DVectors(v1: Vector3d, v2: Vector3d): number {
        let dot = v1.x * v2.x + v1.y * v2.y + v1.z * v2.z;
        let lSq1 = v1.x * v1.x + v1.y * v1.y + v1.z * v1.z;
        let lSq2 = v2.x * v2.x + v2.y * v2.y + v2.z * v2.z;
        let angle = Math.acos(dot / Math.sqrt(lSq1 * lSq2));
        let angleD = angle * (180 / Math.PI);
        return angleD;
    }
    //------------------------------------------------------------------------------------
    /*
     * Calc the Normal specific determinant base angle between two vectors.
     * The output also indicates which side the vector lies to the primary subject vector.
     * Require a fixed normal 0,0,1  with z as up ,as input. (This function increments angles degrees in a clockwise direction). 
     */
    private static calAngleBetweenVectorsOnTheSamePlane(v1: Vector3d, v2: Vector3d, normal: Vector3d): number {
        let dot = v1.x * v2.x + v1.y * v2.y + v1.z * v2.z;//scalar product.
        let det = v1.x * v2.y * normal.z + v2.x * normal.y * v1.z + normal.x * v1.y * v2.z - v1.z * v2.y * normal.x - v2.z * normal.y * v1.x - normal.z * v1.y * v2.x;//determinant
        let angle = Math.atan2(det, dot);
        let angleD = -(angle * (180 / Math.PI));//Radians to degrees + change winding order by flipping positive to negative;
        if (angleD < 0) {//convert the degrees from signed 1->180->-180->-1 to unsigned 1->360
            angleD = angleD * -1;//make value possitve if ngative.
            angleD = 360 - angleD;
        }
        return angleD;
    }
    /*
     * Calulated distance between two 2d points.
     * Due to some discarpancies in ruslts from Point2d.distance() function; 
     * Using this.
     */ 
    private static distance2d(x1: number, x2: number, y1: number, y2:number) : number {
        let a = x1 - x2;
        let b = y1 - y2;
        //pythogoras
        return  Math.sqrt(a * a + b * b);
    }
    //---------------------------------------------------------------------------------------
    // ╦┌┐┌┌─┐┌┬┐┌─┐┌┐┌┌┬┐┬┌─┐┌┬┐┌─┐  ╔═╗┌─┐┌─┐┌┬┐┌─┐┌┬┐┬─┐┬ ┬
    // ║│││└─┐ │ ├─┤│││ │ │├─┤ │ ├┤   ║ ╦├┤ │ ││││├┤  │ ├┬┘└┬┘
    // ╩┘└┘└─┘ ┴ ┴ ┴┘└┘ ┴ ┴┴ ┴ ┴ └─┘  ╚═╝└─┘└─┘┴ ┴└─┘ ┴ ┴└─ ┴
    //---------------------------------------------------------------------------------------
    /* Create Sphere on all the points that are collected for the polygons/gratings.
     * This will highlight and visualize the points/sets of points that will be used to create the polygon/grating.
     */
    public static addSphereHelpersOnPolygonByName(selectedName: string)
    {
        let name = selectedName;
        //let name = PolygonShapeDecorator.selectedGeometry?.name;

        //check if decorator exists , needs to be done everything adding a new geometry. //**
        let isDecoratorExists: boolean = false;
        for (const dec of IModelApp.viewManager.decorators) {
            if (dec.constructor.name.includes("PolygonShapeDecorator")) {
                isDecoratorExists = true;
                AddEditPolygonShapeTool.polygonDecorator = (dec as PolygonShapeDecorator);
            }
        }

        //clear the scene of spheres once befroe recreating
        AddEditPolygonShapeTool.polygonDecorator?.deleteSpheresMarkerSFromDecorator();

        //Recreate sphere helpers
        for (let i = 0; i < AddEditPolygonShapeTool.poligonInfoArray.length; i++) {
            if (name == AddEditPolygonShapeTool.poligonInfoArray[i].name) {
                for (let j = 0; j < AddEditPolygonShapeTool.poligonInfoArray[i].poligonPoints.length; j++) {
                    //create a sphere geometry on Point position
                    AddEditPolygonShapeTool.polygonDecorator.createSphereOnPointPosition(AddEditPolygonShapeTool.poligonInfoArray[i].poligonPoints[j], 0.05, ColorDef.from(200, 100, 100), AddEditPolygonShapeTool.points.length.toString(), "");
                }
            }
        }
        //if decorator exits then dont add it to the ViewManger just invalidate cache and request for a re-draw/needsUpdate for the decorator.//**
        isDecoratorExists == false ? IModelApp.viewManager.addDecorator(AddEditPolygonShapeTool.polygonDecorator) ://else
            IModelApp.viewManager.invalidateCachedDecorationsAllViews(AddEditPolygonShapeTool.polygonDecorator);
    }
    //----------------------------------------------------------------------------------------------------------
    /*
     * Create spheres on points provided
     * Currently only used to visualize & debug data.
     *
     * Not in use
     */
    private static createSpheresOnPoints(points : Point3d[]) {
        //let name = PolygonShapeDecorator.selectedGeometry?.name;

        //check if decorator exists , needs to be done every time adding a new geometry. //**
        let isDecoratorExists: boolean = false;
        for (const dec of IModelApp.viewManager.decorators) {
            if (dec.constructor.name.includes("PolygonShapeDecorator")) {
                isDecoratorExists = true;
                AddEditPolygonShapeTool.polygonDecorator = (dec as PolygonShapeDecorator);
            }
        }

        //clear the scene of spheres once before recreating
        AddEditPolygonShapeTool.polygonDecorator?.deleteSpheresMarkerSFromDecorator();

        //Recreate sphere helpers
        for (let j = 0; j < points.length; j++)
        {
            //create a sphere geometry on Point position
            AddEditPolygonShapeTool.polygonDecorator.createSphereOnPointPosition(points[j], 1.02, ColorDef.from(200, 225, 100), AddEditPolygonShapeTool.points.length.toString(), "");
        }
        //if decorator exits then dont add it to the ViewManger just invalidate cache and request for a re-draw/needsUpdate for the decorator.//**
        isDecoratorExists == false ? IModelApp.viewManager.addDecorator(AddEditPolygonShapeTool.polygonDecorator) : IModelApp.viewManager.invalidateCachedDecorationsAllViews(AddEditPolygonShapeTool.polygonDecorator);
    }
    //------------------------------------------------------------------------------------
    /*
     * Uses the populated Polygon info array to populate the scene with Polygon/Grid objects.
     * Note: since this will recreate only the polygon will be visible all helpers and markers will disappear.
     * Check function createPolygonFromPoints() and the needUpdate flag in PolygonShapeDecorator.
     * 
     * Note : Always keep this first as it clears all poly before regen of geometries.See function.
     */
    public static createPoligonFromPolygonInfoArray(polygonInfoArray: PolygonInfo[]) {

        //check if the same deocrator exist in ViewManager
        let isDecoratorExists: boolean = false;
        for (const dec of IModelApp.viewManager.decorators) {
            if (dec.constructor.name.includes("PolygonShapeDecorator")) {
                isDecoratorExists = true;
                AddEditPolygonShapeTool.polygonDecorator = (dec as PolygonShapeDecorator);//copy the existing reference to the local variable So that the orgnal is reused.
            }
        }

        if (polygonInfoArray.length > 0) {
            AddEditPolygonShapeTool.polygonDecorator.clearGeometry();//clear geometry before recreating,updates the dimension based on changes in data.and avoid overlapping geometry.

            for (let i = 0; i < polygonInfoArray.length; i++) {
                AddEditPolygonShapeTool.polygonDecorator.createPolygonFromPoints(polygonInfoArray[i].poligonPoints, ColorDef.from(214, 125, 62), polygonInfoArray[i].name, "");
            }
            //if decorator exits then dont add it to the ViewManger just invalidate cache and request for a re-draw for the decorator.else it throws error.
            isDecoratorExists == false ? IModelApp.viewManager.addDecorator(AddEditPolygonShapeTool.polygonDecorator) : IModelApp.viewManager.invalidateCachedDecorationsAllViews(AddEditPolygonShapeTool.polygonDecorator);
        }
    }
    //----------------------------------------------------------------------------------------------------------
    /* 
     * Create pipe Decorators using the Pipe class array .
     */ 
    private static createPipesFromPipeData(pipes: Pipe[], color: ColorDef) {

        //check if decorator exists , needs to be done every time adding a new geometry. //**
        let isDecoratorExists: boolean = false;
        for (const dec of IModelApp.viewManager.decorators) {
            if (dec.constructor.name.includes("PolygonShapeDecorator")) {
                isDecoratorExists = true;
                AddEditPolygonShapeTool.polygonDecorator = (dec as PolygonShapeDecorator);
            }
        }

        for (let i = 0; i < pipes.length; i++) {
            AddEditPolygonShapeTool.polygonDecorator.createPipeBetweenPoints(pipes[i].pointA, pipes[i].pointB, color, "jointline" + i.toString(), "");
        }

        //if decorator exits then dont add it to the ViewManger just invalidate cache and request for a re-draw/needsUpdate for the decorator.
        isDecoratorExists == false ? IModelApp.viewManager.addDecorator(AddEditPolygonShapeTool.polygonDecorator) : //else
            IModelApp.viewManager.invalidateCachedDecorationsAllViews(AddEditPolygonShapeTool.polygonDecorator);
    }
    //------------------------------------------------------------------------------------
    /*
     * Creates a Conebased Pipe between points in an array
     * uses points in sets of two(stride of 2) to create a pipe, pipe1 = n->n+1; pipe2 = n+2->n+3...
     *
     * Not in use
     */
    private static createSeperatePipesBetweenPoints(points: Point3d[],color : ColorDef) {//Not in use
        //let name = PolygonShapeDecorator.selectedGeometry?.name;

        //check if decorator exists , needs to be done every time adding a new geometry. //**
        let isDecoratorExists: boolean = false;
        for (const dec of IModelApp.viewManager.decorators) {
            if (dec.constructor.name.includes("PolygonShapeDecorator")) {
                isDecoratorExists = true;
                AddEditPolygonShapeTool.polygonDecorator = (dec as PolygonShapeDecorator);
            }
        }
        
        for (let i = 0; i < points.length - 1; i += 2) {
            AddEditPolygonShapeTool.polygonDecorator.createPipeBetweenPoints(points[i], points[i + 1], color, "jointline" + i.toString(), "");
        }

        //if decorator exits then dont add it to the ViewManger just invalidate cache and request for a re-draw/needsUpdate for the decorator.
        isDecoratorExists == false ? IModelApp.viewManager.addDecorator(AddEditPolygonShapeTool.polygonDecorator) ://else
            IModelApp.viewManager.invalidateCachedDecorationsAllViews(AddEditPolygonShapeTool.polygonDecorator);
    }
    //----------------------------------------------------------------------------------------------------------
    /*
     * Creates a Conebased Pipe between points in an array
     * Creates the pipes sequentialy with no stride. ie : pip1 = n->n+1; pipe2 = n+1->n+2...
     *
     * Not in use
     */
    private static createSequentialLinesBetweenPoints(points: Point3d[], color: ColorDef) {
        //let name = PolygonShapeDecorator.selectedGeometry?.name;

        //check if decorator exists , needs to be done every time adding a new geometry. //**
        let isDecoratorExists: boolean = false;
        for (const dec of IModelApp.viewManager.decorators) {
            if (dec.constructor.name.includes("PolygonShapeDecorator")) {
                isDecoratorExists = true;
                AddEditPolygonShapeTool.polygonDecorator = (dec as PolygonShapeDecorator);
            }
        }

        AddEditPolygonShapeTool.polygonDecorator.clearGratingSupportGeometry();//clear geometry before recreating,updates the dimension based on changes in data.and avoid overlapping geometry.

        for (let i = 0; i < points.length - 1; i++) {
            AddEditPolygonShapeTool.polygonDecorator.createPipeBetweenPoints(points[i], points[i + 1], color, "gratingSupport" + i.toString(), "");
        }
        //create pipe one more time between the last and first index to close the loop.
        AddEditPolygonShapeTool.polygonDecorator.createPipeBetweenPoints(points[points.length - 1], points[0], color, "gratingSupport" + AddEditPolygonShapeTool.points.length.toString(), "");

        //if decorator exits then dont add it to the ViewManger just invalidate cache and request for a re-draw/needsUpdate for the decorator.//**
        isDecoratorExists == false ? IModelApp.viewManager.addDecorator(AddEditPolygonShapeTool.polygonDecorator) ://else
            IModelApp.viewManager.invalidateCachedDecorationsAllViews(AddEditPolygonShapeTool.polygonDecorator);
    }
    //------------------------------------------------------------------------------------
    /* Runs withing the OnButtonDownEvnt and will create a custom marker at the point returned.
    */
    private static addMarkerOnPoint(point: Point3d) {

        let isDecoratorExists: boolean = false;
        for (const dec of IModelApp.viewManager.decorators) {
            if (dec.constructor.name.includes("DefectsDecorator")) {
                isDecoratorExists = true;
                AddEditPolygonShapeTool.polygonDecorator = (dec as PolygonShapeDecorator);
            }
        }

        AddEditPolygonShapeTool.polygonDecorator!.addMarkerOnPoint(point);//create the defect marker

        //Add a decorator if does not exist , and if it does invalidate to update scene.
        isDecoratorExists == false ? IModelApp.viewManager.addDecorator(AddEditPolygonShapeTool.polygonDecorator!) ://else
            IModelApp.viewManager.invalidateCachedDecorationsAllViews(AddEditPolygonShapeTool.polygonDecorator!);
    }
    //------------------------------------------------------------------------------------
    //  ╔═╗┌─┐┬  ┬    ┌┐ ┌─┐┌─┐┬┌─┌─┐
    //  ║  ├─┤│  │    ├┴┐├─┤│  ├┴┐└─┐
    //  ╚═╝┴ ┴┴─┘┴─┘  └─┘┴ ┴└─┘┴ ┴└─┘
    //------------------------------------------------------------------------------------
    /* Gets called on initialisation of this tool. 'ModelApp.tools.run(SelectionTool.toolId)'.
    */
    public override run(): Promise<boolean> {
        super.run();
        const { toolAdmin, viewManager } = IModelApp;

        if (!this.isCompatibleViewport(viewManager.selectedView, false) || !toolAdmin.onInstallTool(this)) {
            return Promise.resolve(false);
        }

        toolAdmin.startPrimitiveTool(this);
        toolAdmin.onPostInstallTool(this);

        AddEditPolygonShapeTool.importMoutingsData();
        AddEditPolygonShapeTool.polygonDecorator = new PolygonShapeDecorator();
        AddEditPolygonShapeTool.polygonDecorator.isEditable = true;

        return Promise.resolve(true);
    }
    //------------------------------------------------------------------------------------
    /* Runs when the tool is rerun after being instanciated once.
     */
    public onRestartTool(): Promise<void> {
        const tool = new AddEditPolygonShapeTool();

        AddEditPolygonShapeTool.isEditMode = false;

        if (!tool.run())
            this.exitTool();
        return Promise.resolve();
    }
    //------------------------------------------------------------------------------------
    /* Callback event from dom. See: OnonDataButtonDown()
     */
    private onMouseClickUp = (event) => {
        event.preventDefault();

        if (event.button == 2) {//2 signifies the right click on mouse
            IModelApp.tools.run(SelectionTool.toolId);
            //recreate spheres helpers to update with the new points, every time the tool is deselected and object looses focus.
            AddEditPolygonShapeTool.addSphereHelpersOnPolygonByName(PolygonShapeDecorator.lastSelectedPolygonShape?.name);
        }
    }
    //------------------------------------------------------------------------------------
    /*
     * Override Gets called when the Tool is run() from SampleToolsWidget.tsx.
     * does object click +  surface ray intersection test
     * _ev return data about the intersecting object and point of intersection.
     */ 
    public override async onDataButtonDown(_ev: BeButtonEvent): Promise<EventHandled> {

        let div = document.getElementsByClassName("imodeljs-vp")[0];
        div?.addEventListener('mouseup', this.onMouseClickUp);
        /*
         Check if the same decorator exist in ViewManager
         if it exists then mark the flag to true so that we avoid reading the same decorator to the ViewManager
         as well as update the the local Decorator variable to the one existing in the viewmanager,
         making sure to always use the same decorator reference.
        */
        let isPDecoratorExists: boolean = false;
        for (const dec of IModelApp.viewManager.decorators) {
            if (dec.constructor.name.includes("PolygonShapeDecorator")) {
                isPDecoratorExists = true;
                AddEditPolygonShapeTool.polygonDecorator = (dec as PolygonShapeDecorator);
            }
        }

        //Edit mode is set when User click on the Sphere helpers to start editing that point
        //see : PolygonshapeDecorator -> onDecorationButtonEvent().
        if (AddEditPolygonShapeTool.isEditMode == false)//if the creation mode is active. Edit mode is false.
        {
            let pointsStride = AddEditPolygonShapeTool._pointCountPerPolygon;//value should not be less than 3 cant create polygon with lesser value
            if (pointsStride < 3) { pointsStride = 3; 
                IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Info, "Point Count too low should be larger than 3", "", OutputMessageType.Toast));
            }
            AddEditPolygonShapeTool._pointCountPerPolygon = pointsStride;

            AddEditPolygonShapeTool.points.push(_ev.point) //store the 3 or more than 3 points and use it to create a polygon, using the stride value.
            //AddEditPolygonShapeTool.polygonDecorator.addMarkers(_ev.point);//not in use

            //create a spere geometry on Point position
            AddEditPolygonShapeTool.polygonDecorator.createSphereOnPointPosition(_ev.point, 0.05, ColorDef.from(200, 100, 100), AddEditPolygonShapeTool.points.length.toString(), "");
            //AddEditPolygonShapeTool.polygonDecorator.addMarkerOnPoint(_ev.point);//Aditya
            //AddEditPolygonShapeTool.markerHelperDecorator.addMarkerOnPoint(_ev.point);

            //---
            //if decorator exits then dont add it to the ViewManger just invalidate cache and request for a re-draw + needsUpdate for the decorator.
            isPDecoratorExists == false ? IModelApp.viewManager.addDecorator(AddEditPolygonShapeTool.polygonDecorator)://else
                IModelApp.viewManager.invalidateCachedDecorationsAllViews(AddEditPolygonShapeTool.polygonDecorator);

            // if number of points matches the amount set in stride, start building the poligon shape with those points
            if (AddEditPolygonShapeTool.points.length > 0 && AddEditPolygonShapeTool.points.length == pointsStride) {
                if (AddEditPolygonShapeTool._mountingsData != null) {//run this only if mountings data exists in project.
                    let polyinfo = new PolygonInfo(); //Populate polygon Info

                    polyinfo.poligonPoints = AddEditPolygonShapeTool.points;// The orignal points that create the polygon.                         
                    polyinfo.faceLoacation = AddEditPolygonShapeTool.getFaceLocationForGrating(AddEditPolygonShapeTool.points);// Return a container with the names of the relvant pipes and mount.             

                    let face1 = polyinfo.faceLoacation.first[polyinfo.faceLoacation.first.length - 3];
                    let face2 = polyinfo.faceLoacation.second[polyinfo.faceLoacation.second.length - 3];

                    polyinfo.name = "Poly" + "_" + face1 + face2 + "_m" + polyinfo.faceLoacation.mount[polyinfo.faceLoacation.mount.length - 1];// Name/Id.

                    AddEditPolygonShapeTool.poligonInfoArray.push(polyinfo);//Push the collected info into the array for use.
                    AddEditPolygonShapeTool.points = [];//reset the points array

                    //Create the polygon after data from the 3+ clicked points is processed
                    AddEditPolygonShapeTool.createPoligonFromPolygonInfoArray(AddEditPolygonShapeTool.poligonInfoArray);

                    //Open the side panel when the Polygon grid gets created
                    // FrontstageManager.activeFrontstageDef?.getZoneDef(ZoneLocation.CenterRight)?.findWidgetDef("PoligonTransform")?.setWidgetState(WidgetState.Open);
                    AddEditPolygonShapeTool.polygonDecorator.setLastSelectedPolygonShape(polyinfo.name);
                    await UiFramework.frontstages.setActiveFrontstage("sandbox:two-viewports-frontstage");
                    store.dispatch(DTVActions. setEditModeFlag(false) )
                }
                else {//else show message and terminate/clear
                    IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Warning, "No Mount Data Found For This Tool", "", OutputMessageType.Toast));
                    //If no mount data found then skip and delete all resedual elements from ViewManager.
                    for (const dec of IModelApp.viewManager.decorators) {
                          if (dec.constructor.name.includes("PolygonShapeDecorator")) {
                              IModelApp.viewManager.dropDecorator(dec);
                              AddEditPolygonShapeTool.terminateTool();
                          }
                          if (dec.constructor.name.includes("GratingMarkerHelper")) {
                              IModelApp.viewManager.dropDecorator(dec);
                          }
                    }
                    AddEditPolygonShapeTool.setEditMode(false);
                    IModelApp.tools.run(SelectionTool.toolId);//revert to selection tool in case the Primitive tool is still active.
                    HighlightGratingTool.isEnabled = false;//change the highlight to disable ,will update results on next update.
                }
                this.exitTool();

            }
        }
        //If Edit mode is true use this Primitive tool to be capable of editing the Poligon.      
        if (AddEditPolygonShapeTool.isEditMode == true) {//this Primitive tool and condition gets enabled in the PolygonShapedecorator on Sphere click event, *Check* : PolygonShapeDecorator.ts -> onDecorationButtonEvent()

            if (PolygonShapeDecorator.selectedGeometry?.name.substring(0, 3) == "Sph") {//if obj is a sphere type.

                for (let pa = 0; pa < AddEditPolygonShapeTool.poligonInfoArray.length; pa++) {//Travese the poligonInfo array and its polygonpoints sub object array.
                    for (let pp = 0; pp < AddEditPolygonShapeTool.poligonInfoArray[pa].poligonPoints.length; pp++) {
                        //check if the clicked sphere object position matches with the location of the points that are in array , if yes then update the point to the new prmitive tool click location.
                        //in this case the selected geometry will be the sphere object, as this option is available on click of the sphere object.
                        if (AddEditPolygonShapeTool.poligonInfoArray[pa].poligonPoints[pp].x == PolygonShapeDecorator.selectedGeometry?.positions[0].x && AddEditPolygonShapeTool.poligonInfoArray[pa].poligonPoints[pp].y == PolygonShapeDecorator.selectedGeometry?.positions[0].y) {
                            //Update the xyz value of the matched point to the new clicked point.
                            AddEditPolygonShapeTool.poligonInfoArray[pa].poligonPoints[pp].x = _ev.point.x
                            AddEditPolygonShapeTool.poligonInfoArray[pa].poligonPoints[pp].y = _ev.point.y
                            AddEditPolygonShapeTool.poligonInfoArray[pa].poligonPoints[pp].z = _ev.point.z
                        }
                    }
                }
            }
            if (PolygonShapeDecorator.selectedGeometry?.name.substring(0, 3) == "joi") {//if object is a joint type.
                for (let pa = 0; pa < AddEditPolygonShapeTool.poligonInfoArray.length; pa++) {
                    for (let jp = 0; jp < AddEditPolygonShapeTool.poligonInfoArray[pa].jointPipes.length; jp++) {
                        if (PolygonShapeDecorator.selectedGeometry?.positions[1] == AddEditPolygonShapeTool.poligonInfoArray[pa].jointPipes[jp].pointB)
                        {//check if the selected geometries matches the joint pipes, if yes edit that pipe.
                            AddEditPolygonShapeTool.poligonInfoArray[pa].jointPipes[jp].pointB.x = _ev.point.x;
                            AddEditPolygonShapeTool.poligonInfoArray[pa].jointPipes[jp].pointB.y = _ev.point.y;
                            AddEditPolygonShapeTool.poligonInfoArray[pa].jointPipes[jp].pointB.z = _ev.point.z;
                        }
                    } 
                }   
            }
            //Recreate geometries with updated values in poligonInfoArray above    
            AddEditPolygonShapeTool.createPoligonFromPolygonInfoArray(AddEditPolygonShapeTool.poligonInfoArray);
            for (let pa = 0; pa < AddEditPolygonShapeTool.poligonInfoArray.length; pa++) {
                //AddEditPolygonShapeTool.createPipesFromPipeData(AddEditPolygonShapeTool.poligonInfoArray[pa].jointPipes, ColorDef.from(224,0 , 224));
                //AddEditPolygonShapeTool.createPipesFromPipeData(AddEditPolygonShapeTool.poligonInfoArray[pa].edgePipes, ColorDef.from(0, 220, 0));
                //AddEditPolygonShapeTool.createPipesFromPipeData(AddEditPolygonShapeTool.poligonInfoArray[pa].endToendJointPipes, ColorDef.from(220, 220, 0));
            }  
        }
        //---
        // isGMhDecoratorExists == false ? IModelApp.viewManager.addDecorator(AddEditPolygonShapeTool.markerHelperDecorator) ://else
        // IModelApp.viewManager.invalidateCachedDecorationsAllViews(AddEditPolygonShapeTool.markerHelperDecorator);
        //---
        return EventHandled.No;
    }
    //------------------------------------------------------------------------------------
    public override async onMouseMotion(_ev: BeButtonEvent): Promise<void> {
        //empty
    }
    //------------------------------------------------------------------------------------
    public override async onMouseStartDrag(_ev: BeButtonEvent): Promise<EventHandled> {
        return EventHandled.No;
    }
    //------------------------------------------------------------------------------------
    public override async onMouseEndDrag(_ev: BeButtonEvent): Promise<EventHandled> {
        this.exitTool();
        return EventHandled.Yes;
    }
    //------------------------------------------------------------------------------------
    public override async onCleanup() {
    }
    //------------------------------------------------------------------------------------
    public override onPostInstall(): Promise<void> {
        super.onPostInstall();
        // Enable AccuSnap so that Decorator can be created by snapping on existing model
        IModelApp.accuSnap.enableSnap(true);//this will enable the intersect pointer that only returns points on existing Reality model
        return Promise.resolve();
    }
    //------------------------------------------------------------------------------------
    public override isCompatibleViewport(_vp: Viewport | undefined, _isSelectedViewChange: boolean): boolean {
        return true;
    }
    //------------------------------------------------------------------------------------
}
