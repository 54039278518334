import { MessageSeverity, ToolbarItemUtilities } from "@itwin/appui-abstract";
import {  clearOldMarkers, executePinAnnotation, executeUnhighlightPin } from "../components/tools/HighlightPinAnnotation";
import {  executeHighlightBracing, executeUnhighlightBracing } from "./HighlightBracings";
import {  executeHighlightEquipment, executeUnhighlightEquipment } from "./HighlightEquipment";
import {  executeHighlightFeedline } from "./HighlightFeedline";
import {  executeHighlightGrating, executeUnhighlightGrating } from "./HighlightGratings";
import {  executeHighlightLadder, executeUnHighlightLadder } from "./HighlightLadder";
import {  executeHighlightMount, executeUnhighlightMount } from "./HighlightMount";
import {  executeHighlightTower, executeUnHighlightTower } from "./HighlightTower";
import { store } from "../../store/rootReducer";
import { SyncUiEventDispatcher, UiFramework } from "@itwin/appui-react";
import { DTVActions } from "../../store/Actions";
import { setSelectedObjectStructure } from "../../store/detectedData/apiDataActionTypes";
import {  initialPropertyListObjectState } from "../../store/detectedData/apiDataTypes";
import { SyncUiEventIds, initialHighlightState } from "../../store/redux-types";
import { OutlineEquipmentTool } from "../components/tools/OutlineEquipmentTool";
import { FeederLineDecorator } from "./decorators/FeederLineDecorator";
import { IModelApp, NotifyMessageDetails, OutputMessagePriority, OutputMessageType } from "@itwin/core-frontend";
import { OutlineFeedlineTool } from "../components/tools/OutlineFeedlineTool";
import { ModifyHandleDecoration } from "../components/tools/modification/ModifyHandleDecoration";
import { executeHighlightFeeders } from "./HighlightFeeders";
import { Logger } from "../api/logging";
import { executeHighlightShape, executeUnHighlightShape } from "./HighlightShape";
import { OutlineShapeTool } from "../components/tools/OutlineShapeTool";


  
export const HighlightAllDetectedObjects = () => {

    return ToolbarItemUtilities.createActionButton(
        "Highlight-all-objects", 
        110, 
        "icon-crop", 
        "Highlight All Objects",
        async () => {    
            if(store.getState().dtvState.featureControls.restrictCrudOperations && !store.getState().dtvState.applicationState.isLoggedInUserAdmin)
                SyncUiEventDispatcher.dispatchSyncUiEvent(SyncUiEventIds.Not_Show_All_Detected_Objects);    
            if(!store.getState().dtvState.applicationState.highlightStates.highlightAll){

                store.dispatch(DTVActions.updateHighlightState({...store.getState().dtvState.applicationState.highlightStates,highlightAll:true}));
                // if(!store.getState().dtvState.applicationState.highlightStates.equipment) await executeHighlightEquipment(false);
                if(!store.getState().dtvState.applicationState.highlightStates.equipment) await executeHighlightShape(false);
                if(!store.getState().dtvState.applicationState.highlightStates.ladder) await executeHighlightLadder(false);
                if(!store.getState().dtvState.applicationState.highlightStates.bracing) await executeHighlightBracing(false);
                if(!store.getState().dtvState.applicationState.highlightStates.tower) await executeHighlightTower(false);
                if(!store.getState().dtvState.applicationState.highlightStates.grating) await executeHighlightGrating();
                if(!store.getState().dtvState.applicationState.highlightStates.feeders) await executeHighlightFeeders();
                if(!store.getState().dtvState.applicationState.highlightStates.annotations) await executePinAnnotation(false);
                try{
                    if(!store.getState().dtvState.applicationState.highlightStates.mount) await executeHighlightMount(false);
                }catch(error){
                    Logger.error('Error while Highlighting Mount ', error);
                }
                IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Success, "All available detected objects are highlighted.","", OutputMessageType.Toast));


            }else{
                UiFramework.dialogs.modeless.close("Edit Dialog");

                if(store.getState().dtvState.applicationState.highlightStates.mount)executeUnhighlightMount();
                if(store.getState().dtvState.applicationState.highlightStates.grating)executeUnhighlightGrating();
                if(store.getState().dtvState.applicationState.highlightStates.bracing)executeUnhighlightBracing();
                if(store.getState().dtvState.applicationState.highlightStates.ladder)executeUnHighlightLadder();
                if(store.getState().dtvState.applicationState.highlightStates.tower)executeUnHighlightTower();
                if(store.getState().dtvState.applicationState.highlightStates.annotations){
                    executeUnhighlightPin();
                    clearOldMarkers();
                    SyncUiEventDispatcher.dispatchSyncUiEvent(SyncUiEventIds.Annotations_UnHighlighted);
                }
                if(store.getState().dtvState.applicationState.highlightStates.feeders){
                    OutlineFeedlineTool.dropDecorator();
                    OutlineFeedlineTool.dropDecorator = undefined;
                    ModifyHandleDecoration.clear();
                    // SampleToolWidget.currfeedlineDecorator = undefined;
                // let feederLineDecor = IModelApp.viewManager.decorators.filter(e => e.constructor.name.includes("FeederLineDecorator"))[0] as FeederLineDecorator;
                // feederLineDecor!.terminate();
                // for (const decor of IModelApp.viewManager.decorators) {
                //     const n = decor.constructor.name;
                //     if (n.includes("FeederLineDecorator")) {
                //         (decor as FeederLineDecorator).terminate();
                //         IModelApp.viewManager.dropDecorator(decor);
                //     }
                // }
            }
                if(store.getState().dtvState.applicationState.highlightStates.equipment){
                    // executeUnhighlightEquipment();
                    executeUnHighlightShape();
                let toolRetVal =false;
                setTimeout(async () => {
                    // toolRetVal = await IModelApp.tools.run(OutlineEquipmentTool.toolId);
                    toolRetVal = await IModelApp.tools.run(OutlineShapeTool.toolId);
            
                    if(toolRetVal){
                        IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Info, "Equipment are successfully loaded.", "", OutputMessageType.Toast));
                    } else {
                    }
                }, 0);
            }
                store.dispatch(setSelectedObjectStructure(initialPropertyListObjectState));
                store.dispatch(DTVActions.updateHighlightState(initialHighlightState));
            }

        }
    );

};

store.subscribe(HighlightAllDetectedObjects);