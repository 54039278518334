//------------------------------------------------------
import * as React from "react";
import { IModelApp, IModelConnection, NotifyMessageDetails, OutputMessagePriority, OutputMessageType } from "@itwin/core-frontend";
import { PropertyRecord } from "@itwin/appui-abstract";
import { Orientation } from "@itwin/core-react";
import "./List.scss";
import { getUnit, getValueInRequiredUnit } from "../Tables/BaseTable";
import GratingsClient from "../../api/gratingsClient";
import { PropertyList } from "@itwin/components-react";
import { store } from "../../../store/rootReducer";
import { CustomGeometry } from "../../tools/decorators/MountDecorator";
import { PolygonShapeDecorator } from "../../tools/decorators/PolygonShapeDecorator";
import { ConfigManager } from "../../../config/ConfigManager";
//------------------------------------------------------
/*
 * Custom contaner to store data that the user will 
 * see in the side pannel. 
*/
//------------------------------------------------------
/*
* pull data from the api and rutilise it here to
* display UI related data. This is the same as 
* the one used in polygonShapeDecorator to show grating info
*/
async function getData() {
    const token = store.getState().auth.accessTokenStatePrivateAPI.accessToken!;
    const a = await GratingsClient.getMountingsDataJason(token);
    if (a) return a;
    //const mountDec = IModelApp.viewManager.decorators.filter(e=>e.constructor.name.includes("MountDecorator"))[0] as MountDecorator;
    //if(mountDec)return mountDec.selectedMount;
    return false;
}
export const gratingProp = async() => {
    const towerdata = store.getState().detectedData.towerStructureData;
    const concreteHeight = ConfigManager.AGL ? Object.entries(towerdata).filter((f) => f[0] === "concrete_height")[0][1] : 0;

    let record = await getData()
    const dataProvider: PropertyRecord[] = [];
    let mountData: any;
    let gratingData;
    for (let mnt in record) {
        if (mnt == "mounts") {
            mountData = record[mnt];
        }
    }

    const ps = PolygonShapeDecorator.lastSelectedPolygonShape;
    let pi: any;
    let elevation: string = "";
    let mountName: string = "";

    const mountAndGratingList : {
        mountName : string,
        grating ? : any
        utmAltitude : string
    }[] =[];

    for(let mount in mountData){
        mountAndGratingList.push({mountName : mount, grating : mountData[mount].grating, utmAltitude : mountData[mount].utm_altitude});
    }

    for(let item in mountAndGratingList){
        if(mountAndGratingList[item].grating){
            for(let gratingName in mountAndGratingList[item].grating){
                if(gratingName == ps.name){
                    mountName = mountAndGratingList[item].mountName;
                    elevation = mountAndGratingList[item].utmAltitude;
                    pi = mountAndGratingList[item].grating[gratingName];
                }
            }
        }
    }
        // if(item["grating"].length){
        //     for(let gratingName in item["grating"]){
        //         if(gratingName === ps.name){
        //             elevation = item["utmAltitude"];
        //             break;
        //         }
        //     }
        // }
    // for (let mnt in mountData) {
    //     for (let eqp in mountData[mnt]) {
    //         elevation = mountData[mnt].utm_altitude
    //         if (eqp == "grating") {
    //             for (let poly in mountData[mnt][eqp]) {
    //                 if (poly == ps.name) {
    //                     pi = mountData[mnt][eqp][poly];
    //                     mountName = mnt;
    //                 }
    //             }
    //         }
    //     }
    // }
    const nameValuePair: {name: string, value: string}[] = [];
    if(!pi)return nameValuePair;

    let name = ps.name.replace("Poly", "Grating")
    dataProvider.push(PropertyRecord.fromString(name, "Name"));
    nameValuePair.push({name: "Name", value: name});
    //Take the two pipe names and sperate them then extract the face loaction 
    if (pi.FaceLocation.length > 0) {
        let faces = pi.FaceLocation.split(",");
        let face1 = faces[0][faces[0].length - 3];
        let face2 = faces[1][faces[1].length - 3];
        dataProvider.push(PropertyRecord.fromString("Face" + face1 + "," + "Face" + face2, "Location"));       
        nameValuePair.push({name: "Location", value: "Face" + face1 + "," + "Face" + face2});

    }
    dataProvider.push(PropertyRecord.fromString(getValueInRequiredUnit(parseFloat(elevation) - store.getState().detectedData.siteCoordinate?.utm.z), "Elevation" + getUnit()));
   
    nameValuePair.push({name: "Elevation"+ getUnit() +" *", value: (getValueInRequiredUnit(parseFloat(elevation) - store.getState().detectedData.siteCoordinate?.utm.z + concreteHeight)).toString()});
    dataProvider.push(PropertyRecord.fromString(mountName, "Mount"));
    nameValuePair.push({name: "Mount", value: mountName});
    let sides = pi.utm.length;
    dataProvider.push(PropertyRecord.fromString(sides, "Edges"));
    nameValuePair.push({name: "Edges", value: sides});

    return nameValuePair;
}
//------------------------------------------------------
function createDataProvider(data: CustomGeometry) {
    const dataProvider: PropertyRecord[] = [];
    let mountData: any;
    let gratingData;
    for (let mnt in data) {
        if (mnt == "mounts") {
            mountData = data[mnt];
        }
    }

    const ps = PolygonShapeDecorator.lastSelectedPolygonShape;
    let pi: any;
    let elevation: string = "";
    let mountName: string = "";
    for (let mnt in mountData) {
        for (let eqp in mountData[mnt]) {
            elevation = mountData[mnt].utm_altitude
            if (eqp == "grating") {
                for (let poly in mountData[mnt][eqp]) {
                    if (poly == ps.name) {
                        pi = mountData[mnt][eqp][poly];
                        mountName = mnt;
                    }
                }
            }
        }
    }

    let name = ps.name.replace("Poly", "Grating")
    dataProvider.push(PropertyRecord.fromString(name, "Name"));

    //Take the two pipe names and sperate them then extract the face loaction 
    if (pi.FaceLocation.length > 0) {
        let faces = pi.FaceLocation.split(",");
        let face1 = faces[0][faces[0].length - 3];
        let face2 = faces[1][faces[1].length - 3];
        dataProvider.push(PropertyRecord.fromString("Face" + face1 + "," + "Face" + face2, "Location"));       
    }
    dataProvider.push(PropertyRecord.fromString(getValueInRequiredUnit(parseFloat(elevation) - store.getState().detectedData.siteCoordinate?.utm.z), "Elevation" + getUnit()));
    dataProvider.push(PropertyRecord.fromString(mountName, "Mount"));
    let sides = pi.utm.length;
    dataProvider.push(PropertyRecord.fromString(sides, "Edges"));

    return dataProvider;
}
//------------------------------------------------------
/*
  Fetch data getData() and process it here it fill the mUserInfo : MountUserInfo[] array
  for use in createDataProvider() for display. 
*/
export function GratingList(props: { imodel: IModelConnection }) {
    const [record, setRecord] = React.useState<any>();

    React.useEffect(() => {
        setRecord(undefined);
        getData().then((data) => {
            if (!data) {
                IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Error, "Data could not be accessed", "", OutputMessageType.Toast));
                return;
            }
            setRecord(data);
        });

    }, [props.imodel]);
    //------
    //Title name and relevant html
    const header = (<h1 className="header">Grating Information</h1>);
    let content = <div className="noDataListContainer"><h1 className="noDataListText">No Equipment Data</h1></div>;
    //Pass data to createDataProvider();
    if (record) {
        const dataProvider: PropertyRecord[] = createDataProvider(record);
        content = <PropertyList width={300} properties={dataProvider} orientation={Orientation.Vertical} />;
    }
    //------
    return (
        <div className="info-panel">
            {header}
            {content}
        </div>
    );
}
//------------------------------------------------------
