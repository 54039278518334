import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
// import { NVRowsForPropEditModal} from '../components/Lists/PropertyEditModal'
import { getValueInRequiredUnit } from '../components/Tables/BaseTable'
import { ReduceDecimalNumberToTwoPlaces } from '../components/Lists/EquipmentList'
import { UiFramework } from '@itwin/appui-react'
import { IModelApp, NotifyMessageDetails, OutputMessagePriority, OutputMessageType } from '@itwin/core-frontend'
import { pecGridStructure, pecInputTagTypes, pecDataValueTypes, pecStrucType, PropertyEditorComponent, pecComponentProps, pecRows, pecColumn, pecOptionData } from '../components/PropertyEditorComponent'
// import { getEquipment, saveOperatorElevationMappingToStore } from './GetDetectedObjectsData'
// import DatabaseApiClient from '../../services/graphql/database-api/database-api-client'
import { RootState } from '../../store/States'
import { mountingsSummaryData } from '../../store/detectedData/apiDataTypes'
import { unitSystemType } from '../../store/redux-types'
import { ConfigManager } from '../../config/ConfigManager'
import { AppSettings } from '../../config/AppSettings'
import { store } from '../../store/rootReducer'
// import { Logger } from '../api/logging'
import { setMountingSummaryData } from '../../store/detectedData/apiDataActionTypes'
import mountClient from '../api/mountClient'
// interface OperatorInterface {showActionButton: boolean, showModal: boolean, values: NVRowsForPropEditModal[], existingMappings: any[], disabledToolTip: string|null}
// interface propsInterface { mapping: any; operators: any[]; mountsData: mountingsData; siteCoordinates: { utm: { z: number } }; unitSystem: unitSystemType; isUserAdmin: any; projectId: string };
const firstSelectElement = "Select Operator";  

export const OperatorElevationMappingTool = (props) => {
  // const [showEOMapModal, setShowEOMapModal] = useState<OperatorInterface>({showActionButton: false, showModal: false, values: [], existingMappings: props.existingMappings, disabledToolTip: "Mounts or Site details not found."});
  // const [elOpMappings, setElOpMappings] = useState<any>(props.existingMappings);
  // const [operators, setOperators] = useState<any>(props.operators);
  const [opElEditData, setOpElEditData] = useState(props.opElDialogStruc);
  const [saveButton, setSaveButton] = useState<{disabled: boolean, text: string}>({disabled: false, text: "Save"});
  
  useEffect(()=>{
      // let mappings = props.existingMappings, addMapping = false;
      // if(mappings.length == 0)addMapping = true;
      // const values: NVRowsForPropEditModal[] = [];
      // const opList = props.operators.map((e: any)=>e.displayName);

      // if(props.mountsData.mounts) {
      //   Object.entries(props.mountsData.mounts).sort().forEach((e: any)=> {
      //     const mountName = `${e[0][0].toUpperCase()+e[0].substr(1).replace(/[^a-z]/g, "")} ${parseInt(e[0].replace(/[^0-9]/g, ""))}`;
      //     const elevation = ReduceDecimalNumberToTwoPlaces(e[1].utm_altitude - props.siteCoordinates.utm.z);
      //     if(addMapping)mappings.push({elevation, operator: "", mountName: e[0]});
      //     const mapVal = mappings.filter((m)=>m.mountName==e[0]);
      //     const row: nvCell[] = [];
      //     row.push(
      //       {
      //         value: mapVal.length ? mapVal[0].operator : "",
      //         type: "string",
      //         // disabled: !SampleToolWidget.sampleToolWidget.state.isUserAdmin,
      //         disabled: false,
      //         inputType: "select",
      //         styles: {parentClass: "col-5", elementClass: "col-12 filter-propEditorComp-dropdown"},
      //         modified: false,
      //         data: [firstSelectElement, ...opList],
      //       },
      //     );
      //     values.push({
      //       nvCells: row,
      //       cellTitle: {
      //         name: `${mountName} - ${elevation}`,
      //         origName: "elevation",
      //         styles: {parentClass: "col-6 dialogBox-propEditorComp-heading-coloumn", elementClass: "col-12 pt-3"},
      //       },
      //     });
      //   });
      //   SyncUiEventDispatcher.dispatchImmediateSyncUiEvent(SyncUiEventIds.RM_Enable_Opeartor_Elevation_Mapping);
      //   // setShowEOMapModal({...showEOMapModal, showModal: !showEOMapModal.showModal, values, existingMappings: mappings});
      // }
  }, []);


  const onPropertyModalChange = (evt: any) => {
      evt.persist();
      const id = evt.target.id;
      const idSplit = id.split("-");
      const {name: theName, value: theValue} = evt.target;
      // const opVals = [...showEOMapModal.existingMappings];

      // const labelName = opElEditData.rows[idSplit[1]].columns[+idSplit[2][0]-1].name.split(' - ')[1];

      // const index  = showEOMapModal.existingMappings.findIndex((e) => parseFloat(e.elevation) === parseFloat(labelName));
      const newRows = [...opElEditData.rows];
      // if(index==-1)return;

      // const mapVals: NVRowsForPropEditModal[] = [...showEOMapModal.values];
      switch (idSplit[0]) {
        case "select": {
            // opVals[index].operator = theValue  === firstSelectElement ? firstSelectElement : theValue;
            // mapVals[index].nvCells[0].value = theValue === firstSelectElement ? firstSelectElement : theValue;
            newRows[parseInt(idSplit[1])].columns[1].value = theValue;
            // opVals[index].operator = theValue  === 0 ? firstSelectElement : props.operators[theValue - 1].displayName;
            // mapVals[index].nvCells[idSplit[1]].value = theValue === 0 ? firstSelectElement : props.operators[theValue - 1].displayName;
            break;
          }
      }
      setOpElEditData({...opElEditData, rows: newRows});
      // setShowEOMapModal({...showEOMapModal, existingMappings: opVals, values: mapVals});
  }

  const onModalBtnClick = async (evt: any) => {
    // const convFactor = props.unitSystem === unitSystemType.IMPERIAL ? 3.2808399 : 1;
    // const elOpMaps = elOpMappings;
    // const resetMapping = elOpMaps.map((e) => ({...e, elevation: ReduceDecimalNumberToTwoPlaces(e.elevation * convFactor).toString()}));
    if (evt === undefined) {
      // setShowEOMapModal({...showEOMapModal, showModal: !showEOMapModal.showModal, existingMappings: resetMapping});
      return;
    }
    evt.persist();
    if (evt.target.name === "Cancel") {
        // setShowEOMapModal({...showEOMapModal, showModal: !showEOMapModal.showModal, existingMappings: resetMapping});
        UiFramework.dialogs.modeless.close("Operator Elevation Mapping");
    }
    if (evt.target.name === "Save" && props.isUserAdmin) {
      const temp = [...opElEditData.rows];

      const modifiedOpVal:object[] = []
      for (let index = 1; index < temp.length; index++) {
        let element = temp[index];
        if(element.columns[1].value && element.columns[1].value != firstSelectElement){
          modifiedOpVal.push({operator: element.columns[1].value,elevation: parseFloat(element.columns[0].name)})
        }
        else if(element.columns[1].value === firstSelectElement){
          modifiedOpVal.push({operator: firstSelectElement ,elevation: parseFloat(element.columns[0].name)})
        }
      }

      let copyMountSummary = JSON.parse(JSON.stringify(props.mountingsSummaryData));
      const updatedOpList:object[] = [];
      modifiedOpVal.forEach(( ele: any) => {
        props.mountingsSummaryData.forEach(element => {
          if(element.elevation === ele.elevation){
            let opObj = props.operators.find((e) => e.displayName === ele.operator)
            updatedOpList.push({mount: element.name, operatorId: opObj ? opObj.operatorId : null})
            let index = props.mountingsSummaryData.indexOf(element);
            copyMountSummary[index].operator = ele.operator != firstSelectElement ? ele.operator : null;
            copyMountSummary[index].operatorId = opObj ? opObj.operatorId : null;
          }
        });
      });

      setSaveButton({disabled: true, text: "Saving..."});
      await mountClient.putMountOperator(store.getState().auth.accessTokenStatePrivateAPI.accessToken,updatedOpList).then(async (res) => {
        if (!res) {
            IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Error,
                `Error occured: edit changes not saved.`, "", OutputMessageType.Toast));
        } else {
          store.dispatch(setMountingSummaryData(copyMountSummary));
            IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Info,
                `Success: changes saved.`, "", OutputMessageType.Toast));
        }
    });
      // try {
      //   await getEquipment(props.token.accessToken);
      // } catch (error) {
      // Logger.error('Error while fetching Elevation Operator Mapping: ', error);
      // }
      
      // setShowEOMapModal({...showEOMapModal, showModal: !showEOMapModal.showModal});
      // const convFactor = props.unitSystem === unitSystemType.IMPERIAL ? 3.2808399 : 1;
      // const temp = showEOMapModal.existingMappings.map((e: any) => {
      //   // if (e.operator !== firstSelectElement)return {elevation: (parseFloat(e.elevation) * (1 / convFactor)).toFixed(2).toString(), operator: e.operator};
      //   if (e.operator !== firstSelectElement)return {elevation: e.elevation.toString(), operator: e.operator};
      //   else return null;
      // }).filter((e) => e);

      // DatabaseApiClient.elevationOperatorUpdate(temp, props.projectId).then((e) => {
      //   if (e) {
      //     const temp = showEOMapModal.existingMappings.map((e: any) => {
      //         // if (e.operator !== firstSelectElement)return {elevation: (parseFloat(e.elevation) * (1 / convFactor)).toFixed(2).toString(), operator: e.operator, mountName: e.mountName};
      //         if (e.operator !== firstSelectElement)return {elevation: parseFloat(e.elevation).toString(), operator: e.operator, mountName: e.mountName};
      //         else return null;
      //     }).filter((e) => e);
      //     setElOpMappings(temp);
      //     saveOperatorElevationMappingToStore(temp, props.mountsData, props.siteCoordinates);
          
      //     setShowEOMapModal({...showEOMapModal, existingMappings: temp});
      //     _resetOperatorFilterWidget();
      //     IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Info, "Elevation-Operator mapping saved successfully.", "", OutputMessageType.Toast));
      //   } else {
      //     IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Error, "Elevation-Operator mapping not saved.", "", OutputMessageType.Toast));
      //   }
      // });
      UiFramework.dialogs.modeless.close("Operator Elevation Mapping");
    }
  }

  // const _resetOperatorFilterWidget = () => {
  //     const filterWidgetState = UiFramework.frontstages.activeFrontstageDef?.findWidgetDef("OperatorFilterWidget")?.state;
  //     UiFramework.frontstages.activeFrontstageDef?.findWidgetDef("OperatorFilterWidget")?.setWidgetState(WidgetState.Unloaded);
  //     if (filterWidgetState === 0)UiFramework.frontstages.activeFrontstageDef?.findWidgetDef("OperatorFilterWidget")?.setWidgetState(WidgetState.Open);
  // }

  const modalBtns = [
      {name: saveButton.text, value: saveButton.text, class: "dialogBox-save-btn", disable: saveButton.disabled, onInteract: onModalBtnClick},
      {name: "Cancel", value: "Cancel", class: "dialogBox-cancel-btn", disable: false, onInteract: onModalBtnClick},
  ];
  const stickyHeading = [
    {name: `Elevation ${props.unitSystem == unitSystemType.IMPERIAL ? "(Ft)" : "(M)"}*`, value: `Elevation ${props.unitSystem == unitSystemType.IMPERIAL ? "(Ft)" : "(M)"}`, classChild: "operator-elevation-heading-color float-child", classParent: "col-6"},
    {name: "Operator", value: "Operator", classChild: "operator-elevation-heading-color float-child", classParent: "col-6"},
];


    
    const onFieldUpdate = (event) => {
      let currentEvent = event;
    }
    
  //   const onFieldChange = (event) => {
  //     const temp: pecGridStructure = {...opElEditData};
  //     const eventType = event.constructor.name;
      
  //     if(eventType == "SyntheticBaseEvent"){
  //       const {id: theId, name: theName, value: theValue} = event.target;
  //       const [inputType, rowIndex, columnIndex] = theId.split("@")[0].split('-');

        
  //       switch (inputType) {
  //         case pecInputTagTypes.SELECT:
  //           temp.rows[rowIndex].columns[columnIndex].value = temp.rows[rowIndex].columns[columnIndex].data![theValue];
  //           break;
  //         case pecInputTagTypes.CHECKBOX:
  //           temp.rows[rowIndex].columns[columnIndex].value = !temp.rows[rowIndex].columns[columnIndex].value;
  //           break;
        
  //         default:
  //           break;
  //       }
        
        
  //     } else {    
  //       switch (eventType) {
  //       case "Number":
          
  //       break;
        
  //       default:
  //         break;
  //       }
  
  //     }
  
  
  //     setOpElEditData(temp);
  //   }
    
  const onFieldClick = (event) => {
    let currentEvent = event;
  }
  
  
  
  
  let opElEditorStructure: pecComponentProps = {
    structureType: pecStrucType.STRUCTURE,
    title: {
      label: "Operator Elevation Mapping",
      // styles: undefined
    },
    grid: opElEditData,
    gridButtons: props.isUserAdmin ? modalBtns : [{name: "Cancel", value: "Cancel", class: "dialogBox-cancel-btn", disable: false, onInteract: onModalBtnClick}],
    gridStickyHeading: stickyHeading,
    eventHandlers: {
      onChange: onPropertyModalChange,
      onUpdate: onFieldUpdate,
    //   onClick: onFieldClick
      onClick: onModalBtnClick
    }
  }
  
  

  return (
      // {showEOMapModal.showModal &&
          // <PropertyEditModal
          //     screenLoc={{x: 300, y: 100}}
          //     width={500}
          //     nameVals={showEOMapModal.values}
          //     cellHeadings={[{name: `Elevation ${getUnit()}`, styles: {parentClass: "col-6", elementClass: "col-12 pt-3"}}, {name: "Operator", styles: {parentClass: "col-6", elementClass: "col-12 pt-3"}}]}
          //     openOClose={!showEOMapModal.showModal}
          //     eventHandlers={{onChange: onPropertyModalChange, btnClickHandler: onModalBtnClick }}
          //     // eventHandlers={{onChange: onPropertyModalChange, btnClickHandler: ()=>{} }}
          //     btns={modalBtns}
          //     title={`Elevation-Operator Mappings`}>
          // </PropertyEditModal>
      // }
      <PropertyEditorComponent {...opElEditorStructure} />
  )
}

const mapStateToProps = (state: RootState) => {
    const mountingsSummaryData = state.detectedData.mountingsSummaryData;
    let uniqueMounts:mountingsSummaryData[] = [mountingsSummaryData[0]];
      for (let index = 1; index < mountingsSummaryData.length; index++) {
        const element = mountingsSummaryData[index];
        if(element.elevation!=mountingsSummaryData[index-1].elevation){
          uniqueMounts.push(element);
        }
      }

    const retVal = {
      opElDialogStruc: {},
      operators: state.detectedData.operatorsData,
      // mountsData: state.detectedData.mountingsData,
      // siteCoordinates: state.detectedData.siteCoordinate,
      // projectId: ConfigManager.projectId,
      unitSystem: AppSettings.defaultDisplaySettings.unitSystem,
      // existingMappings: state.dtvState.applicationState.elevationOperatorsMapping,
      isUserAdmin: state.dtvState.applicationState.isLoggedInUserAdmin,
      mountingsSummaryData,
    };
    const optionsData: pecOptionData[] = state.detectedData.operatorsData.map(e=>({label: e.displayName, value: e.displayName}));
    optionsData.unshift({label: firstSelectElement, value: firstSelectElement});
    // let mappings = retVal.existingMappings, addMapping = false;
    // if(!mappings)addMapping = true;
    // const values: NVRowsForPropEditModal[] = [];
    // const opList = retVal.operators.map((e: any)=>e.displayName);
    
    // const theElevations = retVal.existingMappings.length ? retVal.existingMappings : retVal.mountsData
    const convFactor = retVal.unitSystem == unitSystemType.IMPERIAL ? 3.2808399 : 1;
    let rows: pecRows[] = [];

    const towerdata = state.detectedData.towerStructureData;
    const concreteHeight = ConfigManager.AGL ? parseFloat(Object.entries(towerdata).filter((f) => f[0] === "concrete_height")[0][1])*convFactor : 0;    
    
    // if(retVal.existingMappings.length){
    //   rows = retVal.existingMappings.map(e=>{
    //     const mountName = `${e.mountName[0].toUpperCase()+e.mountName.substr(1).replace(/[^a-z]/g, "")} ${parseInt(e.mountName.replace(/[^0-9]/g, ""))}`;
    //     // const elevation = getValueInRequiredUnit(ReduceDecimalNumberToTwoPlaces(e[1].utm_altitude - state.detectedData.siteCoordinate.utm.z));
    //     const elevationConverted = getValueInRequiredUnit(ReduceDecimalNumberToTwoPlaces(parseFloat(e.elevation)));
    //     const elevation = e.elevation;
    //     const columns: pecColumn[] = [
    //       {
    //         size: 6,
    //         type: pecInputTagTypes.LABEL,
    //         customClass: "",
    //         value: `${mountName} - ${(parseFloat(elevationConverted) + concreteHeight).toFixed(2)}`,
    //         disabled: false,
    //         name: `${mountName} - ${elevation}`,
    //         fieldType: pecDataValueTypes.INTEGER,
    //         modified: false,
    //         styles: {parentClass: "", childClass: ""}
    //       },
    //     ];
    //     if(retVal.isUserAdmin){
    //       columns.push(
    //         {
    //           size: 6,
    //           type: pecInputTagTypes.SELECT,
    //           customClass: "",
    //           value: e.operator,
    //           disabled: false,
    //           name: "operatorValue",
    //           fieldType: pecDataValueTypes.INTEGER,
    //           data: optionsData,
    //           modified: false,
    //           styles: {parentClass: "", childClass: ""}
    //         },      
    //       )
    //     } else {
    //       columns.push(
    //         {
    //           size: 6,
    //           type: pecInputTagTypes.LABEL,
    //           customClass: "",
    //           value: (!e.operator || e.operator =="") ? "Not Specified" : e.operator,
    //           disabled: false,
    //           name: "operatorValue",
    //           fieldType: pecDataValueTypes.INTEGER,
    //           data: optionsData,
    //           modified: false,
    //           styles: {parentClass: "", childClass: ""}
    //         },      
    //       )
    //     }
        
    //     return {
    //       columns
    //     }

    //   });

    // } else if(retVal.mountsData.mounts) {
    //   Object.entries(retVal.mountsData.mounts).sort().forEach((e: any)=> {
    //     const mountName = `${e[0][0].toUpperCase()+e[0].substr(1).replace(/[^a-z]/g, "")} ${parseInt(e[0].replace(/[^0-9]/g, ""))}`;
    //       const elevation = getValueInRequiredUnit(ReduceDecimalNumberToTwoPlaces(e[1].utm_altitude - retVal.siteCoordinates.utm.z));
    //     if(addMapping)mappings.push({elevation, operator: "", mountName: e[0]});
    //     const mapVal = mappings.filter((m)=>m.mountName==e[0]);
    //     const row: nvCell[] = [];
    //     // row.push(
    //     //   {
    //     //     value: mapVal.length ? mapVal[0].operator : "",
    //     //     type: "string",
    //     //     // disabled: !SampleToolWidget.sampleToolWidget.state.isUserAdmin,
    //     //     disabled: false,
    //     //     inputType: "select",
    //     //     styles: {parentClass: "col-5", elementClass: "col-12 filter-propEditorComp-dropdown"},
    //     //     modified: false,
    //     //     data: [firstSelectElement, ...opList],
    //     //   },
    //     // );
    //     // values.push({
    //     //   nvCells: row,
    //     //   cellTitle: {
    //     //     name: `${mountName} - ${elevation}`,
    //     //     origName: "elevation",
    //     //     styles: {parentClass: "col-6 dialogBox-propEditorComp-heading-coloumn", elementClass: "col-12 pt-3"},
    //     //   },
    //     // });
    //     const columns: pecColumn[] = [
    //       {
    //         size: 6,
    //         type: pecInputTagTypes.LABEL,
    //         customClass: "",
    //         value: `${mountName} - ${(parseFloat(elevation) + concreteHeight).toFixed(2)}`,
    //         disabled: false,
    //         name: `${mountName} - ${elevation}`,
    //         fieldType: pecDataValueTypes.INTEGER,
    //         modified: false,
    //         styles: {parentClass: "", childClass: ""}
    //       },
    //     ]
    //     if(retVal.isUserAdmin){
    //       columns.push(
    //         {
    //           size: 6,
    //           type: pecInputTagTypes.SELECT,
    //           customClass: "",
    //           value: e.operator,
    //           disabled: false,
    //           name: "operatorValue",
    //           fieldType: pecDataValueTypes.INTEGER,
    //           data: optionsData,
    //           modified: false,
    //           styles: {parentClass: "", childClass: ""}
    //         },      
    //       )          
    //     } else {
    //       columns.push(
    //         {
    //           size: 6,
    //           type: pecInputTagTypes.LABEL,
    //           customClass: "",
    //           value: (!e.operator|| e.operator =="") ? "Not Specified" : e.operator,
    //           disabled: false,
    //           name: "operatorValue",
    //           fieldType: pecDataValueTypes.INTEGER,
    //           data: optionsData,
    //           modified: false,
    //           styles: {parentClass: "", childClass: ""}
    //         },      
  
    //       )
    //     }
    //     rows.push({
    //       columns
    //     });
    //   });
    // // setShowEOMapModal({...showEOMapModal, showModal: !showEOMapModal.showModal, values, existingMappings: mappings});
    // }
    // const unitSuffix = retVal.unitSystem == unitSystemType.IMPERIAL ? "(Feet)" : "(Meters)";

    
    rows = uniqueMounts.map(e=>{
      const elevationConverted = getValueInRequiredUnit(ReduceDecimalNumberToTwoPlaces(parseFloat(e.elevation.toString())));
      const columns: pecColumn[] = [
        {
          size: 3,
          type: pecInputTagTypes.LABEL,
          customClass: "",
          value: (parseFloat(elevationConverted) + concreteHeight).toFixed(2),
          disabled: false,
          name: e.elevation.toString(),
          fieldType: pecDataValueTypes.INTEGER,
          modified: false,
          styles: {parentClass: "operator-elevation-label-parent", childClass: "operator-elevation-label-child"}
        },
      ];
      if(retVal.isUserAdmin){
        columns.push(
          {
            size: 6,
            type: pecInputTagTypes.SELECT,
            customClass: "",
            value: e.operator,
            disabled: false,
            name: "operatorValue",
            fieldType: pecDataValueTypes.INTEGER,
            data: optionsData,
            modified: false,
            styles: {parentClass: "operator-elevation-select-parent", childClass: "operator-elevation-select-child"}
          },      
        )
      } else {
        columns.push(
          {
            size: 6,
            type: pecInputTagTypes.LABEL,
            customClass: "",
            value: (!e.operator || e.operator =="") ? "Not Specified" : e.operator,
            disabled: false,
            name: "operatorValue",
            fieldType: pecDataValueTypes.INTEGER,
            data: optionsData,
            modified: false,
            styles: {parentClass: "operator-elevation-select-parent", childClass: ""}
          },      
        )
      }
      
      return {
        columns
      }

    });

  rows.unshift({
    columns:[
      {
        size: 12,
        type: pecInputTagTypes.LABEL,
        customClass: "",
        value: ConfigManager.AGL ? "*: Above ground level (AGL) is enabled and applied." : "*: Above ground level (AGL) is disabled.",
        disabled: false,
        name: "agl",
        fieldType: pecDataValueTypes.INTEGER,
        modified: false,
        styles: {parentClass: "", childClass: ""},
        data: "AGL Note on left of Button"
      }
    ]
  })

    const opElDialogStruc: pecGridStructure = {rows
    //   rows: [
    //       {
    //         columns: [
    //           {
    //             size: 8,
    //             type: pecInputTagTypes.LABEL,
    //             customClass: "",
    //             value: "Point/Coordinate Count",
    //             disabled: false,
    //             name: "mvLorR",
    //             fieldType: pecDataValueTypes.INTEGER,
    //             modified: false,
    //             styles: {parentClass: "mb-2", childClass: ""}
    //           },
    //           {
    //             size: 4,
    //             type: pecInputTagTypes.SELECT,
    //             customClass: "",
    //             value: {currentValue: 3, min: 3, max: 10, step: 1, precision: 1},
    //             disabled: false,
    //             name: "operatorValue",
    //             fieldType: pecDataValueTypes.INTEGER,
    //             data: [""],
    //             modified: false,
    //             styles: {parentClass: "mb-2", childClass: "number-input-class"}
    //           },      
    //         ]
    //       }
    //     ]
    }
    retVal.opElDialogStruc = opElDialogStruc;
    return retVal;
  }
  
// const mapStateToProps = (state: RootState) => {
//     return {
//         siteCoordinates: state.detectedData.siteCoordinate,
//         mapping: state.dtvState.applicationState.elevationOperatorsMapping,
//         mountsData: state.detectedData.mountingsData,
//         operators: state.detectedData.operatorsData,
//         unitSystem: state.dtvState.applicationState.projectUnitSystem,
//         isUserAdmin: state.dtvState.applicationState.isLoggedInUserAdmin,
//     }
// }


const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(OperatorElevationMappingTool)