import { DecorateContext, Decorator, GraphicType, IModelApp, Viewport, ViewState3d } from "@itwin/core-frontend";
import { Angle, AngleSweep, Arc3d, AxisOrder, DirectSpiral3d, Matrix3d, Point3d, Ray3d, Transform, Vector3d, XYZProps, YawPitchRollAngles } from "@itwin/core-geometry";


/* This decorator draws a North Arrow at a fixed point on the view. */
export class NorthArrowDecorator implements Decorator {

    private _arrowSizeInches = 0.55;
    private _paddingSizeInches = 0.60;

    // Generates new graphics and adds them to the scene
    public decorate(context: DecorateContext): void {
        const origin = this._getArrowOrigin(context.viewport);
        this._drawNorthArrow(context, Ray3d.create (origin, Vector3d.unitY()));
    }

    private _getArrowOrigin(vp: Viewport) {
        const rect = vp.viewRect;
        const pad = vp.pixelsFromInches(this._paddingSizeInches);
        const viewPoint = Point3d.create (rect.right - pad, rect.bottom - pad);
        const npcPoint = vp.viewToNpc(viewPoint);
        npcPoint.z =  0.5;

        return vp.npcToWorld(npcPoint);
    }

    private _drawNorthArrow(context: DecorateContext, northDir: Ray3d, _id?: string): void {
        // const val = CartographicRange;
        const vp = context.viewport;
        // const pixelSize = vp.pixelsFromInches(this._arrowSizeInches);
        // const scale = vp.viewingSpace.getPixelSizeAtPoint(northDir.origin) * pixelSize;
        const matrix = Matrix3d.createRigidFromColumns(northDir.direction, Vector3d.unitZ(), AxisOrder.YZX);

        const compass = document.getElementById('compass');
        const value = document.getElementById("angleValue");
        const angle = vp.rotation.getAxisAndAngleOfRotation();
        const angles: Angle = Angle.createRadians(Math.atan2(angle.axis.y, angle.axis.x));
        let deg = -2*angles.degrees;
        if(deg < 0){
            deg = 360 + deg;
        }
        if(value){
            value.innerHTML = deg.toFixed(1).toString();
        }

        if(compass){
            compass.style.cssText = `transform: rotate(${-deg}deg);`;
        }

        // if (undefined === matrix)
        return;

        // matrix.scaleColumnsInPlace(scale, scale, scale);
        // const arrowTrans = Transform.createRefs(northDir.origin, matrix);

        // // const northArrowBuilder = context.createGraphicBuilder(GraphicType.WorldOverlay, arrowTrans, _id);
        // const color = ColorDef.white;

        // const arrowOutline: Point3d[] = [];
        // arrowOutline[0] = Point3d.create(0.0, 0.65);
        // arrowOutline[1] = Point3d.create(-0.45, -0.5);
        // arrowOutline[2] = Point3d.create(0.0, -0.2);
        // arrowOutline[3] = Point3d.create(0.45, -0.5);
        // arrowOutline[4] = arrowOutline[0].clone();

        // const arrowLeftFill: Point3d[] = [];
        // arrowLeftFill[0] = arrowOutline[0].clone();
        // arrowLeftFill[1] = arrowOutline[1].clone();
        // arrowLeftFill[2] = arrowOutline[2].clone();
        // arrowLeftFill[3] = arrowLeftFill[0].clone();

        // const arrowRightFill: Point3d[] = [];
        // arrowRightFill[0] = arrowOutline[0].clone();
        // arrowRightFill[1] = arrowOutline[3].clone();
        // arrowRightFill[2] = arrowOutline[2].clone();
        // arrowRightFill[3] = arrowRightFill[0].clone();

        // northArrowBuilder.setSymbology(color, ColorDef.from(0, 0, 0, 200), 1);
        // northArrowBuilder.addArc(Arc3d.createXY(Point3d.createZero(), 0.6), true, true);
        // northArrowBuilder.addArc(Arc3d.createXY(Point3d.create(0.0, 0.85), 0.2), true, true);

        // northArrowBuilder.setSymbology(color, color, 2);
        // northArrowBuilder.addArc(Arc3d.createXY(Point3d.createZero(), 0.5), false, false);
        // northArrowBuilder.addLineString([Point3d.create(0.6, 0.0), Point3d.create(-0.6, 0.0)]);
        // northArrowBuilder.addLineString([Point3d.create(0.0, 0.6), Point3d.create(0.0, -0.6)]);

        // northArrowBuilder.setSymbology(color, ColorDef.from(150, 150, 150), 1);
        // northArrowBuilder.addShape(arrowLeftFill);

        // northArrowBuilder.setSymbology(color, ColorDef.black, 1);
        // northArrowBuilder.addShape(arrowRightFill);

        // northArrowBuilder.setSymbology(color, color, 1);
        // northArrowBuilder.addLineString(arrowOutline);
        // northArrowBuilder.setSymbology(color, color, 3);
        // northArrowBuilder.addLineString([Point3d.create(-0.1, 0.75), Point3d.create(-0.1, 0.95), Point3d.create(0.1, 0.75), Point3d.create(0.1, 0.95)]);

        // context.addDecorationFromBuilder(northArrowBuilder);
    }

}