
import { PropertyRecord } from "@itwin/appui-abstract";
import { ReduceDecimalNumberToFourPlaces, ReduceDecimalNumberToTwoPlaces } from "./Lists/EquipmentList";
import { getValueInRequiredUnit, getUnit } from "./Tables/BaseTable";
import { RootState } from "../../store/States";
import { store } from "../../store/rootReducer";
import { DecoratorHelper } from "../tools/decorators/DecoratorHelper";
import { DefectsDecorator } from "../tools/decorators/DefectsDecorator";
import 'bootstrap/dist/css/bootstrap.css';
import './tower-properties.scss';
import React from "react";
import { ConfigManager } from "../../config/ConfigManager";
import { IModelApp } from "@itwin/core-frontend";
import { TowerStructureDecorator } from "../tools/decorators/TowerStructureDecorator";
import { SyncUiEventDispatcher, UiFramework } from "@itwin/appui-react";
import { DefectType, towerStructureData } from "../../store/detectedData/apiDataTypes";
import { AnnotationDecorator } from "../tools/decorators/AnnotationsDecorator";
import { SyncUiEventIds } from "../../store/redux-types";

function select(state: RootState, dataKey: string) {
  return state.detectedData[dataKey];
}

export const getDefectKeyValue = () => {
  
  const nameValuePair: {name: string, value: string}[] = [];
  let data = {criticality:"", type:"", defectiveComponent:"", defectiveStructure:"", inspectionDate:"", intervention:""}
  if(AnnotationDecorator.selectedMarkerJson && store.getState().detectedData.selectedObjectInformation.objectProperties.selectedObjectNST.subType == DefectType.manual ){
    data = AnnotationDecorator.selectedMarkerJson;
    SyncUiEventDispatcher.dispatchSyncUiEvent(SyncUiEventIds.Defect_Annotation_Selected);
  }
  else data = DefectsDecorator.selectedMarkerJson
  if(data){
    nameValuePair.push({name: "Criticality", value: data?.criticality});
    nameValuePair.push({name: "Type", value:  data?.type});
    nameValuePair.push({name: "Defective Component", value:  data?.defectiveComponent});
    nameValuePair.push({name: "Defective Structure", value:  data?.defectiveStructure});
    nameValuePair.push({name: "Inspection Date", value:  data?.inspectionDate});
    nameValuePair.push({name: "Intervention", value:  data?.intervention});

  }
  return nameValuePair;
}

export const getTowerKeyValue = () => {
  const record = select(store.getState(), "towerStructureData");
  // store.dispatch(getPropertyListStructure(`${pl.selectedObjectProperties.objectCategoryType}#${pl.selectedObjectProperties.selectedObject}`));

    // convert an angle from degrees to radians
    const nameValuePair: {name: string, value: string}[] = [];
    const radians = (degrees) => {
      return degrees * (Math.PI / 180);
    }
    // convert an angle from radians to degrees
    const degrees = (radians) => {
      return radians * (180 / Math.PI);
    }
    const dataProvider: PropertyRecord[] = [];
    let name  =  getTowerType(record)
    dataProvider.push(PropertyRecord.fromString(name, "Tower Type"));
    nameValuePair.push({name: "Tower Type", value: name});
    const concreteHeight = ConfigManager.AGL ? (record.concrete_height === null ? 0 : record.concrete_height) : 0;
    const latAndLong = DecoratorHelper.getLongAndLat(record.epsg_code.toString(), record.base_center[0], record.base_center[1], record.base_altitude);
    const h = record.top_steel_elevation;
    const xDiff = (h) * Math.tan(radians(record.tower_tilt_x));
    const yDiff = (h) * Math.tan(radians(record.tower_tilt_y));
    const absTiltDiff = Math.sqrt(xDiff * xDiff + yDiff * yDiff);
    const absTiltAngle = Math.atan(absTiltDiff / h);
    let absTiltDirection;                                                                             // calc tilt direction
    if (xDiff >= 0) {
        absTiltDirection = Math.acos(yDiff / (Math.sqrt(xDiff * xDiff + yDiff * yDiff)));
    } else {
        absTiltDirection = 2 * Math.PI - Math.acos(yDiff / (Math.sqrt(xDiff * xDiff + yDiff * yDiff)));
    }
    const topAppurtenance = record.top_steel_elevation + (record.top_appurtenance_height  === null ? 0 : record.top_appurtenance_height) + concreteHeight;
    const lightingHeight =  h + (record.lightning_rod_height  === null ? 0 :  record.lightning_rod_height) + concreteHeight;
    dataProvider.push(PropertyRecord.fromString((`${ReduceDecimalNumberToFourPlaces(latAndLong.y)}, ${ReduceDecimalNumberToFourPlaces(latAndLong.x)}`), "Tower Center (Lat, Long)"));
    nameValuePair.push({name: "Tower Center (Lat, Long)", value: (`${ReduceDecimalNumberToFourPlaces(latAndLong.y)}, ${ReduceDecimalNumberToFourPlaces(latAndLong.x)}`)});
    
    dataProvider.push(PropertyRecord.fromString(getValueInRequiredUnit(record.top_width), "Tower Width At Top " + getUnit()));
    nameValuePair.push({name: `Tower Width At Top  ${getUnit()}`, value: getValueInRequiredUnit(record.top_width)});
    
    dataProvider.push(PropertyRecord.fromString(getValueInRequiredUnit(record.base_width), "Tower Width At Base " + getUnit()));
    nameValuePair.push({name: `Tower Width At Base ${getUnit()}`, value: getValueInRequiredUnit(record.base_width)});
    
    dataProvider.push(PropertyRecord.fromString(getValueInRequiredUnit(record.top_steel_elevation), "Tower Height " + getUnit()));
    nameValuePair.push({name: `Tower Height  ${getUnit()} *`, value: getValueInRequiredUnit(record.top_steel_elevation + concreteHeight)});
    
    if(record.hasOwnProperty("lightning_rod_height") && record?.lightning_rod_height) {
      dataProvider.push(PropertyRecord.fromString(getValueInRequiredUnit(lightingHeight), "Lightning Rod Elevation " + getUnit()));
      nameValuePair.push({name: `Lightning Rod Elevation  ${getUnit()} *`, value: getValueInRequiredUnit(lightingHeight)});
    }
    dataProvider.push(PropertyRecord.fromString(getValueInRequiredUnit(record.concrete_height), "Concrete height " + getUnit()));
    nameValuePair.push({name: `Concrete Height  ${getUnit()}`, value: getValueInRequiredUnit(record.concrete_height)});
    
    dataProvider.push(PropertyRecord.fromString(getValueInRequiredUnit(absTiltDiff), "Absolute Sway " + getUnit()));
    nameValuePair.push({name: `Absolute Sway  ${getUnit()}`, value: getValueInRequiredUnit(absTiltDiff)});
    
    dataProvider.push(PropertyRecord.fromString(ReduceDecimalNumberToTwoPlaces(degrees(absTiltAngle)).toString(), "Absolute Tilt (\u00b0)"));
    nameValuePair.push({name: "Absolute Tilt", value: ReduceDecimalNumberToTwoPlaces(degrees(absTiltAngle)).toString()});
    
    dataProvider.push(PropertyRecord.fromString(ReduceDecimalNumberToTwoPlaces(degrees(absTiltDirection)).toString(), "Tilt Direction (\u00b0)"));
    nameValuePair.push({name: "Tilt Direction", value: ReduceDecimalNumberToTwoPlaces(degrees(absTiltDirection)).toString()});
    
    dataProvider.push(PropertyRecord.fromString(getValueInRequiredUnit(topAppurtenance), "Highest Appurtenance Elevation (ATBL) " + getUnit()));
    nameValuePair.push({name: "Highest Appurtenance  Elevation (ATBL) *", value: getValueInRequiredUnit(topAppurtenance)});
    
    dataProvider.push(PropertyRecord.fromString(getValueInRequiredUnit(record.base_altitude), "Base Altitude (AMSL) " + getUnit()));
    nameValuePair.push({name: "Base Altitude (AMSL)", value: getValueInRequiredUnit(record.base_altitude)});


    // let propListObj: selectedObjectInformation = initialPropertyListObjectState;
    // propListObj = {...propListObj, heading: "Tower Information", propertyList: init_RM_TowerPropListState };

    // return dataProvider;
    // store.dispatch(getPropertyListStructure(`abc`));

    return nameValuePair;
}
   

function getTowerType (data){
    if(data.type == "lattice")return `${data.nLegs} Legged Lattice`
    return data.type.charAt(0).toUpperCase() + data.type.slice(1)
}



export const towerColorConvFooter = (marker: string, _type: string) => {
  const towDec=IModelApp.viewManager.decorators.filter(e=>e.constructor.name.includes("TowerStructureDecorator"))[0] as TowerStructureDecorator;
  const towData: towerStructureData = towDec.getTowerGeometry;
  const imc = UiFramework.getIModelConnection();

  const onColorMarkerClick = (event)=>{
    const target = event.target.innerText;
    switch (target) {
      case "Lightning rod":
        towDec.selectedPart="Lightning Rod@Marker";
        
        break;
      case "Highest Appurtenance":
        towDec.selectedPart="Top end of equipment@Marker";
        break;
      case "Top of steel":
        towDec.selectedPart="Tower top@Marker";
        
        break;
      case "Tower Leg":
      case "Tower Pole":
        if(TowerStructureDecorator.towerGeometryInfo.type=="monopole")towDec.selectedPart="Tower Pole";
        else towDec.selectedPart = "segment-joint-1";
        break;
      case "Tower Base":
        towDec.selectedPart="Concrete level@Marker";
        
        break;
      case "Ground level":
        towDec.selectedPart="Ground level@Marker";
        
        break;
    
      default:
        break;
    }
    const theSection = towDec.towerCylinders.find(e=>e.name==towDec.selectedPart)!;
    TowerStructureDecorator.selectedSection.sectionName = towDec.selectedPart!;
    TowerStructureDecorator.selectedSection.theSection = theSection!;
    if(theSection){
      imc?.selectionSet.emptyAll();
      imc?.selectionSet.add(theSection?.transientId!);
    }

  }

  return <>
    <h5 className="text-center" style={{margin:"12px 0 0 10px", color: "#199fd8",whiteSpace:'nowrap', fontFamily: '"Open Sans","Helvetica Neue",Helvetica,Arial,sans-serif' }}>Color Convention</h5>
    <div onClick={onColorMarkerClick} className="container pt-2 mb-3 col-12">
        <div className={`row ${marker == "Lightning Rod" ? "tow-marker-highlight" : ""} tower-marker`}>
            <div className="col-2 ml-1"><div className="square" style={{cursor: "pointer", marginTop: "3px", backgroundColor:"rgb(79,146,142)"}}></div></div>
            <div className="col-10 column-spacing" style={{cursor: "pointer"}}>Lightning rod</div>
        </div>
        {towData.top_appurtenance_height > 0 ? <div className={`row ${marker == "Top end of equipment" ? "tow-marker-highlight" : ""} tower-marker`}>
            <div className="col-2 ml-1"><div className="square" style={{cursor: "pointer", marginTop: "3px", backgroundColor:"green"}}></div></div>
            <div className="col-10 column-spacing" style={{cursor: "pointer"}}>Highest Appurtenance</div>
        </div> : <div className={`row ${marker == "Tower top" ? "tow-marker-highlight" : ""} tower-marker`}>
            <div className="col-2 ml-1"><div className="square" style={{cursor: "pointer", marginTop: "3px", backgroundColor:"blue"}}></div></div>
            <div className="col-10 column-spacing" style={{cursor: "pointer"}}>Top of steel</div>
        </div>}
        {towData.top_appurtenance_height < 0 ? <div className={`row ${marker == "Top end of equipment" ? "tow-marker-highlight" : ""} tower-marker`}>
            <div className="col-2 ml-1"><div className="square" style={{cursor: "pointer", marginTop: "3px", backgroundColor:"green"}}></div></div>
            <div className="col-10 column-spacing" style={{cursor: "pointer"}}>Highest Appurtenance</div>
        </div> : <div className={`row ${marker == "Tower top" ? "tow-marker-highlight" : ""} tower-marker`}>
            <div className="col-2 ml-1"><div className="square" style={{cursor: "pointer", marginTop: "3px", backgroundColor:"blue"}}></div></div>
            <div className="col-10 column-spacing" style={{cursor: "pointer"}}>Top of steel</div>
        </div>}
        <div className={`row ${(marker == "Tower Pole" || marker?.match(/latticeSection/i)) ? "tow-marker-highlight" : ""} tower-marker`}>
            <div className="col-2 ml-1"><div className="square" style={{cursor: "pointer", marginTop: "3px", backgroundColor:"rgb(145,112,86)"}}></div></div>
            <div className="col-10 column-spacing" style={{cursor: "pointer"}}>{`Tower ${TowerStructureDecorator.towerGeometryInfo.type == "monopole" ? "Pole" : "Leg"}`}</div>
        </div>
        <div className={`row ${marker == "Concrete level" ? "tow-marker-highlight" : ""} tower-marker`}>
            <div className="col-2 ml-1"><div className="square" style={{cursor: "pointer", marginTop: "3px", backgroundColor:"red"}}></div></div>
            <div className="col-10 column-spacing" style={{cursor: "pointer"}}>Tower Base</div>
        </div>
        <div className={`row ${marker == "Ground level" ? "tow-marker-highlight" : ""} tower-marker`}>
            <div className="col-2 ml-1"><div className="square" style={{cursor: "pointer", marginTop: "3px", backgroundColor:"rgb(128,128,128)"}}></div></div>
            <div className="col-10 column-spacing" style={{cursor: "pointer"}}>Ground level</div>
        </div>
        <div className={`row`}>
            <div className="col-2 ml-1"><div className="square" style={{marginTop: "3px", cursor: "default", backgroundColor:"silver"}}></div></div>
            <div className="col-10 column-spacing" style={{cursor: "default"}}>Fall zone</div>
        </div>
    </div>
  </>
};
