import { ColorDef, FeatureAppearance, RgbColor } from "@itwin/core-common";
import { DecorateContext, Decorator } from "@itwin/core-frontend";
// import MainPage from "../../components/MainPage";

export class RealityDataDecorator implements Decorator {
    public useCachedDecorations?: true | undefined = true;

    // tslint:disable-next-line:naming-convention
    private chosenIndex: number | undefined;

    constructor(index: number | undefined) {
        if (index !== null) {
            this.chosenIndex = index;
        } else {
            this.chosenIndex = undefined;
        }
    }

    public decorate(context: DecorateContext): void {
        const vp = context.viewport;

        if (this.chosenIndex == null) {
            const indices = []; //MainPage.antennaModelIndices;

            if (indices) {
                indices.forEach((index) => {
                    const appFeature = FeatureAppearance.fromJSON({ transparency: 0.6, rgb: RgbColor.fromColorDef(ColorDef.blue) });
                    const l = vp.displayStyle.contextRealityModelStates[index];
                    l!.appearanceOverrides = appFeature;
                    // vp.overrideRealityModelAppearance(index, FeatureAppearance.fromJSON({ transparency: 0.6, rgb: RgbColor.fromColorDef(ColorDef.blue) }));
                });
            }
        } else {
            const appFeature = FeatureAppearance.fromJSON({ transparency: 0.6, rgb: RgbColor.fromColorDef(ColorDef.red) });
            const l = vp.displayStyle.contextRealityModelStates[this.chosenIndex];
            l!.appearanceOverrides = undefined;
            l!.appearanceOverrides = appFeature;
            // vp.dropRealityModelAppearanceOverride(-1);
            // vp.overrideRealityModelAppearance(this.chosenIndex, FeatureAppearance.fromJSON({ transparency: 0.6, rgb: RgbColor.fromColorDef(ColorDef.red) }));
        }
   }
}
