import { ConfigManager } from "../../config/ConfigManager";
import { BuddiHelper } from "../api/BuddiHelper";
import { RootState } from "../../store/States";
import { AccessToken } from "@itwin/core-bentley";
import { store } from "../../store/rootReducer";

let privateAccessToken: AccessToken;
const listener = () => {
    setProjectIdUrl(store.getState());
}

function setProjectIdUrl(state: RootState) {
    privateAccessToken = state.auth.accessTokenStatePrivateAPI.accessToken!;
}

store.subscribe(listener);

export default class ExternalApiClient {
    constructor() { }

    public static async getProjectSASToken(token: string = privateAccessToken, isInternal: boolean): Promise<any> {
        const url: string = `${ConfigManager.towerSightPortalUrl}/api/v1.0/storage/${ConfigManager.projectId}/token?isInternal=${isInternal}`;
        return fetch(url, {
            method: "get",
            headers: {
                Authorization: token,
            },
        }).then(async (response: Response) => {
            if (!response.ok) {
                return null;
            }
            return response.json();
        }).catch((data: any) => {
            return data;
        });
    }
    
}
