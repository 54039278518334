import { BeButtonEvent, EventHandled, IModelApp, NotifyMessageDetails, OutputMessagePriority, OutputMessageType, PrimitiveTool, ScreenViewport, Viewport } from "@itwin/core-frontend";
import { StagePanelLocation, WidgetState, UiFramework, SyncUiEventDispatcher } from "@itwin/appui-react";
import { AddBoundingBoxTool } from "./AddBoundingBoxTool";
import { ModifyHandleDecoration } from "./modification/ModifyHandleDecoration";
import { setEquipmentData3DMaps, addToBuilt3DObjectIdsMap, updateBuilt3DObjectIdsMap } from "../../../store/detectedData/apiDataActionTypes";
import { store } from "../../../store/rootReducer";
import { FeedlineDecorator } from "../../tools/decorators/FeedlineDecorator";
import { feedlineDataStructure } from "../../api/feederLinesClient";
import { SyncUiEventIds } from "../../../store/redux-types";

export class OutlineFeedlineTool extends PrimitiveTool {
    public static override toolId = "OutlineFeedlineShapes";
    public static override iconSpec = "icon-3d";
    public static exitFeedlineDecorator: any | undefined;

    public static dropDecorator: any | undefined;

    public override isCompatibleViewport(_vp: Viewport | undefined, _isSelectedViewChange: boolean): boolean {
        return true;
    }

    // public override async run(feedlineData: feedlineDataStructure[]): Promise<boolean> {
    public override async run(highlight: boolean): Promise<boolean> {
        super.run()
        const { toolAdmin, viewManager } = IModelApp;
        if (!this.isCompatibleViewport(viewManager.selectedView, false) || !toolAdmin.onInstallTool(this)) {
            return Promise.reject(false);
        }

        toolAdmin.startPrimitiveTool(this);

        const vp = IModelApp.viewManager.selectedView as ScreenViewport;
        // SampleToolWidget.setModelIndexValues(vp);
        if (OutlineFeedlineTool.dropDecorator) {
            const currentState = store.getState();
        //     let feedlineDec: any = IModelApp.viewManager.decorators.filter((e) => e.constructor.name.includes("FeedlineDecorator"));
            const built3ObjectMaps = currentState.detectedData.built3DObjectIdMaps;
        //     const theNameObjectIds = feedlineDec.objectIdMap;
            let newIdVals: any[] = [];
            SyncUiEventDispatcher.dispatchImmediateSyncUiEvent(SyncUiEventIds.RM_Feedline_UnSelected);    
            newIdVals = built3ObjectMaps.idValues.filter((e:[string, string])=>(!e[1].match(/feederGeom/)));
            // Object.entries([...theNameObjectIds]).forEach((e)=>{if(!e[1][1].match(/_edit/))newIdVals.push(e[1]);})
            const newValIds: any[] = newIdVals.map((e: any[])=>[...e].reverse())
            store.dispatch(updateBuilt3DObjectIdsMap({idValues: newIdVals, valueIds: newValIds}));
        
            OutlineFeedlineTool.dropDecorator();
            OutlineFeedlineTool.dropDecorator = undefined;
        
            // SampleToolWidget.currfeedlineDecorator = undefined;
            ModifyHandleDecoration.clear();
            this.exitTool();
            const isBlankConnection: boolean | undefined = UiFramework.getIModelConnection()?.isBlank;
            // if (MainPage.workFlow === "" && MainPage.isiModelExists && (IModelApp as any).listCallback) {
            //     (IModelApp as any).listCallback(ListEnum.TowerInfo);
            // }
            if (isBlankConnection) {
            //   FrontstageManager.activeFrontstageDef?.getStagePanelDef(StagePanelLocation.Right)?.
            //     findWidgetDef("PropertyListWidget")?.setWidgetState(WidgetState.Hidden);
            } else if ((IModelApp as any).listCallback) {
                // (IModelApp as any).listCallback(SampleToolWidget.currentList);
            }
            // FrontstageManager.activeFrontstageDef?.getZoneDef(ZoneLocation.CenterRight)?.
            //     findWidgetDef("EquipmentRotation")?.setWidgetState(WidgetState.Hidden);
            return Promise.resolve(false);
        } else {
            // const existingEquipDataMaps: equipmentDataMaps = store.getState().detectedData.equipmentDataMaps;
            // let newEquipDataMaps: equipmentDataMaps;
            // const newEquipInfoMap = new Map(existingEquipDataMaps.equipNameInfoMap);
            // const newEquipInfoMaps: equipmentDataMaps = {...existingEquipDataMaps, equipNameInfoMap: newEquipInfoMap};

            let feedlineDec: any = IModelApp.viewManager.decorators.filter((e) => e.constructor.name.includes("FeedlineDecorator"));
 
            if (!feedlineDec.length) {
                const decorator = new FeedlineDecorator();
                // SampleToolWidget.currfeedlineDecorator = decorator;
                const loaded = await decorator.loadShapes();
                if(loaded){
                    store.dispatch(addToBuilt3DObjectIdsMap(new Map(decorator.objectIdMap)));
                    OutlineFeedlineTool.dropDecorator = IModelApp.viewManager.addDecorator(decorator);
                   if(!store.getState().dtvState.applicationState.highlightStates.highlightAll) IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Info, "Feedlines are successfully loaded.", "", OutputMessageType.Toast));
                } else {
                    this.exitTool();
                    if(!store.getState().dtvState.applicationState.highlightStates.highlightAll && highlight)IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Warning, "Feedline Data Not Found.", "", OutputMessageType.Toast));
                    return Promise.resolve(false);
                }
                // newEquipDataMaps = {...existingEquipDataMaps, tempEquipMap: decorator.currJson};
                // newEquipDataMaps = {...existingEquipDataMaps};
                // store.dispatch(setEquipmentData3DMaps(new Map(decorator.nameIdMap)));
            } else { // Add box tool already started equip decorator
                feedlineDec = feedlineDec[0] as FeedlineDecorator
                // const decorator = SampleToolWidget.currfeedlineDecorator;
                OutlineFeedlineTool.dropDecorator = OutlineFeedlineTool.exitFeedlineDecorator;
                await feedlineDec.loadShapes();
                // newEquipDataMaps = {...existingEquipDataMaps, tempEquipMap: decorator.currJson};
                // newEquipDataMaps = {...existingEquipDataMaps};
                // store.dispatch(setEquipmentData3DMaps(new Map(feedlineDec.nameIdMap)));
                store.dispatch(addToBuilt3DObjectIdsMap(new Map(feedlineDec.objectIdMap)));
                // IModelApp.viewManager.selectedView?.invalidateCachedDecorations(this);
                IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Info, "Feedlines are successfully loaded.", "", OutputMessageType.Toast));
                return Promise.resolve(true);
            }
                // globalThis.store = store;
            // store.dispatch(setEquipmentDataMaps(newEquipInfoMaps));
        }
                this.exitTool();
        return Promise.resolve(true)
        // IModelApp.viewManager.selectedView?.invalidateDecorations();
    }

    public override async onResetButtonUp(_ev: BeButtonEvent): Promise<EventHandled> {
        // if (OutlineFeedlineTool.dropDecorator) {
        //     OutlineFeedlineTool.dropDecorator();
        //     OutlineFeedlineTool.dropDecorator = undefined;
        //     SampleToolWidget.currfeedlineDecorator = undefined;
        // }
        this.exitTool();

        return EventHandled.No;
    }

    public onRestartTool(): Promise<void> {
        return Promise.resolve();
    }
}