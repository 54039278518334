import { Angle, Arc3d, AxisIndex, Box, Cone, CurveChainWithDistanceIndex, GeometryQuery, IndexedPolyface, IndexedPolyfaceVisitor, LineSegment3d, LineString3d, Loop, Matrix3d, Path, Point3d, PolyfaceBuilder, Range3d, SolidPrimitive, Transform, TransformProps, Vector3d, YawPitchRollAngles } from "@itwin/core-geometry";
import { Cartographic, ColorDef, LinePixels } from "@itwin/core-common";
import { BeButtonEvent, DecorateContext, Decorator, EventHandled, GraphicBuilder, GraphicType, HitDetail, IModelApp, RenderGraphic, ScreenViewport, SelectionTool } from "@itwin/core-frontend";
// import App from "../../components/App";
import { Id64String } from "@itwin/core-bentley";
// import { EquipmentType, SampleToolWidget } from "../frontstages/SampleToolWidget";
import { StagePanelLocation, SyncUiEventDispatcher, UiFramework, WidgetState } from "@itwin/appui-react";
import { IndividualShapeDecorator } from "./IndividualShapeDecorator";
import { EquipmentPosition } from "./EditableShapeDecorator";
import { ModifyHandleDecoration } from "../../components/tools/modification/ModifyHandleDecoration";
import { DecoratorHelper } from "./DecoratorHelper";
import { EquipmentsTable } from "../../components/Tables/EquipmentsTable";
import * as egm96 from "egm96-universal";
import { ConfigManager } from "../../../config/ConfigManager";
import { RootState } from "../../../store/States";
import { objectProperties } from "../../../store/detectedData/apiDataTypes";
import { EquipmentType } from "../../../store/redux-types";
import { store } from "../../../store/rootReducer";
const iModelConnection = UiFramework.getIModelConnection();
// const select = (state: RootState, dataKey: string) => {
//   return state.dtvState.featureControls[dataKey];
// }

let editModeActive: boolean = false, iTwinDisplayFrontFace: boolean = false, all3DObjectsMap: {}, selectedObjectInfo: objectProperties;
const listener = () => {
    setCurrentState(store.getState());
}

function setCurrentState(state: RootState) {
    selectedObjectInfo: state.detectedData.selectedObjectInformation.objectProperties;
    editModeActive = state.dtvState.applicationState.isEditModeActive;
    iTwinDisplayFrontFace = state.dtvState.featureControls.iTwinDisplayFrontFace;
    // equipDataMaps = state.detectedData.equipmentDataMaps;
    // tempEquipMap = equipDataMaps.tempEquipMap;
    all3DObjectsMap = state.detectedData.built3DObjectIdMaps;
}

store.subscribe(listener);


export interface CustomGeometryQuery {
  geometry: GeometryQuery;
  color: ColorDef;
  fill: boolean;
  fillColor: ColorDef;
  lineThickness: number;
  edges: boolean;
  linePixels: LinePixels;
  transientId: Id64String;
  name: string;
}
export interface BoxEntry {
  box: Box;
  name: string;
}

export interface CylinderEntry {
  cylinder: Cone;
  name: string;
  color: ColorDef;
}

export enum ShapeType {
  Box,
  Cylinder,
}
// tslint:disable:naming-convention
// tslint:disable:no-shadowed-variable
export class EquipmentShapeDecorator implements Decorator {
  public useCachedDecorations: true | undefined = true;

  private shapes: CustomGeometryQuery[] = [];
  public loadedShapes: boolean = false;

  public boxes: BoxEntry[] = [];
  private drawnBoxes: BoxEntry[] = [];
  private antennaBoxes: BoxEntry[] = [];
  private defectBoxes: BoxEntry[] = [];
  private rruBoxes: BoxEntry[] = [];
  public static sourceId: any;
  public static equipmentName: string | undefined;
  private microWaveCylinders: CylinderEntry[] = [];
  private drawnCylinders: CylinderEntry[] = [];
  private defectBoxInfo: any = [];
  public currJson: EquipmentPosition[] = [];
  public nameIdMap: Map<string, Id64String> = new Map<string, Id64String>();
  public objectIdMap: Map<Id64String, string> = new Map<string, Id64String>();
  private fill: boolean = true;
  private color: ColorDef = ColorDef.blue;
  private lineThickness: number = 1;
  private edges: boolean = true;
  private linePixels = LinePixels.Solid;
  public equipColors = {
    antenna: {bodyCol: ColorDef.from(71, 54, 165), faceCol: ColorDef.from(22, 28, 86, 1)},
    microwave: {bodyCol: ColorDef.from(5, 165, 120), faceCol: ColorDef.from(13, 96, 6, 1)},
    rru: {bodyCol: ColorDef.from(180, 86, 94), faceCol: ColorDef.from(99, 16, 20, 1)},
  }
  private showFrontFace: boolean = iTwinDisplayFrontFace;
  
  
  public decorate(context: DecorateContext): void {
    this.antennaBoxes.forEach((entry) => {
      if (!this.drawnBoxes.includes(entry)) {
        const builder = PolyfaceBuilder.create();
        builder.addBox(entry.box);
        const polyface = builder.claimPolyface(false);
        this.addGeometry(polyface, this.equipColors.antenna.bodyCol, entry.name);
        this.drawnBoxes.push(entry);
      }
    });
    this.rruBoxes.forEach((entry) => {
      if (!this.drawnBoxes.includes(entry)) {
        const builder = PolyfaceBuilder.create();
        builder.addBox(entry.box);
        const polyface = builder.claimPolyface(false);
        this.addGeometry(polyface, this.equipColors.rru.bodyCol, entry.name);
        this.drawnBoxes.push(entry);
      }
    });
    this.defectBoxes.forEach((entry) => {
        if (!this.drawnBoxes.includes(entry)) {
            const builder = PolyfaceBuilder.create();
            builder.addBox(entry.box);
            const polyface = builder.claimPolyface(false);
            const color = ColorDef.fromString("rgb(255, 115, 0)");
            this.addGeometry(polyface, color, entry.name);
            this.drawnBoxes.push(entry);
        }
    });
    this.microWaveCylinders.forEach((entry) => {
      if (!this.drawnCylinders.includes(entry)) {
        const builder = PolyfaceBuilder.create();
        builder.addCone(entry.cylinder);
        const polyface = builder.claimPolyface(false);
        this.addGeometry(polyface, entry.color, entry.name, true);
        this.drawnCylinders.push(entry);
      }
    });

    this.createGraphics(context);
  }

  /** Return true if supplied Id represents a pickable decoration created by this decorator. */
  public testDecorationHit(_id: string): boolean {
    return Array.from(this.nameIdMap.values()).includes(_id);
  }

  /** Return localized tooltip message for the decoration identified by HitDetail.sourceId. */
  public async getDecorationToolTip(_hit: HitDetail): Promise<HTMLElement | string> {
    // const sourceId = _hit.sourceId;
    // const name = Array.from(this.nameIdMap.keys()).find((key) => this.nameIdMap.get(key) === sourceId);
    // if (!name) {
    //   return "Equipment bounding box";
    // }
    // return name;
    const sourceId = _hit.sourceId;
    let name = Array.from(this.nameIdMap.keys()).find((key) => this.nameIdMap.get(key) === sourceId);
    if(name?.match(/face/ig)){
      const equipTransientId = this.objectIdMap.get(name);
      name = this.faceToEquipName(name).name;
    
    }
    let equip: any = this.currJson.filter((e)=>e.Equipment_Name == name)[0];
    if (!name)return "Equipment bounding box";
    return equip.DisplayName!;
  }

  public async onDecorationButtonEvent(hit: HitDetail, _ev: BeButtonEvent): Promise<EventHandled> {
    let sourceId = hit.sourceId;
    // SampleToolWidget.selectedList = ListEnum.Equipment;
    EquipmentShapeDecorator.sourceId = sourceId;
    let name = Array.from(this.nameIdMap.keys()).find((key) => this.nameIdMap.get(key) === sourceId);

    if(name!.includes('face')){
      const retVal = this.faceToEquipSelect(name!);
      name = retVal.name;
      sourceId = retVal.id;
      const iModelConnection = UiFramework.getIModelConnection();
      const equipId = this.nameIdMap.get(name);
      const equipId2 = this.objectIdMap.get(name);
      iModelConnection?.selectionSet.emptyAll();
      iModelConnection?.selectionSet.add(sourceId as Id64String);
      iModelConnection?.selectionSet.add(equipId as Id64String);
    }
    EquipmentShapeDecorator.equipmentName = name;
    ModifyHandleDecoration.clear();
    const currState = store.getState();
    const editModeActive = currState.dtvState.applicationState.isEditModeActive;
      if (editModeActive && !name?.match(/Micro_Wave/ig)) {
        const boxIndex = this!.boxes.findIndex((item) => item.name === name);
        // ModifyHandleDecoration.create(IModelApp.viewManager.selectedView as ScreenViewport, sourceId, name!, boxIndex, this);
        // if (ModifyHandleDecoration._decorator) {
        //   ModifyHandleDecoration._decorator._shape = this.boxes[boxIndex].box;
        //   ModifyHandleDecoration._decorator._boxIndex = boxIndex;
        //   ModifyHandleDecoration._decorator._shapeName = name!;
        //   ModifyHandleDecoration._decorator.createClipShapeControls();
        // }
      } else {
        ModifyHandleDecoration.clear();
      }


    // if (name) {
    //   IndividualShapeDecorator.selectedEquipName = name;
    //   SampleToolWidget.selectedBoxName = name;
    //   // show equipment widget
    //   UiFramework.frontstages.activeFrontstageDef?.getStagePanelDef(StagePanelLocation.Right)?.
    //     findWidgetDef("PropertyListWidget")?.setWidgetState(WidgetState.Open);
    //   if (name.match(/Box/i)) {
    //     SyncUiEventDispatcher.dispatchSyncUiEvent("box-selected");
    //     ModifyHandleDecoration.clear();
    //     SampleToolWidget.selectedBoxData = this.defectBoxInfo.find((i: any) => i.defectId === name);
    //   } else {
    //     SyncUiEventDispatcher.dispatchSyncUiEvent("equipmentselected");
    //     const isMicroWave = name.match(/Micro_Wave/i);
    //     if (isMicroWave) {
    //       ModifyHandleDecoration.clear();
    //     }
    //     if (SampleToolWidget.isEditModeActive) {
    //       if (!isMicroWave) {
    //         const boxIndex = this!.boxes.findIndex((item) => item.name === name);
    //         ModifyHandleDecoration.clear();
    //         ModifyHandleDecoration.create(IModelApp.viewManager.selectedView as ScreenViewport, sourceId, name!, boxIndex, this);
    //       } else {
    //         UiFramework.frontstages.activeFrontstageDef?.getZoneDef(ZoneLocation.CenterRight)?.
    //         findWidgetDef("EquipmentRotation")?.setWidgetState(WidgetState.Open);
    //       }
    //     }
    //   }
    //   IModelApp.tools.run(SelectionTool.toolId);
    // }
    return EventHandled.No;
  }
  public async loadBoxes(defects: any, towerAltitude: number, clearBox: boolean = false): Promise<void> {
    if (clearBox) {
      this.defectBoxes = [];
      this.boxes = [];
      this.shapes = [];
      this.drawnBoxes = [];
      this.drawnCylinders = [];
      return;
    }
    // Convert Catogrphic to spatial and calc midpoint in seperate buffers.
    for (const defect of defects) {
      const tempPoints = defect.baseSize;
      const iModel = iModelConnection!;
      const spatialPoints: Point3d[] = [];
      for (let i = 0; i < tempPoints.length; i += 2) {
          let cart = Cartographic.fromDegrees({longitude: tempPoints[i], latitude: tempPoints[i + 1], height: towerAltitude});
          const posXyz = await iModel.cartographicToSpatial(cart);
          spatialPoints.push(posXyz);
      }
      const pos = new Point3d();
      const dist: number[] = [];
      for (let i = 0; i < spatialPoints.length - 1; i++) {
        const data = spatialPoints[i];
        pos.x += data.x;
        pos.y += data.y;
        pos.z += data.z;
        // distance calc for bounding box
        const dx = data.x - spatialPoints[i + 1].x;
        const dy = data.y - spatialPoints[i + 1].y;
        const dz = data.z - spatialPoints[i + 1].z;
        // Pythogoras
        dist.push(Math.sqrt(dx * dx + dy * dy + dz * dz));
      }
      pos.x += spatialPoints[spatialPoints.length - 1].x;
      pos.y += spatialPoints[spatialPoints.length - 1].y;
      pos.z += spatialPoints[spatialPoints.length - 1].z;
      // fast midpoint calc barycentric
      pos.x = pos.x / spatialPoints.length;
      pos.y = pos.y / spatialPoints.length;
      pos.z = pos.z / spatialPoints.length;
      const equipPosition: EquipmentPosition = {
          Height: dist[1],
          Width: dist[0],
          Thicness: defect.basePosition,
          x_position: pos.x,
          y_position: pos.y,
          z_position: pos.z,
          Azimuth: 0,
          Tilt: 0,
          Roll: 0,
          Equipment_Name: "Defect",
          DisplayName: "Defect",
          Model: "",
          Manufacturer: "",
      };
      const box = this.constructBoxGeometry(equipPosition);
      if (box) {
        const name = "Box" + Math.random();
        const entry: BoxEntry = {
          box,
          name,
        };
        this.defectBoxes.push(entry);
        this.boxes.push(entry);
        defect.defectId = name;
        this.defectBoxInfo.push(defect);
        const vp = IModelApp.viewManager.selectedView;
        if (vp === undefined) return;
        const nextId = vp.iModel.transientIds.getNext();
        this.objectIdMap.set(nextId, `defectGeom#${name}`);
        this.nameIdMap.set(`${name}`, nextId);
      }
    }
  }

  public loadShapes() {
    // const selectedEquipment = SampleToolWidget.sampleToolWidget.state.operatorFilterData.selectedEquipment.length ? SampleToolWidget.sampleToolWidget.state.operatorFilterData.selectedEquipment: ["All"];;
    const selectedEquipment = ["All"];;
    this.defectBoxes = [];
    this.boxes = [];
    this.shapes = [];
    this.drawnBoxes = [];
    this.antennaBoxes = [];
    this.rruBoxes = [];
    this.drawnCylinders = [];
    this.loadedShapes = false;
    this.nameIdMap = new Map<string, Id64String>();
    IModelApp.viewManager.selectedView?.invalidateDecorations();
    IModelApp.viewManager.selectedView?.invalidateCachedDecorations(this);

    // const equipNamePositionMap: Map<string, any> = new Map(equipDataMaps.equipNamePositionMap);
    if (EquipmentsTable.equipNamePositionMap.size === 0) {
      IModelApp.viewManager.selectedView?.invalidateDecorations();
    }
    const maps = EquipmentsTable.equipNamePositionMap;
    // const tem: EquipmentPosition[] = [...tempEquipMap];
    // let tempAllIdMaps = new Map([...all3DObjectsMap.idValues]);

    for (const [currName, json] of maps) {
      if (!json) {
        return;
      }
      let entry: any;
      if(selectedEquipment[0] == "All" || selectedEquipment.indexOf(currName) != -1){
        if (currName.match(/Micro_Wave/i)) {
          entry = this.drawCylinder(json);
        } else {
          entry = this.drawBox(json);
        }
        const retVal = this.saveIntoLocalObj(json, entry);
        // tem.push(retVal?.jsonObj!);
        // tempAllIdMaps = new Map(retVal?.objectIdMap);
      }
    }
    // store.dispatch(setEquipmentDataMaps({...equipDataMaps, tempEquipMap: tem}));
    // store.dispatch(addToBuilt3DObjectIdsMap(new Map(tempAllIdMaps)));
    this.loadedShapes = true;
    IModelApp.viewManager.selectedView?.invalidateCachedDecorations(this);
  }
  /**
   * Save Entry into local object
   * @param json JSON object of Equipment Position Information
   * @param entry Box/Cone object
   * @returns void
   */
  private saveIntoLocalObj(json: EquipmentPosition, entry: any) {
    const index = this.currJson.findIndex((i) => i.Equipment_Name === json.Equipment_Name);
    // const index = tempEquipMap.findIndex((i) => i.Equipment_Name === json.Equipment_Name);
    if (index === -1) {
      if (!json.Equipment_Name.match(/Micro_Wave/i)) {
        this.boxes.push(entry);
      }
      this.currJson.push(json);
      // tem.push(json);
    }
    const vp = IModelApp.viewManager.selectedView;
    if (vp === undefined) return;
    const nextId = vp.iModel.transientIds.getNext();
    this.objectIdMap.set(nextId, `equipGeom#${json.Equipment_Name}`);
    this.nameIdMap.set(`${json.Equipment_Name}`, nextId);
    return {jsonObj: json, objectIdMap: this.objectIdMap}
  }

  /**
   * Draw Boxes with Position Information
   * @param equipJson EquipPosition information
   */
  private drawBox(equipJson: EquipmentPosition): BoxEntry {
    const box = this.constructBoxGeometry(equipJson);
    const entry: BoxEntry = {
      box,
      name: equipJson.Equipment_Name,
    };
    // Match by "type" instead of name
    if (equipJson.Equipment_Name.match(/Antenna/i)) {
      this.antennaBoxes.push(entry);
    } else if (equipJson.Equipment_Name.match(/RRU/i)) {
      this.rruBoxes.push(entry);
    }
    //Drawing equipment's face
    if(this.showFrontFace)this.drawAntRruFace(entry, equipJson);
    return entry;
  }

  public faceToEquipName(faceName: string){
    let name: string = "", type: EquipmentType | null = null;
    if(faceName.includes("ANT")){
      name=`Antenna_${faceName.split('_')[1]}`;
      type = EquipmentType.Antenna;
    } else if(faceName.includes('MW')){
      name=`Micro_Wave_${faceName.split('_')[1]}`;
      type = EquipmentType.Microwave;
    } else if(faceName.includes('RRU')){
      name=`RRU_${faceName.split('_')[1]}`;
      type = EquipmentType.RRU;
    }
    return {name, type}
  }

  
  public faceToEquipSelect(faceName: string){
    const antId=this!.nameIdMap.get(faceName!);
    // App.iModelConnection!.selectionSet.emptyAll();
    // App.iModelConnection!.selectionSet.add(antId as string);

    const {name, type} = this.faceToEquipName(faceName);
    // SampleToolWidget.setEquipmentType(type!);

    // SampleToolWidget.selectedBoxName=name;
    EquipmentShapeDecorator.sourceId=antId;
    // SampleToolWidget.selectedBoxInformation = SampleToolWidget.equipNameInfoMap.get(name);
    IndividualShapeDecorator.selectedEquipName = name!;
    
    return {name: name, id: antId as string};
}
  
  // public faceToEquipSelect(name: string){
  //   // const equip3DMaps = store.getState().detectedData
  //   const iModelC = UiFramework.getIModelConnection();
  //   const sourceId = store.getState().detectedData["equipmentDataObjectIdMaps"].objectIdMap.get(name!);
  //   if(name.includes("ANT")){
  //     name=`Antenna_${name.split('_')[1]}`;
  //     // SampleToolWidget.setEquipmentType(EquipmentType.Antenna);
  //   } else if(name.includes('MW')){
  //     name=`Micro_Wave_${name.split('_')[1]}`;
  //     // SampleToolWidget.setEquipmentType(EquipmentType.Microwave);
  //   } else if(name.includes('RRU')){
  //     name=`RRU_${name.split('_')[1]}`;
  //     // SampleToolWidget.setEquipmentType(EquipmentType.RRU);
  //   }
  //   const antId=store.getState().detectedData["equipmentDataObjectIdMaps"].objectIdMap.get(name!);
  //   // SampleToolWidget.selectedBoxName=name;
  //   EquipmentShapeDecorator.sourceId=antId;
  //   // SampleToolWidget.selectedBoxInformation = SampleToolWidget.equipNameInfoMap.get(name);
  //   IndividualShapeDecorator.selectedEquipName = name!;
    
  //   return {name: name, id: antId as string};
  // }

  /**
   * Draw Cylinders
   * @param equipJson Equipment Position Information
   * @returns Cone object
   */
  private drawCylinder(equipJson: EquipmentPosition): CylinderEntry | undefined {
    const newEquipJson = JSON.parse(JSON.stringify(equipJson));
    newEquipJson.equipSpecs={frontRad:1, backRad:1}
    
    let factors = {backPtFact: 0.5, frontPtFact: 0.5, frontRadFact: 0.5, backRadFact: 0.5};
    if(this.showFrontFace){
      // factors = {backPtFact: 0.60, frontPtFact: 0.18, frontRadFact: 0.2, backRadFact: 0.51};
    }

    const localPoint = new Point3d(0, 0, 0);
    const backPt = localPoint.plusScaled(Vector3d.unitY(), -factors.backPtFact * newEquipJson.Thicness);
    const frontPt = localPoint.plusScaled(Vector3d.unitY(), factors.frontPtFact * newEquipJson.Thicness);
    const frontRad = (newEquipJson.equipSpecs.frontRad == undefined ? newEquipJson.Height : newEquipJson.equipSpecs.frontRad*newEquipJson.Height) * factors.frontRadFact;
    const backRad = (newEquipJson.equipSpecs.backRad == undefined ? newEquipJson.Height : newEquipJson.equipSpecs.backRad*newEquipJson.Height) * factors.backRadFact;

    

    const cylinder = this.constructConeGeometry(newEquipJson, new Vector3d(0, 0, 0), backPt, frontPt, frontRad, backRad);
    if (cylinder) {
      const entry = {
        name: newEquipJson.Equipment_Name,
        cylinder: cylinder!,
        color: this.equipColors.microwave.bodyCol,
      };
      this.microWaveCylinders.push(entry);
      if(this.showFrontFace)this.drawMWCylFace(entry, newEquipJson);
      return entry;
    }
    return undefined;
  }
  /**
   * find Equipment Position
   * @param name Equipment Name
   * @returns Returns Equipment Position
   */
  public getBoxJsonByName(name: string): EquipmentPosition | undefined {
    return this.currJson.find((json) => json.Equipment_Name === name);
  }
  /**
   * Create new Box
   * @param pt Centroid Point of Equipment
   * @returns new Equipment
   */
  public async addNewBox(pt: Point3d, equipmentType: string): Promise<EquipmentPosition | undefined> {
    // let equipmentType: string = EquipmentType.Antenna;
    SyncUiEventDispatcher.onSyncUiEvent.addListener((args) => {
      if (args.eventIds.has("antenna")) {
        equipmentType = EquipmentType.Antenna;
      }
    });


    let height: number = 0;
    let width: number = 0;
    let thickness: number = 0;
    const iModel = UiFramework.getIModelConnection();
    if (equipmentType === EquipmentType.Antenna) {
      height = 2;
      width = 0.50;
      thickness = 0.25;
    }
    else if (equipmentType === EquipmentType.RRU) {
      height = 0.563;
      width = 0.32;
      thickness = 0.163;
    }
    else if (equipmentType === EquipmentType.Microwave) {
      height = 0.858;
      width = 0.83;
      thickness = 0.575;
    }
    const name = this.getEquipmentName(equipmentType);
    const json: EquipmentPosition = {
      Azimuth: 0,
      Tilt: 0,
      Roll: 0,
      x_position: pt.x,
      y_position: pt.y,
      z_position: pt.z,
      Width: width,
      Thicness: thickness,
      Height: height,
      Equipment_Name: name,
      DisplayName: name,
      Manufacturer: "",
      Model: "",
    };
      //if (!iModel.isBlank) {// Always convert from ecef as per coversion mecanisms set for this module.
                              // Quick fix for : #998270 Equipment Addition is not working on OTiQ.
      const cart = iModel!.spatialToCartographicFromEcef(pt);
      const m = DecoratorHelper.convertWGS2UTM(cart);
      pt.x = json!.x_position = m[0];
      pt.y = json!.y_position = m[1];
      pt.z = json!.z_position = cart.height;
    //} else {
    //  const htBuffer = iModel.spatialToCartographicFromEcef(pt).height;
    //  const correctZ = (htBuffer - json.z_position) + json.z_position;
    //  pt.z = json.z_position = correctZ;
    //}

    // const equipNamePositionMap = new Map(store.getState().detectedData.equipmentDataMaps.equipNamePositionMap);
    let entry: any;
    if (equipmentType === EquipmentType.Microwave) {
      entry = this.drawCylinder(json);
    } else {
      entry = this.drawBox(json);
    }
    if (entry) {
      this.saveIntoLocalObj({...json}, entry);
      const boxIndex = this.boxes.length - 1;

      EquipmentsTable.equipNamePositionMap.set(name, {...json});

      // const existingMaps = equipDataMaps;
      // const newEquipInfoMap = new Map(existingMaps.equipNameInfoMap);
      // const newEquipInfoMaps: equipmentDataMaps = {...existingMaps, equipNameInfoMap: newEquipInfoMap};
      // // store.dispatch(setEquipmentDataMaps(newEquipInfoMaps));

      const id = this.nameIdMap.get(name);
      if (iModel) {
        iModel.selectionSet.replace(id!);
        // SampleToolWidget.selectedBoxName = name;
        // SampleToolWidget.selectedBoxInformation = SampleToolWidget.equipNameInfoMap.get(name);

        // show equipment widget
        UiFramework.frontstages.activeFrontstageDef?.getStagePanelDef(StagePanelLocation.Right)?.
          findWidgetDef("PropertyListWidget")?.setWidgetState(WidgetState.Open);
        SyncUiEventDispatcher.dispatchSyncUiEvent("equipmentselected");
        // show Rotation widget
        // UiFramework.frontstages.activeFrontstageDef?.getZoneDef(ZoneLocation.CenterRight)?.
        //   findWidgetDef("EquipmentRotation")?.setWidgetState(WidgetState.Open);
      }
      if (equipmentType !== EquipmentType.Microwave) {
        // SampleToolWidget.isEditModeActive = true;
        EquipmentShapeDecorator.sourceId = id;
        ModifyHandleDecoration.clear();
        // ModifyHandleDecoration.create(IModelApp.viewManager.selectedView as ScreenViewport, id!, name, boxIndex, this);
      }
    }
    IModelApp.viewManager.selectedView?.invalidateCachedDecorations(this);

    return json;
  }
  /**
   * find equipment name
   * @param equipmentType equipment type
   * @returns name of equipment
   */
  private getEquipmentName(equipmentType: string | undefined) {
    const equips = EquipmentsTable.equipmentData.filter((i) => i.type === equipmentType);
    const index = equips.length;
    return this.recursiveFindName(equipmentType!, index);
  }
  /**
   * find name with recursve func
   * @param equipmentType type of equipment
   * @param index no of existing element
   * @returns name of new equipment
   */
  private recursiveFindName(equipmentType: string, index: number): string {
    const name = equipmentType + "_" + index;
    const exists = EquipmentsTable.equipmentData.findIndex((i) => i.name === name);
    if (exists === -1) {
      return name;
    } else {
      return this.recursiveFindName(equipmentType, index + 1);
    }
  }
  /**
   * add clone box
   * @param _equipData Position information of equipment
   * @param pt Global Point
   * @param _prevName old name of equipment
   * @returns true if successful
   */
  public async addClonedBox(_equipData: EquipmentPosition, pt: Point3d, _prevName: string): Promise<EquipmentPosition | undefined> {
    const vp = IModelApp.viewManager.selectedView!;
    const iModel = UiFramework.getIModelConnection()!;

    // let _name = SampleToolWidget.selectedBoxName;
    // let _name = selectedObjectInfo.selectedObjectNST.name;
    let _name = _prevName;
    const microwaveIndex = this.microWaveCylinders.findIndex((entry) => entry.name === _name);
    const rruIndex = this.rruBoxes.findIndex((entry) => entry.name === _name);
    let equipmentType: EquipmentType;
    if (microwaveIndex !== -1) {
      equipmentType = EquipmentType.Microwave;
    } else if (rruIndex !== -1) {
      equipmentType = EquipmentType.RRU;
    } else {
      equipmentType = EquipmentType.Antenna;
    }
    _name = this.getEquipmentName(equipmentType);
    const newJson: EquipmentPosition = {
      Equipment_Name: _name!,
      DisplayName: _name!,
      Azimuth: _equipData.Azimuth,
      Tilt: _equipData.Tilt,
      Roll: _equipData.Roll,
      x_position: pt.x,
      y_position: pt.y,
      z_position: pt.z,
      Height: _equipData.Height,
      Width: _equipData.Width,
      Thicness: _equipData.Thicness,
      Manufacturer: _equipData.Manufacturer,
      Model: _equipData.Model,
    };
    // if (!iModel.isBlank) {
      const cart = iModel.spatialToCartographicFromEcef(pt);
      const m = DecoratorHelper.convertWGS2UTM(cart);
      pt.x = newJson.x_position = m[0];
      pt.y = newJson.y_position = m[1];
      pt.z = newJson.z_position = cart.height;
    // } else {
    //   const htBuffer = iModel.spatialToCartographicFromEcef(pt).height;
    //   const correctZ = (htBuffer - pt.z) + pt.z;
    //   pt.z = newJson.z_position = correctZ;
    // }

    let entry: any;
    if (microwaveIndex !== -1) {
      entry = this.drawCylinder(newJson);
    } else {
      entry = this.drawBox(newJson);
    }

    this.saveIntoLocalObj(newJson, entry);
    // const equipNamePositionMap = new Map(store.getState().detectedData.equipmentDataMaps.equipNamePositionMap);

    EquipmentsTable.equipNamePositionMap.set(newJson.Equipment_Name, newJson);
    IModelApp.viewManager.selectedView?.invalidateCachedDecorations(this);
    return newJson;
  }

  public deleteBoxByName(_name: Id64String): boolean {
    if (!Array.from(this.nameIdMap.keys()).includes(_name)) {
      return false;
    }
    // Clear any edit arrows
    ModifyHandleDecoration.clear();

    this.boxes = this.boxes.filter((e) => e.name !== _name);
    this.shapes = this.shapes.filter((e) => e.name !== _name);
    this.currJson = this.currJson.filter((e) => e.Equipment_Name !== _name);

    const id = this.nameIdMap.get(_name);
    if (id)
      UiFramework.getIModelConnection()!.selectionSet.remove(id);
    this.nameIdMap.delete(_name);

    this.drawnBoxes = this.drawnBoxes.filter((e) => e.name !== _name);
    this.antennaBoxes = this.antennaBoxes.filter((entry) => entry.name !== _name);
    this.rruBoxes = this.rruBoxes.filter((entry) => entry.name !== _name);
    this.microWaveCylinders = this.microWaveCylinders.filter((entry) => entry.name !== _name);
    
    //Deleting faces from the equipment geometries
    let faceName = "";
    if(_name.includes('Micro')){
      faceName=`MW_${_name.split('_')[2]}_face`;
      this.drawnCylinders = this.drawnCylinders.filter((e) => e.name !== _name || e.name !== faceName);
      this.shapes = this.shapes.filter((e) => e.name !== faceName);
    } else { // For Antennas and RRUs
      faceName = _name.substring(0, 3).toUpperCase()+`_${_name.split('_')[1]}_face`;
      this.drawnBoxes = this.drawnBoxes.filter((e) => e.name !== _name || e.name !== faceName);
      this.shapes = this.shapes.filter((e) => e.name !== faceName);
    }
    // const equipNameInfoMap = new Map(store.getState().detectedData.equipmentDataMaps.equipNameInfoMap);
    // const equipNamePositionMap = new Map(store.getState().detectedData.equipmentDataMaps.equipNamePositionMap);
    
    EquipmentsTable.equipNameInfoMap.delete(_name);
    EquipmentsTable.equipNamePositionMap.delete(_name);

    IModelApp.viewManager.selectedView?.invalidateCachedDecorations(this);
    return true;
  }

  public updateBox(newBox: Box, index: number, isHeightChange: boolean = true, updateJson: boolean = true, newJson: EquipmentPosition | null = null) {
    if (newBox) {
      // const oldBox = this.boxes[index].box.clone();
      this.boxes[index].box = newBox;
      const boxName = this.boxes[index].name;
      
      const antIndex = this.antennaBoxes.findIndex((i) => i.name === boxName);
      if (antIndex !== -1)this.antennaBoxes[antIndex].box = newBox;
      
      const rruIndex = this.rruBoxes.findIndex((i) => i.name === boxName);
      if (rruIndex !== -1)this.rruBoxes[rruIndex].box = newBox;

      const boxIndex = this.drawnBoxes.findIndex((i) => i.name === boxName);
      if (boxIndex !== -1)this.drawnBoxes.splice(boxIndex, 1);

      const shapeIndex = this.shapes.findIndex((shape) => shape.name === boxName);
      if (shapeIndex !== -1)this.shapes.splice(shapeIndex, 1);
      
      if (updateJson) {
        // update currJson list
        const jsonIndex = this.currJson.findIndex((json) => json.Equipment_Name === boxName);
        const json = {...this.currJson[jsonIndex]};
        const centroid = new Point3d(json.x_position, json.y_position, json.z_position);
        const newBoxJson: EquipmentPosition = {
          Azimuth: json.Azimuth,
          Equipment_Name: json.Equipment_Name,
          DisplayName: json.DisplayName,
          Height: !isHeightChange ? json.Height : newBox.getTopOrigin().distance(newBox.getBaseOrigin()),
          Thicness: newBox.getBaseY(),
          Tilt: json.Tilt,
          Width: newBox.getBaseX(),
          x_position: centroid.x,
          y_position: centroid.y,
          z_position: centroid.z,
          Roll: json.Roll,
          Manufacturer: json.Manufacturer,
          Model: json.Model,
        };
        // const equipNamePositionMap = new Map(store.getState().detectedData.equipmentDataMaps.equipNamePositionMap);
        
        // equipNamePositionMap.set(json.Equipment_Name, newBoxJson);
        // Drawing the equipment face
        if(this.showFrontFace){
          const newBoxEntry: BoxEntry = {box: newBox,name: json.Equipment_Name};
          this.drawAntRruFace(newBoxEntry, newBoxJson);
        }
        this.currJson[jsonIndex] = newBoxJson;
      } else if(newJson != null){
        const jsonIndex = this.currJson.findIndex((json) => json.Equipment_Name === boxName);
        const json = {...newJson};
        const centroid = new Point3d(json.x_position, json.y_position, json.z_position);
        const newBoxJson: EquipmentPosition = {
          Azimuth: json.Azimuth,
          Equipment_Name: json.Equipment_Name,
          DisplayName: json.DisplayName,
          Height: !isHeightChange ? json.Height : newBox.getTopOrigin().distance(newBox.getBaseOrigin()),
          Thicness: newBox.getBaseY(),
          Tilt: json.Tilt,
          Width: newBox.getBaseX(),
          x_position: centroid.x,
          y_position: centroid.y,
          z_position: centroid.z,
          Roll: json.Roll,
          Manufacturer: json.Manufacturer,
          Model: json.Model,
        };
        // const equipNamePositionMap = new Map(store.getState().detectedData.equipmentDataMaps.equipNamePositionMap);
        
        // equipNamePositionMap.set(json.Equipment_Name, newBoxJson);
        // Drawing the equipment face
        if(this.showFrontFace){
          const newBoxEntry: BoxEntry = {box: newBox,name: json.Equipment_Name};
          this.drawAntRruFace(newBoxEntry, newBoxJson);
        }
        this.currJson[jsonIndex] = newBoxJson;

      }
      IModelApp.viewManager.selectedView?.invalidateCachedDecorations(this);
    }
  }

  public updateBoxPositionByName(_name: string, _changeVector: Vector3d): boolean {
    const index = this.boxes.findIndex((entry) => entry.name === _name);
    if (index === -1) return false;
    const jsonIndex = this.currJson.findIndex((json) => json.Equipment_Name === _name);
    const equipmentJson = {...this.currJson[jsonIndex]};

    // const equipmentJson: EquipmentPosition = {...this.currJson.find((i) => i.Equipment_Name === _name)!};
    //Making changes to the position property of the equipment position object
    equipmentJson!.x_position+=_changeVector.x;
    equipmentJson!.y_position+=_changeVector.y;
    equipmentJson!.z_position+=_changeVector.z;
    const box = this.constructBoxGeometry(equipmentJson!, _changeVector);
    this.updateBox(box!, index);
    this.currJson[jsonIndex] = equipmentJson;

    if (ModifyHandleDecoration._decorator) {
      ModifyHandleDecoration._decorator._shape = this.boxes[index].box;
      ModifyHandleDecoration._decorator._shapeIndex = index;
      ModifyHandleDecoration._decorator._shapeName = _name;
      ModifyHandleDecoration._decorator.createClipShapeControls();
    }
    return true;
  }

  public async updateBoxRotation(_name: string, newYprAngle: YawPitchRollAngles): Promise<boolean> {
    const index = this.boxes.findIndex((entry) => entry.name === _name);
    const equipmentJson: EquipmentPosition = {...this.currJson.find((i) => i.Equipment_Name === _name)!};
    // const equipmentJson2 = {...equipDataMaps.equipNameInfoMap?.get(_name)};
    equipmentJson!.Roll = newYprAngle.roll.degrees;
    equipmentJson!.Azimuth = newYprAngle.yaw.degrees;
    equipmentJson!.Tilt = newYprAngle.pitch.degrees;
    const newBox = this.constructBoxGeometry(equipmentJson!);

    this.updateBox(newBox!, index, true);
    if (ModifyHandleDecoration._decorator) {
      ModifyHandleDecoration._decorator._shape = this.boxes[index].box;
      ModifyHandleDecoration._decorator._shapeIndex = index;
      ModifyHandleDecoration._decorator._shapeName = _name;
      ModifyHandleDecoration._decorator.createClipShapeControls();
    }
    return true;
  }
  /**
   * update microwave position
   * @param _name name of Microwave
   * @param _changePosVector change vector
   * @returns success if true
   */
  public updateCylinderPosition(_name: string, _changePosVector: Vector3d): boolean {
    const jsonIndex = this.currJson.findIndex((e) => e.Equipment_Name === _name);
    if (jsonIndex === -1) return false;

    const equipJson = {...this.currJson[jsonIndex]};
    //Making changes to the position property of the equipment position object
    equipJson.x_position+=_changePosVector.x;
    equipJson.y_position+=_changePosVector.y;
    equipJson.z_position+=_changePosVector.z;
    this.currJson[jsonIndex] = equipJson;
    return this.updateCylinder(_name, equipJson, _changePosVector);
  }
  /**
   * Update Cylinder Rotation
   * @param _name name of cylinder
   * @param newVal new angle value
   * @param rotationVal enum val (Azimuth: 0, Tilt : 1, Roll : 2)
   * @returns true if rotation is successful
   */
  public updateCylinderRotation(_name: string, yprVal: YawPitchRollAngles): boolean {
    const equipIndex = this.currJson.findIndex((e) => e.Equipment_Name === _name);
    if (equipIndex === -1) return false;

    const equipJson = {...this.currJson[equipIndex]};
    equipJson.Azimuth = yprVal.yaw.degrees;
    equipJson.Tilt = yprVal.pitch.degrees;
    equipJson.Roll = yprVal.roll.degrees;

    return this.updateCylinder(_name, equipJson);
  }
  /**
   * Updtae Cylinder Radius
   * @param _name name of Cylinder
   * @param _change increase cylinder radius with val
   * @returns true if successful
   */
  public updateCylinderRadius(_name: string, _change: number): boolean {
    const jsonIndex = this.currJson.findIndex((e) => e.Equipment_Name === _name);
    if (jsonIndex === -1) return false;

    const equipJson = {...this.currJson[jsonIndex]};
    equipJson.Height = (equipJson.Height + _change);
    this.currJson[jsonIndex] = equipJson;
    return this.updateCylinder(_name, equipJson);
  }
  /**
   * Update Cylinder Thickness
   * @param _name cylinder name
   * @param _change change value from Widget
   * @returns true or false depending on success or failure
   */
  public updateCylinderThickness(_name: string, _change: number): boolean {
    const jsonIndex = this.currJson.findIndex((e) => e.Equipment_Name === _name);
    if (jsonIndex === -1) return false;

    const equipJson = {...this.currJson[jsonIndex]};
    equipJson.Thicness = (equipJson.Thicness + _change);
    this.currJson[jsonIndex] = equipJson;

    return this.updateCylinder(_name, equipJson);
  }
  /**
   * update microwave
   * @param _name name of microwave
   * @param equipJson equipment position information
   * @returns true if successful
   */
  public updateCylinder(_name: string, equipJson: EquipmentPosition, changeVal: Vector3d = new Vector3d(0, 0, 0)): boolean {
    let factors = {backPtFact: 0.5, frontPtFact: 0.5, frontRadFact: 0.5, backRadFact: 0.5};
    if(this.showFrontFace){
      // factors = {backPtFact: 0.75, frontPtFact: 0.18, frontRadFact: 0.2, backRadFact: 0.51};
    }

    const localPoint = new Point3d(0, 0, 0);
    const frontPt = localPoint.plusScaled(Vector3d.unitY(), factors.frontPtFact * equipJson.Thicness);
    const backPt = localPoint.plusScaled(Vector3d.unitY(), -factors.backPtFact * equipJson.Thicness);
    const frontRad = (equipJson.equipSpecs?.frontRad == undefined ? equipJson.Height : equipJson.equipSpecs.frontRad*equipJson.Height) * factors.frontRadFact;
    const backRad = (equipJson.equipSpecs?.backRad == undefined ? equipJson.Height : equipJson.equipSpecs.backRad*equipJson.Height) * factors.backRadFact;


    const newCylinder = this.constructConeGeometry(equipJson, changeVal, backPt, frontPt, frontRad, backRad);

    // const newCylinder = this.constructConeGeometry(equipJson, changeVal);

    if (newCylinder) {
      const drawIndex = this.drawnCylinders.findIndex((e) => e.name === _name);
      if(drawIndex >= 0)this.drawnCylinders.splice(drawIndex, 1);
      const shapeIndex = this.shapes.findIndex((shape) => shape.name === _name);
      if(shapeIndex >= 0)this.shapes.splice(shapeIndex, 1);

      const newEntry: CylinderEntry = {
        name: _name,
        cylinder: newCylinder,
        color: ColorDef.green,
      };
      const cylIndex = this.microWaveCylinders.findIndex((e) => e.name === _name);
      this.microWaveCylinders[cylIndex] = newEntry;
      IModelApp.viewManager.selectedView?.invalidateCachedDecorations(this);
      // const equipNamePositionMap = new Map(store.getState().detectedData.equipmentDataMaps.equipNamePositionMap);
      let obj = EquipmentsTable.equipNamePositionMap.get(_name);
      if(this.showFrontFace)this.drawMWCylFace(newEntry, equipJson);
      obj = equipJson;
      return true;
    }
    return false;
  }

  private drawMWCylFace(entry: CylinderEntry, equipFace: EquipmentPosition): void {
    equipFace=JSON.parse(JSON.stringify(equipFace));  //Creating new object deleting reference
    const faceName = `MW_${entry.name.split('_')[2]}_face`;
    const drawIndex = this.drawnCylinders.findIndex((e) => e.name === faceName);
    if(drawIndex >= 0)this.drawnCylinders.splice(drawIndex, 1);

    const shapeIndex = this.shapes.findIndex((shape) => shape.name === faceName);
    if(shapeIndex >= 0)this.shapes.splice(shapeIndex, 1);

    let vec = new Vector3d();
    equipFace.Equipment_Name=faceName;
    // equipFace.Thicness=.092;
    equipFace.Thicness=.05*equipFace.Thicness;
    equipFace.equipSpecs={frontRad:1, backRad:1};

    let factors = {backPtFact: 0.05, frontPtFact: 0.5, frontRadFact: 0.5, backRadFact: 0.5};
    if(this.showFrontFace){
      // factors = {backPtFact: 1.05, frontPtFact: 0.725, frontRadFact: 0.51, backRadFact: 0.52};
    }

    const localPoint = new Point3d(0, 0, 0);
    const frontPt = localPoint.plusScaled(Vector3d.unitY(), factors.frontPtFact * equipFace.Thicness);
    const backPt = localPoint.plusScaled(Vector3d.unitY(), -factors.backPtFact * equipFace.Thicness);
    const frontRad = (equipFace.equipSpecs.frontRad == undefined ? equipFace.Height : equipFace.equipSpecs.frontRad*equipFace.Height) * factors.frontRadFact;
    const backRad = (equipFace.equipSpecs.backRad == undefined ? equipFace.Height : equipFace.equipSpecs.backRad*equipFace.Height) * factors.backRadFact;


    const face = this.constructConeGeometry(equipFace, new Vector3d(0, 0, 0), backPt, frontPt, frontRad, backRad);


    // const face = this.constructConeGeometry(equipFace);
    const centA = entry.cylinder.getCenterA();
    const centB = entry.cylinder.getCenterB(); 

    vec.setStartEnd(centA, centB);
    // vec.scaleToLength(((centA.distance(centB)/2)+(equipFace.Thicness/2)), vec);
    vec.scaleToLength(((centA.distance(centB)/2)), vec);
    const trf=Transform.createTranslation(vec);
    face.tryTransformInPlace(trf);

    const builder = PolyfaceBuilder.create();
    builder.addCone(face);
    const polyface = builder.claimPolyface(false);
    
    const faceEntry = {
      name: faceName,
      cylinder: face!,
      color: this.equipColors.microwave.faceCol, //Hot pink
    };
    const nextId = IModelApp.viewManager.selectedView!.iModel.transientIds.getNext();
    this.objectIdMap.set(nextId, `equipFaceGeom#${faceName}`);
    this.nameIdMap.set(`${faceName}`, nextId);
    this.addGeometry(polyface, this.equipColors.microwave.faceCol, faceName, true);
    this.drawnCylinders.push(faceEntry);
    IModelApp.viewManager.selectedView?.invalidateCachedDecorations(this);
  }

  public drawAntRruFace(entry: BoxEntry, equipFace: EquipmentPosition): void {
    equipFace=JSON.parse(JSON.stringify(equipFace));

    let faceName = entry.name.substring(0, 3).toUpperCase();
    faceName+=`_${entry.name.split('_')[1]}_face`;

    const drawIndex = this.drawnBoxes.findIndex((e) => e.name === faceName);
    if(drawIndex >= 0)this.drawnBoxes.splice(drawIndex, 1);

    const shapeIndex = this.shapes.findIndex((shape) => shape.name === faceName);
    if(shapeIndex >= 0)this.shapes.splice(shapeIndex, 1);

    equipFace.Equipment_Name=faceName;
    const thick=equipFace.Thicness;
    equipFace.Thicness=.01;

    const face = this.constructBoxGeometry(equipFace);
    let vec = entry.box.getVectorY();
    vec.scaleToLength(((thick/2)+(equipFace.Thicness/2)), vec);
    const trf=Transform.createTranslation(vec);
    face.tryTransformInPlace(trf);

    const builder = PolyfaceBuilder.create();
    builder.addBox(face);
    const polyface = builder.claimPolyface(false);
    const color = entry.name.includes("Antenna") ? this.equipColors.antenna.faceCol : this.equipColors.rru.faceCol
    const faceEntry = {
      name: faceName,
      box: face!,
      color: color
    };
    const nextId = IModelApp.viewManager.selectedView!.iModel.transientIds.getNext();
    this.objectIdMap.set(nextId, `equipFaceGeom#${faceName}`);
    this.nameIdMap.set(`${faceName}`, nextId);

    this.addGeometry(polyface, color, faceName, true);
    this.drawnBoxes.push(faceEntry);
    IModelApp.viewManager.selectedView?.invalidateCachedDecorations(this);
  }


  /**
   * Create Cone object and draw Microwave
   * @param equipJson equipment json data
   * @param shiftPos change position vector
   * @returns Cone object
   */
  public constructConeGeometry(equipJson: EquipmentPosition, shiftPos: Vector3d = new Vector3d(0, 0, 0), backPoint: Point3d, frontPoint: Point3d, frontRadius: number, backRadius: number): Cone {
    // =====================================
    // Step 1 - Build the Cone in local coordinate system
    // Equipment Thickness represents Height of the cylinder and Equipment Height represents Diameter of the Cylinder
    // =====================================
    const localPoint = new Point3d(0, 0, 0);

    // const backPt = localPoint.plusScaled(Vector3d.unitY(), -0.5 * equipJson.Thicness);
    // const frontPt = localPoint.plusScaled(Vector3d.unitY(), 0.3 * equipJson.Thicness);
    // const frontRad = equipJson.equipSpecs.frontRad == undefined ? equipJson.Height : equipJson.equipSpecs.frontRad*equipJson.Height;
    // const backRad = equipJson.equipSpecs.backRad == undefined ? equipJson.Height : equipJson.equipSpecs.backRad*equipJson.Height;

    const cylinder = Cone.createAxisPoints(backPoint, frontPoint, frontRadius, backRadius, true);
    // Always start by building a brand new cone.... do not untransform the old box
    // =====================================
    // Step 2 - Apply Transform (local coordinate system > Global coordinate system)
    // =====================================
    // Pass the new equipment position json object
    return this.applyTransformFromLocaltoGlobal(cylinder!, localPoint, JSON.parse(JSON.stringify(equipJson)), shiftPos)  as Cone;
  }

  public constructBoxGeometry(equipJson: EquipmentPosition, shiftPos: Vector3d = new Vector3d(0, 0, 0)): Box {
    const localCentroid = new Point3d(0, 0, 0);
    // =====================================
    // Step 1 - Build the box in local coordinate system
    // =====================================
    const box = this.createBoxInLocalCoordinate(localCentroid, equipJson.Height, equipJson.Width, equipJson.Thicness);
    // Always start by building a brand new box.... do not untransform the old box
    // =====================================
    // Step 2 - Apply Transform (local coordinate system > Global coordinate system)
    // =====================================
    // Pass the new equipment position json object
    const boxTransform = this.applyTransformFromLocaltoGlobal(box!, localCentroid, JSON.parse(JSON.stringify(equipJson)), shiftPos) as Box;
    return boxTransform;
  }
  /**
   * Build the box in local coordinate system
   * @localCentroid local centroid
   * @param height equipment height
   * @param width equipment width
   * @param thickness equipment thickness
   * @param centroidPt centroid point
   * @returns create a box
   */
  private createBoxInLocalCoordinate(localCentroid: Point3d, height: number, width: number, thickness: number) {
    const localBaseCenter = new Point3d(localCentroid.x, localCentroid.y, -0.5 * height);
    const localBaseOrigin = localBaseCenter.clone();
    localBaseOrigin.x = localBaseOrigin.x - 0.5 * width;
    localBaseOrigin.y = localBaseOrigin.y - 0.5 * thickness;

    const localTopOrigin = localBaseOrigin.clone();
    localTopOrigin.z = localTopOrigin.z + height;

    const box = Box.createDgnBoxWithAxes(localBaseOrigin, Transform.identity.matrix, localTopOrigin, width, thickness, width, thickness, true);
    return box;
  }
  /**
   * create transform from local to global
   * @param boxOrConeInLocal box or cone in local coordinate
   * @param centroidPtInLocal centroid in local coordinate
   * @param equipJson Equipment Object
   * @returns Transformed Box or Cone
   */
  private applyTransformFromLocaltoGlobal(boxOrConeInLocal: SolidPrimitive, centroidPtInLocal: Point3d, equipJson: EquipmentPosition, shiftPosIn: Vector3d): SolidPrimitive {
    let oldCentroidPtInGlobal = new Point3d(equipJson.x_position, equipJson.y_position, equipJson.z_position);
    // =====================================
    // Step 0 - Calculate Centroid with Height Factor
    // =====================================
    const iModel = iModelConnection! || UiFramework.getIModelConnection();

    // Use MSL variable to store values for solve problem of OLD CONTEXT CAPTURE generated model
    let msl: number = 0;
    const cart = iModel.spatialToCartographicFromEcef(iModel.projectExtents.high!);
    oldCentroidPtInGlobal = DecoratorHelper.ExtractSpatialXYZ(cart, oldCentroidPtInGlobal.x, oldCentroidPtInGlobal.y, oldCentroidPtInGlobal.z, iModel);
    if (ConfigManager.RealityDataVersion && ConfigManager.RealityDataVersion === "OLD") {
      msl = egm96.meanSeaLevel(cart.latitudeDegrees, cart.longitudeDegrees);
      oldCentroidPtInGlobal.z = oldCentroidPtInGlobal.z - msl;
    }

    // }
    // =====================================
    // Step 1 - Apply Tilt about the X-axis (Local Coordinate)
    // =====================================
    const tiltMatrix = Matrix3d.createRotationAroundAxisIndex(AxisIndex.X, Angle.createDegrees(equipJson.Tilt));
    let trans = Transform.createFixedPointAndMatrix(centroidPtInLocal, tiltMatrix);
    // Apply to the box
    boxOrConeInLocal?.tryTransformInPlace(trans);
    let newCentroidPtInGlobal = trans.multiplyPoint3d(centroidPtInLocal.clone());
    // Apply the same transform to the box's or cone's imaginary y-vector
    let xYVec = new Vector3d(0, 1, 0);
    xYVec = trans.multiplyVector(xYVec);
    let shiftPos = trans.multiplyVector(shiftPosIn.clone());

    // =====================================
    // Step 2 - Apply -Roll about the Y-axis (Local Coordinate)
    // =====================================
    const rollMatrix = Matrix3d.createRotationAroundVector(xYVec, Angle.createDegrees(-equipJson.Roll));
    trans = Transform.createFixedPointAndMatrix(centroidPtInLocal, rollMatrix!);
    newCentroidPtInGlobal = trans.multiplyPoint3d(newCentroidPtInGlobal);
    // Apply to the box
    boxOrConeInLocal?.tryTransformInPlace(trans);
    shiftPos = trans.multiplyVector(shiftPos);

    // =====================================
    // Step 3 - Apply -Azimuth about the Z-axis (Local Coordinate)
    // =====================================
    const azMatrix = Matrix3d.createRotationAroundAxisIndex(AxisIndex.Z, Angle.createDegrees(-equipJson.Azimuth));
    trans = Transform.createFixedPointAndMatrix(centroidPtInLocal, azMatrix);
    newCentroidPtInGlobal = trans.multiplyPoint3d(newCentroidPtInGlobal);
    // Apply to the box
    boxOrConeInLocal?.tryTransformInPlace(trans);
    shiftPos = trans.multiplyVector(shiftPos);

    // =====================================
    // Step 4 - apply centroid transform (Global Coordinate)
    // =====================================
    shiftPos = shiftPos.plus(oldCentroidPtInGlobal);
    const translation = Transform.createTranslation(shiftPos);
    // Apply to the box
    boxOrConeInLocal?.tryTransformInPlace(translation);
    // multiply local centroid point
    newCentroidPtInGlobal = translation.multiplyPoint3d(newCentroidPtInGlobal);
    // =====================================
    // Step 5 - Save value in UTM
    // =====================================
    if (!iModel.isBlank) {
      const cart = iModel!.spatialToCartographicFromEcef(newCentroidPtInGlobal);
      const m = DecoratorHelper.convertWGS2UTM(cart);
      equipJson.x_position = newCentroidPtInGlobal.x = m[0];
      equipJson.y_position = newCentroidPtInGlobal.y = m[1];
      equipJson.z_position = newCentroidPtInGlobal.z = cart.height;
    } else {
      const htBuffer = iModel.spatialToCartographicFromEcef(newCentroidPtInGlobal).height;
      const correctZ = (htBuffer - newCentroidPtInGlobal.z) + newCentroidPtInGlobal.z;
      equipJson.x_position = newCentroidPtInGlobal.x;
      equipJson.y_position = newCentroidPtInGlobal.y;
      equipJson.z_position = newCentroidPtInGlobal.z = correctZ + msl;
    }
    return boxOrConeInLocal;
  }

  public createGraphics(context: DecorateContext): RenderGraphic | undefined {
    // Get next available Id to represent decoration for its life span.

    this.shapes.forEach((styledGeometry) => {
      let transientId: Id64String;
      if (styledGeometry.transientId === "") {
        transientId = context.viewport.iModel.transientIds.next;
      } else {
        transientId = styledGeometry.transientId;
      }

      const builder = context.createGraphicBuilder(GraphicType.Scene, undefined, transientId);
      // builder.wantNormals = true;
      const geometry = styledGeometry.geometry;
      builder.setSymbology(styledGeometry.color, styledGeometry.fillColor, styledGeometry.lineThickness, styledGeometry.linePixels);
      this.createGraphicsForGeometry(geometry, styledGeometry.edges, builder);

      context.addDecorationFromBuilder(builder);
    });

    // const builder = context.createGraphicBuilder(GraphicType.Scene, undefined, iModelConnection?.transientIds.next);
    // builder.wantNormals = true;

    return undefined;
  }

  private createGraphicsForGeometry(geometry: GeometryQuery, wantEdges: boolean, builder: GraphicBuilder) {
    if (geometry instanceof LineString3d) {
      builder.addLineString(geometry.points);
    } else if (geometry instanceof Loop) {
      builder.addLoop(geometry);
      if (wantEdges) {
        // Since decorators don't natively support visual edges,
        // We draw them manually as lines along each loop edge/arc
        // builder.setSymbology(ColorDef.black, ColorDef.black, 2);
        const curves = geometry.children;
        curves.forEach((value) => {
          if (value instanceof LineString3d) {
            let edges = value.points;
            const endPoint = value.pointAt(0);
            if (endPoint) {
              edges = edges.concat([endPoint]);
            }
            builder.addLineString(edges);
          } else if (value instanceof Arc3d) {
            builder.addArc(value, false, false);
          }
        });
      }
    } else if (geometry instanceof Path) {
      builder.addPath(geometry);
    } else if (geometry instanceof IndexedPolyface) {
      builder.addPolyface(geometry, true);
      if (wantEdges) {
        // Since decorators don't natively support visual edges,
        // We draw them manually as lines along each facet edge
        builder.setSymbology(ColorDef.black, ColorDef.black, 2);
        const visitor = IndexedPolyfaceVisitor.create(geometry, 1);
        let flag = true;
        while (flag) {
          const numIndices = visitor.pointCount;
          for (let i = 0; i < numIndices - 1; i++) {
            const point1 = visitor.getPoint(i);
            const point2 = visitor.getPoint(i + 1);
            if (point1 && point2) {
              builder.addLineString([point1, point2]);
            }
          }
          flag = visitor.moveToNextFacet();
        }
      }
    } else if (geometry instanceof LineSegment3d) {
      const pointA = geometry.point0Ref;
      const pointB = geometry.point1Ref;
      const lineString = [pointA, pointB];
      builder.addLineString(lineString);
    } else if (geometry instanceof Arc3d) {
      builder.addArc(geometry, false, false);
    } else if (geometry instanceof CurveChainWithDistanceIndex) {
      this.createGraphicsForGeometry(geometry.path, wantEdges, builder);
    }
  }

  public addGeometry(geometry: GeometryQuery, fillColor: ColorDef, _name: string, _edges = this.edges) {
    const styledGeometry: CustomGeometryQuery = ({
      geometry,
      color: this.color,
      fill: this.fill,
      // fillColor: ColorDef.fromTbgr(ColorDef.withTransparency(fillColor.tbgr, 255-Math.round(255*SampleToolWidget.iModelTransparency.equipment))),
      fillColor: ColorDef.fromTbgr(ColorDef.withTransparency(fillColor.tbgr, 255-Math.round(255*store.getState().detectedData.objectOpacityState.equipment.value))),
      lineThickness: this.lineThickness,
      edges: _edges,
      linePixels: this.linePixels,
      transientId: this.nameIdMap.get(_name) !== undefined ? this.nameIdMap.get(_name)! : "",
      name: _name,
    });
    this.shapes.push(styledGeometry);
  }
}
