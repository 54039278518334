import {useEffect, useState} from "react";
import "./imageSlider360.scss";
import { Modal, ModalContent, ProgressRadial } from "@itwin/itwinui-react";
import React from "react";
import tower from "./tower.jpg";

import * as THREE from 'three';
import { ConfigManager } from "../../../config/ConfigManager";

let camera, scene, renderer;

let isUserInteracting = false,
    onPointerDownMouseX = 0, onPointerDownMouseY = 0,
    lon = 0, onPointerDownLon = 0,
    lat = 0, onPointerDownLat = 0,
    phi = 0, theta = 0;


async function init(url) {

    const modalContainer = document.getElementById( 'panoramicModal' )!;
    const width = modalContainer.clientWidth;
    const height = modalContainer.clientHeight;
    const container = document.getElementById( 'threeCanvas' )!;

    camera = new THREE.PerspectiveCamera( 75, width / height, 1, 1100 );

    scene = new THREE.Scene();

    const geometry = new THREE.SphereGeometry( 500, 60, 40 );
    // invert the geometry on the x-axis so that all of the faces point inward
    geometry.scale( - 1, 1, 1 );

    const texture = new THREE.TextureLoader().load(url, ()=>document.getElementById('roundNround')?.setAttribute('style', 'display: none'));
    texture.colorSpace = THREE.SRGBColorSpace;
    const material = new THREE.MeshBasicMaterial( { map: texture } );

    const mesh = new THREE.Mesh( geometry, material );

    scene.add( mesh );

    renderer = new THREE.WebGLRenderer();
    renderer.setClearColor( 0xffffff, 0)
    renderer.setPixelRatio( window.devicePixelRatio );
    renderer.setSize( width, height );
    renderer.setAnimationLoop( animate );
    container.appendChild( renderer.domElement );
    renderer.domElement.setAttribute('style', 'margin-left: -16px');

    container.style.touchAction = 'none';
    container.addEventListener( 'pointerdown', onPointerDown );

    document.addEventListener( 'wheel', onDocumentMouseWheel );

    window.addEventListener( 'resize', onWindowResize );

}

function onWindowResize() {
    const modalContainer = document.getElementById( 'panoramicModal' )!;
    const width = modalContainer.clientWidth;
    const height = modalContainer.clientHeight;
    
    camera.aspect = width / height;
    camera.updateProjectionMatrix();

    renderer.setSize( width, height );

}

function onPointerDown( event ) {

    if ( event.isPrimary === false ) return;

    isUserInteracting = true;

    onPointerDownMouseX = event.clientX;
    onPointerDownMouseY = event.clientY;

    onPointerDownLon = lon;
    onPointerDownLat = lat;

    document.addEventListener( 'pointermove', onPointerMove );
    document.addEventListener( 'pointerup', onPointerUp );

}

function onPointerMove( event ) {

    if ( event.isPrimary === false ) return;

    lon = ( onPointerDownMouseX - event.clientX ) * 0.1 + onPointerDownLon;
    lat = ( event.clientY - onPointerDownMouseY ) * 0.1 + onPointerDownLat;

}

function onPointerUp(event) {

    if ( event.isPrimary === false ) return;

    isUserInteracting = false;

    document.removeEventListener( 'pointermove', onPointerMove );
    document.removeEventListener( 'pointerup', onPointerUp );

}

function onDocumentMouseWheel( event ) {

    const fov = camera.fov + event.deltaY * 0.05;

    camera.fov = THREE.MathUtils.clamp( fov, 10, 75 );

    camera.updateProjectionMatrix();

}

function animate() {

    if ( isUserInteracting === false ) {

        // lon += 0.01;

    }

    lat = Math.max( - 85, Math.min( 85, lat ) );
    phi = THREE.MathUtils.degToRad( 90 - lat );
    theta = THREE.MathUtils.degToRad( lon );

    const x = 500 * Math.sin( phi ) * Math.cos( theta );
    const y = 500 * Math.cos( phi );
    const z = 500 * Math.sin( phi ) * Math.sin( theta );

    camera.lookAt( x, y, z );

    renderer.render( scene, camera );

}


const ImageSlider360 = (props:{title:any,height:any,width:any,insightImageUrl:any, showImageModal:any, toggleImageModal:any ,toggle:any,setToggle:any}) => {
    const [loading,setLoading]=useState(false)
    const [showModal, setShowModal]=useState(props.showImageModal)

    useEffect(()=>{
        if(showModal && !loading)setTimeout(() => {
            const type = props.title.split(' - ');
            const theUrl = new URL(props.insightImageUrl);
            const searchParams = new URLSearchParams(theUrl.search.substring(1));
            if(searchParams.has("siteCode") && searchParams.has("clientName")){
              theUrl.search="";
              theUrl.pathname="/insite";
              theUrl.searchParams.append("site", searchParams.get("siteCode") as string);
              theUrl.searchParams.append("client", searchParams.get("clientName") as string);
              theUrl.searchParams.append("pId", ConfigManager.projectId!);
            }
          let url = "";
            switch (type[0]) {
                case "Spherical":
                    url = `${theUrl.origin}/clients/${searchParams.get("client")}/${searchParams.get("site")}/img/planning/360/tower.jpg`
                    break;
            
                case "In shelter":
                    url = `${theUrl.origin}/clients/${searchParams.get("client")}/${searchParams.get("site")}/img/planning/360/shelter.jpg`
                    break;            
            }
            init(url);
            onWindowResize();
        }, 1000);
    },  [loading]);

    const nextIteration=()=>{
        setLoading(false)
        const  ifr=document.getElementById('inlineFrameExample')
        ifr?.setAttribute("style", "display: block;")
    }

    const hideSpinner=()=>{
        if(props.toggle){
            setTimeout(() => {
                nextIteration();
                props.setToggle(false);
            }, 10000);
        }
        else{
            setTimeout(()=>{
                nextIteration();
            },2000)
          
        }
    }

    return (
        <section>          
            {showModal && 


            <Modal
              isOpen={true}
              title={props.title}
              onClose={() => {setShowModal(false)}}
              closeOnEsc={true}
              style={{width: "1600px", height: "750px", maxWidth: "70%", maxHeight: "85%"}}
          >
              <ModalContent id="panoramicModal" style={{height: "100%-50px"}}>
                <>
                    <div id="roundNround" style={{position: 'absolute', display: 'flex',  justifyContent:'center', alignItems:'center', height: '100%', maxHeight: "100%", width: '100%', backgroundColor: "white", left: '0px', zIndex: 10}}>
                      <ProgressRadial size="large" style={{width: "160px", height: "200px"}} indeterminate value={50} >Loading...</ProgressRadial>
                    </div>
                    <div id="threeCanvas" style={{position: 'absolute', display: 'flex',  justifyContent:'center', alignItems:'center', height: 'inherit', borderRadius: "0 0 4px 4px", zIndex: 1}}></div>
                    
                    {/* <iframe id="inlineFrameExample"
                        title="Panoramic view"
                        width="100%"
                        height="730px"
                        src={props.insightImageUrl}
                        // src={"https://westeurope-dev.insite.bentley.com/insite_app_js/html/360_panoramic.html?site=Vantage_Segovia_Fine_Tunned&client=testOTIQ&pId=58862c13-ca4a-4020-801a-4b9aee1c22b7"}
                        style={{display: "none"}}
                        onLoadedData={()=>{

                        }}
                        onLoad={()=>{
                            setTimeout(() => {                                
                                const  ifr=document.getElementById('inlineFrameExample')
                                ifr?.setAttribute("style", "display: block;")
                                const  rnr=document.getElementById('roundNround')
                                rnr?.setAttribute("style", "display: none;")
                            }, 20000);
                            // setLoading(false)
                            }
                        }

                    /> */}
                </>
                
              </ModalContent>


                {/* <div id="modal" className='imageViewModel'>
                    <ModalDialog title={props.title} Y={100} width={props.width} height={props.height} onClose={() => props.toggleImageModal()}>
                    <div className="imodel-viewport-dialog-container">
                        <div id='container'>
                        {loading ? (
                                        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center', height: '100%'}}>
                                             <Spinner size={SpinnerSize.XLarge} />
                                        </div>
                                        ) :null
                        }                      
                            <iframe id="inlineFrameExample"
                                title="Inline Frame"
                                width="100%"
                                height="100%"
                                src={props.insightImageUrl}
                                style={{display: "none"}}
                                onLoad={hideSpinner}
                                >
                            </iframe>
                        </div>
                    </div>    
                    </ModalDialog>
                 </div> */}
            </Modal>

            }
        </section>
    );
};


export default ImageSlider360;