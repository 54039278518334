import { Logger } from "@itwin/core-bentley";
import AxiosInstance from "axios";
import { Logger as TSLogger } from "../api/logging"
import { UrlConfigManager } from "../../config/UrlConfigManager";

const LOGGER_CATEGORY = "TowerSight.BuddiHelper";

export class BuddiHelper {
  // Helper for the Buddi REST api https://buddi.bentley.com/WebService

  private static BUDDI_URL = "https://buddi.bentley.com/WebService";

  /**
   * @description Fetch the ims profile url on the buddi REST api
   * @returns a Promise that resolved the url
   */
  public static getIMSProfileURL = async (): Promise<string> => {
    Logger.logTrace(LOGGER_CATEGORY, "Get IMS Profile url request called");
    return BuddiHelper.getUrl("IMSProfile");
  };

  /**
   * @description Fetch the terms of service url on the buddi REST api
   * @returns a Promise that resolved the url
   */
  public static getTermsOfServiceURL = async (): Promise<string> => {
    Logger.logTrace(LOGGER_CATEGORY, "Get Terms of service url request called");
    return BuddiHelper.getUrl("ConnectTermsOfServiceUrl");
  };

  /**
   * @description Fetch the privacy url on the buddi REST api
   * @returns a Promise that resolved the url
   */
  public static getPrivacyURL = async (): Promise<string> => {
    Logger.logTrace(LOGGER_CATEGORY, "Get privacy url request called");
    return BuddiHelper.getUrl("Bentley.PrivacyPolicy");
  };

  /**
   * @description Fetch the terms of use url on the buddi REST api
   * @returns a Promise that resolved the url
   */
  public static getTermsOfUseUrl = async (): Promise<string> => {
    Logger.logTrace(LOGGER_CATEGORY, "Get terms of use url request called");
    return BuddiHelper.getUrl("Bentley.TermsOfUse");
  };

  /**
   * @description Fetch the cookies url on the buddi REST api
   * @returns a Promise that resolved the url
   */
  public static getCookiesUrl = async (): Promise<string> => {
    Logger.logTrace(LOGGER_CATEGORY, "Get cookies url request called");
    return BuddiHelper.getUrl("CookieStatement.Url");
  };

  /**
   * @description Fetch the contact us url on the buddi REST api
   * @returns a Promise that resolved the url
   */
  public static getContactUsUrl = async (): Promise<string> => {
    Logger.logTrace(LOGGER_CATEGORY, "Get contact us url request called");
    return BuddiHelper.getUrl("Bentley.ContactUs.Url");
    };

    public static getConnectPDFMarkupUrl = async (): Promise<string> => {
        Logger.logTrace(LOGGER_CATEGORY, "Get ConnectPDFMarkup url request called");
        return BuddiHelper.getUrl("ConnectPDFMarkup");
    };

    public static getProjectShareUrl = async (): Promise<string> => {
        Logger.logTrace(LOGGER_CATEGORY, "Get ProjectShare url request called");
        return BuddiHelper.getUrl("ProjectShare.Url");
    };

    public static getTowerSightPortalUrl = async (): Promise<string> => {
      Logger.logTrace(LOGGER_CATEGORY, "Get TowerSightPortal url request called");
      return BuddiHelper.getUrl("TowerSightPortal.Url");
    };

  /**
   * @description Fetch the help url on the buddi REST api
   * @returns a Promise that resolved the url
   */
  public static getHelpUrl = async (): Promise<string> => {
    Logger.logTrace(LOGGER_CATEGORY, "Get help url request called");
    return BuddiHelper.getUrl("OpenFlowsWaterSight.Community");
  };
  /**
   * @description Fetch the help url on the buddi REST api
   * @returns a Promise that resolved the url
   */
  public static getConnectUrl = async (): Promise<string> => {
    Logger.logTrace(LOGGER_CATEGORY, "Get help url request called");
    TSLogger.trace(LOGGER_CATEGORY, "Get IMS Profile url request called")
    return BuddiHelper.getUrl("CONNECTEDContextService.URL");
  };

  /**
   * @description Fetch the oidc url on the buddi REST api
   * @returns a Promise<string> that resolved the url
   */
  public static getOidcUrl = async (): Promise<string> => {
    Logger.logTrace(LOGGER_CATEGORY, "Get oidc url request called.");
    return BuddiHelper.getUrl("IMSOpenID");
    };

  /**
   * @description Fetch the url on the buddi REST api
   * @argument urlName: string. Url Name can be found here https://buddi.bentley.com/Urls
   * @argument regionName: string. Region name can be found here https://buddi.bentley.com/Urls
   * @returns a Promise that resolved the url test
   */
  public static getUrl = async (urlName: string): Promise<string> => {
    // Config.App.set("imjs_buddi_resolve_url_using_region", "103");
    // const region: string = process.env.IMJS_BUDDI_RESOLVE_URL_USING_REGION!;
    // const region: string = "101";
    const region: string = UrlConfigManager.env!.toString();
    Logger.logTrace(
      LOGGER_CATEGORY,
      "Get url request called " + urlName + " " + region,
    );
    const requestedUrl = BuddiHelper.constructGetUrlRequest(urlName, region);
    const response = await AxiosInstance.get(requestedUrl);
    return BuddiHelper.processGetUrlResponse(response);
  };

  /**
   * @description Process the response of a get url request to buddi REST
   * api. Verify that status.
   * @argument response The response of the request.
   * @returns string The IMS profile url fetched on the Buddi REST api.
   */
  private static processGetUrlResponse = (response: any): string => {
    if (response.status !== 200) {
      Logger.logError(LOGGER_CATEGORY, "Get URL request failed ", response);
      return "";
    }
    return response.data.result.url;
    };

  /**
   * @description construct a get url request
   * @argument urlName: string. Url Name can be found here https://buddi.bentley.com/Urls
   * @argument regionName: string. Region name can be found here https://buddi.bentley.com/Urls
   * @returns string. Url of the get request
   */
  private static constructGetUrlRequest = (
    urlName: string,
    regionName: string,
  ): string => {
    Logger.logTrace(LOGGER_CATEGORY, "construct get url request called");
    return (
      BuddiHelper.BUDDI_URL + "/GetUrl?url=" + urlName + "&region=" + regionName
    );
  };
}
