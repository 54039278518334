

import ReactDOM from "react-dom";
import React from 'react'
import BracingsClient from "../../api/bracingsClient";
import { bracTrans, HighlightBracingsTool } from "./HighlightBracingsTool";
import { getValueInRequiredUnit } from "../Tables/BaseTable";
import { WidgetState } from "@itwin/appui-abstract";
import { UiFramework } from "@itwin/appui-react";
import { ColorDef } from "@itwin/core-common";
import { PrimitiveTool, IModelApp, NotifyMessageDetails, OutputMessagePriority, OutputMessageType, SelectionTool, BeButtonEvent, EventHandled, Viewport } from "@itwin/core-frontend";
import { empty } from "apollo-link";
import { RootState } from "../../../store/States";
import { addToBuilt3DObjectIdsMap, setBracingsData, setBracingsData3DMaps, updateBuilt3DObjectIdsMap } from "../../../store/detectedData/apiDataActionTypes";
import { store } from "../../../store/rootReducer";
import { BracingDecorator } from "../../tools/decorators/BracingDecorator";
import { resetObjectIds } from "../HorizontalToolbarItems";
//------------------------------------------------------------------------------------
//This replaces Add Defects tool.
function select(state: RootState, dataKey: string) {
    return state.detectedData[dataKey];
}

export class AddEditBracingTool extends PrimitiveTool {

    public static override toolId = "AddBracingTool";
    
    private static bracingDecorator: BracingDecorator | undefined;
    private static clickCount: number | undefined;
    public static callback: any | undefined;
    private static proximityBracings: any[];            //All the bracings detected in proximity to the bracing that needs to be added.
    private static uiInfo: any;

    public static fdata: any;                           //This holds all the relevant fetched data for used from the database. TowerStructure and Bracings Data
    public static saveBracingArray : any;
    //Variables being set in the Side popout UI
    public static bracingType: string | undefined;      //Type of bracing shape.
    public static topPoint: any | undefined;            //End point for bracing.
    public static bottomPoint: any | undefined;         //Start point for bracing.
    public static ismhChecked: boolean = false;         //Mid horizontal checkbox.
    public static isthChecked: boolean = false;         //Top horizontal checkbox.
    public static isProximity: boolean = true;         //Proximity checkbox.

    //Edit Bracing Varibales
    public static isBracingEditable: boolean = false;
    //--------------------------------------------
    /*
    * 
    */
    public override run(UIInfo : any, callback=undefined): Promise<any> {
        super.run();
        const { toolAdmin, viewManager } = IModelApp;
        if (!this.isCompatibleViewport(viewManager.selectedView, false) || !toolAdmin.onInstallTool(this)) {
            return Promise.resolve(false);
        }
        //-------------
        AddEditBracingTool.clickCount = 0;
        AddEditBracingTool.bracingDecorator = new BracingDecorator();
        AddEditBracingTool.callback = callback;

        AddEditBracingTool.fetchData();//get bracing data from dbase.

        //Note : for top and bottom point the points have to be collected in the tool itelsef
        //so only a flag/boolean to collect point/altitude(here) is sent via the UI , 
        //but for Bracing Type a specific string is sent to tell what the bracing shape should be.
        AddEditBracingTool.uiInfo = UIInfo;

        toolAdmin.startPrimitiveTool(this);
        toolAdmin.onPostInstallTool(this);

        // FrontstageManager.activeFrontstageDef?.getZoneDef(ZoneLocation.CenterRight)?.findWidgetDef("BracingAddEdit")?.setWidgetState(WidgetState.Open);
        //-------------
        return Promise.resolve({resolve:true,topPoint:AddEditBracingTool.topPoint,bottomPoint:AddEditBracingTool.bottomPoint});
    }
    //-------------------------------------------------------------------//
    // Get the data from the API's
    //-------------------------------------------------------------------//
    private static async fetchData() {
        // let bracingsData = await BracingsClient.getBracingsData(App.accessToken);
        // let towerStructure = await BracingsClient.getTowerStructureJson(App.accessToken);

        let towerStructure = select(store.getState(), "towerStructureData");
        let towerBracings = select(store.getState(), "towerBracingsData");

        if (towerBracings && towerStructure) {
            AddEditBracingTool.fdata = {
                "tower-structure": towerStructure,
                "bracings": [...towerBracings]
            };
        }
        else {
            AddEditBracingTool.fdata = "empty";//Project has no data.
        }
    }

    public static runFetchData(){
        AddEditBracingTool.fetchData();
    }
    //------------------------------------------------------------------------------------
    public static getbracingTowerData() {
        return AddEditBracingTool.fdata;
    }
    //------------------------------------------------------------------------------------
    /* Runs when the tool is rerun after being instanciated once.
     */
    public onRestartTool(): Promise<void> {
        const tool = new AddEditBracingTool();
        if (!tool.run(AddEditBracingTool.uiInfo))
            this.exitTool();
        return Promise.resolve();
    }
    //------------------------------------------------------------------------------------
    /* Callback event from dom. See: OnDataButtonDown()
     * The event is loaded in the onDataButtonDown() for this tool,
     * So event will remain isolated to this context only.
     */
    private onMouseClickUp = (event) => {
        event.preventDefault();
        //event.button == 2 signifies the right click on mouse. So this runs on mouseup for right click
        if (event.button == 2) {
            IModelApp.tools.run(SelectionTool.toolId);//run the default tool and stop this Primitive tool.
        }
    }
    //------------------------------------------------------------------------------------
    /*
    * Not in use
    */
    private static invalidateDecoratorCache() {
        if (AddEditBracingTool.bracingDecorator != undefined) {
            IModelApp.viewManager.addDecorator(AddEditBracingTool.bracingDecorator!);
            IModelApp.viewManager.selectedView?.invalidateCachedDecorations(AddEditBracingTool.bracingDecorator!);
        }
    }
    //------------------------------------------------------------------------------------
    /**
     * @param bracingsArray the data set including the newly staged/created bracing
     * 
     * Sorts the bracings object meant to save base on elevations.
     * in this case the elvation 'end'. 
     * This helps sort for correct spec relevant saving of bracing data.
    */
    public static quickSortBracings(bracingsArray: any) {
        let array = bracingsArray;
        return array.sort(function (a, b) {
            if (a.end < b.end) {
                return -1;
            }else {
                return 1;
            }
        });
    }
    //------------------------------------------------------------------------------------
    /**
    * @param proximityFactor determines the the threshold for proximity detection between created and exisinting bracings
    * @param bracingData the Fetched saved bracing data
    * @param bracing the newly created bracing staged for cmparisons.
    * 
    *   Method comapares elevations for the bracing data set and checks wich 
    *   bracings are in Proximit. Return an array of detected nerby bracings.
    */
    private static checkForNearestElevations(proximityFactor: number, bracingData: any[], bracing:any) {

        let parr: any = [];
        for (let i = 0; i < bracingData.length; i++) {
            let dist1 = bracingData[i].start - bracing.end;//compare bracing base 
            let dist2 = bracingData[i].end - bracing.start;

            dist1 < 0 ? dist1 *= -1 : dist1 = dist1;//make unsigned or positive if negative.
            dist2 < 0 ? dist2 *= -1 : dist2 = dist2;//make unsigned or positive if negative.

            //Proximity bracings
            if (dist1 <= proximityFactor) {
                let br = {
                    type: "jointop",//join the top of the new bracing with the detected bracings bottom
                    data : bracingData[i]
                }
                parr.push(br)
            }
            if (dist2 <= proximityFactor) {
                let br = {
                    type: "joinbottom",//join the base of the new bracing with the detected bracings top
                    data: bracingData[i]
                }
                parr.push(br)
            }
        }
        return parr;
    }
    //------------------------------------------------------------------------------------
    /*
     * 
     */ 
    public static updateTheTopBottomOncePointsOnEdit() {
        let bDecor = IModelApp.viewManager.decorators.filter(e => e.constructor.name.includes("BracingDecorator"))[0] as BracingDecorator;
        if (bDecor != undefined && bDecor.selectedBracings != undefined) {
            AddEditBracingTool.topPoint = { height: bDecor.selectedBracings.modelData.parentBracingHeights.bracingTopH };
            AddEditBracingTool.bottomPoint = { height: bDecor.selectedBracings.modelData.parentBracingHeights.bracingBotH };
        }
        else {
            IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Warning, "No Bracing Selected Please Try Again", "", OutputMessageType.Toast));
        }
    }
    //------------------------------------------------------------------------------------
    /*
    * Uses the selected bracing stored in the BracingDecorator local object and 
    * removes it by splicing the index from the set. 
    */
    public static async deleteBracingFromData() {

        const bdec = IModelApp.viewManager.decorators.filter(e => e.constructor.name.includes("BracingDecorator"))[0] as BracingDecorator;

        if (AddEditBracingTool.fdata == undefined && AddEditBracingTool.fdata != empty) {
            await AddEditBracingTool.fetchData();//get bracing data from dbase.
        }

        AddEditBracingTool.saveBracingArray = JSON.parse(JSON.stringify(AddEditBracingTool.fdata));

        if (AddEditBracingTool.saveBracingArray != undefined && bdec.selectedBracings != undefined && bdec.selectedBracings.modelData != undefined) {

            for (let e = 0; e < AddEditBracingTool.saveBracingArray.bracings.length; e++) {//Search
                let bts = bdec.selectedBracings.modelData.parentBracingHeights.bracingTopH;
                let btd = AddEditBracingTool.saveBracingArray.bracings[e].end;

                let bbs = bdec.selectedBracings.modelData.parentBracingHeights.bracingBotH;
                let bbd = AddEditBracingTool.saveBracingArray.bracings[e].start;
                if (bts == btd && bbs == bbd) {
                    AddEditBracingTool.saveBracingArray.bracings.splice(e, 1);
                }
            }
        }
        AddEditBracingTool.updateBracings();//uses the AddEditBracingTool.saveBracingArray to overwrite/update the bracing data.
    }
    //------------------------------------------------------------------------------------
    /**
    * Saves the bracing data generated in
    * AddBracingTool Sorts and rearranges the
    * bracing ids so as to work with the nuances this
    * specific api
    */
    public static async saveNewBracing() {

        if (AddEditBracingTool.saveBracingArray != undefined && AddEditBracingTool.saveBracingArray.bracings != undefined) {
            IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Info, "Saving...", "", OutputMessageType.Toast));

            //Quick sort the bracing baseed on elevations
            AddEditBracingTool.quickSortBracings(AddEditBracingTool.saveBracingArray.bracings);

            let startid = AddEditBracingTool.saveBracingArray.bracings[0].bracingId;
            //This step is needed as the api also sorts the array based on bracing id instead of elevations , dont know why.
            //re generate the ids so that it alighs with the order of the bracing elevations.
            for (let j = 0; j < AddEditBracingTool.saveBracingArray.bracings.length; j++) {
                AddEditBracingTool.saveBracingArray.bracings[j].bracingId = startid;
                startid++;
            }

            if (AddEditBracingTool.saveBracingArray.bracings.length > 0) {
                let status = await BracingsClient.postBracingData(AddEditBracingTool.saveBracingArray.bracings, store.getState().auth.accessTokenStatePrivateAPI.accessToken!);

                //Aditya //should not be excepting Error , this Error mesage needs to be resolved from the API side !!Critical!! 14-06-2023
                //issue returns error even though the data is saved.
                if (status == "Success" || status == "Error") {
                    store.dispatch(setBracingsData(AddEditBracingTool.saveBracingArray.bracings))
                    AddEditBracingTool.reloadAllBracingsUpdatedData();
                    IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Info, "Bracing Saved", "", OutputMessageType.Toast));
                } else {
                    IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Error, "Error While Trying to Save", "", OutputMessageType.Toast));
                }
            }
        }
        else {
            IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Warning, "Nothing To Save", "", OutputMessageType.Toast));
        }
    }
    //------------------------------------------------------------------------------------
    /**
     * Note : Same as  saveNewBracing() above , just diffrent messages.
     * Api has only one post method and is data is overwritable.
     * use the same API call to do , save /update/delete
     */
    public static async updateBracings() {
        //Use the saveBracginArray to ower-write the entire data set in the database.
        if (AddEditBracingTool.saveBracingArray != undefined && AddEditBracingTool.saveBracingArray.bracings != undefined) {
            IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Info, "Updating...", "", OutputMessageType.Toast));

            //Quick sort the bracing baseed on elevations
            AddEditBracingTool.quickSortBracings(AddEditBracingTool.saveBracingArray.bracings);

            let startid = AddEditBracingTool.saveBracingArray.bracings[0].bracingId;
            //This step is needed as the api also sorts the array based on bracing id instead of elevations , dont know why.
            for (let j = 0; j < AddEditBracingTool.saveBracingArray.bracings.length; j++) {
                AddEditBracingTool.saveBracingArray.bracings[j].bracingId = startid;
                startid++
            }
            if (AddEditBracingTool.saveBracingArray.bracings.length > 0) {
                let status = await BracingsClient.postBracingData(AddEditBracingTool.saveBracingArray.bracings, store.getState().auth.accessTokenStatePrivateAPI.accessToken!);

                //Aditya //should not be excepting Error , this Error mesage needs to be resolved !!Critical!!
                if (status == "Success" || status == "Error") {
                    store.dispatch(setBracingsData(AddEditBracingTool.saveBracingArray.bracings))
                    // AddEditBracingTool.reloadAllBracingsUpdatedData();
                    const bracingDec = IModelApp.viewManager.decorators.filter((e) => e.constructor.name.includes("BracingDecorator"))[0] as BracingDecorator;
                    bracingDec.resetBracings({"tower-structure": store.getState().detectedData.towerStructureData, bracings: AddEditBracingTool.saveBracingArray.bracings});
                    const data2Send = {"tower-structure": store.getState().detectedData.towerStructureData, bracings: AddEditBracingTool.saveBracingArray.bracings};
                    await BracingDecorator.refreshDecoratorBracingData(data2Send);
                    bracingDec.resetBracings(data2Send);
                    IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Info, "Bracings Are Updated", "", OutputMessageType.Toast));
                } else {
                    IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Error, "Error While Trying to Update", "", OutputMessageType.Toast));
                }
            }
        }
        else {
            IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Warning, "Nothing To Update", "", OutputMessageType.Toast));
        }
    }
    //------------------------------------------------------------------------------------
    /*
     * Clear older data in working data set used to genrate 
     * Bracing. THis helps avoid unwanted transformations on older selected
     * objects or data when nothing new is slected to overwrite it.
     */ 
    public static clearResiduals() {
        AddEditBracingTool.topPoint = undefined;
        AddEditBracingTool.bottomPoint = undefined;
        AddEditBracingTool.bracingType = undefined;
        AddEditBracingTool.ismhChecked = false;
        AddEditBracingTool.isthChecked = false;
        const bDecor = IModelApp.viewManager.decorators.filter(e => e.constructor.name.includes("BracingDecorator"))[0] as BracingDecorator;
        if (bDecor != undefined && bDecor.selectedBracings != undefined) {
            bDecor.selectedBracings = undefined;//clear the slected bracing info 
        }
    }
    /*
    * Clear and Update the side popout pannel
    */
    public static clearSidePannel() {
        let bt = document.getElementById("bracing-type") as HTMLInputElement
        if (bt) {
            bt.value = "Select";
        }

        let e1 = document.getElementById("thorizontals-cb") as HTMLInputElement
        if (e1) {
            e1.checked = false;
        }
        let e2 = document.getElementById("mhorizontal-cb") as HTMLInputElement
        if (e2) {
            e2.checked = false;
        }
        let te = document.getElementById("te-value") as HTMLInputElement;
        let be = document.getElementById("be-value") as HTMLInputElement;
        //------------------------------    
        if (te) {

            te.value = "0";
        }
        if (be) {

            be.value = "0";
        }
    }
    //------------------------------------------------------------------------------------
    /*
    * Function updates the values in the Popout pannel 
    * So that the user can get the most recent value changes 
    * For the elevation values and the CheckBox states. (Not working robustly need to scrutinise.)'
    * 
    * Function is invoked in relvant events. to resolve .Rightclick->Find All Refrences. 
    */
    public static async updatePopoutPannel() {

        //Run once if not data is not fetched alrady for use 
        if (AddEditBracingTool.fdata == undefined && AddEditBracingTool.fdata != empty) {
            await AddEditBracingTool.fetchData();//get bracing data from dbase.
        }
        //------------------------------
        if (AddEditBracingTool.isBracingEditable == false) {
            if (AddEditBracingTool.topPoint != undefined && AddEditBracingTool.topPoint.height != undefined && AddEditBracingTool.fdata != undefined && AddEditBracingTool.fdata["tower-structure"] != undefined) {
                const topph = (AddEditBracingTool.topPoint.height - AddEditBracingTool.fdata["tower-structure"]["base_altitude"]).toFixed(4);
                let te = document.getElementById("te-value") as HTMLInputElement;
                if (te) {
                    te.value = `${getValueInRequiredUnit(topph)}`
                }
            }
            if (AddEditBracingTool.bottomPoint != undefined && AddEditBracingTool.bottomPoint.height != undefined && AddEditBracingTool.fdata != undefined && AddEditBracingTool.fdata["tower-structure"] != undefined) {
                const botth = (AddEditBracingTool.bottomPoint.height - AddEditBracingTool.fdata["tower-structure"]["base_altitude"]).toFixed(4);
                let be = document.getElementById("be-value") as HTMLInputElement;
                if (be) {
                    be.value = `${getValueInRequiredUnit(botth)}`;
                }
            }

            //------------------------------
            if (AddEditBracingTool.uiInfo != undefined && AddEditBracingTool.uiInfo.bracingType != undefined && AddEditBracingTool.uiInfo.bracinType != "") {
                AddEditBracingTool.bracingType = AddEditBracingTool.uiInfo.bracingType;
            }
            let e1 = document.getElementById("thorizontals-cb") as HTMLInputElement
            if (e1) {
                e1.checked = AddEditBracingTool.isthChecked;
            }
            let e2 = document.getElementById("mhorizontal-cb") as HTMLInputElement
            if (e2) {
                e2.checked = AddEditBracingTool.ismhChecked;
            }
            //------------------------------
            //Show hide relvant bottom buttons if in create mode or Edit mode.
            //------------------------------
            const nbtns = document.getElementById('gen-save');
            const ebtns = document.getElementById('gen-edit-save');
            const csub = document.getElementById('create-sub');
            const esub = document.getElementById('edit-sub');
            const bmm = document.getElementById('brac-mod-merge-split');
            if (nbtns) {
                nbtns.style.display = 'block';
            }
            if (ebtns) {
                ebtns.style.display = 'none';
            }
            if (csub) {
                csub.style.display = 'block';
            }
            if (esub) {
                esub.style.display = 'none';
            }
            if (bmm) {
                bmm.style.display = 'none';
            }
            //----
        }
         //------------------------------
         //If Edit is enabled , show values from decorator select.
         //------------------------------
        if (AddEditBracingTool.isBracingEditable == true) {
            //------------------------------
            const bDecor = IModelApp.viewManager.decorators.filter(e => e.constructor.name.includes("BracingDecorator"))[0] as BracingDecorator;
            if (bDecor != undefined && bDecor.selectedBracings != undefined) {
                let bt = document.getElementById("bracing-type") as HTMLInputElement
                let bts = document.getElementById("bracing-type-sub") as HTMLInputElement
                if (bt && bts) {
                    bt.value = "Select"
                    bts.value = "Select-sub"//bDecor.selectedBracings.modelData.pattern;
                    //if (bDecor.selectedBracings.modelData.pattern == "D UP") {
                    //    bt.value = "d_up";
                    //}
                    //if (bDecor.selectedBracings.modelData.pattern == "D DOWN") {
                    //    bt.value = "d_down";
                    //}
                    //if (bDecor.selectedBracings.modelData.pattern == "K UP") {
                    //    bt.value = "k_up";
                    //}
                    //if (bDecor.selectedBracings.modelData.pattern == "K DOWN") {
                    //    bt.value = "k_down";
                    //}
                    //if (bDecor.selectedBracings.modelData.pattern == "X") {
                    //    bt.value = "x";
                    //}
                }
                //------------------------------
                let e1 = document.getElementById("thorizontals-cb") as HTMLInputElement
                if (e1) {
                    bDecor.selectedBracings.modelData.horizTopBay == "Yes" ? e1.checked = true : e1.checked = false;
                }
                let e2 = document.getElementById("mhorizontal-cb") as HTMLInputElement
                if (e2) {
                    bDecor.selectedBracings.modelData.horizMiddleBay == "Yes" ? e2.checked = true : e2.checked = false;
                }
            }

            //------------------------------
            let te = document.getElementById("te-value") as HTMLInputElement;
            let be = document.getElementById("be-value") as HTMLInputElement;
            //------------------------------
            if (AddEditBracingTool.topPoint != undefined && AddEditBracingTool.topPoint.height != undefined &&  AddEditBracingTool.fdata != undefined && AddEditBracingTool.fdata["tower-structure"] != undefined) {
                if (te) {
                    let val = (AddEditBracingTool.topPoint.height - AddEditBracingTool.fdata["tower-structure"]["base_altitude"]).toFixed(4);
                    te.value = `${getValueInRequiredUnit(val)}`
                }
            }
            if (AddEditBracingTool.bottomPoint != undefined && AddEditBracingTool.bottomPoint.height != undefined && AddEditBracingTool.fdata != undefined && AddEditBracingTool.fdata["tower-structure"] != undefined) {
                if (be) {
                    let val = (AddEditBracingTool.bottomPoint.height - AddEditBracingTool.fdata["tower-structure"]["base_altitude"]).toFixed(4);
                    be.value = `${getValueInRequiredUnit(val)}`;
                }
            }
            //------------------------------
            //Show hide relvant buttons if in create mode or Edit mode.
            //------------------------------
            const nbtns = document.getElementById('gen-save');
            const ebtns = document.getElementById('gen-edit-save');
            const csub = document.getElementById('create-sub');
            const esub = document.getElementById('edit-sub');
            const bmm = document.getElementById('brac-mod-merge-split');

            if (nbtns) {
                nbtns.style.display = 'none';
            }
            if (ebtns) {
                ebtns.style.display = 'block';
            }
            if (csub) {
                csub.style.display = 'none';
            }
            if (esub) {
                esub.style.display = 'block';
            }
            if (bmm) {
                bmm.style.display = 'block';
            }
        }
    }
    //------------------------------------------------------------------------------------
    /*
    * Uses the selected bracing stored in the BracingDecorator local object and 
    * removes it by splicing the index from the set. 
    */
    public static async generateMergedBracing(shape: string, dir: string) {

        const bdec = IModelApp.viewManager.decorators.filter(e => e.constructor.name.includes("BracingDecorator"))[0] as BracingDecorator;

        if (AddEditBracingTool.fdata == undefined && AddEditBracingTool.fdata != empty) {
            await AddEditBracingTool.fetchData();//get bracing data from dbase.
        }

        if (AddEditBracingTool.fdata != undefined && bdec.selectedBracings != undefined && bdec.selectedBracings.modelData != undefined) {

            let bracings = AddEditBracingTool.fdata["bracings"];
            let heights = BracingDecorator.selectedBracing.modelData.parentBracingHeights;

            //get top and bottom bracing
            let aboveBrace: any = undefined;
            let belowBrace: any = undefined;

            let factor = 0.2;
            for (let b = 0; b < bracings.length; b++) {
                let suba = heights.bracingBotH.toFixed(1) - bracings[b].end.toFixed(1)//allow for minor variation as per factor during matches
                if (suba < 0) { suba *= -1}
                if (suba < factor/*heights.bracingBotH.toFixed(1) == bracings[b].end.toFixed(1)*/) {
                    belowBrace = bracings[b]
                }
                let subb = heights.bracingTopH.toFixed(1) - bracings[b].start.toFixed(1)//allow for minor variation during matches
                if (subb < 0) { subb *= -1 }
                if (subb < factor/*heights.bracingTopH.toFixed(1) == bracings[b].start.toFixed(1)*/) {
                    aboveBrace = bracings[b]
                }
            }

            switch(dir){
                case "up":
                    if (aboveBrace != undefined) {
                        for (let e = 0; e < AddEditBracingTool.fdata.bracings.length; e++) {//Search
                            let bts1 = bdec.selectedBracings.modelData.parentBracingHeights.bracingTopH;
                            let btd1 = AddEditBracingTool.fdata.bracings[e].end;
        
                            let bbs1 = bdec.selectedBracings.modelData.parentBracingHeights.bracingBotH;
                            let bbd1 = AddEditBracingTool.fdata.bracings[e].start;
        
                            if (bts1 == btd1 && bbs1 == bbd1) {
                                AddEditBracingTool.fdata.bracings.splice(e, 1);
                            }
                        }
        
                        for (let e = 0; e < AddEditBracingTool.fdata.bracings.length; e++) {
                            let bts2 = aboveBrace.end;
                            let btd2 = AddEditBracingTool.fdata.bracings[e].end;
        
                            let bbs2 = aboveBrace.start;
                            let bbd2 = AddEditBracingTool.fdata.bracings[e].start;
        
                            if (bts2 == btd2 && bbs2 == bbd2) {
                                let newdata = {
                                    bracingId: AddEditBracingTool.fdata.bracings.slice(-1)[0].bracingId + 1,
                                    cert: 1,
                                    end: aboveBrace.end,
                                    horizontalMid: false,
                                    horizontalTop: false,
                                    start: bdec.selectedBracings.modelData.parentBracingHeights.bracingBotH,
                                    type: shape
                                }
                                AddEditBracingTool.fdata.bracings[e] = newdata;
                            }
                        }
                    }
                    else {
                        IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Warning, "Could Not Detect Bracing To Merge Above", "", OutputMessageType.Toast));
                    }
                    //-------------
                    break;
                case "down":
                    if (belowBrace != undefined) {
                        for (let e = 0; e < AddEditBracingTool.fdata.bracings.length; e++) {//Search
                            let bts1 = bdec.selectedBracings.modelData.parentBracingHeights.bracingTopH;
                            let btd1 = AddEditBracingTool.fdata.bracings[e].end;
        
                            let bbs1 = bdec.selectedBracings.modelData.parentBracingHeights.bracingBotH;
                            let bbd1 = AddEditBracingTool.fdata.bracings[e].start;
        
                            if (bts1 == btd1 && bbs1 == bbd1) {
                                AddEditBracingTool.fdata.bracings.splice(e, 1);
                            }
                        }
        
                        for (let e = 0; e < AddEditBracingTool.fdata.bracings.length; e++) {
                            let bts2 = belowBrace.end;
                            let btd2 = AddEditBracingTool.fdata.bracings[e].end;
        
                            let bbs2 = belowBrace.start;
                            let bbd2 = AddEditBracingTool.fdata.bracings[e].start;
        
                            if (bts2 == btd2 && bbs2 == bbd2) {
                                let newdata = {
                                    bracingId: AddEditBracingTool.fdata.bracings.slice(-1)[0].bracingId + 1,
                                    cert: 1,
                                    end: bdec.selectedBracings.modelData.parentBracingHeights.bracingTopH,
                                    horizontalMid: false,
                                    horizontalTop: false,
                                    start: belowBrace.start,
                                    type: shape
                                }
                                AddEditBracingTool.fdata.bracings[e] = newdata;
                            }
                        }
                    }
                    else {
                        IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Warning, "Could Not Detect Bracing To Merge Below", "", OutputMessageType.Toast));
                    }
                    break;
                    //-------------
                default:
                    break;
            }
        }
        AddEditBracingTool.reloadAllBracings();
       AddEditBracingTool.saveBracingArray = JSON.parse(JSON.stringify(AddEditBracingTool.fdata));
    }
    //------------------------------------------------------------------------------------
    /*
    * Uses the selected bracing stored in the BracingDecorator local object and 
    * removes it by splicing the index from the set. 
    */
    public static async splitBracing(shape: string) {

        const bdec = IModelApp.viewManager.decorators.filter(e => e.constructor.name.includes("BracingDecorator"))[0] as BracingDecorator;
        if (AddEditBracingTool.fdata == undefined && AddEditBracingTool.fdata != empty) {
            await AddEditBracingTool.fetchData();//get bracing data from dbase.
        }

        if (AddEditBracingTool.fdata != undefined && bdec.selectedBracings != undefined && bdec.selectedBracings.modelData != undefined) {

            let shapeT = shape;
            let shapeB = shape;

            if (shape == "Double K1") {
                shapeT = "K1 UP";
                shapeB = "K1 Down";
            }
            if (shape == "Double K1B") {
                IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Warning, "K1B Up Not Avaialable", "", OutputMessageType.Toast));
                shapeT = "K1B Down"//"K1B UP";
                shapeB = "K1B Down";
            }
            if (shape == "Double K") {
                shapeT = "K UP";
                shapeB = "K Down";
            }
            if (shape == "Double TK") {
                shapeT = "TK Brace Up";
                shapeB = "TK Brace Down";
            }
            if (shape == "Double K2A") {
                IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Warning, "K2A UP Not Avaialable", "", OutputMessageType.Toast));
                shapeT = "K2A Down"//"K2A UP";
                shapeB = "K2A Down";
            }
            if (shape == "DOUBLE K2") {
                shapeT = "K2 UP";
                shapeB = "K2 Down";
            }
            if (shape == "Double K1B") {
                IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Warning, "K1B UP Not Avaialable", "", OutputMessageType.Toast));
                shapeT = "K1B Down"//"K1B UP";
                shapeB = "K1B Down";
            }
            if (shape == "Double K2A") {
                IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Warning, "K2A UP Not Avaialable", "", OutputMessageType.Toast));
                shapeT = "K2A Down";//"K2A UP";
                shapeB = "K2A Down";
            }
            if (shape == "Double K3A") {
                IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Warning, "K3A UP Not Avaialable", "", OutputMessageType.Toast));
                shapeT = "K3A Down"//"K3A UP";
                shapeB = "K3A Down";
            }
            if (shape == "Double K3") {
                shapeT = "K3 UP";
                shapeB = "K3 Down";
            }
            if (shape == "Double K4") {
                shapeT = "K4 UP";
                shapeB = "K4 Down";
            }
            if (shape == "Double K4A") {
                IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Warning, "K3A UP Not Avaialable", "", OutputMessageType.Toast));
                shapeT = "K4A Down";//"K4A UP";
                shapeB = "K4A Down";
            }

            for (let e = 0; e < AddEditBracingTool.fdata.bracings.length; e++) {
                let t = bdec.selectedBracings.modelData.parentBracingHeights.bracingTopH
                let b = bdec.selectedBracings.modelData.parentBracingHeights.bracingBotH

                let start = AddEditBracingTool.fdata.bracings[e].start;
                let end = AddEditBracingTool.fdata.bracings[e].end;

                if (b == start && t == end) {
                    let sub = AddEditBracingTool.fdata.bracings[e].end - AddEditBracingTool.fdata.bracings[e].start
                    let half = sub * 0.5;

                    let topSplit = {
                        bracingId: AddEditBracingTool.fdata.bracings.slice(-1)[0].bracingId + 1,
                        cert: 1,
                        end: AddEditBracingTool.fdata.bracings[e].end,
                        horizontalMid: false,
                        horizontalTop: false,
                        start: AddEditBracingTool.fdata.bracings[e].start + half,
                        type: shapeT
                    }

                    let bottomSplit = {
                        bracingId: AddEditBracingTool.fdata.bracings.slice(-1)[0].bracingId + 2,
                        cert: 1,
                        end: AddEditBracingTool.fdata.bracings[e].start + half,
                        horizontalMid: false,
                        horizontalTop: false,
                        start: AddEditBracingTool.fdata.bracings[e].start,
                        type: shapeB
                    }

                    AddEditBracingTool.fdata.bracings.splice(e, 1);//remove the existing

                    //Add 2 New split bracings
                    AddEditBracingTool.fdata.bracings.push(topSplit);
                    AddEditBracingTool.fdata.bracings.push(bottomSplit);
                }
            }
            AddEditBracingTool.reloadAllBracings();
            AddEditBracingTool.saveBracingArray = JSON.parse(JSON.stringify(AddEditBracingTool.fdata));
        }
    }
    //------------------------------------------------------------------------------------
    /**
     * @param topPoint // end
     * @param bottomPoint // start
     * @param bracinType type of braing
     * @param th the flag is top horizontal is available
     * @param mh flag if middle horizontal is avaialble
     * 
     * Generate the Bracing that is newly crated, 
     * Adds the new bracing to existing array and 
     * Performs snapping trasformation to to adhere to the
     * spec.
     */
    public static async generateBracing(topPoint: any, bottomPoint: any, bracinType: string, th: boolean, mh: boolean)
    {//Aditya

        AddEditBracingTool.bracingType = bracinType;
        if (bottomPoint.height != undefined && topPoint.height != undefined && (bracinType != undefined || bracinType != "") && AddEditBracingTool.fdata != undefined) {

            //----
            //pop the last index from the data wich has the new created bracing. THis clears uneeded resudals from the visualiser.
            for (let b = 0; b < AddEditBracingTool.fdata.bracings.length; b++) {
                if (AddEditBracingTool.fdata.bracings[b].bracingId == 9999) {
                    AddEditBracingTool.fdata.bracings.pop();
                }
            }
            //----
            let pt = topPoint;
            let pb = bottomPoint;

            //keep the directonal alighnment of the bracing correct.
            //even if the points selected are not in correct order.
            let start: number = 0;
            let end: number = 0
            if (pt.height > pb.height) {
                start = pb.height;
                end = pt.height;
            }
            else if (pt.height < pb.height) {
                start = pt.height;
                end = pb.height;
            }

            let b = {
                "bracingId": AddEditBracingTool.fdata.bracings.slice(-1)[0].bracingId + 1,
                "cert": 1,
                "end": end,//-58.95319267959372,
                "horizontalMid": mh,
                "horizontalTop": th,
                "start": start,//-68.5715605878739,
                "type": AddEditBracingTool.bracingType
            }

            //check for bounds and conflicts with existing data.
            let proximityBracings = AddEditBracingTool.isProximity ? AddEditBracingTool.checkForNearestElevations(1, AddEditBracingTool.fdata.bracings, b) : [];
            if (proximityBracings.length == 0) {IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Warning, "No Bracing Detected In Proximity", "", OutputMessageType.Toast));
            }
            else {IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Info, proximityBracings.length.toString() + " Bracings Detected In Proximity , Snapping Values", "", OutputMessageType.Toast));
            }
            //----------
            //Take the proximity bracings and snap the values
            for (let p = 0; p < proximityBracings.length; p++) {
                if (proximityBracings[p].type == "jointop") {//predetected condition
                    let newend = proximityBracings[p].data.start;
                    AddEditBracingTool.topPoint.height = newend;
                    b = {
                        "bracingId": AddEditBracingTool.fdata.bracings.slice(-1)[0].bracingId + 1,
                        "cert": 1,
                        "end": newend,//new value
                        "horizontalMid": mh,
                        "horizontalTop": th,
                        "start": start,
                        "type": AddEditBracingTool.bracingType
                    }
                }
                if (proximityBracings[p].type == "joinbottom") {//predetected condition
                    let newstart = proximityBracings[p].data.end;
                    AddEditBracingTool.bottomPoint.height = newstart;
                    b = {
                        "bracingId": AddEditBracingTool.fdata.bracings.slice(-1)[0].bracingId + 1,
                        "cert": 1,
                        "end": end,
                        "horizontalMid": mh,
                        "horizontalTop": th,
                        "start": newstart,//new value
                        "type": AddEditBracingTool.bracingType
                    }
                }
            }
            //----------
            AddEditBracingTool.updatePopoutPannel(); //update the pannel to correct the data shown
            //----------
            AddEditBracingTool.fdata.bracings.push(b);
            //Since the orignal array will have an unsorted inclusion.
            //deep clone , keep a copy of the orignal data.Used to save. see-> saveNewBracing()
            AddEditBracingTool.saveBracingArray = JSON.parse(JSON.stringify(AddEditBracingTool.fdata));
            //----------
            let bdecor = IModelApp.viewManager.decorators.filter(e => e.constructor.name.includes("BracingDecorator"))[0] as BracingDecorator;

            //a more robust but higher complexity operation , clear and reload.
            AddEditBracingTool.reloadAllBracings();
            //----
        }
        else {
            IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Error, "Data Incomplete , Cannot Generate Bracing", "", OutputMessageType.Toast));
        }
    }

    //------------------------------------------------------------------------------------
    /**
     * @param topPoint // end
     * @param bottomPoint // start
     * @param bracinType type of braing
     * @param th the flag is top horizontal is available
     * @param mh flag if middle horizontal is avaialble
     * 
     * Generate/Overwrite the Bracing that is being Edited.
     * This runs when the Edit context is running for the Bracings Edit workflow.
     */
    public static async generateBracingOnEdit(topPoint: any, bottomPoint: any, bracinType: string, th: boolean, mh: boolean) {

        if (AddEditBracingTool.isBracingEditable == true && AddEditBracingTool.fdata != undefined) {
            AddEditBracingTool.bracingType = bracinType;
            if (bottomPoint.height != undefined && topPoint.height != undefined && (bracinType != undefined || bracinType != "")) {

                let pt = topPoint;
                let pb = bottomPoint;

                //keep the directonal alighnment of the bracing correct.
                //even if the points selected are not in correct order.
                let start: number = 0;
                let end: number = 0
                if (pt.height > pb.height) {
                    start = pb.height;
                    end = pt.height;
                }
                else if (pt.height < pb.height) {
                    start = pt.height;
                    end = pb.height;
                }

                let b = {
                    "bracingId": AddEditBracingTool.fdata.bracings.slice(-1)[0].bracingId + 1,
                    "cert": 1,
                    "end": end,
                    "horizontalMid": mh,
                    "horizontalTop": th,
                    "start": start,
                    "type": AddEditBracingTool.bracingType
                }

                //check for bounds and conflicts with existing data.
                let proximityBracings = AddEditBracingTool.isProximity ? AddEditBracingTool.checkForNearestElevations(1, AddEditBracingTool.fdata.bracings, b) : [];
                if (proximityBracings.length == 0) {
                    IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Warning, "No Bracing Detected In Proximity", "", OutputMessageType.Toast));
                }
                else {
                    IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Info, proximityBracings.length.toString() + " Bracings Detected In Proximity, Snapping Values", "", OutputMessageType.Toast));
                }
                //----------
                //Take the proximity bracings and snap the values
                for (let p = 0; p < proximityBracings.length; p++) {
                    if (proximityBracings[p].type == "jointop") {//predetected condition
                        let newend = proximityBracings[p].data.start;
                        AddEditBracingTool.topPoint.height = newend;
                        b = {
                            "bracingId": AddEditBracingTool.fdata.bracings.slice(-1)[0].bracingId + 1,
                            "cert": 1,
                            "end": newend,//new value
                            "horizontalMid": mh,
                            "horizontalTop": th,
                            "start": start,
                            "type": AddEditBracingTool.bracingType
                        }
                    }
                    if (proximityBracings[p].type == "joinbottom") {//predetected condition
                        let newstart = proximityBracings[p].data.end;
                        AddEditBracingTool.bottomPoint.height = newstart;
                        b = {
                            "bracingId": AddEditBracingTool.fdata.bracings.slice(-1)[0].bracingId + 1,
                            "cert": 1,
                            "end": end,
                            "horizontalMid": mh,
                            "horizontalTop": th,
                            "start": newstart,//new value
                            "type": AddEditBracingTool.bracingType
                        }
                    }
                }
                //----------
                AddEditBracingTool.updatePopoutPannel(); //update the pannel to correct the data shown
                //----------             
                //Compare and update index here.
                //This Overwrites locally fetched data with the new edited bracing data on the 
                //required index that is looked up in the linear Search process
                const bdec = IModelApp.viewManager.decorators.filter(e => e.constructor.name.includes("BracingDecorator"))[0] as BracingDecorator;
                if (bdec && bdec.selectedBracings != undefined && bdec.selectedBracings.modelData != undefined) {
                    for (let e = 0; e < AddEditBracingTool.fdata.bracings.length; e++) {//Search
                        let bts = bdec.selectedBracings.modelData.parentBracingHeights.bracingTopH;
                        let btd = AddEditBracingTool.fdata.bracings[e].end;

                        let bbs = bdec.selectedBracings.modelData.parentBracingHeights.bracingBotH;
                        let bbd = AddEditBracingTool.fdata.bracings[e].start;
                        if (bts == btd && bbs == bbd) {
                            AddEditBracingTool.fdata.bracings[e] = b;//Overwrite.
                            //----------------------------------------
                            //This step updates the lastSelectedBracing object with the new values
                            //that are used to detect the updated bracing in (fdata).
                            //bracing after recreaction of decorator 
                            if (BracingDecorator.selectedBracing) {
                                BracingDecorator.selectedBracing.modelData.parentBracingHeights.bracingTopH = b.end;
                                BracingDecorator.selectedBracing.modelData.parentBracingHeights.bracingBotH = b.start;
                            }
                            //----------------------------------------
                        }
                    }
                } else {
                    IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Warning, "No Bracing Selected Please Try Again", "", OutputMessageType.Toast));
                }
                //Since the orignal array will have an unsorted inclusion.
                //deep clone , keep a copy of the orignal data.Used to save. see-> BracingAddEditWidget -> saveNewBracing()
                AddEditBracingTool.saveBracingArray = JSON.parse(JSON.stringify(AddEditBracingTool.fdata));
                //-----
                let bdecor = IModelApp.viewManager.decorators.filter(e => e.constructor.name.includes("BracingDecorator"))[0] as BracingDecorator;
                //-----
                if (AddEditBracingTool.bracingDecorator == undefined) {
                    AddEditBracingTool.bracingDecorator = bdecor;
                }
                //-----
                //a more robust but higher complexity operation , clear and reload.
                AddEditBracingTool.reloadAllBracings();
                //-----
                if (AddEditBracingTool.bracingDecorator && BracingDecorator.selectedBracing) {
                    AddEditBracingTool.bracingDecorator.selectedBracings = BracingDecorator.selectedBracing;
                    AddEditBracingTool.bracingDecorator.highlightBracingFromStore(BracingDecorator.selectedBracing);
                }
                //----
                //-----
                AddEditBracingTool.clearResiduals();
                //-----
                //AddEditBracingTool.updatePopoutPannel();
            }
            else {
                IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Error, "Data Incomplete , Cannot Generate Bracing", "", OutputMessageType.Toast));
            }
        } else {
            IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Error, "Error OverWriting, Cannot Generate", "", OutputMessageType.Toast));
        }
    }
    //------------------------------------------------------------------------------------
    /* 
     * Uses AddEditBracingTool.fdata , to reload the bracings 
     * An updates to AddEditBracingTool.fdata will be reflectd.
     * So all PreVisulisation before save need to be made to fdata 
     * for it to be reloaded and revisualised in the DT viewer.
    */
    //------------------------------------------------------------------------------------
    public static async reloadAllBracings() {

        AddEditBracingTool.clearBracingDecorator();//clear the older decoratro added to view manager if already exists.
        AddEditBracingTool.bracingDecorator = new BracingDecorator();
        //------------------------------------------------------------------------------------------------
        //This is the new Process , remove the old one !!Critical!!
        const transientIds: bracTrans[] = [];
        AddEditBracingTool.fdata["bracings"].forEach(e=>transientIds.push({bracingId:e.bracingId, transientId: IModelApp.viewManager.selectedView!.iModel.transientIds.getNext()}));
        var bracFaceInfoDat = await BracingDecorator.getBracingFaceDataForOTD(AddEditBracingTool.fdata["bracings"], AddEditBracingTool.fdata["tower-structure"]);
        let model = await BracingDecorator.modelFromBracingFaceData(bracFaceInfoDat);
        let out = await BracingsClient.postBracingDetectorGeneratorData(store.getState().auth.accessTokenStatePrivateAPI.accessToken!, model);
        if (out.status == "success") {
            AddEditBracingTool.bracingDecorator.createPipesWithOTDData(out.data, HighlightBracingsTool.data2send, bracFaceInfoDat, transientIds);
        } else {
            IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Error, "Error Loading Bracings..", "", OutputMessageType.Toast));
        }
        //--------------------------------------------------------------------------------------------------
        AddEditBracingTool.bracingDecorator!.createPipes(AddEditBracingTool.fdata);

        IModelApp.viewManager.addDecorator(AddEditBracingTool.bracingDecorator!)
    }
    //------------------------------------------------------------------------------------
    /**
     * 
     */
    //------------------------------------------------------------------------------------
    public static async reloadAllBracingsUpdatedData() {
        AddEditBracingTool.clearBracingDecorator();
        await AddEditBracingTool.fetchData();

        AddEditBracingTool.bracingDecorator = new BracingDecorator();
        AddEditBracingTool.bracingDecorator!.createPipes(AddEditBracingTool.fdata);
        const currentState= store.getState();
        const built3ObjectMaps = currentState.detectedData.built3DObjectIdMaps;
        const newIdVals: any[] = built3ObjectMaps.idValues.filter((e:[string, string])=>(!e[1].match(/bracingGeom/)));
        const newValIds: any[] = newIdVals.map((e: any[])=>[...e].reverse())
        store.dispatch(updateBuilt3DObjectIdsMap({idValues: newIdVals, valueIds: newValIds}));
//------------------------------------------------------------------------------------------------
        //This is the new Process , remove the old one !!Critical!!
        const transientIds: bracTrans[] = [];
        AddEditBracingTool.fdata["bracings"].forEach(e=>transientIds.push({bracingId:e.bracingId, transientId: IModelApp.viewManager.selectedView!.iModel.transientIds.getNext()}));
        var bracFaceInfoDat = await BracingDecorator.getBracingFaceDataForOTD(AddEditBracingTool.fdata["bracings"], AddEditBracingTool.fdata["tower-structure"]);
        let model = await BracingDecorator.modelFromBracingFaceData(bracFaceInfoDat);
        let out = await BracingsClient.postBracingDetectorGeneratorData(store.getState().auth.accessTokenStatePrivateAPI.accessToken!, model);
        if (out.status == "success") {
            AddEditBracingTool.bracingDecorator.createPipesWithOTDData(out.data, HighlightBracingsTool.data2send, bracFaceInfoDat, transientIds);
            store.dispatch(addToBuilt3DObjectIdsMap(new Map(AddEditBracingTool.bracingDecorator.objectIdMap)));
            store.dispatch(setBracingsData3DMaps(new Map(AddEditBracingTool.bracingDecorator.nameIdMap)));
        } else {
            IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Error, "Error loading OTD bracings.", "", OutputMessageType.Toast));
        }
        //--------------------------------------------------------------------------------------------------

        IModelApp.viewManager.addDecorator(AddEditBracingTool.bracingDecorator!);
        resetObjectIds(AddEditBracingTool.bracingDecorator);
    }
    //------------------------------------------------------------------------------------
    /*
     * Clears the BracingsDecorator from the ViewManager.
    */
    //------------------------------------------------------------------------------------
    private static clearBracingDecorator() {
        for (const decor of IModelApp.viewManager.decorators) {
            const n = decor.constructor.name;
            if (n.includes("BracingDecorator")) {
                (decor as BracingDecorator).terminate();
                IModelApp.viewManager.dropDecorator(decor);
            }
        }
    }
    //------------------------------------------------------------------------------------
    /* Overrides Gets called when the Tool is run() from SampleToolsWidget.tsx.
     * does object click  to surface testing via the depth buffer.
     * _ev return data about the intersecting object and point of intersection.
     */
    //------------------------------------------------------------------------------------
    public override async onDataButtonDown(_ev: BeButtonEvent): Promise<EventHandled> {
        //create the element contenxt on left button down.
        // let div = document.getElementsByClassName("imodeljs-vp")[0];
        // div?.addEventListener('mouseup', this.onMouseClickUp);

        //----------------
        const iModel = UiFramework.getIModelConnection()!;
        if (AddEditBracingTool.uiInfo.topElevationFlag) {
            AddEditBracingTool.topPoint = await iModel.spatialToCartographic(_ev.point);
        }
        if (AddEditBracingTool.uiInfo.bottomElevationFlag) {
            AddEditBracingTool.bottomPoint = await iModel.spatialToCartographic(_ev.point);
        }
        AddEditBracingTool.callback(AddEditBracingTool.topPoint, AddEditBracingTool.bottomPoint);
        //-----
        AddEditBracingTool.updatePopoutPannel();//update on button down.
        //-----
        IModelApp.tools.run(SelectionTool.toolId);
        return EventHandled.No;
    }
    //------------------------------------------------------------------------------------
    public override async onDataButtonUp(_ev: BeButtonEvent): Promise<EventHandled> {
        //SyncUiEventDispatcher.dispatchSyncUiEvent("imodel-display-toggle");//revert the pannel to default
        //FrontstageManager.activeFrontstageDef?.getStagePanelDef(StagePanelLocation.Right)?.findWidgetDef("PropertyListWidget")?.setWidgetState(WidgetState.Open);
        return EventHandled.Yes;
        this.exitTool();
    }
    //------------------------------------------------------------------------------------
    public override async onMouseMotion(_ev: BeButtonEvent): Promise<void> {
        //empty
    }
    //------------------------------------------------------------------------------------
    public override async onMouseStartDrag(_ev: BeButtonEvent): Promise<EventHandled> {
        return EventHandled.No;
    }
    //------------------------------------------------------------------------------------
    public override async onMouseEndDrag(_ev: BeButtonEvent): Promise<EventHandled> {
        // this.exitTool();
        return EventHandled.No;
    }
    //------------------------------------------------------------------------------------
    public override async onCleanup() {
    }
    //------------------------------------------------------------------------------------
    public override onPostInstall(): Promise<void> {
        super.onPostInstall();
        // Enable AccuSnap so that Decorator can be created by snapping on existing model
        IModelApp.accuSnap.enableSnap(true);//this will enable the intersect pointer that only returns points on existing Reality model
        return Promise.resolve();
    }
    //------------------------------------------------------------------------------------
    public override isCompatibleViewport(_vp: Viewport | undefined, _isSelectedViewChange: boolean): boolean {
        return true;
    }
    //------------------------------------------------------------------------------------
}


