import { SelectAntennaTool } from "./tools/SelectAntennaTool";
import "./Lists/List.scss";
import React from 'react'
import { connect } from 'react-redux'
import { IModelApp, NotifyMessageDetails, OutputMessagePriority, OutputMessageType } from "@itwin/core-frontend";
import { Vector3d } from "@itwin/core-geometry";
import { ModifyHandleDecoration } from "./tools/modification/ModifyHandleDecoration";
import { UiFramework, WidgetDef } from "@itwin/appui-react";
import EquipmentClient from "../api/equipment";
import { Logger } from "../api/logging";
import { EquipmentsTable } from "./Tables/EquipmentsTable";
import { Id64String } from "@itwin/core-bentley";
import { ConfigManager } from "../../config/ConfigManager";
import { RootState } from "../../store/States";
import { towerStructureData, selectedObjectInformation, initialPropertyListObjectState, init_RM_TowerPropListState, ObjectCategoryTypes } from "../../store/detectedData/apiDataTypes";
import { IndividualShapeDecorator } from "../tools/decorators/IndividualShapeDecorator";
import { getValueInRequiredUnit, getUnit } from "./Tables/BaseTable";
import { EquipmentType, unitSystemType } from "../../store/redux-types";
import { store } from "../../store/rootReducer";
import { ReduceDecimalNumberToTwoPlaces } from "./Lists/EquipmentList";
import { pecInputTagTypes, pecDataValueTypes, pecRows, pecGridStructure, pecComponentProps, pecStrucType, pecTableButton, PropertyEditorComponent, pecOptionData } from "./PropertyEditorComponent"
import { AppSettings } from "../../config/AppSettings";
import { ShapeDecorator } from "../tools/decorators/ShapeDecorator";

export const useWidgetDef = (widgetId: string): WidgetDef | undefined => {
    const frontstageDef = UiFramework.frontstages.activeFrontstageDef;
    return frontstageDef?.findWidgetDef(widgetId);
};

export const getManEquipType = (et: string) => {
    let equipType: string = '';
    switch (et) {
        case "Antenna": equipType = "PANEL"; break;
        case "Micro_Wave": equipType = "DISH"; break;
        case "RRU": equipType = "TME"; break;
        case "SQUID": equipType = "SQUID"; break;
    }
    return equipType;
}


export const GetEquipmentProperties = (props) => {
    const [record, setRecord] = React.useState<any>();
    const [imgUrl, setImgUrl] = React.useState([]);
    const [manModDets, setManModDets] = React.useState<any>([]);
    const [manList, setManList] = React.useState<string[]>(["Select Manufacturer"]);
    const [modelList, setModelList] = React.useState<string[]>(["Select Model"]);
    const [showModal, setShowModal] = React.useState(false);
    const [enableSave, setEnableSave] = React.useState(false);
    const [modalFieldVals, setModalFieldVals] = React.useState<pecGridStructure>(props.equipmentPropertiesRow);
    let updatedArray: pecGridStructure;

    React.useEffect(() => {
        setRecord(undefined);
        (async () => {
            const data = await getData(props.selectedEquipment);
            if (!data) {
                return;
            }
            setRecord(data);
            openModal();
            if (data && data.imageList !== "[]" && data.imageList !== "" && data.imageList !== null && data.imageList !== "['no_image']" && data.imageList !== "[\"no_image\"]") {
                const imgName = data.imageList;
                const imgNameReplace = imgName.replace(/[\[\]']+/g, "");
                const imageName = imgNameReplace.replaceAll('"', "").split(",");
                setImgUrl(imageName);
            }
        })();

    }, [props.selectedEquipment]);

    React.useEffect(() => {
        if (record) loadManDets();
    }, [record]);

    React.useEffect(() => {
        if (manList?.length && modalFieldVals?.rows.length) {
            const temp: pecGridStructure = { ...modalFieldVals };
            const manIndex = temp.rows.findIndex(e => e.columns[0].name === "manufacturer");
            const modelIndex = temp.rows.findIndex(e => e.columns[0].name === "model");
            const manOptionData: pecOptionData[] = manList.map(e => ({ label: e, value: e }));
            temp.rows[manIndex].columns[1].data = manOptionData;
            const modelOptionData: pecOptionData[] = modelList.map(e => ({ label: e, value: e }));
            temp.rows[modelIndex].columns[1].data = modelOptionData;
            setModalFieldVals(temp);
        }
    }, [manList]);


    const loadManDets = async () => {
        if (manList.length < 3 || modelList.length < 3) {
            manList.unshift("Loading manufacturers...");
            modelList.unshift("Loading models...");
            setManList([...manList]);
            setModelList([...modelList]);
            if (modalFieldVals.rows.length) {
                const temp: pecGridStructure = { ...modalFieldVals };
                const modelIndex = temp.rows.findIndex(e => e.columns[1].name == "model");
                const modelOptionData: pecOptionData[] = modelList.map(e => ({ label: e, value: e }));;
                temp.rows[modelIndex].columns[1].data = modelOptionData;
                setModalFieldVals(temp);
            }
            await getManData().then((data) => {
                if (!data) return;
                setManModDets([...data]);
                const [manL, modL] = getManModForEquipType(data, record.manufacturer);
                setModelList(modL);
                setManList(manL);
            });
        }
    }

    const getManData = async () => {
        try {
            const token = store.getState().auth.accessTokenStatePrivateAPI.accessToken;
            const data = await EquipmentClient.getAllManModels(token);
            if (!data) {
                return undefined;
            }
            return data;
        } catch (e) {
            Logger.error("Error while calling getAllManModels api ", e);
        }

        return undefined;
    }

    const getManModForEquipType = (mmd, selectedMan) => {
        const mans: any = [], manL: string[] = [], modL: string[] = [];
        const equipType = getManEquipType(record.type);
        const temp: pecGridStructure = { ...modalFieldVals };
        const manIndex = temp.rows.findIndex(e => e.columns[0].name == "manufacturer");
        mmd.forEach((e: any) => {
            if (manL.indexOf(e.manufacturer?.toUpperCase()) === -1 && mans.indexOf(e.manufacturer?.toUpperCase()) === -1 && e.type == equipType && e.manufacturer.length) manL.push(e.manufacturer.toUpperCase());
            if (modL.indexOf(e.model?.toUpperCase()) === -1 && e.manufacturer?.toUpperCase() === selectedMan?.toUpperCase() && e.type == equipType && e.model.length) modL.push(e.model.toUpperCase());
        });
        if (temp.rows[manIndex].columns.length && temp.rows[manIndex].columns[1].value == record.manufacturer && record?.manufacturer && record.manufacturer.length && !record.manufacturer.match(/UPT:NewEquip/i)) manL.push(record.manufacturer);
        if (record.manufacturer && temp.rows[manIndex].columns.length && temp.rows[manIndex].columns[1].value == record.manufacturer && record?.model && record.model.length && !record.model.match(/UPT:NewEquip-001/i)) modL.push(record.model);
        return [["Select Manufacturer", ...[...new Set([...manL])].sort()], ["Select Model", ...[...new Set([...modL])].sort()]];
    }

    const onFieldUpdate = (event) => {
        let currentEvent = event;
    }

    const saveProps = () => {
        updatedArray = JSON.parse(JSON.stringify(modalFieldVals));
        const newRec = JSON.parse(JSON.stringify(record));

        const unit = props.unitSystem;

        const putData: any = {
            Azimuth: newRec.azimuth,
            Equipment_Name: record.name,
            Elevation_Height: unit === unitSystemType.METRIC ? record.elevationHeight : record.elevationHeight,
            Height: unit === unitSystemType.METRIC ? newRec.height : newRec.height,
            Thicness: unit === unitSystemType.METRIC ? newRec.depth : newRec.depth,
            Width: unit === unitSystemType.METRIC ? newRec.width : newRec.width,
            x_position: newRec.xPosition,
            y_position: newRec.yPosition,
            z_position: newRec.zPosition,
            Tilt: newRec.tilt,
            Roll: newRec.roll,
            Manufacturer: newRec.manufacturer,
            Model: newRec.model,
            UserDriven: newRec.userDriven,
            Face: newRec.faceName,
        }

        const feetToMeterConvFactor = 1 / 3.2808399;
        let modified = false;
        const updatedProps: any[] = [];
        updatedArray.rows.forEach((ua: pecRows, i: number) => {
            const e = ua.columns[1];
            if(e){
                if (e.modified) {
                    modalFieldVals.rows[i].columns[1].modified = false;
                    modified = true;
                    updatedProps.push(JSON.parse(JSON.stringify({ ...e, name: ua.columns[1].name })));
                    const formattedName = ua.columns[1].name[0].toUpperCase() + ua.columns[1].name.split('').slice(1).join('');
                    if (e.fieldType === "float" && ua.columns[0].name !== "elevationHeight" && ua.columns[0].name !== "depth") {
                        putData[formattedName] = unit === unitSystemType.METRIC ? parseFloat(e.value.toString()) : parseFloat(e.value.toString()) * 1/12 * feetToMeterConvFactor;
                        newRec[ua.columns[1].name] = unit === unitSystemType.METRIC ? parseFloat(e.value.toString()) : parseFloat(e.value.toString());
                    } else if (e.fieldType === "integer" && ua.columns[0].name !== "elevationHeight" && ua.columns[0].name !== "depth") {
                        putData[formattedName] = parseInt(e.value.toString());
                        newRec[ua.columns[1].name] = parseInt(e.value.toString());
                    } else if (e.fieldType === "degree" && ua.columns[0].name !== "elevationHeight" && ua.columns[0].name !== "depth") {
                        if (ua.columns[0].name === "tilt") {
                            putData[formattedName] = -1 * parseFloat(e.value.toString());
                            newRec[ua.columns[1].name] = -1 * parseFloat(e.value.toString());
                        }
                        else {
                            putData[formattedName] = parseFloat(e.value.toString());
                            newRec[ua.columns[1].name] = parseFloat(e.value.toString());
                        }
                    } else if (ua.columns[0].name === "elevationHeight") {
                        putData.z_position += unit === unitSystemType.METRIC ? parseFloat(e.value.toString()) - record.elevationHeight : (parseFloat(e.value.toString()) * feetToMeterConvFactor - record.elevationHeight);
                        putData.Elevation_Height = unit === unitSystemType.METRIC ? parseFloat(e.value.toString()) - props.concrete_height : parseFloat(e.value.toString()) * feetToMeterConvFactor - props.concrete_height;
                    } else if (ua.columns[0].name === "depth") {
                        putData.Thicness = unit === unitSystemType.METRIC ? parseFloat(e.value.toString()) : parseFloat(e.value.toString()) * 1/12 * feetToMeterConvFactor;
                        newRec.depth = unit === unitSystemType.METRIC ? parseFloat(e.value.toString()) : parseFloat(e.value.toString()) * 1/12 * feetToMeterConvFactor;
                    } else if (formattedName.match(/Manufacturer|Model/i)) {
                        putData[formattedName] = (e.value.toString() == "Select Manufacturer" || e.value.toString() == "Select Model") ? "UPT:NewEquip" : e.value.toString();
                        newRec[ua.columns[1].name] = (e.value.toString() == "Select Manufacturer" || e.value.toString() == "Select Model") ? "UPT:NewEquip-001" : e.value.toString();
                    } else if(formattedName === "Active"){
                        putData['Active'] = e.value == "True" ? true : false;
                        newRec[ua.columns[0].name] = e.value.toString();
                    } else if(formattedName === "Face"){
                        const face = e.value.toString().split(" ").join("")
                        if(newRec.faceName != face){
                            putData["UserDriven"] = true;
                        }
                        putData[formattedName] = face;
                        newRec[ua.columns[0].name] = face;
                    }
                    else {
                        putData[formattedName] = e.value.toString();
                        newRec[ua.columns[0].name] = e.value.toString();
                    }
                }
            }
        });

        if (modified) {
            const displayName = ShapeDecorator.selectedEquipment.displayName;
            IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Info, `Saving ${displayName}, Please wait...`, "", OutputMessageType.Toast));

            ModifyHandleDecoration.SaveData(putData, modified, newRec.name).then((res) => {
                if (res) {
                    const imc = UiFramework.getIModelConnection();
                    const sourceId = ShapeDecorator.selectedEquipment.transId;
                    imc?.selectionSet.emptyAll();
                    imc?.selectionSet.add(sourceId as Id64String);

                    const name = ShapeDecorator.selectedEquipment.theEquipment?.Equipment_Name;
                    const enim = EquipmentsTable.equipNameInfoMap.get(name!);
                    const enpm = EquipmentsTable.equipNamePositionMap.get(name!);

                    updatedProps.forEach((up: any) => {
                        if (up.fieldType === "float") enim[up.name] = unit === unitSystemType.METRIC ? parseFloat(up.value) : parseFloat(up.value);
                        else if (up.type === "integer" && up.name != "agl") enim[up.name] = parseInt(up.value);
                        else if (up.fieldType === "degree" && up.name != "tilt") enim[up.name] = parseFloat(up.value);
                        else if (up.fieldType === "degree" && up.name == "tilt") enim[up.name] = -1 * parseFloat(up.value);
                        else enim[up.name] = up.value;

                        if (up.name === "manufacturer") enpm!.Manufacturer = up.value == "Select Manufacturer" ? "UPT:NewEquip" : up.value;
                        if (up.name === "model") enpm!.Model = up.value == "Select Model" ? "UPT:NewEquip-001" : up.value;
                        if (up.name === "face") enpm!.Face = up.value.toString().split(" ").join("");
                        if (up.name === "azimuth") enpm!.Azimuth = parseFloat(up.value);
                        if (up.name === "roll") enpm!.Roll = parseFloat(up.value);
                        if (up.name === "height") enpm!.Height = unit === unitSystemType.METRIC ? parseFloat(up.value) : parseFloat(up.value) * 1/12 * feetToMeterConvFactor;
                        if (up.name === "width") enpm!.Width = unit === unitSystemType.METRIC ? parseFloat(up.value) : parseFloat(up.value) * 1/12 * feetToMeterConvFactor;
                        if (up.name === "thicness" || up.name === "depth") enpm!.Thicness = unit === unitSystemType.METRIC ? parseFloat(up.value) : parseFloat(up.value) * 1/12 * feetToMeterConvFactor;
                        if (up.name === "tilt") enpm!.Tilt = -1 * parseFloat(up.value);
                        if (up.name === "isActive") enpm!.Active = up.value == "True" ? true : false;
                        if (up.name === "elevationHeight") {
                            enpm!.z_position = parseFloat(putData.z_position);
                            enpm!.Elevation_Height = parseFloat(putData.Elevation_Height);
                        }
                    });

                    const equipDec = IModelApp.viewManager.decorators.filter((e) => e.constructor.name.includes("ShapeDecorator"))[0] as ShapeDecorator;

                    const equipType = name?.match(/Ant/ig) ? EquipmentType.Antenna : name?.match(/RRU/ig) ? EquipmentType.RRU : EquipmentType.Microwave;
                    // const jsonIndex = equipDec.currJson.findIndex((e) => e.Equipment_Name === name);

                    // equipDec.currJson[jsonIndex] = enpm!;
                    if (equipType === EquipmentType.Antenna || equipType === EquipmentType.RRU) {
                        equipDec?.updateBoxPositionByName(displayName!, new Vector3d(0, 0, 0));
                    } else if (equipType === EquipmentType.Microwave || equipType === EquipmentType.SQUID) {
                        equipDec?.updateCylinderPosition(displayName!, new Vector3d(0, 0, 0));
                    }

                }
            });
        } else {
            IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Info,
                `Nothing to save.`, "", OutputMessageType.Toast));
        }
        props.onClose();
        setShowModal(false);
    }


    const onModalBtnClick = (evt: any) => {
        if (evt?.target.name == "Save") saveProps();
        else {
            props.onClose();
            setShowModal(false);
        }
    }

    const modalBtns: pecTableButton[] = [
        { name: "Save", value: "Save", class: "dialogBox-save-btn", disable: !enableSave, onInteract: onModalBtnClick },
        { name: "Cancel", value: "Cancel", class: "dialogBox-cancel-btn", disable: false, onInteract: onModalBtnClick },
    ];


    const onPropertyModalChange = (evt: any) => {
        evt.persist();
        const { id: theId, name: theName, selectedIndex: theSelectedIndex, value: theValue } = evt.target;
        const temp: pecGridStructure = { ...modalFieldVals };
        const unit = props.unitSystem;
        const inchesToSelUnitConvFactor = unit == unitSystemType.METRIC ? 0.0254 : 1; // For Imperial we need to show dimensions in inches hence the factor 1 for Imperial 
        // const poundsToSelUnitConvFactor = unit == unitSystemType.METRIC ? 0.453592 : 1;
        const towerData = store.getState().detectedData.towerStructureData as towerStructureData;
        const [inputType, rowIndex, columnIndex] = theId.split("@")[0].split('-');
        switch (theName) {
            case "manufacturer": {
                const manIndex = temp.rows.findIndex(e => e.columns[0].name == "manufacturer");
                const modelIndex = temp.rows.findIndex(e => e.columns[0].name == "model");
                const heightIndex = temp.rows.findIndex(e => e.columns[0].name == "height");
                const widthIndex = temp.rows.findIndex(e => e.columns[0].name == "width");
                const depthIndex = temp.rows.findIndex(e => e.columns[0].name == "depth");

                temp.rows[manIndex].columns[1].value = manList[parseInt(theSelectedIndex)];
                if (manList[theSelectedIndex] == "Select Manufacturer") {
                    temp.rows[manIndex].columns[1].value = manList[theSelectedIndex];
                    temp.rows[modelIndex].columns[1].value = modelList[0];
                    const modelOptionData: pecOptionData[] = [{ label: "Select Model", value: "Select Model" }];
                    temp.rows[modelIndex].columns[1].data = modelOptionData;
                    temp.rows[manIndex].columns[1].modified = true;
                    temp.rows[modelIndex].columns[1].modified = true;
                    break;
                }

                const [, modList] = getManModForEquipType(manModDets, temp.rows[manIndex].columns[1].value);
                temp.rows[modelIndex].columns[1].data = modList.map(e => ({ label: e, value: e }));
                temp.rows[modelIndex].columns[1].value = modList[1];
                setModelList([...modList])

                const selMM = manModDets.filter(e => e.manufacturer.toUpperCase() === temp.rows[manIndex].columns[1].value && e.model.toUpperCase() === temp.rows[modelIndex].columns[1].value.toString().toUpperCase() && e.type.toUpperCase() === getManEquipType(record.type).toUpperCase())[0];

                temp.rows[heightIndex].columns[1].value = parseFloat((inchesToSelUnitConvFactor * selMM.height.toString()).toFixed(6));
                temp.rows[widthIndex].columns[1].value = parseFloat((inchesToSelUnitConvFactor * selMM.width.toString()).toFixed(6));
                temp.rows[depthIndex].columns[1].value = parseFloat((inchesToSelUnitConvFactor * selMM.depth.toString()).toFixed(6));

                temp.rows[manIndex].columns[1].modified = true;
                temp.rows[modelIndex].columns[1].modified = true;
                temp.rows[heightIndex].columns[1].modified = true;
                temp.rows[widthIndex].columns[1].modified = true;
                temp.rows[depthIndex].columns[1].modified = true;
                break;
            }
            case "model": {
                const manIndex = temp.rows.findIndex(e => e.columns[0].name == "manufacturer");
                const modelIndex = temp.rows.findIndex(e => e.columns[0].name == "model");
                const heightIndex = temp.rows.findIndex(e => e.columns[0].name == "height");
                const widthIndex = temp.rows.findIndex(e => e.columns[0].name == "width");
                const depthIndex = temp.rows.findIndex(e => e.columns[0].name == "depth");

                if (modelList[theSelectedIndex] == "Select Model") {
                    temp.rows[modelIndex].columns[1].value = modelList[theSelectedIndex];
                    temp.rows[modelIndex].columns[1].modified = true;
                    break;
                }

                const selMM = manModDets.filter(e => e.manufacturer.toUpperCase() === temp.rows[manIndex].columns[1].value && e.model.toUpperCase() === modelList[theSelectedIndex].toUpperCase() && e.type.toUpperCase() === getManEquipType(record.type).toUpperCase())[0];
                if (selMM !== undefined) {
                    temp.rows[modelIndex].columns[1].value = modelList[parseInt(theSelectedIndex)];
                    temp.rows[heightIndex].columns[1].value = parseFloat((inchesToSelUnitConvFactor * selMM.height.toString()).toFixed(6));
                    temp.rows[widthIndex].columns[1].value = parseFloat((inchesToSelUnitConvFactor * selMM.width.toString()).toFixed(6));
                    temp.rows[depthIndex].columns[1].value = parseFloat((inchesToSelUnitConvFactor * selMM.depth.toString()).toFixed(6));

                    temp.rows[modelIndex].columns[1].modified = true;
                    temp.rows[heightIndex].columns[1].modified = true;
                    temp.rows[widthIndex].columns[1].modified = true;
                    temp.rows[depthIndex].columns[1].modified = true;

                } else {
                    IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Warning, `Select Manufacturer & model is not valid for the equipment.`, "", OutputMessageType.Toast));
                }
                break;
            }
            case "active": {
                temp.rows[rowIndex].columns[1].value = theValue == "True" ? "True" : "False";
                temp.rows[rowIndex].columns[1].modified = true;
                break;
            }
            case "equipmentLibrary": {
                const dimIndex = temp.rows.findIndex(e => e.columns[0].name == "equipmentLibrary");
                const manIndex = temp.rows.findIndex(e => e.columns[0].name == "manufacturer");
                const modelIndex = temp.rows.findIndex(e => e.columns[0].name == "model");
                const heightIndex = temp.rows.findIndex(e => e.columns[0].name == "height");
                const widthIndex = temp.rows.findIndex(e => e.columns[0].name == "width");
                const depthIndex = temp.rows.findIndex(e => e.columns[0].name == "depth");
                const td = temp.rows[dimIndex].columns[1].data;

                temp.rows[dimIndex].columns[1].value = td != undefined ? td[theSelectedIndex].label : temp.rows[dimIndex].columns[1].value;

                if (temp.rows[dimIndex].columns[1].value == "CUSTOM") {
                    temp.rows[manIndex].columns[1].value = "UPT:NewEquip";
                    temp.rows[modelIndex].columns[1].value = "UPT:NewEquip-001";
                    temp.rows[manIndex].columns[1].disabled = true;
                    temp.rows[modelIndex].columns[1].disabled = true;
                    temp.rows[heightIndex].columns[1].disabled = false;
                    temp.rows[widthIndex].columns[1].disabled = false;
                    temp.rows[depthIndex].columns[1].disabled = false;
                    temp.rows[manIndex].columns[1].modified = true;
                    temp.rows[modelIndex].columns[1].modified = true;
                } else if (temp.rows[dimIndex].columns[1].value == "STANDARD") {
                    const [manL, modL] = getManModForEquipType(manModDets, record.manufacturer);
                    setModelList(modL);
                    setManList(manL);
                    temp.rows[manIndex].columns[1].value = record.manufacturer ? record.manufacturer.match(/UPT:NewEquip/i) ? "Select Manufacturer" : record.manufacturer : record.manufacturer;
                    temp.rows[modelIndex].columns[1].value = record.model ? record.model.match(/UPT:NewEquip-001/i) ? "Select Model" : record.model : record.model;
                    const modelOptionData: pecOptionData[] = modL.map(e => ({ label: e, value: e }));
                    temp.rows[modelIndex].columns[1].data = modelOptionData;
                    temp.rows[manIndex].columns[1].disabled = false;
                    temp.rows[modelIndex].columns[1].disabled = false;
                    temp.rows[heightIndex].columns[1].disabled = true;
                    temp.rows[widthIndex].columns[1].disabled = true;
                    temp.rows[depthIndex].columns[1].disabled = true;
                    if (record.manufacturer && !record.manufacturer.match(/UPT:NewEquip/i)) {
                        const selMM = manModDets.filter(e => e.manufacturer.toUpperCase() === record.manufacturer.toUpperCase() && e.model.toUpperCase() === record.model.toUpperCase() && e.type.toUpperCase() === getManEquipType(record.type).toUpperCase())[0];
                        temp.rows[heightIndex].columns[1].value = parseFloat((inchesToSelUnitConvFactor * selMM?.height.toString()).toFixed(6));
                        temp.rows[widthIndex].columns[1].value = parseFloat((inchesToSelUnitConvFactor * selMM?.width.toString()).toFixed(6));
                        temp.rows[depthIndex].columns[1].value = parseFloat((inchesToSelUnitConvFactor * selMM?.depth.toString()).toFixed(6));
                    }
                }
                break;
            }
            default: {
                const unitConvFactor = unit == unitSystemType.IMPERIAL ? 3.28084 : 1;
                const towerEl = towerData ? (towerData.top_altitude - towerData.base_altitude) * unitConvFactor : "Not Available";
                const concreteHeight = parseFloat(getValueInRequiredUnit(props.concrete_height));
                let valid: boolean = true;

                switch (theName) {
                    case "elevationHeight": {
                        if (towerEl != "Not Available") {
                            if (ConfigManager.AGL) {
                                if (theValue >= 0 && theValue < concreteHeight) {
                                    valid = false;
                                    IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Warning, `Equipment elevation can not be a less than Concrete Height.`, "", OutputMessageType.Toast));
                                }
                                else if (theValue > 0 && theValue > towerEl + concreteHeight) {
                                    valid = false;
                                    IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Warning, `Equipment elevation can not be greater than the tower height ${Math.floor(towerEl + concreteHeight)} ${unit == unitSystemType.METRIC ? "Meters" : "Feet"}.`, "", OutputMessageType.Toast));
                                }
                                else if (theValue < 0) {
                                    valid = false;
                                    IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Warning, `Equipment elevation can not be a negative value < 0.`, "", OutputMessageType.Toast));
                                }
                            }
                            else {
                                if (theValue > 0 && theValue > towerEl) {
                                    valid = false;
                                    IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Warning, `Equipment elevation can not be greater than the tower height ${Math.floor(towerEl)} ${unit == unitSystemType.METRIC ? "Meters" : "Feet"}.`, "", OutputMessageType.Toast));
                                } else if (theValue < 0) {
                                    valid = false;
                                    IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Warning, `Equipment elevation can not be a negative value < 0.`, "", OutputMessageType.Toast));
                                }
                            }
                        } else if (theValue < 0) {
                            valid = false;
                            IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Warning, `Equipment ${theName} can not less than -180 & greater than +180 degrees.`, "", OutputMessageType.Toast));
                        }
                        break;
                    }
                    case "roll":
                    case "tilt": {
                        if (theValue > 180 || theValue < -180) {
                            valid = false;
                            IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Warning, `Equipment ${theName} can not less than -180 & greater than +180 degrees.`, "", OutputMessageType.Toast));
                        }
                        break;
                    }
                    case "azimuth": {
                        if (theValue < 0 || theValue > 360) {
                            valid = false;
                            IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Warning, `Equipment ${theName} can not less than 0 & greater than 360 degrees.`, "", OutputMessageType.Toast));
                        }
                        break;
                    }
                    case "height":
                    case "width":
                    case "depth": {
                        if (towerEl != "Not Available") {
                            if (theValue > 0 && theValue > towerEl / 2) {
                                valid = false;
                                IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Warning, `Equipment ${theName} can not be too big.`, "", OutputMessageType.Toast));
                            } else if (theValue <= 0) {
                                valid = false;
                                IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Warning, `Equipment ${theName} can not be 0 or a negative value.`, "", OutputMessageType.Toast));
                            }
                        } else if (theValue < 0) {
                            valid = false;
                            IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Warning, `Equipment ${theName} can not be a negative value < 0.`, "", OutputMessageType.Toast));
                        }
                        break;
                    }
                }

                if (valid) {
                    temp.rows[rowIndex].columns[1].value = theValue;
                    temp.rows[rowIndex].columns[1].modified = true;
                }
                break;
            }
        }
        setModalFieldVals(temp);
        let modified = false;
        temp.rows.map(e => {
            const recType = typeof (record[e.columns[0].name])
            if (e.columns[0].name != "equipmentLibrary" && e.columns[0].name != "agl") {
                if (recType == "number" && !e.columns[1].name.match(/azimuth|roll|tilt/i)) {
                    if (getValueInRequiredUnit(record[e.columns[1].name]) != (Math.round(e.columns[1].value as number * 100) / 100).toFixed(2)) modified = true;
                } else if (record[e.columns[1].name] != e.columns[1].value) {
                    modified = true;
                }
                else if (e.columns[1].name === "tilt" && -1 * record[e.columns[1].name] != e.columns[1].value) {
                    modified = true;
                }
            }
        });
        setEnableSave(modified);
    }

    const openModal = async () => {
        let dimTy = "";
        let manL: any[] = [], modL: any[] = [];
        const theRec = await getData(props.selectedEquipment);
        setRecord(theRec);
        let meterToSelUnitConvFactor = 0;
        if (theRec != undefined) {
            dimTy = theRec.manufacturer && theRec.manufacturer.match(/UPT:NewEquip/i) ? "CUSTOM" : theRec.manufacturer && !theRec.manufacturer.match(/UPT:NewEquip/i) ? "STANDARD" : "CUSTOM";
            meterToSelUnitConvFactor = props.unitSystem == unitSystemType.IMPERIAL ? 3.2808399 : 1;
            const poundsToSelUnitConvFactor = props.unitSystem == unitSystemType.IMPERIAL ? 1 : 0.453592;
            if (dimTy == "STANDARD" && !theRec.manufacturer.match(/UPT:NewEquip/i)) {
                manL = [...new Set(["Select Manufacturer", ...[theRec.manufacturer, ...manList].sort()])];
                modL = [...new Set(["Select Model", ...[theRec.model, ...modelList].sort()])];
            } else if (dimTy == "CUSTOM") {
                manL = [...new Set(["Select Manufacturer", ...manList.sort()])];
                modL = [...new Set(["Select Model", ...modelList.sort()])];
            }
            const mountFaceValue = theRec.plat && theRec.faceName ? [...new Set(theRec.faceName?.replace(ConfigManager.PasCaseToSpSepRegExp, "$1$4 $2$3$5").split(' '))].join(' ') : "NA";
            setManList([...manL]);
            setModelList([...modL]);
            setEnableSave(false);
            const tempRec = { ...theRec, equipmentLibrary: dimTy, face: mountFaceValue }
            const recData = Object.entries(tempRec);

            const temp: pecGridStructure = { ...modalFieldVals };

            temp.rows.forEach(e => {
                let theKeyValue = recData.filter((f) => f[0] === e.columns[0].name) as any;
                let theValue: any = theKeyValue.length ? theKeyValue[0][1] as string : "";
                if(theKeyValue.length){
                    switch (e.columns[1].name) {
                        case "manufacturer":
                        case "model": {
                            e.columns[1].value = theValue;
                            e.columns[1].disabled = dimTy === "CUSTOM";
                            let optionData: pecOptionData[] = []
                            if (e.columns[1].name === "manufacturer") {
                                const selectedMan: any[] = ["Select Manufacturer", theRec.manufacturer];
                                optionData = (dimTy == "STANDARD" && manL.length) ? manL.map(e => ({ label: e, value: e })) : selectedMan.map(e => ({ label: e, value: e }))
                            }
                            else {
                                const selectedMod: any[] = ["Select Model", theRec.model];
                                optionData = (dimTy == "STANDARD" && modL.length) ? modL.map(e => ({ label: e, value: e })) : selectedMod.map(e => ({ label: e, value: e }));
                            }
                            e.columns[1].data = optionData;
                            break;
                        }
                        case "height":
                        case "width":
                        case "depth":
                        case "elevationHeight": {
                            if (e.columns[1].name !== "elevationHeight") {
                                e.columns[1].value = ((parseFloat(theValue)) * 1).toFixed(6);
                                e.columns[1].disabled = dimTy === "STANDARD";
                                e.columns[1].value = ReduceDecimalNumberToTwoPlaces( AppSettings.defaultDisplaySettings.unitSystem == unitSystemType.METRIC ? e.columns[1].value : getValueInRequiredUnit(parseFloat(e.columns[1].value)*39.37, true ,true)).toString()
                            }
                            else{
                                e.columns[1].value = ((parseFloat(theValue) + props.concrete_height) * meterToSelUnitConvFactor).toFixed(3);
                            }
                            break;
                        }
                        case "tilt": {
                            e.columns[1].value = (-1 * parseFloat(theValue)).toString();
                            break;
                        }
                        case "active": {
                            e.columns[1].value = theValue == false ? "False" : "True";
                            break;
                        }
                        case "equipmentLibrary": {
                            e.columns[1].value = theValue ;
                            break;
                        }
                        case "face":{
                            e.columns[1].disabled = theRec.faceName && theRec.plat ? false : true;
                            let optionData: pecOptionData[] = []
                            if(theRec.faceName && theRec.plat){
                                const faces = props.mountsData[theRec.plat].FACEs;
                                const tempFaces = Object.entries(faces);
                                const faceList:any[] = [];
                                tempFaces.forEach(e => {
                                    let mf = [...new Set(e[0]?.replace(ConfigManager.PasCaseToSpSepRegExp, "$1$4 $2$3$5").split(' '))].join(' ');
                                    faceList.push(mf)}
                                    )
                                optionData = faceList.map(e => ({ label: e, value: e }));
                            }
                            else{
                                optionData = ["NA"].map(e => ({ label: e, value: e }));
                            }
                            e.columns[1].value = theValue ;
                            e.columns[1].data = optionData;
                            break;
                        }
                        default: {
                            e.columns[1].value = parseFloat(theValue);
                            break;
                        }
                    }
                }
            })
            setModalFieldVals(temp);
            setShowModal(true)
        }
        else {
            IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Error, "Error! ,Issue With Data", "", OutputMessageType.Toast));
        }
    };

    let propertEditorStructure: pecComponentProps = {
        structureType: pecStrucType.STRUCTURE,
        title: {
            label: "Edit Properties ",
        },
        grid: modalFieldVals,
        gridButtons: modalBtns,
        eventHandlers: {
            onChange: onPropertyModalChange,
            onClick: onModalBtnClick,
            onUpdate: onFieldUpdate,
        }
    }
    return (
        <>
            {(modalFieldVals.rows.length && showModal) ?
                <PropertyEditorComponent {...propertEditorStructure} />
                : null
            }
        </>
    );
}

const mapStateToProps = (state: RootState) => {
    const towerdata = state.detectedData.towerStructureData;
    const concrete_height = ConfigManager.AGL ? Object.entries(towerdata).filter((f) => f[0] === "concrete_height")[0][1] : 0;
    const unitSystem: unitSystemType = AppSettings.defaultDisplaySettings.unitSystem;
    // const selectedEquipment = EquipmentShapeDecorator.equipmentName;
    const selectedEquipment = ShapeDecorator.selectedEquipment.displayName;
    const mountsData = state.detectedData.mountingsData.mounts;
    const rows: pecRows[] = [
        {
            columns: [
                {
                    size: 5,
                    type: pecInputTagTypes.LABEL,
                    customClass: "",
                    value: `Elevation Height ${getUnit()} *:`,
                    disabled: false,
                    name: "elevationHeight",
                    fieldType: pecDataValueTypes.INTEGER,
                    modified: false,
                    styles: { parentClass: "", childClass: "" }
                },
                {
                    size: 7,
                    type: pecInputTagTypes.INPUT,
                    customClass: "",
                    value: "",
                    disabled: false,
                    name: "elevationHeight",
                    fieldType: pecDataValueTypes.FLOAT,
                    modified: false,
                    styles: { parentClass: 'col-6', childClass: "dialogBox-propEditorComp-input col-12" }

                }
            ]
        },
        // Commenting the Edit of Mount Face at the moment as the exact requirement is under review.
        // {
        //     columns: [
        //         {
        //             size: 5,
        //             type: pecInputTagTypes.LABEL,
        //             customClass: "",
        //             value: "Mount Face:",
        //             disabled: false,
        //             name: "face",
        //             fieldType: pecDataValueTypes.INTEGER,
        //             modified: false,
        //             styles: { parentClass: "", childClass: "" }
        //         },
        //         {
        //             size: 7,
        //             type: pecInputTagTypes.SELECT,
        //             customClass: "",
        //             value: "",
        //             disabled: false,
        //             name: "face",
        //             fieldType: pecDataValueTypes.STRING,
        //             modified: false,
        //             styles: { parentClass: "", childClass: "" }
        //         }
        //     ]
        // },
        {
            columns: [
                {
                    size: 5,
                    type: pecInputTagTypes.LABEL,
                    customClass: "",
                    value: "Equipment Library:",
                    disabled: false,
                    name: "equipmentLibrary",
                    fieldType: pecDataValueTypes.INTEGER,
                    modified: false,
                    styles: { parentClass: "", childClass: "" }
                },
                {
                    size: 7,
                    type: pecInputTagTypes.SELECT,
                    customClass: "",
                    value: "",
                    disabled: false,
                    name: "equipmentLibrary",
                    fieldType: pecDataValueTypes.STRING,
                    modified: false,
                    data: ["STANDARD", "CUSTOM"].map(e => ({ label: e, value: e })),
                    styles: { parentClass: "", childClass: "" }
                }
            ]
        },
        {
            columns: [
                {
                    size: 5,
                    type: pecInputTagTypes.LABEL,
                    customClass: "",
                    value: "Manufacturer:",
                    disabled: false,
                    name: "manufacturer",
                    fieldType: pecDataValueTypes.INTEGER,
                    modified: false,
                    styles: { parentClass: "", childClass: "" }
                },
                {
                    size: 7,
                    type: pecInputTagTypes.SELECT,
                    customClass: "",
                    value: "",
                    disabled: false,
                    name: "manufacturer",
                    fieldType: pecDataValueTypes.STRING,
                    modified: false,
                    styles: { parentClass: "", childClass: "" }
                }
            ]
        },
        {
            columns: [
                {
                    size: 5,
                    type: pecInputTagTypes.LABEL,
                    customClass: "",
                    value: "Model:",
                    disabled: false,
                    name: "model",
                    fieldType: pecDataValueTypes.INTEGER,
                    modified: false,
                    styles: { parentClass: "", childClass: "" }
                },
                {
                    size: 7,
                    type: pecInputTagTypes.SELECT,
                    customClass: "",
                    value: "",
                    disabled: false,
                    name: "model",
                    fieldType: pecDataValueTypes.STRING,
                    modified: false,
                    styles: { parentClass: "", childClass: "" }
                }
            ]
        },
        {
            columns: [
                {
                    size: 5,
                    type: pecInputTagTypes.LABEL,
                    customClass: "",
                    value: `Height ${ AppSettings.defaultDisplaySettings.unitSystem == unitSystemType.METRIC? getUnit(false,false):getUnit(false,true)}:`,
                    disabled: false,
                    name: "height",
                    fieldType: pecDataValueTypes.INTEGER,
                    modified: false,
                    styles: { parentClass: "mb-3", childClass: "" }
                },
                {
                    size: 7,
                    type: pecInputTagTypes.INPUT,
                    customClass: "",
                    value: 0.0,
                    disabled: false,
                    name: "height",
                    fieldType: pecDataValueTypes.FLOAT,
                    modified: false,
                    styles: { parentClass: '', childClass: "dialogBox-propEditorComp-input col-12" }
                }
            ]
        },
        {
            columns: [
                {
                    size: 5,
                    type: pecInputTagTypes.LABEL,
                    customClass: "",
                    value: `Width ${ AppSettings.defaultDisplaySettings.unitSystem == unitSystemType.METRIC? getUnit(false,false):getUnit(false,true)}:`,
                    disabled: false,
                    name: "width",
                    fieldType: pecDataValueTypes.INTEGER,
                    modified: false,
                    styles: { parentClass: "", childClass: "" }
                },
                {
                    size: 7,
                    type: pecInputTagTypes.INPUT,
                    customClass: "",
                    value: 0.0,
                    disabled: false,
                    name: "width",
                    fieldType: pecDataValueTypes.FLOAT,
                    modified: false,
                    styles: { parentClass: 'col-6', childClass: "dialogBox-propEditorComp-input col-12" }
                }
            ]
        },
        {
            columns: [
                {
                    size: 5,
                    type: pecInputTagTypes.LABEL,
                    customClass: "",
                    value: `Thickness ${ AppSettings.defaultDisplaySettings.unitSystem == unitSystemType.METRIC? getUnit(false,false):getUnit(false,true)}:`,
                    disabled: false,
                    name: "depth",
                    fieldType: pecDataValueTypes.INTEGER,
                    modified: false,
                    styles: { parentClass: "", childClass: "" }
                },
                {
                    size: 7,
                    type: pecInputTagTypes.INPUT,
                    customClass: "",
                    value: 0.0,
                    disabled: false,
                    name: "depth",
                    fieldType: pecDataValueTypes.FLOAT,
                    modified: false,
                    styles: { parentClass: 'col-6', childClass: "dialogBox-propEditorComp-input col-12" }
                }
            ]
        },
        {
            columns: [
                {
                    size: 5,
                    type: pecInputTagTypes.LABEL,
                    customClass: "",
                    value: "Azimuth (°):",
                    disabled: false,
                    name: "azimuth",
                    fieldType: pecDataValueTypes.INTEGER,
                    modified: false,
                    styles: { parentClass: "", childClass: "" }
                },
                {
                    size: 7,
                    type: pecInputTagTypes.INPUT,
                    customClass: "",
                    value: 0,
                    disabled: false,
                    name: "azimuth",
                    fieldType: pecDataValueTypes.DEGREE,
                    modified: false,
                    styles: { parentClass: 'col-6', childClass: "dialogBox-propEditorComp-input col-12" }
                }
            ]
        },
        {
            columns: [
                {
                    size: 5,
                    type: pecInputTagTypes.LABEL,
                    customClass: "",
                    value: "Roll (°):",
                    disabled: false,
                    name: "roll",
                    fieldType: pecDataValueTypes.INTEGER,
                    modified: false,
                    styles: { parentClass: "", childClass: "" }
                },
                {
                    size: 7,
                    type: pecInputTagTypes.INPUT,
                    customClass: "",
                    value: 0,
                    disabled: false,
                    name: "roll",
                    fieldType: pecDataValueTypes.DEGREE,
                    modified: false,
                    styles: { parentClass: 'col-6', childClass: "dialogBox-propEditorComp-input col-12" }
                }
            ]
        },
        {
            columns: [
                {
                    size: 5,
                    type: pecInputTagTypes.LABEL,
                    customClass: "",
                    value: "Down Tilt (°):",
                    disabled: false,
                    name: "tilt",
                    fieldType: pecDataValueTypes.INTEGER,
                    modified: false,
                    styles: { parentClass: "", childClass: "" }
                },
                {
                    size: 7,
                    type: pecInputTagTypes.INPUT,
                    customClass: "",
                    value: 0,
                    disabled: false,
                    name: "tilt",
                    fieldType: pecDataValueTypes.DEGREE,
                    modified: false,
                    styles: { parentClass: 'col-6', childClass: "dialogBox-propEditorComp-input col-12" }
                }
            ]
        },
        {
            columns: [
                {
                    size: 5,
                    type: pecInputTagTypes.LABEL,
                    customClass: "",
                    value: "Active:",
                    disabled: false,
                    name: "isActive",
                    fieldType: pecDataValueTypes.INTEGER,
                    modified: false,
                    styles: { parentClass: "", childClass: "" }
                },
                // {
                //     size: 7,
                //     type: pecInputTagTypes.TOGGLE,
                //     customClass: "",
                //     value: true,
                //     disabled: false,
                //     name: "active",
                //     fieldType: pecDataValueTypes.BOOLEAN,
                //     modified: false,
                //     styles: { parentClass: 'dialogBox-propEditorComp-toggle', childClass: "col-12" }
                // }
                {
                    size: 7,
                    type: pecInputTagTypes.SELECT,
                    customClass: "",
                    value: "True",
                    disabled: false,
                    name: "active",
                    fieldType: pecDataValueTypes.STRING,
                    modified: false,
                    data: ["True", "False"].map(e => ({ label: e, value: e })),
                    styles: { parentClass: "", childClass: "" }
                }
            ]
        },
        {
            columns: [
                {
                    size: 12,
                    type: pecInputTagTypes.LABEL,
                    customClass: "",
                    value: ConfigManager.AGL ? "*: Above ground level (AGL) is enabled and applied." : "*: Above ground level (AGL) is disabled.",
                    disabled: false,
                    name: "agl",
                    fieldType: pecDataValueTypes.INTEGER,
                    modified: false,
                    styles: { parentClass: "mt-1", childClass: " pb-2" }
                }
            ]
        }
    ]

    const equipmentPropertiesRow: pecGridStructure = {
        rows: rows
    }

    const retState = {
        unitSystem,
        selectedEquipment,
        equipmentPropertiesRow,
        concrete_height,
        mountsData
    }
    return retState;
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(GetEquipmentProperties);

export const getEquipmentKeyValue = async (EquipmentName: string) => {
    const towerdata = store.getState().detectedData.towerStructureData;
    const concreteHeight = ConfigManager.AGL ? Object.entries(towerdata).filter((f) => f[0] === "concrete_height")[0][1] : 0;
    // const dataProvider: PropertyRecord[] = [];
    const record = await getData(EquipmentName);
    if (!record) return;
    let positiveAzimuth = 0;
    // if (record.azimuth < 0) {
    //     positiveAzimuth = 360.0 + record.azimuth;
    // } else {
        positiveAzimuth = record.azimuth;
    // }
    const man = record.manufacturer ? record?.manufacturer.match(/UPT:NewEquip/i) ? "" : record.manufacturer : "";
    const model = record.model ? record?.model.match(/UPT:NewEquip-001/i) ? "" : record.model : "";
    const mf = [...new Set(record.faceName?.replace(ConfigManager.PasCaseToSpSepRegExp, "$1$4 $2$3$5").split(' '))].join(' ');
    let nameValuePair: { name: string, value: string }[] | any = [];

    // dataProvider.push(PropertyRecord.fromString(record.name, "Equipment Name"));
    nameValuePair.push({ name: "Equipment Name", value: record.displayName });

    // dataProvider.push(PropertyRecord.fromString(record.type, "Equipment Type"));
    nameValuePair.push({ name: "Equipment Type", value: record.displayName.match(/SQUID/ig) ? "SQUID" : record.type });

    // dataProvider.push(PropertyRecord.fromString(man, "Manufacturer"));
    nameValuePair.push({ name: "Manufacturer", value: man });

    // dataProvider.push(PropertyRecord.fromString(model, "Model"));
    nameValuePair.push({ name: "Model", value: model });

    const currState = store.getState();
    const mountingsData = currState.detectedData.mountingsData;
    const equipmentMount = mountingsData.equipment[record.name] ? mountingsData.equipment[record.name].plat : "NA";
    
    const sector = mountingsData.mounts[equipmentMount]?.FACEs_info[record.faceName]?.Sector ?? undefined;
    nameValuePair.push({ name: "Sector", value: (sector == undefined || sector == null) ? "" : sector });
    nameValuePair.push({ name: "Mount Face", value: (record.faceName == undefined || record.faceName == null) ? "" : mf });

    // const selParMount = MountDecorator.mountData.filter((e) => e[0] === selMount.modelData.parentMount)[0];
    const centelineElevationMetric = (currState.detectedData.siteCoordinate && equipmentMount != "NA" && equipmentMount != null && equipmentMount != undefined) ? ReduceDecimalNumberToTwoPlaces(mountingsData.mounts[equipmentMount].utm_altitude - currState.detectedData.siteCoordinate.utm.z) : "0";
    let centerlineElevation =  AppSettings.defaultDisplaySettings.unitSystem == unitSystemType.METRIC?(+centelineElevationMetric + concreteHeight).toFixed(2): ((+centelineElevationMetric + concreteHeight)*3.28084).toFixed(2)

    // dataProvider.push(PropertyRecord.fromString(getValueInRequiredUnit(record.elevationHeight) ?? "", "Elevation Height " + getUnit()));
    // const mountCEl = record.elevationHeight + concreteHeight;
    const mountCEl = parseFloat(centerlineElevation);
    // nameValuePair.push({ name: `Mount Centerline Elevation ${getUnit()} *`, value: getValueInRequiredUnit(mountCEl) ?? "" });

    // dataProvider.push(PropertyRecord.fromString(ReduceDecimalNumberToTwoPlaces(positiveAzimuth).toString(), "Azimuth (" + "\u00b0" + ")"));
    let azim = ReduceDecimalNumberToTwoPlaces(positiveAzimuth);
    // azim = azim > 180 ? azim - 360 : azim;
    nameValuePair.push({ name: "Azimuth (°)", value: azim.toString() });

    // dataProvider.push(PropertyRecord.fromString(ReduceDecimalNumberToTwoPlaces(-1 * record.tilt).toString(), "Down Tilt (" + "\u00b0" + ")"));
    nameValuePair.push({ name: "Down Tilt (°)", value: ReduceDecimalNumberToTwoPlaces(-1 * record.tilt).toString() });

    // dataProvider.push(PropertyRecord.fromString(ReduceDecimalNumberToTwoPlaces(record.roll).toString(), "Roll (" + "\u00b0" + ")"));
    nameValuePair.push({ name: "Roll (°)", value: ReduceDecimalNumberToTwoPlaces(record.roll).toString() });

    // dataProvider.push(PropertyRecord.fromString(getValueInRequiredUnit(record.height), "Height " + getUnit()));
    if(record.type != EquipmentType.Microwave)nameValuePair.push({ name: `Height ${ AppSettings.defaultDisplaySettings.unitSystem == unitSystemType.METRIC? getUnit(false,false):getUnit(false,true)}`, value: ReduceDecimalNumberToTwoPlaces( AppSettings.defaultDisplaySettings.unitSystem == unitSystemType.METRIC?getValueInRequiredUnit(record.height):getValueInRequiredUnit(record.height*39.37, true ,true)).toString() });

    // dataProvider.push(PropertyRecord.fromString(getValueInRequiredUnit(record.width), "Width " + getUnit()));
    let label = record.type == EquipmentType.Microwave ? "Width" : "Width"
    nameValuePair.push({ name: `${label} ${ AppSettings.defaultDisplaySettings.unitSystem == unitSystemType.METRIC? getUnit(false,false):getUnit(false,true)}`, value: ReduceDecimalNumberToTwoPlaces( AppSettings.defaultDisplaySettings.unitSystem == unitSystemType.METRIC?getValueInRequiredUnit(record.width):getValueInRequiredUnit(record.width*39.37, true ,true)).toString() });
    
    // dataProvider.push(PropertyRecord.fromString(getValueInRequiredUnit(record.depth), "Thickness " + getUnit()));
    label = record.type == EquipmentType.Microwave ? "Thickness" : "Thickness"
    if(record.type != EquipmentType.SQUID)nameValuePair.push({ name: `${label} ${ AppSettings.defaultDisplaySettings.unitSystem == unitSystemType.METRIC? getUnit(false,false):getUnit(false,true)}`, value: ReduceDecimalNumberToTwoPlaces( AppSettings.defaultDisplaySettings.unitSystem == unitSystemType.METRIC?getValueInRequiredUnit(record.depth):getValueInRequiredUnit(record.depth*39.37, true ,true)).toString() });

    nameValuePair.push({ name: `Weight ${getUnit(true,false)}`, value: ReduceDecimalNumberToTwoPlaces(getValueInRequiredUnit(record.weight, false, false, true))});
    // dataProvider.push(PropertyRecord.fromString(getValueInRequiredUnit(record.lateralOffset), "Lateral Offset " + getUnit()));
    nameValuePair.push({ name: `Lateral Offset ${getUnit()}`, value: ReduceDecimalNumberToTwoPlaces(getValueInRequiredUnit(record.lateralOffset)).toString() });

    // dataProvider.push(PropertyRecord.fromString(getValueInRequiredUnit(record.standoffDistance), "Standoff Distance " + getUnit()));
    nameValuePair.push({ name: `Standoff Distance ${getUnit()}`, value: ReduceDecimalNumberToTwoPlaces(getValueInRequiredUnit(record.standoffDistance)).toString() });

    // dataProvider.push(PropertyRecord.fromString(getValueInRequiredUnit(record.verticalOffset), "Vertical Offset " + getUnit()));
    nameValuePair.push({ name: `Vertical Offset ${getUnit()}`, value: ReduceDecimalNumberToTwoPlaces(getValueInRequiredUnit(record.verticalOffset)).toString() });
    const ECE = mountCEl + parseFloat(getValueInRequiredUnit(record.verticalOffset));
    nameValuePair.push({ name: `Equipment Centerline Elevation ${getUnit()} *`, value: ReduceDecimalNumberToTwoPlaces(ECE).toString() });
    nameValuePair.push({ name: `Mount Centerline Elevation ${getUnit()} *`, value: centerlineElevation ?? "" });
    
    nameValuePair.push({ name: `Active`, value: record.isActive == false ? "False" : "True" });


    let propListObj: selectedObjectInformation = initialPropertyListObjectState;
    propListObj = { ...propListObj, heading: "Tower Information", propertyList: init_RM_TowerPropListState };

    return nameValuePair;
}

const getData = async (equipName: string) => {
    try {
        if (equipName && equipName!.includes('face'))
            IndividualShapeDecorator.selectedEquipName = equipName;
        const data = await SelectAntennaTool.fetchEquipmentData(equipName);
        return data;
    } catch (e) {
        Logger.error("Error while calling fetchEquipmentData api", e);
    }
}