import { EquipmentInformation, EquipmentsTable } from "../components/Tables/EquipmentsTable";
import { AccessToken, GuidString } from "@itwin/core-bentley";
import { Logger } from "./logging";
import { store } from "../../store/rootReducer";
import { RootState } from "../../store/States";
import { EquipmentPosition } from "../tools/decorators/EditableShapeDecorator";
import { ConfigManager } from "../../config/ConfigManager";
import { IModelApp, NotifyMessageDetails, OutputMessagePriority, OutputMessageType } from "@itwin/core-frontend";
import { equipmentData } from "../../store/detectedData/apiDataTypes";
import { EquipmentData } from "../tools/decorators/ShapeDecorator";
import { EquipmentType } from "../../store/redux-types";

let privateAccessToken: AccessToken;
const listener = () => {
    setProjectIdUrl(store.getState());
}

function setProjectIdUrl(state: RootState) {
    privateAccessToken = state.auth.accessTokenStatePrivateAPI.accessToken!;
}

store.subscribe(listener);


export const createEquipment = async (infoData: EquipmentInformation, token: AccessToken) => {
  let retResp: boolean = false;
  if (infoData) {
    retResp = await EquipmentClient.postEquipmentJson(token, infoData, "v1.1");
  }
  return Promise.resolve(retResp);

} 

export const saveEquipment = async (posData: EquipmentData) => {
  let retResp: boolean = false;
  if (posData) {
    const equipData: EquipmentInformation={...EquipmentsTable.equipmentData.filter((e)=>{return e.name === posData.Equipment_Name})[0]};
    const theIndex=EquipmentsTable.equipmentData.findIndex((e)=>{return e.name === posData.Equipment_Name});
    const currentState = store.getState();
    const putData: equipmentData = {                                                                
        name: posData.Equipment_Name,
        displayName: equipData.displayName!,
        manufacturer: posData.Manufacturer,
        model: posData.Model,
        type: equipData.type,
        operator: equipData.operator!,
        height: posData.Height,
        elevationHeight: 0,
        width: posData.Width,
        depth: posData.Thickness,
        weight: equipData.weight!,
        azimuth: posData.Azimuth,
        tilt: -1*posData.DownTilt,
        xPosition: posData.x_position,
        yPosition: posData.y_position,
        zPosition: posData.z_position,
        isActive: posData.isActive!,
        roll: posData.Roll,
        area: equipData.area!,
        bandTechnology: equipData.bandTechnology!,
        reference: equipData.reference!,
    };
    putData.elevationHeight =  posData.Elevation_Height == undefined ? (posData.z_position-currentState.detectedData.siteCoordinate.utm.z): posData.Elevation_Height;
    const infoData = currentState.detectedData.selectedObjectInformation.objectProperties;
    if (infoData && posData && currentState.detectedData.siteCoordinate) {
      await EquipmentClient.putEquipmentJson(currentState.auth.accessTokenStatePrivateAPI.accessToken!, putData, "v1.1")
        // tslint:disable-next-line:no-shadowed-variable
        .then(async (res) => {
          if (!res) {
              IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Error,
                  `Error occured: ${name} edit changes not saved.`, "", OutputMessageType.Toast));
                  retResp = false;
          } else {
            // equipData.name=posData.Equipment_Name;
            // equipData.manufacturer=posData.Manufacturer;
            // equipData.model=posData.Model;
            // equipData.height=posData.Height;
            // equipData.elevationHeight=putData.elevationHeight;
            // equipData.width=posData.Width;
            // equipData.depth=posData.Thickness;
            // equipData.azimuth=posData.Azimuth;
            // equipData.tilt=-1*posData.DownTilt;
            // equipData.xPosition=posData.x_position;
            // equipData.yPosition=posData.y_position;
            // equipData.zPosition=posData.z_position;
            // equipData.roll=posData.Roll;
            // equipData.dimensions=`${posData.Height}x${posData.Width}x${posData.Thickness}`;

            // // (IModelApp as any).listCallback(ListEnum.Equipment);y
            // EquipmentsTable.equipmentData[theIndex] = equipData;
            IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Info,
                `Success: ${equipData.displayName} changes saved.`, "", OutputMessageType.Toast));
                retResp = true;
          }
        });
    } else {
      IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Error,
      `Error occured: ${equipData.displayName} edit changes not saved.`, "", OutputMessageType.Toast));
      retResp = false;
    }
  }
  return Promise.resolve(retResp);

} 

export default class EquipmentClient {
  constructor() { }

    public static setSiteCoordinates = async (token: string = privateAccessToken) => {
      // const ConfigManager.projectId = ConfigManager.ConfigManager.projectId;
      // const { ConfigManager.projectId } = store.getState().detectedData.projectDetails;
      const url = `${ConfigManager.openToweriQUrl}/v1.0/${ConfigManager.projectId}/Site/Coordinates/`;

      return fetch(url, {
          method: "get",
          headers: {
              Authorization: token,
          },
      }).then(async (response: Response) => {
          if (!response.ok) {
            return null;
          }
          return response.json();
      }).then((data: any) => {
          return data;
      });
    }

    public static async getEquipmentInfoJson(token: string = privateAccessToken, name: string): Promise<any> {
      if (name) {
          const equipName = name.replace(/_wave/g, "_Wave");
          // const ConfigManager.projectId = ConfigManager.ConfigManager.projectId;
          const url = `${ConfigManager.openToweriQUrl}/v1.0/${ConfigManager.projectId}/Equipment/${equipName}`;

          return fetch(url, {
              method: "get",
              headers: {
                  Authorization: token,
              },
          }).then(async (response: Response) => {
              if (!response.ok) {
                  return null;
              }
              return response.json();
          }).then((data: any) => {
              return data;
          });
      }
    }

  public static async getAllManModels(token: string = privateAccessToken) : Promise<any> {
    const url = `${ConfigManager.openToweriQUrl}/v1.0/Antenna?isActive=true`;

    return fetch(url, {
      method: "GET",
      headers: {
      Authorization: token
    },
    }).then(async (response: Response) => {
      if (!response.ok)return null;
      return response.json();
    }).then((data: any) => {
      
      return data;
    });
  }
  
  public static async getAllOrgManModels(token: string = privateAccessToken, ultimateRefId: GuidString) : Promise<any> {
    const url = `${ConfigManager.openToweriQUrl}/v1.0/Org/${ultimateRefId}/Antenna?includeGlobal=true`;

    return fetch(url, {
      method: "GET",
      headers: {
      Authorization: token
    },
    }).then(async (response: Response) => {
      if (!response.ok)return null;
      return response.json();
    }).then((data: any) => {
      
      return data;
    });
  }
  

  public static async getAllPolygons(token: string = privateAccessToken) : Promise<any> {
    const url = `${ConfigManager.openToweriQUrl}/v1.0/PolygonPoint`;

    return fetch(url, {
      method: "GET",
      headers: {
      Authorization: token
    },
    }).then(async (response: Response) => {
      if (!response.ok)return null;
      return response.json();
    }).then((data: any) => {
      
      return data;
    });
  }
  
  public static async getInsiteImageUrl(token: string = privateAccessToken): Promise<any> {
    // const ConfigManager.projectId = ConfigManager.ConfigManager.projectId;
    const url = `${decodeURIComponent(
      window.location.origin,
    )}/api/Equipment/imageurl/` + ConfigManager.projectId;

    return fetch(url, {
      method: "get",
      headers: {
        Authorization: token,
      },
    }).then(async (response: Response) => {
      if (!response.ok) {
        return "err";
      }
      return await response.json();
    }).then((data: any) => {
      if (data === "err") {
        return null;
      }
      return data;
    });
  }

  
  public static async getAdmin(token: string = privateAccessToken): Promise<any> {
    
    const url = `${decodeURIComponent(window.location.origin)}/organization/user/a`;

    return fetch(url, {
      method: "post",
      headers: {
        Authorization: token,
      },
    }).then(async (response: Response) => {
      if (!response.ok) {
        return "err";
      }
      return response.json();
    }).then((data: any) => {
      if (data === "Error while determining user role.") {
        return null;
      }
      return data;
    }).catch(err=>{
      Logger.error('Error occurred  fetching user admin status: ', err);
      return null;
    });
  }

  public static async getAllEquipmentJson(token: string = privateAccessToken, apiVersion: string = 'v1.0'): Promise<any> {
    // const ConfigManager.projectId = ConfigManager.ConfigManager.projectId;
    const url = `${ConfigManager.openToweriQUrl}/${apiVersion}/${ConfigManager.projectId}/Equipment`;
    return fetch(url, {
          method: "get",
          headers: {
              Authorization: token,
          },
      }).then(async (response: Response) => {
          if (!response.ok) {
              return "err";
          }
          return response.json();
      }).then((data: any) => {
          if (data === "err") {
              return null;
          }
          return data;
      });
  }

  public static async getEquipmentPositionJson(token: string = privateAccessToken, name: string): Promise<any> {
      if (name) {
          const equipName = name.replace(/_wave/g, "_Wave");
          // tslint:disable-next-line:no-console
          // const ConfigManager.projectId = ConfigManager.ConfigManager.projectId;
          const url = `${ConfigManager.openToweriQUrl}/v1.0/${ConfigManager.projectId}/Equipment/${name}/Position/`;

          return fetch(url, {
              method: "get",
              headers: {
                  Authorization: token,
              },
          }).then(async (response: Response) => {
              if (!response.ok) {
                  return null;
              }
              return response.json();
          }).then((data: any) => {
              return data;
          });
      }
  }
  public static async getTowerStructureJson(token: string = privateAccessToken): Promise<any> {
    // const ConfigManager.projectId = ConfigManager.ConfigManager.projectId;
    const url = `${ConfigManager.openToweriQUrl}/v1.0/${ConfigManager.projectId}/Site/Structure`;

    return fetch(url, {
        method: "get",
        headers: {
            Authorization: token,
        },
    }).then(async (response: Response) => {
        if (!response.ok) {
          ConfigManager.AGL = false;
            return null;
        }
        return response.json();
    }).then((data: any) => {
        return data;
    });
  }

  public static async getMountingsDataJson(token: string = privateAccessToken): Promise<any> {
        // tslint:disable-next-line:no-console
      // const ConfigManager.projectId = ConfigManager.ConfigManager.projectId;
      const url = `${ConfigManager.openToweriQUrl}/v1.1/${ConfigManager.projectId}/MountingsData`;

      return fetch(url, {
          method: "get",
          headers: {
              Authorization: token,
          },
      }).then(async (response: Response) => {
          if (!response.ok) {
              return null;
          }
          return response.json();
      }).then((data: any) => {
          return data;
      }).catch(err=>{
        Logger.error('Error Or No Data while fetching Mountings Data: ', err)
        return null;
      });
  }

  public static async getOperatorsJson(token: string = privateAccessToken): Promise<any> {
        // tslint:disable-next-line:no-console
      const url = `${ConfigManager.openToweriQUrl}/v1.0/Operator`;

      return fetch(url, {
          method: "get",
          headers: {
              Authorization: token,
          },
      }).then(async (response: Response) => {
          if (!response.ok) {
              return null;
          }
          return response.json();
      }).then((data: any) => {
          return data;
      });
  }

  public static async getTowerSiteImages(token: string = privateAccessToken): Promise<any> {
    // tslint:disable-next-line:no-console
  // const ConfigManager.projectId = ConfigManager.ConfigManager.projectId;
  const url = `${ConfigManager.openToweriQUrl}/v1.0/${ConfigManager.projectId}/Site/Images`;

  return fetch(url, {
      method: "get",
      headers: {
          Authorization: token,
      },
  }).then(async (response: Response) => {
      if (!response.ok) {
          return null;
      }
      return response.json();
  }).then((data: any) => {
      return data;
  });
}

  public static async postEquipmentPositionJson(token: string = privateAccessToken, equipData: EquipmentPosition, apiVersion: string="v1.0"): Promise<any> {
    if (!equipData || !equipData.Equipment_Name) {
      return;
    }
    // const ConfigManager.projectId = ConfigManager.ConfigManager.projectId;
    const url = `${ConfigManager.openToweriQUrl}/${apiVersion}/${ConfigManager.projectId}/Equipment/${equipData.Equipment_Name}/Position/`;
    return fetch(url, {
      method: "post",
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(equipData),
    }).then(async (res: Response) => {
      if (!res.ok) {
        return null;
      }
      return "successful";
    }).then((data: any) => {
      return data;
    });
  }

  public static async putEquipmentPositionJson(token: string = privateAccessToken, equipData: any): Promise<any> {
    if (!equipData || !equipData.Equipment_Name) {
      return;
    }
    // const ConfigManager.projectId = ConfigManager.ConfigManager.projectId;
    const url = `${ConfigManager.openToweriQUrl}/v1.0/${ConfigManager.projectId}/Equipment/${equipData.Equipment_Name}/Position/`;

    return fetch(url, {
      method: "put",
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(equipData),
    }).then(async (res: Response) => {
      if (!res.ok) {
        return null;
      }
      return "success";
    }).then((data: any) => {
      return data;
    });
  }
  public static async putEquipmentOffsetJson(token: string = privateAccessToken, equipData: any): Promise<any> {
    // const ConfigManager.projectId = ConfigManager.ConfigManager.projectId;
    const url = `${ConfigManager.openToweriQUrl}/v1.0/${ConfigManager.projectId}/MountingsData`;

    return fetch(url, {
      method: "put",
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(equipData),
    }).then(async (res: Response) => {
      if (!res.ok) {
        return null;
      }
      return "success";
    }).then((data: any) => {
      return data;
    });
  }

  public static async postEquipmentJson(token: string = privateAccessToken, equipData: EquipmentInformation, apiVersion: string="v1.0"): Promise<any> {
    if (!equipData || !equipData.name) {
      return;
    }
    // const ConfigManager.projectId = ConfigManager.ConfigManager.projectId;
    const url = `${ConfigManager.openToweriQUrl}/${apiVersion}/${ConfigManager.projectId}/Equipment/`;

    return fetch(url, {
      method: "post",
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(equipData),
    }).then(async (res: Response) => {
      if (!res.ok) {
        return null;
      }
      return "successful";
    }).then((data: any) => {
      return data;
    });
  }

  public static async putEquipmentJson(token: string = privateAccessToken, equipData: any, apiVersion: string="v1.0"): Promise<any> {
    if (!equipData || !equipData.name) {
      return;
    }
    // const ConfigManager.projectId = ConfigManager.ConfigManager.projectId;
    const url = `${ConfigManager.openToweriQUrl}/${apiVersion}/${ConfigManager.projectId}/Equipment/`;

    return fetch(url, {
      method: "put",
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(equipData),
    }).then(async (res: Response) => {
      if (!res.ok) {
        return null;
      }
      return "success";
    }).then((data: any) => {
      return data;
    });
  }

  public static async deleteEquipmentJson(token: string = privateAccessToken, deleteData: any) {
    // const ConfigManager.projectId = ConfigManager.ConfigManager.projectId;
    const url = `${ConfigManager.openToweriQUrl}/v1.0/${ConfigManager.projectId}/Equipment/${deleteData.equipmentId}`;

    // const url = `${decodeURIComponent(
    //  window.location.origin
    // )}/api/Equipment/info/` + ConfigManager.projectId;

    return fetch(url, {
      method: "delete",
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
      // body: JSON.stringify(deleteData),
    }).then(async (response: Response) => {
      if (!response.ok) {
        return "err";
      }
      //const data = await response.json();
      return "success";
    }).then((data: any) => {
      if (data === "err") {
        return null;
      }
      return data;
    });
  }
}
