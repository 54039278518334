import { Vector3d, YawPitchRollAngles } from "@itwin/core-geometry";
import { StagePanelLocation, UiFramework, WidgetState } from "@itwin/appui-react";
import { ConfigurableCreateInfo, SyncUiEventDispatcher, WidgetControl, WidgetDef } from "@itwin/appui-react";
import { EquipmentShapeDecorator, ShapeType } from "../decorators/EquipmentShapeDecorator";
import { ModifyHandleDecoration } from "../../components/tools/modification/ModifyHandleDecoration";
import { IModelApp } from "@itwin/core-frontend";
import { EquipmentRotationType } from "../../tools/DetectedEquipmentEditHandler";
import { store } from "../../../store/rootReducer";
import { EquipmentsTable } from "../../components/Tables/EquipmentsTable";
import { ShapeDecorator } from "../decorators/ShapeDecorator";

export class EquipmentRotationWidget extends WidgetControl {

    // tslint:disable-next-line:naming-convention
    private currDef: WidgetDef | undefined;

    constructor(info: ConfigurableCreateInfo, options: any) {
        super(info, options);

        this.currDef = UiFramework.frontstages.activeFrontstageDef?.getStagePanelDef(StagePanelLocation.Right)?.findWidgetDef("EquipmentRotation");

        SyncUiEventDispatcher.onSyncUiEvent.addListener((args) => {

            if (args.eventIds.has("equipmentunselected") && this.currDef?.state !== WidgetState.Hidden) {
                this.setWidgetState(WidgetState.Hidden);
            }
        });
    }

    public static async sliderChange(_newVal: number, rotationVal: number, _shapeType: ShapeType = ShapeType.Box): Promise<void> {

        const decorator = IModelApp.viewManager.decorators.filter((e) => e.constructor.name.includes("ShapeDecorator"))[0] as ShapeDecorator;
        // const shapeName = store.getState().detectedData.selectedObjectInformation.objectProperties.selectedObjectNST.name;
        const shapeName = ShapeDecorator.selectedEquipment.displayName;

        // update shape
        if (shapeName && decorator) {
            const index = decorator!.shapes.findIndex((entry) => entry.modelData!.DisplayName === shapeName);
            const currJson = {...decorator.shapes[index].modelData!};
            if (rotationVal === EquipmentRotationType.Azimuth) {
                currJson.Azimuth = _newVal;
            } else if (rotationVal === EquipmentRotationType.Tilt) {
                currJson.Tilt = _newVal;
                currJson.DownTilt = -1*_newVal;
            } else {
                currJson.Roll = _newVal;
            }
            const newYpr = YawPitchRollAngles.createDegrees(currJson.Azimuth, currJson.DownTilt, currJson.Roll);
            if (_shapeType === ShapeType.Box) {
                // modDecorator._hasEditedBox = true;
                decorator.updateBoxRotation(shapeName, newYpr);
            } else if (_shapeType === ShapeType.Cylinder) {
                // SampleToolWidget.isModifiedData = true;
                decorator.updateCylinderRotation(shapeName, newYpr);
            }
        }
    }

    public static async scale(_scaleVector: any): Promise<void> {
        const shapeName = store.getState().detectedData.selectedObjectInformation.objectProperties.selectedObjectNST.name;
        // const decorator = SampleToolWidget.currEquipDecorator;
        const decorator = IModelApp.viewManager.decorators.filter((e) => e.constructor.name.includes("ShapeDecorator"))[0] as ShapeDecorator;
        decorator.scaleGeometry(shapeName, _scaleVector);
    }

    public static async translate(dx: number, dy: number, dz: number): Promise<void> {
        const decorator = IModelApp.viewManager.decorators.filter((e) => e.constructor.name.includes("ShapeDecorator"))[0] as ShapeDecorator;
        // const shapeName = store.getState().detectedData.selectedObjectInformation.objectProperties.selectedObjectNST.name;
        const shapeName = ShapeDecorator.selectedEquipment.displayName;

        // update Box
        if (shapeName && decorator) {
            if (shapeName.match(/Micro_Wave|SQUID/i)) {
                decorator.updateCylinderPosition(shapeName, new Vector3d(dx, dy, dz));
            } else {
                const modDecorator = ModifyHandleDecoration._decorator!;
                if(modDecorator)modDecorator._hasEditedBox = true;
                decorator.updateBoxPositionByName(shapeName, new Vector3d(dx, dy, dz));
            }
        }
        const updatedJson = decorator?.currJson.find((e) => e.Equipment_Name === shapeName);
        if (!updatedJson) return;

        const equipInfo = EquipmentsTable.equipNameInfoMap?.get(shapeName!);
        const data = {
            name: shapeName,
            lateralOffset: equipInfo.lateralOffset + dx,
            standoffDistance: equipInfo.standoffDistance + dy,
            verticalOffset: equipInfo.verticalOffset + dz,
        };
        equipInfo.lateralOffset = data.lateralOffset;
        equipInfo.standoffDistance = data.standoffDistance;
        equipInfo.verticalOffset = data.verticalOffset;

    }

    public static async cylinderDepthChange(_change: number): Promise<void> {
        const shapeName = EquipmentShapeDecorator.equipmentName;
        const decorator = IModelApp.viewManager.decorators.filter((e) => e.constructor.name.includes("EquipmentShapeDecorator"))[0] as EquipmentShapeDecorator;;

        // update shape
        if (shapeName && decorator) {
            decorator.updateCylinderThickness(shapeName, _change);
        } else return;
    }

    public static async cylinderRadiusChange(_change: number): Promise<void> {
        const shapeName = EquipmentShapeDecorator.equipmentName;
        const decorator = IModelApp.viewManager.decorators.filter((e) => e.constructor.name.includes("EquipmentShapeDecorator"))[0] as EquipmentShapeDecorator;;

        // update shape
        if (shapeName && decorator) {
            decorator.updateCylinderRadius(shapeName, _change);
        } else return;
    }
}
