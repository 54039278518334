import { IModelApp, Tool, StartOrResume, ScreenViewport, NotifyMessageDetails, OutputMessageType, OutputMessagePriority, BeButtonEvent } from "@itwin/core-frontend";
import { SvgArrowUp, SvgArrowLeft, SvgArrowRight, SvgArrowDown, SvgCaretUp, SvgCaretDown, SvgMove, SvgSearch, SvgSync, SvgCheckmark, SvgAdd, SvgCloseCircular, SvgPlaceholder, SvgImodel, SvgModel, SvgNew, SvgReCenter, SvgWindowSplitVertical, SvgWindowSplitHorizontal, SvgSortDown, SvgCollapse, SvgMerge, SvgExpand, SvgExpandAll, SvgUnlink, SvgUpload, SvgDownload, SvgChevronDown, SvgChevronUp, SvgMoreVertical, SvgAndroid, SvgAttach, SvgWindow } from "@itwin/itwinui-icons-react";
import React, { useEffect, useRef, useState } from "react";
import { EquipmentsTable, ManModels, equipmentModel } from "../components/Tables/EquipmentsTable";
import { Badge, Button, Checkbox, IconButton, InputGroup, Label, LabeledInput, LabeledSelect, MenuItem, ProgressLinear, Radio, Select, SelectOption, Slider, Tab, Tabs, Text, ToggleSwitch, Tooltip } from "@itwin/itwinui-react";
import { RootState } from "../../store/States";
import { connect } from "react-redux";
import { Point3d, PolyfaceBuilder, Sphere, StrokeOptions, Vector3d } from "@itwin/core-geometry";
import { EquipmentRotationWidget } from "./widgets/EquipmentRotationWidget";
import { EquipmentInformation, EquipmentType, unitSystemType } from "../../store/redux-types";
import { UiFramework, WidgetState } from "@itwin/appui-react";
import { Id64String } from "@itwin/core-bentley";
import { store } from "../../store/rootReducer";
import { DTVActions } from "../../store/Actions";
import { resetObjectIds } from "../components/HorizontalToolbarItems";
import { ModifyHandleDecoration } from "../components/tools/modification/ModifyHandleDecoration";
import EquipmentClient, { createEquipment, saveEquipment } from "../api/equipment";
import { fetchEquipmentData } from "./GetDetectedObjectsData";
import Place3DObjectApi from "../components/tools/Place3DObjectApi";
import { DetectedGeometryType, PlacementMode, PlacementTool } from "../components/tools/PlacementTool";
import { BracingDecorator, CustomGeometry } from "./decorators/BracingDecorator";
import { setBracingsData, setSelectedObjectStructure, setSiteCoordinate } from "../../store/detectedData/apiDataActionTypes";
import { initialPropertyListObjectState, ObjectCategoryTypes, PlanBracingInterface, towerBracingsData, towerStructureData } from "../../store/detectedData/apiDataTypes";
import { getManEquipType } from "../components/GetEquipmentProperties";
import { getUnit, getValueInRequiredUnit } from "../components/Tables/BaseTable";
import { ReduceDecimalNumberToFourPlaces, ReduceDecimalNumberToTwoPlaces } from "../components/Lists/EquipmentList";
import { AppSettings } from "../../config/AppSettings";
import { ConfigManager } from "../../config/ConfigManager";
import { SvgSortUp } from "@itwin/itwinui-react/cjs/core/utils";
import { IconSpecUtilities } from "@itwin/appui-abstract";
import SvgVerticalCollapse from "../components/icon/svg/SvgVerticalCollapse.svg"
import { BPL, bracingPatternList } from "./BracingsEditHandler";
import { bracTrans } from "../components/tools/HighlightBracingsTool";
import BracingsClient from "../api/bracingsClient";
import { PointSelectionTool } from "../components/tools/PointSelectionTool";
import { ColorDef, LinePixels } from "@itwin/core-common";
import { DecoratorHelper } from "./decorators/DecoratorHelper";
import { operationName } from "@apollo/client";

export interface buttonState {label: string, disabled: boolean};
export interface optionState {label: string, value: number};
export interface position3D {x: number, y: number, z: number}
export interface BracingEditProperties {
  bracingId: number;
  cert: number;
  topElevation: number;
  horizontalMid: boolean;
  horizontalTop: boolean;
  bottomElevation: number;
  type: string;
  planBracingMid: PlanBracingInterface | null;
  planBracingTop: PlanBracingInterface | null
}

export enum LegBasedTower {ThreeLeggedTower= 3, FourLeggedTower = 4}

export const TowerBasedPlanBracingTypes = [
  {towerType: LegBasedTower.ThreeLeggedTower, planBracingTypes: [
    "Triangular 1#TRIANGULAR 1",
    "Triangular 2#TRIANGULAR 2",
    "Triangular 3#TRIANGULAR 3",
    "Triangular Portal#TRIANGULAR Portal",
  ]},
  {towerType: LegBasedTower.FourLeggedTower, planBracingTypes: [
    "X-BRACE#X-BRACE",
    "Diamond 1#DIAMOND 1",
    "Diamond 2#DIAMOND 2",
    "Diamond 3#DIAMOND 3",
    "Diamond Portal#Diamond Portal",
    "Diamond 1X#DIAMOND 1X",
    "Diamond 2X#DIAMOND 2X",
    "Diamond 3T#DIAMOND 3T",
  ]},
]

export const BracingPatterns = [
  "K@K Brace Up#",
  "K@K Brace Down#",
  "K@TK Brace Up#",
  "K@TK Brace Down#",
  "K@K Brace Left#",
  "K@K Brace Right#",
  "K@K1 UP#",
  "K@K1 Down#",
  "K@K1B Down#",
  "K@K2 Down#",
  "K@K2 Up#",
  "K@K2A Down#",
  "K@K3 UP#",
  "K@K3 Down#",
  "K@K3A Down#",
  "K@K3A M Down#",
  "K@K4 UP#",
  "K@K4 Down#",
  "K@K4A Down#",
  "K@Double K#",
  "K@Double TK#",
  "K@Double K1#",
  "K@Double K1B#",
  "K@Double K2#",
  "K@Double K2A#",
  "K@Double K3#",
  "K@Double K3A#",
  "K@Double K4#",
  "K@Double K4A#",
  "K@Cranked K#",
  "K@Cranked K2#",
  "X@X Brace#",
  "X@Taper X#",
  "X@CX Brace#",
  "X@TX Brace#",
  "Diamond@Diagonal UP#",
  "Diamond@Diagonal Down#",
  "Misc@Portal#", 
  "Misc@M#",
]

export enum OperatingMode {
  Add, Edit
}
interface BracingEditHandlerState {
  bracingId: number;
  topElevation: number;
  horizontalMid: boolean;
  horizontalTop: boolean;
  bottomElevation: number;
  addMultiple: {enabled: boolean, count: number};
  mainPatternList: SelectOption<number>[],
  subPatternList: SelectOption<number>[],
  mainPatternIndex: number;
  subPatternIndex: number;
  mainType: string;
  subType: string;
  planBracingMid: {enabled: boolean, type: string | null};
  planBracingTop: {enabled: boolean, type: string | null};
  opMode: OperatingMode
}

const mainPatternList = [{label: "Select", value: 0}];
BracingPatterns.forEach(bp=>{
  const mt = bp.split('@')[0];
  if(mainPatternList.findIndex(mp=>mp.label==mt)==-1)mainPatternList.push({label: mt, value: mainPatternList.length})
});


const bracingDefProps: BracingEditProperties = {
  bracingId: 0,
  cert: 1,
  topElevation: 0,
  horizontalTop: false,
  horizontalMid: false,
  bottomElevation: 0,
  type: "",
  planBracingTop: null,
  planBracingMid: null
}

export enum DimensionMode {
  Custom = "Custom",
  Model = "Model"
}

export const getTypedManModelList = (equipmentType: string)=>{
  const selEquipType = getManEquipType(equipmentType)
  return  EquipmentsTable.formattedEquipmentManModels.manModels.filter(e=>e.equipmentTypes.find(et=>et==selEquipType));
}


export const deleteSelectedBracing = async (bracingIdToDelete)=>{
  const storedBracings = store.getState().detectedData.towerBracingsData;
  if(!bracingIdToDelete.length)return;

  const braceId = bracingIdToDelete.split('_')[2];
  const remainingBracings = bracingIdToDelete.length ? storedBracings.filter(bs=>bs.bracingId != braceId) : storedBracings;
  const bracingDec = IModelApp.viewManager.decorators.filter((e) => e.constructor.name.includes("BracingDecorator"))[0] as BracingDecorator;
  const backupPipes = bracingDec.pipes;//.filter(pipe=>!pipe.name.match(/braceId/));
  bracingDec.pipes = bracingDec.pipes.filter(pipe=>!pipe.name.match(braceId));
  IModelApp.viewManager.selectedView?.invalidateDecorations();
  IModelApp.viewManager.selectedView?.invalidateCachedDecorations(bracingDec);
  await updateDecoratedBracings([], storedBracings, {faceBracing: false, planBracings: false}, braceId);
  IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Info, "Deleting bracing in progress...", "", OutputMessageType.Toast));
  
  if(storedBracings.length > remainingBracings.length){
      await BracingsClient.postBracingData(remainingBracings, store.getState().auth.accessTokenStatePrivateAPI.accessToken!).then(async resp=>{
      if (resp == "Success") {
        bracingDec.pipes = [];
        const newBracingData = await BracingsClient.getBracingsData(store.getState().auth.accessTokenStatePrivateAPI.accessToken!);
        store.dispatch(setBracingsData(newBracingData))
        const data2Send = {"tower-structure": store.getState().detectedData.towerStructureData, bracings: newBracingData};
        await BracingDecorator.refreshDecoratorBracingData(data2Send);
        bracingDec.resetBracings(data2Send);
        resetObjectIds(bracingDec);
        UiFramework.frontstages.activeFrontstageDef?.findWidgetDef("BracingEditWidget")?.setWidgetState(WidgetState.Hidden);
        IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Info, "Bracing deleted successfully.", "", OutputMessageType.Toast));
      } else {
        IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Error, "Error while trying to delete.", "", OutputMessageType.Toast));
        bracingDec.pipes = backupPipes;
        IModelApp.viewManager.selectedView?.invalidateDecorations();
        IModelApp.viewManager.selectedView?.invalidateCachedDecorations(bracingDec);
      }
    }).catch(async _error=>{
      IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Error, "Error while trying to delete.", "", OutputMessageType.Toast));
      bracingDec.pipes = backupPipes;
      IModelApp.viewManager.selectedView?.invalidateDecorations();
      IModelApp.viewManager.selectedView?.invalidateCachedDecorations(bracingDec);
    });
  }
}

export const updateDecoratedBracings = async (updatedBracingsData: towerBracingsData[], existingBracingData, modifiedBracings: {faceBracing: boolean, planBracings: boolean}, braceNameToDelete: string = "")=>{
  const bracingDec = IModelApp.viewManager.decorators.filter((e) => e.constructor.name.includes("BracingDecorator"))[0] as BracingDecorator;
  const towerData = store.getState().detectedData.towerStructureData;
  const prevSelBracing = BracingDecorator.prevSelectedBracingForEdit;
  const data2Send = {"tower-structure": towerData, "bracings": updatedBracingsData}
  if(braceNameToDelete.length && braceNameToDelete != "All"){
    const prevBracingName = prevSelBracing.split('_')[2];
    let thePrevPracing:  null|undefined|CustomGeometry = null;
    if(prevBracingName){
      thePrevPracing = bracingDec.pipes.find(bp=>bp.name.match(prevBracingName));
      const theBaseBracingData = existingBracingData.find(bd=>bd.bracingId.toString()==prevBracingName);
      if(theBaseBracingData)updatedBracingsData.push(theBaseBracingData);
    }
    let newPipes = bracingDec.pipes.filter(bp=>!bp.name.match(braceNameToDelete));
    if(prevBracingName != "" && prevBracingName != undefined)newPipes = newPipes.filter(bp=>!bp.name.match(prevBracingName));
    bracingDec.pipes = newPipes;
  }

  const faceTransientIds: bracTrans[] = [];
  const planTransientIds: bracTrans[] = [];

  bracingDec.geometryGraphics = [];


  if(modifiedBracings.faceBracing && braceNameToDelete != "All"){
    updatedBracingsData.forEach(e=>faceTransientIds.push({bracingId:e.bracingId, transientId: IModelApp.viewManager.selectedView!.iModel.transientIds.getNext()}));
    var bracFaceInfoDat = BracingDecorator.getBracingFaceDataForOTD(updatedBracingsData, towerData);
    let faceModel = BracingDecorator.modelFromBracingFaceData(bracFaceInfoDat);
    let out = await BracingsClient.postBracingDetectorGeneratorData(store.getState().auth.accessTokenStatePrivateAPI.accessToken!, faceModel);
    if(out)bracingDec.createPipesWithOTDData(out.data, data2Send, bracFaceInfoDat, faceTransientIds);
  }

  if(modifiedBracings.planBracings && braceNameToDelete != "All"){
    const {planBracings} = BracingDecorator.getBracingCoordinatesFromPattern(updatedBracingsData, towerData);
    if(planBracings.length){
      const out2 = await BracingsClient.postBracingDetectorGeneratorData(store.getState().auth.accessTokenStatePrivateAPI.accessToken!, planBracings);
      planBracings.forEach(e=>planTransientIds.push({bracingId:e.BracingId, transientId: IModelApp.viewManager.selectedView!.iModel.transientIds.getNext()}));
      if(out2)bracingDec.createPipesWithOTDData(out2.data, data2Send, planBracings, planTransientIds);
    }
  } 
}


const CustomBracingEditHandler: React.FunctionComponent = (cbehProps: any) => {
  const widget = UiFramework.frontstages.activeFrontstageDef?.findWidgetDef("BracingEditWidget");
  let timerRef = useRef<NodeJS.Timer>();
    const disable = (IModelApp.toolAdmin.activeTool as PlacementTool)?.toolId === "PlacementTool";
    let mergeUpStyle = "default";
    let mergeDownStyle = "default";
    const spl: {label: string, value: number}[] = [{label: "Select", value: 0}];
    const mpl = bracingPatternList.map((bpl: BPL, bplId: number)=>({label: bpl.label, value: bplId+1}))
    mpl.unshift({label: "Select", value: 0});
    let thePattern: BPL;
    bracingPatternList.forEach((bpl: BPL)=>{
      const found = bpl.subType.filter(st=>st == cbehProps?.bracingPropertyJson?.type)!;
      if(found.length){
        thePattern = bpl;
        bpl.subType.forEach((st: string, stId: number)=>spl.push({label: st, value: stId+1}))
      }
    });
    const bracingData: towerBracingsData[] = cbehProps?.bracingData ?? [];
    const allBracingElevation = bracingData.filter(bd=>bd.end);
    let mergeUp: {forBracing: number, theBracings:  null | towerBracingsData} = {forBracing: cbehProps?.bracingPropertyJson?.bracingId, theBracings: null};
    let mergeDown: {forBracing: number, theBracings: null | towerBracingsData} = {forBracing: cbehProps?.bracingPropertyJson?.bracingId, theBracings: null};
    const unitImperial = AppSettings.defaultDisplaySettings.unitSystem == unitSystemType.IMPERIAL;
    const feetToMeterConvFactor = 3.2808399;
    const maxHeight = unitImperial ? feetToMeterConvFactor * (cbehProps.towerData.top_altitude - cbehProps.towerData.base_altitude) : cbehProps.towerData.top_altitude - cbehProps.towerData.base_altitude;
    bracingData.map(bd=>{
      const onTop = bracingData.filter(e=>(Math.abs(e.start-bd.end) < .2 && e.bracingId != bd.bracingId));
      const onBottom = bracingData.filter(e=>(Math.abs(e.end-bd.start) < .2 && e.bracingId != bd.bracingId));
      if(onTop.length && bd.bracingId == cbehProps?.bracingPropertyJson?.bracingId)mergeUp.theBracings = onTop[0];
      if(onBottom.length && bd.bracingId == cbehProps?.bracingPropertyJson?.bracingId)mergeDown.theBracings = onBottom[0];
      // return {bracingId: bd.bracingId, onTop: onTop.length ? onTop : null, atBottom: onBottom.length ? onBottom : null};
    });


    const planBracingPatternList = TowerBasedPlanBracingTypes.find(tbpbt=>tbpbt.towerType==cbehProps.towerNLegs)?.planBracingTypes.map((pbt: string, pbtId: number)=>({label: pbt.split('#')[0], value: pbtId+1, patternCode: pbt.split('#')[1]}))!;
    planBracingPatternList?.unshift({label: "Select", value: 0, patternCode: ""});

    const bracingDefState: BracingEditHandlerState = {
      bracingId: 0,
      topElevation: 0,
      horizontalMid: false,
      horizontalTop: false,
      bottomElevation: 0,
      addMultiple: { enabled: false, count: 1 },
      mainPatternIndex: 0,
      subPatternIndex: 0,
      mainType: "",
      subType: "",
      planBracingMid: { enabled: false, type: null },
      planBracingTop: { enabled: false, type: null },
      mainPatternList: mainPatternList,
      subPatternList: [{label: "Select", value: 0}],
      opMode: cbehProps.editMode ? OperatingMode.Edit : OperatingMode.Add
    }

    const [bracingState, setBracingState] = React.useState<BracingEditHandlerState>({...bracingDefState, opMode: cbehProps.editMode ? OperatingMode.Edit : OperatingMode.Add});
    const [mergeButtonState, setMergeButtonState] = useState<{mergeUp: {active: boolean, id: number}, mergeDown: {active: boolean, id: number}, splitUp: {active: boolean, id: number|string}}>({mergeUp: {active: false, id: 0}, mergeDown: {active: false, id: 0}, splitUp: {active: false, id: 0}});
    const [bracingProps, setbracingProps] = useState<BracingEditProperties>(cbehProps?.bracingPropertyJson);
    // const [selMainPattern, setSelMainPattern] = useState<number>(mpl.findIndex(mp=>mp.label == thePattern.label));
    const [selectedValue, setSelectedValue] = useState<string>("0");
    const [equipTiltLabel, setEquipTiltLabel] = useState<number>(0);
    const [equipTiltSlider, setEquipTiltSlider] = useState<number>(0);
    const [searchString, setSearchString] = useState<string>("");
    const [siteCoordZ, setSiteCoordZ] = useState<number>(cbehProps.siteCoordZ);
    // const [selSubPattern, setSelSubPattern] = useState<number>(spl.findIndex(sp=>sp.label==cbehProps?.bracingPropertyJson?.type));
    const [selModel, setSelModel] = useState<number>(0);
    const [planPatternLoad, setPlanPatternLoad] = useState<boolean>(false);
    const [facePatternLoad, setFacePatternLoad] = useState<boolean>(false);
    const [library, setLibrary] = useState<number>(0);
    // const [mainPatternList, setModelList] = useState<any[]>(mpl);
    // const [subPatternList, setSubPatternList] = useState<any[]>(spl);
    const [planPatternList, setPlanPatternList] = useState<any[]>(planBracingPatternList);
    // const [midPlanBracing, setMidPlanBracing] = useState<number>(planBracingPatternList?.findIndex(pbpl=>pbpl.label == cbehProps?.bracingPropertyJson.planBracingMid?.type) ?? 0);
    // const [topPlanBracing, setTopPlanBracing] = useState<number>(planBracingPatternList?.findIndex(pbpl=>pbpl.label == cbehProps?.bracingPropertyJson.planBracingTop?.type) ?? 0);
    const [isDisabled, setIsDisabled] = useState(!disable);
    const [triggerCreate, setTriggerCreate] = useState(false);
    // const [resetDisabled, setResetDisabled] = useState(cbehProps.addingEquipment);
    const [theBracings, setTheBracings] = useState<towerBracingsData[]|[]>([]);
    const [widgetClosed, setWidgetClosed] = useState<boolean>(false);
    const [dimensionMode, setDimensionMode] = useState<DimensionMode>(DimensionMode.Model);
    const [saveButton, setSaveButton] = useState<buttonState>({label: cbehProps.editMode ? "Save" : "Add", disabled: !cbehProps.addingEquipment});
    const [resetButton, setResetButton] = useState<buttonState>({label: cbehProps.addingEquipment? "Cancel" : "Reset", disabled: false});
    const move = 0.01;
    const ref = React.useRef(null);

    useEffect(()=>{
      if(cbehProps?.bracingPropertyJson?.bracingId != 0 && cbehProps.editMode && cbehProps.open){
        const { bracingId, topElevation, horizontalMid, horizontalTop, bottomElevation, type: subType, cert, planBracingMid, planBracingTop } = cbehProps?.bracingPropertyJson;
        const theBracing: towerBracingsData = {
          bracingId,
          cert,
          end: topElevation + parseFloat(cbehProps.siteCoordZ),
          horizontalMid,
          horizontalTop,
          planBracingMid,
          planBracingTop,
          start: bottomElevation + parseFloat(cbehProps.siteCoordZ),
          type: subType
        }
        bracingUpdate([theBracing], {faceBracing: true, planBracings: true}, bracingId.toString()).then(resp=>{
          if(resp){
            let searchPattern = `@${subType}#`;
            const patternIndex = BracingPatterns.findIndex(bp=>bp.match(searchPattern));
            const spt = BracingPatterns[patternIndex];
            searchPattern = `${spt.split('@')[0]}@`;
            const mainType = spt.split('@')[0];
            const mainPatternList = [{label: "Select", value: 0}];
            const subPatternList = [{label: "Select", value: 0}];
            BracingPatterns.forEach(bp=>{
              const mt = bp.split('@')[0];
              if(mainPatternList.findIndex(mp=>mp.label==mt)==-1)mainPatternList.push({label: mt, value: mainPatternList.length})
              const res = bp.match(searchPattern);
              if(res){
                const temp = bp.split('@');
                subPatternList.push({label: temp[1].split('#')[0], value: subPatternList.length})
              }
            });
            const braState: BracingEditHandlerState = {
              ...bracingDefState,
              bracingId,
              topElevation: unitImperial ? topElevation * feetToMeterConvFactor : topElevation,
              horizontalTop,
              horizontalMid,
              bottomElevation: unitImperial ? bottomElevation * feetToMeterConvFactor : bottomElevation,
              mainPatternIndex: mainPatternList.findIndex(mpl=>mpl.label==spt.split('@')[0]),
              subPatternIndex: subPatternList.findIndex(spl=>spl.label==spt.split('@')[1].split('#')[0]),
              mainPatternList,
              subPatternList,
              mainType,
              subType,
              planBracingTop: {enabled: planBracingTop?.type != null, type: planBracingTop?.type ?? "Select"},
              planBracingMid: {enabled: planBracingMid?.type != null, type: planBracingMid?.type ?? "Select"}
            }
            setBracingState(braState);

          }
        });
        setSaveButton(saveButton=>({...saveButton, disabled: true}));
      } else if(!cbehProps.editMode && cbehProps.open && !widgetClosed){
        onReset();
        const bracingDec = IModelApp.viewManager.decorators.filter((e) => e.constructor.name.includes("BracingDecorator"))[0] as BracingDecorator;
        if(bracingDec)bracingDec.resetBracings();
        const widget = UiFramework.frontstages.activeFrontstageDef?.findWidgetDef("BracingEditWidget");
        if(widget?.state == WidgetState.Hidden)widget?.setWidgetState(WidgetState.Open);
        setBracingState({...bracingDefState, opMode: OperatingMode.Add});
        setSaveButton(saveButton=>({...saveButton, disabled: true}));
      } else if(!cbehProps.editMode){
        onReset();
        setBracingState({...bracingDefState, opMode: OperatingMode.Add});
        setSaveButton(saveButton=>({...saveButton, disabled: true}));
      }
    }, [cbehProps?.bracingPropertyJson?.bracingId, cbehProps?.editMode, widget?.state]);

    useEffect(() => {
      if(cbehProps.open){
        let { bracingId, topElevation, horizontalMid, horizontalTop, bottomElevation, mainType, subType, mainPatternIndex, subPatternList, mainPatternList, subPatternIndex, planBracingMid, planBracingTop } = bracingState;
  
        if(topElevation != 0 && bottomElevation != 0 && mainPatternIndex != 0 && subPatternIndex != 0 && triggerCreate){
          createBracing();
        }
      }
      
    }, [bracingState, triggerCreate])
    

    const getNextBracingId = (currentBracingId: number) => {
      let newBracingId = currentBracingId;
      for (let i = 0; i < cbehProps.bracingData.length; i++) {
        const found = cbehProps.bracingData.find(bd=>bd.bracingId == newBracingId);
        if(found)newBracingId++;
        else break;
      }
      return newBracingId;
    }
  
    const onMouseDown = async (target) => {
      const bracingDec = IModelApp.viewManager.decorators.filter((e) => e.constructor.name.includes("BracingDecorator"))[0] as BracingDecorator;
      let { bracingId, topElevation, horizontalMid, horizontalTop, bottomElevation, mainType, subType, mainPatternIndex, subPatternList, mainPatternList, subPatternIndex, planBracingMid, planBracingTop } = bracingState;
      const theBracing: towerBracingsData = JSON.parse(JSON.stringify(cbehProps.bracingData.find(bd=>bd.bracingId == bracingId)));
      const allBracings: towerBracingsData[] = []
      let theBraceNames = `${bracingId}`;
      switch (target) {
        case "Merge up":
          if(mergeUp.theBracings != null){
            theBraceNames=theBraceNames.concat('|', mergeUp.theBracings.bracingId.toString());
            theBracing.end = mergeUp.theBracings.end; 
            setMergeButtonState(mergeButtonState=>({...mergeButtonState, mergeUp: {active: true, id: mergeUp.theBracings!.bracingId}}));
          }
          allBracings.push(theBracing);
          if(mergeButtonState.mergeDown.active){
            const downBracing: towerBracingsData = JSON.parse(JSON.stringify(cbehProps.bracingData.find(bd=>bd.bracingId == mergeButtonState.mergeDown.id)));
            allBracings.push(downBracing);
          }
          break;
        case "Merge down":
          if(mergeDown.theBracings != null){
            theBraceNames=theBraceNames.concat('|', mergeDown.theBracings.bracingId.toString());
            theBracing.start = mergeDown.theBracings.start; 
            setMergeButtonState(mergeButtonState=>({...mergeButtonState, mergeDown: {active: true, id: mergeDown.theBracings!.bracingId}}));
          }          
          allBracings.push(theBracing);
          if(mergeButtonState.mergeUp.active){
            const upBracing: towerBracingsData = JSON.parse(JSON.stringify(cbehProps.bracingData.find(bd=>bd.bracingId == mergeButtonState.mergeUp.id)));
            allBracings.push(upBracing);
          }
          if(mergeButtonState.splitUp.active){
            theBraceNames=theBraceNames.concat('|', mergeButtonState.splitUp.id.toString());
            const upBracing: towerBracingsData[] = JSON.parse(JSON.stringify(cbehProps.bracingData.filter(bd=>bd.bracingId.toString().match(mergeButtonState.splitUp.id))));
            // allBracings.push(...upBracing);
          }
          break;
        case "Split up":
          const bracingSplitUp=JSON.parse(JSON.stringify(theBracing));
          const midElevation = (theBracing.start+theBracing.end)/2;
          theBracing.end = midElevation;
          bracingSplitUp.start = midElevation;
          const newBracingId = getNextBracingId(theBracing.bracingId);
          bracingDec.creatingBracingIds = `${theBracing.bracingId}|${newBracingId}`;
          bracingSplitUp.bracingId = newBracingId;
          allBracings.push(theBracing, bracingSplitUp);
          setMergeButtonState(mergeButtonState=>({...mergeButtonState, splitUp: {active: true, id: bracingDec.creatingBracingIds}}));
          if(mergeButtonState.mergeUp.active){
            const upBracing: towerBracingsData = JSON.parse(JSON.stringify(cbehProps.bracingData.find(bd=>bd.bracingId == mergeButtonState.mergeUp.id)));
            allBracings.push(upBracing);
          }
          if(mergeButtonState.mergeDown.active){
            const downBracing: towerBracingsData = JSON.parse(JSON.stringify(cbehProps.bracingData.find(bd=>bd.bracingId == mergeButtonState.mergeDown.id)));
            allBracings.push(downBracing);
          }
          break;
      
        default:
          break;
      }
      setTheBracings(allBracings);
      await bracingUpdate(allBracings, {faceBracing: true, planBracings: true }, theBraceNames, true);
      setSaveButton(saveButton=>({...saveButton, disabled: false, label: cbehProps.editMode ? "Save" : "Add"}));

    }

  
    const onFieldUpdate = async (target, field) => {
      if(target != null && typeof target == "object")target.persist();
      const bracingDec = IModelApp.viewManager.decorators.filter((e) => e.constructor.name.includes("BracingDecorator"))[0] as BracingDecorator;
      let { bracingId, topElevation, horizontalMid, horizontalTop, bottomElevation, mainType, subType, mainPatternIndex, subPatternList, mainPatternList, subPatternIndex, planBracingMid, planBracingTop } = bracingState;
      let braceName = "";
      let planBracingModified: boolean = false;
      let faceBracingModified: boolean = false;
      let theTopElevation: number = unitImperial ? parseFloat(cbehProps.siteCoordZ) + topElevation/feetToMeterConvFactor : parseFloat(cbehProps.siteCoordZ) + topElevation ;
      let theBotElevation: number = unitImperial ? parseFloat(cbehProps.siteCoordZ) + bottomElevation/feetToMeterConvFactor : parseFloat(cbehProps.siteCoordZ) + bottomElevation ;
      switch (field) {
        case "OperatingMode": {
          setBracingState(()=>({...bracingDefState, opMode: target}));
          if(target == OperatingMode.Add){
            store.dispatch(DTVActions.setEditModeFlag(false));
            store.dispatch(setSelectedObjectStructure(initialPropertyListObjectState));
            bracingDec.resetBracings();
          } else {
            setSaveButton(saveButton=>({...saveButton, disabled: true}));
            store.dispatch(DTVActions.setEditModeFlag(true));
            const name = cbehProps.selectedObjectInfo.objectProperties.selectedObjectNST.name;
            const braceId = name.split('_')[2]
            if(cbehProps.selectedObjectInfo.heading == "Bracing Information" && name.length){
              BracingDecorator.selectedBracingForEdit = name;
              // const newPipes = bracingDec.pipes.filter(pipe=>!pipe.name.match(braceId));
              // bracingDec.pipes=newPipes;
              
              // const faceBraceData = BracingDecorator.faceOTDData.bracingData.filter(fData=>fData.parent==braceId);
              // const faceMems = BracingDecorator.faceOTDData.otdData.Members.filter(mem=>mem.BracingId==braceId);
              // const faceNodes = BracingDecorator.faceOTDData.otdData.Nodes;
              // const planBraceData = BracingDecorator.planOTDData.bracingData.filter(pData=>pData.parent==braceId);
              // const planMems = BracingDecorator.planOTDData.otdData.Members.filter(mem=>mem.BracingId==braceId);
              // const planNodes = BracingDecorator.planOTDData.otdData.Nodes;

              // const faceTransientIds: bracTrans[] = [];
              // const planTransientIds: bracTrans[] = [];
              // const towerData = store.getState().detectedData.towerStructureData;
              // const data2Send = {"tower-structure": towerData, "bracings": cbehProps.bracingData.filter(bd=>bd.bracingId==braceId)};
                
              // faceTransientIds.push({bracingId: braceId, transientId: IModelApp.viewManager.selectedView!.iModel.transientIds.getNext()});
              // bracingDec.createPipesWithOTDData({Members: faceMems, Nodes: faceNodes}, data2Send, faceBraceData, faceTransientIds);
              
              // if(planBraceData.length){
              //     planBraceData.forEach(e=>planTransientIds.push({bracingId:e.BracingId, transientId: IModelApp.viewManager.selectedView!.iModel.transientIds.getNext()}));
              //     bracingDec.createPipesWithOTDData({Members: planMems, Nodes: planNodes}, data2Send, planBraceData, planTransientIds);
              // }
        
              // resetObjectIds(bracingDec);
              // bracingDec.geometryGraphics = [];
              // IModelApp.viewManager.selectedView?.invalidateDecorations();
              // IModelApp.viewManager.selectedView?.invalidateCachedDecorations(bracingDec);
            }
          }
        }
        break;
        case "HorizontalTopSwitch": {
          setFacePatternLoad(true);
          horizontalTop = target.target.checked;
          setBracingState(ps=>({...ps, horizontalTop: horizontalTop}));
          // if(!horizontalTop){
            braceName = `pipe_bracing_${bracingId}`;
            const newPipes = bracingDec.pipes.filter(bp=>!bp.name.match(braceName));
            bracingDec.pipes = newPipes;
          // }
          faceBracingModified=true;
        }
        break;
        case "HorizontalMidSwitch": {
          setFacePatternLoad(true);
          horizontalMid = target.target.checked;
          setBracingState(ps=>({...ps, horizontalMid: horizontalMid}));
          // if(!horizontalMid){
            braceName = `pipe_bracing_${bracingId}`;
            const newPipes = bracingDec.pipes.filter(bp=>!bp.name.match(braceName));
            bracingDec.pipes = newPipes;
          // }
        }
        faceBracingModified=true;
        break;
        case "PlanTopSwitch": {
          setPlanPatternLoad(true);
          planBracingTop.enabled = target.target.checked;
          setBracingState(ps=>({...ps, planBracingTop: {...ps.planBracingTop, enabled: planBracingTop.enabled, type: planBracingPatternList[1].patternCode}}));
          if(!planBracingTop.enabled)braceName = `plan_bracing_${bracingId}_place_planTop`;
          planBracingModified=true;
          // const pbt = planBracingPatternList[1].label.split(' ');
          // planBracingTop.type = `${pbt[0].toUpperCase()} ${pbt[1]}`;
          const pbt = planBracingPatternList[1].patternCode;
          planBracingTop.type = pbt;
        }
        break;
        case "PlanMidSwitch": {
          setPlanPatternLoad(true);
          planBracingMid.enabled = target.target.checked;
          setBracingState(ps=>({...ps, planBracingMid: {...ps.planBracingMid, enabled: planBracingMid.enabled, type: planBracingPatternList[1].patternCode}}));
          if(!planBracingMid.enabled)braceName = `plan_bracing_${bracingId}_place_planMid`;
          planBracingModified=true;
          // const pbm = planBracingPatternList[1].label.split(' ');
          // planBracingMid.type = `${pbm[0].toUpperCase()} ${pbm[1]}`;
          const pbm = planBracingPatternList[1].patternCode;
          planBracingMid.type = pbm;
        }
        break;
        case "MainPatternSelect": {
          mainType = bracingState.mainPatternList[target].label;
          mainPatternIndex = target;

          subPatternList = [{label: "Select", value: 0}];
          let searchPattern = `${mainType}@`
          BracingPatterns.forEach(bp=>{
            const res = bp.match(searchPattern);
            if(res)subPatternList.push({label: bp.split('@')[1].split('#')[0], value: subPatternList.length})
          });
        
          subType = subPatternList[1].label;
          setBracingState(ps=>({...ps, mainType, subType: subPatternList[1].label, mainPatternIndex, subPatternList, subPatternIndex: 1}));
          braceName = `pipe_bracing_${bracingId}_face_Face`;
          faceBracingModified=true;
        }
        break;
        case "SubPatternSelect": {
          subType = bracingState.subPatternList[target].label;
          subPatternIndex = target;
          setBracingState(ps=>({...ps, subType, subPatternIndex}));
          braceName = `pipe_bracing_${bracingId}_face_Face`;
          faceBracingModified=true;
        }
        break;
        case "PlanTopSelect": {
          // const pbt = planBracingPatternList[target].label.split(' ');
          // planBracingTop.type = `${pbt[0].toUpperCase()} ${pbt[1]}`;
          const pbt = planBracingPatternList[target].patternCode;
          planBracingTop.type = pbt;
          setBracingState(ps=>({...ps, planBracingTop: {...ps.planBracingTop, type: planBracingTop.type}}));
          if(planBracingTop.enabled)braceName = `plan_bracing_${bracingId}_place_planTop`;
            planBracingModified=true;
        }
        break;
        case "PlanMidSelect": {
          // const pbm = planBracingPatternList[target].label.split(' ');
          // planBracingMid.type = `${pbm[0].toUpperCase()} ${pbm[1]}`;
          const pbm = planBracingPatternList[target].patternCode;
          planBracingMid.type = pbm;
          setBracingState(ps=>({...ps, planBracingMid: {...ps.planBracingMid, type: planBracingMid.type}}));
          if(planBracingMid.enabled)braceName = `plan_bracing_${bracingId}_place_planMid`;
          planBracingModified=true;
        }
        break;
        case "topElevation": {
          let value = unitImperial ? target.target.value / feetToMeterConvFactor : target.target.value;
          if(value > maxHeight)value = maxHeight;
          else if (value < 0)value = 0;
          topElevation = parseFloat(value);
          updateElevation("TopElevation", new Point3d(0, 0, parseFloat(value)), true);
          planBracingModified=true;
          faceBracingModified=true;
          theTopElevation=parseFloat(cbehProps.siteCoordZ) + topElevation;
          braceName = bracingId.toString();
          // const newPipes = bracingDec.pipes.filter(bp=>!bp.name.match(braceName));
          // bracingDec.pipes = newPipes;
          setBracingState(ps=>({...ps, topElevation}));
        }
        break;
        case "bottomElevation": {
          let value = unitImperial ? target.target.value / feetToMeterConvFactor : target.target.value;
          if(value > maxHeight)value = maxHeight;
          else if (value < 0)value = 0;
          bottomElevation = parseFloat(value);
          updateElevation("BottomElevation", new Point3d(0, 0, parseFloat(value)), true);
          planBracingModified=true;
          faceBracingModified=true;
          theBotElevation=parseFloat(cbehProps.siteCoordZ) + bottomElevation;
          braceName = bracingId.toString();
          // const newPipes = bracingDec.pipes.filter(bp=>!bp.name.match(braceName));
          // bracingDec.pipes = newPipes;
          setBracingState(ps=>({...ps, bottomElevation}));
        }
        break;
      
        default:
          break;
      }

      if(bracingId != 0){
        const existingBracing: towerBracingsData|undefined = cbehProps.bracingData.find(bd=>bd.bracingId == bracingId);
        const theBracing: towerBracingsData = {
          bracingId: 0,
          cert: 0,
          end: 0,
          horizontalMid: false,
          horizontalTop: false,
          planBracingMid: null,
          planBracingTop: null,
          start: 0,
          type: ""
        };

        if(existingBracing != undefined){
          let existPlanTopType = existingBracing.planBracingTop;
          let existPlanMidType = existingBracing.planBracingMid;
        
          // if(existingBracing.planBracingTop != null){
          //   const existPlanTopTypeArr = existingBracing.planBracingTop?.type.split(' ');
          //   existPlanTopType = {type: `${existPlanTopTypeArr![0].toUpperCase()} ${existPlanTopTypeArr![1]}`, cert: 1}
          // }
          // if(existingBracing.planBracingMid != null){
          //   const existPlanMidTypeArr = existingBracing.planBracingMid?.type.split(' ');
          //   existPlanMidType = {type: `${existPlanMidTypeArr![0].toUpperCase()} ${existPlanMidTypeArr![1]}`, cert: 1}
          // }
          theBracing.bracingId = existingBracing.bracingId;
          theBracing.cert = existingBracing.cert;
          theBracing.end = existingBracing.end;
          theBracing.horizontalMid = existingBracing.horizontalMid;
          theBracing.horizontalTop = existingBracing.horizontalTop;
          theBracing.planBracingMid = existPlanMidType;
          theBracing.planBracingTop = existPlanTopType;
          theBracing.start = existingBracing.start;
          theBracing.type = existingBracing.type;
        } else {
          theBracing.bracingId = bracingId;
          theBracing.cert = 1;
          theBracing.end = parseFloat(cbehProps.siteCoordZ) + topElevation;
          theBracing.start = parseFloat(cbehProps.siteCoordZ) + bottomElevation;
          theBracing.type = subType;
        }
        theBracing.horizontalTop = horizontalTop; 
        theBracing.horizontalMid = horizontalMid; 
        theBracing.type = subType; 
        theBracing.planBracingTop = planBracingTop.enabled ? {type: planBracingTop.type!, cert: 1} : null;
        theBracing.planBracingMid = planBracingMid.enabled ? {type: planBracingMid.type!, cert: 1} : null;
        theBracing.end = theTopElevation;
        theBracing.start = theBotElevation;

        // theBracing.end = parseFloat(cbehProps.siteCoordZ) + topElevation;
        // theBracing.start = parseFloat(cbehProps.siteCoordZ) + bottomElevation;
    
        // if(topElevationModified)theBracing.end = parseFloat(cbehProps.siteCoordZ) + topElevation;
        // if(botElevationModified)theBracing.start = parseFloat(cbehProps.siteCoordZ) + bottomElevation;
    
        // if(theBracing.planBracingTop != null){
        //   const existPlanTopTypeArr = theBracing.planBracingTop?.type.split(' ');
        //   theBracing.planBracingTop = {type: existPlanTopTypeArr![0].toUpperCase()+' '+existPlanTopTypeArr![1], cert: 0}
        // }
        // if(theBracing.planBracingMid != null){
        //   const existPlanMidTypeArr = theBracing.planBracingMid?.type.split(' ');
        //   theBracing.planBracingMid = {type: existPlanMidTypeArr![0].toUpperCase()+' '+existPlanMidTypeArr![1], cert: 0}
        // }
      
        setTheBracings([theBracing]);
        if(field != "OperatingMode"){
          setSaveButton(saveButton=>({...saveButton, disabled: false, label: cbehProps.editMode ? "Save" : "Add"}));
          if(!cbehProps.editMode && topElevation != 0 && bottomElevation != 0)setTriggerCreate(true);
          else await bracingUpdate([theBracing], {faceBracing: faceBracingModified, planBracings: planBracingModified }, braceName);
        }
      }
      // bracingDec.geometryGraphics = [];
      
      // const planTransientIds: bracTrans[] = [];
      // const towerData = store.getState().detectedData.towerStructureData;
      // const {planBracings} = BracingDecorator.getBracingCoordinatesFromPattern([theBracing], towerData);
      // planBracings.forEach(e=>planTransientIds.push({bracingId:e.BracingId, transientId: IModelApp.viewManager.selectedView!.iModel.transientIds.getNext()}));
      // const out2 = await BracingsClient.postBracingDetectorGeneratorData(store.getState().auth.accessTokenStatePrivateAPI.accessToken!, planBracings);
      // if(out2) {
      //   bracingDec.createPipesWithOTDData(out2.data, {
      //   "tower-structure": towerData,
      //   "bracings": [theBracing]}, planBracings, planTransientIds);
      // }
      // IModelApp.viewManager.selectedView?.invalidateDecorations();
      // IModelApp.viewManager.selectedView?.invalidateCachedDecorations(bracingDec);
    }

    const bracingUpdate = async (updatedBracingsData: towerBracingsData[], modifiedBracings: {faceBracing: boolean, planBracings: boolean}, braceNameToDelete: string = "", fromMergeSplit: boolean = false) => {
      const bracingDec = IModelApp.viewManager.decorators.filter((e) => e.constructor.name.includes("BracingDecorator"))[0] as BracingDecorator;

      const towerData = store.getState().detectedData.towerStructureData;
      const prevSelBracing = BracingDecorator.prevSelectedBracingForEdit;
      const data2Send = {"tower-structure": towerData, "bracings": updatedBracingsData}
      if(braceNameToDelete.length && braceNameToDelete != "All"){
        const prevBracingName = prevSelBracing.split('_')[2];
        let thePrevPracing:  null|undefined|CustomGeometry = null;
        if(prevBracingName){
          thePrevPracing = bracingDec.pipes.find(bp=>bp.name.match(prevBracingName));
          const theBaseBracingData = bracingData.find(bd=>bd.bracingId.toString()==prevBracingName);
          if(theBaseBracingData && !fromMergeSplit)updatedBracingsData.push(theBaseBracingData);
        }
        let newPipes = bracingDec.pipes.filter(bp=>!bp.name.match(braceNameToDelete));
        if(prevBracingName != "" && prevBracingName != undefined)newPipes = newPipes.filter(bp=>!bp.name.match(prevBracingName));
        bracingDec.pipes = newPipes;
      }
    
      const faceTransientIds: bracTrans[] = [];
      const planTransientIds: bracTrans[] = [];

        if((modifiedBracings.faceBracing || !cbehProps.editMode) && braceNameToDelete != "All"){
          updatedBracingsData.forEach(e=>faceTransientIds.push({bracingId:e.bracingId, transientId: IModelApp.viewManager.selectedView!.iModel.transientIds.getNext()}));
          var bracFaceInfoDat = BracingDecorator.getBracingFaceDataForOTD(updatedBracingsData, towerData);
          let faceModel = BracingDecorator.modelFromBracingFaceData(bracFaceInfoDat);
  
  
          await BracingsClient.postBracingDetectorGeneratorData(store.getState().auth.accessTokenStatePrivateAPI.accessToken!, faceModel).then(resp=>{
            if(resp)bracingDec.createPipesWithOTDData(resp.data, data2Send, bracFaceInfoDat, faceTransientIds);
            bracingDec.geometryGraphics = [];
            resetObjectIds(bracingDec);
            IModelApp.viewManager.selectedView?.invalidateDecorations();
            IModelApp.viewManager.selectedView?.invalidateCachedDecorations(bracingDec);
          });
        }
      
        if((modifiedBracings.planBracings || !cbehProps.editMode) && braceNameToDelete != "All"){
          const {planBracings} = BracingDecorator.getBracingCoordinatesFromPattern(updatedBracingsData, towerData);
          if(planBracings.length){
            const out2 = await BracingsClient.postBracingDetectorGeneratorData(store.getState().auth.accessTokenStatePrivateAPI.accessToken!, planBracings).then(resp=>{
              planBracings.forEach(e=>planTransientIds.push({bracingId:e.BracingId, transientId: IModelApp.viewManager.selectedView!.iModel.transientIds.getNext()}));
              if(resp)bracingDec.createPipesWithOTDData(resp.data, data2Send, planBracings, planTransientIds);
              bracingDec.geometryGraphics = [];
              resetObjectIds(bracingDec);
              IModelApp.viewManager.selectedView?.invalidateDecorations();
              IModelApp.viewManager.selectedView?.invalidateCachedDecorations(bracingDec);
              
            });
          } else {
            bracingDec.geometryGraphics = [];
            resetObjectIds(bracingDec);
            IModelApp.viewManager.selectedView?.invalidateDecorations();
            IModelApp.viewManager.selectedView?.invalidateCachedDecorations(bracingDec);
          }
        } 
      
  
    
      // if((modifiedBracings.faceBracing || !cbehProps.editMode) && braceNameToDelete != "All"){
      //   var bracFaceInfoDat = BracingDecorator.getBracingFaceDataForOTD(updatedBracingsData, towerData);
      //   let faceModel = BracingDecorator.modelFromBracingFaceData(bracFaceInfoDat);
    

      //   const {planBracings} = BracingDecorator.getBracingCoordinatesFromPattern(updatedBracingsData, towerData);

      //   await Promise.all([
      //     BracingsClient.postBracingDetectorGeneratorData(store.getState().auth.accessTokenStatePrivateAPI.accessToken!, faceModel),
      //     BracingsClient.postBracingDetectorGeneratorData(store.getState().auth.accessTokenStatePrivateAPI.accessToken!, planBracings)
      //   ]).then(([faceBracingData, planBracingData])=>{
                    
      //     updatedBracingsData.forEach(e=>faceTransientIds.push({bracingId:e.bracingId, transientId: IModelApp.viewManager.selectedView!.iModel.transientIds.getNext()}));
      //     const allMembers = [...faceBracingData.data.Members];
      //     const allNodes = [...faceBracingData.data.Nodes];
      //     const allBracingInfo = [...face]
      //     if(planBracingData){
      //       planBracings.forEach(e=>planTransientIds.push({bracingId:e.BracingId, transientId: IModelApp.viewManager.selectedView!.iModel.transientIds.getNext()}));
      //       allMembers.concat(...planBracingData.data.Members);
      //       allNodes.concat(...planBracingData.data.Nodes);
      //     }
      //     const allData = {Members: allMembers, Nodes: allNodes};
      //     if(allData.Members.length)bracingDec.createPipesWithOTDData(allData, data2Send, planBracings, [...faceTransientIds, ...planTransientIds]);
      //     bracingDec.geometryGraphics = [];
      //     resetObjectIds(bracingDec);
      //     IModelApp.viewManager.selectedView?.invalidateDecorations();
      //     IModelApp.viewManager.selectedView?.invalidateCachedDecorations(bracingDec);
      //   });
  
      // }

      // resetObjectIds(bracingDec);
      // IModelApp.viewManager.selectedView?.invalidateDecorations();
      // IModelApp.viewManager.selectedView?.invalidateCachedDecorations(bracingDec);
      BracingDecorator.prevSelectedBracingForEdit = "";
      setTimeout(() => {
        setFacePatternLoad(false)
        setPlanPatternLoad(false)
      }, 500);
      return true;
    }

    const createBracing = async (create: boolean = true) => {
      setTriggerCreate(false);
      const bracingDec = IModelApp.viewManager.decorators.filter((e) => e.constructor.name.includes("BracingDecorator"))[0] as BracingDecorator;
      const towerData = store.getState().detectedData.towerStructureData;
      let newBracingId: number;
      let { bracingId, topElevation, horizontalMid, horizontalTop, bottomElevation, subType, planBracingMid, planBracingTop, addMultiple } = bracingState;
      if(unitImperial){
        topElevation/=feetToMeterConvFactor;
        bottomElevation/=feetToMeterConvFactor;
      }
      if(create){
        newBracingId = cbehProps.bracingData.length ? getNextBracingId(cbehProps.bracingData[cbehProps.bracingData.length-1].bracingId) : 1;
        bracingId = newBracingId;
        setBracingState(bracingState=>({...bracingState, bracingId: newBracingId}));
      }
      const allBracings: towerBracingsData[] = [];//cbehProps.bracingData
      let allCreatedBracingIds: string = ``;
      Array.from(Array(10).keys()).forEach(e=>allCreatedBracingIds=allCreatedBracingIds.concat(`${newBracingId+e}|`));

      const theEnd = parseFloat(cbehProps.siteCoordZ) + topElevation;
      const theStart = parseFloat(cbehProps.siteCoordZ) + bottomElevation;
      const dist = Math.abs(theEnd-theStart);
      const divs = dist / addMultiple.count;
      for (let ai = 0; ai < addMultiple.count; ai++) {
        const newBracing: towerBracingsData = {
          bracingId: 0,
          cert: 0,
          end: 0,
          horizontalMid: false,
          horizontalTop: false,
          planBracingMid: null,
          planBracingTop: null,
          start: 0,
          type: ""
        };
        if(create){
          newBracing.bracingId = bracingId+ai;
          newBracing.start = theStart+ai*divs;
          newBracing.end = theStart+(ai+1)*divs;
          newBracing.type = subType;
          newBracing.horizontalTop = horizontalTop;
          newBracing.horizontalMid = horizontalMid;
          newBracing.planBracingTop = planBracingTop.enabled ? {type: planBracingTop.type!, cert: 1} : null; 
          newBracing.planBracingMid = planBracingMid.enabled ? {type: planBracingMid.type!, cert: 1} : null;
          // allCreatedBracingIds = allCreatedBracingIds.concat(`${newBracing.bracingId}|`);
        } else {
          const toBeEdited: towerBracingsData = JSON.parse(JSON.stringify(cbehProps.bracingData.find(bd=>bd.bracingId == bracingId)));
          newBracing.bracingId = toBeEdited.bracingId+ai;
          newBracing.cert = toBeEdited.cert;
          newBracing.end = toBeEdited.end;
          newBracing.horizontalMid = toBeEdited.horizontalMid;
          newBracing.horizontalTop = toBeEdited.horizontalTop;
          newBracing.planBracingMid = toBeEdited.planBracingMid;
          newBracing.planBracingTop = toBeEdited.planBracingTop;
          newBracing.start = toBeEdited.start;
          newBracing.type = toBeEdited.type;
        }
        if(newBracing.planBracingTop != null){
          const existPlanTopTypeArr = newBracing.planBracingTop?.type.split(' ');
          newBracing.planBracingTop = {type: existPlanTopTypeArr![0].toUpperCase()+' '+existPlanTopTypeArr![1], cert: 0}
        }
        if(newBracing.planBracingMid != null){
          const existPlanMidTypeArr = newBracing.planBracingMid?.type.split(' ');
          newBracing.planBracingMid = {type: existPlanMidTypeArr![0].toUpperCase()+' '+existPlanMidTypeArr![1], cert: 0}
        }

        allBracings.push(newBracing);
      }  

      bracingDec.geometryGraphics = [];
      allCreatedBracingIds = allCreatedBracingIds.substring(0, allCreatedBracingIds.length-1);
      bracingDec.creatingBracingIds = allCreatedBracingIds;
      setTheBracings(allBracings);
      await bracingUpdate(allBracings, {faceBracing: true, planBracings: true}, allCreatedBracingIds);
      setSaveButton(saveButton=>({...saveButton, disabled: false}));
      // const faceTransientIds: bracTrans[] = [];
      // const planTransientIds: bracTrans[] = [];

      // const data2Send = {
      //   "tower-structure": towerData,
      //   "bracings": allBracings}

      // allBracings.forEach(e=>faceTransientIds.push({bracingId:e.bracingId, transientId: IModelApp.viewManager.selectedView!.iModel.transientIds.getNext()}));
      // var bracFaceInfoDat = bracingDec.getBracingFaceDataForOTD(allBracings, towerData);
      // let faceModel = BracingDecorator.modelFromBracingFaceData(bracFaceInfoDat);

      // let out = await BracingsClient.postBracingDetectorGeneratorData(store.getState().auth.accessTokenStatePrivateAPI.accessToken!, faceModel);
      // bracingDec.createPipesWithOTDData(out.data, data2Send, bracFaceInfoDat, faceTransientIds);
      
      // const {planBracings} = BracingDecorator.getBracingCoordinatesFromPattern(allBracings, towerData);
      // planBracings.forEach(e=>planTransientIds.push({bracingId:e.BracingId, transientId: IModelApp.viewManager.selectedView!.iModel.transientIds.getNext()}));
      // const out2 = await BracingsClient.postBracingDetectorGeneratorData(store.getState().auth.accessTokenStatePrivateAPI.accessToken!, planBracings);
      // if(out2) {
      //   bracingDec.createPipesWithOTDData(out2.data, data2Send, planBracings, planTransientIds);
      // }
      // resetObjectIds(bracingDec);
      // IModelApp.viewManager.selectedView?.invalidateDecorations();
      // IModelApp.viewManager.selectedView?.invalidateCachedDecorations(bracingDec);
    }

    const onReset = () => {
      BracingDecorator.selectedBracing="";
      BracingDecorator.selectedBracingForEdit="";
      const bracingDec = IModelApp.viewManager.decorators.filter((e) => e.constructor.name.includes("BracingDecorator"))[0] as BracingDecorator;
      // if(e.ctrlKey)setBracingState(bracingDefState);
      if(bracingDec)bracingDec.resetBracings();
      if(cbehProps.editMode && bracingDec){
        setMergeButtonState({mergeUp: {active: false, id: 0}, mergeDown: {active: false, id: 0}, splitUp: {active: false, id: 0}});
        if(cbehProps.bracingPropertyJson.bracingId != 0){
  
          const {bracingId, cert, end, horizontalMid, horizontalTop, planBracingMid, planBracingTop, start, type} = cbehProps.bracingData.find(e=>e.bracingId == parseInt(cbehProps?.bracingPropertyJson?.bracingId))!;
          let searchPattern = `@${type}#`;
          const patternIndex = BracingPatterns.findIndex(bp=>bp.match(searchPattern));
          const spt = BracingPatterns[patternIndex];
          const mainType = spt.split('@')[0];
          const subPatternList = [{label: "Select", value: 0}];
          searchPattern = `${spt.split('@')[0]}@`;
          BracingPatterns.forEach(bp=>{
            const mt = bp.split('@')[0];
            if(mainPatternList.findIndex(mp=>mp.label==mt)==-1)mainPatternList.push({label: mt, value: mainPatternList.length})
            const res = bp.match(searchPattern);
            if(res){
              const temp = bp.split('@');
              subPatternList.push({label: temp[1].split('#')[0], value: subPatternList.length})
            }
          });
    
    
          let bracingEditProps: BracingEditHandlerState = {
            ...bracingDefState,
            bracingId: bracingId,
            topElevation: unitImperial ? (end-parseFloat(cbehProps.siteCoordZ)) * feetToMeterConvFactor : end-parseFloat(cbehProps.siteCoordZ),
            bottomElevation: unitImperial ? (start-parseFloat(cbehProps.siteCoordZ)) * feetToMeterConvFactor : start-parseFloat(cbehProps.siteCoordZ),
            horizontalMid: horizontalMid,
            horizontalTop: horizontalTop,
            mainPatternIndex: mainPatternList.findIndex(mpl=>mpl.label==spt.split('@')[0]),
            subPatternIndex: subPatternList.findIndex(spl=>spl.label==spt.split('@')[1].split('#')[0]),
            subPatternList,
            mainType,
            subType: type,
            planBracingMid: {
              enabled: planBracingMid == null ? false : true,
              type: planBracingMid == null ? null : planBracingMid.type
            },
            planBracingTop: {
              enabled: planBracingTop == null ? false : true,
              type: planBracingTop == null ? null : planBracingTop.type
            }
          };
          setBracingState(bracingEditProps);
          setSaveButton(saveButton=>({...saveButton, disabled: true}));
        }
      } else {
        setBracingState(bracingDefState);
      }



      // bracingEditProps.bracingId = bracingId;
      // bracingEditProps.topElevation = end-cbehProps.siteCoordZ;
      // bracingEditProps.horizontalMid = horizontalMid;
      // bracingEditProps.horizontalTop = horizontalTop;
      // bracingEditProps.bottomElevation = start-cbehProps.siteCoordZ;
      // bracingEditProps.type = type;
      // bracingEditProps.cert = cert;
      // bracingEditProps.planBracingMid = planBracingMid;
      // bracingEditProps.planBracingTop = planBracingTop;

    }

    const onClose = () => {
      // BracingDecorator.selectedBracing="";
      // BracingDecorator.selectedBracingForEdit="";
      const bracingDec = IModelApp.viewManager.decorators.filter((e) => e.constructor.name.includes("BracingDecorator"))[0] as BracingDecorator;
      if(bracingDec)bracingDec.resetBracings();
      store.dispatch(setSelectedObjectStructure(initialPropertyListObjectState));
      const widget = UiFramework.frontstages.activeFrontstageDef?.findWidgetDef("BracingEditWidget");
      widget?.setWidgetState(WidgetState.Hidden);
      store.dispatch(DTVActions.setEditModeFlag(false));
      setWidgetClosed(true);
    }

    const onSave = async (_target) => {
      const bracingDec = IModelApp.viewManager.decorators.filter((e) => e.constructor.name.includes("BracingDecorator"))[0] as BracingDecorator;
      setSaveButton(saveButton=>({...saveButton, disabled: true, label: cbehProps.editMode ? "Save" : "Add"}));
      switch (saveButton.label) {
        case "Add": {
          const olderBracings = cbehProps.bracingData;
          const allBracings = [...olderBracings, ...theBracings!];
          const res = await BracingsClient.postBracingData(allBracings, cbehProps.privateToken);
          if(res == "Success"){
            IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Info, "Bracings added successfully.", "", OutputMessageType.Toast));
            const theRefreshedBracingData = await BracingsClient.getBracingsData(cbehProps.privateToken);            
            store.dispatch(setBracingsData(theRefreshedBracingData));
            const data2Send = {"tower-structure": store.getState().detectedData.towerStructureData, bracings: theRefreshedBracingData};
            await BracingDecorator.refreshDecoratorBracingData(data2Send);
            bracingDec.resetBracings(data2Send);
            setBracingState(bracingDefState);
            // widget?.setWidgetState(WidgetState.Hidden);
          }
          break;
        }
        case "Save": {
          const bracingIdsToAvoidArray = theBracings.map(brace=>brace.bracingId);
          // const bracingIdsToAvoid = bracingIdsToAvoidArray.join('|');
          const olderBracings = cbehProps.bracingData.filter(bd=>bracingIdsToAvoidArray.findIndex(bitaa=>bitaa==bd.bracingId)==-1);
          const allBracings = [...olderBracings, ...theBracings];
          const res = await BracingsClient.postBracingData(allBracings, cbehProps.privateToken);
          if(res == "Success"){
            IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Info, "Bracings updated successfully.", "", OutputMessageType.Toast));
            const theRefreshedBracingData = await BracingsClient.getBracingsData(cbehProps.privateToken);            
            store.dispatch(setBracingsData(theRefreshedBracingData));
            bracingDec.resetBracings({"tower-structure": store.getState().detectedData.towerStructureData, bracings: theRefreshedBracingData});
            const data2Send = {"tower-structure": store.getState().detectedData.towerStructureData, bracings: theRefreshedBracingData};
            await BracingDecorator.refreshDecoratorBracingData(data2Send);
            bracingDec.resetBracings(data2Send);
            // const widget = UiFramework.frontstages.activeFrontstageDef?.findWidgetDef("BracingEditWidget");
            // widget?.setWidgetState(WidgetState.Hidden);
          }
          break;
        }
        default:
          break;
      }
    }

    const toggleElevaionMarker = (_target) => {
    }

    const updateElevation = async (target, point, skipConversion = false)=>{
      const bracingDec = IModelApp.viewManager.decorators.filter((e) => e.constructor.name.includes("BracingDecorator"))[0] as BracingDecorator;
      const iModel = UiFramework.getIModelConnection()!;
      const convertedPoint = unitImperial ? new Point3d(point.x, point.y, point.z*feetToMeterConvFactor) : point;
      const thePoint = await iModel.spatialToCartographic(convertedPoint);
      const elVal = !skipConversion ? thePoint.height - cbehProps.siteCoordZ : convertedPoint.z;
      const options = StrokeOptions.createForCurves();
      options.needParams = false;
      options.needNormals = true;
      const radius = 0.2;
      const builder = PolyfaceBuilder.create(options);
      const objInfo = {
        color: ColorDef.red,
        transparency: 0,
        thickness: 2,
        pointA: new Point3d(),
        pointB: new Point3d(1, 1, 1),
        thePoint: point.clone(),
        name: target,
        elevation: elVal
        };
      let topElevation: number = bracingState.topElevation, bottomElevation: number = bracingState.bottomElevation;
      
      switch (target) {
        case "TopElevation": {
          if(bracingDec.pipes.length){
            const botIndex = bracingDec.pipes.findIndex(bp=>bp.name.match(/BottomElevation/));
            const botSphereInfo = botIndex > -1 ? bracingDec.pipes[botIndex].modelData : {};
            topElevation = objInfo.elevation;
            if(botIndex > -1 && elVal < botSphereInfo?.elevation){
              const builder = PolyfaceBuilder.create(options);
              bracingDec.pipes.splice(botIndex, 1);
              botSphereInfo.color =  elVal < botSphereInfo?.elevation ? ColorDef.blue : ColorDef.green;
              objInfo.color =  elVal < botSphereInfo.elevation ? ColorDef.green : ColorDef.blue;
              botSphereInfo.name = "BottomElevation";
              objInfo.thePoint = botSphereInfo.thePoint;
              objInfo.elevation = botSphereInfo.elevation;
              // botSphereInfo.thePoint = convertedPoint.clone();
              botSphereInfo.elevation = elVal;
              const sphere = Sphere.createCenterRadius(new Point3d(botSphereInfo.thePoint.x, botSphereInfo.thePoint.y, botSphereInfo.thePoint.z), radius);
              builder.addSphere(sphere);
              const polyface = builder.claimPolyface(false);
              const ball = bracingDec.addGeometry(polyface, botSphereInfo, iModel.transientIds.getNext(), "BottomElevation")
              bracingDec.pipes.push(ball);
              setBracingState((bracingState)=>({...bracingState, topElevation: objInfo.elevation, bottomElevation: elVal}));
            } else {
              objInfo.color = ColorDef.blue;
              setBracingState((bracingState)=>({...bracingState, topElevation: elVal}));
              if(botIndex > -1){
                const builder = PolyfaceBuilder.create(options);
                const botSphereInfo = bracingDec.pipes[botIndex].modelData;
                bracingDec.pipes.splice(botIndex, 1);
                botSphereInfo.color = ColorDef.green;
                const sphere = Sphere.createCenterRadius(new Point3d(botSphereInfo.thePoint.x, botSphereInfo.thePoint.y, botSphereInfo.thePoint.z), radius);
                builder.addSphere(sphere);
                const polyface = builder.claimPolyface(false);
                const ball = bracingDec.addGeometry(polyface, botSphereInfo, iModel.transientIds.getNext(), "BottomElevation")
                bracingDec.pipes.push(ball);  
              }
            }
          }
        }
        break;
        case "BottomElevation":{
          if(bracingDec.pipes.length){
            const topIndex = bracingDec.pipes.findIndex(bp=>bp.name.match(/TopElevation/));
            const topSphereInfo = topIndex > -1 ? bracingDec.pipes[topIndex].modelData : {};
            bottomElevation = objInfo.elevation;
            if(topIndex > -1 && elVal > topSphereInfo?.elevation ){
              const builder = PolyfaceBuilder.create(options);
              bracingDec.pipes.splice(topIndex, 1);
              topSphereInfo.color =  elVal > topSphereInfo?.elevation ? ColorDef.green : ColorDef.blue;
              objInfo.color = elVal > topSphereInfo?.elevation ? ColorDef.blue : ColorDef.green;
              topSphereInfo.name = "TopElevation";
              objInfo.thePoint = topSphereInfo.thePoint;
              objInfo.elevation = topSphereInfo.elevation;
              // topSphereInfo.thePoint = convertedPoint.clone();
              topSphereInfo.elevation = elVal;
              const sphere = Sphere.createCenterRadius(new Point3d(topSphereInfo.thePoint.x, topSphereInfo.thePoint.y, topSphereInfo.thePoint.z), radius);
              builder.addSphere(sphere);
              const polyface = builder.claimPolyface(false);
              const ball = bracingDec.addGeometry(polyface, topSphereInfo, iModel.transientIds.getNext(), "TopElevation")
              bracingDec.pipes.push(ball);
              setBracingState((bracingState)=>({...bracingState, bottomElevation: objInfo.elevation, topElevation: elVal}));
            } else {
              objInfo.color = ColorDef.green;
              setBracingState((bracingState)=>({...bracingState, bottomElevation: elVal}));
              if(topIndex > -1){
                const builder = PolyfaceBuilder.create(options);
                const topSphereInfo = bracingDec.pipes[topIndex].modelData;
                bracingDec.pipes.splice(topIndex, 1);
                topSphereInfo.color = ColorDef.blue;
                const sphere = Sphere.createCenterRadius(new Point3d(topSphereInfo.thePoint.x, topSphereInfo.thePoint.y, topSphereInfo.thePoint.z), radius);
                builder.addSphere(sphere);
                const polyface = builder.claimPolyface(false);
                const ball = bracingDec.addGeometry(polyface, topSphereInfo, iModel.transientIds.getNext(), "TopElevation")
                bracingDec.pipes.push(ball);  
              }
            }
          }
        }
        break;
      
        default:
          break;
      }

      if(bracingDec.pipes){
        const sphereIndex = bracingDec.pipes.findIndex(bp=>bp.name.match(target));
        if(sphereIndex > -1)bracingDec.pipes.splice(sphereIndex, 1);
      }
      const sphere = Sphere.createCenterRadius(new Point3d(point.x, point.y, point.z), radius);
      builder.addSphere(sphere);
      const polyface = builder.claimPolyface(false);
      const ball = bracingDec.addGeometry(polyface, objInfo, iModel.transientIds.getNext(), target)
      bracingDec.pipes.push(ball);

      if(!cbehProps.editMode)setTriggerCreate(true);

      // bracingDec.geometryGraphics = [];
      // IModelApp.viewManager.selectedView?.invalidateDecorations();
      // IModelApp.viewManager.selectedView?.invalidateCachedDecorations(bracingDec);
      return {topElevation, bottomElevation};
    }

    const getElevation = async (target) => {
      await IModelApp.tools.run(PointSelectionTool.toolId, async (ev: BeButtonEvent)=>{
        const {topElevation: te, bottomElevation: be} = await updateElevation(target, ev.point);
        let { bracingId, topElevation, horizontalMid, horizontalTop, bottomElevation, subType, planBracingMid, planBracingTop } = bracingState;
        let theTopElevation: number = unitImperial ? parseFloat(cbehProps.siteCoordZ) + te/feetToMeterConvFactor : parseFloat(cbehProps.siteCoordZ) + te ;
        let theBotElevation: number = unitImperial ? parseFloat(cbehProps.siteCoordZ) + be/feetToMeterConvFactor : parseFloat(cbehProps.siteCoordZ) + be ;
        const theBracing: towerBracingsData = {
          bracingId,
          cert: 1,
          end: theTopElevation,
          horizontalMid,
          horizontalTop,
          planBracingMid: planBracingTop.enabled ? {type: planBracingTop.type!, cert: 1} : null,
          planBracingTop: planBracingMid.enabled ? {type: planBracingMid.type!, cert: 1} : null,
          start: theBotElevation,
          type: subType
        };


        if(!cbehProps.editMode && bracingId != 0 && topElevation != 0 && bottomElevation != 0)setTriggerCreate(true);
        else if(bracingId != 0 && topElevation != 0 && bottomElevation != 0)await bracingUpdate([theBracing], {faceBracing: true, planBracings: true }, bracingId.toString());
        else {
          const bracingDec = IModelApp.viewManager.decorators.filter((e) => e.constructor.name.includes("BracingDecorator"))[0] as BracingDecorator;
          bracingDec.geometryGraphics = [];
          IModelApp.viewManager.selectedView?.invalidateDecorations();
          IModelApp.viewManager.selectedView?.invalidateCachedDecorations(bracingDec);            
        }
        // const bracingDec = IModelApp.viewManager.decorators.filter((e) => e.constructor.name.includes("BracingDecorator"))[0] as BracingDecorator;
        // bracingDec.geometryGraphics = [];
        // IModelApp.viewManager.selectedView?.invalidateDecorations();
        // IModelApp.viewManager.selectedView?.invalidateCachedDecorations(bracingDec);
      }, false);
    }

  
    const toolChangeListener = (tool: Tool, _start: StartOrResume): void => {
      if (tool.toolId === "PlacementTool") {
        setIsDisabled(false);
        return;
      }
      setIsDisabled(true);
    };

    IModelApp.toolAdmin.activeToolChanged.addListener(toolChangeListener);
  
    let posRotJsx = [<></>];

    const planBraceTopIndex = bracingState.planBracingTop.type != null ? planPatternList.findIndex(ppl=>ppl.label.toUpperCase().match(bracingState.planBracingTop.type?.toUpperCase())) : 0;
    const planBraceMidIndex = bracingState.planBracingMid.type != null ? planPatternList.findIndex(ppl=>ppl.label.toUpperCase().match(bracingState.planBracingMid.type?.toUpperCase())) : 0;


    return <>{(widget?.state == WidgetState.Open) &&
    // return <>{(cbehProps.open) &&
      <>
        <div className=" bracing-placement-options">
          <div className=" bracing-placement-grid pl-0">
            <div className="controls">
              <fieldset>
                <legend style={{fontWeight: "bold", marginBottom: "10px"}}>Operating Mode</legend>
                <div className=" bracing-placement-grid">
                  <div className="controls">
                    <div className="row">
                      <div className="bracing-placement-component-group">
                        <Label className="col-3" htmlFor="text-input">Mode</Label>
                        <Button style={{border: "none", padding: "0", backgroundColor: "transparent"}} className="col-4 text-center"  onClick={()=>onFieldUpdate(OperatingMode.Add, "OperatingMode")}><Badge className="" style={{border: `1px solid ${!cbehProps.editMode ? "green":  "black"}`}} backgroundColor={!cbehProps.editMode ? "positive" : "ash"}>Create</Badge></Button>
                        <Button style={{border: "none", padding: "0", backgroundColor: "transparent"}} className="col-4 text-center"  onClick={()=>onFieldUpdate(OperatingMode.Edit, "OperatingMode")}><Badge className="" style={{border: `1px solid ${cbehProps.editMode ? "black":  "green"}`}} backgroundColor={cbehProps.editMode ? "positive" : "ash"}>Edit</Badge></Button>
                        {/* <Button style={{border: "none", padding: "0", backgroundColor: "transparent"}} className="col-4 text-center"  onClick={()=>onFieldUpdate(OperatingMode.Add, "OperatingMode")}><Badge className="" style={{border: `1px solid ${cbehProps.editMode ? "black":  "green"}`}} backgroundColor={!cbehProps.editMode ? "positive" : "ash"}>Add</Badge></Button>
                        <Button style={{border: "none", padding: "0", backgroundColor: "transparent"}} className="col-4 text-center"  onClick={()=>onFieldUpdate(OperatingMode.Edit, "OperatingMode")}><Badge className="" style={{border: `1px solid ${!cbehProps.editMode ? "black":  "green"}`}} backgroundColor={cbehProps.editMode ? "positive" : "ash"}>Edit</Badge></Button> */}
                        {/* <Radio className="col-4" name="Mode" status="positive" onClick={()=>onFieldUpdate(OperatingMode.Add, "OperatingMode")} label="Add" checked={(bracingState.opMode == OperatingMode.Add || !cbehProps.editMode)} disabled={cbehProps.editMode} />
                        <Radio className="col-4" name="Mode" status="warning" onClick={()=>onFieldUpdate(OperatingMode.Edit, "OperatingMode")} label="Edit" checked={bracingState.opMode == OperatingMode.Edit || cbehProps.editMode} disabled={!cbehProps.editMode} /> */}
                      </div>
                    </div>
                  </div>
                </div>
                {/* <ProgressLinear className="mt-3 mb-0" style={{ width: "100%" }} labels={["Please wait..."]} isAnimated indeterminate /> */}
              </fieldset>
              <fieldset>
                <legend style={{fontWeight: "bold", marginBottom: "10px"}}>Bay Elevation & Count</legend>
                <div className=" bracing-placement-grid">
                  <div className="controls">
                    <div className="row">
                      <LabeledInput size="small" step={0.05} min={0} max={maxHeight} value={Math.round(bracingState.topElevation*100000)/100000} onChange={(e)=>onFieldUpdate(e, "topElevation")} displayStyle="inline" status={bracingState.topElevation == 0 ? "negative" : undefined} message="Enter elevation" title="Enter elevation" type="number" className="col-9 pr-0" label='Top' setFocus />
                      {/* <IconButton size="small" className="col-2" label='Pick location' styleType='high-visibility' onClick={()=>getElevation("TopElevation")}><SvgReCenter /></IconButton> */}
                      <IconButton size="small" className="col-2" label='Pick location' styleType='high-visibility' onClick={()=>getElevation("TopElevation")}><SvgReCenter /></IconButton>
                    </div>
                    <div className="row">
                      <LabeledInput size="small" step={0.05} min={0} max={maxHeight} value={Math.round(bracingState.bottomElevation*100000)/100000} onChange={(e)=>onFieldUpdate(e, "bottomElevation")} displayStyle="inline" status={bracingState.bottomElevation == 0 ? "negative" : undefined} type="number" className="col-9 pr-0" label='Bottom' setFocus />
                      <IconButton size="small" className="col-2" label='Pick location' styleType='cta'  onClick={()=>getElevation("BottomElevation")}><SvgReCenter /></IconButton>
                    </div>
                    {/* <div className="row">
                      <div className="col-8 mt-1 font-weight-bold" style={{fontWeight: "bold"}} title="Show elevation markers.">Elevation marker</div>
                      <div className="col-3 mt-1"><ToggleSwitch onChange={(e)=>toggleElevaionMarker(e)} checked={bracingState.addMultiple.enabled} icon={<SvgCheckmark />} /></div>
                    </div> */}
                    <div className="row">
                      <div className="col-8 mt-1 font-weight-bold" style={{fontWeight: "bold"}} title="Specify the number of bracings to be created between the elevation.">Create Multiple</div>
                      <div className="col-3 mt-1"><ToggleSwitch onChange={(e)=>setBracingState((bracingState)=>({...bracingState, addMultiple: {...bracingState.addMultiple, enabled: e.target.checked}}))} checked={bracingState.addMultiple.enabled} icon={<SvgCheckmark />} /></div>
                    </div>
                    <LabeledInput
                      className=""
                      label="# of Bracings"
                      size="small"
                      type="number"
                      disabled={!bracingState.addMultiple.enabled}
                      step={1}
                      min={1}
                      max={10}
                      value={bracingState.addMultiple.count}
                      displayStyle="inline"
                      onChange={(e)=>{if(!cbehProps.editMode){setTriggerCreate(true)}setBracingState((bracingState)=>({...bracingState, addMultiple: {...bracingState.addMultiple, count: parseInt(e.target.value)}}))}}
                    />

                  </div>
                </div>
                {/* <ProgressLinear className="mt-3 mb-0" style={{ width: "100%" }} labels={["Please wait..."]} isAnimated indeterminate /> */}
              </fieldset>
              <fieldset>
                <legend style={{fontWeight: "bold", marginBottom: "4px"}}>Face Bracing Pattern</legend>
                {/* <div className=" bracing-placement-control row p-1">
                  <Text className="col-4">Name: </Text>
                  <Select
                    className="col-8"
                    size="small"
                    value={selMainPattern}
                    options={equipmentList}
                    onKeyDown={(e)=>console.log('This is name keyDown handler: ', e)}
                    onKeyDownCapture={(e)=>console.log('This is name keyDownCapture handler: ', e)}
                    onChange={(e)=>{onEquipmentNameSelect(parseInt(e))}} />
                </div> */}
                <LabeledSelect
                  className="mb-1"
                  label='Main Pattern'
                  displayStyle="inline"
                  size="small" 
                  status={bracingState.mainPatternIndex == 0 ? "negative" : "positive"}
                  value={bracingState.mainPatternIndex}
                  setFocus={true}
                  options={bracingState.mainPatternList}
                  onChange={e=>onFieldUpdate(e, "MainPatternSelect")}
                />
                <LabeledSelect
                  className="mb-1"
                  label='Sub-Pattern'
                  displayStyle="inline"
                  size="small" 
                  status={bracingState.subPatternIndex == 0 ? "negative" : "positive"}
                  value={bracingState.subPatternIndex}
                  setFocus={true}
                  options={bracingState.subPatternList}
                  onChange={e=>onFieldUpdate(e, "SubPatternSelect")}
                />
                <div className="row">
                  <div className="col-8 mt-1 font-weight-bold" style={{fontWeight: "bold"}} title="Equipment state">Horizontal Top</div>
                  <div className="col-3 mt-1"><ToggleSwitch onChange={(e)=>onFieldUpdate(e, "HorizontalTopSwitch")} checked={bracingState.horizontalTop} icon={<SvgCheckmark />} /></div>
                </div>
                <div className="row">
                  <div className="col-8 mt-1 font-weight-bold" style={{fontWeight: "bold"}} title="Equipment state">Horizontal Mid</div>
                  <div className="col-3 mt-1"><ToggleSwitch onChange={(e)=>onFieldUpdate(e, "HorizontalMidSwitch")} checked={bracingState.horizontalMid} icon={<SvgCheckmark />} /></div>
                </div>
                {facePatternLoad && <ProgressLinear className="mt-3 mb-0" style={{ width: "100%" }} labels={["Please wait..."]} isAnimated indeterminate />}

                {/* <div className="mt-2 ml-1 mr-1">
                  <Button style={{width: "100%"}} title="Move In" size="large" startIcon={<SvgSync />} onClick={getBestMatch}>Get best matches</Button>
                </div> */}

                {/* <caption>Select using custom renderers for menu items and selected value.</caption>
                <Select<string>
                  options={[
                    { value: 'yellow', label: 'Yellow' },
                    { value: 'green', label: 'Green' },
                    { value: 'red', label: 'Red' },
                  ]}
                  value={selectedValue}
                  placeholder='Placeholder text'
                  itemRenderer={(option, itemProps) => (
                    <MenuItem
                      style={{ color: option.value }}
                      isSelected={itemProps.isSelected}
                      onClick={() => {
                        setSelectedValue(option.value);
                        itemProps.close();
                      }}
                      role='option'
                      ref={(el) => itemProps.isSelected && el?.scrollIntoView()}
                    >
                      {option.label}
                    </MenuItem>
                  )}
                  selectedItemRenderer={(option) => (
                    <span style={{ backgroundColor: option.value }}>{option.label}</span>
                  )}
                /> */}

              </fieldset>
              <fieldset>
                <legend style={{fontWeight: "bold", marginBottom: "4px"}}>Plan Bracing Pattern</legend>
                <div className=" bracing-placement-grid">
                  <div className="controls">
                    <div className="row">
                      <div className="col-3 font-weight-bold" style={{fontWeight: "bold"}} title="Equipment state">Top</div>
                      <div className="col-3"><ToggleSwitch onChange={(e)=>onFieldUpdate(e, "PlanTopSwitch")} disabled={false} checked={bracingState.planBracingTop.enabled} icon={<SvgCheckmark />} /></div>
                      <LabeledSelect
                        className="col-6"
                        // label='Top Pattern'
                        displayStyle="inline"
                        size="small"
                        disabled={!bracingState.planBracingTop.enabled}
                        // status="positive"
                        value={planBraceTopIndex}
                        setFocus={true}
                        options={planPatternList}
                        onChange={e=>onFieldUpdate(e, "PlanTopSelect")}
                      />
                    </div>
                    <div className="row">
                      <div className="col-3 font-weight-bold" style={{fontWeight: "bold"}} title="Equipment state">Mid</div>
                      <div className="col-3"><ToggleSwitch onChange={(e)=>onFieldUpdate(e, "PlanMidSwitch")} disabled={false} checked={bracingState.planBracingMid.enabled} icon={<SvgCheckmark />} /></div>
                      <LabeledSelect
                        className="mb-0 col-6"
                        // label='Mid Pattern'
                        displayStyle="inline"
                        size="small" 
                        disabled={!bracingState.planBracingMid.enabled}
                        // status="positive"
                        value={planBraceMidIndex}
                        setFocus={true}
                        options={planPatternList}
                        onChange={e=>onFieldUpdate(e, "PlanMidSelect")}
                      />
                    </div>
                    {/* <LabeledSelect
                      className="mb-0"
                      label='Mid Pattern'
                      displayStyle="inline"
                      size="small" 
                      disabled={!bracingState.planBracingMid.enabled}
                      // status="positive"
                      value={planPatternList.findIndex(ppl=>ppl.label==bracingState.planBracingMid.type)}
                      setFocus={true}
                      options={planPatternList}
                      onChange={e=>onFieldUpdate(e, "PlanMidSelect")}
                    /> */}
                  </div>
                  {planPatternLoad && <ProgressLinear className="mt-0 mb-0" style={{ width: "100%" }} labels={["Please wait..."]} isAnimated indeterminate />}
                </div>
              </fieldset>
              <fieldset>
                <legend style={{fontWeight: "bold", marginBottom: "4px"}}>Merge or Split</legend>
                <div className=" bracing-placement-grid">
                  <div className="controls">
                    <div className="d-flex justify-content-center">
                      <Button styleType={mergeButtonState.mergeUp.active ? "cta" : "default"} className="col-12" title="Merge Up" disabled={!cbehProps.editMode || mergeUp.theBracings==null || mergeButtonState.mergeUp.active} startIcon={<SvgChevronUp />} onMouseDown={()=>onMouseDown("Merge up")} >Merge Up</Button>
                    </div>
                    <div className="d-flex justify-content-center">
                      <Button styleType={mergeButtonState.splitUp.active ? "cta" : "default"} className="col-12" title="Split" disabled={!cbehProps.editMode || mergeButtonState.splitUp.active} startIcon={<SvgUnlink/>} onMouseDown={()=>onMouseDown("Split up")} >Split Up</Button>
                    </div>
                    <div className="d-flex justify-content-center">
                      <Button styleType={mergeButtonState.mergeDown.active ? "cta" : "default"} className="col-12" title="Merge Down" disabled={!cbehProps.editMode || mergeDown.theBracings==null || mergeButtonState.mergeDown.active} startIcon={<SvgChevronDown />} onMouseDown={()=>onMouseDown("Merge down")} >Merge Down</Button>
                    </div>

                    {/* <div className="row">
                      <div className="col-8 font-weight-bold" style={{fontWeight: "bold"}} title="Equipment state">Merge Up</div>
                      <div className="col-3"><ToggleSwitch onChange={(e)=>onFieldUpdate(e, "Status")} checked={bracingProps.Active} icon={<SvgCheckmark />} /></div>
                    </div>
                    <div className="row">
                      <div className="col-8 font-weight-bold" style={{fontWeight: "bold"}} title="Equipment state">Merge Down</div>
                      <div className="col-3"><ToggleSwitch onChange={(e)=>onFieldUpdate(e, "Status")} checked={bracingProps.Active} icon={<SvgCheckmark />} /></div>
                    </div>
                    <div className="row">
                      <div className="col-8 font-weight-bold" style={{fontWeight: "bold"}} title="Equipment state">Split</div>
                      <div className="col-3"><ToggleSwitch onChange={(e)=>onFieldUpdate(e, "Status")} checked={bracingProps.Active} icon={<SvgCheckmark />} /></div>
                    </div>
                    <div className="row">
                      <div className="col-8 font-weight-bold" style={{fontWeight: "bold"}} title="Connect Nearest Bracings">Connect Bracings</div>
                      <div className="col-3"><ToggleSwitch onChange={(e)=>onFieldUpdate(e, "Status")} checked={bracingProps.Active} icon={<SvgCheckmark />} /></div>
                    </div> */}
                  </div>
                </div>
                {/* <ProgressLinear className="mt-3 mb-0" style={{ width: "100%" }} labels={["Please wait..."]} isAnimated indeterminate /> */}
              </fieldset>
              {/* <fieldset>
                <legend style={{fontWeight: "bold", marginBottom: "0px"}}>Position & Rotation</legend>
                <Tabs
                  tabsClassName="equipment-posrot-tab"
                  contentClassName="pl-0 pb-0 pt-2"
                  wrapperClassName="equipment-posrot-tab-wrapper"
                  orientation="horizontal"
                  onTabSelected={setIndex}
                  type="pill"
                  labels={[
                    <Tab key={0} label="Position" />,
                    <Tab key={1} label="Rotation" disabled={false} />,
                  ]}
                >
                  {posRotJsx[index]}
                </Tabs>
              </fieldset> */}
              <fieldset>
                <legend style={{fontWeight: "bold"}}>Action</legend>
                <div className="bracing-action-grid">
                  <Button style={{border: "none", padding: "0", backgroundColor: "transparent"}} className="col text-center" disabled={resetButton.disabled} onDoubleClick={onReset} onClick={onReset}><Badge style={{width: "100%", border: `1px solid ${resetButton.disabled ? "black":  "blue"}`}} backgroundColor={resetButton.disabled ? "ash" : "primary"}>{resetButton.label}</Badge></Button>
                  <Button style={{border: "none", padding: "0", backgroundColor: "transparent"}} className="col text-center" disabled={saveButton.disabled} onClick={onSave}><Badge style={{width: "100%", border: `1px solid ${saveButton.disabled ? "black" : "green"}`}} backgroundColor={saveButton.disabled ? "ash" : "positive"}>{cbehProps.editMode ? "Save" : "Add"}</Badge></Button>
                  <Button style={{border: "none", padding: "0", backgroundColor: "transparent"}} className="col text-center" onClick={onClose}><Badge style={{width: "100%"}} backgroundColor="negative">Close</Badge></Button>
                </div>
              </fieldset>
              {/* <hr/> */}
            </div>
          </div>
        </div>
      </>
    }</>;
  };
  

  const mapStateToProps = (state: RootState) => {
    let siteCoordinates = store.getState().detectedData.siteCoordinate;
    if (!siteCoordinates) {
        EquipmentClient.setSiteCoordinates(store.getState().auth.accessTokenStatePrivateAPI.accessToken).then(sc=>{
          siteCoordinates = sc;
          store.dispatch(setSiteCoordinate(sc));
        });     // Storing site coordinate, for if equipments are edited & require update relative to site <coordinates className="" />
    }
    const bracingDec = IModelApp.viewManager.decorators.filter((e) => e.constructor.name.includes("BracingDecorator"))[0] as BracingDecorator;
    let bracingEditProps: BracingEditProperties = JSON.parse(JSON.stringify(bracingDefProps));
    if(bracingDec && state.dtvState.applicationState.isEditModeActive){
      const bracingData: towerBracingsData[] = state.detectedData.towerBracingsData as towerBracingsData[];
      const selBraceName = state.detectedData.selectedObjectInformation.objectProperties.selectedObjectNST.name;
      const selBraceId = selBraceName.split('_')[2];
      const theBracing = bracingDec.pipes.find(bp=>bp.name.match(selBraceName));
      if(selBraceId){
        const theSelBracing = bracingData.find(e=>e.bracingId == parseInt(selBraceId));
        if(theSelBracing){
          const {bracingId, cert, end, horizontalMid, horizontalTop, planBracingMid, planBracingTop, start, type} = theSelBracing!;
    
          bracingEditProps.bracingId = bracingId;
          bracingEditProps.topElevation = end-siteCoordinates.utm.z;
          bracingEditProps.horizontalMid = horizontalMid;
          bracingEditProps.horizontalTop = horizontalTop;
          bracingEditProps.bottomElevation = start-siteCoordinates.utm.z;
          bracingEditProps.type = type;
          bracingEditProps.cert = cert;
          bracingEditProps.planBracingMid = planBracingMid;
          bracingEditProps.planBracingTop = planBracingTop;
        }
      }
    }

    const widget = UiFramework.frontstages.activeFrontstageDef?.findWidgetDef("BracingEditWidget");
    if(widget?.state == WidgetState.Hidden && bracingDec && state.dtvState.applicationState.isEditModeActive && state.detectedData.selectedObjectInformation.objectProperties.objectCategoryType == ObjectCategoryTypes.Bracings_RM){
      // widget?.setWidgetState(WidgetState.Open);
      const dmmWidget = UiFramework.frontstages.activeFrontstageDef?.findWidgetDef("DesignModelMenu");
      dmmWidget?.setWidgetState(WidgetState.Closed);
    }
    const towData = state.detectedData.towerStructureData as towerStructureData;
    const retVal = {
      open: bracingDec != undefined,
      editMode: state.dtvState.applicationState.isEditModeActive,
      bracingData: state.detectedData.towerBracingsData,
      towerData: state.detectedData.towerStructureData,
      siteCoordZ: siteCoordinates.utm.z,
      towerNLegs: towData.nLegs == 3 ? LegBasedTower.ThreeLeggedTower : LegBasedTower.FourLeggedTower,
      bracingPropertyJson: bracingEditProps! ?? bracingDefProps,
      selectedObjectInfo: state.detectedData.selectedObjectInformation,
      privateToken: state.auth.accessTokenStatePrivateAPI.accessToken
    };
    return retVal;
    // return widget?.state == WidgetState.Open ? retVal : {open: false};
  }
  
  const mapDispatchToProps = {}
  
  export default connect(mapStateToProps, mapDispatchToProps)(CustomBracingEditHandler)