import { IModelApp, NotifyMessageDetails, OutputMessagePriority, OutputMessageType } from "@itwin/core-frontend"
import { pecInputTagTypes, pecDataValueTypes, pecRows, pecGridStructure, pecComponentProps, pecStrucType, pecTableButton, PropertyEditorComponent, numericValue } from "../components/PropertyEditorComponent"

import React, { ReactNode, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { AddEditBracingTool } from "../components/tools/AddEditBracingTool";
import { RootState } from "../../store/States";
import { BracingDecorator } from "./decorators/BracingDecorator";
import { ConfigManager, UCFirst } from "../../config/ConfigManager";
import { getValueInRequiredUnit, getUnit, convertFeetToMeter } from "../components/Tables/BaseTable";
import { UiFramework } from "@itwin/appui-react";

export interface BracingCommsI {
  topElevationFlag: boolean | undefined;
  bottomElevationFlag: boolean | undefined;
  bracingType: string | undefined;
}


export interface BPL {name: string, label: string, subType: string[]}

export const bracingPatternList: BPL[] = [
  {
    name: "x",
    label: "X",
    subType: [
      "X Brace",
      "Taper X",
      "CX Brace",
      "TX Brace"
    ]
  },
  {
    name: "diamond",
    label: "Diamond",
    subType: [
      "Diagonal UP",
      "Diagonal Down"
    ]
  },
  {
    name: "k",
    label: "K",
    subType: [
      "K Brace Up",
      "K Brace Down",
      "TK Brace Up",
      "TK Brace Down",
      "K Brace Left",
      "K Brace Right",
      "K1 UP",
      "K1 Down",
      "K1B Down",
      "K2 Down",
      "K2 Up",
      "K2A Down",
      "K3 UP",
      "K3 Down",
      "K3A Down",
      "K3A M Down",
      "K4 UP",
      "K4 Down",
      "K4A Down",
      "Double K",
      "Double TK",
      "Double K1",
      "Double K1B",
      "Double K2",
      "Double K2A",
      "Double K3",
      "Double K3A",
      "Double K4",
      "Double K4A",
      "Cranked K",
      "Cranked K2",
    ]
  },
  {
    name: "misc",
    label: "Misc",
    subType: [
      "Portal", 
      "M"
    ]
  },
]

const BracingsEditHandler = (props) => {
  const [bracingsEditData, setBracingsEditData] = useState<pecGridStructure>(props.bracingsMenuStruc);
  const [bracingType, setBracingType] = useState("")
  const [mergeState, setMergeState] = useState("")
  useEffect(() => {
  
    // return () => {}
  }, [])

  useEffect(()=>{
    if(typeOfWorkflow == "Edit" && selectedBracings){
      let bracingObj = {name:selectedBracings.modelData.pattern,subType:""}
      bracingPatternList.map(bpl=>bpl.subType.filter(sub=>{
       if(sub == selectedBracings.modelData.pattern){bracingObj.subType = selectedBracings.modelData.pattern
        bracingObj.name = bpl.label
       }
      }));
      const topph = (selectedBracings.modelData.parentBracingHeights.bracingTopH - selectedBracings.modelData.baseAltitude).toFixed(4);
      const botth = (selectedBracings.modelData.parentBracingHeights.bracingBotH - selectedBracings.modelData.baseAltitude).toFixed(4);
      let tH = getValueInRequiredUnit(topph);
      let bH = getValueInRequiredUnit(botth);
      const temp: pecGridStructure = {...bracingsEditData};
      let tempTopVal :any= temp.rows[1].columns[1].value;
      let tempBotVal :any= temp.rows[2].columns[1].value
      tempTopVal.currentValue = parseFloat( tH );
      tempBotVal.currentValue = parseFloat(bH);
      let otherColumn = {parentClass: "mb-2", childClass: " "}
      let data = bracingPatternList.filter(bracing=>bracing.label == bracingObj.name)
      if(data && data.length){
        let subData=data[0].subType.map(sub=>{return{label:sub,name:sub}})
        temp.rows[0].columns[3].data = [... temp.rows[0].columns[3].data,...subData];
      }
      temp.rows[0].columns[1].value = bracingObj.name;
      temp.rows[0].columns[3].value = bracingObj.subType;
      temp.rows[1].columns[1].value = tempTopVal;
      temp.rows[2].columns[1].value = tempBotVal ;
      temp.rows[3].columns[1].value = selectedBracings.modelData.horizMiddleBay == "No"?false:true ;
      temp.rows[4].columns[1].value = selectedBracings.modelData.horizTopBay == "No"?false:true ;
      temp.rows[5].columns[1].styles = otherColumn ;
      temp.rows[6].columns[1].styles = otherColumn ;
      temp.rows[7].columns[1].styles = otherColumn ;
      setBracingsEditData(temp)
      setBracingType(bracingObj.subType)

    }
  },[selectedBracings])


  const onModalBtnClick = (event) => {
    const  target = event.target.id.split("#")[0];
    let currentEvent = event;
    switch(target){
      case "Preview":
        if(typeOfWorkflow == "Add") generateBracing(bracingType)
        else checkConditionForGenerateEdit();
        break;
      case "Save":
       if(typeOfWorkflow == "Add") AddEditBracingTool.saveNewBracing();
       else AddEditBracingTool.updateBracings();
       revertToOriginalState();
       closeFunc.onClose()
       break;
      case "Cancel":
        revertToOriginalState();
        AddEditBracingTool.reloadAllBracingsUpdatedData();
        closeFunc.onClose()
        break;
      default:
        break;
    }
  }

  const revertToOriginalState = ()=>{
    const temp: pecGridStructure = {...bracingsEditData};
    if(typeOfWorkflow == "Add"){
      AddEditBracingTool.topPoint = undefined;
      AddEditBracingTool.bottomPoint = undefined;
    }else{
      let otherColumn = {parentClass: "mb-2", childClass: " "}
      let tempBotVal :any= temp.rows[2].columns[1].value;
      let tempTopVal :any= temp.rows[1].columns[1].value;
      tempTopVal.currentValue = 0.00;
      tempBotVal.currentValue = 0.00;
      temp.rows[1].columns[1].value = tempTopVal;
      temp.rows[2].columns[1].value = tempBotVal ;
      temp.rows[3].columns[1].value = false ;
      temp.rows[4].columns[1].value = false ;
      temp.rows[5].columns[1].styles = otherColumn ;
      temp.rows[6].columns[1].styles = otherColumn 
      temp.rows[7].columns[1].styles = otherColumn 
      AddEditBracingTool.topPoint = undefined
      AddEditBracingTool.bottomPoint = undefined;
      setBracingsEditData(temp)
      setMergeState("")
    }
    }

  const checkConditionForGenerateEdit = ()=>{
    if (AddEditBracingTool.isBracingEditable == true) {
          if (bracingType != "--Select--" && bracingType != "") {
              if (mergeState == "up") {
                  setMergeState("");
                generateBracing(bracingType, "up");                        
              }
              if (mergeState == "down") {
                  setMergeState("");
                generateBracing(bracingType, "down");
                  
              }
              if (mergeState == "split") {
                  setMergeState("");
              generateBracing(bracingType, "split");
              }
              if (mergeState == "") {
                 generateBracing(bracingType);
              }
          }

          if ((bracingType == "--Select--" || bracingType == "") && mergeState == "split") {
              setMergeState("");
             generateBracing("getcurrent", "split");
          }
          if ((bracingType == "--Select--"|| bracingType == "") && mergeState == "up") {
              setMergeState("");
             generateBracing("getcurrent", "up");
          }
          if ((bracingType == "--Select--"|| bracingType == "") && mergeState == "down") {
              setMergeState("");
              generateBracing("getcurrent", "down");
          }
          else if (bracingType == "--Select--" || bracingType == "") {
              setMergeState("");
              IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Warning, "No Pattern Selected , Auto Detecting", "", OutputMessageType.Toast));
          }
      
  }
  }
  
  const onFieldUpdate = async(event) => {
    event.persist()
    const [typeId, target] = event.target.tagName != "SPAN" ? event.target.id.split("@") : event.target.parentElement.id.split("@");
    const temp: pecGridStructure = {...bracingsEditData};
    let tempTopVal: any, tempBotVal: any;
    const handleElevation = (topEl, botEl)=>{
      //  return {topEl, botEl}
      tempTopVal = temp.rows[1].columns[1].value;
      tempBotVal = temp.rows[2].columns[1].value;
      if(topEl){
        const topph = (topEl.height - AddEditBracingTool.fdata["tower-structure"]["base_altitude"]).toFixed(4);
        tempTopVal.currentValue =  parseFloat(getValueInRequiredUnit( topph)) ;

      } 
      if(botEl){
        const topph = (botEl.height - AddEditBracingTool.fdata["tower-structure"]["base_altitude"]).toFixed(4);
        tempBotVal.currentValue = parseFloat(getValueInRequiredUnit( topph)) ;
      } 
      setBracingsEditData(temp);
    }
    
    switch (target) {
      case "topElButton":
        let b: BracingCommsI = ({
            topElevationFlag: true,
            bottomElevationFlag: undefined,
            bracingType: bracingsEditData.rows[0].columns[1].value as string
        })
        let resDeco : any= await IModelApp.tools.run(AddEditBracingTool.toolId,b, handleElevation);
        break;
      case "botElButton":
        
        let botBrac: BracingCommsI = ({
          topElevationFlag: undefined,
          bottomElevationFlag: true,
          bracingType: bracingType
          })
      let resDec:any =await IModelApp.tools.run(AddEditBracingTool.toolId, botBrac, handleElevation);
 
          break;
       
      default:
        break;
    }
    if(event.target.innerText == "Merge Up"){
      let rowItem :any= temp.rows[5].columns[1].styles
      let otherColumn = {parentClass: "mb-2", childClass: "BracingEdit-button-width"}
      rowItem = {...temp.rows[5].columns[1].styles,childClass:"dialogBox-button-selected BracingEdit-button-width"}
      temp.rows[5].columns[1].styles = mergeState == "up"?otherColumn: rowItem ;
      temp.rows[6].columns[1].styles = otherColumn 
      temp.rows[7].columns[1].styles = otherColumn 
      mergeState == "up"?setMergeState(""): setMergeState("up")
    }
    if(event.target.innerText == "Merge Down"){
      let rowItem :any= temp.rows[5].columns[1].styles
      let otherColumn = {parentClass: "mb-2", childClass: "BracingEdit-button-width"}
      rowItem = {...temp.rows[5].columns[1].styles,childClass:"dialogBox-button-selected BracingEdit-button-width"}
      temp.rows[6].columns[1].styles = mergeState == "down"?otherColumn: rowItem ;
      temp.rows[7].columns[1].styles = otherColumn 
      temp.rows[5].columns[1].styles = otherColumn 
      mergeState == "down"?setMergeState(""):setMergeState("down")
    }
    if(event.target.innerText == "Split"){
      let rowItem :any= temp.rows[5].columns[1].styles
      let otherColumn = {parentClass: "mb-2", childClass: "BracingEdit-button-width"}
      rowItem = {...temp.rows[5].columns[1].styles,childClass:"dialogBox-button-selected BracingEdit-button-width"}
      temp.rows[7].columns[1].styles = mergeState == "split"?otherColumn: rowItem ;
      temp.rows[5].columns[1].styles = otherColumn 
      temp.rows[6].columns[1].styles = otherColumn 
      mergeState == "split"?setMergeState(""):setMergeState("split")
    }
    setBracingsEditData(temp);

  }
  
  const onFieldChange = async (event, inputId: string) => {
    const temp: pecGridStructure = {...bracingsEditData};
    const eventType = event.constructor.name;
    if((eventType == "SyntheticBaseEvent") || (typeof event === 'object' && event !== null)){
      const {id: theId, name: theName, value: theValue} = event.target;
      const [inputType, rowIndex, columnIndex] = theId.split("@")[0].split('-');
      switch (inputType) {
        case pecInputTagTypes.SELECT:
          // temp.rows[rowIndex].columns[columnIndex].value = temp.rows[rowIndex].columns[columnIndex].data![theValue];
          temp.rows[rowIndex].columns[columnIndex].value = theValue;
          if(rowIndex == 0 && columnIndex == 1 && theValue !="--Select--"){
            const subTypeList = bracingPatternList.filter(bpl=>bpl.label == theValue)[0].subType.map(pst=>({name: pst, label: pst}));
            subTypeList.unshift({name: "--Select--", label: "--Select--"});
            temp.rows[0].columns[3].data = subTypeList;
          }else if(rowIndex == 0 && columnIndex == 3){
            temp.rows[rowIndex].columns[columnIndex].value = theValue;
            setBracingType(theValue)
          }
          break;
        case pecInputTagTypes.CHECKBOX:
          temp.rows[rowIndex].columns[columnIndex].value = !temp.rows[rowIndex].columns[columnIndex].value;
          break;
        case pecInputTagTypes.SWITCH:
          if(theId.split("@")[1] == "HorizontalMidBaySwitch")AddEditBracingTool.ismhChecked = event.target.checked
          if(theId.split("@")[1] == "HorizontalTopBaySwitch")AddEditBracingTool.isthChecked = event.target.checked
          if(theId.split("@")[1] == "CalculateProximitySwitch")AddEditBracingTool.isProximity = event.target.checked
          temp.rows[rowIndex].columns[columnIndex].value = !temp.rows[rowIndex].columns[columnIndex].value
        default:
          break;
      }
      
      
    } else {    
      switch (eventType) {
        case "Number":
          const [inputType, rowIndex, columnIndex] = inputId.split("@")[0].split('-');
          temp.rows[rowIndex].columns[columnIndex].value.currentValue = event;
          if(inputId.split("@")[1]=="topElVal") setTopElevationFromInputBox(temp.rows[rowIndex].columns[columnIndex].value.currentValue - parseFloat(getValueInRequiredUnit(props.concreteHeight)))
          else if(inputId.split("@")[1]=="botElVal")setBottomElevationFromInputBox(temp.rows[rowIndex].columns[columnIndex].value.currentValue -parseFloat(getValueInRequiredUnit(props.concreteHeight)))
        break;
        
        default:
          break;
      }
    }
    setBracingsEditData(temp);
  }
  
  const onFieldClick = (event) => {
    let currentEvent = event;

  }
  const setTopElevationFromInputBox = (inputValue: number) => {
    if (AddEditBracingTool.fdata != undefined) {
        let alt = AddEditBracingTool.fdata["tower-structure"]["base_altitude"];
        AddEditBracingTool.topPoint = { height: inputValue }

        let p: number = 0;
        if (getUnit() == "(ft)") {
            let v = convertFeetToMeter(inputValue);
            p = (v + parseFloat(alt));
        }
        if (getUnit() == "(m)") {
            let v = inputValue;
            p = (v + alt);
        }
        AddEditBracingTool.topPoint = { height: p };
    }
}
const generateBracing = async(bracingType: string, merge?:string) =>{
  //------------------------------------------------------------------------------------
  //NORMAL SCENARIO
  //------------------------------------------------------------------------------------
  if (AddEditBracingTool.isBracingEditable == false) {
      if (AddEditBracingTool.topPoint && AddEditBracingTool.bottomPoint && bracingType) {
          AddEditBracingTool.generateBracing(AddEditBracingTool.topPoint, AddEditBracingTool.bottomPoint, bracingType, AddEditBracingTool.isthChecked, AddEditBracingTool.ismhChecked);
      }
      else {
          IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Error, "Data Incomplete , Please Complete User Inputs", "", OutputMessageType.Toast));
      }
      return "new Bracing"
  }
  //------------------------------------------------------------------------------------
  //WHEN EDIT IS ENABLED
  //------------------------------------------------------------------------------------
  else
  {
      if (merge == "up") {
          if (bracingType == "getcurrent") {
              const bdec = IModelApp.viewManager.decorators.filter(e => e.constructor.name.includes("BracingDecorator"))[0] as BracingDecorator;
              AddEditBracingTool.generateMergedBracing(bdec.selectedBracings.modelData.pattern, "up");
          } else {
              AddEditBracingTool.generateMergedBracing(bracingType, "up");
          }
          return "mergedUp";//exit
      }
      if (merge == "down") {
          if (bracingType == "getcurrent") {
              const bdec = IModelApp.viewManager.decorators.filter(e => e.constructor.name.includes("BracingDecorator"))[0] as BracingDecorator;
              AddEditBracingTool.generateMergedBracing(bdec.selectedBracings.modelData.pattern, "down");
          } else {
              AddEditBracingTool.generateMergedBracing(bracingType, "down");
          }

          return "mergedDown";//exit
      }
      if (merge == "split") {
          if (bracingType == "getcurrent") {
              const bdec = IModelApp.viewManager.decorators.filter(e => e.constructor.name.includes("BracingDecorator"))[0] as BracingDecorator;
              AddEditBracingTool.splitBracing(bdec.selectedBracings.modelData.pattern);
          } else {
              AddEditBracingTool.splitBracing(bracingType);
          }

          return "split";//exit
      }
      //------------------------------------------------------------------------------------
      const bDecor = IModelApp.viewManager.decorators.filter(e => e.constructor.name.includes("BracingDecorator"))[0] as BracingDecorator;
      if (bDecor != undefined  && bDecor.selectedBracings != undefined && bDecor.selectedBracings.modelData != undefined) {
          let be:any = bracingsEditData.rows[2].columns[1].value;

          if (be && be.hasOwnProperty("currentValue")) {
              let p: number = 0;
              if (getUnit() == "(ft)") {
                  let v = convertFeetToMeter(parseFloat(be.currentValue));
                  p = (v + parseFloat(bDecor.selectedBracings.modelData.baseAltitude));
              }
              if (getUnit() == "(m)") {
                  let v = parseFloat(be.currentValue);
                  p = (v + bDecor.selectedBracings.modelData.baseAltitude);
              }
              AddEditBracingTool.bottomPoint = { height: p };
          }

          let te : any = bracingsEditData.rows[1].columns[1].value;
          if (te && te.hasOwnProperty("currentValue")) {
              let p: number = 0;
              if (getUnit() == "(ft)") {
                  let v = convertFeetToMeter(parseFloat(te.currentValue));
                  p = (v + parseFloat(bDecor.selectedBracings.modelData.baseAltitude));
              }
              if (getUnit() == "(m)") {
                  let v = parseFloat(te.currentValue);
                  p = (v + parseFloat(bDecor.selectedBracings.modelData.baseAltitude));
              }
              AddEditBracingTool.topPoint = { height: p };
          }
      } else {
          IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Warning, "No Bracing Selected Please Try Again", "", OutputMessageType.Toast));
      }
      //------------------------------------------------------------------------------------
      let th :any =  bracingsEditData.rows[4].columns[1]
      if (th) {
          AddEditBracingTool.isthChecked = th.value;
      }
      let mh :any= bracingsEditData.rows[3].columns[1]
      if (mh) {
          AddEditBracingTool.ismhChecked = mh.value;
      }   
      if (AddEditBracingTool.topPoint != undefined && AddEditBracingTool.bottomPoint != undefined && bracingType != undefined && AddEditBracingTool.isthChecked != undefined && AddEditBracingTool.ismhChecked != undefined) {
          //---Call To Generate
          AddEditBracingTool.generateBracingOnEdit(AddEditBracingTool.topPoint, AddEditBracingTool.bottomPoint, bracingType, AddEditBracingTool.isthChecked, AddEditBracingTool.ismhChecked);
          //---
      }
      else {
          IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Error, "Data Incomplete , Please Complete User Inputs", "", OutputMessageType.Toast));
      }
      return "edit Bracing"
  }
}

const setBottomElevationFromInputBox = (inputValue : number) => {
  if (AddEditBracingTool.fdata != undefined) {
      let alt = AddEditBracingTool.fdata["tower-structure"]["base_altitude"];
      AddEditBracingTool.bottomPoint = { height: inputValue }

      let p: number = 0;
      if (getUnit() == "(ft)") {
          let v = convertFeetToMeter(inputValue);
          p = (v + parseFloat(alt));
      }
      if (getUnit() == "(m)") {
          let v = inputValue;
          p = (v + alt);
      }
      AddEditBracingTool.bottomPoint = { height: p };
  }
}
  
  let modalBtns: pecTableButton[] = 
  [
    {name: "Preview", value: "preview", class: "dialogBox-save-btn", disable: false, onInteract: onModalBtnClick},
    {name: "Save", value: "save", class: "dialogBox-cancel-btn", disable: false, onInteract: onModalBtnClick},
    {name: "Cancel", value: "cancel", class: "dialogBox-cancel-btn", disable: false, onInteract: onModalBtnClick},
  ];
  
  
  let bracingsEditorStructure: pecComponentProps = {
    structureType: pecStrucType.STRUCTURE,
    title: {
      label: "Bracing Edit",
      // styles: undefined
    },
    grid: bracingsEditData,
    gridButtons: modalBtns,
    eventHandlers: {
      onChange: onFieldChange,
      onUpdate: onFieldUpdate,
      onMouseUp: onFieldUpdate,
      onClick: onFieldClick
    }
  }


  return (
    <PropertyEditorComponent {...bracingsEditorStructure} />
  )
}

let typeOfWorkflow = "";
let closeFunc :any;
let selectedBracings ;
const mapStateToProps = (state: RootState, compProps) => {
  let bDecor = IModelApp.viewManager.decorators.filter(e => e.constructor.name.includes("BracingDecorator"))[0] as BracingDecorator;
  if(compProps.title.includes("Edit") && bDecor && bDecor.selectedBracings && bDecor.selectedBracings.modelData && bDecor.selectedBracings.modelData.type == "Plan Bracing"){
    UiFramework.dialogs.modeless.close("Edit Dialog")
    return;
  }
  const towerdata = state.detectedData.towerStructureData;
  const concreteHeight = ConfigManager.AGL ? Object.entries(towerdata).filter((f) => f[0] === "concrete_height")[0][1] : 0;

  AddEditBracingTool.runFetchData()
  AddEditBracingTool.isProximity = true;
  const mode = compProps.title.includes('Add') ? "Add" : "Edit";
  typeOfWorkflow = mode;
  closeFunc =  compProps
  const selObjInfo = state.detectedData.selectedObjectInformation;
  const mainPatternType = bracingPatternList.map(e=>({label: e.label, value: e.label}));
  mainPatternType.unshift({label: "--Select--", value: "--Select--"});
  
  let tH="0", bH="0", subTypeList: {name: string, label: string}[]=[], patternTypeValue = "--Select--", horizTopBay = "No", horizMiddleBay = "No", calculateProximity = "Yes";
  if(mode == "Edit"){
    // const selPipe = bDecor.pipes.filter(e=>e.name = selObjInfo.objectProperties.selectedObjectNST.name)[0];
  if(bDecor && bDecor.selectedBracings){
    selectedBracings = bDecor.selectedBracings;
    const selPipe = bDecor.selectedBracings;
    const topph = (bDecor.selectedBracings.modelData.parentBracingHeights.bracingTopH - bDecor.selectedBracings.modelData.baseAltitude + concreteHeight).toFixed(4);
    const botth = (bDecor.selectedBracings.modelData.parentBracingHeights.bracingBotH - bDecor.selectedBracings.modelData.baseAltitude + concreteHeight).toFixed(4);
    tH = `${getValueInRequiredUnit(topph)}`;
    bH = `${getValueInRequiredUnit(botth)}`;
    const typeInList = bracingPatternList.filter(bpl=>bpl.label == selPipe.modelData.pattern);
    if(typeInList.length)subTypeList = typeInList[0].subType.map(pst=>({name: pst, label: pst}));
    else bracingPatternList.push({name: selPipe.modelData.pattern.toLowerCase(), label: UCFirst(selPipe.modelData.pattern), subType: []})
    patternTypeValue = selPipe.modelData.pattern.toUpperCase();
    horizMiddleBay = selPipe.modelData.horizMiddleBay;
    horizTopBay = selPipe.modelData.horizTopBay;
    calculateProximity = "Yes";
  }
  }  
if(mode === "Add"){
  AddEditBracingTool.ismhChecked = false;
  AddEditBracingTool.isthChecked = false;
 }
  subTypeList.unshift({name: "--Select--", label: "--Select--"});
  
  const bracingsMenuStruc: pecGridStructure = {
    rows: [
      {
        columns: [
          {
            size: 2,
            type: pecInputTagTypes.LABEL,
            customClass: "",
            value: "Pattern",
            disabled: false,
            name: "Pattern",
            fieldType: pecDataValueTypes.INTEGER,
            modified: false,
            styles: {parentClass: "", childClass: ""}
          },
          {
            size: 3,
            type: pecInputTagTypes.SELECT,
            customClass: "",
            value: patternTypeValue,
            disabled: false,
            name: "pattern-input-1",
            fieldType: pecDataValueTypes.INTEGER,
            modified: false,
            styles: {parentClass: "", childClass: "dialogBox-propEditorComp-dropdown"},
            data: mainPatternType
          },      
          {
            size: 3,
            type: pecInputTagTypes.LABEL,
            customClass: "",
            value: "Sub-Pattern",
            disabled: false,
            name: "Sub-Pattern",
            fieldType: pecDataValueTypes.INTEGER,
            modified: false,
            styles: {parentClass: "", childClass: ""}
          },
          {
            size: 4,
            type: pecInputTagTypes.SELECT,
            customClass: "",
            value: "--Select--",
            disabled: false,
            name: "subPattern-input-2",
            fieldType: pecDataValueTypes.INTEGER,
            modified: false,
            styles: {parentClass: "", childClass: "dialogBox-propEditorComp-dropdown"},
            data: subTypeList.length ? subTypeList : [{label: "--Select--", value: "--Select--"}]
          },      
        ]
        
      },
      {
        columns: [
          {
            size: 6,
            type: pecInputTagTypes.LABEL,
            customClass: "",
            value: `Top Elevation ${getUnit()} *`,
            disabled: false,
            name: "topEl",
            fieldType: pecDataValueTypes.INTEGER,
            modified: false,
            styles: {parentClass: "mt-2", childClass: ""}
          },
          {
            size: 4,
            type: pecInputTagTypes.NUMERIC_INPUT,
            customClass: "",
            value: { currentValue: parseFloat(tH), min: -1000, max: 1000, precision: 2, step: 0.05 },
            disabled: false,
            name: "topElVal",
            fieldType: pecDataValueTypes.INTEGER,
            modified: false,
            styles: {parentClass: "mt-2", childClass: " "}
          },      
          {
            size: 2,
            type: pecInputTagTypes.BUTTON,
            customClass: "",
            value: "L",
            disabled: false,
            name: "topElButton",
            fieldType: pecDataValueTypes.INTEGER,
            modified: false,
            styles: {parentClass: "mt-2", childClass: " "}
          },      
        ]
        
      },
      {
        columns: [
          {
            size: 6,
            type: pecInputTagTypes.LABEL,
            customClass: "",
            value: `Bottom Elevation ${getUnit()} *`,
            disabled: false,
            name: "botEl",
            fieldType: pecDataValueTypes.INTEGER,
            modified: false,
            styles: {parentClass: "mt-2 mb-2", childClass: ""}
          },
          {
            size: 4,
            type: pecInputTagTypes.NUMERIC_INPUT,
            customClass: "",
            value: { currentValue: parseFloat(bH), min: -1000, max: 1000, precision: 2, step: 0.05 },
            disabled: false,
            name: "botElVal",
            fieldType: pecDataValueTypes.INTEGER,
            modified: false,
            styles: {parentClass: "mt-2 mb-2", childClass: " "}
          },      
          {
            size: 2,
            type: pecInputTagTypes.BUTTON,
            customClass: "",
            value: "L",
            disabled: false,
            name: "botElButton",
            fieldType: pecDataValueTypes.INTEGER,
            modified: false,
            styles: {parentClass: "mt-2 mb-2", childClass: " "}
          },      
        ]
        
      },
      {
        columns: [
          {
            size: 8,
            type: pecInputTagTypes.LABEL,
            customClass: "",
            value: "Horizontal Mid Bay",
            disabled: false,
            name: "hmb",
            fieldType: pecDataValueTypes.INTEGER,
            modified: false,
            styles: {parentClass: "", childClass: ""}
          },
          {
            size: 4,
            type: pecInputTagTypes.SWITCH,
            customClass: "",
            value: horizMiddleBay == "Yes",
            disabled: false,
            name: "Horizontal Mid Bay Switch",
            fieldType: pecDataValueTypes.BOOLEAN,
            modified: false,
            styles: {parentClass: "mb-2", childClass: " "},
          },      
        ]
        
      },
      {
        columns: [
          {
            size: 8,
            type: pecInputTagTypes.LABEL,
            customClass: "",
            value: "Horizontal Top Bay",
            disabled: false,
            name: "htb",
            fieldType: pecDataValueTypes.INTEGER,
            modified: false,
            styles: {parentClass: "", childClass: ""}
          },
          {
            size: 4,
            type: pecInputTagTypes.SWITCH,
            customClass: "",
            value: horizTopBay == "Yes",
            disabled: false,
            name: "Horizontal Top Bay Switch",
            fieldType: pecDataValueTypes.BOOLEAN,
            modified: false,
            styles: {parentClass: "mb-2", childClass: ""},
          },
        ]
        
      },
      {
        columns: [
          {
            size: 8,
            type: pecInputTagTypes.LABEL,
            customClass: "",
            value: "Merge Up",
            disabled: false,
            name: "Merge Up",
            fieldType: pecDataValueTypes.INTEGER,
            modified: false,
            styles: {parentClass: "", childClass: ""}
          },
          {
            size: 4,
            type: pecInputTagTypes.BUTTON,
            customClass: "",
            value: "Merge Up",
            disabled: false,
            name: "mergeUp",
            fieldType: pecDataValueTypes.BOOLEAN,
            modified: false,
            styles: {parentClass: "mb-2", childClass: "BracingEdit-button-width"},
          },
        ]
        
      },
      {
        columns: [
          {
            size: 8,
            type: pecInputTagTypes.LABEL,
            customClass: "",
            value: "Merge Down",
            disabled: false,
            name: "mergeDown",
            fieldType: pecDataValueTypes.INTEGER,
            modified: false,
            styles: {parentClass: "", childClass: ""}
          },
          {
            size: 4,
            type: pecInputTagTypes.BUTTON,
            customClass: "",
            value: "Merge Down",
            disabled: false,
            name: "mergeDown",
            fieldType: pecDataValueTypes.BOOLEAN,
            modified: false,
            styles: {parentClass: "mb-2", childClass: "BracingEdit-button-width"},
          },
        ]
        
      },
      {
        columns: [
          {
            size: 8,
            type: pecInputTagTypes.LABEL,
            customClass: "",
            value: "Split",
            disabled: false,
            name: "Split",
            fieldType: pecDataValueTypes.INTEGER,
            modified: false,
            styles: {parentClass: "", childClass: ""}
          },
          {
            size: 4,
            type: pecInputTagTypes.BUTTON,
            customClass: "",
            value: "Split",
            disabled: false,
            name: "split",
            fieldType: pecDataValueTypes.BOOLEAN,
            modified: false,
            styles: {parentClass: "mb-2", childClass: "BracingEdit-button-width"},
          },
        ]
        
      },
      {
        columns: [
          {
            size: 8,
            type: pecInputTagTypes.LABEL,
            customClass: "",
            value: "Connect Nearest Bracing",
            disabled: false,
            name: "cp",
            fieldType: pecDataValueTypes.INTEGER,
            modified: false,
            styles: {parentClass: "", childClass: ""}
          },
          {
            size: 4,
            type: pecInputTagTypes.SWITCH,
            customClass: "",
            value: calculateProximity == "Yes",
            disabled: false,
            name: "Calculate Proximity Switch",
            fieldType: pecDataValueTypes.BOOLEAN,
            modified: false,
            styles: {parentClass: "mb-2", childClass: ""},
          },      
        ]
      },
    ]
  }

  const newBracingMenuStructure : pecGridStructure = {
    rows: [
      {
        columns: [
          {
            size: 2,
            type: pecInputTagTypes.LABEL,
            customClass: "",
            value: "Pattern",
            disabled: false,
            name: "Pattern",
            fieldType: pecDataValueTypes.INTEGER,
            modified: false,
            styles: {parentClass: "", childClass: ""}
          },
          {
            size: 3,
            type: pecInputTagTypes.SELECT,
            customClass: "",
            value: patternTypeValue,
            disabled: false,
            name: "pattern-input-1",
            fieldType: pecDataValueTypes.INTEGER,
            modified: false,
            styles: {parentClass: "", childClass: "dialogBox-propEditorComp-dropdown"},
            data: mainPatternType
          },      
          {
            size: 3,
            type: pecInputTagTypes.LABEL,
            customClass: "",
            value: "Sub-Pattern",
            disabled: false,
            name: "Sub-Pattern",
            fieldType: pecDataValueTypes.INTEGER,
            modified: false,
            styles: {parentClass: "", childClass: ""}
          },
          {
            size: 4,
            type: pecInputTagTypes.SELECT,
            customClass: "",
            value: "--Select--",
            disabled: false,
            name: "subPattern-input-2",
            fieldType: pecDataValueTypes.INTEGER,
            modified: false,
            styles: {parentClass: "", childClass: "dialogBox-propEditorComp-dropdown"},
            data: subTypeList.length ? subTypeList : [{label: "--Select--", value: "--Select--"}]
          },      
        ]
        
      },
      {
        columns: [
          {
            size: 6,
            type: pecInputTagTypes.LABEL,
            customClass: "",
            value: "Top Elevation *",
            disabled: false,
            name: "topEl",
            fieldType: pecDataValueTypes.INTEGER,
            modified: false,
            styles: {parentClass: "mt-2", childClass: ""}
          },
          {
            size: 4,
            type: pecInputTagTypes.NUMERIC_INPUT,
            customClass: "",
            value: { currentValue: parseFloat(tH), min: -1000, max: 1000, precision: 2, step: 0.05 },
            disabled: false,
            name: "topElVal",
            fieldType: pecDataValueTypes.INTEGER,
            modified: false,
            styles: {parentClass: "mt-2", childClass: " "}
          },      
          {
            size: 2,
            type: pecInputTagTypes.BUTTON,
            customClass: "",
            value: "L",
            disabled: false,
            name: "topElButton",
            fieldType: pecDataValueTypes.INTEGER,
            modified: false,
            styles: {parentClass: "mt-2", childClass: " "}
          },      
        ]
        
      },
      {
        columns: [
          {
            size: 6,
            type: pecInputTagTypes.LABEL,
            customClass: "",
            value: "Bottom Elevation *",
            disabled: false,
            name: "botEl",
            fieldType: pecDataValueTypes.INTEGER,
            modified: false,
            styles: {parentClass: "mt-2 mb-2", childClass: ""}
          },
          {
            size: 4,
            type: pecInputTagTypes.NUMERIC_INPUT,
            customClass: "",
            value: { currentValue: parseFloat(bH), min: -1000, max: 1000, precision: 2, step: 0.05 },
            disabled: false,
            name: "botElVal",
            fieldType: pecDataValueTypes.INTEGER,
            modified: false,
            styles: {parentClass: "mt-2 mb-2", childClass: " "}
          },      
          {
            size: 2,
            type: pecInputTagTypes.BUTTON,
            customClass: "",
            value: "L",
            disabled: false,
            name: "botElButton",
            fieldType: pecDataValueTypes.INTEGER,
            modified: false,
            styles: {parentClass: "mt-2 mb-2", childClass: ""}
          },      
        ]
        
      },
      {
        columns: [
          {
            size: 8,
            type: pecInputTagTypes.LABEL,
            customClass: "",
            value: "Horizontal Mid Bay",
            disabled: false,
            name: "hmb",
            fieldType: pecDataValueTypes.INTEGER,
            modified: false,
            styles: {parentClass: "", childClass: ""}
          },
          {
            size: 4,
            type: pecInputTagTypes.SWITCH,
            customClass: "",
            value: horizMiddleBay == "Yes",
            disabled: false,
            name: "Horizontal Mid Bay Switch",
            fieldType: pecDataValueTypes.BOOLEAN,
            modified: false,
            styles: {parentClass: "mb-2", childClass: ""},
          },      
        ]
        
      },
      {
        columns: [
          {
            size: 8,
            type: pecInputTagTypes.LABEL,
            customClass: "",
            value: "Horizontal Top Bay",
            disabled: false,
            name: "htb",
            fieldType: pecDataValueTypes.INTEGER,
            modified: false,
            styles: {parentClass: "", childClass: ""}
          },
          {
            size: 4,
            type: pecInputTagTypes.SWITCH,
            customClass: "",
            value: horizTopBay == "Yes",
            disabled: false,
            name: "Horizontal Top Bay Switch",
            fieldType: pecDataValueTypes.BOOLEAN,
            modified: false,
            styles: {parentClass: "mb-2", childClass: ""},
          },
        ]
        
      },
      {
        columns: [
          {
            size: 8,
            type: pecInputTagTypes.LABEL,
            customClass: "",
            value: "Connect Nearest Bracing",
            disabled: false,
            name: "cp",
            fieldType: pecDataValueTypes.INTEGER,
            modified: false,
            styles: {parentClass: "", childClass: ""}
          },
          {
            size: 4,
            type: pecInputTagTypes.SWITCH,
            customClass: "",
            value: calculateProximity == "Yes",
            disabled: false,
            name: "Calculate Proximity Switch",
            fieldType: pecDataValueTypes.BOOLEAN,
            modified: false,
            styles: {parentClass: "mb-2", childClass: ""},
          },      
        ]
      },
    ]
  }
let allState = state;
  const retVal = {
    bracingsMenuStruc:typeOfWorkflow == "Add"?newBracingMenuStructure:bracingsMenuStruc,
    concreteHeight
  };

  retVal.bracingsMenuStruc.rows.push({
    columns:[
      {
        size: 12,
        type: pecInputTagTypes.LABEL,
        customClass: "",
        value: ConfigManager.AGL ? "*: Above ground level (AGL) is enabled and applied." : "*: Above ground level (AGL) is disabled.",
        disabled: false,
        name: "agl",
        fieldType: pecDataValueTypes.INTEGER,
        modified: false,
        styles: {parentClass: "agl-note", childClass: ""}
      }
    ]
  })
  return retVal;
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(BracingsEditHandler);