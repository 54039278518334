import { IModelApp, Tool, StartOrResume, ScreenViewport, NotifyMessageDetails, OutputMessageType, OutputMessagePriority } from "@itwin/core-frontend";
import { SvgArrowUp, SvgArrowLeft, SvgArrowRight, SvgArrowDown, SvgCaretUp, SvgCaretDown, SvgMove, SvgSearch, SvgSync, SvgCheckmark } from "@itwin/itwinui-icons-react";
import React, { useEffect, useRef, useState } from "react";
import { EquipmentsTable, ManModels, equipmentModel } from "../components/Tables/EquipmentsTable";
import { Badge, Button, Checkbox, HorizontalTabs, InputGroup, LabeledInput, LabeledSelect, MenuItem, Select, Slider, Tab, Tabs, Text, ToggleSwitch, Tooltip } from "@itwin/itwinui-react";
import { RootState } from "../../store/States";
import { connect } from "react-redux";
import { Vector3d } from "@itwin/core-geometry";
import { EquipmentRotationWidget } from "./widgets/EquipmentRotationWidget";
import { EquipmentInformation, EquipmentType, unitSystemType } from "../../store/redux-types";
import { EquipmentRotationType } from "./DetectedEquipmentEditHandler";
import { UiFramework, WidgetState } from "@itwin/appui-react";
import { Id64String } from "@itwin/core-bentley";
import { store } from "../../store/rootReducer";
import { DTVActions } from "../../store/Actions";
import { resetObjectIds } from "../components/HorizontalToolbarItems";
import { ModifyHandleDecoration } from "../components/tools/modification/ModifyHandleDecoration";
import EquipmentClient, { createEquipment, saveEquipment } from "../api/equipment";
import { fetchEquipmentData } from "./GetDetectedObjectsData";
import Place3DObjectApi from "../components/tools/Place3DObjectApi";
import { DetectedGeometryType, PlacementMode, PlacementTool } from "../components/tools/PlacementTool";
import { ShapeType } from "./decorators/EquipmentShapeDecorator";
import { ShapeDecorator, CreateGeometryMode, EquipmentData, select } from "./decorators/ShapeDecorator";
import { setSiteCoordinate } from "../../store/detectedData/apiDataActionTypes";
import { ObjectCategoryTypes } from "../../store/detectedData/apiDataTypes";
import { InstanceKey, Key, KeySet, KeySetJSON } from "@itwin/presentation-common";
import { getManEquipType } from "../components/GetEquipmentProperties";
import { getUnit, getValueInRequiredUnit } from "../components/Tables/BaseTable";
import { ReduceDecimalNumberToFourPlaces, ReduceDecimalNumberToTwoPlaces } from "../components/Lists/EquipmentList";
import { AppSettings } from "../../config/AppSettings";
import { ConfigManager } from "../../config/ConfigManager";

export interface position3D {x: number, y: number, z: number}
export interface EquipmentEditProperties {
  DisplayName: string;
  Type: string;
  Manufacturer: string;
  Model: string;
  Height: number,
  Width: number,
  Depth: number,
  Azimuth: number,
  DownTilt: number,
  Roll: number,
  ShapeId: number|undefined,
  Position: position3D,
  Elevation: number,
  Modified: boolean,
  Active: boolean,
}

const equipDefProps: EquipmentEditProperties = {
  DisplayName: "",
  Type: "Antenna",
  Manufacturer: "",
  Model: "",
  Height: 0,
  Width: 0,
  Depth: 0,
  Azimuth: 0,
  DownTilt: 0,
  Roll: 0,
  ShapeId: undefined,
  Position: { x: 0, y: 0, z: 0 },
  Elevation: 0,
  Active: true,
  Modified: false
}

export enum DimensionMode {
  Custom = "Custom",
  Model = "Model"
}

export const getTypedManModelList = (equipmentType: string)=>{
  const selEquipType = getManEquipType(equipmentType)
  return  EquipmentsTable.formattedEquipmentManModels.manModels.filter(e=>e.equipmentTypes.find(et=>et==selEquipType));
}

export const getSearchedManModelList = (typeFilteredManModelList: ManModels[], searchString: string)=>{
  const matchingModels: ManModels[] = [];
  typeFilteredManModelList.filter(avamm=>{
    avamm.models.forEach((m: equipmentModel)=>{
      if(m.name.toUpperCase().match(searchString.toUpperCase())){
        const theIndex = matchingModels.findIndex(tm=>tm.manufacturer == avamm.manufacturer);
        if(theIndex == -1)matchingModels.push({manufacturer: avamm.manufacturer, equipmentTypes: [m.type],  models: [m]});
        else {
          const typeIndex = matchingModels[theIndex].equipmentTypes.findIndex(et=>et == m.type);
          if(typeIndex == -1)matchingModels[theIndex].equipmentTypes.push(m.type);
          matchingModels[theIndex].models.push(m);
        }
      }});//.filter(e=>e);
  });
  return matchingModels;
}

export interface buttonState {label: string, disabled: boolean};
const CustomEquipmentHandler: React.FunctionComponent = (cehProps: any) => {
    let timerRef = useRef<NodeJS.Timer>();
    const allAvailableAntManModel: ManModels[] = EquipmentsTable.formattedEquipmentManModels.manModels;
    const equipmentList = cehProps?.equipmentList?.length ? cehProps.equipmentList : [];
    const manList = allAvailableAntManModel.map((mm, mmId)=>({label: mm.manufacturer, value: mmId+1}));
    const typeFilteredManModelList = getTypedManModelList(cehProps?.equipCurrJson?.Type);
    const theManList = typeFilteredManModelList.map((mm, mmId)=>({label: mm.manufacturer, value: mmId+1}));
    theManList.unshift({label: "Select", value: 0});

    manList.unshift({label: "Select", value: 0});
    const sm = typeFilteredManModelList[0];
    const models = sm ? sm.models.map((mm, mmId)=>({label: mm.name, value: mmId+1})) : [];
    models.unshift({label: "Select", value: 0});
    const disable = (IModelApp.toolAdmin.activeTool as PlacementTool)?.toolId === "PlacementTool";
    
    const [index, setIndex] = React.useState(0);
    const [dataUpdated, setDataUpdated] = useState<boolean>(false);
    const [equipmentProps, setEquipmentProps] = useState<EquipmentEditProperties>(cehProps?.equipCurrJson);
    const [selEquipName, setSelEquipName] = useState<string>("0");
    const [selectedValue, setSelectedValue] = useState<string>("0");
    const [equipTiltLabel, setEquipTiltLabel] = useState<number>(0);
    const [equipTiltSlider, setEquipTiltSlider] = useState<number>(0);
    const [searchString, setSearchString] = useState<string>("");
    const [siteCoordZ, setSiteCoordZ] = useState<number>(cehProps.siteCoordZ);
    const [selMan, setSelMan] = useState<number>(0);
    const [selModel, setSelModel] = useState<number>(0);
    const [searchModel, setSearchModel] = useState<boolean>(true);
    const [library, setLibrary] = useState<number>(0);
    const [manufacturerList, setManufacturerList] = useState<any[]>(manList);
    const [modelList, setModelList] = useState<any[]>(models);
    const [isDisabled, setIsDisabled] = useState(!disable);
    const [isModified, setIsModified] = useState(false);
    const [resetDisabled, setResetDisabled] = useState(cehProps.addingEquipment);
    const [resetLabel, setResetLabel] = useState<string>("Reset");
    const [saveLabel, setSaveLabel] = useState<string>("Save");
    const [dimensionMode, setDimensionMode] = useState<DimensionMode>(DimensionMode.Model);
    const [saveButton, setSaveButton] = useState<buttonState>({label: cehProps.addingEquipment ? "Add" : "Save", disabled: !cehProps.addingEquipment});
    const [resetButton, setResetButton] = useState<buttonState>({label: cehProps.addingEquipment? "Cancel" : "Reset", disabled: !cehProps.addingEquipment});
    const move = 0.01;
    const ref = React.useRef(null);
    const allManModelList = useRef<ManModels[]>(allAvailableAntManModel);

    // useEffect(()=>{
    //   if((cehProps?.equipCurrJson?.Manufacturer == "UPT:NewEquip" && cehProps?.equipCurrJson?.Model == "UPT:NewEquip-001") || (selMan > 0 && selModel > 0)){
    //       // updateEquipmentIn3D();
    //   }
    // }, [selModel, selMan]);

  
    useEffect(()=>{
      allManModelList.current = allAvailableAntManModel;
      if(cehProps.open && !cehProps.addingEquipment)resetSearchString();
    }, [searchModel]);

  
    useEffect(()=>{
      setEquipmentProps(cehProps.equipCurrJson);
      if(cehProps?.addingEquipment){
        setResetButton({label: "Cancel", disabled: false})
        setSaveButton({label: "Add", disabled: false})
        // setDimensionMode(DimensionMode.Custom);
      } else {
        setResetButton({label: "Reset", disabled: true});
        setSaveButton({label: "Save", disabled: true});
        // setSelEquipName("0");  // Commented this as this was removing the newly added equipment from the Equipment name list.
      }
    }, [cehProps?.addingEquipment]);

  
    useEffect(()=>{
      if(cehProps?.updatedEquipment && !cehProps?.addingEquipment){
        setResetButton({label: "Reset", disabled: false})
        setSaveButton({label: "Save", disabled: false})
      }
    }, [cehProps?.updatedEquipment]);

  
    useEffect(()=>{
      const equipDN = cehProps?.equipCurrJson?.DisplayName;
      if(equipDN){
        setEquipmentProps(cehProps.equipCurrJson);
        setSiteCoordZ(cehProps.siteCoordZ);
        let theDimMode: DimensionMode = DimensionMode.Custom;
        if((cehProps?.equipCurrJson?.Manufacturer == "UPT:NewEquip" && cehProps?.equipCurrJson?.Model == "UPT:NewEquip-001") || (cehProps?.equipCurrJson?.Manufacturer == "XXXXX" && cehProps?.equipCurrJson?.Model == "XXXXX") || (cehProps?.equipCurrJson?.Manufacturer == "" && cehProps?.equipCurrJson?.Model == "") || (cehProps?.equipCurrJson?.Manufacturer == null && cehProps?.equipCurrJson?.Model == null))theDimMode = DimensionMode.Custom;
        else theDimMode = DimensionMode.Model;
        setDimensionMode(theDimMode);

        setSelEquipName(equipmentList.findIndex(e=>e.label==equipDN));
        setSearchString("");
        const typeFilteredManModelList = getTypedManModelList(cehProps.equipCurrJson.Type);
        // allManModelList.current = allAvailableAntManModel;
        allManModelList.current = typeFilteredManModelList;
        const theManList = typeFilteredManModelList.map((mm, mmId)=>({label: mm.manufacturer, value: mmId+1}));
        theManList.unshift({label: "Select", value: 0});
        setManufacturerList(theManList);
        updateManModel(cehProps.equipCurrJson.Manufacturer, cehProps.equipCurrJson.Model, theManList, typeFilteredManModelList);
        if(equipTiltLabel==0)setEquipTiltLabel(-1*cehProps?.equipCurrJson.Tilt);
        setEquipTiltSlider(-1*cehProps?.equipCurrJson.Tilt);
        if(ShapeDecorator.selectedEquipment.isModified){
          setResetButton((resetButton)=>({...resetButton, disabled: false}));
          setSaveButton((saveButton)=>({...saveButton, disabled: false}));
        } else if(!cehProps.addingEquipment){
          setResetButton((resetButton)=>({...resetButton, disabled: true}));
          setSaveButton((saveButton)=>({...saveButton, disabled: true}));
        } else if(cehProps.addingEquipment)setDimensionMode(DimensionMode.Custom);
        // if(widget?.state == WidgetState.Open && !equipEditProps.ShapeId){
          const equipDec = IModelApp.viewManager.decorators.filter((e) => e.constructor.name.includes("ShapeDecorator"))[0] as ShapeDecorator;
          let shapeIndex = equipDec.shapes.findIndex((shape) => shape.modelData?.DisplayName === ShapeDecorator.selectedEquipment.displayName);
          ModifyHandleDecoration.clear();        
          if(ModifyHandleDecoration._decorator && shapeIndex !== -1 && theDimMode == DimensionMode.Custom){
            // if(equipDec.shapes[shapeIndex].modelData?.shapeId == null)
            ModifyHandleDecoration.create(IModelApp.viewManager.selectedView as ScreenViewport, equipDec.shapes[shapeIndex].transientId, equipDN!, shapeIndex, equipDec);
          }
          // if (ModifyHandleDecoration._decorator && shapeIndex != -1 && equipDec.shapes[shapeIndex].modelData?.shapeId == null && theDimMode == DimensionMode.Custom) {
          if (ModifyHandleDecoration._decorator && shapeIndex != -1 && theDimMode == DimensionMode.Custom) {
            ModifyHandleDecoration._decorator._shape = equipDec.shapes[shapeIndex].modelData?.geometryEntry?.geometry;
            ModifyHandleDecoration._decorator._shapeIndex = shapeIndex;
            ModifyHandleDecoration._decorator._shapeName = equipDN;
            ModifyHandleDecoration._decorator.createClipShapeControls();
          } else {
            // ModifyHandleDecoration.clear();
          }
        // }
    
      // } else if(ShapeDecorator.selectedEquipment.creating){
      //   setResetDisabled(true);
      //   setResetButton((resetButton)=>({...resetButton, disabled: true});
      }

    }, [cehProps?.equipCurrJson?.DisplayName]);


    /** Disable input box (radius) if PlacementTool is not active  */
    const toolChangeListener = (tool: Tool, _start: StartOrResume): void => {
      if (tool.toolId === "PlacementTool") {
        setIsDisabled(false);
        return;
      }
      setIsDisabled(true);
    };
  
    const updateEquipmentIn3D = async (theManList = manufacturerList, theManId: number = selMan, theModelList = modelList, theModelId: number = selModel) => {
      const shapeDecorator = IModelApp.viewManager.decorators.filter((e) => e.constructor.name.includes("ShapeDecorator"))[0] as ShapeDecorator;
      let shapeDN = cehProps.equipCurrJson!.DisplayName;
      // if(selMan > 0 && selModel > 0)ShapeDecorator.selectedEquipment.isModified=true;
      const equipmentJson = shapeDecorator.shapes.find((json) => json.modelData?.DisplayName === shapeDN)?.modelData!;
      equipmentJson!.Manufacturer=theManId > 0 ? theManList[theManId].label : "UPT:NewEquip";
      equipmentJson!.Model=theModelId > 0 ? theModelList[theModelId].label : "UPT:NewEquip-001";

      const equipPolygons = EquipmentsTable.equipPolygons;
      const equipPoly = equipPolygons.find(ep=>ep.manufacturer == equipmentJson.Manufacturer && ep.model == equipmentJson.Model);
      if(equipPoly)equipmentJson.shapeId=equipPoly.polygonPointId;
      else equipmentJson.shapeId=undefined;
    
      shapeDecorator.deleteCurrentShape(shapeDN);

      shapeDecorator.createGeometry(equipmentJson!, CreateGeometryMode.New);
    
      IModelApp.viewManager.selectedView?.invalidateDecorations();
      IModelApp.viewManager.selectedView?.invalidateCachedDecorations(shapeDecorator);
            
      resetObjectIds(shapeDecorator);
      
      let shapeIndex = shapeDecorator.shapes.findIndex((shape) => shape.modelData!.DisplayName === shapeDN);
      ShapeDecorator.selectedEquipment.transId = shapeDecorator.shapes[shapeIndex].transientId;
      const iModelConnection = UiFramework.getIModelConnection();
      iModelConnection?.selectionSet.emptyAll();

      if(ModifyHandleDecoration._decorator && shapeIndex !== -1){
        // ModifyHandleDecoration.clear();
        // ModifyHandleDecoration.create(IModelApp.viewManager.selectedView as ScreenViewport, shapeDecorator.shapes[shapeIndex]?.transientId!, equipmentJson.DisplayName!, shapeIndex, shapeDecorator);
      }      
      // if (ModifyHandleDecoration._decorator && shapeDecorator.shapes[shapeIndex].modelData?.shapeId == null) {
      if (ModifyHandleDecoration._decorator) {
        ModifyHandleDecoration._decorator._shape = shapeDecorator.shapes[shapeIndex].modelData?.geometryEntry?.geometry;
        ModifyHandleDecoration._decorator._shapeIndex = shapeIndex;
        ModifyHandleDecoration._decorator._shapeName = equipmentJson.DisplayName;
        ModifyHandleDecoration._decorator.createClipShapeControls();
      } else {
        ModifyHandleDecoration.clear();
      }
    };
  
    const updateManModel = (manufacturer, model, theManList = manufacturerList, theManModels = allManModelList.current): {manIndex: number, modelIndex: number, modeL: any[]} => {
      const selManIndex = theManList.findIndex(e=>e.label==manufacturer);
      const sm = theManModels[selManIndex != -1 ? selManIndex-1 : 0];
      const models = sm != undefined ? sm.models.map((mm, mmId)=>({label: mm.name, value: mmId+1})) : [];
      models.unshift({label: "Select", value: 0});
      setModelList(models);
      setManufacturerList(theManList);
      const selModelIndex = models.findIndex(e=>e.label==model);
      setSelMan(selManIndex != -1 ? selManIndex : 0);
      setSelModel(selModelIndex != -1 ? selModelIndex : 0);
      return {manIndex: selManIndex, modelIndex: selModelIndex, modeL: models};
    };
  
    const onEquipmentNameSelect = async (nameIndex: number) => {
      if(nameIndex > 0){
        const decorator = IModelApp.viewManager.decorators.filter((e) => e.constructor.name.includes("ShapeDecorator"))[0] as ShapeDecorator;
        const equipName = equipmentList[nameIndex].label;
        const shapeIndex = decorator.shapes.findIndex(e=>e.modelData?.DisplayName == equipName);
        const equipment = decorator.shapes[shapeIndex];
        if(shapeIndex != -1 && equipment){
          // if(ShapeDecorator.selectedEquipment.creating || ShapeDecorator.selectedEquipment.created){
            ShapeDecorator.selectedEquipment.displayName = equipName;
            ShapeDecorator.selectedEquipment.transId = equipment.transientId;
          // }
          const iModelConnection = UiFramework.getIModelConnection();
          iModelConnection?.selectionSet.emptyAll();
          iModelConnection?.selectionSet.add(equipment?.transientId as Id64String);
          setSelEquipName(equipmentList.findIndex(e=>e.label==equipName));
        }
      } else setSelEquipName("0");
    };
  
    const onFieldUpdate = async (target, field: string) => {
      const convFactor =  AppSettings.defaultDisplaySettings.unitSystem == unitSystemType.METRIC ? 1 : 1/39.3701;
      const temp: EquipmentEditProperties = JSON.parse(JSON.stringify(equipmentProps));
      let theValue: number|boolean, rotationVal: EquipmentRotationType = EquipmentRotationType.Azimuth;
      const decorator = IModelApp.viewManager.decorators.filter((e) => e.constructor.name.includes("ShapeDecorator"))[0] as ShapeDecorator;
      const shape = decorator.shapes.find(s=>s.modelData?.DisplayName == temp.DisplayName)!;
      let equipData: EquipmentData = shape.modelData!;
      enum UpdateMode {
        Rotational,
        Positional,
        Dimensional,
        State
      }
      let updateMode: UpdateMode = UpdateMode.Rotational;
      if(target.constructor.name == "Array"){
        theValue = target[0];
      } else {
        theValue = Number(target.target.value);
      }
      switch (field) {
        case "Elevation": {
            const convFactor =  AppSettings.defaultDisplaySettings.unitSystem == unitSystemType.METRIC ? 1 : 1/3.28084;
            theValue = convFactor*(theValue as number);
            temp.Elevation = theValue as number;
            shape.modelData!.z_position = parseFloat((temp.Elevation).toString()) + parseFloat(siteCoordZ.toString()) - cehProps.concreteHeight;
            equipData!.Elevation_Height = AppSettings.defaultDisplaySettings.unitSystem == unitSystemType.METRIC ? temp.Elevation : temp.Elevation;
            updateMode = UpdateMode.Positional;
          }
          break;
        case "Height":
          temp.Height = theValue as number;
          equipData.Height = convFactor*temp.Height!;
          updateMode = UpdateMode.Dimensional;
          break;
        case "Width":
          temp.Width = theValue as number;
          equipData.Width = convFactor*temp.Width!;
          updateMode = UpdateMode.Dimensional;
          if(equipData.Equipment_Type === EquipmentType.Microwave){
            temp.Height = temp.Width;
            equipData.Height = equipData.Width!;
          } else if(equipData.Equipment_Type === EquipmentType.SQUID){
            temp.Depth = temp.Width;
            equipData.Thickness = equipData.Width!;
          }

          break;
        case "Thickness":
          temp.Depth = theValue as number;
          equipData.Thickness = convFactor*temp.Depth!;
          updateMode = UpdateMode.Dimensional;
          break;
        case "Azimuth":
          temp.Azimuth = theValue as number;
          rotationVal = EquipmentRotationType.Azimuth;
          break;
        case "Roll":
          theValue = theValue as number;
          temp.Roll = theValue;
          rotationVal = EquipmentRotationType.Roll;
          break;
        case "Tilt":
          theValue = theValue as number;
          temp.DownTilt = theValue;
          rotationVal = EquipmentRotationType.Tilt;
          setEquipTiltLabel(theValue);
          setEquipTiltSlider(-1*theValue);
          break;
        case "Status": {
            theValue = target.currentTarget.checked as boolean;
            equipData!.isActive = theValue;
            temp.Active = theValue;
            updateMode = UpdateMode.State;
          }
          break;
      
        default:
          break;
      }

      const equipType: EquipmentType = ShapeDecorator.selectedEquipment?.theEquipment?.Equipment_Type! as EquipmentType;
      switch (updateMode) {
        case UpdateMode.Positional:
        case UpdateMode.Dimensional:
        case UpdateMode.State: {
            decorator.deleteCurrentShape(temp.DisplayName);
            decorator.createGeometry(equipData!, CreateGeometryMode.New);
            resetObjectIds(decorator);
            let shapeIndex = decorator.shapes.findIndex((shape) => shape.modelData?.DisplayName === ShapeDecorator.selectedEquipment.displayName);
            ModifyHandleDecoration.clear();        
            if(ModifyHandleDecoration._decorator && shapeIndex !== -1 && dimensionMode == DimensionMode.Custom){
              // if(decorator.shapes[shapeIndex].modelData?.shapeId == null)
              ModifyHandleDecoration.create(IModelApp.viewManager.selectedView as ScreenViewport, decorator.shapes[shapeIndex].transientId, equipData?.DisplayName!, shapeIndex, decorator);
            }
            // if (ModifyHandleDecoration._decorator && shapeIndex != -1 && decorator.shapes[shapeIndex].modelData?.shapeId == null && dimensionMode == DimensionMode.Custom) {
            if (ModifyHandleDecoration._decorator && shapeIndex != -1 && dimensionMode == DimensionMode.Custom) {
              ModifyHandleDecoration._decorator._shape = decorator.shapes[shapeIndex].modelData?.geometryEntry?.geometry;
              ModifyHandleDecoration._decorator._shapeIndex = shapeIndex;
              ModifyHandleDecoration._decorator._shapeName = equipData?.DisplayName!;
              ModifyHandleDecoration._decorator.createClipShapeControls();
            }
          }
          break;
        case UpdateMode.Rotational: {
            theValue = theValue as number;
            if (equipType == EquipmentType.Microwave || equipType ==  EquipmentType.SQUID) { // cylinder
              await EquipmentRotationWidget.sliderChange(rotationVal == EquipmentRotationType.Tilt ? -1*theValue : theValue, rotationVal, ShapeType.Cylinder);
            } else {
              await EquipmentRotationWidget.sliderChange(rotationVal == EquipmentRotationType.Tilt ? -1*theValue : theValue, rotationVal, ShapeType.Box);
            }
          
          }
          break;
        default:
          break;
      }

      // const equipType: EquipmentType = ShapeDecorator.getEquipmentTypeFromString(ShapeDecorator.selectedEquipment.theEquipment?.Equipment_Type!);
      setEquipmentProps(temp);
      // const decorator = IModelApp.viewManager.decorators.filter((e) => e.constructor.name.includes("ShapeDecorator"))[0] as ShapeDecorator;
      if(!ShapeDecorator.selectedEquipment.created){
        ShapeDecorator.selectedEquipment.isModified = true;      
        setIsModified(ShapeDecorator.selectedEquipment.isModified);
        if(!cehProps.addingEquipment){
          setSaveButton((saveButton)=>({...saveButton, disabled: !ShapeDecorator.selectedEquipment.isModified}));
          setResetButton((resetButton)=>({...resetButton, disabled: false}));
        } else {
          setSaveButton({label: "Add", disabled: false});
          setResetButton({label: "Cancel", disabled: false});
        }
      }
    };
  
    const onMouseUp = (target) => {
      clearInterval(timerRef.current!);
      switch(target){
        case "Move Up":
          updateElevation();
        break;
        case "Move Down":
          updateElevation();
        break;
      }
    }
    
    const updateElevation = () => {
      const shapeDecorator = IModelApp.viewManager.decorators.filter((e) => e.constructor.name.includes("ShapeDecorator"))[0] as ShapeDecorator;
      let shapeDN = cehProps.equipCurrJson!.DisplayName;
      const equipmentJson = shapeDecorator.shapes.find((json) => json.modelData?.DisplayName === shapeDN)?.modelData!;
      const {z_position} = equipmentJson;
      const temp: EquipmentEditProperties = JSON.parse(JSON.stringify(equipmentProps));
      temp.Elevation = z_position - siteCoordZ;
      setEquipmentProps(temp);
    }

    const onMouseDown = (target) => {
      switch (target) {
        case "Azimuth":
          break;
        case "Roll":
          break;
        case "Tilt":
          break;
      
        default:
          break;
      }


      let movementVector: Vector3d = new Vector3d();
      let moveDirection: number = 0;
      enum editMode {
        radial = "Radial",
        positional = "Positional",
        dimensional = "Dimensional"
      }
      let mode: editMode = editMode.positional;
      switch(target){
        case "Move Left":
          movementVector = new Vector3d(move, 0, 0);        
        break;
        case "Move Right": 
          movementVector = new Vector3d(-move, 0, 0);        
        break;
        case "Move Out":
          movementVector = new Vector3d(0, move, 0);        
          break;
        case "Move In": 
          movementVector = new Vector3d(0, -move, 0);        
        break;
        case "Move Up":
          movementVector = new Vector3d(0, 0, move);        
        break;
        case "Move Down": 
          movementVector = new Vector3d(0, 0, -move);        
        break;
        case "Increase Width":
          mode = editMode.dimensional;
          movementVector = new Vector3d(1, 2, 1);        
          break;
        case "Decrease Width": 
          mode = editMode.dimensional;
          movementVector = new Vector3d(1, -2, 1);        
        break;
        case "Scale In":
          mode = editMode.dimensional;
          movementVector = new Vector3d(move, move, move);        
        break;
        case "Scale Out": 
          mode = editMode.dimensional;
          movementVector = new Vector3d(-move, -move, -move);        
        break;
        case "Increase Height":
          mode = editMode.dimensional;
          movementVector = new Vector3d(0, 0, move);        
        break;
        case "Decrease Height": 
          mode = editMode.dimensional;
          movementVector = new Vector3d(0, 0, -move);        
        break;
        case "increaseThickness": 
          mode = editMode.dimensional;
          moveDirection = move;
          break;
        case "decreaseThickness": 
          mode = editMode.dimensional;
          moveDirection = -move;
        break;
        case "increaseDiameter": 
          mode = editMode.radial;
          moveDirection = move;
          break;
        case "decreaseDiameter": 
          mode = editMode.radial;
          moveDirection = -move;
        break;
      }
  
  
      setDataUpdated(true);
      if(mode == editMode.positional){
        timerRef.current=setInterval(()=>EquipmentRotationWidget.translate(movementVector.x, movementVector.y, movementVector.z), 50);
      } else if (mode == editMode.dimensional) {
        timerRef.current=setInterval(()=>EquipmentRotationWidget.scale(movementVector), 50);
      } else if (mode == editMode.radial) {
        timerRef.current=setInterval(()=>EquipmentRotationWidget.cylinderRadiusChange(moveDirection), 50)!;
      }
      // const decorator = IModelApp.viewManager.decorators.filter((e) => e.constructor.name.includes("ShapeDecorator"))[0] as ShapeDecorator;
      if(!ShapeDecorator.selectedEquipment.created){
        ShapeDecorator.selectedEquipment.isModified = true;      
        setIsModified(ShapeDecorator.selectedEquipment.isModified);
        if(!cehProps.addingEquipment){
          setSaveButton((saveButton)=>({...saveButton, disabled: !ShapeDecorator.selectedEquipment.isModified}));
          setResetButton((resetButton)=>({...resetButton, disabled: false}));
        } else {
          setSaveButton({label: "Add", disabled: false});
          setResetButton({label: "Cancel", disabled: false});
        }
      }
      setTimeout(() => {
        clearInterval(timerRef.current);
        if(mode == editMode.positional)updateElevation()
      }, 10000);
  
    };
  
    const onClose = ()=>{
      UiFramework.frontstages.activeFrontstageDef?.findWidgetDef("EquipmentEditWidget")?.setWidgetState(WidgetState.Hidden);
      store.dispatch(DTVActions.setEditModeFlag(!store.getState().dtvState.applicationState.isEditModeActive));
      ModifyHandleDecoration.clear();
    }
  
    const onReset = ()=>{
      let decorator = IModelApp.viewManager.decorators.filter((e) => e.constructor.name.includes("ShapeDecorator"))[0] as ShapeDecorator;
      if(resetButton.label == "Cancel"){
        decorator.deleteCurrentShape(cehProps.equipCurrJson.DisplayName);
        decorator.resetSelectedEquipment();
        ShapeDecorator.selectedEquipment.creating = false;
        ShapeDecorator.selectedEquipment.created = false;
        if(ModifyHandleDecoration._decorator)ModifyHandleDecoration.clear();
        setResetButton({label: "Reset", disabled: true});
        setSaveButton({label: "Save", disabled: true});
        setSelEquipName("0");
      } else {
        const temp: EquipmentEditProperties = JSON.parse(JSON.stringify(equipmentProps));
        const dimConvFactor =  AppSettings.defaultDisplaySettings.unitSystem == unitSystemType.METRIC ? 1 : 39.3701;
        const elevationConvFactor =  AppSettings.defaultDisplaySettings.unitSystem == unitSystemType.METRIC ? 1 : 1/3.28084;

        if(searchString.length){
          allManModelList.current = allAvailableAntManModel;
          setManufacturerList(manList);
          setSearchModel(true);
          setSearchString("");
        }
        const equipDisplayName = cehProps.equipCurrJson.DisplayName;
        const theEquip = store.getState().detectedData.equipmentData.find(e=>e.displayName==equipDisplayName);
        const decEquipData: EquipmentData = decorator.convertDetectionDataToDecoratorData(theEquip);
        const typeFilteredManModelList = getTypedManModelList(decEquipData.Equipment_Type);
        allManModelList.current = typeFilteredManModelList;
        const theManList = typeFilteredManModelList.map((mm, mmId)=>({label: mm.manufacturer, value: mmId+1}));
        theManList.unshift({label: "Select", value: 0});
        setManufacturerList(theManList);
    
        updateManModel(decEquipData.Manufacturer, decEquipData.Model, theManList, typeFilteredManModelList);
        let shapeIndex = decorator.shapes.findIndex((shape) => shape.modelData?.DisplayName === equipDisplayName);
        ShapeDecorator.selectedEquipment.isModified = false;      
        setIsModified(ShapeDecorator.selectedEquipment.isModified);
        setSaveButton((saveButton)=>({...saveButton, disabled: !ShapeDecorator.selectedEquipment.isModified}));
        
        let shapeDN = decEquipData!.DisplayName;
        decorator.deleteCurrentShape(shapeDN);
        // decorator.resetSelectedEquipment();

        temp.DisplayName = decEquipData.DisplayName;
        temp.Type = decEquipData.Equipment_Type;
        temp.Manufacturer = decEquipData.Manufacturer;
        temp.Model = decEquipData.Model;
        temp.Height = dimConvFactor*decEquipData.Height;
        temp.Width = dimConvFactor*decEquipData.Width;
        temp.Depth = dimConvFactor*decEquipData.Thickness;
        temp.Azimuth = decEquipData.Azimuth;
        temp.DownTilt = decEquipData.DownTilt;
        temp.Roll = decEquipData.Roll;
        temp.ShapeId = decEquipData.shapeId;
        temp.Active = decEquipData.isActive!;
        temp.Position = {x: decEquipData.x_position, y: decEquipData.y_position, z: decEquipData.z_position};
        temp.Elevation = cehProps.concreteHeight + decEquipData.z_position - siteCoordZ;
        temp.Modified = false;

        if(temp.Manufacturer.match(/NewEquip/ig) || temp.Model.match(/NewEquip/ig))setDimensionMode(DimensionMode.Custom);
        else setDimensionMode(DimensionMode.Model);
      
        setEquipmentProps(temp);

        decorator.createGeometry(decEquipData!, CreateGeometryMode.New);
        shapeIndex = decorator.shapes.findIndex((shape) => shape.modelData?.DisplayName === equipDisplayName);
        if(!ModifyHandleDecoration._decorator && shapeIndex !== -1){
          ModifyHandleDecoration.clear();
          ModifyHandleDecoration.create(IModelApp.viewManager.selectedView as ScreenViewport, decorator.shapes[shapeIndex].transientId, shapeDN!, shapeIndex, decorator);
        }      
        // if (ModifyHandleDecoration._decorator && decorator.shapes[shapeIndex].modelData?.shapeId == null) {
        if (ModifyHandleDecoration._decorator) {
          ModifyHandleDecoration._decorator._shape = decorator.shapes[shapeIndex].modelData?.geometryEntry?.geometry;
          ModifyHandleDecoration._decorator._shapeIndex = shapeIndex;
          ModifyHandleDecoration._decorator._shapeName = equipDisplayName;
          ModifyHandleDecoration._decorator.createClipShapeControls();
        } else {
          // ModifyHandleDecoration.clear();
        }
        IModelApp.viewManager.selectedView?.invalidateDecorations();
        IModelApp.viewManager.selectedView?.invalidateCachedDecorations(decorator);
        resetObjectIds(decorator);
        // setSelEquipName("0");
        setResetDisabled(true);
        setResetButton((resetButton)=>({...resetButton, disabled: true}));
      }
    }
  
    const onSave = async ()=>{
      const equipDisplayName = cehProps.equipCurrJson.DisplayName;
      let decorator = IModelApp.viewManager.decorators.filter((e) => e.constructor.name.includes("ShapeDecorator"))[0] as ShapeDecorator;
      const theShape = decorator.shapes.find(e=>e.modelData?.DisplayName==equipDisplayName)!;
      let retVal: boolean = false;
      if(saveButton.label == "Add"){
        if(retVal)IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Info, `Adding new equipment: ${theShape?.modelData?.DisplayName}.`, "", OutputMessageType.Toast));
        setSaveLabel('Adding...');
        setIsModified(false);
        setSaveButton({label: "Adding...", disabled: true});
        setResetDisabled(true);
        setResetButton((resetButton)=>({...resetButton, disabled: true}));
        let siteCoordinates = select(store.getState(), "siteCoordinate");
          
        if (!siteCoordinates) {
            siteCoordinates = await EquipmentClient.setSiteCoordinates(store.getState().auth.accessTokenStatePrivateAPI.accessToken /*accessTokenPrivate is a string now*/);     // Storing site coordinate, for if equipments are edited & require update relative to site <coordinates className="" />
            store.dispatch(setSiteCoordinate(siteCoordinates));
        }
  
        const theEquipData: EquipmentData = theShape?.modelData!;
        const createEquipData: EquipmentInformation = {
          className: "Equipment",
          id: theShape?.transientId!,
          height: theEquipData.Height,
          elevationHeight: theEquipData.z_position-siteCoordinates.utm.z,
          width: theEquipData.Width,
          depth: theEquipData.Thickness,
          type: theEquipData.Equipment_Type == EquipmentType.SQUID ? EquipmentType.SQUID : theEquipData.Equipment_Type,
          isActive: theEquipData.isActive,
          model: theEquipData.Model,
          manufacturer: theEquipData.Manufacturer,
          xPosition: theEquipData.x_position,
          yPosition: theEquipData.y_position,
          zPosition: theEquipData.z_position,
          yaw: theEquipData.Azimuth,
          azimuth: theEquipData.Azimuth,
          tilt: -1*theEquipData.DownTilt,
          pitch: theEquipData.Azimuth,
          roll: theEquipData.Roll,
          name: theEquipData.Equipment_Name,
          displayName: theEquipData.DisplayName,
          imageList: ""
        }

        retVal = await createEquipment(createEquipData, cehProps.privateToken);
        IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Info, `Success: ${createEquipData.displayName} created successfully.`, "", OutputMessageType.Toast));
      } else {
        IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Info, `Saving equipment: ${theShape?.modelData?.DisplayName}, please wait...`, "", OutputMessageType.Toast));
        setSaveLabel("Saving...");
        setIsModified(false);
        setSaveButton({label: "Saving...", disabled: true});
        setResetDisabled(true);
        setResetButton((resetButton)=>({...resetButton, disabled: true}));
        retVal = await saveEquipment(theShape?.modelData!);
      }
      if(ShapeDecorator.selectedEquipment.created)setSaveButton({label: "Added", disabled: true});
      else setSaveButton((saveButton)=>({...saveButton, label: "Saved", disabled: true}));

      // decorator.resetSelectedEquipment();
      if(retVal){
        await fetchEquipmentData(cehProps.privateToken)
        const iModelConnection = UiFramework.getIModelConnection();
        resetObjectIds(decorator);
        const theShape = decorator.shapes.find(e=>e.modelData?.DisplayName==equipDisplayName);
        ShapeDecorator.selectedEquipment.transId = theShape?.transientId!;
        ShapeDecorator.selectedEquipment.displayName = theShape?.modelData?.DisplayName!;
        // ShapeDecorator.selectedEquipment.displayName = theShape?.modelData?.DisplayName;
        iModelConnection?.selectionSet.emptyAll();
        iModelConnection?.selectionSet.add(theShape?.transientId as Id64String);
        ShapeDecorator.selectedEquipment.isModified = false;
        ShapeDecorator.selectedEquipment.created = false;
        ShapeDecorator.selectedEquipment.creating = false;
        setIsModified(ShapeDecorator.selectedEquipment.isModified);
        setSaveButton((saveButton)=>({...saveButton, disabled: true}));
        setResetDisabled(true);
        setResetButton((resetButton)=>({...resetButton, disabled: true}));
      }
      setTimeout(() => {
        // setSaveLabel('Save');
        // setResetLabel("Reset")
        setResetButton((resetButton)=>({...resetButton, label: "Reset", disabled: true}));
        setSaveButton((saveButton)=>({...saveButton, label: "Save", disabled: true}));
      }, 3000);
      
    }
  
    const manSelectHandler = (target) => {
      // let manL = allManModelList.current, modeL, manId=target, modelId=selModel;
      let manL = manufacturerList, modeL = modelList, manId=target, modelId=selModel;
      if(target > 0){
        const sm = allManModelList.current[target-1];
        setSelMan(target)
        const ml = sm.models.map((mm, mmId)=>({label: mm.name, value: mmId+1}));
        ml.unshift({label: "Select", value: 0});
        setSelModel(ml[1].value);
        setModelList(ml);
        setDimensionMode(DimensionMode.Model);
        modelId = ml[1].value;
        modeL = ml;
        Place3DObjectApi.objectProperties = {...Place3DObjectApi.objectProperties!, properties: {...Place3DObjectApi.objectProperties?.properties!, Manufacturer: sm.manufacturer, Model: ml[1].label}};
      } else {
        modelId=0;
        setSelMan(0);
        setSelModel(0);
        setDimensionMode(DimensionMode.Custom);
      }
      setIsModified(true);
      // const decorator = IModelApp.viewManager.decorators.filter((e) => e.constructor.name.includes("ShapeDecorator"))[0] as ShapeDecorator;
      if(!ShapeDecorator.selectedEquipment.created){
        setResetDisabled(false);
        setResetButton((resetButton)=>({...resetButton, disabled: false}));
        setSaveButton((saveButton)=>({...saveButton, disabled: false}));
      }
      updateEquipmentIn3D(manL, manId, modeL, modelId);
    }
  
    const modelSelectHandler = (target) => {
      if(target > 0){
        const sm = modelList[target-1];
        setSelModel(target);
        setDimensionMode(DimensionMode.Model);
        Place3DObjectApi.objectProperties = {...Place3DObjectApi.objectProperties!, properties: {...Place3DObjectApi.objectProperties?.properties!, Manufacturer: manufacturerList[selMan].label, Model: sm.label}};
      } else {
        setSelModel(0);
        setDimensionMode(DimensionMode.Custom);
      }
      setIsModified(true);
      setSaveButton((saveButton)=>({...saveButton, disabled: false}));
      setResetButton((resetButton)=>({...resetButton, disabled: false}));
      updateEquipmentIn3D(manufacturerList, selMan, modelList, target);
    }
  
    const moveEquipment = () => {
      const decorator = IModelApp.viewManager.decorators.filter((e) => e.constructor.name.includes("ShapeDecorator"))[0] as ShapeDecorator;
      const equipJson = decorator.shapes.find(e=>e.modelData?.DisplayName==ShapeDecorator.selectedEquipment.displayName)?.modelData!;
      Place3DObjectApi.objectProperties = {mode: PlacementMode.Update, properties: equipJson};
      void IModelApp.tools.run(PlacementTool.toolId, PlacementMode.Update, DetectedGeometryType.Equipment, (e: string)=>{
        switch (e) {
          case "Reset":
            setResetButton((resetButton)=>({...resetButton, disabled: true}));
            setSaveButton((saveButton)=>({...saveButton, disabled: true}));
            onReset();
            break;
          case "Success":
            if(cehProps.addingEquipment){
              setSaveButton((saveButton)=>({...saveButton, disabled: false}));
              setResetButton((resetButton)=>({...resetButton, disabled: false}));
            } else {
              setSaveButton({label: "Add", disabled: false});
              setResetButton({label: "Cancel", disabled: false});
            }
            const equipJson = decorator.shapes.find(e=>e.modelData?.DisplayName==ShapeDecorator.selectedEquipment.displayName)?.modelData!;
            const temp: EquipmentEditProperties = JSON.parse(JSON.stringify(equipmentProps));
            temp.Elevation = equipJson.z_position - siteCoordZ;
            setEquipmentProps(temp);
            break;
        
          default:
            break;
        }
      });

    }
  
    const resetSearchString = () => {
      // allManModelList.current = allAvailableAntManModel;
      // setManufacturerList(manList);

      // const selEquipType = getManEquipType(cehProps.equipCurrJson.Type)
      // const typeFilteredManModelList = allAvailableAntManModel.filter(e=>e.equipmentTypes.find(et=>et==selEquipType));
      // allManModelList.current = typeFilteredManModelList;
      const theManList = allManModelList.current.map((mm, mmId)=>({label: mm.manufacturer, value: mmId+1}));
      theManList.unshift({label: "Select", value: 0});
      setManufacturerList(theManList);

      setSearchString("");
      let manL = manList, modeL=modelList, manId=selMan, modelId=selModel;
      if(cehProps?.equipCurrJson?.Manufacturer!=null){          
        let decorator = IModelApp.viewManager.decorators.filter((e) => e.constructor.name.includes("ShapeDecorator"))[0] as ShapeDecorator;
        const theEquip = store.getState().detectedData.equipmentData.find(e=>e.displayName==cehProps.equipCurrJson.DisplayName);
        const decEquipData: EquipmentData = decorator.convertDetectionDataToDecoratorData(theEquip);
        const typeFilteredManModelList = getTypedManModelList(decEquipData.Equipment_Type);
        allManModelList.current = typeFilteredManModelList;
        const theManList = typeFilteredManModelList.map((mm, mmId)=>({label: mm.manufacturer, value: mmId+1}));
        theManList.unshift({label: "Select", value: 0});
        manL = theManList;
        setManufacturerList(theManList);
        
        const retVal = updateManModel(decEquipData.Manufacturer, decEquipData.Model, manL, typeFilteredManModelList);
        manId = retVal.manIndex;
        modeL = retVal.modeL;
        modelId = retVal.modelIndex;
      }
      updateEquipmentIn3D(manL, manId, modeL, modelId);
    }
  
    const searchCatalogHandler = (modelOrMan: boolean) => {
      // allManModelList.current = allAvailableAntManModel;
      // setManufacturerList(manList);
      const typeFilteredManModelList = getTypedManModelList(cehProps.equipCurrJson.Type);
      allManModelList.current = typeFilteredManModelList;
      const theManList = typeFilteredManModelList.map((mm, mmId)=>({label: mm.manufacturer, value: mmId+1}));
      theManList.unshift({label: "Select", value: 0});
      setManufacturerList(theManList);

      setSearchModel(modelOrMan);
      resetSearchString();
    }
  
    const getBestMatch = () => {
      const theEquip = store.getState().detectedData.equipmentData.find(e=>e.displayName==cehProps.equipCurrJson.DisplayName)!;
      const typeFilteredManModelList = getTypedManModelList(cehProps.equipCurrJson.Type);
      const allMatchedModels: ManModels[] = [];
      typeFilteredManModelList.forEach(mml=>{
        const mm: ManModels = {
          manufacturer: mml.manufacturer,
          equipmentTypes: mml.equipmentTypes,
          models: []
        }
        const tempMatch = mml.models.filter((model): any=>{
          const modelHeight = model.specification.height/39.3701;
          const modelWidth = model.specification.width/39.3701;
          const modelDepth = model.specification.depth/39.3701;
          if(Math.abs(modelHeight-theEquip?.height)<0.05 && Math.abs(modelWidth-theEquip?.width)<0.05 && Math.abs(modelDepth-theEquip?.depth)<0.05)return model;
        });
        mm.models = tempMatch;
        if(tempMatch.length)allMatchedModels.push(mm);
      });
      if(allMatchedModels.length){
        allManModelList.current = allMatchedModels;
        const theManList = allMatchedModels.map((mm, mmId)=>({label: mm.manufacturer, value: mmId+1}));
        theManList.unshift({label: "Select", value: 0});
        const modelL = allMatchedModels[0].models.map((mm, mmId)=>({label: mm.name, value: mmId+1}));
        modelL.unshift({label: "Select", value: 0});
        setModelList(modelL);
        setManufacturerList(theManList);
        setSelMan(1);
        setSelModel(1);
        IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Success, `Matches are loaded, please select from the dropdown.`, "", OutputMessageType.Toast));
      } else IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Warning, `No close matches found for the equipment.`, "", OutputMessageType.Toast));

    }
  
    const searchCatalog = (event) => {
      const theSS = event.target.value;
      setSearchString(theSS);
      if(theSS.length==0){
        setManufacturerList(manList);        
        resetSearchString();
        setResetButton({label: "Reset", disabled: true});
        setSaveButton({label: "Save", disabled: true});
        ShapeDecorator.selectedEquipment.isModified = false;
        setDimensionMode(DimensionMode.Custom);
        // updateEquipmentIn3D();
        return;
      }

      if(searchModel){
        const matchingModels: ManModels[] = getSearchedManModelList(typeFilteredManModelList, theSS);
        // const modelMatches: ManModels[] = [];
        if(matchingModels.length==1){
          allManModelList.current = matchingModels;
          const tempManList = [{label: matchingModels[0].manufacturer, value: 1}];
          const tempModelList = matchingModels[0].models.map((mm: equipmentModel, mmId: number)=>({label: mm.name, value: mmId+1}));
          tempManList.unshift({label: "Select", value: 0});
          tempModelList.unshift({label: "Select", value: 0});
          setManufacturerList(tempManList);
          setModelList(tempModelList);
          setSelMan(1);
          setSelModel(1);
          ShapeDecorator.selectedEquipment.isModified = true;
          setResetButton((resetButton)=>({...resetButton, disabled: false}));
          setSaveButton((saveButton)=>({...saveButton, disabled: false}));
          updateEquipmentIn3D(tempManList, 1, tempModelList, 1);
        } else if(matchingModels.length > 0) {
          allManModelList.current = matchingModels;
          const tempManList = matchingModels.map((mm: ManModels, mmId)=>({label: mm.manufacturer, value: mmId+1}));
          tempManList.unshift({label: "Select", value: 0});
          const tempModelList = matchingModels[0].models.map((mm: equipmentModel, mmId)=>({label: mm.name, value: mmId+1}));
          tempModelList.unshift({label: "Select", value: 0});
          setManufacturerList(tempManList);
          setModelList(tempModelList);
          setSelMan(1);
          setSelModel(1);
          ShapeDecorator.selectedEquipment.isModified = true;
          setResetButton((resetButton)=>({...resetButton, disabled: false}));
          setSaveButton((saveButton)=>({...saveButton, disabled: false}));
          updateEquipmentIn3D(tempManList, 1, tempModelList, 1);
        } else return;
        setDimensionMode(DimensionMode.Model);
      } else {
        const matchingMans: ManModels[] = typeFilteredManModelList.filter((avamm: any)=>{
        if(avamm.manufacturer.toUpperCase().match(theSS.toUpperCase())){
          return avamm.manufacturer;
        }});//.filter(e=>e);
        if(matchingMans.length){
          allManModelList.current = matchingMans;
          const tempManList = matchingMans.map((mm: ManModels, mmId)=>({label: mm.manufacturer, value: mmId+1}));
          tempManList.unshift({label: "Select", value: 0});
          const tempModelList = matchingMans[0].models.map((mm: equipmentModel, mmId)=>({label: mm.name, value: mmId+1}));
          tempModelList.unshift({label: "Select", value: 0});
          setManufacturerList(tempManList);
          setModelList(tempModelList);
          setSelMan(1);
          setSelModel(1);
          ShapeDecorator.selectedEquipment.isModified = true;
          setResetButton((resetButton)=>({...resetButton, disabled: false}));
          setSaveButton((saveButton)=>({...saveButton, disabled: false}));
          updateEquipmentIn3D();
        }
      }
    }
  
    IModelApp.toolAdmin.activeToolChanged.addListener(toolChangeListener);
  
    let posRotJsx = [<></>];
    if(cehProps.open && equipmentProps != undefined){
      posRotJsx = [
        <div className="equipment-placement-grid">
          <div className="controls">
            <Button title="Move Up" size="large" startIcon={<SvgArrowUp/>} onMouseUp={()=>onMouseUp("Move Up")} onMouseDown={()=>onMouseDown("Move Up")} />
            <div className="row">
              <div className="col-4">
                <Button title="Move Left" style={{height: "100%", width: "100%"}} startIcon={<SvgArrowLeft />} onMouseUp={onMouseUp} onMouseDown={()=>onMouseDown("Move Left")} />
              </div>
              <div className="col-4">
                <div className="row">
                  <Button title="Move In" size="large" startIcon={<SvgCaretUp />} onMouseUp={onMouseUp} onMouseDown={()=>onMouseDown("Move In")} />
                </div>
                <div className="row">
                  <Button title="Scale & Move" size="large" startIcon={<SvgMove />} onClick={moveEquipment} />
                </div>
                <div className="row">
                  <Button title="Move Out" size="large" startIcon={<SvgCaretDown />} onMouseUp={onMouseUp} onMouseDown={()=>onMouseDown("Move Out")} />
                </div>
              </div>
              <div className="col-4">
                <Button title="Move Right" style={{height: "100%", width: "100%"}} endIcon={<SvgArrowRight />} onMouseUp={onMouseUp} onMouseDown={()=>onMouseDown("Move Right")} />
              </div>
            </div>
            <Button title="Move Down" style={{marginBottom: "0px"}} size="large" startIcon={<SvgArrowDown />} onMouseUp={()=>onMouseUp("Move Down")} onMouseDown={()=>onMouseDown("Move Down")} />
          </div>
        </div>,
        <div className="equipment-placement-grid">
          <div className="controls">
            <LabeledInput
                label="Azimuth :"
                size="small"
                type="number"
                step={0.1}
                value={equipmentProps.Azimuth}
                displayStyle="inline"
                onChange={(e)=>onFieldUpdate(e, "Azimuth")}
              />
            <Slider values={[equipmentProps.Azimuth]} min={0} max={360} onUpdate={(e)=>onFieldUpdate(e, "Azimuth")} step={0.5} className="mb-1" />
            <LabeledInput
                label="Roll :"
                size="small"
                type="number"
                step={0.1}
                value={equipmentProps.Roll}
                displayStyle="inline"
                onChange={(e)=>onFieldUpdate(e, "Roll")}
              />
            <Slider values={[equipmentProps.Roll]} min={-180} max={180} onUpdate={(e)=>onFieldUpdate(e, "Roll")} step={0.5} className="mb-1" />
            <LabeledInput
                label="Down Tilt :"
                size="small"
                type="number"
                step={0.1}
                min={-180}
                max={180}
                // value={equipTiltLabel}
                value={equipmentProps.DownTilt}
                displayStyle="inline"
                onChange={(e)=>onFieldUpdate(e, "Tilt")}
              />
            {/* <Slider values={[-1*equipmentProps.Tilt]} min={-180} max={180} onUpdate={(e)=>onFieldUpdate(e, "Tilt")} step={0.5} /> */}
            <Slider values={[equipmentProps.DownTilt]} min={-180} max={180} onUpdate={(e)=>onFieldUpdate(e, "Tilt")} step={0.5} />
            {/* <Slider values={[equipTiltSlider]} min={-180} max={180} onUpdate={(e)=>onFieldUpdate(e, "Tilt")} step={0.5} /> */}
          </div>
        </div>
      ]
    }


    return <>{(cehProps.open && equipmentProps != undefined) &&
      <>
        <div className="equipment-placement-options">
          <div className="equipment-placement-grid pl-0">
            <div className="controls">
              <fieldset>
                <legend style={{fontWeight: "bold", marginBottom: "4px"}}>Equipment Select</legend>
                {/* <div className="equipment-placement-control row p-1">
                  <Text className="col-4">Name: </Text>
                  <Select
                    className="col-8"
                    size="small"
                    value={selEquipName}
                    options={equipmentList}
                    onKeyDown={(e)=>console.log('This is name keyDown handler: ', e)}
                    onKeyDownCapture={(e)=>console.log('This is name keyDownCapture handler: ', e)}
                    onChange={(e)=>{onEquipmentNameSelect(parseInt(e))}} />
                </div> */}
                <LabeledSelect
                  size="small"
                  className="mb-2"
                  label='Equipment'
                  displayStyle="inline"
                  // status="positive"
                  value={selEquipName}
                  setFocus={true}
                  options={equipmentList}
                  onChange={e=>onEquipmentNameSelect(parseInt(e))}
                />
                <LabeledSelect
                  size="small"
                  className="mb-2"
                  label='Manufacturer'
                  displayStyle="inline"
                  value={selMan}
                  setFocus={true}
                  options={manufacturerList}
                  onChange={e=>manSelectHandler(e)}
                />
                <LabeledSelect
                  size="small"
                  className="mb-2"
                  label='Model'
                  displayStyle="inline"
                  value={selModel}
                  setFocus={true}
                  options={modelList}
                  onChange={e=>modelSelectHandler(e)}
                />
                <div className="row">
                  <div className="col-6 mt-1">
                    <InputGroup
                      displayStyle="default"
                    >
                      <Checkbox style={{display: "flex", fontWeight: "bold"}} checked={!searchModel} onChange={()=>searchCatalogHandler(false)} label="Manufacturer" />
                      <Checkbox style={{display: "flex", fontWeight: "bold"}} checked={searchModel} onChange={()=>searchCatalogHandler(true)} label="Model" />
                    </InputGroup>
                  </div>
                  <div className="col-6">

                    <LabeledInput
                      className="mt-2"
                      placeholder="Search..."
                      // label="Search"
                      displayStyle="inline"
                      value={searchString}
                      svgIcon={
                        <div ref={ref}>
                          <SvgSearch />
                        </div>
                      }
                      iconDisplayStyle="inline"
                      onChange={(e)=>searchCatalog(e)} />
                    <Tooltip reference={ref} content="Search catalog" />
                  </div>
                </div>
                <div className="row">
                  <div className="col-6 mt-1 font-weight-bold" style={{fontWeight: "bold"}} title="Equipment state">Active</div>
                  <div className="col-2 mt-1" style={{fontWeight: "bold"}}>{equipmentProps.Active ? "True" : "False"}</div>
                  <div className="col-4 mt-1"><ToggleSwitch onChange={(e)=>onFieldUpdate(e, "Status")} checked={equipmentProps.Active} icon={<SvgCheckmark />} /></div>
                </div>
                {/* <div className="mt-2 ml-1 mr-1">
                  <Button style={{width: "100%"}} title="Move In" size="large" startIcon={<SvgSync />} onClick={getBestMatch}>Get best matches</Button>
                </div> */}

                {/* <caption>Select using custom renderers for menu items and selected value.</caption>
                <Select<string>
                  options={[
                    { value: 'yellow', label: 'Yellow' },
                    { value: 'green', label: 'Green' },
                    { value: 'red', label: 'Red' },
                  ]}
                  value={selectedValue}
                  placeholder='Placeholder text'
                  itemRenderer={(option, itemProps) => (
                    <MenuItem
                      style={{ color: option.value }}
                      isSelected={itemProps.isSelected}
                      onClick={() => {
                        setSelectedValue(option.value);
                        itemProps.close();
                      }}
                      role='option'
                      ref={(el) => itemProps.isSelected && el?.scrollIntoView()}
                    >
                      {option.label}
                    </MenuItem>
                  )}
                  selectedItemRenderer={(option) => (
                    <span style={{ backgroundColor: option.value }}>{option.label}</span>
                  )}
                /> */}

              </fieldset>
              <fieldset>
                <legend style={{fontWeight: "bold", marginBottom: "4px"}}>Dimension</legend>
                <div className="equipment-placement-grid">
                  <div className="controls">
                    <LabeledInput
                        label={`Elevation ${getUnit(false,false)}: `}
                        size="small"
                        type="number"
                        step={0.001}
                        value={ReduceDecimalNumberToFourPlaces(getValueInRequiredUnit(equipmentProps.Elevation))}
                        displayStyle="inline"
                        onChange={(e)=>onFieldUpdate(e, "Elevation")}
                      />
                    <LabeledInput
                        label={`Height ${AppSettings.defaultDisplaySettings.unitSystem == unitSystemType.METRIC ? getUnit(false, false) : getUnit(false, true)}: `}
                        title={`${dimensionMode == DimensionMode.Model ? `${dimensionMode} Defined` : dimensionMode} Height`}
                        disabled={dimensionMode == DimensionMode.Model || equipmentProps?.DisplayName?.match(/micro_wave/ig) != null}
                        size="small"
                        type="number"
                        step={0.1}
                        value={Math.round(equipmentProps.Height*10000)/10000}
                        displayStyle="inline"
                        onChange={(e)=>onFieldUpdate(e, "Height")}
                      />
                    <LabeledInput
                        label={`Width ${AppSettings.defaultDisplaySettings.unitSystem == unitSystemType.METRIC ? getUnit(false, false) : getUnit(false, true)}: `}
                        title={`${dimensionMode == DimensionMode.Model ? `${dimensionMode} Defined` : dimensionMode} Width`}
                        disabled={dimensionMode == DimensionMode.Model}
                        size="small"
                        type="number"
                        step={0.1}
                        value={Math.round(equipmentProps.Width*10000)/10000}
                        displayStyle="inline"
                        onChange={(e)=>onFieldUpdate(e, "Width")}
                      />
                    <LabeledInput
                        label={`Thickness ${AppSettings.defaultDisplaySettings.unitSystem == unitSystemType.METRIC ? getUnit(false, false) : getUnit(false, true)}: `}
                        title={`${dimensionMode == DimensionMode.Model ? `${dimensionMode} Defined` : dimensionMode} Thickness`}
                        disabled={dimensionMode == DimensionMode.Model || equipmentProps?.Type?.match(/squid/ig) != null}
                        size="small"
                        type="number"
                        step={0.1}
                        value={Math.round(equipmentProps.Depth*10000)/10000}
                        displayStyle="inline"
                        onChange={(e)=>onFieldUpdate(e, "Thickness")}
                      />
                    {/* <Slider values={[-1*equipmentProps.Tilt]} min={-180} max={180} onUpdate={(e)=>onFieldUpdate(e, "Tilt")} step={0.5} /> */}
                    {/* <Slider values={[equipmentProps.DownTilt]} min={-180} max={180} onUpdate={(e)=>onFieldUpdate(e, "Tilt")} step={0.5} /> */}
                    {/* <Slider values={[equipTiltSlider]} min={-180} max={180} onUpdate={(e)=>onFieldUpdate(e, "Tilt")} step={0.5} /> */}
                  </div>
                </div>
              </fieldset>
              <fieldset>
                <legend style={{fontWeight: "bold", marginBottom: "0px"}}>Position & Rotation</legend>
                <Tabs
                  tabsClassName="equipment-posrot-tab"
                  contentClassName="pl-0 pb-0 pt-2"
                  wrapperClassName="equipment-posrot-tab-wrapper"
                  orientation="horizontal"
                  onTabSelected={setIndex}
                  type="pill"
                  labels={[
                    <Tab key={0} label="Position" />,
                    <Tab key={1} label="Rotation" disabled={false} />,
                  ]}
                >
                  {posRotJsx[index]}
                </Tabs>
              </fieldset>
              <fieldset>
                <legend style={{fontWeight: "bold"}}>Action</legend>
                <div className="equipment-placement-grid">
                  <Button style={{border: "none", padding: "0", backgroundColor: "transparent"}} className="col text-center" disabled={resetButton.disabled} onClick={onReset}><Badge style={{width: "100%", border: `1px solid ${resetButton.disabled ? "black":  "blue"}`}} backgroundColor={resetButton.disabled ? "ash" : "primary"}>{resetButton.label}</Badge></Button>
                  <Button style={{border: "none", padding: "0", backgroundColor: "transparent"}} className="col text-center" disabled={saveButton.disabled} onClick={onSave}><Badge style={{width: "100%", border: `1px solid ${saveButton.disabled ? "black" : "green"}`}} backgroundColor={saveButton.disabled ? "ash" : "positive"}>{saveButton.label}</Badge></Button>
                  <Button style={{border: "none", padding: "0", backgroundColor: "transparent"}} className="col text-center" onClick={onClose}><Badge style={{width: "100%"}} backgroundColor="negative">Close</Badge></Button>
                </div>
              </fieldset>
              {/* <hr/> */}
            </div>
          </div>
        </div>
      </>
    }</>;
  };
  

  const mapStateToProps = (state: RootState) => {
    let equipDec: any = IModelApp.viewManager.decorators.find((e) => e.constructor.name.includes("ShapeDecorator"));
    let equipEditProps: EquipmentEditProperties = equipDefProps;
    let allEquipmentList: string[] = [], equipmentList: {label: string, value: number}[] = [];
    let siteCoordinates = store.getState().detectedData.siteCoordinate;
    const towerdata = store.getState().detectedData.towerStructureData;
    const concreteHeight = ConfigManager.AGL ? Object.entries(towerdata).filter((f) => f[0] === "concrete_height")[0][1] : 0;
    const selEquip = ShapeDecorator.selectedEquipment;
    if (!siteCoordinates) {
        EquipmentClient.setSiteCoordinates(store.getState().auth.accessTokenStatePrivateAPI.accessToken).then(sc=>{
          siteCoordinates = sc;
          store.dispatch(setSiteCoordinate(sc));
        });     // Storing site coordinate, for if equipments are edited & require update relative to site <coordinates className="" />
    }
    if(state.detectedData.equipmentData.length){

      const AntennaList: number[] = [], RRUList: number[] = [], MicroWaveList: number[] = [], SQUIDList: number[] = [];
      const detEquipData = state.detectedData.equipmentData.filter(ed=>ed.type!="Tower");
      detEquipData.forEach(e=>e?.displayName?.match(/Ant/) ? AntennaList.push(parseInt(e?.displayName?.split('_')[1])) : e?.displayName?.match(/RRU/) ? RRUList.push(parseInt(e?.displayName?.split('_')[1])) : e?.displayName?.match(/Micro_Wave/) ? MicroWaveList.push(parseInt(e?.displayName?.split('_')[2])) : SQUIDList.push(parseInt(e?.displayName?.split('_')[1])) );
      if(equipDec){
        // equipDec = equipDec[0] as ShapeDecorator;
        if(selEquip.creating || selEquip.created){
          const creatingEquipName = selEquip.displayName.split('_');
          switch (creatingEquipName[0]) {
            case EquipmentType.Antenna:
              AntennaList.push(parseInt(creatingEquipName[1]));
              break;
            case EquipmentType.RRU:
              RRUList.push(parseInt(creatingEquipName[1]));
              break;
            case EquipmentType.SQUID:
              SQUIDList.push(parseInt(creatingEquipName[1]));
              break;
            default:
              MicroWaveList.push(parseInt(creatingEquipName[2]));
          }
        }
      }
      const equipmentListForted: any[] = [...AntennaList.sort((a, b)=>(a-b)).map(e=>`Antenna_${e}`), ...RRUList.sort((a, b)=>(a-b)).map(e=>`RRU_${e}`), ...MicroWaveList.sort((a, b)=>(a-b)).map(e=>`Micro_Wave_${e}`), ...SQUIDList.sort((a, b)=>(a-b)).map(e=>`SQUID_${e}`)];
      if(allEquipmentList.length)allEquipmentList = allEquipmentList.sort();
      equipmentList = equipmentListForted.map((mm, mmId)=>({label: mm, value: mmId+1}));
      if(equipmentList.length)equipmentList.unshift({label: "Select Equipment", value: 0});
    }

    if(equipDec){
    // if(equipDec && state.detectedData.selectedObjectInformation.objectProperties.objectCategoryType == ObjectCategoryTypes.Equipment_RM){
      // const equipCurrJson: EquipmentData = equipDec.shapes.find((i) => i.modelData?.DisplayName === state.detectedData.selectedObjectInformation.objectProperties.selectedObjectNST.displayName!)?.modelData as EquipmentData;
      const equipCurrJson: EquipmentData = equipDec.shapes.find((i) => i.modelData?.DisplayName === selEquip.displayName!)?.modelData as EquipmentData;
      equipEditProps = {
        DisplayName: equipCurrJson?.DisplayName,
        Type: equipCurrJson?.Equipment_Type,
        Manufacturer: equipCurrJson?.Manufacturer,
        Model: equipCurrJson?.Model,
        Height: ReduceDecimalNumberToTwoPlaces( AppSettings.defaultDisplaySettings.unitSystem == unitSystemType.METRIC?getValueInRequiredUnit(equipCurrJson?.Height):getValueInRequiredUnit(equipCurrJson?.Height*39.37, true ,true)) ?? 0,
        Width: ReduceDecimalNumberToTwoPlaces( AppSettings.defaultDisplaySettings.unitSystem == unitSystemType.METRIC?getValueInRequiredUnit(equipCurrJson?.Width):getValueInRequiredUnit(equipCurrJson?.Width*39.37, true ,true)) ?? 0,
        Depth: ReduceDecimalNumberToTwoPlaces( AppSettings.defaultDisplaySettings.unitSystem == unitSystemType.METRIC?getValueInRequiredUnit(equipCurrJson?.Thickness):getValueInRequiredUnit(equipCurrJson?.Thickness*39.37, true ,true)) ?? 0,
        Azimuth: equipCurrJson?.Azimuth ?? 0,
        DownTilt: equipCurrJson?.DownTilt ?? 0,
        Roll: equipCurrJson?.Roll ?? 0,
        ShapeId: equipCurrJson?.shapeId,
        Position: {x: equipCurrJson?.x_position ?? 0, y: equipCurrJson?.y_position ?? 0, z: equipCurrJson?.z_position ?? 0},
        Elevation: equipCurrJson?.Elevation_Height!,
        Active: equipCurrJson?.isActive!,
        Modified: selEquip.isModified
      }
    }

    const widget = UiFramework.frontstages.activeFrontstageDef?.findWidgetDef("EquipmentEditWidget");
    if(widget?.state == WidgetState.Hidden && state.dtvState.applicationState.isEditModeActive && equipDec && state.detectedData.selectedObjectInformation.objectProperties.objectCategoryType == ObjectCategoryTypes.Equipment_RM){
      widget?.setWidgetState(WidgetState.Open);
      const dmmWidget = UiFramework.frontstages.activeFrontstageDef?.findWidgetDef("DesignModelMenu");
      dmmWidget?.setWidgetState(WidgetState.Closed);
    }
    
    const retVal = {
      siteCoordZ: siteCoordinates.utm.z,
      concreteHeight,
      updatedEquipment:  selEquip.isModified,
      addingEquipment:  selEquip.creating || selEquip.created,
      open: true,
      equipmentList,
      equipCurrJson: equipEditProps! ?? equipDefProps,
      selectedObjectInfo: state.detectedData.selectedObjectInformation,
      privateToken: state.auth.accessTokenStatePrivateAPI.accessToken
    };
    return widget?.state == WidgetState.Open ? retVal : {open: false};
  }
  
  const mapDispatchToProps = {}
  
  export default connect(mapStateToProps, mapDispatchToProps)(CustomEquipmentHandler)