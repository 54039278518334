import { BackgroundMapProps, BackgroundMapSettings } from "@itwin/core-common";
import {
  BeButtonEvent, EventHandled, IModelApp, LocateResponse, NotifyMessageDetails, OutputMessageAlert,
  OutputMessagePriority, OutputMessageType, PrimitiveTool, ScreenViewport, ToolAssistance, ToolAssistanceImage, ToolAssistanceInputMethod, ToolAssistanceInstruction, ToolAssistanceSection, Viewport,
} from "@itwin/core-frontend";
import { MessageManager } from "@itwin/appui-react";
import { DigitalTwinViewerApp } from "../../../api/DigitalTwinViewerApp";
import DatabaseApiClient from "../../../services/graphql/database-api/database-api-client";
import { ConfigManager } from "../../../config/ConfigManager";
// import { NineZoneSampleApp } from "../../app/NineZoneSampleApp";
// import { Settings } from "../../api/Settings";

export interface mapElevation {flatMapElevation: number | string, terrainElevation: number | string};
/**
 * AdjustElevationTool
 */
export class AdjustElevationTool extends PrimitiveTool {
  public static toolId = "adjustElevation";
  public static iconSpec = "icon-map";

  public isCompatibleViewport(vp: Viewport | undefined, isSelectedViewChange: boolean): boolean { return (super.isCompatibleViewport(vp, isSelectedViewChange) && undefined !== vp); }
  public requireWriteableTarget(): boolean { return false; }
  public onPostInstall(): Promise<void> { super.onPostInstall(); this.setupAndPromptForNextAction(); return Promise.resolve();}
  public onUnsuspend(): Promise<void> { this.showPrompt(); return Promise.resolve();}

  protected showPrompt(): void {
    const mainMsg = IModelApp.localization.getLocalizedString("DigitalTwinViewerLoc:tools.adjustElevation.prompts.enterDataPoint");

    const mainInstruction = ToolAssistance.createInstruction(this.iconSpec, mainMsg);
    const mouseInstructions: ToolAssistanceInstruction[] = [];

    mouseInstructions.push(ToolAssistance.createInstruction(ToolAssistanceImage.LeftClick, IModelApp.localization.getLocalizedString("DigitalTwinViewerLoc:tools.adjustElevation.prompts.acceptGroundPoint"), false, ToolAssistanceInputMethod.Mouse));
    mouseInstructions.push(ToolAssistance.createInstruction(ToolAssistanceImage.MouseWheel, IModelApp.localization.getLocalizedString("DigitalTwinViewerLoc:tools.adjustElevation.prompts.rotateToZoom"), false, ToolAssistanceInputMethod.Mouse));
    mouseInstructions.push(ToolAssistance.createInstruction(ToolAssistanceImage.MouseWheelClickDrag, IModelApp.localization.getLocalizedString("DigitalTwinViewerLoc:tools.adjustElevation.prompts.clickAndDragToPan"), false, ToolAssistanceInputMethod.Mouse));
    mouseInstructions.push(ToolAssistance.createModifierKeyInstruction(ToolAssistance.shiftKey, ToolAssistanceImage.MouseWheelClickDrag, IModelApp.localization.getLocalizedString("DigitalTwinViewerLoc:tools.adjustElevation.prompts.shiftClickAndDragToRotate"), false, ToolAssistanceInputMethod.Mouse));

    const sections: ToolAssistanceSection[] = [];
    sections.push(ToolAssistance.createSection(mouseInstructions, ToolAssistance.inputsLabel));

    const instructions = ToolAssistance.createInstructions(mainInstruction, sections);
    IModelApp.notifications.setToolAssistance(instructions);
  }

  protected setupAndPromptForNextAction(): void {
    this.showPrompt();
  }

  public static updateAuxiliaryCoordinateSystemInVp(vp: Viewport) {
    const origin = vp.auxCoordSystem.getOrigin();
    origin.z = vp.backgroundMapSettings.groundBias;
    vp.auxCoordSystem.setOrigin(origin);
  }

  public static updateAuxiliaryCoordinateSystem() {
    for (const vp of IModelApp.viewManager) {
      AdjustElevationTool.updateAuxiliaryCoordinateSystemInVp(vp);
    }
  }

  private updateBackgroundMap(props: BackgroundMapProps): Promise<void> {
    const vp = IModelApp.viewManager.selectedView as ScreenViewport;
    if (vp) {
      vp.changeBackgroundMapProps(props);
      // NineZoneSampleApp.onMapSettingChanged.raiseEvent(vp); // this will updateAuxiliaryCoordinateSystem
      // vp.synchWithView(false);
      vp.synchWithView({ noSaveInUndo: true });
    }
    return Promise.resolve();
  }

  public static isEnabled(vp: Viewport): boolean {
    if (vp && vp.view.displayStyle.backgroundMapSettings.applyTerrain)
      return false;
    return true;
  }

  public async onDataButtonDown(ev: BeButtonEvent): Promise<EventHandled> {
    const hit = await IModelApp.locateManager.doLocate(new LocateResponse(), true, ev.point, ev.viewport, ev.inputSource);
    if (hit !== undefined) {
      const vp = IModelApp.viewManager.selectedView as ScreenViewport;
      if (!AdjustElevationTool.isEnabled(vp)) {

        for (const vp of IModelApp.viewManager) {
          const bgMapSettings = vp.backgroundMapSettings as BackgroundMapSettings;
          const bgMapGeom = vp.view.displayStyle.getBackgroundMapGeometry()!;
          let altitude = bgMapGeom.getPointHeight(hit.hitPoint);
          const planeAltitude = bgMapGeom.getPlane().altitude(hit.hitPoint);
          altitude = planeAltitude;
          if (altitude) {
            // const newGroundBias: mapElevation = {flatMapElevation: vp.backgroundMapSettings.terrainSettings.heightOrigin + altitude};
            const elevationOffset: mapElevation = {flatMapElevation: vp.backgroundMapSettings.groundBias, terrainElevation: vp.backgroundMapSettings.terrainSettings.heightOrigin + altitude}
            DatabaseApiClient.updateTowerElevationSetting(elevationOffset, ConfigManager.projectId!);     
            AdjustElevationTool.updateGroundBias(vp, bgMapSettings, elevationOffset);       
            const messageDetails: NotifyMessageDetails = new NotifyMessageDetails(
              OutputMessagePriority.Info,
              IModelApp.localization.getLocalizedString("DigitalTwinViewerLoc:tools.adjustElevation.rdsSettings.saveElevationTerrainOnBrief"),
              IModelApp.localization.getLocalizedString("DigitalTwinViewerLoc:tools.adjustElevation.rdsSettings.saveElevationTerrainOnDetails"),
              OutputMessageType.Toast,
              OutputMessageAlert.Dialog,
            );
            MessageManager.addMessage(messageDetails);
          }
        }  
  


        // Tool not enable -> Display an informative message
        // const messageDetails: NotifyMessageDetails = new NotifyMessageDetails(
        //   OutputMessagePriority.Warning,
        //   IModelApp.localization.getLocalizedString("DigitalTwinViewerLoc:tools.adjustElevation.AdjustElevationDisableBrief"),
        //   IModelApp.localization.getLocalizedString("DigitalTwinViewerLoc:tools.adjustElevation.AdjustElevationDisableDetail"),
        //   OutputMessageType.Toast,
        //   OutputMessageAlert.Dialog,
        // );
        // MessageManager.addMessage(messageDetails);
        // this.exitTool();
        // return EventHandled.No;
      } else if(vp) {
        for (const vp of IModelApp.viewManager) {
          const bgMapSettings = vp.backgroundMapSettings as BackgroundMapSettings;
          const bgMapGeom = vp.view.displayStyle.getBackgroundMapGeometry()!;
          let altitude = bgMapGeom.getPointHeight(hit.hitPoint);
          const planeAltitude = bgMapGeom.getPlane().altitude(hit.hitPoint);
          altitude = planeAltitude;
          if (altitude) {
            // const newGroundBias: mapElevation = {flatMapElevation: vp.backgroundMapSettings.groundBias + altitude, terrainElevation: };
            const elevationOffset: mapElevation = {flatMapElevation: vp.backgroundMapSettings.groundBias + altitude, terrainElevation: vp.backgroundMapSettings.terrainSettings.heightOrigin}
            AdjustElevationTool.updateGroundBias(vp, bgMapSettings, elevationOffset);  
            await DatabaseApiClient.updateTowerElevationSetting(elevationOffset, ConfigManager.projectId!);     
            const messageDetails: NotifyMessageDetails = new NotifyMessageDetails(
              OutputMessagePriority.Info,
              IModelApp.localization.getLocalizedString("DigitalTwinViewerLoc:tools.adjustElevation.rdsSettings.saveElevationTerrainOffBrief"),
              IModelApp.localization.getLocalizedString("DigitalTwinViewerLoc:tools.adjustElevation.rdsSettings.saveElevationTerrainOffDetails"),
              OutputMessageType.Toast,
              OutputMessageAlert.Dialog,
            );
            MessageManager.addMessage(messageDetails);
          }
        }
      }
    }

    this.exitTool();
    return EventHandled.Yes;
  }

  public static updateGroundBias = (vp, bgMapSettings, newGroundBias: mapElevation) => {
    if (undefined !== bgMapSettings.groundBias && newGroundBias.flatMapElevation) {
      vp.changeBackgroundMapProps({
        applyTerrain: bgMapSettings.applyTerrain,
        groundBias: parseFloat(newGroundBias.flatMapElevation as string),
        transparency: bgMapSettings.transparency,
        useDepthBuffer: bgMapSettings.useDepthBuffer,
      });
    }

    if (undefined !== bgMapSettings.terrainSettings && newGroundBias.terrainElevation) {
      vp.changeBackgroundMapProps({
        applyTerrain: bgMapSettings.applyTerrain,
        terrainSettings: {
          heightOrigin: parseFloat(newGroundBias.terrainElevation as string),
          heightOriginMode: bgMapSettings.terrainSettings.heightOriginMode,
        },
        transparency: bgMapSettings.transparency,
        useDepthBuffer: bgMapSettings.useDepthBuffer,
      });
    }
    vp.synchWithView({ noSaveInUndo: true });
    DigitalTwinViewerApp.onMapSettingChanged.raiseEvent(vp);
  }

  public async onResetButtonUp(_ev: BeButtonEvent): Promise<EventHandled> {
    this.exitTool();
    return EventHandled.No;
  }

  public onRestartTool(): Promise<void> {
    const tool = new AdjustElevationTool();
    if (!tool.run())
      this.exitTool();
    return Promise.resolve();
  }
}
