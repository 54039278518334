import { ToolbarItemUtilities } from "@itwin/appui-abstract";
import { RenderMode } from "@itwin/core-common";
import { IModelApp, NotifyMessageDetails, OutputMessagePriority, OutputMessageType } from "@itwin/core-frontend";
// import { CustomToolbarProvider } from "./CustomToolbarProvider";
import EquipmentClient from "../api/equipment";
import TrackTelemetryApiClient from "../api/trackTelemetry";
import BracingsClient from "../api/bracingsClient";
import { AddEditBracingTool } from "../components/tools/AddEditBracingTool";
import { HighlightBracingsTool } from "../components/tools/HighlightBracingsTool";
import { SyncUiEventDispatcher, UiFramework, WidgetState } from "@itwin/appui-react";
import { Logger } from "../api/logging";
import { RootState } from "../../store/States";
import { store } from "../../store/rootReducer";
import { setBracingsData, setSelectedObjectStructure, setSiteCoordinate, setTowerData } from "../../store/detectedData/apiDataActionTypes";
import { DTVActions } from "../../store/Actions";
import { SyncUiEventIds } from "../../store/redux-types";
import { ObjectCategoryTypes, initialPropertyListObjectState } from "../../store/detectedData/apiDataTypes";
import { DigitalTwinViewerApp } from "../../api/DigitalTwinViewerApp";
import { BracingDecorator } from "./decorators/BracingDecorator";

const select = (state: RootState, dataKey: string) => {
    return state.detectedData[dataKey];
}
  
export const HighlightBracings = () => {

    return ToolbarItemUtilities.createActionButton(
        "Highlight-bracings", 
        110, 
        "icon-crop", 
        "Highlight Bracings",
        async () => await executeHighlightBracing(true)
    );






    // async () => {
    //     // TrackTelemetryApiClient.trackTelemetry("TOWER_ITWIN_HIGHLIGHT_EQUIPMENT");
    //     // if (!this._isInsiteSupported) {
    //     //   this.showNotSupportedNotification();
    //     //   return;
    //     // }
    //     // this.siteCoord;
    //     if (!siteCoordinates) {
    //         const siteCoord = await EquipmentClient.setSiteCoordinates(store.getState().auth.accessTokenStatePrivateAPI.accessToken /*accessTokenPrivate is a string now*/);     // Storing site coordinate, for if equipments are edited & require update relative to site <coordinates className="" />
    //         store.dispatch(setSiteCoordinate(siteCoord));
    //     }
    //     if (!SampleToolWidget.equipNamePositionMap || SampleToolWidget.equipNamePositionMap.size === 0) {
    //         IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Error,`No Equipment detected in this tower.`, "", OutputMessageType.Toast));
    //         return;
    //     }
    //     let towerStructure = select(store.getState(), "towerStructureData");
        
    //     if (!towerStructure) {
    //         towerStructure = await EquipmentClient.getTowerStructureJson(store.getState().auth.accessTokenStatePrivateAPI.accessToken /*accessTokenPrivate is a string now*/);   // Storing tower structure, for if equipments are edited they can be validated with the tower elevation values.
    //         store.dispatch(setTowerData(towerStructure));
    //     }

    //     const vp = IModelApp.viewManager.selectedView;
    //     if (vp) {
    //         const viewFlags = vp.viewFlags.copy({renderMode: RenderMode.SmoothShade});
    //         vp.viewFlags = viewFlags;
    //     }

    //     let toolRetVal: boolean = false;

    //     setTimeout(async () => {
    //         toolRetVal = await IModelApp.tools.run(OutlineEquipmentTool.toolId);
    //         // (currTools.filter(e=>e.id == "Select")[0] as ActionButton).execute();   //  Making the select tool enabled after equipment loading for abetter user experience. 
    //         if(toolRetVal){
    //             IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Info, "Equipment are successfully loaded.", "", OutputMessageType.Toast));
    //             // this.setState({operatorFilterData: {...this.state.operatorFilterData, selectedEquipment: ["All"]}});
    //         } else {
    //             // this.setState({operatorFilterData: {...this.state.operatorFilterData, selectedEquipment: []}});
    //         }
    //         // this.setState({highlightStates: {...this.state.highlightStates, equipment: toolRetVal}});
    //         // this._resetOperatorFilterWidget();
    //     }, 0);

    //     // tslint:disable-next-line:tslint-react-set-state-usage
    //     // if (this.state.isVisible) {
    //         //   this.setState({ isVisible: false });
    //         // }
    //         // if (!this.state.disableCloneEquipment) {
    //     //   this.setState({ disableCloneEquipment: true });
    //     // }
    //     // (currTools.filter(e=>e.id == "Select")[0] as ActionButton).execute();   //  Making the select tool enabled after equipment loading for abetter user experience. 
    //     // this.setState({ disableEditEquipment: !this.state.disableEditEquipment });
    // });
};

export const executeHighlightBracing = async (showMessage:boolean = true) => {
    
    const currentState = store.getState();
    let siteCoordinates = select(currentState, "siteCoordinate");
    // TrackTelemetryApiClient.trackTelemetry("TOWER_ITWIN_HIGHLIGHT_MOUNT");
    // if (!this._isInsiteSupported) {
    //   this.showNotSupportedNotification();
    //   return;
    // }
    if (!siteCoordinates) {
        siteCoordinates = await EquipmentClient.setSiteCoordinates(currentState.auth.accessTokenStatePrivateAPI.accessToken /*accessTokenPrivate is a string now*/);     // Storing site coordinate, for if equipments are edited & require update relative to site <coordinates className="" />
        store.dispatch(setSiteCoordinate(siteCoordinates));
    }
    
    if(DigitalTwinViewerApp.projectDetails.isInsightAvailable){
        const highlightStates = currentState.dtvState.applicationState.highlightStates;
        if(store.getState().dtvState.applicationState.highlightStates.bracing){
            if(currentState.detectedData.selectedObjectInformation.objectProperties.objectCategoryType == ObjectCategoryTypes.Bracings_RM)store.dispatch(setSelectedObjectStructure(initialPropertyListObjectState));
            store.dispatch(DTVActions.updateHighlightState({...highlightStates, bracing: false}))

            executeUnhighlightBracing();
            return;
        } else {
            store.dispatch(DTVActions.updateHighlightState({...highlightStates, bracing: true}))
            SyncUiEventDispatcher.dispatchSyncUiEvent(SyncUiEventIds.RM_Bracing_Highlighted);           
        }
    }
    if(store.getState().dtvState.featureControls.restrictCrudOperations && !store.getState().dtvState.applicationState.isLoggedInUserAdmin)
        SyncUiEventDispatcher.dispatchSyncUiEvent(SyncUiEventIds.Not_Show_All_Detected_Objects);    
    // let mountsData = select(currentState, "mountingsData");
    let towerStructure = select(currentState, "towerStructureData");
    let towerBracings = select(currentState, "towerBracingsData");
    
    if (!towerStructure) {
        towerStructure = await EquipmentClient.getTowerStructureJson(currentState.auth.accessTokenStatePrivateAPI.accessToken /*accessTokenPrivate is a string now*/);   // Storing tower structure, for if equipments are edited they can be validated with the tower elevation values.
        store.dispatch(setTowerData(towerStructure));
    }
    const bd = IModelApp.viewManager.decorators.filter(e=>e.constructor.name.includes("BracingDecorator"));

    if (!towerBracings.length) {
        try {
            towerBracings = await BracingsClient.getBracingsData(currentState.auth.accessTokenStatePrivateAPI.accessToken!);
            if(towerBracings){
                const vp = IModelApp.viewManager.selectedView;
                if (vp) {
                    const viewFlags = vp.viewFlags.copy({renderMode: RenderMode.SmoothShade});
                    vp.viewFlags = viewFlags;
                }
                AddEditBracingTool.isBracingEditable = false;
                
                // FrontstageManager.activeFrontstageDef?.getZoneDef(ZoneLocation.CenterRight)?.findWidgetDef("BracingAddEdit")?.setWidgetState(WidgetState.Closed);
                if (!bd.length) {
                //   let toolRetVal: boolean=false;
                //   setTimeout(() => {
                    // run the highlight class
                    let toolRetVal: boolean=false;
                    await IModelApp.tools.run(HighlightBracingsTool.toolId, true).then(result => toolRetVal=result);
                    // CHANGE THE MOUSE POINTER !!!! (make it for select objects)
                    // (currTools.filter(e=>e.id == "Select")[0] as ActionButton).execute();
                //   }, 0);
                //   if (toolRetVal)this.setState({ isBracingsVisible: true});
                //   this.setState({highlightStates: {...this.state.highlightStates, bracing: true}});
                } 
                else {
                //   this.setState({isBracingsVisible: false});
                //   this.setState({highlightStates: {...this.state.highlightStates, bracing: false}});
                    IModelApp.tools.run(HighlightBracingsTool.toolId, [], false);
                    UiFramework.frontstages.activeFrontstageDef?.findWidgetDef("BracingEditWidget")?.setWidgetState(WidgetState.Hidden);
                }

                store.dispatch(setBracingsData(towerBracings));
            } else {
                let pipeDecor = IModelApp.viewManager.decorators.filter(e => e.constructor.name.includes("BracingDecorator"))[0] as BracingDecorator;

                if(pipeDecor === undefined){
                    const vp = IModelApp.viewManager.selectedView;
                    if (undefined !== vp) {    
                        // This approach doesn't work well with camera turned on
                        // vp.zoom(undefined, 0.3, { animateFrustumChange: true });
                    }    
                    // Create the decorator 
                    pipeDecor = new BracingDecorator();
                    // add decorator to the view manager
                    IModelApp.viewManager.addDecorator(pipeDecor);
                }
        
                if(showMessage)IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Error,`No Bracings detected in this tower.`, "", OutputMessageType.Toast));
            }                    
        } catch (error) {
            Logger.error('Error occured while fetching towerBracings: ', error);
        }
    } else if(!bd.length){
        await IModelApp.tools.run(HighlightBracingsTool.toolId, true)
    } else IModelApp.tools.run(HighlightBracingsTool.toolId, [], false);


    // if (bracingsData && tdata) {
    //     if (tdata['nLegs'] >= 3) {//Only enable with sst+
    //         return true
    //     }
    //     else {
    //         return false
    //     }
    // }
    // else {
    //     return false;
    // }
        
}

export const executeUnhighlightBracing = () => {

    IModelApp.tools.run(HighlightBracingsTool.toolId, [], false);
    SyncUiEventDispatcher.dispatchSyncUiEvent(SyncUiEventIds.RM_Bracing_UnHighlighted);
    UiFramework.dialogs.modeless.close("Edit Dialog")
    UiFramework.frontstages.activeFrontstageDef?.findWidgetDef("BracingEditWidget")?.setWidgetState(WidgetState.Hidden);
    store.dispatch(DTVActions.setEditModeFlag(false));
    return;
}
store.subscribe(HighlightBracings);