import { IModelApp } from "@itwin/core-frontend";
import { ConfigManager } from "../../config/ConfigManager";
import { store } from "../../store/rootReducer";
import { getUnit, getValueInRequiredUnit } from "./Tables/BaseTable";
import { LadderDecorator } from "../tools/decorators/LadderDecorator";
import { FeedlineDecorator } from "../tools/decorators/FeedlineDecorator";
import { DecoratorHelper } from "../tools/decorators/DecoratorHelper";
import { UiFramework } from "@itwin/appui-react";

export const getFeedLineKeyValue = (clusterId: string) => {
    // if(clusterId.toString().match(/CustomFeedline/))return [];
    let feedlineDec = IModelApp.viewManager.decorators.filter(e=>e.constructor.name.includes("FeedlineDecorator"))[0] as FeedlineDecorator;
    const flData = feedlineDec.shapes.find(e=>e.modelData?.Id==clusterId.toString());
    if(!flData)return [];
    // const feedLineData = store.getState().detectedData.feedLinesData;
    // const fData = feedLineData.filter(e=>e.clusterId==clusterId)[0];
    // const xPoint = (fData.endCenter.x ? fData.endCenter.x : 0) -(fData.startCenter.x ? fData.startCenter.x : 0) ;
    // const yPoint = (fData.endCenter.y ? fData.endCenter.y : 0) -(fData.startCenter.y ? fData.startCenter.y : 0) ;
    // const zPoint = (fData.endCenter.z ? fData.endCenter.z : 0) -(fData.startCenter.z ? fData.startCenter.z : 0) ;
    // const length = Math.sqrt(Math.pow(xPoint,2) + Math.pow(yPoint,2) + Math.pow(zPoint,2));
    const len = flData?.modelData?.ActualPositions.base.distance(flData.modelData.ActualPositions.top);
    const iModel = UiFramework.getIModelConnection()!;
    const stPtCart = iModel!.spatialToCartographicFromEcef(flData?.modelData?.ActualPositions.base!);
    const endPtCart = iModel!.spatialToCartographicFromEcef(flData?.modelData?.ActualPositions.top!);

    const topEl = stPtCart.height;
    const botEl = endPtCart.height;
    const el2 = (topEl+botEl)/2
    let stpt = DecoratorHelper.convertWGS2UTM(stPtCart);
    let enpt = DecoratorHelper.convertWGS2UTM(endPtCart);

    // let ladderDec = IModelApp.viewManager.decorators.filter(e=>e.constructor.name.includes("LadderDecorator"))[0] as LadderDecorator;
    // if(!ladderDec){
    //     ladderDec = new LadderDecorator();
    // }
    // let {topElevation,bottomElevation,elevation} = ladderDec.getPropertiesCalulation(fData.startCenter ,fData.endCenter);
    const towerdata = store.getState().detectedData.towerStructureData;
    const concreteHeight = ConfigManager.AGL ? Object.entries(towerdata).filter((f) => f[0] === "concrete_height")[0][1] : 0;

    let nameValuePair: { name: string, value: string }[] | any = [];
    // nameValuePair.push({ name: `Length ${getUnit()}`, value: getValueInRequiredUnit(length)});
    // nameValuePair.push({ name: `Width ${getUnit()}`, value: getValueInRequiredUnit(fData.width)});
    // nameValuePair.push({ name: `Thickness ${getUnit()}`, value: getValueInRequiredUnit(fData.height)});
    // nameValuePair.push({ name: `Top Elevation ${getUnit()} *`, value: getValueInRequiredUnit(bottomElevation + concreteHeight)});
    // nameValuePair.push({ name: `Bottom Elevation ${getUnit()} *`, value: getValueInRequiredUnit(topElevation + concreteHeight)});
    // nameValuePair.push({ name: `Centerline Elevation ${getUnit()} *`, value: getValueInRequiredUnit(elevation + concreteHeight)});

    nameValuePair.push({ name: `Length ${getUnit()}`, value: getValueInRequiredUnit(len)});
    nameValuePair.push({ name: `Width ${getUnit()}`, value: getValueInRequiredUnit(flData?.modelData?.Width)});
    nameValuePair.push({ name: `Thickness ${getUnit()}`, value: getValueInRequiredUnit(flData?.modelData?.Thickness)});
    nameValuePair.push({ name: `Top Elevation ${getUnit()} *`, value: getValueInRequiredUnit(botEl + concreteHeight)});
    nameValuePair.push({ name: `Bottom Elevation ${getUnit()} *`, value: getValueInRequiredUnit(topEl + concreteHeight)});
    nameValuePair.push({ name: `Centerline Elevation ${getUnit()} *`, value: getValueInRequiredUnit(el2 + concreteHeight)});

    return nameValuePair;

}
