import { ToolbarItemUtilities } from "@itwin/appui-abstract";
import { RenderMode } from "@itwin/core-common";
import { IModelApp, NotifyMessageDetails, OutputMessagePriority, OutputMessageType } from "@itwin/core-frontend";
import EquipmentClient from "../api/equipment";
import TrackTelemetryApiClient from "../api/trackTelemetry";
import { StagePanelLocation, SyncUiEventDispatcher, UiFramework, WidgetState } from "@itwin/appui-react";
import { RootState } from "../../store/States";
import { setSiteCoordinate, setTowerData, setTowerData3DMaps, addToBuilt3DObjectIdsMap, setSelectedObjectStructure } from "../../store/detectedData/apiDataActionTypes";
import { store } from "../../store/rootReducer";
import { TowerGeometry, TowerStructureDecorator, selectedTowerSection } from "./decorators/TowerStructureDecorator";
import { DTVActions } from "../../store/Actions";
import { ObjectCategoryTypes, initialPropertyListObjectState } from "../../store/detectedData/apiDataTypes";
import { Id64String } from "@itwin/core-bentley";
import { editOnClose } from "../components/HorizontalToolbarItems";

function select(state: RootState, dataKey: string) {
    return state.detectedData[dataKey];
}
  
export const HighlightTower = () => {

    return ToolbarItemUtilities.createActionButton(
        "TowerStructure",
        110,
        "icon-merge",
        "Highlight Tower Structure",
        // this.state.highlightStates.tower,
        async () => await executeHighlightTower(true)
    )




    // async () => {
    //     // TrackTelemetryApiClient.trackTelemetry("TOWER_ITWIN_HIGHLIGHT_EQUIPMENT");
    //     // if (!this._isInsiteSupported) {
    //     //   this.showNotSupportedNotification();
    //     //   return;
    //     // }
    //     // this.siteCoord;
    //     if (!siteCoordinates) {
    //         const siteCoord = await EquipmentClient.setSiteCoordinates(store.getState().auth.accessTokenStatePrivateAPI.accessToken /*accessTokenPrivate is a string now*/);     // Storing site coordinate, for if equipments are edited & require update relative to site <coordinates className="" />
    //         store.dispatch(setSiteCoordinate(siteCoord));
    //     }
    //     if (!SampleToolWidget.equipNamePositionMap || SampleToolWidget.equipNamePositionMap.size === 0) {
    //         IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Error,`No Equipment detected in this tower.`, "", OutputMessageType.Toast));
    //         return;
    //     }
    //     let towerStructure = select(store.getState(), "towerStructureData");
        
    //     if (!towerStructure) {
    //         towerStructure = await EquipmentClient.getTowerStructureJson(store.getState().auth.accessTokenStatePrivateAPI.accessToken /*accessTokenPrivate is a string now*/);   // Storing tower structure, for if equipments are edited they can be validated with the tower elevation values.
    //         store.dispatch(setTowerData(towerStructure));
    //     }

    //     const vp = IModelApp.viewManager.selectedView;
    //     if (vp) {
    //         const viewFlags = vp.viewFlags.copy({renderMode: RenderMode.SmoothShade});
    //         vp.viewFlags = viewFlags;
    //     }

    //     let toolRetVal: boolean = false;

    //     setTimeout(async () => {
    //         toolRetVal = await IModelApp.tools.run(OutlineEquipmentTool.toolId);
    //         // (currTools.filter(e=>e.id == "Select")[0] as ActionButton).execute();   //  Making the select tool enabled after equipment loading for abetter user experience. 
    //         if(toolRetVal){
    //             IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Info, "Equipment are successfully loaded.", "", OutputMessageType.Toast));
    //             // this.setState({operatorFilterData: {...this.state.operatorFilterData, selectedEquipment: ["All"]}});
    //         } else {
    //             // this.setState({operatorFilterData: {...this.state.operatorFilterData, selectedEquipment: []}});
    //         }
    //         // this.setState({highlightStates: {...this.state.highlightStates, equipment: toolRetVal}});
    //         // this._resetOperatorFilterWidget();
    //     }, 0);

    //     // tslint:disable-next-line:tslint-react-set-state-usage
    //     // if (this.state.isVisible) {
    //         //   this.setState({ isVisible: false });
    //         // }
    //         // if (!this.state.disableCloneEquipment) {
    //     //   this.setState({ disableCloneEquipment: true });
    //     // }
    //     // (currTools.filter(e=>e.id == "Select")[0] as ActionButton).execute();   //  Making the select tool enabled after equipment loading for abetter user experience. 
    //     // this.setState({ disableEditEquipment: !this.state.disableEditEquipment });
    // });
};

export const executeHighlightTower = async (showMessage:boolean = true) => {
    const siteCoordinates = select(store.getState(), "siteCoordinate");
    let towerStructure = select(store.getState(), "towerStructureData");
    // this.siteCoord;
    if (!siteCoordinates) {
        const siteCoord = await EquipmentClient.setSiteCoordinates(store.getState().auth.accessTokenStatePrivateAPI.accessToken /*accessTokenPrivate is a string now*/);     // Storing site coordinate, for if equipments are edited & require update relative to site <coordinates className="" />
        store.dispatch(setSiteCoordinate(siteCoord));
    }
    // SampleToolWidget.selectedList = ListEnum.TowerGeometry;
    // if (!this._isInsiteSupported) {
    //     this.showNotSupportedNotification();
    //     return;
    // }
    if (!Object.entries(towerStructure).length) {
        towerStructure = await EquipmentClient.getTowerStructureJson(store.getState().auth.accessTokenStatePrivateAPI.accessToken /*AccessToken is a string now*/)// Storing tower structure, for if equipments are edited they can be validated with the tower elevation values.
        if(towerStructure)store.dispatch(setTowerData(towerStructure));
    }
    const result = towerStructure;
    if (result == null || !result.type) {
       if(showMessage) IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Error, "This feature is not supported for this project.", "", OutputMessageType.Toast));
        return;
    }
    let flag: boolean = false;

    let towerDec: any = IModelApp.viewManager.decorators.find((e) => e.constructor.name.includes("TowerStructureDecorator"))!;
    if(towerDec) {
        towerDec = towerDec as TowerStructureDecorator;
        flag = true;
    }
    const currentState: RootState = store.getState();
    if(currentState.dtvState.applicationState.highlightStates.tower){
        store.dispatch(DTVActions.updateHighlightState({...currentState.dtvState.applicationState.highlightStates, tower: false}));
        if(currentState.detectedData.selectedObjectInformation.objectProperties.objectCategoryType == ObjectCategoryTypes.Tower_RM)store.dispatch(setSelectedObjectStructure(initialPropertyListObjectState));       
        const widget = UiFramework.frontstages.activeFrontstageDef?.findWidgetDef("TowerEditWidget");
        if(widget?.state == WidgetState.Open)widget?.setWidgetState(WidgetState.Hidden);

        executeUnHighlightTower();
        return;
    } else {
        store.dispatch(DTVActions.updateHighlightState({...currentState.dtvState.applicationState.highlightStates, tower: true}));
    }

    const vp = IModelApp.viewManager.selectedView;
    if (vp) {
        const viewFlags = vp.viewFlags.copy({renderMode: RenderMode.SmoothShade});
        vp.viewFlags = viewFlags;
    }
    if(result) {
        towerDec = new TowerStructureDecorator();
        towerDec.loadShapes(result);
        IModelApp.viewManager.addDecorator(towerDec);
        store.dispatch(setTowerData3DMaps(towerDec.nameIdMap));
        store.dispatch(addToBuilt3DObjectIdsMap(new Map(towerDec.objectIdMap)));
        const imc = UiFramework.getIModelConnection();
        imc?.selectionSet.emptyAll();
        // const {cylinder, color, fill, fillColor, lineThickness, edges, linePixels, startPos, endPos, topWid, baseWid, name} = towerDec.towercylinders[0];
        // const abc: selectedTowerSection = {
        //     transId: "",
        //     sectionName: name,
        //     theSection: null,
        //     isModified: false,
        //     creating: false,
        //     created: false
        // };
        const sectionIndex = towerDec.towerCylinders.findIndex(e=>e.name=="Ground level@Marker");
        TowerStructureDecorator.selectedSection.sectionName = towerDec.towerCylinders[sectionIndex].name;
        TowerStructureDecorator.selectedSection.theSection = towerDec.towerCylinders[sectionIndex];
        imc?.selectionSet.add([...new Set(towerDec.objectIdMap.keys())][1]! as Id64String);
        // SampleToolWidget.currentList=ListEnum.TowerGeometry;
        UiFramework.frontstages.activeFrontstageDef?.getStagePanelDef(StagePanelLocation.Right)?.
        findWidgetDef("PropertyListWidget")?.setWidgetState(WidgetState.Open);
        SyncUiEventDispatcher.dispatchSyncUiEvent("tower-selected");
        // this.setState({highlightStates: {...this.state.highlightStates, tower: !flag}});
        // (currTools.filter(e=>e.id == "Select")[0] as ActionButton).execute();   //  Making the select tool enabled after equipment loading for abetter user experience. 
    } else {
       if(showMessage) IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Error,`No Tower Structure Data found for this project .`, "", OutputMessageType.Toast));
    }
    IModelApp.viewManager.selectedView?.invalidateDecorations();
    TrackTelemetryApiClient.trackTelemetry("TOWER_ITWIN_VIEWER_TOWER_STRUCTURE");
}

export const executeUnHighlightTower = () => {
    editOnClose("TowerStructureDecorator",true);
    let flag: boolean = false;

    let towerDec: any = IModelApp.viewManager.decorators.filter((e) => e.constructor.name.includes("TowerStructureDecorator"));
    if(towerDec.length) {
        towerDec = towerDec[0] as TowerStructureDecorator;
        flag = true;
    }
  towerDec!.terminate();
        IModelApp.viewManager.dropDecorator(towerDec!);
        if (flag) {
            towerDec!.terminate();
            IModelApp.viewManager.dropDecorator(towerDec!);
            // this.setState({highlightStates: {...this.state.highlightStates, tower: !flag}})
        }
        return;
}
store.subscribe(HighlightTower);