import { Angle, Arc3d, AxisIndex, Box, Cone, CurveChainWithDistanceIndex, GeometryQuery, IndexedPolyface, IndexedPolyfaceVisitor, LineSegment3d, LineString3d, LinearSweep, Loop, Matrix3d, Path, Point3d, PolyfaceBuilder, Range3d, SolidPrimitive, Sphere, StrokeOptions, Transform, TransformProps, Vector3d, YawPitchRollAngles } from "@itwin/core-geometry";
import { Cartographic, ColorByName, ColorDef, LinePixels } from "@itwin/core-common";
import { BeButtonEvent, DecorateContext, Decorator, EventHandled, GraphicBuilder, GraphicType, HitDetail, IModelApp, RenderGraphic, ScreenViewport, SelectionTool } from "@itwin/core-frontend";
// import App from "../../components/App";
import { Id64String } from "@itwin/core-bentley";
// import { EquipmentType, SampleToolWidget } from "../frontstages/SampleToolWidget";
import { StagePanelLocation, SyncUiEventDispatcher, UiFramework, WidgetState } from "@itwin/appui-react";
import { IndividualShapeDecorator } from "./IndividualShapeDecorator";
import { ModifyHandleDecoration } from "../../components/tools/modification/ModifyHandleDecoration";
import { DecoratorHelper } from "./DecoratorHelper";
import { EquipmentsTable } from "../../components/Tables/EquipmentsTable";
import * as egm96 from "egm96-universal";
import { ConfigManager } from "../../../config/ConfigManager";
import { RootState } from "../../../store/States";
import { FeedlineSectionType, feedLineData, objectProperties, towerStructureData } from "../../../store/detectedData/apiDataTypes";
import { EquipmentType, SyncUiEventIds } from "../../../store/redux-types";
import { store } from "../../../store/rootReducer";
import FeederLineClient, { feedlineDataStructure } from "../../api/feederLinesClient";
import { setFeedLineData } from "../../../store/detectedData/apiDataActionTypes";
import { PointSelectionTool } from "../../components/tools/PointSelectionTool";
import { getUnlinkedFeedline } from "../DetectedFeedlineEditHandler";
import { resetObjectIds } from "../../components/HorizontalToolbarItems";
const iModelConnection = UiFramework.getIModelConnection();
// const select = (state: RootState, dataKey: string) => {
//   return state.dtvState.featureControls[dataKey];
// }

let editModeActive: boolean = false, iTwinDisplayFrontFace: boolean = false, all3DObjectsMap: {}, selectedObjectInfo: objectProperties;
const listener = () => {
    setCurrentState(store.getState());
}

export interface FeedlineData {
  Azimuth: number;
  // Equipment_Name?: string;
  Height: number;
  Width: number;
  Thickness: number;
  // Roll?: number;
  // Tilt?: number;
  // x_position?: number;
  // y_position?: number;
  // z_position?: number;9
  // Model?: string;
  // Manufacturer?: string;
  Id: string;
  // Elevation_Height?: number;
  // DisplayName?: string;
  CenterPositions: {localBase: Point3d, localTop: Point3d};
  ActualPositions: {base: Point3d, top: Point3d};
  FeedlineType: {type: FeedlineSectionType, segmentCount: number, absoluteLength: number};
}

function setCurrentState(state: RootState) {
    selectedObjectInfo: state.detectedData.selectedObjectInformation.objectProperties;
    editModeActive = state.dtvState.applicationState.isEditModeActive;
    iTwinDisplayFrontFace = state.dtvState.featureControls.iTwinDisplayFrontFace;
    // equipDataMaps = state.detectedData.equipmentDataMaps;
    // tempEquipMap = equipDataMaps.tempEquipMap;
    all3DObjectsMap = state.detectedData.built3DObjectIdMaps;
}

store.subscribe(listener);


export interface CustomGeometryQuery {
  geometry: GeometryQuery;
  color: ColorDef;
  fill: boolean;
  fillColor: ColorDef;
  lineThickness: number;
  edges: boolean;
  capped: boolean;
  linePixels: LinePixels;
  transientId: Id64String;
  name: string|number;
  modelData: FeedlineData|undefined;
}
export interface BoxEntry {
  box: any;
  name?: string;
  modelData?: FeedlineData;
}

export interface CylinderEntry {
  cylinder: Cone;
  name: string;
  color: ColorDef;
}

export enum ShapeType {
  Box,
  Cylinder,
}

export enum CreateGeometryMode {
  New,
  Edit,
  DrawBox,
  DrawFeedlineBox
}
// tslint:disable:naming-convention
// tslint:disable:no-shadowed-variable
export class FeedlineDecorator implements Decorator {
  public useCachedDecorations: true | undefined = true;
  public decoratorName = "FeedlineDecorator";

  public shapes: CustomGeometryQuery[] = [];
  public loadedShapes: boolean = false;

  public boxes: BoxEntry[] = [];
  private drawnBoxes: BoxEntry[] = [];
  private antennaBoxes: BoxEntry[] = [];
  private defectBoxes: BoxEntry[] = [];
  private rruBoxes: BoxEntry[] = [];
  public static sourceId: any;
  private microWaveCylinders: CylinderEntry[] = [];
  private drawnCylinders: CylinderEntry[] = [];
  private defectBoxInfo: any = [];
  public currJson: FeedlineData[] = [];
  public untouchedFeedlineData: FeedlineData|undefined = undefined;
  public untouchedFeedlineSegments: FeedlineData[] = [];
  public nameIdMap: Map<string, Id64String> = new Map<string, Id64String>();
  public objectIdMap: Map<Id64String, string> = new Map<string, Id64String>();
  public static selectedFeedline: CustomGeometryQuery;
  private fill: boolean = true;
  private color: ColorDef = ColorDef.blue;
  private lineThickness: number = 1;
  private edges: boolean = true;
  private capped: boolean = true;
  private linePixels = LinePixels.Solid;
  public equipColors = {
    antenna: {bodyCol: ColorDef.from(71, 54, 165), faceCol: ColorDef.from(22, 28, 86, 1)},
    microwave: {bodyCol: ColorDef.from(5, 165, 120), faceCol: ColorDef.from(13, 96, 6, 1)},
    rru: {bodyCol: ColorDef.from(180, 86, 94), faceCol: ColorDef.from(99, 16, 20, 1)},
  }
  public segmentAdded: boolean = false;
  public selectedFeedline: {transId: Id64String, modelId: string} = {transId: "", modelId: ""};
  

  public createGeometry = (feedlineJson: FeedlineData, mode: CreateGeometryMode = CreateGeometryMode.DrawBox) => {
    let theGeometry, edges: boolean = this.edges, capped: boolean = this.capped, color = this.equipColors.antenna.bodyCol, builder, polyface;
    if(mode == CreateGeometryMode.DrawFeedlineBox){
      theGeometry = this.addFeedline(feedlineJson.CenterPositions.localBase, feedlineJson.CenterPositions.localTop, feedlineJson.Width, feedlineJson.Thickness, feedlineJson!);
      const options = StrokeOptions.createForCurves();
      options.needParams = false;
      options.needNormals = true;
      builder = PolyfaceBuilder.create(options);
      polyface = builder.claimPolyface(false);
      color = ColorDef.fromTbgr(ColorDef.withTransparency(ColorDef.create(ColorByName.aqua).tbgr, 0))
      builder.addLinearSweep(theGeometry);
      edges = true;
    } else if(mode == CreateGeometryMode.Edit){
      const newJsons = this.getEditBoxJson(feedlineJson);
      const colors = [ColorByName.red, ColorByName.aqua, ColorByName.purple, ColorByName.blue];

      newJsons.forEach((e,i)=>{
        const part = e.Id.split('-');
        let theColor: number;
        switch (part[part.length-1]) {
          case "start":
            theColor = colors[0];
            break;
          case "middle":
            theColor = colors[1];
            break;
          case "segment":
            theColor = colors[2];
            break;
          case "end":
            theColor = colors[3];
            break;
        
          // default:
          //   break;
        }
        const theGeometry = this.constructBoxGeometry(e!);
        builder = PolyfaceBuilder.create();
        builder.addBox(theGeometry);
        polyface = builder.claimPolyface(false);
        
        const entry: BoxEntry = {
          box: theGeometry,
          name: e.Id?.toString(),
          modelData: e,
        };
        this.saveIntoLocalObj(e, entry, mode);
        // color = ColorDef.fromTbgr(ColorDef.withTransparency(ColorDef.create(colors[i]).tbgr,  255-Math.round(255*store.getState().detectedData.objectOpacityState.feedline.value)))
        color = ColorDef.fromTbgr(ColorDef.withTransparency(ColorDef.create(theColor!).tbgr,  255-Math.round(255*store.getState().detectedData.objectOpacityState.feedline.value)))
        if(i==1)capped = false;
        this.addGeometry(polyface, color, e.Id!, edges, capped, e!);
      })
      

    } else if(mode == CreateGeometryMode.DrawBox){
      const theGeometry = this.constructBoxGeometry(feedlineJson!);
      builder = PolyfaceBuilder.create();
      builder.addBox(theGeometry);
      polyface = builder.claimPolyface(false);
      color = ColorDef.fromTbgr(ColorDef.withTransparency(ColorDef.create(ColorByName.aqua).tbgr,  255-Math.round(255*store.getState().detectedData.objectOpacityState.feedline.value)))
    }

    if(mode == CreateGeometryMode.New){
      const theGeometry = this.constructBoxGeometry(feedlineJson!);
      builder = PolyfaceBuilder.create();
      builder.addBox(theGeometry);
      polyface = builder.claimPolyface(false);
      const entry: BoxEntry = {
        box: theGeometry,
        name: feedlineJson.Id?.toString(),
        modelData: feedlineJson,
      };
      this.saveIntoLocalObj(feedlineJson, entry, mode);
      color = ColorDef.fromTbgr(ColorDef.withTransparency(ColorDef.create(ColorByName.aqua).tbgr,  255-Math.round(255*store.getState().detectedData.objectOpacityState.feedline.value)))
    }
    if(mode != CreateGeometryMode.Edit)this.addGeometry(polyface, color, feedlineJson.Id!, edges, capped, feedlineJson!);
  }
  
  public getAllSegmentBoxes(allFeeds: CustomGeometryQuery[]): void {
    const allActualPoints = allFeeds.map(e=>e.modelData?.ActualPositions);
    const allCenterPoints = allFeeds.map(e=>e.modelData?.CenterPositions);
    const absLen = allFeeds[0].modelData?.ActualPositions.base.distance(allFeeds[allFeeds.length-1].modelData?.ActualPositions.top!)!;
    const partRatio = 0.01;
    const reqdLen = absLen*partRatio;

    const allUniqActPoints = [allActualPoints[0]?.base, allActualPoints[0]?.top, ...allActualPoints.map((e, i)=>{if(i>0)return e?.top;return null}).filter(e=>e)];
    const allUniqCentPoints = [allCenterPoints[0]?.localBase, allCenterPoints[0]?.localTop, ...allCenterPoints.map((e, i)=>{if(i>0)return e?.localTop;return null}).filter(e=>e)];
    const theData = allFeeds[0].modelData!;
    const json = {...allFeeds[0].modelData};
    const options = StrokeOptions.createForCurves();
    options.needParams = false;
    options.needNormals = true;

    let temp: FeedlineData, theGeometry: Box;
    for(let i=0; i < allFeeds.length; i++){
      const theBox = allFeeds[i].modelData!;
      let polyface: IndexedPolyface;
      let sphere: Sphere;
      let newActualPoints: {base: Point3d, top: Point3d}={
        base: new Point3d,
        top: new Point3d
      }, newCentralPoints: {localBase: Point3d, localTop: Point3d}={
        localBase: new Point3d,
        localTop: new Point3d
      };
          const boxLen = theBox.ActualPositions.base.distance(theBox.ActualPositions.top)!;
          const reqdRatio = reqdLen/boxLen;
      switch (i) {
        case 0: {
          newActualPoints = {base: theBox.ActualPositions.base!, top: theBox.ActualPositions.base!.interpolate(reqdRatio, theBox.ActualPositions.top!)}
          newCentralPoints = {localBase: theBox.CenterPositions.localBase!, localTop: theBox.CenterPositions.localBase!.interpolate(reqdRatio, theBox.CenterPositions.localTop!)}

          temp = {
            ...theBox,
            Azimuth: theBox.Azimuth,
            // Id: `${json.Id}-${suffixes[i]}`,
            Id: `${theBox.Id}`,
            CenterPositions: newCentralPoints,
            ActualPositions: newActualPoints
          };
          
          {
            let theGeometry = this.constructBoxGeometry(temp!);
            const builder = PolyfaceBuilder.create(options);
            builder.addBox(theGeometry);
            let polyface = builder.claimPolyface(false);
            this.addGeometry(polyface, ColorDef.fromTbgr(ColorDef.withTransparency(ColorDef.create(ColorByName.blue).tbgr, 0)), "Box1", true, true, temp);
          }
            
          newActualPoints = {base: theBox.ActualPositions.base.interpolate(reqdRatio, theBox.ActualPositions.top), top: theBox.ActualPositions.base.interpolate(1-reqdRatio/2, theBox.ActualPositions.top)!}
          newCentralPoints = {localBase: theBox.CenterPositions.localBase.interpolate(reqdRatio, theBox.CenterPositions.localTop)!, localTop: theBox.CenterPositions.localBase.interpolate(1-reqdRatio/2, theBox.CenterPositions.localTop)!}

          temp = {
            ...theBox,
            Azimuth: theBox.Azimuth,
            // Id: `${json.Id}-${suffixes[i]}`,
            Id: `${theBox.Id}`,
            CenterPositions: newCentralPoints,
            ActualPositions: newActualPoints
          };

          {
            let theGeometry = this.constructBoxGeometry(temp!);
            const builder = PolyfaceBuilder.create(options);
            builder.addBox(theGeometry);
            let polyface = builder.claimPolyface(false);
            this.addGeometry(polyface, ColorDef.fromTbgr(ColorDef.withTransparency(ColorDef.create(ColorByName.aqua).tbgr, 0)), "Box2", true, true, temp);
          }
            
          newActualPoints = {base: theBox.ActualPositions.base.interpolate(1-reqdRatio/2, theBox.ActualPositions.top)!, top: theBox.ActualPositions.base!.interpolate(1+reqdRatio/2, theBox.ActualPositions.top!)}
          newCentralPoints = {localBase: theBox.CenterPositions.localBase!.interpolate(1-reqdRatio/2, theBox.CenterPositions.localTop), localTop: theBox.CenterPositions.localBase!.interpolate(1+reqdRatio/2, theBox.CenterPositions.localTop!)}

          temp = {
            ...theBox,
            Azimuth: theBox.Azimuth,
            // Id: `${json.Id}-${suffixes[i]}`,
            Id: `${theBox.Id}`,
            CenterPositions: newCentralPoints,
            ActualPositions: newActualPoints
          };

          {
            let theGeometry = this.constructBoxGeometry(temp!);
            const builder = PolyfaceBuilder.create(options);
            builder.addBox(theGeometry);
            let polyface = builder.claimPolyface(false);
            this.addGeometry(polyface, ColorDef.fromTbgr(ColorDef.withTransparency(ColorDef.create(ColorByName.purple).tbgr, 0)), "Box3", true, true, temp);
          }
            
            
        }
        break;
        case allFeeds.length-1:{
            
          newActualPoints = {base: theBox.ActualPositions.base.interpolate(reqdRatio/2, theBox.ActualPositions.top)!, top: theBox.ActualPositions.base!.interpolate(1-reqdRatio, theBox.ActualPositions.top!)}
          newCentralPoints = {localBase: theBox.CenterPositions.localBase!.interpolate(reqdRatio/2, theBox.CenterPositions.localTop), localTop: theBox.CenterPositions.localBase!.interpolate(1-reqdRatio, theBox.CenterPositions.localTop!)}

          temp = {
            ...theBox,
            Azimuth: theBox.Azimuth,
            // Id: `${json.Id}-${suffixes[i]}`,
            Id: `${theBox.Id}`,
            CenterPositions: newCentralPoints,
            ActualPositions: newActualPoints
          };

          {
            let theGeometry = this.constructBoxGeometry(temp!);
            const builder = PolyfaceBuilder.create(options);
            builder.addBox(theGeometry);
            let polyface = builder.claimPolyface(false);
            this.addGeometry(polyface, ColorDef.fromTbgr(ColorDef.withTransparency(ColorDef.create(ColorByName.aqua).tbgr, 0)), "Box3", true, true, temp);
          }
            
          newActualPoints = {base: theBox.ActualPositions.base.interpolate(1-reqdRatio, theBox.ActualPositions.top)!, top: theBox.ActualPositions.top}
          newCentralPoints = {localBase: theBox.CenterPositions.localBase!.interpolate(1-reqdRatio, theBox.CenterPositions.localTop), localTop: theBox.CenterPositions.localTop}

          temp = {
            ...theBox,
            Azimuth: theBox.Azimuth,
            // Id: `${json.Id}-${suffixes[i]}`,
            Id: `${theBox.Id}`,
            CenterPositions: newCentralPoints,
            ActualPositions: newActualPoints
          };

          {
            let theGeometry = this.constructBoxGeometry(temp!);
            const builder = PolyfaceBuilder.create(options);
            builder.addBox(theGeometry);
            let polyface = builder.claimPolyface(false);
            this.addGeometry(polyface, ColorDef.fromTbgr(ColorDef.withTransparency(ColorDef.create(ColorByName.red).tbgr, 0)), "Box3", true, true, temp);
          }
          
        }
          break;
      
        default:
          break;
      }
    }



    // for(let i=0; i < allUniqActPoints.length; i++){
    //   switch (i%2) {
    //     case 0:
    //       if(i<allUniqActPoints.length-1){
    //         // sphere = Sphere.createCenterRadius(allUniqCentPoints[i]?.interpolate(partRatio, allUniqCentPoints[i+1]!)!, 0.1);
    //         // builder.addSphere(sphere);
    //         // polyface = builder.claimPolyface(false);
    //         // this.addGeometry(polyface, ColorDef.fromTbgr(ColorDef.withTransparency(ColorDef.create(ColorByName.blanchedAlmond).tbgr, 120)), "Box", false, true, json);
    //         newActualPoints = {base: allUniqActPoints[i]!, top: allUniqActPoints[i]!.interpolate(partRatio, allUniqActPoints[i+1]!)}
    //         newCentralPoints = {localBase: allUniqCentPoints[i]!, localTop: allUniqCentPoints[i]!.interpolate(partRatio, allUniqCentPoints[i+1]!)}
  
    //         let temp: FeedlineData = {
    //           ...theData,
    //           Azimuth: theData.Azimuth,
    //           // Id: `${json.Id}-${suffixes[i]}`,
    //           Id: `${theData.Id}`,
    //           CenterPositions: newCentralPoints,
    //           ActualPositions: newActualPoints
    //         };
    //         // let theGeometry = this.constructBoxGeometry(temp!);
    //         // builder.addBox(theGeometry);
    //         // polyface = builder.claimPolyface(false);
    //         // this.addGeometry(polyface, ColorDef.fromTbgr(ColorDef.withTransparency(ColorDef.create(ColorByName.blue).tbgr, 0)), "Box", true, true, temp);
              

    //         // sphere = Sphere.createCenterRadius(allUniqCentPoints[i]?.interpolate(1-partRatio/2, allUniqCentPoints[i+1]!)!, 0.1);
    //         // builder.addSphere(sphere);
    //         // polyface = builder.claimPolyface(false);
    //         // this.addGeometry(polyface, ColorDef.fromTbgr(ColorDef.withTransparency(ColorDef.create(ColorByName.blanchedAlmond).tbgr, 0)), "Box", false, true, json);

    //         newActualPoints = {base: allUniqActPoints[i]?.interpolate(partRatio, allUniqActPoints[i+1]!)!, top: allUniqActPoints[i]!.interpolate(1-partRatio/2, allUniqActPoints[i+1]!)}
    //         newCentralPoints = {localBase: allUniqCentPoints[i]?.interpolate(partRatio, allUniqCentPoints[i+1]!)!, localTop: allUniqCentPoints[i]!.interpolate(1-partRatio/2, allUniqCentPoints[i+1]!)}
  
    //         temp = {
    //           ...theData,
    //           Azimuth: theData.Azimuth,
    //           // Id: `${json.Id}-${suffixes[i]}`,
    //           Id: `${theData.Id}`,
    //           CenterPositions: newCentralPoints,
    //           ActualPositions: newActualPoints
    //         };
    //         // theGeometry = this.constructBoxGeometry(temp!);
    //         // builder.addBox(theGeometry);
    //         // polyface = builder.claimPolyface(false);
    //         // this.addGeometry(polyface, ColorDef.fromTbgr(ColorDef.withTransparency(ColorDef.create(ColorByName.aqua).tbgr, 0)), "Box", true, true, temp);
              
    //       }
            

    //       break;
    //     case 1:
    //       if(i<allUniqCentPoints.length-1){
    //         // sphere = Sphere.createCenterRadius(allUniqCentPoints[i]?.interpolate(partRatio/2, allUniqCentPoints[i+1]!)!, 0.1);
    //         // builder.addSphere(sphere);
    //         // polyface = builder.claimPolyface(false);
    //         // this.addGeometry(polyface, ColorDef.fromTbgr(ColorDef.withTransparency(ColorDef.create(ColorByName.blanchedAlmond).tbgr, 0)), "Box", false, true, json);

    //         // newActualPoints = {base: allUniqActPoints[i-1]?.interpolate(partRatio*1.5, allUniqActPoints[i]!)!, top: allUniqActPoints[i]!.interpolate(partRatio/2, allUniqActPoints[i+1]!)}
    //         // newCentralPoints = {localBase: allUniqCentPoints[i-1]?.interpolate(partRatio*1.5, allUniqCentPoints[i]!)!, localTop: allUniqCentPoints[i]!.interpolate(partRatio/2, allUniqCentPoints[i+1]!)}
  
    //         // let temp = {
    //         //   ...theData,
    //         //   Azimuth: theData.Azimuth,
    //         //   // Id: `${json.Id}-${suffixes[i]}`,
    //         //   Id: `${theData.Id}`,
    //         //   CenterPositions: newCentralPoints,
    //         //   ActualPositions: newActualPoints
    //         // };
    //         // let theGeometry = this.constructBoxGeometry(temp!);
    //         // builder.addBox(theGeometry);
    //         // polyface = builder.claimPolyface(false);
    //         // this.addGeometry(polyface, ColorDef.fromTbgr(ColorDef.withTransparency(ColorDef.create(ColorByName.yellow).tbgr, 0)), "Box", true, true, temp);
              

    //       } else if(i==allUniqCentPoints.length-1){
    //         // sphere = Sphere.createCenterRadius(allUniqCentPoints[i-1]?.interpolate(1-partRatio, allUniqCentPoints[i]!)!, 0.1);
    //         // builder.addSphere(sphere);
    //         // polyface = builder.claimPolyface(false);
    //         // this.addGeometry(polyface, ColorDef.fromTbgr(ColorDef.withTransparency(ColorDef.create(ColorByName.blanchedAlmond).tbgr, 0)), "Box", false, true, json);

    //       }
    //       break;
      
    //     default:
    //       console.log(0, partRatio/2)
    //       console.log(allUniqActPoints[i], partRatio)
    //       break;
    //   }
    // }

    
    // const newJsons: FeedlineData[] = []
    // let newActualPoints: {base: Point3d, top: Point3d}={
    //   base: new Point3d,
    //   top: new Point3d
    // }, newCentralPoints: {localBase: Point3d, localTop: Point3d}={
    //   localBase: new Point3d,
    //   localTop: new Point3d
    // };
    // const suffixes = ["start", "middle", "segment", "end"]
    //   let theData = allFeeds[0].modelData!;
    //   let theJson: FeedlineData = JSON.parse(JSON.stringify(theData));
    //   theJson.ActualPositions.base = Point3d.fromJSON(theJson.ActualPositions.base);
    //   theJson.ActualPositions.top = Point3d.fromJSON(theJson.ActualPositions.top);
    //   theJson.CenterPositions.localBase = Point3d.fromJSON(theJson.CenterPositions.localBase);
    //   theJson.CenterPositions.localTop = Point3d.fromJSON(theJson.CenterPositions.localTop);
    //   newJsons.push(theJson);
    //   // switch (i) {
    //   //   case 0:
    //   theJson.Id = `${theData.Id}-${suffixes[0]}`
    //   theJson.ActualPositions.top = theData.ActualPositions.base.interpolate(partRatio, theData.ActualPositions.top);
    //   theJson.CenterPositions.localTop = theData.CenterPositions.localBase.interpolate(partRatio, theData.CenterPositions.localTop);
      
    //   for(let i = 1; i < allFeeds.length-1; i++){
    //     const temp = newJsons[newJsons.length-1];
    //     newActualPoints.base = temp.ActualPositions.base.interpolate(partRatio/2, temp.ActualPositions.top)
    //     theJson = {
    //       ...theData,
    //       ActualPositions: newActualPoints,
    //       CenterPositions: newCentralPoints,
    //     }
    //   }
      
    //   theData = allFeeds[allFeeds.length-1].modelData!;
    //   theJson = JSON.parse(JSON.stringify(theData));
    //   theJson.ActualPositions.base = Point3d.fromJSON(theJson.ActualPositions.base);
    //   theJson.ActualPositions.top = Point3d.fromJSON(theJson.ActualPositions.top);
    //   theJson.CenterPositions.localBase = Point3d.fromJSON(theJson.CenterPositions.localBase);
    //   theJson.CenterPositions.localTop = Point3d.fromJSON(theJson.CenterPositions.localTop);

    //   theJson.Id = `${theData.Id}-${suffixes[3]}`
    //   theJson.ActualPositions.base = theData.ActualPositions.base.interpolate(partRatio/2, theData.ActualPositions.top);
    //   theJson.CenterPositions.localBase = theData.CenterPositions.localBase.interpolate(partRatio/2, theData.CenterPositions.localTop);
    //   newJsons.push(theJson);
          
    // return allFeeds;
  }
  
  public getEditBoxJson(json: FeedlineData): FeedlineData[] {

    if(json.FeedlineType.type == FeedlineSectionType.Segmented){
      const allFeeds = this.shapes.filter(e=>e.name == json.Id.split('_')[0]);
      // this.getAllSegmentBoxes(allFeeds);

      const newJsons: FeedlineData[] = [];
      const newJsons2: FeedlineData[] = [];
      // const allActualPoints = allFeeds.map(e=>e.modelData?.ActualPositions);
      // const allCenterPoints = allFeeds.map(e=>e.modelData?.CenterPositions);
      const absLen = allFeeds[0].modelData?.ActualPositions.base.distance(allFeeds[allFeeds.length-1].modelData?.ActualPositions.top!)!;
      const partRatio = 0.01;
      const reqdLen = absLen*partRatio;
  
      // const allUniqActPoints = [allActualPoints[0]?.base, allActualPoints[0]?.top, ...allActualPoints.map((e, i)=>{if(i>0)return e?.top;return null}).filter(e=>e)];
      // const allUniqCentPoints = [allCenterPoints[0]?.localBase, allCenterPoints[0]?.localTop, ...allCenterPoints.map((e, i)=>{if(i>0)return e?.localTop;return null}).filter(e=>e)];
      // const theData = allFeeds[0].modelData!;
      // const json = {...allFeeds[0].modelData};
      const options = StrokeOptions.createForCurves();
      options.needParams = false;
      options.needNormals = true;
  
      let temp: FeedlineData, theGeometry: Box;
      for(let i=0; i < allFeeds.length; i++){
        let theBox = allFeeds[i].modelData!;
        // theBox = theBox.Id == json.Id ? json : theBox; // Causing the start position to not change when point selection is used.
        let polyface: IndexedPolyface;
        let sphere: Sphere;
        let newActualPoints: {base: Point3d, top: Point3d}={
          base: new Point3d,
          top: new Point3d
        }, newCentralPoints: {localBase: Point3d, localTop: Point3d}={
          localBase: new Point3d,
          localTop: new Point3d
        };
        const boxLen = theBox.ActualPositions.base.distance(theBox.ActualPositions.top)!;
        const reqdRatio = reqdLen/boxLen;
        switch (i) {
          case 0: {
            newActualPoints = {base: theBox.ActualPositions.base!, top: theBox.ActualPositions.base!.interpolate(reqdRatio, theBox.ActualPositions.top!)}
            newCentralPoints = {localBase: theBox.CenterPositions.localBase!, localTop: theBox.CenterPositions.localBase!.interpolate(reqdRatio, theBox.CenterPositions.localTop!)}
  
            temp = {
              ...theBox,
              // Azimuth: theBox.Azimuth,
              // Id: `${json.Id}-${suffixes[i]}`,
              Id: `${theBox.Id}-end`,
              CenterPositions: newCentralPoints,
              ActualPositions: newActualPoints
            };
            newJsons.push(temp);
            
            // {
            //   let theGeometry = this.constructBoxGeometry(temp!);
            //   const builder = PolyfaceBuilder.create(options);
            //   builder.addBox(theGeometry);
            //   let polyface = builder.claimPolyface(false);
            //   this.addGeometry(polyface, ColorDef.fromTbgr(ColorDef.withTransparency(ColorDef.create(ColorByName.blue).tbgr, 0)), "Box1", true, true, temp);
            // }
              
            newActualPoints = {base: theBox.ActualPositions.base.interpolate(reqdRatio, theBox.ActualPositions.top), top: theBox.ActualPositions.base.interpolate(1-reqdRatio/2, theBox.ActualPositions.top)!}
            newCentralPoints = {localBase: theBox.CenterPositions.localBase.interpolate(reqdRatio, theBox.CenterPositions.localTop)!, localTop: theBox.CenterPositions.localBase.interpolate(1-reqdRatio/2, theBox.CenterPositions.localTop)!}
  
            temp = {
              ...theBox,
              // Azimuth: theBox.Azimuth,
              // Id: `${json.Id}-${suffixes[i]}`,
              Id: `${theBox.Id}-middle`,
              CenterPositions: newCentralPoints,
              ActualPositions: newActualPoints
            };
            newJsons.push(temp);
  
            // {
            //   let theGeometry = this.constructBoxGeometry(temp!);
            //   const builder = PolyfaceBuilder.create(options);
            //   builder.addBox(theGeometry);
            //   let polyface = builder.claimPolyface(false);
            //   this.addGeometry(polyface, ColorDef.fromTbgr(ColorDef.withTransparency(ColorDef.create(ColorByName.aqua).tbgr, 0)), "Box2", true, true, temp);
            // }
              
            newActualPoints = {base: theBox.ActualPositions.base.interpolate(1-reqdRatio/2, theBox.ActualPositions.top)!, top: theBox.ActualPositions.base!.interpolate(1+reqdRatio/2, theBox.ActualPositions.top!)}
            newCentralPoints = {localBase: theBox.CenterPositions.localBase!.interpolate(1-reqdRatio/2, theBox.CenterPositions.localTop), localTop: theBox.CenterPositions.localBase!.interpolate(1+reqdRatio/2, theBox.CenterPositions.localTop!)}
  
            temp = {
              ...theBox,
              // Azimuth: theBox.Azimuth,
              // Id: `${json.Id}-${suffixes[i]}`,
              Id: `${theBox.Id}-segment`,
              CenterPositions: newCentralPoints,
              ActualPositions: newActualPoints
            };
            newJsons.push(temp);

            // {
            //   let theGeometry = this.constructBoxGeometry(temp!);
            //   const builder = PolyfaceBuilder.create(options);
            //   builder.addBox(theGeometry);
            //   let polyface = builder.claimPolyface(false);
            //   this.addGeometry(polyface, ColorDef.fromTbgr(ColorDef.withTransparency(ColorDef.create(ColorByName.purple).tbgr, 0)), "Box3", true, true, temp);
            // }
              
              
          }
          break;
          case allFeeds.length-1:{
              
            newActualPoints = {base: theBox.ActualPositions.base.interpolate(reqdRatio/2, theBox.ActualPositions.top)!, top: theBox.ActualPositions.base!.interpolate(1-reqdRatio, theBox.ActualPositions.top!)}
            newCentralPoints = {localBase: theBox.CenterPositions.localBase!.interpolate(reqdRatio/2, theBox.CenterPositions.localTop), localTop: theBox.CenterPositions.localBase!.interpolate(1-reqdRatio, theBox.CenterPositions.localTop!)}
  
            temp = {
              ...theBox,
              // Azimuth: theBox.Azimuth,
              // Id: `${json.Id}-${suffixes[i]}`,
              Id: `${theBox.Id}-middle`,
              CenterPositions: newCentralPoints,
              ActualPositions: newActualPoints
            };
            newJsons.push(temp);
  
            // {
            //   let theGeometry = this.constructBoxGeometry(temp!);
            //   const builder = PolyfaceBuilder.create(options);
            //   builder.addBox(theGeometry);
            //   let polyface = builder.claimPolyface(false);
            //   this.addGeometry(polyface, ColorDef.fromTbgr(ColorDef.withTransparency(ColorDef.create(ColorByName.aqua).tbgr, 0)), "Box3", true, true, temp);
            // }
              
            newActualPoints = {base: theBox.ActualPositions.base.interpolate(1-reqdRatio, theBox.ActualPositions.top)!, top: theBox.ActualPositions.top}
            newCentralPoints = {localBase: theBox.CenterPositions.localBase!.interpolate(1-reqdRatio, theBox.CenterPositions.localTop), localTop: theBox.CenterPositions.localTop}
  
            temp = {
              ...theBox,
              Azimuth: theBox.Azimuth,
              // Id: `${json.Id}-${suffixes[i]}`,
              Id: `${theBox.Id}-start`,
              CenterPositions: newCentralPoints,
              ActualPositions: newActualPoints
            };
            newJsons.push(temp);

            // {
            //   let theGeometry = this.constructBoxGeometry(temp!);
            //   const builder = PolyfaceBuilder.create(options);
            //   builder.addBox(theGeometry);
            //   let polyface = builder.claimPolyface(false);
            //   this.addGeometry(polyface, ColorDef.fromTbgr(ColorDef.withTransparency(ColorDef.create(ColorByName.red).tbgr, 0)), "Box3", true, true, temp);
            // }
            
          }
          break;
        
          default:

            newActualPoints = {base: theBox.ActualPositions.base.interpolate(reqdRatio/2, theBox.ActualPositions.top), top: theBox.ActualPositions.base.interpolate(1-reqdRatio/2, theBox.ActualPositions.top)!}
            newCentralPoints = {localBase: theBox.CenterPositions.localBase.interpolate(reqdRatio/2, theBox.CenterPositions.localTop)!, localTop: theBox.CenterPositions.localBase.interpolate(1-reqdRatio/2, theBox.CenterPositions.localTop)!}
  
            temp = {
              ...theBox,
              // Azimuth: theBox.Azimuth,
              // Id: `${json.Id}-${suffixes[i]}`,
              Id: `${theBox.Id}-middle`,
              CenterPositions: newCentralPoints,
              ActualPositions: newActualPoints
            };
            newJsons.push(temp);
  
              
            newActualPoints = {base: theBox.ActualPositions.base.interpolate(1-reqdRatio/2, theBox.ActualPositions.top)!, top: theBox.ActualPositions.base!.interpolate(1+reqdRatio/2, theBox.ActualPositions.top!)}
            newCentralPoints = {localBase: theBox.CenterPositions.localBase!.interpolate(1-reqdRatio/2, theBox.CenterPositions.localTop), localTop: theBox.CenterPositions.localBase!.interpolate(1+reqdRatio/2, theBox.CenterPositions.localTop!)}
  
            temp = {
              ...theBox,
              // Azimuth: theBox.Azimuth,
              // Id: `${json.Id}-${suffixes[i]}`,
              Id: `${theBox.Id}-segment`,
              CenterPositions: newCentralPoints,
              ActualPositions: newActualPoints
            };
            newJsons.push(temp);
            // {
            //   let theGeometry = this.constructBoxGeometry(temp!);
            //   const builder = PolyfaceBuilder.create(options);
            //   builder.addBox(theGeometry);
            //   let polyface = builder.claimPolyface(false);
            //   this.addGeometry(polyface, ColorDef.fromTbgr(ColorDef.withTransparency(ColorDef.create(ColorByName.aqua).tbgr, 0)), "Box2", true, true, temp);
            // }
              
            break;
        }
      }
      allFeeds.forEach(af=>{
        const shapeIndex = this.shapes.findIndex(e=>e.modelData?.Id==af.modelData?.Id);
        if(shapeIndex != -1)this.shapes.splice(shapeIndex, 1);
      })

      const allActPoints = allFeeds.map(e=>e.modelData?.ActualPositions.base);
      allActPoints.push(allFeeds[allFeeds.length-1].modelData?.ActualPositions.top);
      const allCentPoints = allFeeds.map(e=>e.modelData?.CenterPositions.localBase);
      allCentPoints.push(allFeeds[allFeeds.length-1].modelData?.CenterPositions.localTop);
      const theId = json.Id.split('@')[0];
      for(let i=0; i < allActPoints.length-1; i++){
        let theActPoint = allActPoints[i]!;
        let theNextActPoint = allActPoints[i+1]!;
        let theCentPoint = allCentPoints[i]!;
        let theNextCentPoint = allCentPoints[i+1]!;

        // theBox = theBox.Id == json.Id ? json : theBox;
        let polyface: IndexedPolyface;
        let sphere: Sphere;
        let newActualPoints: {base: Point3d, top: Point3d}={
          base: new Point3d,
          top: new Point3d
        }, newCentralPoints: {localBase: Point3d, localTop: Point3d}={
          localBase: new Point3d,
          localTop: new Point3d
        };
        // const boxLen = theBox.ActualPositions.base.distance(theBox.ActualPositions.top)!;
        let boxLen = theActPoint.distance(theNextActPoint)!;
        // const boxLen = absLen*0.01!;
        let reqdRatio = reqdLen/boxLen;
        
        switch (i) {
          case 0: {
            newActualPoints = {base: theActPoint!, top: theActPoint!.interpolate(reqdRatio, theNextActPoint!)}
            newCentralPoints = {localBase: theCentPoint!, localTop: theCentPoint!.interpolate(reqdRatio, theNextCentPoint!)}
  
            temp = {
              ...json,
              // Azimuth: theBox.Azimuth,
              // Id: `${json.Id}-${suffixes[i]}`,
              Id: `${theId}@${i}-end`,
              CenterPositions: newCentralPoints,
              ActualPositions: newActualPoints
            };
            newJsons2.push(temp);

            newActualPoints = {base: theActPoint.interpolate(reqdRatio, theNextActPoint), top: theActPoint.interpolate(1-reqdRatio/2, theNextActPoint)!}
            newCentralPoints = {localBase: theCentPoint.interpolate(reqdRatio, theNextCentPoint)!, localTop: theCentPoint.interpolate(1-reqdRatio/2, theNextCentPoint)!}
  
            temp = {
              ...json,
              // Azimuth: theBox.Azimuth,
              // Id: `${json.Id}-${suffixes[i]}`,
              Id: `${theId}@${i}-middle`,
              CenterPositions: newCentralPoints,
              ActualPositions: newActualPoints
            };
            newJsons2.push(temp);
      
            // let theNextPlusActPoint = allActPoints[i+2]!;
            // let theNextPlusCentPoint = allCentPoints[i+2]!;
            // newActualPoints = {base: theActPoint.interpolate(1-reqdRatio/2, theNextActPoint), top: theNextActPoint!.interpolate(reqdRatio/2, theNextPlusActPoint!)}
            // newCentralPoints = {localBase: theCentPoint.interpolate(1-reqdRatio/2, theNextCentPoint), localTop: theNextCentPoint!.interpolate(reqdRatio/2, theNextPlusCentPoint!)}
  
            // temp = {
            //   ...json,
            //   // Azimuth: theBox.Azimuth,
            //   // Id: `${json.Id}-${suffixes[i]}`,
            //   Id: `${theId}@${i}-segment`,
            //   CenterPositions: newCentralPoints,
            //   ActualPositions: newActualPoints
            // };
            // newJsons2.push(temp);
          }
          break;
          case allActPoints.length-2: {
            newActualPoints = {base: theActPoint.interpolate(reqdRatio/2, theNextActPoint), top: theActPoint.interpolate(1-reqdRatio, theNextActPoint)!}
            newCentralPoints = {localBase: theCentPoint.interpolate(reqdRatio/2, theNextCentPoint)!, localTop: theCentPoint.interpolate(1-reqdRatio, theNextCentPoint)!}
  
            temp = {
              ...json,
              // Azimuth: theBox.Azimuth,
              // Id: `${json.Id}-${suffixes[i]}`,
              Id: `${theId}@${i}-middle`,
              CenterPositions: newCentralPoints,
              ActualPositions: newActualPoints
            };
            newJsons2.push(temp);

            newActualPoints = {base: theActPoint.interpolate(1-reqdRatio, theNextActPoint), top: theNextActPoint}
            newCentralPoints = {localBase: theCentPoint.interpolate(1-reqdRatio, theNextCentPoint), localTop: theNextCentPoint}
  
            temp = {
              ...json,
              // Azimuth: theBox.Azimuth,
              // Id: `${json.Id}-${suffixes[i]}`,
              Id: `${theId}@${i}-start`,
              CenterPositions: newCentralPoints,
              ActualPositions: newActualPoints
            };
            newJsons2.push(temp);

          }
          break;
        
          default: {
    
            newActualPoints = {base: theActPoint.interpolate(reqdRatio/2, theNextActPoint), top: theActPoint.interpolate(1-reqdRatio/2, theNextActPoint)}
            newCentralPoints = {localBase: theCentPoint.interpolate(reqdRatio/2, theNextCentPoint), localTop: theCentPoint!.interpolate(1-reqdRatio/2, theNextCentPoint)}
  
            temp = {
              ...json,
              // Azimuth: theBox.Azimuth,
              // Id: `${json.Id}-${suffixes[i]}`,
              Id: `${theId}@${i}-middle`,
              CenterPositions: newCentralPoints,
              ActualPositions: newActualPoints
            };
            newJsons2.push(temp);

            // let theNextPlusActPoint = allActPoints[i+2]!;
            // let theNextPlusCentPoint = allCentPoints[i+2]!;
            // newActualPoints = {base: theActPoint.interpolate(1-reqdRatio/2, theNextActPoint), top: theNextActPoint!.interpolate(reqdRatio/2, theNextPlusActPoint!)}
            // newCentralPoints = {localBase: theCentPoint.interpolate(1-reqdRatio/2, theNextCentPoint), localTop: theNextCentPoint!.interpolate(reqdRatio/2, theNextPlusCentPoint!)}
  
            // temp = {
            //   ...json,
            //   // Azimuth: theBox.Azimuth,
            //   // Id: `${json.Id}-${suffixes[i]}`,
            //   Id: `${theId}@${i}-segment`,
            //   CenterPositions: newCentralPoints,
            //   ActualPositions: newActualPoints
            // };
            // newJsons2.push(temp);
          }
          break;
        }
      }
      const combinedjson: FeedlineData[] = [];
      for(let i=0; i < newJsons2.length-2; i++){
        if(newJsons2[i].Id.match(/middle/)){
          let newActualPoints = {base: newJsons2[i].ActualPositions.top.clone(), top: newJsons2[i+1].ActualPositions.base.clone()}
          let newCentralPoints = {localBase: newJsons2[i].CenterPositions.localTop.clone(), localTop: newJsons2[i+1].CenterPositions.localBase.clone()}

          temp = {
            ...newJsons2[i],
            // Azimuth: theBox.Azimuth,
            // Id: `${json.Id}-${suffixes[i]}`,
            Id: `${newJsons2[i].Id.split('-')[0]}-segment`,
            CenterPositions: newCentralPoints,
            ActualPositions: newActualPoints
          };

          combinedjson.push(newJsons2[i]);
          combinedjson.push(temp);
        } else {
          combinedjson.push(newJsons2[i])
        }
      }
      combinedjson.splice(combinedjson.length, 0, ...newJsons2.slice(newJsons2.length-2))
      return newJsons;
    }
     else {


      const partRatio = 0.01;
      const newJsons: FeedlineData[] = []
      const suffixes = ["start", "middle", "end"]
      let newActualPoints: {base: Point3d, top: Point3d}={
        base: new Point3d,
        top: new Point3d
      }, newCentralPoints: {localBase: Point3d, localTop: Point3d}={
        localBase: new Point3d,
        localTop: new Point3d
      };
      const count = 3; // 3 Boxes to be drawn for Start Middle and End resp
      for(let i=0; i < count; i++){
        // newActualPoints.push(json.ActualPositions.base.interpolate(partRatio, json.ActualPositions.top));
        // newActualPoints.push(json.ActualPositions.base.interpolate(partRatio, json.ActualPositions.top));
        switch (suffixes[i]) {
          case "start":
              newActualPoints = {base: json.ActualPositions.top, top: json.ActualPositions.top.interpolate(partRatio, json.ActualPositions.base)}
              newCentralPoints = {localBase: json.CenterPositions.localTop, localTop: json.CenterPositions.localTop.interpolate(partRatio, json.CenterPositions.localBase)}
            break;
          case "middle":
              newActualPoints = {base: json.ActualPositions.top.interpolate(partRatio, json.ActualPositions.base), top: json.ActualPositions.top.interpolate(1-partRatio, json.ActualPositions.base)}
              newCentralPoints = {localBase: json.CenterPositions.localTop.interpolate(partRatio, json.CenterPositions.localBase), localTop: json.CenterPositions.localTop.interpolate(1-partRatio, json.CenterPositions.localBase)}
            break;
          case "end":
              newActualPoints = {base: json.ActualPositions.top.interpolate(1-partRatio, json.ActualPositions.base), top: json.ActualPositions.base}
              newCentralPoints = {localBase: json.CenterPositions.localTop.interpolate(1-partRatio, json.CenterPositions.localBase), localTop: json.CenterPositions.localBase}
            break;
        
          default:
            break;
        }
        const temp: FeedlineData = {
          ...json,
          Azimuth: -1*json.Azimuth,
          Id: `${json.Id}-${suffixes[i]}`,
          CenterPositions: newCentralPoints,
          ActualPositions: newActualPoints
        };
        newJsons.push(temp);
      }
      return newJsons;
      // return [];

    }
  }
  
  public getUneditedBoxJson = (): FeedlineData[] => {
    const start = this.shapes.find(e=>e.modelData?.Id.match(/start/))!;
    const end = this.shapes.find(e=>e.modelData?.Id.match(/end/))!;
    if(start.modelData!.Id.match(/@/)){
      const segments = this.shapes.filter(e=>e.modelData?.Id.match(/segment/))!;
      const middle = this.shapes.filter(e=>e.modelData?.Id.match(/middle/))!;
      const feedlines: FeedlineData[]=[]
      const feedlines2: FeedlineData[]=[]
      middle.forEach((e: CustomGeometryQuery, mi: number)=>{
        const temp: FeedlineData = {
          ...start.modelData!,
          Id: "",
          CenterPositions: {
            localBase: new Point3d,
            localTop: new Point3d
          },
          ActualPositions: {
            base: new Point3d,
            top: new Point3d
          },
          FeedlineType: {
            type: FeedlineSectionType.Segmented,
            segmentCount: segments.length,
            absoluteLength: start.modelData?.FeedlineType.absoluteLength!
          }
        };
        const segmentPosition = parseInt(e.modelData!.Id.split('@')[1].split('-')[0]);
        // const segIndex = Math.floor(mi/2);
        // switch (segmentPosition) {
        //   case 0:
        //     temp.ActualPositions = {base: new Point3d(...end.modelData?.ActualPositions.base.toArray()!)!, top: segments[segIndex].modelData?.ActualPositions.base.interpolate(0.5, segments[segIndex].modelData?.ActualPositions.top!)!};
        //     temp.CenterPositions = {localBase: new Point3d(...end.modelData?.CenterPositions.localBase.toArray()!), localTop: segments[segIndex].modelData?.CenterPositions.localBase.interpolate(0.5, segments[segIndex].modelData?.CenterPositions.localTop!)!};
        //     temp.Id = e.modelData?.Id.split('-')[0]!;
        //     feedlines.push(temp);
        //     break;
        //   case middle.length-1:
        //     temp.ActualPositions = {base: segments[segIndex].modelData?.ActualPositions.base.interpolate(0.5, segments[segIndex].modelData?.ActualPositions.top!)!, top: new Point3d(...start.modelData?.ActualPositions.top!.toArray()!)};
        //     temp.CenterPositions = {localBase: segments[segIndex].modelData?.CenterPositions.localBase.interpolate(0.5, segments[segIndex].modelData?.CenterPositions.localTop!)!, localTop: new Point3d(...start.modelData?.CenterPositions.localTop!.toArray()!)};
        //     temp.Id = e.modelData?.Id.split('-')[0]!;
        //     feedlines.push(temp);
        //     break;
            
        //   default:
        //     temp.ActualPositions = {base: segments[segIndex].modelData?.ActualPositions.base.interpolate(0.5, segments[segIndex].modelData?.ActualPositions.top!)!, top: segments[segIndex+1].modelData?.ActualPositions.base.interpolate(0.5, segments[segIndex+1].modelData?.ActualPositions.top!)!};
        //     temp.CenterPositions = {localBase: segments[segIndex].modelData?.CenterPositions.localBase.interpolate(0.5, segments[segIndex].modelData?.CenterPositions.localTop!)!, localTop: segments[segIndex+1].modelData?.CenterPositions.localBase.interpolate(0.5, segments[segIndex+1].modelData?.CenterPositions.localTop!)!};
        //     temp.Id = e.modelData?.Id.split('-')[0]!;
        //     feedlines.push(temp);
            
        //     break;
        // }
        // switch (segmentPosition) {
        switch (mi) {
          case 0:
            temp.ActualPositions = {base: new Point3d(...end.modelData?.ActualPositions.base.toArray()!)!, top: segments[mi].modelData?.ActualPositions.base.interpolate(0.5, segments[mi].modelData?.ActualPositions.top!)!};
            temp.CenterPositions = {localBase: new Point3d(...end.modelData?.CenterPositions.localBase.toArray()!), localTop: segments[mi].modelData?.CenterPositions.localBase.interpolate(0.5, segments[mi].modelData?.CenterPositions.localTop!)!};
            temp.Id = e.modelData?.Id.split('-')[0]!;
            feedlines.push(temp);
            break;
          case middle.length-1:
            temp.ActualPositions = {base: segments[mi-1].modelData?.ActualPositions.base.interpolate(0.5, segments[mi-1].modelData?.ActualPositions.top!)!, top: new Point3d(...start.modelData?.ActualPositions.top!.toArray()!)};
            temp.CenterPositions = {localBase: segments[mi-1].modelData?.CenterPositions.localBase.interpolate(0.5, segments[mi-1].modelData?.CenterPositions.localTop!)!, localTop: new Point3d(...start.modelData?.CenterPositions.localTop!.toArray()!)};
            temp.Id = e.modelData?.Id.split('-')[0]!;
            feedlines.push(temp);
            break;
            
          default:
            temp.ActualPositions = {base: segments[mi-1].modelData?.ActualPositions.base.interpolate(0.5, segments[mi-1].modelData?.ActualPositions.top!)!, top: segments[mi].modelData?.ActualPositions.base.interpolate(0.5, segments[mi].modelData?.ActualPositions.top!)!};
            temp.CenterPositions = {localBase: segments[mi-1].modelData?.CenterPositions.localBase.interpolate(0.5, segments[mi-1].modelData?.CenterPositions.localTop!)!, localTop: segments[mi].modelData?.CenterPositions.localBase.interpolate(0.5, segments[mi].modelData?.CenterPositions.localTop!)!};
            temp.Id = e.modelData?.Id.split('-')[0]!;
            feedlines.push(temp);
            
            break;
        }

      });
      return feedlines;
    } else {

      return [{
          ...start?.modelData!,
          Azimuth: -1*start.modelData?.Azimuth!,
          Id: start?.modelData!.Id!.split('-')[0],
          CenterPositions: {
              localBase: end?.modelData?.CenterPositions.localTop!,
              localTop: start?.modelData?.CenterPositions.localBase!
          },
          ActualPositions: {
              base: end?.modelData?.ActualPositions.top!,
              top: start?.modelData?.ActualPositions.base!
          }
      }];
    }
  }
  
  public decorate(context: DecorateContext): void {
    // this.antennaBoxes.forEach((entry) => {
    //   if (!this.drawnBoxes.includes(entry)) {
    //     const builder = PolyfaceBuilder.create();
    //     builder.addBox(entry.box);
    //     const polyface = builder.claimPolyface(false);
    //     this.addGeometry(polyface, this.equipColors.antenna.bodyCol, entry.name!, this.edges, entry.modelData!);
    //     this.drawnBoxes.push(entry);
    //   }
    // });
    // this.rruBoxes.forEach((entry) => {
    //   if (!this.drawnBoxes.includes(entry)) {
    //     const builder = PolyfaceBuilder.create();
    //     builder.addBox(entry.box);
    //     const polyface = builder.claimPolyface(false);
    //     this.addGeometry(polyface, this.equipColors.rru.bodyCol, entry.name);
    //     this.drawnBoxes.push(entry);
    //   }
    // });
    // this.defectBoxes.forEach((entry) => {
    //     if (!this.drawnBoxes.includes(entry)) {
    //         const builder = PolyfaceBuilder.create();
    //         builder.addBox(entry.box);
    //         const polyface = builder.claimPolyface(false);
    //         const color = ColorDef.fromString("rgb(255, 115, 0)");
    //         this.addGeometry(polyface, color, entry.name);
    //         this.drawnBoxes.push(entry);
    //     }
    // });
    // this.microWaveCylinders.forEach((entry) => {
    //   if (!this.drawnCylinders.includes(entry)) {
    //     const builder = PolyfaceBuilder.create();
    //     builder.addCone(entry.cylinder);
    //     const polyface = builder.claimPolyface(false);
    //     this.addGeometry(polyface, entry.color, entry.name, true);
    //     this.drawnCylinders.push(entry);
    //   }
    // });

    this.createGraphics(context);
  }

  /** Return true if supplied Id represents a pickable decoration created by this decorator. */
  public testDecorationHit(_id: string): boolean {
    const iModelConnection = UiFramework.getIModelConnection()!!;
    iModelConnection?.selectionSet.emptyAll();
    SyncUiEventDispatcher.onSyncUiEvent.addListener((args) => {
      if (args.eventIds.has(SyncUiEventIds.RM_Feedline_Selected)) {

        const theObject = this.objectIdMap.get(this.selectedFeedline.transId);
        if(theObject?.match(/@/)){
          const feedId = theObject.split('#')[2].split('@')[0];
          const allFeeds = this.shapes.filter(e=>e.name == feedId.split('_')[0]);
          allFeeds.forEach(e=>{
            iModelConnection?.selectionSet.add(e.transientId);
          })
        }
      }
    });
  return Array.from(this.nameIdMap.values()).includes(_id);
  }

  // /** Return localized tooltip message for the decoration identified by HitDetail.sourceId. */
  // public async getDecorationToolTip(_hit: HitDetail): Promise<HTMLElement | string> {
  //   // const sourceId = _hit.sourceId;
  //   // const name = Array.from(this.nameIdMap.keys()).find((key) => this.nameIdMap.get(key) === sourceId);
  //   // if (!name) {
  //   //   return "Equipment bounding box";
  //   // }
  //   // return name;
  //   const sourceId = _hit.sourceId;
  //   let name = Array.from(this.nameIdMap.keys()).find((key) => this.nameIdMap.get(key) === sourceId);
  //   if(name?.match(/face/ig)){
  //     const equipTransientId = this.objectIdMap.get(name);
  //     name = this.faceToEquipName(name).name;
    
  //   }
  //   let equip: any = this.shapes.filter((e)=>e.modelData?.Id == name)[0];
  //   if (!name)return "Equipment bounding box";
  //   return equip.DisplayName!;
  // }

  public addSegment = async (targetedFeedline: FeedlineData, ev: BeButtonEvent) => {
    const unEditedBoxJson = this.getUneditedBoxJson();
    if(targetedFeedline.FeedlineType.type == FeedlineSectionType.NonSegmented){
      const theSeparatedFeeds = this.getSeparatedFeeds(unEditedBoxJson[0], targetedFeedline.Id, ev);
      if(!theSeparatedFeeds.length)return;
      this.segmentAdded = true;
      const newShapes = this.shapes.filter(e=>!e.modelData?.Id.match(targetedFeedline.Id));
      this.shapes = newShapes;
      
      theSeparatedFeeds.forEach(newFeed=>this.createGeometry(newFeed, CreateGeometryMode.New));
      unEditedBoxJson[0].FeedlineType.type = FeedlineSectionType.Segmented;
      this.createGeometry(unEditedBoxJson[0], CreateGeometryMode.Edit);
      
      IModelApp.viewManager.invalidateDecorationsAllViews();
      IModelApp.viewManager.selectedView?.invalidateCachedDecorations(this);
    } else {
      const currentTargetId =  targetedFeedline.Id.split('@');
      const allFeeds = this.shapes.filter(e=>e.name == currentTargetId[0].split('_')[0]);
      
      const theSeparatedFeeds = this.getSeparatedFeeds(targetedFeedline, targetedFeedline.Id, ev);
      if(!theSeparatedFeeds.length)return;
      this.segmentAdded = true;
      
      // this.shapes.splice(this.shapes.findIndex(f=>f.modelData?.Id == targetedFeedline!.Id), 1);

      // const shapeIndex = this.shapes.findIndex()
      const theNewFeedlines: FeedlineData[] = [];
      theSeparatedFeeds.forEach((sepFeed, _i)=>{
        const temp = sepFeed.Id.split('@');
        const newFeed: FeedlineData = {
          ...sepFeed,
          // Id: `${temp.slice(0, 1).join('_')}@${i}`
        }
        theNewFeedlines.push(newFeed);
        // this.createGeometry(newFeed, CreateGeometryMode.New);
      });

      const replIndex = unEditedBoxJson.findIndex(e=>e.Id == currentTargetId.join('@'));
      unEditedBoxJson.splice(replIndex, 1, ...theNewFeedlines);
      const anotherNewFeeds: FeedlineData[] = [];
      unEditedBoxJson.forEach((uef: FeedlineData, i: number)=>{
        // const theId = uef.Id.split('@');
        const newFeed: FeedlineData = {...uef, Id: `${currentTargetId[0]}@${i}`};
        anotherNewFeeds.push(newFeed)
      });
      anotherNewFeeds.forEach(newFl=>this.createGeometry(newFl, CreateGeometryMode.New));
      const newShapes = this.shapes.filter(e=>!e.modelData?.Id.match(/start|middle|segment|end/));
      this.shapes = newShapes;
      this.createGeometry(anotherNewFeeds[0], CreateGeometryMode.Edit)
      anotherNewFeeds.forEach(e=>{
        const shapeIndex = this.shapes.findIndex(s=>s.modelData?.Id==e.Id);
        if(shapeIndex!=-1)this.shapes.splice(shapeIndex, 1);
      })
      IModelApp.viewManager.invalidateDecorationsAllViews();
      IModelApp.viewManager.selectedView?.invalidateCachedDecorations(this);

      const newFeeds: CustomGeometryQuery[] = [];
      // allFeeds.forEach((cg, i)=>{
      //   if(i >= parseInt(currentTargetId[1])){
      //     if(cg.modelData?.Id == `${targetedFeedline.Id}-middle`){


      //     } else {
      //       newFeeds.push(cg);
      //     }
      //   } else {
      //     newFeeds.push(cg);
      //   }
      // });

    }
  }

  private getSeparatedFeeds = (unEditedBoxJson: FeedlineData, feedId, ev) => {
    const theSeparatedFeeds: FeedlineData[] = [];
    // const start = this.shapes.find(e=>e.modelData?.Id.match(/start/))?.modelData;
    // const end = this.shapes.find(e=>e.modelData?.Id.match(/end/))?.modelData;
    // const ptDistToStart = start?.CenterPositions.localBase.distance(ev.point)!;
    // const ptDistToEnd = end?.CenterPositions.localTop.distance(ev.point)!;
    // const feedLen = start?.CenterPositions.localBase.distance(end?.CenterPositions.localTop!)!;

    if(ev.point.z > unEditedBoxJson.ActualPositions.base.z && ev.point.z < unEditedBoxJson.ActualPositions.top.z){
      // const feedId = theData.Id;
      const distFromBot = unEditedBoxJson.ActualPositions.base.distance(ev.point);
      const distFromTop = unEditedBoxJson.ActualPositions.top.distance(ev.point);
      const length = unEditedBoxJson.ActualPositions.top.distance(unEditedBoxJson.ActualPositions.base);
      const rat = distFromBot/length;

      const options = StrokeOptions.createForCurves();
      options.needParams = false;
      options.needNormals = true;
      const builder = PolyfaceBuilder.create(options);
      let polyface: IndexedPolyface;
      let sphere = Sphere.createCenterRadius(unEditedBoxJson.ActualPositions.base.interpolate(rat, unEditedBoxJson.ActualPositions.top), 0.05);
      // builder.addSphere(sphere);
      // polyface = builder.claimPolyface(false);
      // this.addGeometry(polyface, ColorDef.fromTbgr(ColorDef.withTransparency(ColorDef.create(ColorByName.blanchedAlmond).tbgr, 120)), "Box", false, true, unEditedBoxJson);

      const newBox1: FeedlineData = JSON.parse(JSON.stringify(unEditedBoxJson));   
      newBox1.Id+=`@0`;
      newBox1.ActualPositions.base = Point3d.fromJSON(newBox1.ActualPositions.base);
      // newBox1.ActualPositions.top = Point3d.fromJSON(newBox1.ActualPositions.top);
      newBox1.CenterPositions.localBase = Point3d.fromJSON(newBox1.CenterPositions.localBase);
      // newBox1.CenterPositions.localTop = Point3d.fromJSON(newBox1.CenterPositions.localTop);

      newBox1.ActualPositions.top = unEditedBoxJson.ActualPositions.base.interpolate(rat, unEditedBoxJson.ActualPositions.top);
      newBox1.CenterPositions.localTop = unEditedBoxJson.CenterPositions.localBase.interpolate(rat, unEditedBoxJson.CenterPositions.localTop);
      theSeparatedFeeds.push(newBox1);
      // this.createGeometry(newBox1, CreateGeometryMode.New);
      
      const newBox2: FeedlineData = JSON.parse(JSON.stringify(unEditedBoxJson));
      newBox2.Id+=`@1`;      
      // newBox2.ActualPositions.base = Point3d.fromJSON(newBox2.ActualPositions.base);
      newBox2.ActualPositions.top = Point3d.fromJSON(newBox2.ActualPositions.top);
      // newBox2.CenterPositions.localBase = Point3d.fromJSON(newBox2.CenterPositions.localBase);
      newBox2.CenterPositions.localTop = Point3d.fromJSON(newBox2.CenterPositions.localTop);
      
      newBox2.ActualPositions.base = unEditedBoxJson.ActualPositions.base.interpolate(rat, unEditedBoxJson.ActualPositions.top);
      newBox2.CenterPositions.localBase = unEditedBoxJson.CenterPositions.localBase.interpolate(rat, unEditedBoxJson.CenterPositions.localTop);
      theSeparatedFeeds.push(newBox2);
      // this.createGeometry(newBox2, CreateGeometryMode.New);

      const allFeeds = this.shapes.filter(e=>e.name == feedId.split('_')[0]);
      // this.createGeometry(unEditedBoxJson);
      // const theShape = this.shapes[0];
    }
    return theSeparatedFeeds;
  }

  public theLocationAdjuster = async (theShape: CustomGeometryQuery, theOtherEnd: FeedlineData, ev: BeButtonEvent) => {
      let newJson: FeedlineData;
      const thePart = theShape.modelData?.Id.split('-')!;
      switch (thePart[1]) {
        case "start": {
          // const theOtherEnd = this.shapes.find(e=>e.modelData?.Id.match(/theOtherEnd/));
          const theActualPoint = ev.point.clone();
          theActualPoint.x = theActualPoint.x + 0.5 * theShape.modelData?.Width!;
          theActualPoint.y = theActualPoint.y + 0.5 * theShape.modelData?.Thickness!;
          const theCentralPoint = ev.point.clone();
          theCentralPoint.x = theCentralPoint.x - 0.5 * theShape.modelData?.Width!;
          theCentralPoint.y = theCentralPoint.y - 0.5 * theShape.modelData?.Thickness!;
          newJson = {
            ...theShape.modelData!,
            Azimuth: -1*theShape.modelData?.Azimuth!,
            Id: thePart[0],
            CenterPositions: {
              // localBase: theShape.modelData?.CenterPositions.localTop!,
              localTop: theCentralPoint!,
              localBase: theOtherEnd?.CenterPositions.localBase!
            },
            ActualPositions: {
              // base: ev.point!,
              top: theActualPoint!,
              base: theOtherEnd?.ActualPositions.base!
            }
          }
        }
        break;
        case "end": {
          // const end = this.shapes.find(e=>e.modelData?.Id.match(/end/));
          const theActualPoint = ev.point.clone();
          theActualPoint.x = theActualPoint.x + 0.5 * theShape.modelData?.Width!;
          theActualPoint.y = theActualPoint.y + 0.5 * theShape.modelData?.Thickness!;
          const theCentralPoint = ev.point.clone();
          theCentralPoint.x = theCentralPoint.x - 0.5 * theShape.modelData?.Width!;
          theCentralPoint.y = theCentralPoint.y - 0.5 * theShape.modelData?.Thickness!;
          newJson = {
            ...theShape.modelData!,
            Azimuth: -1*theShape.modelData?.Azimuth!,
            Id: thePart[0],
            CenterPositions: {
              // localBase: theShape.modelData?.CenterPositions.localTop!,
              localTop: theOtherEnd?.CenterPositions.localBase!,
              localBase: theCentralPoint!
            },
            ActualPositions: {
              // base: ev.point!,
              top: theOtherEnd?.ActualPositions.base!,
              base: theActualPoint!
            }
          }
        }
        break;
        case "segment": {
          // const end = this.shapes.find(e=>e.modelData?.Id.match(/end/));
          const theActualPoint = ev.point.clone();
          theActualPoint.x = theActualPoint.x + 0.5 * theShape.modelData?.Width!;
          theActualPoint.y = theActualPoint.y + 0.5 * theShape.modelData?.Thickness!;
          const theCentralPoint = ev.point.clone();
          theCentralPoint.x = theCentralPoint.x - 0.5 * theShape.modelData?.Width!;
          theCentralPoint.y = theCentralPoint.y - 0.5 * theShape.modelData?.Thickness!;
          newJson = {
            ...theShape.modelData!,
            Azimuth: -1*theShape.modelData?.Azimuth!,
            Id: thePart.join('-'),
            CenterPositions: {
              // localBase: theShape.modelData?.CenterPositions.localTop!,
              localTop: theOtherEnd?.CenterPositions.localBase!,
              localBase: theCentralPoint!
            },
            ActualPositions: {
              // base: ev.point!,
              top: theOtherEnd?.ActualPositions.base!,
              base: theActualPoint!
            }
          }
        }
        break;
      
        default:
          break;
      }
      if(theShape.modelData?.FeedlineType.type == FeedlineSectionType.Segmented){
        if(thePart[1] == "segment"){
          const theShapeModel = this.shapes.find(e=>e.modelData?.Id! == thePart.join('-'));
          const shapeIndex = this.shapes.findIndex(e=>e.modelData?.Id==theShapeModel?.modelData?.Id);
          if(shapeIndex != -1){
              this.shapes[shapeIndex].modelData = newJson!;
              // this.shapes.splice(shapeIndex, 1, );
          
          }
          const newJsons = this.getUneditedBoxJson();
          newJsons.forEach(e=>this.createGeometry(e, CreateGeometryMode.New));
          // const newJson = this.shapes.find(e=>e.modelData?.Id! == shapeName)?.modelData!;
          const newShapes = this.shapes.filter(e=>!e.modelData?.Id.match(/start|middle|segment|end/));
          this.shapes = newShapes;
          this.createGeometry(newJson!, CreateGeometryMode.Edit);

        } else {
          const feedlinesJson = getUnlinkedFeedline(this.getUneditedBoxJson()!);
          feedlinesJson.forEach((feedlineJson, flIndex)=>{
            
            if(thePart[1] == "start" && flIndex == feedlinesJson.length-1){
              const theActualPoint = ev.point.clone();
              theActualPoint.x = theActualPoint.x + 0.5 * theShape.modelData?.Width!;
              theActualPoint.y = theActualPoint.y + 0.5 * theShape.modelData?.Thickness!;
              const theCentralPoint = ev.point.clone();
              theCentralPoint.x = theCentralPoint.x - 0.5 * theShape.modelData?.Width!;
              theCentralPoint.y = theCentralPoint.y - 0.5 * theShape.modelData?.Thickness!;
              const nj: FeedlineData = {
                ...feedlineJson,
                CenterPositions: {
                  // localBase: theShape.modelData?.CenterPositions.localTop!,
                  localBase: feedlineJson?.CenterPositions.localBase!,
                  localTop: theCentralPoint!
                },
                ActualPositions: {
                  // base: ev.point!,
                  base: feedlineJson?.ActualPositions.base!,
                  top: theActualPoint!
                }
              }

                // this.createGeometry({...nj, Id: "Custom"});
                
                this.createGeometry(getUnlinkedFeedline([nj])[0], CreateGeometryMode.New);
            } else if(thePart[1] == "end" && flIndex == 0) {
              const theActualPoint = ev.point.clone();
              theActualPoint.x = theActualPoint.x + 0.5 * theShape.modelData?.Width!;
              theActualPoint.y = theActualPoint.y + 0.5 * theShape.modelData?.Thickness!;
              const theCentralPoint = ev.point.clone();
              theCentralPoint.x = theCentralPoint.x - 0.5 * theShape.modelData?.Width!;
              theCentralPoint.y = theCentralPoint.y - 0.5 * theShape.modelData?.Thickness!;
              const nj: FeedlineData = {
                ...feedlineJson,
                CenterPositions: {
                  // localBase: theShape.modelData?.CenterPositions.localTop!,
                  localTop: feedlineJson?.CenterPositions.localTop!,
                  localBase: theCentralPoint!
                },
                ActualPositions: {
                  // base: ev.point!,
                  top: feedlineJson?.ActualPositions.top!,
                  base: theActualPoint!
                }
              }

                // this.createGeometry({...nj, Id: "Custom"});
                
                this.createGeometry(getUnlinkedFeedline([nj])[0], CreateGeometryMode.New);
            } else this.createGeometry(feedlineJson, CreateGeometryMode.New);
            if(flIndex == feedlinesJson.length-1){
              let newShapes = this.shapes.filter(e=>!e.modelData?.Id.match(/start|middle|segment|end/));
              this.shapes = newShapes;        
              this.createGeometry(feedlineJson, CreateGeometryMode.Edit);
            }
          })
          // const newShapes = this.shapes.filter(e=>!e.modelData?.Id.match(/start|middle|segment|end/));
          
          // this.shapes = newShapes;
          // this.createGeometry(newJson!, CreateGeometryMode.Edit);
        }

      } else {
        const newShapes = this.shapes.filter(e=>!e.modelData?.Id.match(/start|middle|segment|end/));
        
        this.shapes = newShapes;
        this.createGeometry(newJson!, CreateGeometryMode.Edit);

      }

      IModelApp.viewManager.invalidateDecorationsAllViews();
      IModelApp.viewManager.selectedView?.invalidateCachedDecorations(this);
  }

  public async onDecorationButtonEvent(hit: HitDetail, _ev: BeButtonEvent): Promise<EventHandled> {
    let sourceId = hit.sourceId;
    // SampleToolWidget.selectedList = ListEnum.Equipment;
    // FeedlineDecorator.sourceId = sourceId;
    // let name = Array.from(this.nameIdMap.keys()).find((key) => this.nameIdMap.get(key) === sourceId);
    const theShape = this.shapes.find(e=>e.transientId == sourceId)!;
    this.selectedFeedline = {transId: sourceId, modelId: theShape?.modelData?.Id!};    

    if(store.getState().dtvState.applicationState.isEditModeActive){
      if(theShape?.modelData?.Id.match(/start|middle|segment|end/)){  
        const thePart = theShape.modelData?.Id.split('-')!;
        switch (thePart[1]) {
          case "start": {
            const end = {...this.shapes.find(e=>e.modelData?.Id.match(/end/))?.modelData!};
            await IModelApp.tools.run(PointSelectionTool.toolId, (ev: BeButtonEvent)=>{this.theLocationAdjuster(theShape, end!, ev)}, true);
          }
          break;
          case "end": {
            const start = {...this.shapes.find(e=>e.modelData?.Id.match(/start/))?.modelData!};
            await IModelApp.tools.run(PointSelectionTool.toolId, (ev: BeButtonEvent)=>{this.theLocationAdjuster(theShape, start!, ev)}, true);
          }
          break;
          case "segment": {
            const segment = {...this.shapes.find(e=>e.modelData?.Id.match(/segment/))?.modelData!};
            await IModelApp.tools.run(PointSelectionTool.toolId, (ev: BeButtonEvent)=>{this.theLocationAdjuster(theShape, segment!, ev)});
          }
          break;
        }
      }

    }



    // FeedlineDecorator.selectedFeedline = this.shapes.find(e=>e.transientId == sourceId)!;

    if(this.untouchedFeedlineData != undefined && store.getState().dtvState.applicationState.isEditModeActive && !theShape?.modelData?.Id.match(/start|middle|segment|end/)){
      // if(storedFl.findIndex(e=>e.clusterId == parseInt(feedId)) != -1){
          if(this.untouchedFeedlineData!.FeedlineType.type == FeedlineSectionType.Segmented){
            this.untouchedFeedlineSegments.forEach(e=>this.createGeometry(e));
          } else this.createGeometry(this.untouchedFeedlineData!, CreateGeometryMode.New);
          const newShapes = this.shapes.filter(e=>!e.modelData?.Id.match(/start|middle|segment|end/));
          this.shapes = newShapes;
  
          // SyncUiEventDispatcher.dispatchImmediateSyncUiEvent(SyncUiEventIds.Nothing_Selected);
          // SyncUiEventDispatcher.dispatchImmediateSyncUiEvent(SyncUiEventIds.RM_Feedline_UnSelected);
      // } else {
      //   const shapeIndex = decorator.shapes.findIndex(e=>e.modelData?.Id==feedId);
      //   if(shapeIndex != -1)decorator.shapes.splice(shapeIndex, 1);
      // } 
      this.untouchedFeedlineData = theShape ? {...theShape.modelData} as FeedlineData : undefined;
      
      const theSegments = theShape?.modelData?.Id.match(/@/) ? this.shapes.filter(e=>e.name == theShape.modelData?.Id.split('_')[0]) : [];

      this.untouchedFeedlineSegments = theSegments.length ? getUnlinkedFeedline(theSegments.map(e=>e.modelData!)) : [];
      if(this.untouchedFeedlineData)this.createGeometry(this.untouchedFeedlineData!, CreateGeometryMode.Edit);
      IModelApp.viewManager.invalidateDecorationsAllViews();
      IModelApp.viewManager.selectedView?.invalidateCachedDecorations(this);

      resetObjectIds(this);
    }

    // if(name!.includes('face')){
    //   const retVal = this.faceToEquipSelect(name!);
    //   name = retVal.name;
    //   sourceId = retVal.id;
    //   const iModelConnection = UiFramework.getIModelConnection();
    //   const equipId = this.nameIdMap.get(name);
    //   const equipId2 = this.objectIdMap.get(name);
    //   iModelConnection?.selectionSet.emptyAll();
    //   iModelConnection?.selectionSet.add(sourceId as Id64String);
    //   iModelConnection?.selectionSet.add(equipId as Id64String);
    // }
    // // ModifyHandleDecoration.clear();
    // const currState = store.getState();
    // const editModeActive = currState.dtvState.applicationState.isEditModeActive;
      // if (editModeActive && !name?.match(/Micro_Wave/ig)) {
      //   const boxIndex = this!.boxes.findIndex((item) => item.name === name);
      //   ModifyHandleDecoration.create(IModelApp.viewManager.selectedView as ScreenViewport, sourceId, name!, boxIndex, this);
      //   if (ModifyHandleDecoration._decorator) {
      //     ModifyHandleDecoration._decorator._shape = this.boxes[boxIndex].box;
      //     ModifyHandleDecoration._decorator._boxIndex = boxIndex;
      //     ModifyHandleDecoration._decorator._shapeName = name!;
      //     ModifyHandleDecoration._decorator.createClipShapeControls();
      //   }
      // } else {
      //   ModifyHandleDecoration.clear();
      // }


    // if (name) {
    //   IndividualShapeDecorator.selectedEquipName = name;
    //   SampleToolWidget.selectedBoxName = name;
    //   // show equipment widget
    //   UiFramework.frontstages.activeFrontstageDef?.getStagePanelDef(StagePanelLocation.Right)?.
    //     findWidgetDef("PropertyListWidget")?.setWidgetState(WidgetState.Open);
    //   if (name.match(/Box/i)) {
    //     SyncUiEventDispatcher.dispatchSyncUiEvent("box-selected");
    //     ModifyHandleDecoration.clear();
    //     SampleToolWidget.selectedBoxData = this.defectBoxInfo.find((i: any) => i.defectId === name);
    //   } else {
    //     SyncUiEventDispatcher.dispatchSyncUiEvent("equipmentselected");
    //     const isMicroWave = name.match(/Micro_Wave/i);
    //     if (isMicroWave) {
    //       ModifyHandleDecoration.clear();
    //     }
    //     if (SampleToolWidget.isEditModeActive) {
    //       if (!isMicroWave) {
    //         const boxIndex = this!.boxes.findIndex((item) => item.name === name);
    //         ModifyHandleDecoration.clear();
    //         ModifyHandleDecoration.create(IModelApp.viewManager.selectedView as ScreenViewport, sourceId, name!, boxIndex, this);
    //       } else {
    //         UiFramework.frontstages.activeFrontstageDef?.getZoneDef(ZoneLocation.CenterRight)?.
    //         findWidgetDef("EquipmentRotation")?.setWidgetState(WidgetState.Open);
    //       }
    //     }
    //   }
    //   IModelApp.tools.run(SelectionTool.toolId);
    // }
    return EventHandled.No;
  }
  // public async loadBoxes(defects: any, towerAltitude: number, clearBox: boolean = false): Promise<void> {
  //   if (clearBox) {
  //     this.defectBoxes = [];
  //     this.boxes = [];
  //     this.shapes = [];
  //     this.drawnBoxes = [];
  //     this.drawnCylinders = [];
  //     return;
  //   }
  //   // Convert Catogrphic to spatial and calc midpoint in seperate buffers.
  //   for (const defect of defects) {
  //     const tempPoints = defect.baseSize;
  //     const iModel = iModelConnection!;
  //     const spatialPoints: Point3d[] = [];
  //     for (let i = 0; i < tempPoints.length; i += 2) {
  //         let cart = Cartographic.fromDegrees({longitude: tempPoints[i], latitude: tempPoints[i + 1], height: towerAltitude});
  //         const posXyz = await iModel.cartographicToSpatial(cart);
  //         spatialPoints.push(posXyz);
  //     }
  //     const pos = new Point3d();
  //     const dist: number[] = [];
  //     for (let i = 0; i < spatialPoints.length - 1; i++) {
  //       const data = spatialPoints[i];
  //       pos.x += data.x;
  //       pos.y += data.y;
  //       pos.z += data.z;
  //       // distance calc for bounding box
  //       const dx = data.x - spatialPoints[i + 1].x;
  //       const dy = data.y - spatialPoints[i + 1].y;
  //       const dz = data.z - spatialPoints[i + 1].z;
  //       // Pythogoras
  //       dist.push(Math.sqrt(dx * dx + dy * dy + dz * dz));
  //     }
  //     pos.x += spatialPoints[spatialPoints.length - 1].x;
  //     pos.y += spatialPoints[spatialPoints.length - 1].y;
  //     pos.z += spatialPoints[spatialPoints.length - 1].z;
  //     // fast midpoint calc barycentric
  //     pos.x = pos.x / spatialPoints.length;
  //     pos.y = pos.y / spatialPoints.length;
  //     pos.z = pos.z / spatialPoints.length;
  //     const equipPosition: FeedlineData = {
  //       Height: dist[1],
  //       Width: dist[0],
  //       Thickness: defect.basePosition,
  //       // x_position: pos.x,
  //       // y_position: pos.y,
  //       // z_position: pos.z,
  //       Azimuth: 0,
  //       Id: ""
  //     };
  //     const box = this.constructBoxGeometry(equipPosition);
  //     if (box) {
  //       const name = "Box" + Math.random();
  //       const entry: BoxEntry = {
  //         box,
  //         name,
  //       };
  //       this.defectBoxes.push(entry);
  //       this.boxes.push(entry);
  //       defect.defectId = name;
  //       this.defectBoxInfo.push(defect);
  //       const vp = IModelApp.viewManager.selectedView;
  //       if (vp === undefined) return;
  //       const nextId = vp.iModel.transientIds.getNext();
  //       this.objectIdMap.set(nextId, `defectGeom#${name}`);
  //       this.nameIdMap.set(`${name}`, nextId);
  //     }
  //   }
  // }

  public async loadShapes(feedlineData: feedlineDataStructure[] = []) {
    // const selectedEquipment = SampleToolWidget.sampleToolWidget.state.operatorFilterData.selectedEquipment.length ? SampleToolWidget.sampleToolWidget.state.operatorFilterData.selectedEquipment: ["All"];;
    // const selectedEquipment = ["All"];;
    this.defectBoxes = [];
    this.boxes = [];
    this.shapes = [];
    this.drawnBoxes = [];
    this.antennaBoxes = [];
    this.rruBoxes = [];
    this.drawnCylinders = [];
    this.loadedShapes = false;
    this.nameIdMap = new Map<string, Id64String>();
    IModelApp.viewManager.selectedView?.invalidateDecorations();
    IModelApp.viewManager.selectedView?.invalidateCachedDecorations(this);

    // const equipNamePositionMap: Map<string, any> = new Map(equipDataMaps.equipNamePositionMap);
    if (EquipmentsTable.equipNamePositionMap.size === 0) {
      IModelApp.viewManager.selectedView?.invalidateDecorations();
    }
    // data = data.length ? await FeederLineClient.getFeedLineData(store.getState().auth.accessTokenStatePrivateAPI.accessToken!) : data;//Temporary example data.
    const iModel = UiFramework.getIModelConnection()!;
    // let tsd = store.getState().detectedData.towerStructureData as towerStructureData;
    // const maps: any = new Map(data.map((e, _i)=>{
      // const cart = iModel!.spatialToCartographicFromEcef(e.startCenter);
      // const m = DecoratorHelper.convertWGS2UTM(cart);
      // const ypr = YawPitchRollAngles.createDegrees(-e.orientation, 0, 0);
      // const matrix = ypr.toMatrix3d();
      // // const matrix = new Matrix3d();
      // const o = iModel.spatialToCartographicFromEcef(iModel.projectExtents.high!);
      // const ecefA = DecoratorHelper.utm2localCoords(o, e.startCenter.x, e.startCenter.y, e.startCenter.z, iModel, tsd.epsg_code);
      // const ecefB = DecoratorHelper.utm2localCoords(o, e.endCenter.x, e.endCenter.y, e.endCenter.z, iModel, tsd.epsg_code);
      // const dot = ecefA.dotVectorsToTargets(new Point3d(ecefB.x, ecefB.y, 0), ecefB);
      // const lengthA = ecefA.distance(ecefB);
      // const lengthB = ecefA.distance(new Point3d(ecefB.x, ecefB.y, 0));
      // const theta = Math.acos(dot / (lengthA * lengthB));
      // const deg = theta * 180 / Math.PI; // 1Rad × 180/π
      // const points = [e.startCenter, e.endCenter] // [ [x,y,z] , [x,y,z] , [x,y,z] ]
      // const utms = points.map((e: any) => e[2]);
      // const altitude = (utms.reduce((sum: number, e: number) => sum + e, 0) / utms.length);
      // const elevation = altitude - tsd.base_altitude;
      // const theHeight = e.endCenter.z-e.startCenter.z;
      // ecefA.

      // const radius = .2;
      // let sphere = Sphere.createCenterRadius(Point3d.create(ecefA.x, ecefA.y, ecefA.z), radius);
      // builder.addSphere(sphere);
      // let polyface = builder.claimPolyface(false);
      // decoratorState.setColor(color);
      // this.addGeometry(polyface, ColorDef.fromTbgr(ColorDef.withTransparency(ColorDef.create(ColorByName.cyan).tbgr, 50)), "sphere");
    
      // sphere = Sphere.createCenterRadius(Point3d.create(ecefB.x, ecefB.y, ecefB.z), radius);
      // builder.addSphere(sphere);
      // polyface = builder.claimPolyface(false);
      // decoratorState.setColor(color);
      // this.addGeometry(polyface, ColorDef.fromTbgr(ColorDef.withTransparency(ColorDef.create(ColorByName.cyan).tbgr, 50)), "sphere");

      // const azMatrix = Matrix3d.createRotationAroundAxisIndex(AxisIndex.Z, Angle.createDegrees(-equipJson.Azimuth));
      // lineVector.interpolate(0.5, lineVector.negate())
      // let mp = p1.interpolate(0.5, p2)
      // lineVector.scaleToLength(((e.height*0.5)), lineVector);
      // const trf=Transform.createTranslation(lineVector);

      // sphere = Sphere.createCenterRadius(Point3d.create(mp.x, mp.y, mp.z), radius);
      // builder.addSphere(sphere);
      // polyface = builder.claimPolyface(false);
      // decoratorState.setColor(color);
      // this.addGeometry(polyface, ColorDef.fromTbgr(ColorDef.withTransparency(ColorDef.create(ColorByName.cyan).tbgr, 50)), "sphere");

      // let newAng = lineVector.angleTo(new Vector3d(p2.x, p2.y, p2.z));
      // lineVector.angleToXY()

      // let newAng = YawPitchRollAngles.createFromMatrix3d(newMatrix!)!;

      // const midPoint = new Point3d((e.startCenter.x+e.endCenter.x)/2, (e.startCenter.y+e.endCenter.y)/2, (e.startCenter.z+e.endCenter.z)/2)
      // const startPt = new Point3d(ecefA.x, ecefA.y, ecefA.z);
      // let aVec = new Vector3d(startPt.x, startPt.y, startPt.z);
      // const endPt = new Point3d(ecefB.x, ecefB.y, ecefB.z);
    
      // const azMatrix = Matrix3d.createRotationAroundAxisIndex(AxisIndex.Z, Angle.createDegrees(-e.orientation));
      // const azMatrix = Matrix3d.createRotationAroundVector(lineVector, Angle.createDegreesAdjustSigned180(-e.orientation));
      // trans = Transform.createFixedPointAndMatrix(centroidPtInLocal, azMatrix);
  
      const storedFl = feedlineData.length ? feedlineData : store.getState().detectedData.feedLinesData;
      let data: feedlineDataStructure[];
      if(storedFl.length === 0){
        data = await FeederLineClient.getFeedLineData();
        if(data) store.dispatch(setFeedLineData(data));
        else return false;
      } else data = storedFl;

      // const localBaseCenter = new Point3d(mp.x, mp.y, -0.5 * theHeight);
      let flm: FeedlineData[] = [];
      const o = iModel.spatialToCartographicFromEcef(iModel.projectExtents.high!);
      data.map((e)=>{
          flm.push(...this.getJson(e, iModel, o, flm.length));
        })
      const maps: any = new Map(flm.map(f=>([f[0], {...f[1]}])));

      // const phi = e.orientation * ((Math.PI)/180);
      // const rotMatrix = [   Math.cos(phi) , -Math.sin(phi), 0 ,
      //                        Math.sin(phi) , Math.cos(phi) , 0 ,
      //                        0               , 0               , 1 ];
      // const rot = new Matrix3d(new Float64Array(rotMatrix));      


      // let vec = box.getVectorY();
      // vec.scaleToLength((-(e.height)), vec);
      // const trf=Transform.createTranslation(vec);
      // box.tryTransformInPlace(trf);
        // box?.tryTransformInPlace(Transform.createOriginAndMatrix(undefined, matrix))
      // box?.tryTransformInPlace()

      // let shiftPos = new Vector3d();
      // let oldCentroidPtInGlobal = new Point3d(0, 0, 0);
      // let centroidPtInLocal = new Point3d(0, 0, 0);
      // let newCentroidPtInGlobal = new Point3d(0, 0, 0);
      // const azMatrix = Matrix3d.createRotationAroundAxisIndex(AxisIndex.Z, Angle.createDegrees(-e.orientation));
      // const trans = Transform.createFixedPointAndMatrix(centroidPtInLocal, azMatrix);
      // newCentroidPtInGlobal = trans.multiplyPoint3d(newCentroidPtInGlobal);
      // // Apply to the box
      // box?.tryTransformInPlace(trans);
      // shiftPos = trans.multiplyVector(shiftPos);
  
      // // =====================================
      // // Step 4 - apply centroid transform (Global Coordinate)
      // // =====================================
      // shiftPos = shiftPos.plus(oldCentroidPtInGlobal);
      // const translation = Transform.createTranslation(shiftPos);
      // // Apply to the box
      // box?.tryTransformInPlace(translation);
  


      // this.addGeometry(polyface, ColorDef.fromTbgr(ColorDef.withTransparency(ColorDef.create(ColorByName.aqua).tbgr, 50)), "Box");

    
      // console.log('This is the o, ecefA, ecefB, dot, lengthA, lengthB, theta, deg, altitude, elevation, newMatrix: ', o, ecefA, ecefB, dot, lengthA, lengthB, theta, deg, altitude, elevation, newMatrix);
      // return ([`Antenna_${_i}`,
      // {
      //   "Azimuth": -(newAng?.yaw),
      //   "Equipment_Name": `Antenna_${_i}`,
      //   "Height": theHeight,
      //   "Elevation_Height": 36.045746,
      //   "Manufacturer": 'NOKIA',
      //   "Model": "AEQU",
      //   "Roll": 0,
      //   "Thickness": e.height,
      //   "Tilt": 0,
      //   "Width": e.width,
      //   "DisplayName": `Antenna_${_i}`,
      //   "x_position": midPoint.x,
      //   "y_position": midPoint.y,
      //   "z_position": midPoint.z,
      //   "feeder": e,
      //   "theData": {localBase: localBaseOrigin, localTop: localTopOrigin}
      //   // "theMatrix": newMatrix
      // }])
    //   return [];
    // }));
    // const map = new Map(new Map([
    //   [
    //       "Antenna_1",
    //       {
    //           "Azimuth": 147,
    //           "Equipment_Name": "Antenna_1",
    //           "Height": 0.75,
    //           "Elevation_Height": 45.11,
    //           "Manufacturer": "NOKIA",
    //           "Model": "AEQU",
    //           "Roll": 0,
    //           "Thickness": 0.24,
    //           "Tilt": -0.4,
    //           "Width": 0.45,
    //           "DisplayName": "Antenna_1",
    //           "x_position": 360675.09587003745,
    //           "y_position": 4428904.298023943,
    //           "z_position": 125.83609206820708
    //       }
    //   ],
    // ]));


    const masps = new Map(new Map([
      [
          "Antenna_25",
          {
            "Azimuth": -(51.75130797685135),
            "Equipment_Name": "Antenna_25",
            "Height": 127.9891505451873-82.38915054518729,
            "Elevation_Height": 36.045746,
            "Manufacturer": 'NOKIA',
            "Model": "AEQU",
            "Roll": 0,
            "Thickness": 0.10,
            "Tilt": 0,
            "Width": 0.5,
            "DisplayName": "Antenna_25",
            "x_position": 360674.38223554107,
            "y_position": 4428904.379713146,
            "z_position": 106.7695027967974,
              // "x_position": 360673.2816707022,
            // "y_position": 4428905.61874918,
            // "z_position": 116.11432401138008
        }
        // {
        //   "Azimuth": 75.5,
        //   "Equipment_Name": "Antenna_25",
        //   "Height": 2,
        //   "Elevation_Height": 36.045746,
        //   "Manufacturer": "NOKIA",
        //   "Model": "AEQU",
        //   "Roll": 0,
        //   "Thickness": 0.25,
        //   "Tilt": 0,
        //   "Width": 0.5,
        //   "DisplayName": "Antenna_25",
        //   "x_position": 360673.1348636053,
        //   "y_position": 4428905.641524891,
        //   "z_position": 116.11432401138008
        // }        
      ],
    ]));

    // const mps = EquipmentsTable.equipNamePositionMap;
    // const tem: FeedlineData[] = [...tempEquipMap];
    // let tempAllIdMaps = new Map([...all3DObjectsMap.idValues]);

    // for (const [currName, json] of maps) {
    flm.forEach(json=> {
      if (!json) {
        return;
      }
      let entry: any;
      // if(selectedEquipment[0] == "All" || selectedEquipment.indexOf(currName) != -1){
          entry = this.drawBox(json);
        const retVal = this.saveIntoLocalObj(json, entry);
        // tem.push(retVal?.jsonObj!);
        // tempAllIdMaps = new Map(retVal?.objectIdMap);
      // }
    })
    // store.dispatch(setEquipmentDataMaps({...equipDataMaps, tempEquipMap: tem}));
    // store.dispatch(addToBuilt3DObjectIdsMap(new Map(tempAllIdMaps)));
    this.loadedShapes = true;
    IModelApp.viewManager.selectedView?.invalidateCachedDecorations(this);
    return this.loadedShapes;
  }


  public reload = async (feedlineData: feedlineDataStructure[] = []): Promise<boolean> => {
    feedlineData = feedlineData.length ? feedlineData : store.getState().detectedData.feedLinesData;
    if(!feedlineData?.length)return false;
    this.shapes = [];
    this.objectIdMap.clear();
    IModelApp.viewManager.invalidateDecorationsAllViews();
    IModelApp.viewManager.selectedView?.invalidateCachedDecorations(this);

    this.loadShapes(feedlineData);
    return true;
  }


  public getJson = (e, iModel, o, theId): FeedlineData[] => {
    let startPt, endPt;

    // if(e.clusterId == 1663){
    //   startPt = new Point3d(-3.916844130381833,-6.300245031569192,-30.51673721109995);
    //   endPt = new Point3d(-3.860031070784317,-6.21195782409602,-29.474511928558286)
    // } else {
      startPt = DecoratorHelper.ExtractSpatialXYZ(o, e.startCenter.x, e.startCenter.y, e.startCenter.z, iModel);
      endPt = DecoratorHelper.ExtractSpatialXYZ(o, e.endCenter.x, e.endCenter.y, e.endCenter.z, iModel);
    // }

    const theWidth = e.width;
    // const theHeight = startPt.distance(endPt);
    // let lineVector = startPt.vectorTo(endPt);
    const theHeight = startPt.distance(endPt);
    const theThickness = e.height;
    const localBaseOrigin = new Point3d(startPt.x, startPt.y, startPt.z);
    localBaseOrigin.x = localBaseOrigin.x - 0.5 * theWidth;
    localBaseOrigin.y = localBaseOrigin.y - 0.5 * theThickness;
    
    const localTopOrigin = new Point3d(endPt.x, endPt.y, endPt.z);;
    localTopOrigin.x = localTopOrigin.x - 0.5 * theWidth;
    localTopOrigin.y = localTopOrigin.y - 0.5 * theThickness;
    // const midPoint = new Point3d((e.startCenter.x+e.endCenter.x)/2, (e.startCenter.y+e.endCenter.y)/2, (e.startCenter.z+e.endCenter.z)/2)
    let midPoint = startPt.interpolate(0.5, endPt);
    // const ypr = YawPitchRollAngles.createDegrees(-e.orientation, 0, 0);
    let i=0
    // const flm: FeedlineData[] = [];
    // let startNoOffset: Point3d = startPt, endNoOffset: Point3d;
    // if(e.segmentPoints != null){
    //   const segmentPoints = e.segmentPoints.map(sp=>DecoratorHelper.ExtractSpatialXYZ(o, sp.x, sp.y, sp.z, iModel));
    //   if(segmentPoints.length){
    //     let start: Point3d = startPt, end: Point3d;
    //     for(; i < segmentPoints.length; i++){
    //       if(i == 0)end=segmentPoints[i];
    //       else if(i == segmentPoints.length-2){
    //           start=segmentPoints[i+1];
    //           end = endPt;
    //           start.x = start.x - 0.5 * theWidth;
    //           start.y = start.y - 0.5 * theThickness;
    //       } else {
    //           start=segmentPoints[i];
    //           end=segmentPoints[i+1];
    //       }
    //       startNoOffset = start;
    //       endNoOffset = end;
    //       start.x = start.x - 0.5 * theWidth;
    //       start.y = start.y - 0.5 * theThickness;
    //       end.x = end.x - 0.5 * theWidth;
    //       end.y = end.y - 0.5 * theThickness;
    //       midPoint = start.interpolate(0.5, end);
    //       // theId+=`@${i}`
    //       // this.addFeedline(start, end, theWidth, theThickness, e.orientation, e);
    //       const elm: FeedlineData = {
    //         "Azimuth": (e.orientation),
    //         // "Equipment_Name": `Antenna_${flm.length+1}`,
    //         "Height": theHeight,
    //         // "Elevation_Height": 36.045746,
    //         // "Manufacturer": 'NOKIA',
    //         // "Model": "AEQU",
    //         // "Roll": 0,
    //         "Thickness": e.height,
    //         // "Tilt": 0,
    //         "Width": e.width,
    //         "Id": `${e.clusterId}_${theId}@${i}`,
    //         // "startCenter": startNoOffset,
    //         // "endCenter": endNoOffset,
    //         // "DisplayName": `Antenna_${theId+1}`,
    //         // "x_position": midPoint.x,
    //         // "y_position": midPoint.y,
    //         // "z_position": midPoint.z,
    //         // "feeder": e,
    //         "CenterPositions": { localBase: start, localTop: end },
    //         "ActualPositions": { base: startNoOffset, top: endNoOffset },
    //         "FeedlineType": {type: FeedlineSectionType.Segmented, segmentCount: segmentPoints.length, absoluteLength: theHeight}
    //       }
    //       flm.push(elm)
    //       // return elm;
    //     }
    //   }
    //   const stPt = segmentPoints[segmentPoints.length-1];
    //   // stPt.x = stPt.x - 0.5 * theWidth;
    //   // stPt.y = stPt.y - 0.5 * theThickness;
    //   startNoOffset = stPt;
    //   endNoOffset = endPt;
    //   midPoint = stPt.interpolate(0.5, localTopOrigin);
    //   // theId+=`@${i}`

    //   // this.addFeedline(stPt, localTopOrigin, theWidth, theThickness, e.orientation, e);
    //   const elm: FeedlineData = {
    //     "Azimuth": (e.orientation),
    //     // "Equipment_Name": `Antenna_${theId+1}`,
    //     "Height": theHeight,
    //     // "Elevation_Height": 36.045746,
    //     // "Manufacturer": 'NOKIA',
    //     // "Model": "AEQU",
    //     // "Roll": 0,
    //     "Thickness": e.height,
    //     // "Tilt": 0,
    //     "Id": `${e.clusterId}_${theId}@${i}`,
    //     "Width": e.width,
    //     // "DisplayName": `Antenna_${theId+1}`,
    //     // "x_position": midPoint.x,
    //     // "y_position": midPoint.y,
    //     // "z_position": midPoint.z,
    //     // "feeder": e,
    //     "CenterPositions": {localBase: stPt, localTop: localTopOrigin},
    //     "ActualPositions": {base: startNoOffset, top: endNoOffset},
    //     "FeedlineType": {type: FeedlineSectionType.Segmented, segmentCount: segmentPoints.length, absoluteLength: theHeight}
    //   };
    //   flm.push(elm)
    //   // return elm;

    //     // this.addBoxWithInfo(objinfo);

    // } else {
    //   // this.addFeedline(localBaseOrigin, localTopOrigin, theWidth, theThickness, e.orientation, e);
    //   startNoOffset = startPt;
    //   endNoOffset = endPt;
    //   const elm: FeedlineData = {
    //     "Azimuth": (e.orientation),
    //     // "Equipment_Name": `Antenna_${theId+1}`,
    //     "Height": theHeight,
    //     // "Elevation_Height": 36.045746,
    //     // "Manufacturer": 'NOKIA',
    //     // "Model": "AEQU",
    //     // "Roll": 0,
    //     "Thickness": e.height,
    //     // "Tilt": 0,
    //     "Width": e.width,
    //     "Id": `${e.clusterId}_${theId}`,
    //     // "DisplayName": `Antenna_${flm.length+1}`,
    //     // "x_position": midPoint.x,
    //     // "y_position": midPoint.y,
    //     // "z_position": midPoint.z,
    //     // "feeder": e,
    //     "CenterPositions": {localBase: localBaseOrigin, localTop: localTopOrigin},
    //     "ActualPositions": {base: startNoOffset, top: endNoOffset},
    //     "FeedlineType": {type: FeedlineSectionType.NonSegmented, segmentCount: 0, absoluteLength: theHeight}
    //   }
    //   flm.push(elm);
    //   // return [elm];
    // }

    // return ([`Antenna_${_i}`,
    // {
    //     "Azimuth": -(e.orientation),
    //     "Equipment_Name": `Antenna_${_i}`,
    //     "Height": theHeight,
    //     "Elevation_Height": 36.045746,
    //     "Manufacturer": 'NOKIA',
    //     "Model": "AEQU",
    //     "Roll": 0,
    //     "Thickness": e.height,
    //     "Tilt": 0,
    //     "Width": e.width,
    //     "DisplayName": `Antenna_${_i}`,
    //     "x_position": midPoint.x,
    //     "y_position": midPoint.y,
    //     "z_position": midPoint.z,
    //     "feeder": e,
    //     "theData": {localBase: localBaseOrigin, localTop: localTopOrigin},
    //   }
    //   ])
      

    let allCoords = [e.startCenter]
    if(e.segmentPoints != null)allCoords = allCoords.concat(e.segmentPoints.map(s=>s));
    allCoords = allCoords.concat(e.endCenter);
    let midStart;
    const flm1: FeedlineData[] = []
    for(let i=0; i<allCoords.length-1; i++){
      // startPt = DecoratorHelper.ExtractSpatialXYZ(o, e.startCenter.x, e.startCenter.y, e.startCenter.z, iModel);
      // endPt = DecoratorHelper.ExtractSpatialXYZ(o, e.endCenter.x, e.endCenter.y, e.endCenter.z, iModel);
      startPt = DecoratorHelper.ExtractSpatialXYZ(o, allCoords[i].x, allCoords[i].y, allCoords[i].z, iModel);
      endPt = DecoratorHelper.ExtractSpatialXYZ(o, allCoords[i+1].x, allCoords[i+1].y, allCoords[i+1].z, iModel);
      
      const actBase = new Point3d(startPt.x, startPt.y, startPt.z);
      const actTop = new Point3d(endPt.x, endPt.y, endPt.z);

      const centBase = new Point3d(startPt.x, startPt.y, startPt.z);
      centBase.x = centBase.x - 0.5 * theWidth;
      centBase.y = centBase.y - 0.5 * theThickness;
  
      const centTop = new Point3d(endPt.x, endPt.y, endPt.z);
      centTop.x = centTop.x - 0.5 * theWidth;
      centTop.y = centTop.y - 0.5 * theThickness;
  
      let flId = `${e.clusterId}_${theId}`;
      if(allCoords.length > 2)flId+=`@${i}`;

      const elm: FeedlineData = {
        "Azimuth": (e.orientation),
        // "Equipment_Name": `Antenna_${theId+1}`,
        "Height": theHeight,
        // "Elevation_Height": 36.045746,
        // "Manufacturer": 'NOKIA',
        // "Model": "AEQU",
        // "Roll": 0,
        "Thickness": e.height,
        // "Tilt": 0,
        "Id": flId,
        "Width": e.width,
        // "DisplayName": `Antenna_${theId+1}`,
        // "x_position": midPoint.x,
        // "y_position": midPoint.y,
        // "z_position": midPoint.z,
        // "feeder": e,
        "CenterPositions": {localBase: centBase, localTop: centTop},
        "ActualPositions": {base: actBase, top: actTop},
        "FeedlineType": {type: allCoords.length > 2 ? FeedlineSectionType.Segmented : FeedlineSectionType.NonSegmented, segmentCount: allCoords.length >2 ? allCoords.length-2: 0, absoluteLength: theHeight}
      };
      flm1.push(elm)


    }

    return flm1;
      
  }


  public addFeedline = (localBaseOrigin, localTopOrigin, theWidth, theThickness, azimuth, _e={}) => {
    // const matrix = ypr.toMatrix3d();
    // let newMatrix = Matrix3d.createRotationAroundVector(lineVector, matrix.getAxisAndAngleOfRotation().angle)!;
    // newMatrix = Transform.identity.matrix;
    
    // const phi = e.orientation * ((Math.PI)/180);

    const options = StrokeOptions.createForCurves();
    options.needParams = false;
    options.needNormals = true;
    const box = Box.createDgnBoxWithAxes(localBaseOrigin, Transform.identity.matrix, localTopOrigin, theWidth, theThickness, theWidth, theThickness, true)!;

    let x=0, y=0, z=0;
    box.getCorners().forEach(e=>{x+=e.x;y+=e.y;z+=e.z;});
    // console.log("x, y: ",x, y, x/4, y/4);
    const centroidPtInLocal = new Point3d(x/8, y/8, z/8);

    const azMatrix = Matrix3d.createRotationAroundVector(box.getVectorZ(), Angle.createDegrees(azimuth.Azimuth))!;
    // const azMatrix = Matrix3d.createRotationAroundAxisIndex(AxisIndex.Z, newAng.yaw);
    const trans = Transform.createFixedPointAndMatrix(centroidPtInLocal, azMatrix);
    let newCentroidPtInGlobal = trans.origin;
    newCentroidPtInGlobal = trans.multiplyPoint3d(newCentroidPtInGlobal);
    // Apply to the box
    box?.tryTransformInPlace(trans);
    // let inrBox = box.

    // const box2 = Box.createDgnBoxWithAxes(localBaseOrigin, Transform.identity.matrix, localTopOrigin, theWidth, theThickness, theWidth, theThickness, true)!;
    // const boxBuilder = PolyfaceBuilder.create(options);
    // boxBuilder.addBox(box2!);
    // let boxPolyface = boxBuilder.claimPolyface(false);
    // this.addGeometry(boxPolyface, ColorDef.fromTbgr(ColorDef.withTransparency(ColorDef.create(ColorByName.blanchedAlmond).tbgr, 180)), "Box");
    

    // boxBuilder.addBox(box!);
    // boxPolyface = boxBuilder.claimPolyface(false);
    // this.addGeometry(boxPolyface, ColorDef.fromTbgr(ColorDef.withTransparency(ColorDef.create(ColorByName.aqua).tbgr, 120)), "Box", false, undefined);


    const custom = this.createCustomFeedline(box)!;
    return custom;
    // boxBuilder.addLinearSweep(custom);
    // boxPolyface = boxBuilder.claimPolyface(false);
    // this.addGeometry(boxPolyface, ColorDef.fromTbgr(ColorDef.withTransparency(ColorDef.create(ColorByName.hotPink).tbgr, 100)), "Box", true);
    
    
    // this.getPipesInsideFeedline(box, options, trans, _e);
}


  public createCustomFeedline = (outerBox: Box) => {
    let lineString;
    if(!Object.entries(outerBox!).length){
      lineString = Loop.createPolygon([
        new Point3d(0, 5, 0), 
        new Point3d(5, 0, 0), 
        new Point3d(6, 1, 0), 
        new Point3d(6, 4, 0), 
        new Point3d(5, 5, 0), 
        new Point3d(4, 6, 0), 
        // new Point3d(3, 6, 0), 
        // new Point3d(2, 6, 0), 
        new Point3d(1, 6, 0), 
        // new Point3d(5, 5, 0)
      ]);
    } else {
      // innerBox.tryTransformInPlace(trans);
      // const decorator = new GeometryDecorator();
      // IModelApp.viewManager.addDecorator(decorator);
      const options = StrokeOptions.createForCurves();
      options.needParams = false;
      options.needNormals = true;
      const builder = PolyfaceBuilder.create(options);
      let polyface;

      let x=0, y=0, z=0;
      outerBox!.getCorners().forEach(e=>{x+=e.x;y+=e.y;z+=e.z;});
      // console.log("x, y: ",x, y, x/4, y/4);
      const centroidPtInLocal = new Point3d(x/8, y/8, z/8);

      const trans = Transform.createFixedPointAndMatrix(centroidPtInLocal, Matrix3d.createScale(1, 0.5, 1));
      // const innerBox = outerBox!.cloneTransformed(trans)!;
      const innerBox = outerBox!.cloneTransformed(Transform.identity)!;
      // const innerBox = outerBox!.cloneTransformed(Transform.createScaleAboutPoint(centroidPtInLocal, 0.5))!;


      builder.addBox(innerBox);
      polyface = builder.claimPolyface(false);
      // decorator.setColor(ColorDef.fromTbgr(ColorDef.withTransparency(ColorDef.create(ColorByName.blue).tbgr, 50)));
      // this.addGeometry(polyface, ColorDef.fromTbgr(ColorDef.withTransparency(ColorDef.create(ColorByName.blanchedAlmond).tbgr, 120)), "Box", false);

      let bcForPipe = outerBox!.getCorners();
      let topCenter = bcForPipe.slice(0,4);
      let botCenter = bcForPipe.slice(4);


      let len = botCenter[0].distance(botCenter[1]);
      let wid = botCenter[0].distance(botCenter[2]);
      // let len1 = botCenter[2].distance(botCenter[3]);
      // let wid1 = botCenter[1].distance(botCenter[3]);
      let pRad = 0.015;
      let pDist = pRad*2;

      let widIndices: {wid1:{from: number, to: number}, wid2: {from: number, to: number}}, lenIndices: {len1:{from: number, to: number}, len2: {from: number, to: number}};
      if(len > wid){
        lenIndices={len1:{from: 0, to: 1}, len2: {from: 3, to: 2}};
        widIndices={wid1:{from: 1, to: 3}, wid2: {from: 2, to: 0}};
      } else {
        lenIndices={len1:{from: 1, to: 3}, len2: {from: 2, to: 0}};
        widIndices={wid1:{from: 0, to: 1}, wid2: {from: 3, to: 2}};
      }

      const oBoxCorners = outerBox!.getCorners();
      const iBoxCorners = innerBox!.getCorners();

      let lenWiseCount = Math.floor((len)/pDist); 
      let widWiseCount = Math.floor((wid)/pDist);
      
      // let pipe = Cone.createAxisPoints(widBisBot1!, widBisTop1!, pRad, pRad, true);
      // builder.addCone(pipe!);
      // polyface = builder.claimPolyface(false);
      // this.addGeometry(polyface, ColorDef.fromTbgr(ColorDef.withTransparency(ColorDef.create(ColorByName.red).tbgr, 50)), "Pipe");

      // pipe = Cone.createAxisPoints(widBisBot2!, widBisTop2!, pRad, pRad, true);
      // builder.addCone(pipe!);
      // polyface = builder.claimPolyface(false);
      // this.addGeometry(polyface, ColorDef.fromTbgr(ColorDef.withTransparency(ColorDef.create(ColorByName.red).tbgr, 50)), "Pipe");

      

      let rowMax = lenWiseCount > widWiseCount ? widWiseCount : lenWiseCount, colMax=lenWiseCount > widWiseCount ? lenWiseCount : widWiseCount;
      rowMax = rowMax <= 1 ? 2 : rowMax; 
      colMax = colMax <= 1 ? 2 : colMax; 

      const outerPoints: {point: Point3d, type: string, index: number}[] = [{point: oBoxCorners[lenIndices.len1.from], type: "outer", index: 0}], innerPoints: {point: Point3d, type: string, index: number}[] = [{point: iBoxCorners[lenIndices.len1.from], type: "inner", index: 0}];
      const outerPoints2: {point: Point3d, type: string, index: number}[] = [{point: oBoxCorners[lenIndices.len2.from], type: "outer", index: 0}], innerPoints2: {point: Point3d, type: string, index: number}[] = [{point: iBoxCorners[lenIndices.len2.from], type: "inner", index: 0}];
      for(let colCount=1; colCount < colMax; colCount++){
        let loc: Point3d;

        loc = iBoxCorners[lenIndices.len1.from].interpolate(colCount/colMax, iBoxCorners[lenIndices.len1.to]);
        innerPoints.push({point: loc, type: "inner", index: colCount});
        // let sphere = Sphere.createCenterRadius(loc, 0.05);
        // builder.addSphere(sphere);
        // polyface = builder.claimPolyface(false);
        // decorator.addGeometry(polyface);

        loc = oBoxCorners[lenIndices.len1.from].interpolate(colCount/colMax, oBoxCorners[lenIndices.len1.to]);
        outerPoints.push({point: loc, type: "outer", index: colCount});
        // sphere = Sphere.createCenterRadius(loc, 0.05);
        // builder.addSphere(sphere);
        // polyface = builder.claimPolyface(false);
        // decorator.addGeometry(polyface);

        loc = iBoxCorners[lenIndices.len2.from].interpolate(colCount/colMax, iBoxCorners[lenIndices.len2.to]);
        innerPoints2.push({point: loc, type: "inner", index: colCount});
        // let sphere = Sphere.createCenterRadius(loc, 0.05);
        // builder.addSphere(sphere);
        // polyface = builder.claimPolyface(false);
        // decorator.addGeometry(polyface);

        loc = oBoxCorners[lenIndices.len2.from].interpolate(colCount/colMax, oBoxCorners[lenIndices.len2.to]);
        outerPoints2.push({point: loc, type: "outer", index: colCount});
        // sphere = Sphere.createCenterRadius(loc, 0.05);
        // builder.addSphere(sphere);
        // polyface = builder.claimPolyface(false);
        // decorator.addGeometry(polyface);
      }
      outerPoints.push({point: oBoxCorners[lenIndices.len1.to], type: "outer", index: outerPoints.length})
      outerPoints2.push({point: oBoxCorners[lenIndices.len2.to], type: "outer", index: outerPoints2.length})
      innerPoints.push({point: iBoxCorners[lenIndices.len1.to], type: "inner", index: innerPoints.length})
      innerPoints2.push({point: iBoxCorners[lenIndices.len2.to], type: "inner", index: innerPoints2.length})

      const thePoints: {point: Point3d, type: string, index: number}[] = []
      colMax = colMax%2 == 0 ? colMax+1 : colMax;

      const {lenOutSide1: op, lenOutSide2: op2, lenInSide1: ip, lenInSide2: ip2} = this.getPoints(oBoxCorners, lenIndices, widIndices, colMax);


      // const totalOuterPoints = [...outerPoints, ...outerPoints2];
      // const totalInnerPoints = [...innerPoints, ...innerPoints2];

      const totalOuterPoints = [...op, ...op2];
      const totalInnerPoints = [...ip, ...ip2];

      for(let i=0, outerFirst = true; i < totalOuterPoints.length; i++){
        if(i%2==0 && outerFirst){
          thePoints.push(totalInnerPoints[i], totalOuterPoints[i]);
          outerFirst=!outerFirst;
        } else {
          thePoints.push(totalOuterPoints[i], totalInnerPoints[i]);
          outerFirst=!outerFirst;
          // thePoints.push(outerPoints[i], innerPoints[i]);
        }
      }
      const justPoints = thePoints.map(e=>e.point);

      // for(let i=0, outerFirst = true; i < outerPoints.length; i++){
      //   if(i%2==0 && outerFirst){
      //     thePoints.push(innerPoints[i], outerPoints[i]);
      //     console.log('This is else inner first i%2!=0: ', i, outerFirst)
      //     outerFirst=!outerFirst;
      //   } else {
      //     console.log('This is if outer first i%2==0: ', i, outerFirst)
      //     thePoints.push(outerPoints[i], innerPoints[i]);
      //     outerFirst=!outerFirst;
      //     // thePoints.push(outerPoints[i], innerPoints[i]);
      //   }
      // }
      // const justPoints = thePoints.map(e=>e.point);
      // console.log('These are the points after: ', thePoints, justPoints);


      lineString = Loop.createPolygon(justPoints);
      // lineString.tryTransformInPlace()

      // lineString = Loop.createPolygon([
      //   // oBoxCorners[0],
      //   // oBoxCorners[1],
      //   // oBoxCorners[3],
      //   // oBoxCorners[2],
      //   oBoxCorners[lenIndices.len1.from],
      //   oBoxCorners[widIndices.wid2.from],
      //   oBoxCorners[widIndices.wid2.from].interpolate(0.1, oBoxCorners[lenIndices.len2.from]),
      //   // oBoxCorners[widIndices.wid2.from].interpolate(0.1, oBoxCorners[lenIndices.len2.from]),

      //   // oBoxCorners[lenIndices.len2.from],
      //   // oBoxCorners[widIndices.wid1.from],
      // ]);

    }
    
    const vec = outerBox!.getBaseOrigin().vectorTo(outerBox!.getTopOrigin());
    // const linearSweep = LinearSweep.create(lineString, Vector3d.create(0,0, 10), true/* capped */);
    const linearSweep = LinearSweep.create(lineString, vec, true/* capped */);
    if (!linearSweep) {
      return;
    }
    return linearSweep
      
  }

  public getPoints = (boxCorners: Point3d[], lenIndices: {len1:{from: number, to: number}, len2: {from: number, to: number}}, _widIndices: {wid1:{from: number, to: number}, wid2: {from: number, to: number}}, colMax) => {
    const options = StrokeOptions.createForCurves();
    options.needParams = false;
    options.needNormals = true;
    const builder = PolyfaceBuilder.create(options);
    let lenOutSide1: {point: Point3d, type: string, index: number}[] = [], lenOutSide2: {point: Point3d, type: string, index: number}[] = [], lenInSide1: {point: Point3d, type: string, index: number}[] = [], lenInSide2: {point: Point3d, type: string, index: number}[] = [];
    let polyface;
    let groudDepth = 0.3;
    for(let i=0; i <= colMax; i++){
      let loc1 =   boxCorners[lenIndices.len1.from].interpolate((i/colMax), boxCorners[lenIndices.len1.to])
      lenOutSide1.push({point: loc1, type: "outer", index: i});
      
      let sphere = Sphere.createCenterRadius(loc1, 0.01);
      // builder.addSphere(sphere);
      // polyface = builder.claimPolyface(false);
      // this.addGeometry(polyface, ColorDef.fromTbgr(ColorDef.withTransparency(ColorDef.create(ColorByName.green).tbgr, 120)), "Box", false);
      
      let loc2 =   boxCorners[lenIndices.len2.to].interpolate((i/colMax), boxCorners[lenIndices.len2.from])
      lenOutSide2.push({point: loc2, type: "outer", index: i});
      sphere = Sphere.createCenterRadius(loc2, 0.01);
      // builder.addSphere(sphere);
      // polyface = builder.claimPolyface(false);
      // this.addGeometry(polyface, ColorDef.fromTbgr(ColorDef.withTransparency(ColorDef.create(ColorByName.green).tbgr, 120)), "Box", false);
      
      let inLoc1 = loc1.interpolate(groudDepth, loc2);
      lenInSide1.push({point: inLoc1, type: "inner", index: i});
      sphere = Sphere.createCenterRadius(inLoc1, 0.01);
      // builder.addSphere(sphere);
      // polyface = builder.claimPolyface(false);
      // this.addGeometry(polyface, ColorDef.fromTbgr(ColorDef.withTransparency(ColorDef.create(ColorByName.green).tbgr, 120)), "Box", false);
      
      let inLoc2= loc1.interpolate(1-groudDepth, loc2);
      lenInSide2.push({point: inLoc2, type: "inner", index: i});
      sphere = Sphere.createCenterRadius(inLoc2, 0.01);
      // builder.addSphere(sphere);
      // polyface = builder.claimPolyface(false);
      // this.addGeometry(polyface, ColorDef.fromTbgr(ColorDef.withTransparency(ColorDef.create(ColorByName.green).tbgr, 120)), "Box", false);
      // builder.addBox(innerBox);
      // polyface = builder.claimPolyface(false);
      // // decorator.setColor(ColorDef.fromTbgr(ColorDef.withTransparency(ColorDef.create(ColorByName.blue).tbgr, 50)));
      // this.addGeometry(polyface, ColorDef.fromTbgr(ColorDef.withTransparency(ColorDef.create(ColorByName.blanchedAlmond).tbgr, 120)), "Box", false);

    }
    lenOutSide2 = lenOutSide2.reverse()
    lenInSide2 = lenInSide2.reverse()
    return {lenOutSide1, lenOutSide2, lenInSide1, lenInSide2}
  }

  public getPipesInsideFeedline = (box, options, trans, e) => {
      
    let feedlinePolyface;
    const feedlineBuilder = PolyfaceBuilder.create(options);

    let bcForPipe = box.getCorners();
    let topCenter = bcForPipe.slice(0,4);
    let botCenter = bcForPipe.slice(4);


    let len = botCenter[0].distance(botCenter[1]);
    let wid = botCenter[0].distance(botCenter[2]);
    let len1 = botCenter[2].distance(botCenter[3]);
    let wid1 = botCenter[1].distance(botCenter[3]);
    let pRad = 0.025;
    let pDist = pRad*2.1;

    let widIndices, lenIndices;
    if(len > wid){
      lenIndices={len1:{from: 0, to: 1}, len2: {from: 3, to: 2}};
      widIndices={wid1:{from: 1, to: 3}, wid2: {from: 2, to: 0}};
    } else {
      lenIndices={len1:{from: 1, to: 3}, len2: {from: 2, to: 0}};
      widIndices={wid1:{from: 0, to: 1}, wid2: {from: 3, to: 2}};
    }

    const widBotDist1 = botCenter[widIndices.wid1.from].distance(botCenter[widIndices.wid2.to])
    const lenBotDist1 = botCenter[lenIndices.len1.from].distance(botCenter[lenIndices.len2.to])
    const widTopDist1 = topCenter[widIndices.wid1.from].distance(topCenter[widIndices.wid2.to])
    const widTopDist2 = topCenter[widIndices.wid1.from].distance(topCenter[widIndices.wid2.to])

    const widBisBot1 = botCenter[widIndices.wid1.from].interpolate(0.5, botCenter[widIndices.wid1.to]);
    const widBisBot2 = botCenter[widIndices.wid2.from].interpolate(0.5, botCenter[widIndices.wid2.to]);
    const widBisTop1 = topCenter[widIndices.wid1.from].interpolate(0.5, topCenter[widIndices.wid1.to]);
    const widBisTop2 = topCenter[widIndices.wid2.from].interpolate(0.5, topCenter[widIndices.wid2.to]);

    // const widBisBot1 = widBotVec1.scaleToLength(botCenter[widIndices.wid1.from].distance(botCenter[widIndices.wid1.to])/2)?.cloneAsPoint3d() as Point3d;
    // const widBisBot2 = widBotVec2.scaleToLength(botCenter[widIndices.wid2.from].distance(botCenter[widIndices.wid2.to])/2)?.cloneAsPoint3d() as Point3d;
    // const widBisTop1 = widTopVec1.scaleToLength(topCenter[widIndices.wid1.from].distance(topCenter[widIndices.wid1.to])/2)?.cloneAsPoint3d() as Point3d;
    // const widBisTop2 = widTopVec2.scaleToLength(topCenter[widIndices.wid2.from].distance(topCenter[widIndices.wid2.to])/2)?.cloneAsPoint3d() as Point3d;

    
    let x=0, y=0,z=0;
    topCenter.forEach(e=>{x+=e.x*0.8;y+=e.y*0.8;z+=e.z*0.8;});
    let pSt = new Point3d(x/4, y/4, z/4);
    x=0;y=0;z=0;
    botCenter.forEach(e=>{x+=e.x*0.8;y+=e.y*0.8;z+=e.z*0.8;});
    let pEn = new Point3d(x/4, y/4, z/4);
    
    let lPad = pRad/len;
    let wPad = pRad/wid;
     
    let lenWiseCount = Math.floor((len)/pDist); 
    let widWiseCount = Math.floor((wid)/pDist);
    
    // let pipe = Cone.createAxisPoints(widBisBot1!, widBisTop1!, pRad, pRad, true);
    // builder.addCone(pipe!);
    // polyface = builder.claimPolyface(false);
    // this.addGeometry(polyface, ColorDef.fromTbgr(ColorDef.withTransparency(ColorDef.create(ColorByName.red).tbgr, 50)), "Pipe");

    // pipe = Cone.createAxisPoints(widBisBot2!, widBisTop2!, pRad, pRad, true);
    // builder.addCone(pipe!);
    // polyface = builder.claimPolyface(false);
    // this.addGeometry(polyface, ColorDef.fromTbgr(ColorDef.withTransparency(ColorDef.create(ColorByName.red).tbgr, 50)), "Pipe");

    

    let rowMax = lenWiseCount > widWiseCount ? widWiseCount : lenWiseCount, colMax=lenWiseCount > widWiseCount ? lenWiseCount : widWiseCount;
    rowMax = rowMax <= 1 ? 2 : rowMax; 
    colMax = colMax <= 1 ? 2 : colMax; 
    for(let rowCount=1; rowCount < rowMax; rowCount++){
      const WBB1 = botCenter[widIndices.wid1.from].interpolate(rowCount/rowMax, botCenter[widIndices.wid1.to]);
      const WBB2 = botCenter[widIndices.wid2.to].interpolate(rowCount/rowMax, botCenter[widIndices.wid2.from]);
      const WBT1 = topCenter[widIndices.wid1.from].interpolate(rowCount/rowMax, topCenter[widIndices.wid1.to]);
      const WBT2 = topCenter[widIndices.wid2.to].interpolate(rowCount/rowMax, topCenter[widIndices.wid2.from]);

      // const WBB1 = botCenter[widIndices.wid1.from].interpolate(rowCount/widWiseCount, botCenter[widIndices.wid1.to]);
      // const WBB2 = botCenter[widIndices.wid2.from].interpolate(rowCount/widWiseCount, botCenter[widIndices.wid2.to]);
      // const WBT1 = topCenter[widIndices.wid1.from].interpolate(rowCount/widWiseCount, topCenter[widIndices.wid1.to]);
      // const WBT2 = topCenter[widIndices.wid2.from].interpolate(rowCount/widWiseCount, topCenter[widIndices.wid2.to]);
      let theTop, theBottom, theTopCenter1, theBottomCenter1, theTopCenter2, theBottomCenter2, pipe;
      theBottomCenter1 = WBB1, theTopCenter1 = WBT1;
      theBottomCenter2 = WBB2, theTopCenter2 = WBT2;


      for(let colCount=1; colCount < colMax; colCount++){
        theBottom = theBottomCenter1.interpolate(colCount/colMax, theBottomCenter2);
        // theBottom.x = theBottom.x + 0.5 * pRad;
        // theBottom.y = theBottom.y + 0.5 * pRad;
  
        theTop = theTopCenter1.interpolate(colCount/colMax, theTopCenter2);
        // theTop.x = theTop.x + 0.5 * pRad;
        // theTop.y = theTop.y + 0.5 * pRad;
            // pipe = Cone.createAxisPoints(theTop!, theBottom!, pRad, pRad, true);
        // builder.addCone(pipe!);
        // polyface = builder.claimPolyface(false);
        // this.addGeometry(polyface, ColorDef.fromTbgr(ColorDef.withTransparency(ColorDef.create(ColorByName.red).tbgr, 50)), "Pipe");

        
        let pipe = Box.createDgnBoxWithAxes(theTop, trans.matrix, theBottom, pRad, pRad, pRad, pRad, true)!;
        // let vec = box.getVectorY();
        // vec.scaleToLength(((e.height)/2)-0.5*pRad, vec);
        // let trf=Transform.createTranslation(vec);
        // pipe.tryTransformInPlace(trf);

        let x=0, y=0, z=0;
        pipe.getCorners().forEach(e=>{x+=e.x;y+=e.y;z+=e.z;});
        // console.log("x, y: ",x, y, x/4, y/4);
        const centroidPtInLocal = new Point3d(x/8, y/8, z/8);
            const azMatrix = Matrix3d.createRotationAroundVector(pipe.getVectorZ(), Angle.createDegrees(e.orientation))!;
        // const azMatrix = Matrix3d.createRotationAroundAxisIndex(AxisIndex.Z, newAng.yaw);
        // const trans = Transform.createFixedPointAndMatrix(centroidPtInLocal, azMatrix);
        // let newCentroidPtInGlobal = trans.origin;
        // newCentroidPtInGlobal = trans.multiplyPoint3d(newCentroidPtInGlobal);
        // Apply to the box
        // pipe?.tryTransformInPlace(trans);
  
        feedlineBuilder.addBox(pipe!);
        feedlinePolyface = feedlineBuilder.claimPolyface(false);
        this.addGeometry(feedlinePolyface, ColorDef.fromTbgr(ColorDef.withTransparency(ColorDef.create(ColorByName.black).tbgr, 0)), "Pipe", false);


      }
      
      // // let pt = widBisBot1.interpolate(0.5, widBisTop1)!
      // pipe = Cone.createAxisPoints(theTop!, theBottom!, pRad, pRad, true);
      // builder.addCone(pipe!);
      // polyface = builder.claimPolyface(false);
      // this.addGeometry(polyface, ColorDef.fromTbgr(ColorDef.withTransparency(ColorDef.create(ColorByName.red).tbgr, 50)), "Pipe");


    }

    // // for(let ciw=1; ciw < widWiseCount; ciw++){
    //   for(let cil=1; cil < lenWiseCount; cil++){
    //     let fstBtm = botCenter[lenIndices.len1.from].interpolate(cil/lenWiseCount, botCenter[lenIndices.len1.to]);//.scaleInPlace(0.8);
    //     let fstTop = topCenter[lenIndices.len1.from].interpolate(cil/lenWiseCount, topCenter[lenIndices.len1.to]);//.scaleInPlace(0.8);  
    //     const lbo = new Point3d(fstBtm.x, fstBtm.y, fstBtm.z);
    //     lbo.x = lbo.x - 1 * pRad;
    //     lbo.y = lbo.y - 1 * pRad;
        
    //     const lto = new Point3d(fstTop.x, fstTop.y, fstTop.z);;
    //     lto.x = lto.x - 1 * pRad;
    //     lto.y = lto.y - 1 * pRad;
    //       let pipe = Box.createDgnBoxWithAxes(fstBtm, trans.matrix, fstTop, pRad, pRad, pRad, pRad, true)!;
    //     let vec = box.getVectorY();
    //     vec.scaleToLength((-(e.height)/2)-0.5*pRad, vec);
    //     let trf=Transform.createTranslation(vec);
    //     pipe.tryTransformInPlace(trf);

    //     let x=0, y=0, z=0;
    //     pipe.getCorners().forEach(e=>{x+=e.x;y+=e.y;z+=e.z;});
    //     // console.log("x, y: ",x, y, x/4, y/4);
    //     const centroidPtInLocal = new Point3d(x/8, y/8, z/8);
    //         const azMatrix = Matrix3d.createRotationAroundVector(pipe.getVectorZ(), Angle.createDegrees(e.orientation))!;
    //     // const azMatrix = Matrix3d.createRotationAroundAxisIndex(AxisIndex.Z, newAng.yaw);
    //     // const trans = Transform.createFixedPointAndMatrix(centroidPtInLocal, azMatrix);
    //     // let newCentroidPtInGlobal = trans.origin;
    //     // newCentroidPtInGlobal = trans.multiplyPoint3d(newCentroidPtInGlobal);
    //     // Apply to the box
    //     // pipe?.tryTransformInPlace(trans);
  
    //     builder.addBox(pipe!);
    //     let polyface = builder.claimPolyface(false);
    //     this.addGeometry(polyface, ColorDef.fromTbgr(ColorDef.withTransparency(ColorDef.create(ColorByName.red).tbgr, 50)), "Pipe");


    //     // let pipe = Cone.createAxisPoints(fstBtm, fstTop, pRad, pRad, true)!;
    //     // let vec = box.getVectorY();
    //     // vec.scaleToLength((-(e.height/2)), vec);
    //     // let trf=Transform.createTranslation(vec);
    //     // pipe.tryTransformInPlace(trf);
    //     // builder.addCone(pipe!);
    //     // let polyface = builder.claimPolyface(false);
    //     // this.addGeometry(polyface, ColorDef.fromTbgr(ColorDef.withTransparency(ColorDef.create(ColorByName.red).tbgr, 50)), "Pipe");
    //     // let pipe2 = pipe.clone();
    //     // vec = box.getVectorY();
    //     // vec.scaleToLength((-(e.height/5)), vec);
    //     // trf=Transform.createTranslation(vec);
    //     // pipe.tryTransformInPlace(trf);
    //     // builder.addCone(pipe!);
    //     // polyface = builder.claimPolyface(false);
    //     // this.addGeometry(polyface, ColorDef.fromTbgr(ColorDef.withTransparency(ColorDef.create(ColorByName.red).tbgr, 50)), "Pipe");
    //   }
    // // }

    let fstBtm = botCenter[0].interpolate(0.2, botCenter[1]);//.scaleInPlace(0.8);
    let fstTop = topCenter[0].interpolate(0.2, topCenter[1]);//.scaleInPlace(0.8);
    let lstBtm = botCenter[1].interpolate(0.2, botCenter[3]);//.scaleInPlace(0.8);
    let lstTop = topCenter[1].interpolate(0.2, topCenter[3]);//.scaleInPlace(0.8);
    let lstBtm1 = botCenter[2].interpolate(0.2, botCenter[0]);//.scaleInPlace(0.8);
    let lstTop1 = topCenter[2].interpolate(0.2, topCenter[0]);//.scaleInPlace(0.8);
    let midBtm = botCenter[3].interpolate(0.2, botCenter[2]);//.scaleInPlace(0.8);
    let midTop = topCenter[3].interpolate(0.2, topCenter[2]);//.scaleInPlace(0.8);

    // let fstBtm = botCenter[0].interpolate(0.5, botCenter[1]);//.scaleInPlace(0.8);
    // let fstTop = topCenter[0].interpolate(0.5, topCenter[1]);//.scaleInPlace(0.8);
    // let midBtm = botCenter[0].interpolate(0.5, botCenter[2]);//.scaleInPlace(0.8);
    // let midTop = topCenter[0].interpolate(0.5, topCenter[2]);//.scaleInPlace(0.8);
    // let lstBtm = botCenter[1].interpolate(0.5, botCenter[3]);//.scaleInPlace(0.8);
    // let lstTop = topCenter[1].interpolate(0.5, topCenter[3]);//.scaleInPlace(0.8);
    // let lstBtm1 = botCenter[2].interpolate(0.5, botCenter[3]);//.scaleInPlace(0.8);
    // let lstTop1 = topCenter[2].interpolate(0.5, topCenter[3]);//.scaleInPlace(0.8);

    // console.log('01: ', botCenter[0].distance(botCenter[1]));
    // console.log('02: ', botCenter[0].distance(botCenter[2]));
    // console.log('13: ', botCenter[1].distance(botCenter[3]));
    // console.log('23: ', botCenter[2].distance(botCenter[3]));

    // let pipe = Cone.createAxisPoints(fstBtm, fstTop, pRad, pRad, true);
    // builder.addCone(pipe!);
    // polyface = builder.claimPolyface(false);
    // this.addGeometry(polyface, ColorDef.fromTbgr(ColorDef.withTransparency(ColorDef.create(ColorByName.red).tbgr, 50)), "Pipe");
    
    // pipe = Cone.createAxisPoints(midBtm, midTop, pRad, pRad, true);
    // builder.addCone(pipe!);
    // polyface = builder.claimPolyface(false);
    // this.addGeometry(polyface, ColorDef.fromTbgr(ColorDef.withTransparency(ColorDef.create(ColorByName.green).tbgr, 50)), "Pipe");
    
    // pipe = Cone.createAxisPoints(lstBtm, lstTop, pRad, pRad, true);
    // builder.addCone(pipe!);
    // polyface = builder.claimPolyface(false);
    // this.addGeometry(polyface, ColorDef.fromTbgr(ColorDef.withTransparency(ColorDef.create(ColorByName.blue).tbgr, 50)), "Pipe");

    // pipe = Cone.createAxisPoints(lstBtm1, lstTop1, pRad, pRad, true);
    // builder.addCone(pipe!);
    // polyface = builder.claimPolyface(false);
    // this.addGeometry(polyface, ColorDef.fromTbgr(ColorDef.withTransparency(ColorDef.create(ColorByName.pink).tbgr, 50)), "Pipe");
    

  }


  // public updatePipeSelectedForEdit = () => {
  //   // const md = IModelApp.viewManager.decorators.filter(e=>e.constructor.name.includes("FeedlineDecorator"))[0] as FeedlineDecorator;
  //   const md = this;
  //   let ji = md.pipes.findIndex((e) => e.uid.includes(md.selectedMount.uid));
    
  //   //Deleting previously editing pipe and adding selected pipe
  //   let i = md.pipes.findIndex((e) => e.uid.includes("_editMid"));
  //   if(i > -1  && !md.selectedMount.uid.includes("_edit")){
  //     const prevEditingPipe = md.pipes[i];
  //     const newPipeToBeEdited = md.pipes[ji];
  //     if(ji!==-1){
  //       md.nameIdMap.delete(md.nameIdMap.get(md.pipes[ji].transientId) as string);
  //       md.pipes.splice(ji, 1);
  //     }
  //     do{
  //       i = md.pipes.findIndex((e) => e.uid.includes("_edit"));
  //       if(i!==-1){
  //         md.nameIdMap.delete(md.nameIdMap.get(md.pipes[i].transientId) as string);
  //         md.pipes.splice(i, 1);
  //       }
  //     } while(i !== -1);
  //     MountDecorator.editing=true;
  //     if(!newPipeToBeEdited.name.includes("edit"))md.addPipe(newPipeToBeEdited.startPos, newPipeToBeEdited.endPos, newPipeToBeEdited.thickness, newPipeToBeEdited.fillColor, newPipeToBeEdited.name, newPipeToBeEdited.modelData);
  //     md.addPipe(prevEditingPipe.startPos, prevEditingPipe.endPos, prevEditingPipe.thickness, prevEditingPipe.fillColor, prevEditingPipe.name.split("_edit")[0].split("@")[0], prevEditingPipe.modelData);
      

  //   } else if(!md.selectedMount.uid.includes("_edit")){
  //     let ji = md.pipes.findIndex((e) => e.uid.includes(md.selectedMount.uid));
  //     MountDecorator.editing=true;
  //     if(ji!==-1){
  //       const newPipeToBeEdited = md.pipes[ji];
  //       md.nameIdMap.delete(md.nameIdMap.get(md.pipes[ji].transientId) as string);
  //       md.pipes.splice(ji, 1);
  //       md.addPipe(newPipeToBeEdited.startPos, newPipeToBeEdited.endPos, newPipeToBeEdited.thickness, newPipeToBeEdited.fillColor, newPipeToBeEdited.name, newPipeToBeEdited.modelData);
  //     }
  //   }
  //   //Redrawing the Mount geometries
  //   const allPipes = md.pipes;
  //   const allTori = md.tori;
  //   const allNameIds = md.nameIdMap;
  //   md.terminate();
  //   md.pipes = allPipes;
  //   md.tori = allTori;
  //   md.nameIdMap = allNameIds;
  //   IModelApp.viewManager.invalidateDecorationsAllViews();
  //   IModelApp.viewManager.selectedView?.invalidateCachedDecorations(md);
  //   resetObjectIds(md)
  // }
  

  
  public async loadShapes_bkp() {
    // const selectedEquipment = SampleToolWidget.sampleToolWidget.state.operatorFilterData.selectedEquipment.length ? SampleToolWidget.sampleToolWidget.state.operatorFilterData.selectedEquipment: ["All"];;
    const selectedEquipment = ["All"];;
    this.defectBoxes = [];
    this.boxes = [];
    this.shapes = [];
    this.drawnBoxes = [];
    this.antennaBoxes = [];
    this.rruBoxes = [];
    this.drawnCylinders = [];
    this.loadedShapes = false;
    this.nameIdMap = new Map<string, Id64String>();
    IModelApp.viewManager.selectedView?.invalidateDecorations();
    IModelApp.viewManager.selectedView?.invalidateCachedDecorations(this);

    // const equipNamePositionMap: Map<string, any> = new Map(equipDataMaps.equipNamePositionMap);
    if (EquipmentsTable.equipNamePositionMap.size === 0) {
      IModelApp.viewManager.selectedView?.invalidateDecorations();
    }
    let data = await FeederLineClient.getFeedLineData();//Temporary example data.
    const iModel = UiFramework.getIModelConnection()!;
    let tsd = store.getState().detectedData.towerStructureData as towerStructureData;
    // const maps: any = new Map(data.map((e, _i)=>{
      // const cart = iModel!.spatialToCartographicFromEcef(e.startCenter);
      // const m = DecoratorHelper.convertWGS2UTM(cart);
      // const ypr = YawPitchRollAngles.createDegrees(-e.orientation, 0, 0);
      // const matrix = ypr.toMatrix3d();
      // // const matrix = new Matrix3d();
      // const o = iModel.spatialToCartographicFromEcef(iModel.projectExtents.high!);
      // const ecefA = DecoratorHelper.utm2localCoords(o, e.startCenter.x, e.startCenter.y, e.startCenter.z, iModel, tsd.epsg_code);
      // const ecefB = DecoratorHelper.utm2localCoords(o, e.endCenter.x, e.endCenter.y, e.endCenter.z, iModel, tsd.epsg_code);
      // const dot = ecefA.dotVectorsToTargets(new Point3d(ecefB.x, ecefB.y, 0), ecefB);
      // const lengthA = ecefA.distance(ecefB);
      // const lengthB = ecefA.distance(new Point3d(ecefB.x, ecefB.y, 0));
      // const theta = Math.acos(dot / (lengthA * lengthB));
      // const deg = theta * 180 / Math.PI; // 1Rad × 180/π
      // const points = [e.startCenter, e.endCenter] // [ [x,y,z] , [x,y,z] , [x,y,z] ]
      // const utms = points.map((e: any) => e[2]);
      // const altitude = (utms.reduce((sum: number, e: number) => sum + e, 0) / utms.length);
      // const elevation = altitude - tsd.base_altitude;
      // const theHeight = e.endCenter.z-e.startCenter.z;
      // ecefA.

      // const radius = .2;
      // let sphere = Sphere.createCenterRadius(Point3d.create(ecefA.x, ecefA.y, ecefA.z), radius);
      // builder.addSphere(sphere);
      // let polyface = builder.claimPolyface(false);
      // decoratorState.setColor(color);
      // this.addGeometry(polyface, ColorDef.fromTbgr(ColorDef.withTransparency(ColorDef.create(ColorByName.cyan).tbgr, 50)), "sphere");
    
      // sphere = Sphere.createCenterRadius(Point3d.create(ecefB.x, ecefB.y, ecefB.z), radius);
      // builder.addSphere(sphere);
      // polyface = builder.claimPolyface(false);
      // decoratorState.setColor(color);
      // this.addGeometry(polyface, ColorDef.fromTbgr(ColorDef.withTransparency(ColorDef.create(ColorByName.cyan).tbgr, 50)), "sphere");

      // const azMatrix = Matrix3d.createRotationAroundAxisIndex(AxisIndex.Z, Angle.createDegrees(-equipJson.Azimuth));
      // lineVector.interpolate(0.5, lineVector.negate())
      // let mp = p1.interpolate(0.5, p2)
      // lineVector.scaleToLength(((e.height*0.5)), lineVector);
      // const trf=Transform.createTranslation(lineVector);

      // sphere = Sphere.createCenterRadius(Point3d.create(mp.x, mp.y, mp.z), radius);
      // builder.addSphere(sphere);
      // polyface = builder.claimPolyface(false);
      // decoratorState.setColor(color);
      // this.addGeometry(polyface, ColorDef.fromTbgr(ColorDef.withTransparency(ColorDef.create(ColorByName.cyan).tbgr, 50)), "sphere");

      // let newAng = lineVector.angleTo(new Vector3d(p2.x, p2.y, p2.z));
      // lineVector.angleToXY()

      // let newAng = YawPitchRollAngles.createFromMatrix3d(newMatrix!)!;

      // const midPoint = new Point3d((e.startCenter.x+e.endCenter.x)/2, (e.startCenter.y+e.endCenter.y)/2, (e.startCenter.z+e.endCenter.z)/2)
      // const startPt = new Point3d(ecefA.x, ecefA.y, ecefA.z);
      // let aVec = new Vector3d(startPt.x, startPt.y, startPt.z);
      // const endPt = new Point3d(ecefB.x, ecefB.y, ecefB.z);
    
      // const azMatrix = Matrix3d.createRotationAroundAxisIndex(AxisIndex.Z, Angle.createDegrees(-e.orientation));
      // const azMatrix = Matrix3d.createRotationAroundVector(lineVector, Angle.createDegreesAdjustSigned180(-e.orientation));
      // trans = Transform.createFixedPointAndMatrix(centroidPtInLocal, azMatrix);
  
      // const localBaseCenter = new Point3d(mp.x, mp.y, -0.5 * theHeight);
      const maps: any = new Map(data.map((e, _i)=>{
      const theWidth = e.width;
      const theThickness = e.height;
      const midPoint = new Point3d((e.startCenter.x+e.endCenter.x)/2, (e.startCenter.y+e.endCenter.y)/2, (e.startCenter.z+e.endCenter.z)/2)
      const ypr = YawPitchRollAngles.createDegrees(-e.orientation, 0, 0);
      const matrix = ypr.toMatrix3d();
      const o = iModel.spatialToCartographicFromEcef(iModel.projectExtents.high!);
      const startPt = DecoratorHelper.utm2localCoords(o, e.startCenter.x, e.startCenter.y, e.startCenter.z, iModel, tsd.epsg_code);
      const endPt = DecoratorHelper.utm2localCoords(o, e.endCenter.x, e.endCenter.y, e.endCenter.z, iModel, tsd.epsg_code);
      const theHeight = startPt.distance(endPt);
      let lineVector = startPt.vectorTo(endPt);
      let newMatrix = Matrix3d.createRotationAroundVector(lineVector, matrix.getAxisAndAngleOfRotation().angle)!;
      newMatrix = Transform.identity.matrix;
      
      const localBaseOrigin = new Point3d(startPt.x, startPt.y, startPt.z);
      localBaseOrigin.x = localBaseOrigin.x - 0.5 * theWidth;
      localBaseOrigin.y = localBaseOrigin.y - 0.5 * theThickness;
      
      const localTopOrigin = new Point3d(endPt.x, endPt.y, endPt.z);;
      localTopOrigin.x = localTopOrigin.x - 0.5 * theWidth;
      localTopOrigin.y = localTopOrigin.y - 0.5 * theThickness;
      const phi = e.orientation * ((Math.PI)/180);

      const options = StrokeOptions.createForCurves();
      options.needParams = false;
      options.needNormals = true;
      const box = Box.createDgnBoxWithAxes(localBaseOrigin, Transform.identity.matrix, localTopOrigin, theWidth, theThickness, theWidth, theThickness, true)!;
        
      let x=0, y=0, z=0;
      box.getCorners().forEach(e=>{x+=e.x;y+=e.y;z+=e.z;});
      // console.log("x, y: ",x, y, x/4, y/4);
      const centroidPtInLocal = new Point3d(x/8, y/8, z/8);

      const azMatrix = Matrix3d.createRotationAroundVector(box.getVectorZ(), Angle.createDegrees(e.orientation))!;
      // const azMatrix = Matrix3d.createRotationAroundAxisIndex(AxisIndex.Z, newAng.yaw);
      const trans = Transform.createFixedPointAndMatrix(centroidPtInLocal, azMatrix);
      let newCentroidPtInGlobal = trans.origin;
      newCentroidPtInGlobal = trans.multiplyPoint3d(newCentroidPtInGlobal);
      // Apply to the box
      box?.tryTransformInPlace(trans);
      // let inrBox = box.

      // const box2 = Box.createDgnBoxWithAxes(localBaseOrigin, Transform.identity.matrix, localTopOrigin, theWidth, theThickness, theWidth, theThickness, true)!;
      const boxBuilder = PolyfaceBuilder.create(options);
      // boxBuilder.addBox(box2!);
      let boxPolyface = boxBuilder.claimPolyface(false);
      // this.addGeometry(boxPolyface, ColorDef.fromTbgr(ColorDef.withTransparency(ColorDef.create(ColorByName.blanchedAlmond).tbgr, 180)), "Box");
      

      boxBuilder.addBox(box!);
      boxPolyface = boxBuilder.claimPolyface(false);
      this.addGeometry(boxPolyface, ColorDef.fromTbgr(ColorDef.withTransparency(ColorDef.create(ColorByName.aqua).tbgr, 120)), "Box", false);
      
      let feedlinePolyface;
      const feedlineBuilder = PolyfaceBuilder.create(options);

      let bcForPipe = box.getCorners();
      let topCenter = bcForPipe.slice(0,4);
      let botCenter = bcForPipe.slice(4);


      let len = botCenter[0].distance(botCenter[1]);
      let wid = botCenter[0].distance(botCenter[2]);
      let len1 = botCenter[2].distance(botCenter[3]);
      let wid1 = botCenter[1].distance(botCenter[3]);
      let pRad = 0.025;
      let pDist = pRad*2.1;

      let widIndices, lenIndices;
      if(len > wid){
        lenIndices={len1:{from: 0, to: 1}, len2: {from: 3, to: 2}};
        widIndices={wid1:{from: 1, to: 3}, wid2: {from: 2, to: 0}};
      } else {
        lenIndices={len1:{from: 1, to: 3}, len2: {from: 2, to: 0}};
        widIndices={wid1:{from: 0, to: 1}, wid2: {from: 3, to: 2}};
      }

      const widBotDist1 = botCenter[widIndices.wid1.from].distance(botCenter[widIndices.wid2.to])
      const lenBotDist1 = botCenter[lenIndices.len1.from].distance(botCenter[lenIndices.len2.to])
      const widTopDist1 = topCenter[widIndices.wid1.from].distance(topCenter[widIndices.wid2.to])
      const widTopDist2 = topCenter[widIndices.wid1.from].distance(topCenter[widIndices.wid2.to])

      const widBisBot1 = botCenter[widIndices.wid1.from].interpolate(0.5, botCenter[widIndices.wid1.to]);
      const widBisBot2 = botCenter[widIndices.wid2.from].interpolate(0.5, botCenter[widIndices.wid2.to]);
      const widBisTop1 = topCenter[widIndices.wid1.from].interpolate(0.5, topCenter[widIndices.wid1.to]);
      const widBisTop2 = topCenter[widIndices.wid2.from].interpolate(0.5, topCenter[widIndices.wid2.to]);

      // const widBisBot1 = widBotVec1.scaleToLength(botCenter[widIndices.wid1.from].distance(botCenter[widIndices.wid1.to])/2)?.cloneAsPoint3d() as Point3d;
      // const widBisBot2 = widBotVec2.scaleToLength(botCenter[widIndices.wid2.from].distance(botCenter[widIndices.wid2.to])/2)?.cloneAsPoint3d() as Point3d;
      // const widBisTop1 = widTopVec1.scaleToLength(topCenter[widIndices.wid1.from].distance(topCenter[widIndices.wid1.to])/2)?.cloneAsPoint3d() as Point3d;
      // const widBisTop2 = widTopVec2.scaleToLength(topCenter[widIndices.wid2.from].distance(topCenter[widIndices.wid2.to])/2)?.cloneAsPoint3d() as Point3d;

      
      x=0;y=0;z=0;
      topCenter.forEach(e=>{x+=e.x*0.8;y+=e.y*0.8;z+=e.z*0.8;});
      let pSt = new Point3d(x/4, y/4, z/4);
      x=0;y=0;z=0;
      botCenter.forEach(e=>{x+=e.x*0.8;y+=e.y*0.8;z+=e.z*0.8;});
      let pEn = new Point3d(x/4, y/4, z/4);
      
      let lPad = pRad/len;
      let wPad = pRad/wid;
       
      let lenWiseCount = Math.floor((len)/pDist); 
      let widWiseCount = Math.floor((wid)/pDist);
      
      // let pipe = Cone.createAxisPoints(widBisBot1!, widBisTop1!, pRad, pRad, true);
      // builder.addCone(pipe!);
      // polyface = builder.claimPolyface(false);
      // this.addGeometry(polyface, ColorDef.fromTbgr(ColorDef.withTransparency(ColorDef.create(ColorByName.red).tbgr, 50)), "Pipe");

      // pipe = Cone.createAxisPoints(widBisBot2!, widBisTop2!, pRad, pRad, true);
      // builder.addCone(pipe!);
      // polyface = builder.claimPolyface(false);
      // this.addGeometry(polyface, ColorDef.fromTbgr(ColorDef.withTransparency(ColorDef.create(ColorByName.red).tbgr, 50)), "Pipe");

      

      let rowMax = lenWiseCount > widWiseCount ? widWiseCount : lenWiseCount, colMax=lenWiseCount > widWiseCount ? lenWiseCount : widWiseCount;
      rowMax = rowMax <= 1 ? 2 : rowMax; 
      colMax = colMax <= 1 ? 2 : colMax; 
      for(let rowCount=1; rowCount < rowMax; rowCount++){
        const WBB1 = botCenter[widIndices.wid1.from].interpolate(rowCount/rowMax, botCenter[widIndices.wid1.to]);
        const WBB2 = botCenter[widIndices.wid2.to].interpolate(rowCount/rowMax, botCenter[widIndices.wid2.from]);
        const WBT1 = topCenter[widIndices.wid1.from].interpolate(rowCount/rowMax, topCenter[widIndices.wid1.to]);
        const WBT2 = topCenter[widIndices.wid2.to].interpolate(rowCount/rowMax, topCenter[widIndices.wid2.from]);

        // const WBB1 = botCenter[widIndices.wid1.from].interpolate(rowCount/widWiseCount, botCenter[widIndices.wid1.to]);
        // const WBB2 = botCenter[widIndices.wid2.from].interpolate(rowCount/widWiseCount, botCenter[widIndices.wid2.to]);
        // const WBT1 = topCenter[widIndices.wid1.from].interpolate(rowCount/widWiseCount, topCenter[widIndices.wid1.to]);
        // const WBT2 = topCenter[widIndices.wid2.from].interpolate(rowCount/widWiseCount, topCenter[widIndices.wid2.to]);
        let theTop, theBottom, theTopCenter1, theBottomCenter1, theTopCenter2, theBottomCenter2, pipe;
        theBottomCenter1 = WBB1, theTopCenter1 = WBT1;
        theBottomCenter2 = WBB2, theTopCenter2 = WBT2;


        for(let colCount=1; colCount < colMax; colCount++){
          theBottom = theBottomCenter1.interpolate(colCount/colMax, theBottomCenter2);
          // theBottom.x = theBottom.x + 0.5 * pRad;
          // theBottom.y = theBottom.y + 0.5 * pRad;
    
          theTop = theTopCenter1.interpolate(colCount/colMax, theTopCenter2);
          // theTop.x = theTop.x + 0.5 * pRad;
          // theTop.y = theTop.y + 0.5 * pRad;
              // pipe = Cone.createAxisPoints(theTop!, theBottom!, pRad, pRad, true);
          // builder.addCone(pipe!);
          // polyface = builder.claimPolyface(false);
          // this.addGeometry(polyface, ColorDef.fromTbgr(ColorDef.withTransparency(ColorDef.create(ColorByName.red).tbgr, 50)), "Pipe");

          
          let pipe = Box.createDgnBoxWithAxes(theTop, trans.matrix, theBottom, pRad, pRad, pRad, pRad, false)!;
          // let vec = box.getVectorY();
          // vec.scaleToLength(((e.height)/2)-0.5*pRad, vec);
          // let trf=Transform.createTranslation(vec);
          // pipe.tryTransformInPlace(trf);

          let x=0, y=0, z=0;
          pipe.getCorners().forEach(e=>{x+=e.x;y+=e.y;z+=e.z;});
          // console.log("x, y: ",x, y, x/4, y/4);
          const centroidPtInLocal = new Point3d(x/8, y/8, z/8);
              const azMatrix = Matrix3d.createRotationAroundVector(pipe.getVectorZ(), Angle.createDegrees(e.orientation))!;
          // const azMatrix = Matrix3d.createRotationAroundAxisIndex(AxisIndex.Z, newAng.yaw);
          // const trans = Transform.createFixedPointAndMatrix(centroidPtInLocal, azMatrix);
          // let newCentroidPtInGlobal = trans.origin;
          // newCentroidPtInGlobal = trans.multiplyPoint3d(newCentroidPtInGlobal);
          // Apply to the box
          // pipe?.tryTransformInPlace(trans);
    
          feedlineBuilder.addBox(pipe!);
          feedlinePolyface = feedlineBuilder.claimPolyface(false);
          this.addGeometry(feedlinePolyface, ColorDef.fromTbgr(ColorDef.withTransparency(ColorDef.create(ColorByName.black).tbgr, 0)), "Pipe", false);


        }
        
        // // let pt = widBisBot1.interpolate(0.5, widBisTop1)!
        // pipe = Cone.createAxisPoints(theTop!, theBottom!, pRad, pRad, true);
        // builder.addCone(pipe!);
        // polyface = builder.claimPolyface(false);
        // this.addGeometry(polyface, ColorDef.fromTbgr(ColorDef.withTransparency(ColorDef.create(ColorByName.red).tbgr, 50)), "Pipe");
  
  
      }

      // // for(let ciw=1; ciw < widWiseCount; ciw++){
      //   for(let cil=1; cil < lenWiseCount; cil++){
      //     let fstBtm = botCenter[lenIndices.len1.from].interpolate(cil/lenWiseCount, botCenter[lenIndices.len1.to]);//.scaleInPlace(0.8);
      //     let fstTop = topCenter[lenIndices.len1.from].interpolate(cil/lenWiseCount, topCenter[lenIndices.len1.to]);//.scaleInPlace(0.8);  
      //     const lbo = new Point3d(fstBtm.x, fstBtm.y, fstBtm.z);
      //     lbo.x = lbo.x - 1 * pRad;
      //     lbo.y = lbo.y - 1 * pRad;
          
      //     const lto = new Point3d(fstTop.x, fstTop.y, fstTop.z);;
      //     lto.x = lto.x - 1 * pRad;
      //     lto.y = lto.y - 1 * pRad;
      //       let pipe = Box.createDgnBoxWithAxes(fstBtm, trans.matrix, fstTop, pRad, pRad, pRad, pRad, true)!;
      //     let vec = box.getVectorY();
      //     vec.scaleToLength((-(e.height)/2)-0.5*pRad, vec);
      //     let trf=Transform.createTranslation(vec);
      //     pipe.tryTransformInPlace(trf);

      //     let x=0, y=0, z=0;
      //     pipe.getCorners().forEach(e=>{x+=e.x;y+=e.y;z+=e.z;});
      //     // console.log("x, y: ",x, y, x/4, y/4);
      //     const centroidPtInLocal = new Point3d(x/8, y/8, z/8);
      //         const azMatrix = Matrix3d.createRotationAroundVector(pipe.getVectorZ(), Angle.createDegrees(e.orientation))!;
      //     // const azMatrix = Matrix3d.createRotationAroundAxisIndex(AxisIndex.Z, newAng.yaw);
      //     // const trans = Transform.createFixedPointAndMatrix(centroidPtInLocal, azMatrix);
      //     // let newCentroidPtInGlobal = trans.origin;
      //     // newCentroidPtInGlobal = trans.multiplyPoint3d(newCentroidPtInGlobal);
      //     // Apply to the box
      //     // pipe?.tryTransformInPlace(trans);
    
      //     builder.addBox(pipe!);
      //     let polyface = builder.claimPolyface(false);
      //     this.addGeometry(polyface, ColorDef.fromTbgr(ColorDef.withTransparency(ColorDef.create(ColorByName.red).tbgr, 50)), "Pipe");


      //     // let pipe = Cone.createAxisPoints(fstBtm, fstTop, pRad, pRad, true)!;
      //     // let vec = box.getVectorY();
      //     // vec.scaleToLength((-(e.height/2)), vec);
      //     // let trf=Transform.createTranslation(vec);
      //     // pipe.tryTransformInPlace(trf);
      //     // builder.addCone(pipe!);
      //     // let polyface = builder.claimPolyface(false);
      //     // this.addGeometry(polyface, ColorDef.fromTbgr(ColorDef.withTransparency(ColorDef.create(ColorByName.red).tbgr, 50)), "Pipe");
      //     // let pipe2 = pipe.clone();
      //     // vec = box.getVectorY();
      //     // vec.scaleToLength((-(e.height/5)), vec);
      //     // trf=Transform.createTranslation(vec);
      //     // pipe.tryTransformInPlace(trf);
      //     // builder.addCone(pipe!);
      //     // polyface = builder.claimPolyface(false);
      //     // this.addGeometry(polyface, ColorDef.fromTbgr(ColorDef.withTransparency(ColorDef.create(ColorByName.red).tbgr, 50)), "Pipe");
      //   }
      // // }

      let fstBtm = botCenter[0].interpolate(0.2, botCenter[1]);//.scaleInPlace(0.8);
      let fstTop = topCenter[0].interpolate(0.2, topCenter[1]);//.scaleInPlace(0.8);
      let lstBtm = botCenter[1].interpolate(0.2, botCenter[3]);//.scaleInPlace(0.8);
      let lstTop = topCenter[1].interpolate(0.2, topCenter[3]);//.scaleInPlace(0.8);
      let lstBtm1 = botCenter[2].interpolate(0.2, botCenter[0]);//.scaleInPlace(0.8);
      let lstTop1 = topCenter[2].interpolate(0.2, topCenter[0]);//.scaleInPlace(0.8);
      let midBtm = botCenter[3].interpolate(0.2, botCenter[2]);//.scaleInPlace(0.8);
      let midTop = topCenter[3].interpolate(0.2, topCenter[2]);//.scaleInPlace(0.8);

      // let fstBtm = botCenter[0].interpolate(0.5, botCenter[1]);//.scaleInPlace(0.8);
      // let fstTop = topCenter[0].interpolate(0.5, topCenter[1]);//.scaleInPlace(0.8);
      // let midBtm = botCenter[0].interpolate(0.5, botCenter[2]);//.scaleInPlace(0.8);
      // let midTop = topCenter[0].interpolate(0.5, topCenter[2]);//.scaleInPlace(0.8);
      // let lstBtm = botCenter[1].interpolate(0.5, botCenter[3]);//.scaleInPlace(0.8);
      // let lstTop = topCenter[1].interpolate(0.5, topCenter[3]);//.scaleInPlace(0.8);
      // let lstBtm1 = botCenter[2].interpolate(0.5, botCenter[3]);//.scaleInPlace(0.8);
      // let lstTop1 = topCenter[2].interpolate(0.5, topCenter[3]);//.scaleInPlace(0.8);

      // console.log('01: ', botCenter[0].distance(botCenter[1]));
      // console.log('02: ', botCenter[0].distance(botCenter[2]));
      // console.log('13: ', botCenter[1].distance(botCenter[3]));
      // console.log('23: ', botCenter[2].distance(botCenter[3]));


      // let pipe = Cone.createAxisPoints(fstBtm, fstTop, pRad, pRad, true);
      // builder.addCone(pipe!);
      // polyface = builder.claimPolyface(false);
      // this.addGeometry(polyface, ColorDef.fromTbgr(ColorDef.withTransparency(ColorDef.create(ColorByName.red).tbgr, 50)), "Pipe");
      
      // pipe = Cone.createAxisPoints(midBtm, midTop, pRad, pRad, true);
      // builder.addCone(pipe!);
      // polyface = builder.claimPolyface(false);
      // this.addGeometry(polyface, ColorDef.fromTbgr(ColorDef.withTransparency(ColorDef.create(ColorByName.green).tbgr, 50)), "Pipe");
      
      // pipe = Cone.createAxisPoints(lstBtm, lstTop, pRad, pRad, true);
      // builder.addCone(pipe!);
      // polyface = builder.claimPolyface(false);
      // this.addGeometry(polyface, ColorDef.fromTbgr(ColorDef.withTransparency(ColorDef.create(ColorByName.blue).tbgr, 50)), "Pipe");

      // pipe = Cone.createAxisPoints(lstBtm1, lstTop1, pRad, pRad, true);
      // builder.addCone(pipe!);
      // polyface = builder.claimPolyface(false);
      // this.addGeometry(polyface, ColorDef.fromTbgr(ColorDef.withTransparency(ColorDef.create(ColorByName.pink).tbgr, 50)), "Pipe");
      

        // return ([`Antenna_${_i}`,
        // {
        //     "Azimuth": -(e.orientation),
        //     "Equipment_Name": `Antenna_${_i}`,
        //     "Height": theHeight,
        //     "Elevation_Height": 36.045746,
        //     "Manufacturer": 'NOKIA',
        //     "Model": "AEQU",
        //     "Roll": 0,
        //     "Thickness": e.height,
        //     "Tilt": 0,
        //     "Width": e.width,
        //     "DisplayName": `Antenna_${_i}`,
        //     "x_position": midPoint.x,
        //     "y_position": midPoint.y,
        //     "z_position": midPoint.z,
        //     "feeder": e,
        //     "theData": {localBase: localBaseOrigin, localTop: localTopOrigin},
        //   }
        //   ])
          return [];
        }));

      // const phi = e.orientation * ((Math.PI)/180);
      // const rotMatrix = [   Math.cos(phi) , -Math.sin(phi), 0 ,
      //                        Math.sin(phi) , Math.cos(phi) , 0 ,
      //                        0               , 0               , 1 ];
      // const rot = new Matrix3d(new Float64Array(rotMatrix));      


      // let vec = box.getVectorY();
      // vec.scaleToLength((-(e.height)), vec);
      // const trf=Transform.createTranslation(vec);
      // box.tryTransformInPlace(trf);
        // box?.tryTransformInPlace(Transform.createOriginAndMatrix(undefined, matrix))
      // box?.tryTransformInPlace()

      // let shiftPos = new Vector3d();
      // let oldCentroidPtInGlobal = new Point3d(0, 0, 0);
      // let centroidPtInLocal = new Point3d(0, 0, 0);
      // let newCentroidPtInGlobal = new Point3d(0, 0, 0);
      // const azMatrix = Matrix3d.createRotationAroundAxisIndex(AxisIndex.Z, Angle.createDegrees(-e.orientation));
      // const trans = Transform.createFixedPointAndMatrix(centroidPtInLocal, azMatrix);
      // newCentroidPtInGlobal = trans.multiplyPoint3d(newCentroidPtInGlobal);
      // // Apply to the box
      // box?.tryTransformInPlace(trans);
      // shiftPos = trans.multiplyVector(shiftPos);
  
      // // =====================================
      // // Step 4 - apply centroid transform (Global Coordinate)
      // // =====================================
      // shiftPos = shiftPos.plus(oldCentroidPtInGlobal);
      // const translation = Transform.createTranslation(shiftPos);
      // // Apply to the box
      // box?.tryTransformInPlace(translation);
  


      // this.addGeometry(polyface, ColorDef.fromTbgr(ColorDef.withTransparency(ColorDef.create(ColorByName.aqua).tbgr, 50)), "Box");

    
      // console.log('This is the o, ecefA, ecefB, dot, lengthA, lengthB, theta, deg, altitude, elevation, newMatrix: ', o, ecefA, ecefB, dot, lengthA, lengthB, theta, deg, altitude, elevation, newMatrix);
      // return ([`Antenna_${_i}`,
      // {
      //   "Azimuth": -(newAng?.yaw),
      //   "Equipment_Name": `Antenna_${_i}`,
      //   "Height": theHeight,
      //   "Elevation_Height": 36.045746,
      //   "Manufacturer": 'NOKIA',
      //   "Model": "AEQU",
      //   "Roll": 0,
      //   "Thickness": e.height,
      //   "Tilt": 0,
      //   "Width": e.width,
      //   "DisplayName": `Antenna_${_i}`,
      //   "x_position": midPoint.x,
      //   "y_position": midPoint.y,
      //   "z_position": midPoint.z,
      //   "feeder": e,
      //   "theData": {localBase: localBaseOrigin, localTop: localTopOrigin}
      //   // "theMatrix": newMatrix
      // }])
    //   return [];
    // }));
    // const map = new Map(new Map([
    //   [
    //       "Antenna_1",
    //       {
    //           "Azimuth": 147,
    //           "Equipment_Name": "Antenna_1",
    //           "Height": 0.75,
    //           "Elevation_Height": 45.11,
    //           "Manufacturer": "NOKIA",
    //           "Model": "AEQU",
    //           "Roll": 0,
    //           "Thickness": 0.24,
    //           "Tilt": -0.4,
    //           "Width": 0.45,
    //           "DisplayName": "Antenna_1",
    //           "x_position": 360675.09587003745,
    //           "y_position": 4428904.298023943,
    //           "z_position": 125.83609206820708
    //       }
    //   ],
    // ]));


    const masps = new Map(new Map([
      [
          "Antenna_25",
          {
            "Azimuth": -(51.75130797685135),
            "Equipment_Name": "Antenna_25",
            "Height": 127.9891505451873-82.38915054518729,
            "Elevation_Height": 36.045746,
            "Manufacturer": 'NOKIA',
            "Model": "AEQU",
            "Roll": 0,
            "Thickness": 0.10,
            "Tilt": 0,
            "Width": 0.5,
            "DisplayName": "Antenna_25",
            "x_position": 360674.38223554107,
            "y_position": 4428904.379713146,
            "z_position": 106.7695027967974,
              // "x_position": 360673.2816707022,
            // "y_position": 4428905.61874918,
            // "z_position": 116.11432401138008
        }
        // {
        //   "Azimuth": 75.5,
        //   "Equipment_Name": "Antenna_25",
        //   "Height": 2,
        //   "Elevation_Height": 36.045746,
        //   "Manufacturer": "NOKIA",
        //   "Model": "AEQU",
        //   "Roll": 0,
        //   "Thickness": 0.25,
        //   "Tilt": 0,
        //   "Width": 0.5,
        //   "DisplayName": "Antenna_25",
        //   "x_position": 360673.1348636053,
        //   "y_position": 4428905.641524891,
        //   "z_position": 116.11432401138008
        // }        
      ],
    ]));

    // const mps = EquipmentsTable.equipNamePositionMap;
    // const tem: FeedlineData[] = [...tempEquipMap];
    // let tempAllIdMaps = new Map([...all3DObjectsMap.idValues]);

    for (const [currName, json] of maps) {
      if (!json) {
        return;
      }
      let entry: any;
      if(selectedEquipment[0] == "All" || selectedEquipment.indexOf(currName) != -1){
        // if (currName.match(/Micro_Wave/i)) {
        //   entry = this.drawCylinder(json);
        // } else {
          entry = this.drawBox(json);
        // }
        const retVal = this.saveIntoLocalObj(json, entry);
        // tem.push(retVal?.jsonObj!);
        // tempAllIdMaps = new Map(retVal?.objectIdMap);
      }
    }
    // store.dispatch(setEquipmentDataMaps({...equipDataMaps, tempEquipMap: tem}));
    // store.dispatch(addToBuilt3DObjectIdsMap(new Map(tempAllIdMaps)));
    this.loadedShapes = true;
    IModelApp.viewManager.selectedView?.invalidateCachedDecorations(this);
  }
  /**
   * Save Entry into local object
   * @param json JSON object of Equipment Position Information
   * @param entry Box/Cone object
   * @returns void
   */
  private saveIntoLocalObj(json: FeedlineData, _entry: any, mode: CreateGeometryMode = CreateGeometryMode.DrawBox) {
    // const index = this.currJson.findIndex((i) => i.Equipment_Name === json.Equipment_Name);
    // // const index = tempEquipMap.findIndex((i) => i.Equipment_Name === json.Equipment_Name);
    // if (index === -1) {
    //   // if (!json.Equipment_Name.match(/Micro_Wave/i)) {
    //     this.boxes.push(entry);
    //   // }
    //   this.currJson.push(json);
    //   // tem.push(json);
    // }
    const vp = IModelApp.viewManager.selectedView;``
    if (vp === undefined) return;
    const nextId = vp.iModel.transientIds.getNext();
    this.objectIdMap.set(nextId, `feederGeom#FeedLine_#${json.Id}`);
    this.nameIdMap.set(`${json.Id}`, nextId);
    if(mode == CreateGeometryMode.DrawBox)this.createGeometry(json)    
    return {jsonObj: json, objectIdMap: this.objectIdMap}
  }

  /**
   * Draw Boxes with Position Information
   * @param equipJson EquipPosition information
   */
  private drawBox(equipJson: FeedlineData): BoxEntry {
    const box = this.constructBoxGeometry(equipJson);
    const entry: BoxEntry = {
      box,
      name: equipJson.Id?.toString(),
      modelData: equipJson,
    };
    // Match by "type" instead of name
    // if (equipJson.Equipment_Name.match(/Antenna/i)) {
      this.antennaBoxes.push(entry);
      this.antennaBoxes.push(entry);
    // } else if (equipJson.Equipment_Name.match(/RRU/i)) {
    //   this.rruBoxes.push(entry);
    // }
    //Drawing equipment's face
    // if(this.showFrontFace)this.drawAntRruFace(entry, equipJson);
    return entry;
  }

  public faceToEquipName(faceName: string){
    let name: string = "", type: EquipmentType | null = null;
    if(faceName.includes("ANT")){
      name=`Antenna_${faceName.split('_')[1]}`;
      type = EquipmentType.Antenna;
    } else if(faceName.includes('MW')){
      name=`Micro_Wave_${faceName.split('_')[1]}`;
      type = EquipmentType.Microwave;
    } else if(faceName.includes('RRU')){
      name=`RRU_${faceName.split('_')[1]}`;
      type = EquipmentType.RRU;
    }
    return {name, type}
  }

  
  public faceToEquipSelect(faceName: string){
    const antId=this!.nameIdMap.get(faceName!);
    // App.iModelConnection!.selectionSet.emptyAll();
    // App.iModelConnection!.selectionSet.add(antId as string);

    const {name, type} = this.faceToEquipName(faceName);
    // SampleToolWidget.setEquipmentType(type!);

    // SampleToolWidget.selectedBoxName=name;
    FeedlineDecorator.sourceId=antId;
    // SampleToolWidget.selectedBoxInformation = SampleToolWidget.equipNameInfoMap.get(name);
    IndividualShapeDecorator.selectedEquipName = name!;
    
    return {name: name, id: antId as string};
}
  
  // public faceToEquipSelect(name: string){
  //   // const equip3DMaps = store.getState().detectedData
  //   const iModelC = UiFramework.getIModelConnection();
  //   const sourceId = store.getState().detectedData["equipmentDataObjectIdMaps"].objectIdMap.get(name!);
  //   if(name.includes("ANT")){
  //     name=`Antenna_${name.split('_')[1]}`;
  //     // SampleToolWidget.setEquipmentType(EquipmentType.Antenna);
  //   } else if(name.includes('MW')){
  //     name=`Micro_Wave_${name.split('_')[1]}`;
  //     // SampleToolWidget.setEquipmentType(EquipmentType.Microwave);
  //   } else if(name.includes('RRU')){
  //     name=`RRU_${name.split('_')[1]}`;
  //     // SampleToolWidget.setEquipmentType(EquipmentType.RRU);
  //   }
  //   const antId=store.getState().detectedData["equipmentDataObjectIdMaps"].objectIdMap.get(name!);
  //   // SampleToolWidget.selectedBoxName=name;
  //   FeedlineDecorator.sourceId=antId;
  //   // SampleToolWidget.selectedBoxInformation = SampleToolWidget.equipNameInfoMap.get(name);
  //   IndividualShapeDecorator.selectedEquipName = name!;
    
  //   return {name: name, id: antId as string};
  // }

  /**
   * find Equipment Position
   * @param name Equipment Name
   * @returns Returns Equipment Position
   */
  // public getBoxJsonByName(name: string): FeedlineData | undefined {
  //   return this.currJson.find((json) => json.Equipment_Name === name);
  // }
  /**
   * Create new Box
   * @param pt Centroid Point of Equipment
   * @returns new Equipment
   */
  // public async addNewBox(pt: Point3d, equipmentType: string): Promise<FeedlineData | undefined> {
  //   // let equipmentType: string = EquipmentType.Antenna;
  //   SyncUiEventDispatcher.onSyncUiEvent.addListener((args) => {
  //     if (args.eventIds.has("antenna")) {
  //       equipmentType = EquipmentType.Antenna;
  //     }
  //   });


  //   let height: number = 0;
  //   let width: number = 0;
  //   let thickness: number = 0;
  //   const iModel = UiFramework.getIModelConnection();
  //   if (equipmentType === EquipmentType.Antenna) {
  //     height = 2;
  //     width = 0.50;
  //     thickness = 0.25;
  //   }
  //   else if (equipmentType === EquipmentType.RRU) {
  //     height = 0.563;
  //     width = 0.32;
  //     thickness = 0.163;
  //   }
  //   else if (equipmentType === EquipmentType.Microwave) {
  //     height = 0.858;
  //     width = 0.83;
  //     thickness = 0.575;
  //   }
  //   const name = this.getEquipmentName(equipmentType);
  //   const json: FeedlineData = {
  //     Azimuth: 0,
  //     Width: width,
  //     Thickness: thickness,
  //     Height: height,
  //     Id: "",
  //     CenterPositions: {
  //       localBase: new Point3d,
  //       localTop: new Point3d
  //     },
  //     ActualPositions: {
  //       base: new Point3d,
  //       top: new Point3d
  //     }
  //   };
  //     //if (!iModel.isBlank) {// Always convert from ecef as per coversion mecanisms set for this module.
  //                             // Quick fix for : #998270 Equipment Addition is not working on OTiQ.
  //     const cart = iModel!.spatialToCartographicFromEcef(pt);
  //     const m = DecoratorHelper.convertWGS2UTM(cart);
  //     pt.x = json!.x_position = m[0];
  //     pt.y = json!.y_position = m[1];
  //     pt.z = json!.z_position = cart.height;
  //   //} else {
  //   //  const htBuffer = iModel.spatialToCartographicFromEcef(pt).height;
  //   //  const correctZ = (htBuffer - json.z_position) + json.z_position;
  //   //  pt.z = json.z_position = correctZ;
  //   //}

  //   // const equipNamePositionMap = new Map(store.getState().detectedData.equipmentDataMaps.equipNamePositionMap);
  //   let entry: any;
  //   // if (equipmentType === EquipmentType.Microwave) {
  //   //   entry = this.drawCylinder(json);
  //   // } else {
  //     entry = this.drawBox(json);
  //   // }
  //   if (entry) {
  //     this.saveIntoLocalObj({...json}, entry);
  //     const boxIndex = this.boxes.length - 1;

  //     // EquipmentsTable.equipNamePositionMap.set(name, {...json});

  //     // const existingMaps = equipDataMaps;
  //     // const newEquipInfoMap = new Map(existingMaps.equipNameInfoMap);
  //     // const newEquipInfoMaps: equipmentDataMaps = {...existingMaps, equipNameInfoMap: newEquipInfoMap};
  //     // // store.dispatch(setEquipmentDataMaps(newEquipInfoMaps));

  //     const id = this.nameIdMap.get(name);
  //     if (iModel) {
  //       iModel.selectionSet.replace(id!);
  //       // SampleToolWidget.selectedBoxName = name;
  //       // SampleToolWidget.selectedBoxInformation = SampleToolWidget.equipNameInfoMap.get(name);

  //       // show equipment widget
  //       UiFramework.frontstages.activeFrontstageDef?.getStagePanelDef(StagePanelLocation.Right)?.
  //         findWidgetDef("PropertyListWidget")?.setWidgetState(WidgetState.Open);
  //       SyncUiEventDispatcher.dispatchSyncUiEvent("equipmentselected");
  //       // show Rotation widget
  //       // UiFramework.frontstages.activeFrontstageDef?.getZoneDef(ZoneLocation.CenterRight)?.
  //       //   findWidgetDef("EquipmentRotation")?.setWidgetState(WidgetState.Open);
  //     }
  //     if (equipmentType !== EquipmentType.Microwave) {
  //       // SampleToolWidget.isEditModeActive = true;
  //       FeedlineDecorator.sourceId = id;
  //       ModifyHandleDecoration.clear();
  //       ModifyHandleDecoration.create(IModelApp.viewManager.selectedView as ScreenViewport, id!, name, boxIndex, this);
  //     }
  //   }
  //   IModelApp.viewManager.selectedView?.invalidateCachedDecorations(this);

  //   return json;
  // }
  /**
   * find equipment name
   * @param equipmentType equipment type
   * @returns name of equipment
   */
  private getEquipmentName(equipmentType: string | undefined) {
    const equips = EquipmentsTable.equipmentData.filter((i) => i.type === equipmentType);
    const index = equips.length;
    return this.recursiveFindName(equipmentType!, index);
  }
  /**
   * find name with recursve func
   * @param equipmentType type of equipment
   * @param index no of existing element
   * @returns name of new equipment
   */
  private recursiveFindName(equipmentType: string, index: number): string {
    const name = equipmentType + "_" + index;
    const exists = EquipmentsTable.equipmentData.findIndex((i) => i.name === name);
    if (exists === -1) {
      return name;
    } else {
      return this.recursiveFindName(equipmentType, index + 1);
    }
  }
  /**
   * add clone box
   * @param _equipData Position information of equipment
   * @param pt Global Point
   * @param _prevName old name of equipment
   * @returns true if successful
   */
  // public async addClonedBox(_equipData: FeedlineData, pt: Point3d, _prevName: string): Promise<FeedlineData | undefined> {
  //   const vp = IModelApp.viewManager.selectedView!;
  //   const iModel = UiFramework.getIModelConnection()!;

  //   // let _name = SampleToolWidget.selectedBoxName;
  //   // let _name = selectedObjectInfo.selectedObjectNST.name;
  //   let _name = _prevName;
  //   const microwaveIndex = this.microWaveCylinders.findIndex((entry) => entry.name === _name);
  //   const rruIndex = this.rruBoxes.findIndex((entry) => entry.name === _name);
  //   let equipmentType: EquipmentType;
  //   if (microwaveIndex !== -1) {
  //     equipmentType = EquipmentType.Microwave;
  //   } else if (rruIndex !== -1) {
  //     equipmentType = EquipmentType.RRU;
  //   } else {
  //     equipmentType = EquipmentType.Antenna;
  //   }
  //   _name = this.getEquipmentName(equipmentType);
  //   const newJson: FeedlineData = {
  //     Azimuth: _equipData.Azimuth,
  //     Height: _equipData.Height,
  //     Width: _equipData.Width,
  //     Thickness: _equipData.Thickness,
  //     Id: "",
  //     CenterPositions: {
  //       localBase: new Point3d,
  //       localTop: new Point3d
  //     },
  //     ActualPositions: {
  //       base: new Point3d,
  //       top: new Point3d
  //     }
  //   };
  //   // if (!iModel.isBlank) {
  //     const cart = iModel.spatialToCartographicFromEcef(pt);
  //     const m = DecoratorHelper.convertWGS2UTM(cart);
  //     pt.x = newJson.x_position = m[0];
  //     pt.y = newJson.y_position = m[1];
  //     pt.z = newJson.z_position = cart.height;
  //   // } else {
  //   //   const htBuffer = iModel.spatialToCartographicFromEcef(pt).height;
  //   //   const correctZ = (htBuffer - pt.z) + pt.z;
  //   //   pt.z = newJson.z_position = correctZ;
  //   // }

  //   let entry: any;
  //   // if (microwaveIndex !== -1) {
  //   //   entry = this.drawCylinder(newJson);
  //   // } else {
  //     entry = this.drawBox(newJson);
  //   // }

  //   this.saveIntoLocalObj(newJson, entry);
  //   // const equipNamePositionMap = new Map(store.getState().detectedData.equipmentDataMaps.equipNamePositionMap);

  //   // EquipmentsTable.equipNamePositionMap.set(newJson.Equipment_Name, newJson);
  //   IModelApp.viewManager.selectedView?.invalidateCachedDecorations(this);
  //   return newJson;
  // }

  // public deleteBoxByName(_name: Id64String): boolean {
  //   if (!Array.from(this.nameIdMap.keys()).includes(_name)) {
  //     return false;
  //   }
  //   // Clear any edit arrows
  //   ModifyHandleDecoration.clear();

  //   this.boxes = this.boxes.filter((e) => e.name !== _name);
  //   this.shapes = this.shapes.filter((e) => e.name !== _name);
  //   this.currJson = this.currJson.filter((e) => e.Equipment_Name !== _name);

  //   const id = this.nameIdMap.get(_name);
  //   if (id)
  //     UiFramework.getIModelConnection()!.selectionSet.remove(id);
  //   this.nameIdMap.delete(_name);

  //   this.drawnBoxes = this.drawnBoxes.filter((e) => e.name !== _name);
  //   this.antennaBoxes = this.antennaBoxes.filter((entry) => entry.name !== _name);
  //   this.rruBoxes = this.rruBoxes.filter((entry) => entry.name !== _name);
  //   this.microWaveCylinders = this.microWaveCylinders.filter((entry) => entry.name !== _name);
    
  //   //Deleting faces from the equipment geometries
  //   let faceName = "";
  //   if(_name.includes('Micro')){
  //     faceName=`MW_${_name.split('_')[2]}_face`;
  //     this.drawnCylinders = this.drawnCylinders.filter((e) => e.name !== _name || e.name !== faceName);
  //     this.shapes = this.shapes.filter((e) => e.name !== faceName);
  //   } else { // For Antennas and RRUs
  //     faceName = _name.substring(0, 3).toUpperCase()+`_${_name.split('_')[1]}_face`;
  //     this.drawnBoxes = this.drawnBoxes.filter((e) => e.name !== _name || e.name !== faceName);
  //     this.shapes = this.shapes.filter((e) => e.name !== faceName);
  //   }
  //   // const equipNameInfoMap = new Map(store.getState().detectedData.equipmentDataMaps.equipNameInfoMap);
  //   // const equipNamePositionMap = new Map(store.getState().detectedData.equipmentDataMaps.equipNamePositionMap);
    
  //   EquipmentsTable.equipNameInfoMap.delete(_name);
  //   EquipmentsTable.equipNamePositionMap.delete(_name);

  //   IModelApp.viewManager.selectedView?.invalidateCachedDecorations(this);
  //   return true;
  // }

  // public updateBox(newBox: Box, index: number, isHeightChange: boolean = true, updateJson: boolean = true, newJson: FeedlineData | null = null) {
  //   if (newBox) {
  //     // const oldBox = this.boxes[index].box.clone();
  //     this.boxes[index].box = newBox;
  //     const boxName = this.shapes[index].name;
      
  //     // const antIndex = this.antennaBoxes.findIndex((i) => i.name === boxName);
  //     // if (antIndex !== -1)this.antennaBoxes[antIndex].box = newBox;
      
  //     // const rruIndex = this.rruBoxes.findIndex((i) => i.name === boxName);
  //     // if (rruIndex !== -1)this.rruBoxes[rruIndex].box = newBox;

  //     // const boxIndex = this.drawnBoxes.findIndex((i) => i.name === boxName);
  //     // if (boxIndex !== -1)this.drawnBoxes.splice(boxIndex, 1);

  //     const shapeIndex = this.shapes.findIndex((shape) => shape.name === boxName);
  //     if (shapeIndex !== -1)this.shapes.splice(shapeIndex, 1);
      
  //     if (updateJson) {
  //       // update currJson list
  //       const jsonIndex = this.currJson.findIndex((json) => json.Equipment_Name === boxName);
  //       const json = {...this.currJson[jsonIndex]};
  //       const centroid = new Point3d(json.x_position, json.y_position, json.z_position);
  //       const newBoxJson: FeedlineData = {
  //         Azimuth: json.Azimuth,
  //         Height: !isHeightChange ? json.Height : newBox.getTopOrigin().distance(newBox.getBaseOrigin()),
  //         Thickness: newBox.getBaseY(),
  //         Width: newBox.getBaseX(),
  //         Id: "",
  //         CenterPositions: {
  //           localBase: new Point3d,
  //           localTop: new Point3d
  //         },
  //         ActualPositions: {
  //           base: new Point3d,
  //           top: new Point3d
  //         }
  //       };
  //       // const equipNamePositionMap = new Map(store.getState().detectedData.equipmentDataMaps.equipNamePositionMap);
        
  //       // equipNamePositionMap.set(json.Equipment_Name, newBoxJson);
  //       // Drawing the equipment face
  //       if(this.showFrontFace){
  //         const newBoxEntry: BoxEntry = {box: newBox,name: json.Equipment_Name};
  //         // this.drawAntRruFace(newBoxEntry, newBoxJson);
  //       }
  //       this.shapes[jsonIndex].modelData = newBoxJson;
  //     } else if(newJson != null){
  //       const jsonIndex = this.shapes.findIndex((json) => json.name === boxName);
  //       const json = {...newJson};
  //       const centroid = new Point3d(json.x_position, json.y_position, json.z_position);
  //       const newBoxJson: FeedlineData = {
  //         Azimuth: json.Azimuth,
  //         Height: !isHeightChange ? json.Height : newBox.getTopOrigin().distance(newBox.getBaseOrigin()),
  //         Thickness: newBox.getBaseY(),
  //         Width: newBox.getBaseX(),
  //         Id: "",
  //         CenterPositions: {
  //           localBase: new Point3d,
  //           localTop: new Point3d
  //         },
  //         ActualPositions: {
  //           base: new Point3d,
  //           top: new Point3d
  //         }
  //       };
  //       // const equipNamePositionMap = new Map(store.getState().detectedData.equipmentDataMaps.equipNamePositionMap);
        
  //       // equipNamePositionMap.set(json.Equipment_Name, newBoxJson);
  //       // Drawing the equipment face
  //       // if(this.showFrontFace){
  //       //   const newBoxEntry: BoxEntry = {box: newBox,name: json.Equipment_Name};
  //       //   this.drawAntRruFace(newBoxEntry, newBoxJson);
  //       // }
  //       this.currJson[jsonIndex] = newBoxJson;

  //     }
  //     IModelApp.viewManager.selectedView?.invalidateCachedDecorations(this);
  //   }
  // }


  // public updateBoxPositionByName(_name: string, _changeVector: Vector3d): boolean {
  //   const index = this.boxes.findIndex((entry) => entry.name === _name);
  //   if (index === -1) return false;
  //   const jsonIndex = this.currJson.findIndex((json) => json.Equipment_Name === _name);
  //   const equipmentJson = {...this.currJson[jsonIndex]};

  //   // const equipmentJson: FeedlineData = {...this.currJson.find((i) => i.Equipment_Name === _name)!};
  //   //Making changes to the position property of the equipment position object
  //   // equipmentJson!.x_position+=_changeVector.x;
  //   // equipmentJson!.y_position+=_changeVector.y;
  //   // equipmentJson!.z_position+=_changeVector.z;
  //   const box = this.constructBoxGeometry(equipmentJson!, _changeVector);
  //   this.updateBox(box!, index);
  //   this.currJson[jsonIndex] = equipmentJson;

  //   if (ModifyHandleDecoration._decorator) {
  //     ModifyHandleDecoration._decorator._shape = this.boxes[index].box;
  //     ModifyHandleDecoration._decorator._boxIndex = index;
  //     ModifyHandleDecoration._decorator._shapeName = _name;
  //     ModifyHandleDecoration._decorator.createClipShapeControls();
  //   }
  //   return true;
  // }

  // public async updateBoxRotation(_name: string, newYprAngle: YawPitchRollAngles): Promise<boolean> {
  //   const index = this.shapes.findIndex((entry) => entry.name === _name);
  //   // const equipmentJson: FeedlineData = {...this.currJson.find((i) => i.Equipment_Name === _name)!};
  //   const equipmentJson: FeedlineData = {...this.shapes.find((i) => i.name === _name)!}.modelData!;
  //   // const equipmentJson2 = {...equipDataMaps.equipNameInfoMap?.get(_name)};
  //   equipmentJson!.Roll = newYprAngle.roll.degrees;
  //   equipmentJson!.Azimuth = newYprAngle.yaw.degrees;
  //   equipmentJson!.Tilt = newYprAngle.pitch.degrees;
  //   const newBox = this.constructBoxGeometry(equipmentJson!);

  //   this.updateBox(newBox!, index, true);
  //   if (ModifyHandleDecoration._decorator) {
  //     ModifyHandleDecoration._decorator._shape = this.boxes[index].box;
  //     ModifyHandleDecoration._decorator._boxIndex = index;
  //     ModifyHandleDecoration._decorator._shapeName = _name;
  //     ModifyHandleDecoration._decorator.createClipShapeControls();
  //   }
  //   return true;
  // }
  /**
   * update microwave position
   * @param _name name of Microwave
   * @param _changePosVector change vector
   * @returns success if true
   */

  public constructBoxGeometry(equipJson: FeedlineData, _shiftPos: Vector3d = new Vector3d(0, 0, 0)): Box {
    // const localCentroid = new Point3d(0, 0, 0);
    // =====================================
    // Step 1 - Build the box in local coordinate system
    // =====================================
    // this.addFeedline(equipJson.theData.localBase, equipJson.theData.localTop, equipJson.width, equipJson.thickness, equipJson.azimuth);
    const box = Box.createDgnBoxWithAxes(equipJson.CenterPositions.localBase, Transform.identity.matrix, equipJson.CenterPositions.localTop, equipJson.Width, equipJson.Thickness, equipJson.Width, equipJson.Thickness, equipJson.Id.match(/middle/) == null)!;
    // const box = this.createBoxInLocalCoordinate(localCentroid, equipJson.Height, equipJson.Width, equipJson.Thickness, equipJson)!;
    // let box = this.createBoxFromStartandEndPoints(equipJson.feeder.startCenter, equipJson.feeder.endCenter, equipJson.feeder.width, equipJson.feeder.height, -equipJson.feeder.orientation, 0, 0);
    // Always start by building a brand new box.... do not untransform the old box
    // =====================================
    // Step 2 - Apply Transform (local coordinate system > Global coordinate system)
    // =====================================
    // Pass the new equipment position json object
    // const boxTransform = this.applyTransformFromLocaltoGlobal(box!, localCentroid, JSON.parse(JSON.stringify(equipJson)), shiftPos) as Box;

    let x=0, y=0, z=0;
    box.getCorners().forEach(e=>{x+=e.x;y+=e.y;z+=e.z;});
    // console.log("x, y: ",x, y, x/4, y/4);

    // let shiftPosIn = new Vector3d(0, 0, 0);
    // let newAng = YawPitchRollAngles.createFromMatrix3d(newMatrix!)!;

    const centroidPtInLocal = new Point3d(x/8, y/8, z/8);
    // const tiltMatrix = Matrix3d.createRotationAroundAxisIndex(AxisIndex.X, Angle.createDegrees(0));
    // // const tiltMatrix = Matrix3d.createRotationAroundAxisIndex(AxisIndex.X, newAng.pitch);
    // let trans = Transform.createFixedPointAndMatrix(centroidPtInLocal, tiltMatrix);
    // // // Apply to the box
    // box?.tryTransformInPlace(trans);
    // let newCentroidPtInGlobal = trans.multiplyPoint3d(centroidPtInLocal.clone());

    // // Apply the same transform to the box's or cone's imaginary y-vector
    // let xYVec = new Vector3d(0, 1, 0);
    // xYVec = trans.multiplyVector(xYVec);
    // let shiftPos = trans.multiplyVector(shiftPosIn.clone());

    // =====================================
    // Step 2 - Apply -Roll about the Y-axis (Local Coordinate)
    // =====================================
    // const rollMatrix = Matrix3d.createRotationAroundVector(xYVec, Angle.createDegrees(0));
    // const rollMatrix = Matrix3d.createRotationAroundVector(xYVec, newAng.roll);
    // trans = Transform.createFixedPointAndMatrix(centroidPtInLocal, rollMatrix!);
    // newCentroidPtInGlobal = trans.multiplyPoint3d(newCentroidPtInGlobal);
    // // Apply to the box
    // box?.tryTransformInPlace(trans);
    // shiftPos = trans.multiplyVector(shiftPos);
    // const stMinEnd = startPt.minus(endPt);
    // console.log('yaw: ', stMinEnd);

    const azMatrix = Matrix3d.createRotationAroundVector(box.getVectorZ(), Angle.createDegrees(equipJson.Azimuth))!;
    // const azMatrix = Matrix3d.createRotationAroundAxisIndex(AxisIndex.Z, newAng.yaw);
    const trans = Transform.createFixedPointAndMatrix(centroidPtInLocal, azMatrix);
    let newCentroidPtInGlobal = trans.origin;
    newCentroidPtInGlobal = trans.multiplyPoint3d(newCentroidPtInGlobal);
    // Apply to the box
    box?.tryTransformInPlace(trans);





    return box;
  }


  private createBoxFromStartandEndPoints(start: Point3d, end: Point3d, width: number, thickness: number , yaw: number, pitch: number, roll:number) {
    const ypr = YawPitchRollAngles.createDegrees(yaw, pitch, roll);
    const matrix = ypr.toMatrix3d();
    const box = Box.createDgnBoxWithAxes(start, matrix, end, width, thickness, width, thickness, true);
    return box;
}

  /**
   * Build the box in local coordinate system
   * @localCentroid local centroid
   * @param height equipment height
   * @param width equipment width
   * @param thickness equipment thickness
   * @param centroidPt centroid point
   * @returns create a box
   */
  private createBoxInLocalCoordinate(_localCentroid: Point3d, _height: number, width: number, thickness: number, _theJson) {
    // const localBaseCenter = new Point3d(localCentroid.x, localCentroid.y, -0.5 * height);
    // const localBaseOrigin = localBaseCenter.clone();
    // localBaseOrigin.x = localBaseOrigin.x - 0.5 * width;
    // localBaseOrigin.y = localBaseOrigin.y - 0.5 * thickness;

    // const localTopOrigin = localBaseOrigin.clone();
    // localTopOrigin.z = localTopOrigin.z + height;

    const box = Box.createDgnBoxWithAxes(_theJson.theData.localBase, Transform.identity.matrix, _theJson.theData.localTop, width, thickness, width, thickness, true);
    // const box = Box.createDgnBoxWithAxes(localBaseOrigin, Transform.identity.matrix, localTopOrigin, width, thickness, width, thickness, true);
    return box;
  }
  /**
   * create transform from local to global
   * @param boxOrConeInLocal box or cone in local coordinate
   * @param centroidPtInLocal centroid in local coordinate
   * @param equipJson Equipment Object
   * @returns Transformed Box or Cone
   */
  private applyTransformFromLocaltoGlobal(boxOrConeInLocal: SolidPrimitive, centroidPtInLocal: Point3d, equipJson: FeedlineData|any, shiftPosIn: Vector3d): SolidPrimitive {
    let oldCentroidPtInGlobal = new Point3d(equipJson.x_position, equipJson.y_position, equipJson.z_position);
    // =====================================
    // Step 0 - Calculate Centroid with Height Factor
    // =====================================
    const iModel = iModelConnection! || UiFramework.getIModelConnection();

    // Use MSL variable to store values for solve problem of OLD CONTEXT CAPTURE generated model
    let msl: number = 0;
    const cart = iModel.spatialToCartographicFromEcef(iModel.projectExtents.high!);
    oldCentroidPtInGlobal = DecoratorHelper.ExtractSpatialXYZ(cart, oldCentroidPtInGlobal.x, oldCentroidPtInGlobal.y, oldCentroidPtInGlobal.z, iModel);
    if (ConfigManager.RealityDataVersion && ConfigManager.RealityDataVersion === "OLD") {
      msl = egm96.meanSeaLevel(cart.latitudeDegrees, cart.longitudeDegrees);
      oldCentroidPtInGlobal.z = oldCentroidPtInGlobal.z - msl;
    }

    // }
    // =====================================
    // Step 1 - Apply Tilt about the X-axis (Local Coordinate)
    // =====================================
    const tiltMatrix = Matrix3d.createRotationAroundAxisIndex(AxisIndex.X, Angle.createDegrees(equipJson.Tilt));
    let trans = Transform.createFixedPointAndMatrix(centroidPtInLocal, tiltMatrix);
    // Apply to the box
    boxOrConeInLocal?.tryTransformInPlace(trans);
    let newCentroidPtInGlobal = trans.multiplyPoint3d(centroidPtInLocal.clone());
    // Apply the same transform to the box's or cone's imaginary y-vector
    let xYVec = new Vector3d(0, 1, 0);
    xYVec = trans.multiplyVector(xYVec);
    let shiftPos = trans.multiplyVector(shiftPosIn.clone());

    // =====================================
    // Step 2 - Apply -Roll about the Y-axis (Local Coordinate)
    // =====================================
    const rollMatrix = Matrix3d.createRotationAroundVector(xYVec, Angle.createDegrees(-equipJson.Roll));
    trans = Transform.createFixedPointAndMatrix(centroidPtInLocal, rollMatrix!);
    newCentroidPtInGlobal = trans.multiplyPoint3d(newCentroidPtInGlobal);
    // Apply to the box
    boxOrConeInLocal?.tryTransformInPlace(trans);
    shiftPos = trans.multiplyVector(shiftPos);

    // =====================================
    // Step 3 - Apply -Azimuth about the Z-axis (Local Coordinate)
    // =====================================
    const azMatrix = Matrix3d.createRotationAroundAxisIndex(AxisIndex.Z, Angle.createDegrees(-equipJson.Azimuth));
    trans = Transform.createFixedPointAndMatrix(centroidPtInLocal, azMatrix);
    newCentroidPtInGlobal = trans.multiplyPoint3d(newCentroidPtInGlobal);
    // Apply to the box
    boxOrConeInLocal?.tryTransformInPlace(trans);
    shiftPos = trans.multiplyVector(shiftPos);

    // =====================================
    // Step 4 - apply centroid transform (Global Coordinate)
    // =====================================
    shiftPos = shiftPos.plus(oldCentroidPtInGlobal);
    const translation = Transform.createTranslation(shiftPos);
    // Apply to the box
    boxOrConeInLocal?.tryTransformInPlace(translation);
    // multiply local centroid point
    newCentroidPtInGlobal = translation.multiplyPoint3d(newCentroidPtInGlobal);
    // =====================================
    // Step 5 - Save value in UTM
    // =====================================
    if (!iModel.isBlank) {
      const cart = iModel!.spatialToCartographicFromEcef(newCentroidPtInGlobal);
      const m = DecoratorHelper.convertWGS2UTM(cart);
      equipJson.x_position = newCentroidPtInGlobal.x = m[0];
      equipJson.y_position = newCentroidPtInGlobal.y = m[1];
      equipJson.z_position = newCentroidPtInGlobal.z = cart.height;
    } else {
      const htBuffer = iModel.spatialToCartographicFromEcef(newCentroidPtInGlobal).height;
      const correctZ = (htBuffer - newCentroidPtInGlobal.z) + newCentroidPtInGlobal.z;
      equipJson.x_position = newCentroidPtInGlobal.x;
      equipJson.y_position = newCentroidPtInGlobal.y;
      equipJson.z_position = newCentroidPtInGlobal.z = correctZ + msl;
    }
    return boxOrConeInLocal;
  }

  public createGraphics(context: DecorateContext): RenderGraphic | undefined {
    // Get next available Id to represent decoration for its life span.

    this.shapes.forEach((styledGeometry) => {
      let transientId: Id64String;
      if (styledGeometry.transientId === "") {
        transientId = context.viewport.iModel.transientIds.next;
      } else {
        transientId = styledGeometry.transientId;
      }

      const builder = context.createGraphicBuilder(GraphicType.Scene, undefined, transientId);
      // builder.wantNormals = true;
      const geometry = styledGeometry.geometry;
      builder.setSymbology(styledGeometry.color, styledGeometry.fillColor, styledGeometry.lineThickness, styledGeometry.linePixels);
      this.createGraphicsForGeometry(geometry, styledGeometry.edges, builder);

      context.addDecorationFromBuilder(builder);
    });

    // const builder = context.createGraphicBuilder(GraphicType.Scene, undefined, iModelConnection?.transientIds.next);
    // builder.wantNormals = true;

    return undefined;
  }

  private createGraphicsForGeometry(geometry: GeometryQuery, wantEdges: boolean, builder: GraphicBuilder) {
    if (geometry instanceof LineString3d) {
      builder.addLineString(geometry.points);
    } else if (geometry instanceof Loop) {
      builder.addLoop(geometry);
      if (wantEdges) {
        // Since decorators don't natively support visual edges,
        // We draw them manually as lines along each loop edge/arc
        // builder.setSymbology(ColorDef.black, ColorDef.black, 2);
        const curves = geometry.children;
        curves.forEach((value) => {
          if (value instanceof LineString3d) {
            let edges = value.points;
            const endPoint = value.pointAt(0);
            if (endPoint) {
              edges = edges.concat([endPoint]);
            }
            builder.addLineString(edges);
          } else if (value instanceof Arc3d) {
            builder.addArc(value, false, false);
          }
        });
      }
    } else if (geometry instanceof Path) {
      builder.addPath(geometry);
    } else if (geometry instanceof IndexedPolyface) {
      builder.addPolyface(geometry, true);
      if (wantEdges) {
        // Since decorators don't natively support visual edges,
        // We draw them manually as lines along each facet edge
        builder.setSymbology(ColorDef.black, ColorDef.black, 2);
        const visitor = IndexedPolyfaceVisitor.create(geometry, 1);
        let flag = true;
        while (flag) {
          const numIndices = visitor.pointCount;
          for (let i = 0; i < numIndices - 1; i++) {
            const point1 = visitor.getPoint(i);
            const point2 = visitor.getPoint(i + 1);
            if (point1 && point2) {
              builder.addLineString([point1, point2]);
            }
          }
          flag = visitor.moveToNextFacet();
        }
      }
    } else if (geometry instanceof LineSegment3d) {
      const pointA = geometry.point0Ref;
      const pointB = geometry.point1Ref;
      const lineString = [pointA, pointB];
      builder.addLineString(lineString);
    } else if (geometry instanceof Arc3d) {
      builder.addArc(geometry, false, false);
    } else if (geometry instanceof CurveChainWithDistanceIndex) {
      this.createGraphicsForGeometry(geometry.path, wantEdges, builder);
    }
  }

  public addGeometry(geometry: GeometryQuery, _fillColor: ColorDef, _name: string|number, _edges = this.edges, _capped: boolean = this.capped, _modelData: FeedlineData|any = undefined) {
    const styledGeometry: CustomGeometryQuery = ({
      geometry,
      color: this.color,
      fill: this.fill,
      // fillColor: ColorDef.fromTbgr(ColorDef.withTransparency(fillColor.tbgr, 255-Math.round(255*SampleToolWidget.iModelTransparency.equipment))),
      // fillColor: ColorDef.fromTbgr(ColorDef.withTransparency(fillColor.tbgr, 255-Math.round(255*store.getState().detectedData.objectOpacityState.equipment.value))),
      // fillColor: ColorDef.fromTbgr(ColorDef.withTransparency(ColorDef.fromAbgr(ColorByName.aqua).tbgr, 0)),
      fillColor: _fillColor,
      // fillColor: _fillColor,
      lineThickness: this.lineThickness,
      edges: _edges,
      capped: _capped,
      modelData: _modelData,
      linePixels: this.linePixels,
      transientId: this.nameIdMap.get(_name.toString()) !== undefined ? this.nameIdMap.get(_name.toString())! : "",
      name: _name.toString().split('_')[0],
    });
    this.shapes.push(styledGeometry);
  }
}
