import { pecColumn, pecInputTagTypes, pecDataValueTypes, pecRows, pecGridStructure, pecComponentProps, pecStrucType, pecTableButton, PropertyEditorComponent } from "../components/PropertyEditorComponent"

import React, { ReactNode, useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { AddEditPolygonShapeTool } from "../components/tools/AddEditPolygonShapeTool"
import { HighlightGratingTool } from "../components/tools/HighlightGratingTool"
import { RootState } from "../../store/States"
import { PolygonShapeDecorator } from "./decorators/PolygonShapeDecorator"
import { editOnClose, resetObjectIds } from "../components/HorizontalToolbarItems"
import { SyncUiEventDispatcher, UiFramework } from "@itwin/appui-react"
import { IModelApp } from "@itwin/core-frontend"
import { SyncUiEventIds } from "../../store/redux-types"

let runTimer: any;
const GratingsEditHandler = (props) => {
  const [gratingsEditData, setGratingsEditData] = useState(props.gratingsMenuStruc);
  const [saveButton, setSaveButton] = useState<{disabled: boolean, text: string}>({disabled: false, text: "Save"});



  const onModalBtnClick = async (event: any) => {
    const id = event.target.id;
    switch (id) {
      case "Save#1": {
        saveSelectedPolygonData();
        break;
      }
      case "Cancel#2": {
      editOnClose("PolygonShapeDecorator", true)
        break;
      }
    }
  }
  
  const onFieldUpdate = (event) => {

    let currentEvent = event;

  }
  const  onMouseDown= (event) => {

    const eventType = event!.constructor?.name;
    const {id: theId, name: theName, value: theValue} = event.target.tagName != "SPAN" ? event.target : event.target.parentElement;
    const idSplit = theId.split("@");
    const [inputType, rowIndex, columnIndex] = idSplit[0].split('-');
    let decorator = IModelApp.viewManager.decorators.filter((e) => e.constructor.name.includes("PolygonShapeDecorator"))[0] as PolygonShapeDecorator;
    if(idSplit[1] == "averageApproximation"){
      snapToElevationPlane(true)
      resetObjectIds(decorator);
    }
    else if(idSplit[1] == "rawNormal"){
      snapToElevationPlane(false);
      resetObjectIds(decorator);
    }
    runTimer = setInterval(
      async ()=>{
        switch(idSplit[1]){
          case "moveUp": 
            translatePolygon(0, 0, 0.002)
            break;
          case "moveDown":         
          translatePolygon(0, 0, -0.002)
            break;
        }
    }, 50);

    setTimeout(() => clearInterval(runTimer), 10000);
  }
  const onMouseUp = (event) => {
    let currentEvent= event;
    clearInterval(runTimer);

  }
  const onFieldChange = async (event, inputId: string) => {
   
    const temp: pecGridStructure = {...gratingsEditData};
    const eventType = event!.constructor?.name;
    // return ;
    if((eventType == "SyntheticBaseEvent") || (typeof event === 'object' && event !== null)){
      const {id: theId, name: theName, value: theValue} = event.target;
      const [inputType, rowIndex, columnIndex] = theId.split("@")[0].split('-');
  
      switch (inputType) {
        case pecInputTagTypes.SWITCH:
          temp.rows[rowIndex].columns[columnIndex].value = !temp.rows[rowIndex].columns[columnIndex].value;
          createJointsAndSupportingMembers();
          break;
        case pecInputTagTypes.SELECT:
          temp.rows[rowIndex].columns[columnIndex].value = temp.rows[rowIndex].columns[columnIndex].data![theValue];
          break;
        case pecInputTagTypes.SLIDER_INPUT:
          break;
        case pecInputTagTypes.CHECKBOX:
          temp.rows[rowIndex].columns[columnIndex].value = !temp.rows[rowIndex].columns[columnIndex].value;
          break;
      
        default:
          break;
      }
      
      
    } else {    
      const [inputType, rowIndex, columnIndex] = inputId.split("@")[0].split('-');
      switch (inputType) {
      case "sis":
        temp.rows[rowIndex].columns[columnIndex].value.currentValue = event[0];
        break;
      case "sii":
        temp.rows[rowIndex].columns[columnIndex].value.currentValue = event;
      break;
      
      default:
        break;
      }

    }


    setGratingsEditData(temp);
  }
  
  const onFieldClick = (event) => {
    let currentEvent = event;
  }

 const  translatePolygon = (dx: number, dy: number, dz: number) =>{
    if (PolygonShapeDecorator.lastSelectedPolygonShape != undefined) {
        AddEditPolygonShapeTool.poligonInfoArray;
        PolygonShapeDecorator.lastSelectedPolygonShape;//holds the refrence to the last selected Grating polygon that will be transformed.

        for (let n = 0; n < AddEditPolygonShapeTool.poligonInfoArray.length; n++) {
            if (AddEditPolygonShapeTool.poligonInfoArray[n]?.name == PolygonShapeDecorator.lastSelectedPolygonShape?.name) {
                for (let m = 0; m < AddEditPolygonShapeTool.poligonInfoArray[n].poligonPoints.length; m++) {
                    AddEditPolygonShapeTool.poligonInfoArray[n].poligonPoints[m].x += dx;
                    AddEditPolygonShapeTool.poligonInfoArray[n].poligonPoints[m].y += dy;
                    AddEditPolygonShapeTool.poligonInfoArray[n].poligonPoints[m].z += dz;
                    }
                }
            }
        AddEditPolygonShapeTool.createPoligonFromPolygonInfoArray(AddEditPolygonShapeTool.poligonInfoArray);
    }
    let decorator = IModelApp.viewManager.decorators.filter((e) => e.constructor.name.includes("PolygonShapeDecorator"))[0] as PolygonShapeDecorator;
    resetObjectIds(decorator);
}
//------------------------------------------------------------------------------------
const updatePointCountForPolygonCreation = async(pointCount: number)=> {
    AddEditPolygonShapeTool.pointCountPerPolygon = pointCount;
}
//------------------------------------------------------------------------------------
const snapToElevationPlane = async(mod: boolean) => {
    if (PolygonShapeDecorator.lastSelectedPolygonShape != undefined) {
        AddEditPolygonShapeTool.snapGratingPointToNearestPlaneByName(PolygonShapeDecorator.lastSelectedPolygonShape.name, mod);
    }
}
//------------------------------------------------------------------------------------
const createJointsAndSupportingMembers= () =>{
    if (PolygonShapeDecorator.lastSelectedPolygonShape != undefined) {
        AddEditPolygonShapeTool.createJointsAndSupportingMembersByName(PolygonShapeDecorator.lastSelectedPolygonShape.name,false);
    }
}
//------------------------------------------------------------------------------------
const levelAndCreateJointsAndSupportingMembersasync=() => {
    if (PolygonShapeDecorator.lastSelectedPolygonShape != undefined) {
        AddEditPolygonShapeTool.snapGratingPointToNearestPlaneByName(PolygonShapeDecorator.lastSelectedPolygonShape.name, true);
        AddEditPolygonShapeTool.createJointsAndSupportingMembersByName(PolygonShapeDecorator.lastSelectedPolygonShape.name,true);
    }
}
//------------------------------------------------------------------------------------
const deleteSelectedPolygonasync = () => {
    if (PolygonShapeDecorator.lastSelectedPolygonShape != undefined) {
        AddEditPolygonShapeTool.deletePolygonByName(PolygonShapeDecorator.lastSelectedPolygonShape.name);
        // if (HighlightGratingTool.isEnabled) {
        //     AddEditPolygonShapeTool.deleteSavedGratingByName(PolygonShapeDecorator.lastSelectedPolygonShape.name); // This is deleting grating from API
        // }
    }
    editOnClose("PolygonShapeDecorator", true)
}
//------------------------------------------------------------------------------------
const saveSelectedPolygonData = async() =>{
    setSaveButton({disabled: true, text: "Saving..."});
    if (PolygonShapeDecorator.lastSelectedPolygonShape != undefined) {
        await AddEditPolygonShapeTool.saveExportGratingsData();
    }
    SyncUiEventDispatcher.dispatchSyncUiEvent(SyncUiEventIds.RM_Grating_Selected);
    editOnClose("PolygonShapeDecorator", false, true);
    let decorator = IModelApp.viewManager.decorators.filter((e) => e.constructor.name.includes("PolygonShapeDecorator"))[0] as PolygonShapeDecorator;
    resetObjectIds(decorator);
}
  
  
  const modalAddBtns: pecTableButton[] = [
    {name: saveButton.text, value: saveButton.text, class: "dialogBox-save-btn grating-dialogBox-save-btn", disable: saveButton.disabled, onInteract: saveSelectedPolygonData},
    {name: "Delete Preview", value: "Cancel", class: "dialogBox-cancel-btn grating-dialogBox-cancel-btn", disable: false, onInteract: deleteSelectedPolygonasync},
  ];

  const modalEditBtns: pecTableButton[] = [
    {name: saveButton.text, value: saveButton.text, class: "dialogBox-save-btn grating-dialogBox-save-btn", disable: saveButton.disabled, onInteract: onModalBtnClick},
    {name: "Cancel", value: "Cancel", class: "dialogBox-cancel-btn grating-dialogBox-cancel-btn", disable: false, onInteract: onModalBtnClick},
  ];
  
  
  let gratingsEditorStructure: pecComponentProps = {
    structureType: pecStrucType.STRUCTURE,
    title: {
      label: "Gratings Edit",
      // styles: undefined
    },
    grid: gratingsEditData,
    gridButtons: props.title.includes("Edit") ? modalEditBtns : modalAddBtns,
    eventHandlers: {
      onChange: onFieldChange,
      onUpdate: onFieldUpdate,
      onClick: onFieldClick,
      onMouseDown:onMouseDown,
      onMouseUp:onMouseUp

    }
  }


  return (
    <PropertyEditorComponent {...gratingsEditorStructure} />
  )
}

const mapStateToProps = (state: RootState) => {
  let allState = state;
  const gratingsMenuStruc: pecGridStructure = {
    rows: [
        {
          columns: [
            {
              size: 8,
              type: pecInputTagTypes.LABEL,
              customClass: "",
              value: "Point/Coordinate Count",
              disabled: false,
              name: "mvLorR",
              fieldType: pecDataValueTypes.INTEGER,
              modified: false,
              styles: {parentClass: "mb-2", childClass: ""}
            },
            {
              size: 4,
              type: pecInputTagTypes.NUMERIC_INPUT,
              customClass: "",
              value: {currentValue: 3, min: 3, max: 10, step: 1, precision: 1},
              disabled: false,
              name: "botElVal",
              fieldType: pecDataValueTypes.INTEGER,
              modified: false,
              styles: {parentClass: "mb-2", childClass: "number-input-class"}
            },      
          ]
          
        },
        {
          columns: [
            {
              size: 8,
              type: pecInputTagTypes.LABEL,
              customClass: "",
              value: "Move Up or Down",
              disabled: false,
              name: "mvUorD",
              fieldType: pecDataValueTypes.INTEGER,
              modified: false,
              styles: {parentClass: "mb-2", childClass: ""}
            },
            {
              size: 2,
              type: pecInputTagTypes.BUTTON,
              customClass: "",
              value: "U",
              disabled: false,
              name: "moveUp",
              fieldType: pecDataValueTypes.INTEGER,
              modified: false,
              styles: {parentClass: "mb-2", childClass: "number-input-class"}
            },      
            {
              size: 2,
              type: pecInputTagTypes.BUTTON,
              customClass: "",
              value: "D",
              disabled: false,
              name: "moveDown",
              fieldType: pecDataValueTypes.INTEGER,
              modified: false,
              styles: {parentClass: "mb-2", childClass: "number-input-class"}
            },      
          ]
        },
        {
          columns: [
            {
              size: 8,
              type: pecInputTagTypes.LABEL,
              customClass: "",
              value: "Snap to nearest plane",
              disabled: false,
              name: "mvForB",
              fieldType: pecDataValueTypes.INTEGER,
              modified: false,
              styles: {parentClass: "mb-2", childClass: ""}
            },
            {
              size: 2,
              type: pecInputTagTypes.BUTTON,
              customClass: "",
              value: "F",
              disabled: false,
              name: "averageApproximation",
              fieldType: pecDataValueTypes.INTEGER,
              modified: false,
              styles: {parentClass: "mb-2", childClass: "number-input-class"},
            },      
            {
              size: 2,
              type: pecInputTagTypes.BUTTON,
              customClass: "",
              value: "B",
              disabled: false,
              name: "rawNormal",
              fieldType: pecDataValueTypes.INTEGER,
              modified: false,
              styles: {parentClass: "mb-2", childClass: "number-input-class"}
            },      
          ]        
        },
        {
          columns: [
            {
              size: 8,
              type: pecInputTagTypes.LABEL,
              customClass: "",
              value: "Auto create supporting member",
              disabled: false,
              name: "mvForB",
              fieldType: pecDataValueTypes.INTEGER,
              modified: false,
              styles: {parentClass: "mb-2", childClass: ""}
            },
            {
              size: 4,
              type: pecInputTagTypes.SWITCH,
              customClass: "",
              value: false,
              disabled: true,   //THis feature is not working properly and therefore it is disabled temporarily.
              name: "botElVal-switch",
              fieldType: pecDataValueTypes.INTEGER,
              modified: false,
              styles: {parentClass: "mb-2", childClass: "number-input-class"}
            },      
          ]        
        },
      ]
  }

  const retVal = {
    gratingsMenuStruc
  };
  return retVal;
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(GratingsEditHandler);