// -------------------------------------------------------
// import App from "../../components/App";
import { Arc3d, Cone, CurveChainWithDistanceIndex, GeometryQuery, IndexedPolyface, IndexedPolyfaceVisitor, LineSegment3d, LineString3d, Loop, Path, Point3d, PolyfaceBuilder, Transform, Vector3d, TorusPipe, Angle, AngleSweep, Point2d } from "@itwin/core-geometry";
import { ColorByName, ColorDef, LinePixels } from "@itwin/core-common";
import { BeButtonEvent, DecorateContext, Decorator, EventHandled, GraphicBranch, GraphicBuilder, GraphicType, HitDetail, IModelApp, RenderGraphic, SelectionTool } from "@itwin/core-frontend";
import { Id64String } from "@itwin/core-bentley";
import { SyncUiEventDispatcher, UiFramework, StagePanelLocation, WidgetState } from "@itwin/appui-react";
import { DecoratorHelper } from "./DecoratorHelper";
// import { SampleToolWidget } from "../frontstages/SampleToolWidget";
import { ListEnum } from "../../components/Lists/PropertyList";
import { EquipmentPosition } from "./EditableShapeDecorator";
import { store } from "../../../store/rootReducer";
import { setLadderData3DMaps, addToBuilt3DObjectIdsMap } from "../../../store/detectedData/apiDataActionTypes";
import { getPropertiesCalculation, handleLocalToUtm } from "../../components/getLadderProperties";
// -------------------------------------------------------
// Implements visualising mount pipes in an Imodel view.
// -------------------------------------------------------
export interface CustomGeometry {// Custom container for compartmentalising decorator infomation
  geometry: GeometryQuery;
  color: ColorDef;
  fill: boolean;
  fillColor: ColorDef;
  lineThickness: number;
  edges: boolean;
  linePixels: LinePixels;
  transientId: Id64String;
  name: string;
  uid: string;
  startPos: Point3d;
  endPos: Point3d;
  modelData: any;
  thickness: number;
  length: number;
}
interface CircularMountGeometry {
  mount:string,
  geometry?: GeometryQuery;
  torus: TorusPipe;
  color: ColorDef;
  fill: boolean;
  fillColor: ColorDef;
  lineThickness: number;
  edges: boolean;
  linePixels: LinePixels;
  modelData: any;
  uid: string;
  transientId: Id64String;
  name: string;
}
// tslint:disable:naming-convention
export class LadderDecorator implements Decorator {
  // ------------------------------------------------------
  // VARIABLES
  // ------------------------------------------------------
  public pipes: CustomGeometry[];
  public tori: CircularMountGeometry[];
  // private graphics: RenderGraphic | undefined;
  // private flagA: boolean | undefined;
  public geometryGraphics: RenderGraphic[];
  public equipLadderData: any;
  public static ladderData: any[] = [];
  public selectedLadder: any;
  public otherSelectedLadder:any = null;
    public currJson: EquipmentPosition[] = [];
  public static editing: boolean = false;
  public loadedShapes: boolean = false;
  private towerStructure:any = {};
  public static typeOfLadder:String="";
  public decoratorName = "LadderDecorator";
  public previousSelection: undefined | {name: string, startPos: any, endPos: any} = undefined;

  // private selectedID: Id64String | undefined;
  // private selectedName: string | undefined;
  // private selectedGeometryGraphic: RenderGraphic|undefined;

  // private localFData: any;

  // this is very important for high density decorators , improves performance
  // apparently the parent uses this public variable to know how to
  // draw the primitives wrt this decorator child.
  // In the default secanrio the decorator validates and rebinds primitive information , which is inefficent,
  // Use this to optimise ,where info is not rebound but buffer handle/ref is reused.
  // https://www.itwinjs.org/learning/frontend/viewdecorations/#cached-decorations. for more info
  public readonly useCachedDecorations = true;
  public nameIdMap: Map<string, Id64String> = new Map<string, Id64String>();
  public objectIdMap: Map<string, Id64String> = new Map<string, Id64String>();

  // ------------------------------------------------------
  // CONSTRUCTOR
  // ------------------------------------------------------
  constructor() {
    this.pipes = [];
    this.tori = [];
    // this.geometryBuilders = [];
    this.geometryGraphics = [];
    this.getTowerStructureData().then((data)=>{
      this.towerStructure = data;
    });

    
  }
  // ------------------------------------------------------
  // FUNCTIONS
  // ------------------------------------------------------
  /*
  * Functonalty to add markers to a markerset array
  * the markerset is then used in the decorate() to
  * be added to the current Decorator context
  * See: in decorate()// Diabled for now.
  */
  // public addMarkers(pos: Point3d) {
  //     const marker = new Marker(pos, new Point2d(50, 50));
  //     marker.position = pos;
  //     // marker1.visible = true;
  //     marker.setImageUrl("image/defect-camera-icon2.png");
  //     this.markerSet.push(marker);
  // }
  // -------------------------------------------------------
  /*
  * Clear the elements from the existing arrays
  * that will be bound in the decorator() ovveride callback
  */
  // private clearMarkers() {
  //     this.markerSet.length = 0;
  //     IModelApp.viewManager.invalidateDecorationsAllViews();
  // }
  // -------------------------------------------------------
  private clearGeometry() {
    this.pipes = [];
    this.tori = [];
    this.currJson = [];
    this.nameIdMap = new Map<string, Id64String>();
    this.geometryGraphics = [];
    // this.graphics = undefined;
    IModelApp.viewManager.invalidateDecorationsAllViews();
  }
  // -------------------------------------------------------
  // Run externally when terminating and exiting this tool.
  public terminate() {
    // this.clearMarkers();
    this.clearGeometry();
    IModelApp.viewManager.invalidateDecorationsAllViews();
    IModelApp.viewManager.selectedView?.invalidateCachedDecorations(this);
  }
  // -------------------------------------------------------
  /* Take an input for a single pipe geometry to be created
  * in scene.
  */
  public addPipe(startPos: Point3d, endPos: Point3d, thickness: number, color: ColorDef, pname: string, objInfo: any,position?:string) {
    // create cyliner using Cone geometry generated via api.
   if(startPos || endPos ){
    const uniqName = `${pname}`;
    const pipe = Cone.createAxisPoints(startPos, endPos, thickness, thickness, true);

    //Deciding the color of the Pipe based on it's occupancy:- Praful  
    // const equipStatus = Object.entries(this.equipMountData.equipment).map((e: any) => { if (e[1].plat === objInfo.parentMount && e[1].pipeMount === pname && e[1].Face === objInfo.mountFace) return e[0]; }).filter((e) => e);
    // if (equipStatus.length) {
    //   const concAnts: string[] = [];
    //   equipStatus.forEach((e: any) => { if (concAnts.indexOf(e) === -1) concAnts.push(e); });
    //   color = ColorDef.fromTbgr(ColorDef.withTransparency(ColorDef.red.tbgr, 255 - Math.round(255 * store.getState().detectedData.objectOpacityState.mount.value)));
    //   objInfo.vacancy = "Occupied";
    //   objInfo.csEquipNames = concAnts.join(", ");
    // } else if (objInfo.orientation === "Vertical") {
    // }
    color = ColorDef.green;


    //Editing code starts here...
    if (LadderDecorator.editing && this.selectedLadder && this.selectedLadder.uid.includes(uniqName)) {

      const fstPt = startPos.interpolate(0.1, endPos);
      const secPt = startPos.interpolate(0.9, endPos);
      let p1: CustomGeometry, p2: CustomGeometry, p3: CustomGeometry;

      const pipe1 = Cone.createAxisPoints(startPos, fstPt, thickness, thickness, true);
      color = ColorDef.red;

      // create a cone using a Cone object in Polyface Builder.
      const poly1 = PolyfaceBuilder.create();
      if (pipe1 !== undefined) {
        poly1.addCone(pipe1!);
        const polyface1 = poly1.claimPolyface(false);

        // init CustomGeometry var with values.
        p1 = ({
          geometry: polyface1,
          color: ColorDef.white,
          fill: true,
          fillColor: ColorDef.fromTbgr(ColorDef.withTransparency(color.tbgr, 255 - Math.round(255 * store.getState().detectedData.objectOpacityState.mount.value))),
          lineThickness: 0.5,
          thickness: thickness,
          length: startPos.distance(fstPt),
          edges: true,
          linePixels: LinePixels.Solid,
          transientId: IModelApp.viewManager.selectedView!.iModel.transientIds.next, // generate and store a unique ID each time
          name: pname + "_editFront",
          uid: uniqName + "_editFront",
          modelData: objInfo,
          startPos: startPos,
          endPos: endPos,
        });
        this.nameIdMap.set(`ladderGeom#${p1.name}@${p1.startPos.x}@${p1.endPos.x}@${p1. endPos.z}@${LadderDecorator.typeOfLadder}`, p1.transientId);
        this.objectIdMap.set(p1.transientId, `ladderGeom#${p1.name}@${p1.startPos.x}@${p1.endPos.x}@${p1. endPos.z}@${LadderDecorator.typeOfLadder}`);
        // push to array.
        // if(!this.editing)
        this.pipes.push(p1);

      }

      color = ColorDef.blue;
      const pipe2 = Cone.createAxisPoints(fstPt, secPt, thickness, thickness, true);
      // const pipe2 = Cone.createAxisPoints(startPos, endPos, thickness, thickness, true);

      // create a cone using a Cone object in Polyface Builder.
      const poly2 = PolyfaceBuilder.create();
      if (pipe2 !== undefined) {
        poly2.addCone(pipe2!);
        const polyface2 = poly2.claimPolyface(false);

        // init CustomGeometry var with values.
        p2 = ({
          geometry: polyface2,
          color: ColorDef.white,
          fill: true,
          fillColor: ColorDef.fromTbgr(ColorDef.withTransparency(color.tbgr, 255 - Math.round(255 * store.getState().detectedData.objectOpacityState.mount.value))),
          lineThickness: 0.5,
          thickness: thickness,
          length: fstPt.distance(secPt),
          edges: true,
          linePixels: LinePixels.Solid,
          transientId: IModelApp.viewManager.selectedView!.iModel.transientIds.next, // generate and store a unique ID each time
          name: pname + "_editMid",
          uid: uniqName + "_editMid",
          modelData: objInfo,
          startPos: startPos,
          endPos: endPos,
        });
        this.nameIdMap.set(`ladderGeom#${p2.name}@${p2. endPos.x}@${p2. endPos.x}@${p2. endPos.z}@${LadderDecorator.typeOfLadder}`, p2.transientId);
        this.objectIdMap.set(p2.transientId, `ladderGeom#${p2.name}@${p2. endPos.x}@${p2. endPos.x}@${p2. endPos.z}@${LadderDecorator.typeOfLadder}`);
        // push to array.
        // if(!this.editing)
        this.pipes.push(p2);

      }


      const pipe3 = Cone.createAxisPoints(secPt, endPos, thickness, thickness, true);
      color = ColorDef.red;

      // create a cone using a Cone object in Polyface Builder.
      const poly3 = PolyfaceBuilder.create();
      if (pipe3 !== undefined) {
        poly3.addCone(pipe3!);
        const polyface3 = poly3.claimPolyface(false);

        // init CustomGeometry var with values.
        p3 = ({
          geometry: polyface3,
          color: ColorDef.white,
          fill: true,
          fillColor: ColorDef.fromTbgr(ColorDef.withTransparency(color.tbgr, 255 - Math.round(255 * store.getState().detectedData.objectOpacityState.mount.value))),
          lineThickness: 0.5,
          thickness: thickness,
          length: secPt.distance(endPos),
          edges: true,
          linePixels: LinePixels.Solid,
          transientId: IModelApp.viewManager.selectedView!.iModel.transientIds.next, // generate and store a unique ID each time
          name: pname + "_editEnd",
          uid: uniqName + "_editEnd",
          modelData: objInfo,
          startPos: startPos,
          endPos: endPos,
        });
        this.nameIdMap.set(`ladderGeom#${p3.name}@${p3.startPos.x}@${p3. endPos.x}@${p3. endPos.z}@${LadderDecorator.typeOfLadder}`, p3.transientId);
        this.objectIdMap.set(p3.transientId, `ladderGeom#${p3.name}@${p3.startPos.x}@${p3. endPos.x}@${p3. endPos.z}@${LadderDecorator.typeOfLadder}`);
        // push to array.
        // if(!this.editing)
        this.pipes.push(p3);

      }


      // }
      if(objInfo && objInfo.name && (objInfo.name.includes("FIRST")|| objInfo.name.includes("SECOND")) && !objInfo.hasOwnProperty("startPoint"))this.setDataForLadder(objInfo)

    } else {


      // create a cone using a Cone object in Polyface Builder.
      const poly = PolyfaceBuilder.create();
      if (pipe !== undefined) {
        poly.addCone(pipe!);
        const polyface = poly.claimPolyface(false);
        let {topElevation,bottomElevation,elevation} = getPropertiesCalculation(objInfo.startPoint,objInfo.endpoint,objInfo)

        // init CustomGeometry var with values.
        let place = position ?position:""
        const p: CustomGeometry = ({
          geometry: polyface,
          color: ColorDef.white,
          fill: true,
          fillColor: ColorDef.fromTbgr(ColorDef.withTransparency(ColorDef.create(ColorByName.deepPink).tbgr, 255 - Math.round(255 * store.getState().detectedData.objectOpacityState.ladder.value))),
          lineThickness: 0.5,
          thickness: thickness,
          length: startPos.distance(endPos),
          edges: true,
          linePixels: LinePixels.Solid,
          transientId: IModelApp.viewManager.selectedView!.iModel.transientIds.next, // generate and store a unique ID each time
          name: pname+place,
          uid: pname+place,
          modelData:  {...objInfo,topElevation,bottomElevation,elevation,name: pname+place},
          startPos: startPos,
          endPos: endPos,
        });
        this.nameIdMap.set(`ladderGeom#${p.name}@${position}@${p. endPos.x}@${p. endPos.z}@${LadderDecorator.typeOfLadder}`, p.transientId);
        this.objectIdMap.set(p.transientId, `ladderGeom#${p.name}@${position}@${p. endPos.x}@${p. endPos.z}@${LadderDecorator.typeOfLadder}`);
        // push to array.
        this.pipes.push(p);

      }
      // This condition is used for keeping the Ladderdata sync with editing changes
      if(objInfo && objInfo.name && (objInfo.name.includes("FIRST")|| objInfo.name.includes("SECOND")) &&!objInfo.hasOwnProperty("startPoint"))this.setDataForLadder(objInfo)
    }

    }
    //Editing code ends here...
  }
  

//This function is used to save the changes of Edited pipe in the Utms if the points are not in utms
public setDataForLadder = (objInfo) => {
  let allInfo = objInfo.name.split("_");
  let name = allInfo[0];
  let index = allInfo[1]=="FIRST"?0:2;
  let ladderIndex = LadderDecorator.ladderData.findIndex(ladder=>ladder.ladderId == name)
  let copyLadderData = [...LadderDecorator.ladderData];
  let first = handleLocalToUtm(objInfo.startPos)
  let second = handleLocalToUtm(objInfo.endPos)
  copyLadderData[ladderIndex] = {
    ...copyLadderData[ladderIndex],coordinates:index==0?[
      {x:first.x,y:first.y,z:first.z,},
      { x:second.x,y:second.y,z:second.z,},
     { ...copyLadderData[ladderIndex].coordinates[2]},{... copyLadderData[ladderIndex].coordinates[3]}
    ]:[
      { ...copyLadderData[ladderIndex].coordinates[0]},{... copyLadderData[ladderIndex].coordinates[1]},
      { x:first.x,y:first.y,z:first.z,},
      {x:second.x,y:second.y,z:second.z,}
    ]
  }
    LadderDecorator.ladderData = copyLadderData
}
  public createPipeBetweenPoints(a: Point3d, b: Point3d, color: ColorDef, pname: string, objInfo: any) {//Visualisation helpers

    const pipe = Cone.createAxisPoints(a, b, 0.04, 0.04, true);//uses a cone to create a line
    const fstPt = a.interpolate(0.1, b);
    const secPt = b.interpolate(0.9, b);
    let mesh: any;
    if (pipe !== undefined) {
        const poly = PolyfaceBuilder.create();
        poly.addCone(pipe!);
        mesh = poly.claimPolyface(false);
    }
    // init CustomGeometry var with values.

    let points: Point3d[] = []
    points.push(a);
    points.push(b);//in this case only one point denoting the position
    const p = ({
        geometry: mesh,
        color: color,
        fill: true,
        fillColor:  ColorDef.blue,
        thickness:0.04,
        lineThickness: 0.5,
        edges: true,
        linePixels: LinePixels.Solid,
        length: fstPt.distance(secPt),
        transientId: IModelApp.viewManager.selectedView!.iModel.transientIds.next,      // generate and store a unique ID each time
        name: pname,
        modelData: objInfo,
        startPos: a,
        endPos: b,
        uid: 1 + "_editMid",
        
    });
   
   
    // this._nameIdMap.set(`${p.name}@${p.modelData.mountFace}@${p.modelData.parentMountType}@${p.modelData.parentMount}`, p.transientId);
    // push to array.
    this.pipes.push(p);

    IModelApp.viewManager.invalidateCachedDecorationsAllViews(this);//call for update of screen as viewmanger decorators as well.
    // this.needUpdate = true;//change this to true , this will invoke an update for this decorator and rebind the primitives to buffer/cache in createGraphics().
}

  // -------------------------------------------------------
  /*
   * Takes in data that was fetched in fetchData().
   * and uses it to place pipe geometry using the
   * start and end points.
   * Note: will need value conversions for Imodel scene.
   * Works fine for reality model scene.
  */
  public clearAndReload(data: any) {
    this.terminate();
    this.useDataAndLoadPipes(data,["All"]);
  }
  // -------------------------------------------------------
  /*
   * Takes in data that was fetched in fetchData().
   * and uses it to place pipe geometry using the
   * start and end points.
   * Note: will need value conversions for Imodel scene.
   * Works fine for reality model scene.
  */
  public useDataAndLoadPipes(data: any, selectedElevations: string[] = ["All"]) {
    // todo: Praful: It's not correct way to keep the mount name, please read the mount names
    if (data) {
      // selectedElevations = SampleToolWidget.sampleToolWidget.state.operatorFilterData.selectedMounts.length ? SampleToolWidget.sampleToolWidget.state.operatorFilterData.selectedMounts: selectedElevations;
      // if(selectedElevations[0] != "All")this.terminate();
      LadderDecorator.typeOfLadder = selectedElevations[0];
      let towerData:any = null;
      LadderDecorator.ladderData = selectedElevations[0]=="NEW"?[...LadderDecorator.ladderData,...data]: [...data]
       const ladderData =  data;
      const thickness: number = 0.05;
      const iModel = UiFramework.getIModelConnection()!;
      // let statusCheck : number = 0;//status iterator
      ladderData.forEach((md: any): any => {

        md.coordinates.forEach((coord,index)=>{
          let cA = new Point3d(coord.x,coord.y, coord.z);
          let cB = new Point3d( coord.x, coord.y, coord.z);
            let check = false
       
             if(index<3 && index!==1){
                 cB = new Point3d( md.coordinates[index+1].x, md.coordinates[index+1].y, md.coordinates[index+1].z);
                 check = true
                }
         

            
                const o = iModel.spatialToCartographicFromEcef(iModel.projectExtents.high!);
                const ecefA = DecoratorHelper.ExtractSpatialXYZ(o, cA.x, cA.y, cA.z, iModel);
                const ecefB = DecoratorHelper.ExtractSpatialXYZ(o, cB.x, cB.y, cB.z, iModel);
                const dot = ecefA.dotVectorsToTargets(new Point3d(ecefB.x, ecefB.y, 0), ecefB);
                const lengthA = ecefA.distance(ecefB);
                const lengthB = ecefA.distance(new Point3d(ecefB.x, ecefB.y, 0));
                const theta = Math.acos(dot / (lengthA * lengthB));
                const deg = theta * 180 / Math.PI; // 1Rad × 180/π
         
                // Adding realityData property to refer and populate the mount specific property values
                const realityData: any = {
                  name: "",
                  mountFace: "",
                  parentMount: md[0],
                //   parentMountType: mount.type,
                  length: (ecefA.distance(ecefB).toFixed(2)).toString(),
                  size: 0.06.toString(),
                  startPoint: ecefA,
                  endpoint: ecefB,
                  startPos: cA,
                  endPos: cB,
                  orientation: "Vertical",
                  // If lateral orentation
                //   lateralOffset: (legOFace[1].offset.LateralOffset.toFixed(2)).toString(),
                  tilt: deg.toFixed(2).toString(),
                  vacancy: "Vacant",
                  csEquipNames: "",
                  parentHorizontals: "",
                //   manufacturer: md[0].manufacturer ?? "",
                //   model: md[0].model ?? "",
        
                  azimuth: "NA",
                  // azimuth : val[0][2].azimuth,
                //   altitude: altitude,
                //   elevation: elevation,
                  childVerticals: [],
                };
              if(check)  this.addPipe(ecefA, ecefB, thickness, ColorDef.green, md.ladderId.toString(), realityData,index<2?"_FIRST":"_SECOND"); // Load up a pipe for each entry
            
        })
 
      });
    }

    // Set the color right after pipes with correct dimensions are created
    // this.colorisePipes(equipData); // Change color as per spec. Blue is default
    // IModelApp.viewManager.selectedView?.invalidateCachedDecorations(this);
    return true;
  }
  // -------------------------------------------------------
  /*
  * Load up the geometry info for pipes into a builder object and
  * return a graphic object and store it in the geometryGraphics[].
  * These grapic objects will be added to the the context via context.addDecoration()
  * Utilised in deocrate overload See: in decorate();
  */
  private createGraphics(context: DecorateContext) {
    // Check if Geometry Graphic objects are created and if not then load up in array.
    if (this.geometryGraphics.length === 0) { // Iterate over each pipe object loaded with the imported jason params and build the graphics for it.
      for (const pipe of this.pipes) {
        let transientId = context.viewport.iModel.transientIds.next;
        if (pipe.transientId === "") {
          pipe.transientId = transientId;
          this.nameIdMap.set(`ladderGeom#${pipe.name}`, pipe.transientId);
          this.objectIdMap.set(pipe.transientId, `ladderGeom#${pipe.name}`);
        } else {
          transientId = pipe.transientId;
        }
        // Speicify unique transientId so that geometry are considered as sperate entites.
        const builder = context.createGraphicBuilder(GraphicType.Scene, Transform.identity, transientId);
        // builder.wantNormals = true;
        //
        const geometry = pipe.geometry;
        // let fillColor: ColorDef = ColorDef.white;
        // builder.setSymbology(ColorDef.black,pipe.fillColor, 1, LinePixels.Code0);//sets geometry asthetics
        builder.setBlankingFill(pipe.fillColor);
        this.createGraphicsForGeometry(geometry, false, builder);
        //
        const graphic = builder.finish();
        this.geometryGraphics.push(graphic); // create graphic obj and push to array to be used in a GraphicBranch
      }
      
      // render torus for circular mounts
      this.tori.forEach((styledGeometry: CircularMountGeometry) => {
        if (styledGeometry.transientId === "") {
          styledGeometry.transientId = context.viewport.iModel.transientIds.next;
        }

        const builder = context.createGraphicBuilder(GraphicType.Scene, Transform.identity, styledGeometry.transientId);
        // builder.wantNormals = true;
        builder.setBlankingFill(styledGeometry.fillColor);
        // builder.setSymbology(styledGeometry.color, styledGeometry.fillColor, styledGeometry.lineThickness, styledGeometry.linePixels);
        this.createGraphicsForGeometry(styledGeometry.geometry!, styledGeometry.edges, builder);
        const graphic = builder.finish();
        this.geometryGraphics.push(graphic);
      });
    }   
    // If the Graphics are already created  and geometryGraphics array length is greater than 0 then
    // add all the grapics objects as decorations to the scene. This helps avoid having to bind primitive data into
    // buffers each time decorate is called which is costly.
    // Also see : useCachedDecorations that implicityl re-uses buffers , rather than having to mange them here like so.



    
    for (const geo of this.geometryGraphics) {
      // Some branch object presets
      const overrides = {};
      // const overrides = new ViewFlagOverrides();
      // overrides.setShowVisibleEdges(false);
      // overrides.setApplyLighting(true);

      // Create new SceneGraph //dont know why we do this every time seems inefficient, but it is as per docs.
      const branch = new GraphicBranch(false);
      branch.setViewFlagOverrides(overrides);
      // add to SceneGraph graphic branch Note : this is a workaround to improve not having to rebind geometry info.
      branch.add(geo);
      const graphic = context.createBranch(branch, Transform.identity);

      context.addDecoration(GraphicType.Scene, graphic);
    }

  }
  // -------------------------------------------------------
  /*
  * This is a boilerplate for loading up all types of geometry queries
  * into the builder object . This function is a as per the IModel doc samples.
  * Keep as is.
  */
  private createGraphicsForGeometry(geometry: GeometryQuery, wantEdges: boolean, builder: GraphicBuilder) {
    if (geometry instanceof LineString3d) {
      builder.addLineString(geometry.points);
    } else if (geometry instanceof Loop) {
      builder.addLoop(geometry);
      if (wantEdges) {
        // Since decorators don't natively support visual edges,
        // We draw them manually as lines along each loop edge/arc
        builder.setSymbology(ColorDef.black, ColorDef.black, 2);
        const curves = geometry.children;
        curves.forEach((value) => {
          if (value instanceof LineString3d) {
            let edges = value.points;
            const endPoint = value.pointAt(0);
            if (endPoint) {
              edges = edges.concat([endPoint]);
            }
            builder.addLineString(edges);
          } else if (value instanceof Arc3d) {
            builder.addArc(value, false, false);
          }
        });
      }
    } else if (geometry instanceof Path) {
      builder.addPath(geometry);
    } else if (geometry instanceof IndexedPolyface) {
      builder.addPolyface(geometry, true); // this is what will be used to generate the pipes.
      if (wantEdges) {
        // Since decorators don't natively support visual edges,
        // We draw them manually as lines along each facet edge
        builder.setSymbology(ColorDef.black, ColorDef.black, 2);
        const visitor = IndexedPolyfaceVisitor.create(geometry, 1);
        let flag = true;
        while (flag) {
          const numIndices = visitor.pointCount;
          for (let i = 0; i < numIndices - 1; i++) {
            const point1 = visitor.getPoint(i);
            const point2 = visitor.getPoint(i + 1);
            if (point1 && point2) {
              builder.addLineString([point1, point2]);
            }
          }
          flag = visitor.moveToNextFacet();
        }
      }
    } else if (geometry instanceof LineSegment3d) {
      const pointA = geometry.point0Ref;
      const pointB = geometry.point1Ref;
      const lineString = [pointA, pointB];
      builder.addLineString(lineString);
    } else if (geometry instanceof Arc3d) {
      builder.addArc(geometry, false, false);
    } else if (geometry instanceof CurveChainWithDistanceIndex) {
      this.createGraphicsForGeometry(geometry.path, wantEdges, builder);
    }
  }

  private getTowerCenterAtAltitude(towerData, altitude) {
    let x_deviation = (altitude - towerData['base_altitude']) * Math.tan(this.degrees_to_radians(towerData["tower_tilt_x"]));
    let y_deviation = (altitude - towerData['base_altitude']) * Math.tan(this.degrees_to_radians(towerData["tower_tilt_y"]));
    let x, y, z;
    [x, y, z] = [towerData['base_center'][0] + x_deviation,
    towerData['base_center'][1] + y_deviation,
      altitude];
    return [x, y, z];
  }
  
  private degrees_to_radians(angleInDegree) {
    let angleInRadian = angleInDegree * Math.PI / 180;
    return angleInRadian;
  }
  private async getTowerStructureData(){
    // const token = App.accessToken;
    const towerStructureData = store.getState().detectedData.towerStructureData;
    // const towerStructureData = await EquipmentClient.getTowerStructureJson(token);
    return towerStructureData;
  }
  private createTorusPipe(origin:Point3d, outerRadius: number, innerRadius: number,color: ColorDef, nameId: string, mountName: string, objInfo: any = {}) {
    const torusPipe = TorusPipe.createAlongArc(Arc3d.createXY(new Point3d(origin.x, origin.y, origin.z + innerRadius), outerRadius, AngleSweep.create(Angle.createDegrees(360))), innerRadius, false);
    const uniqName = `Circular Mount@Circular Face@${objInfo.parentMountType}@${objInfo.parentMount}`;
    const torusData: CircularMountGeometry = {
      mount: mountName,
      color: color,
      torus: torusPipe!,
      edges: false,
      fill: true,
      fillColor: ColorDef.fromTbgr(ColorDef.withTransparency(color.tbgr, 255 - Math.round(255 * store.getState().detectedData.objectOpacityState.mount.value))),
      linePixels: LinePixels.Solid,
      lineThickness: 1,
      name: nameId+"_edit",
      modelData: objInfo,
      uid: uniqName,
      transientId: IModelApp.viewManager.selectedView!.iModel.transientIds.next, // generate and store a unique ID each time
    };
    if (torusPipe) {
      const builder = PolyfaceBuilder.create();
      builder.addTorusPipe(torusPipe);
      const polyface = builder.claimPolyface(false);
      torusData.geometry = polyface;
      this.tori.push(torusData);
      this.nameIdMap.set(`ladderGeom#${torusData.name}`, torusData.transientId);
      this.objectIdMap.set(torusData.transientId, `ladderGeom#${torusData.name}`);
      // this.nameIdMap.set(`${p2.name}@${p2.modelData.mountFace}@${p2.modelData.parentMountType}@${p2.modelData.parentMount}`, p2.transientId);
    }

  }

  public updatePipeSelectedForEdit = () => {
    const md = IModelApp.viewManager.decorators.filter(e=>e.constructor.name.includes("LadderDecorator"))[0] as LadderDecorator;
    let ji = md.pipes.findIndex((e) => e.uid.includes(md.selectedLadder.uid));
    
    //Deleting previously editing pipe and adding selected pipe
    let i = md.pipes.findIndex((e) => e.uid.includes("_editMid"));
    if(i > -1  && !md.selectedLadder.uid.includes("_edit")){
      const prevEditingPipe = md.pipes[i];
      const newPipeToBeEdited = md.pipes[ji];
      if(ji!==-1){
        md.nameIdMap.delete(md.nameIdMap.get(md.pipes[ji].transientId) as string);
        md.pipes.splice(ji, 1);
      }
      do{
        i = md.pipes.findIndex((e) => e.uid.includes("_edit"));
        if(i!==-1){
          md.nameIdMap.delete(md.nameIdMap.get(md.pipes[i].transientId) as string);
          md.pipes.splice(i, 1);
        }
      } while(i !== -1);
      LadderDecorator.editing=true;

      md.addPipe(newPipeToBeEdited.startPos, newPipeToBeEdited.endPos, newPipeToBeEdited.thickness, newPipeToBeEdited.fillColor, newPipeToBeEdited.name, newPipeToBeEdited);
      md.addPipe(prevEditingPipe.startPos, prevEditingPipe.endPos, prevEditingPipe.thickness, prevEditingPipe.fillColor, prevEditingPipe.name.split("_edit")[0].split("@")[0], prevEditingPipe);
      

    } else if(!md.selectedLadder.uid.includes("_edit")){
      let ji = md.pipes.findIndex((e) => e.uid.includes(md.selectedLadder.uid));
      LadderDecorator.editing=true;
      if(ji!==-1){
        const newPipeToBeEdited = md.pipes[ji];
        md.nameIdMap.delete(md.nameIdMap.get(md.pipes[ji].transientId) as string);
        md.pipes.splice(ji, 1);
        md.addPipe(newPipeToBeEdited.startPos, newPipeToBeEdited.endPos, newPipeToBeEdited.thickness,newPipeToBeEdited.fillColor, newPipeToBeEdited.name, newPipeToBeEdited.modelData);
      }
    }
    let names = this.selectedLadder.name.split(["_"]);
    let name = names[1] == "FIRST"?names[0]+"_SECOND":names[0]+"_FIRST";
    let otherLadderPipe:any = this.pipes.find(pipe=>pipe.name == name)
    this.otherSelectedLadder = otherLadderPipe;
    //Redrawing the Mount geometries
    const allPipes = md.pipes;
    const allNameIds = md.nameIdMap;
    md.terminate();
    md.pipes = allPipes;
    md.nameIdMap = allNameIds;
    IModelApp.viewManager.invalidateDecorationsAllViews();
    IModelApp.viewManager.selectedView?.invalidateCachedDecorations(md);
    store.dispatch(setLadderData3DMaps(new Map(md.nameIdMap)));
    store.dispatch(addToBuilt3DObjectIdsMap(new Map(md.objectIdMap)));
  }
  
  // -------------------------------------------------------
  // CALLBACKS
  // -------------------------------------------------------
  /*
  * Ovveride from Decorator parent.
  * Is called to validate Decoratror objects to draw in the current view context.
  * Note :
  * Not a real render callback/loop not usable for Animations,
  * as the loop is not syncronus and runs intermittently, or when the current view comes into focus 'ie on mousclick or mouse move'.
  */
  public decorate(context: DecorateContext): void {
    // Create and add the Geometry to Context
    
    this.createGraphics(context);
    // Bind markers to Context to be drawn.
    // for (let i = 0; i < this.markerSet.length; i++) {this.markerSet[i].addDecoration(context); }
  }
  // -------------------------------------------------------
  /*
  * Override
  * called when decorator object get clicked on
  * will return  the refrence of the hit obect
  */
  public async onDecorationButtonEvent(hit: HitDetail, _ev: BeButtonEvent): Promise<EventHandled> {
    const sourceId = hit.sourceId; // not utilised
    // -----
    if (_ev.isDoubleClick) {
      // SyncUiEventDispatcher.dispatchSyncUiEvent("whole-mount-selected");
      UiFramework.frontstages.activeFrontstageDef?.getStagePanelDef(StagePanelLocation.Right)?.findWidgetDef("PropertyListWidget")?.setWidgetState(WidgetState.Open);
      IModelApp.tools.run(SelectionTool.toolId);
      // SampleToolWidget.selectedList = ListEnum.FullMount;
    } else {
      // SyncUiEventDispatcher.dispatchSyncUiEvent("mount-selected");
      // SampleToolWidget.selectedList = ListEnum.Mount;
      const isEditActive = store.getState().dtvState.applicationState.isEditModeActive;
      LadderDecorator.editing = true;
      // this.updatePipeSelectedForEdit();

      UiFramework.frontstages.activeFrontstageDef?.getStagePanelDef(StagePanelLocation.Right)?.findWidgetDef("PropertyListWidget")?.setWidgetState(WidgetState.Open);
      IModelApp.tools.run(SelectionTool.toolId);
      // if (SampleToolWidget.selectedList === ListEnum.Mount && this.selectedMount?.uid?.includes("_editFront") || this.selectedMount?.uid?.includes("_editMid") || this.selectedMount?.uid?.includes("_editEnd")) IModelApp.tools.run(EditMountMouseHandlers.toolId, this.selectedMount);
    }
    // -----
    return EventHandled.No;
  }

  public testDecorationHit(hitid: string): boolean {

    // Checking for the whole mount select event id in dispatched events list
  
    // Setting the SelectedMount property
    let selectedLadder: boolean = false;
    for (const pipe of this.pipes) {
      if (pipe.transientId === hitid) {
        this.selectedLadder = pipe; // static member used in MountPropertylist.tsx
        selectedLadder = true;
        
        this.previousSelection = {name: pipe.name, startPos: new Point3d(pipe.startPos.x, pipe.startPos.y, pipe.startPos.z), endPos: new Point3d(pipe.endPos.x, pipe.endPos.y, pipe.endPos.z)};

        break;
      }
    }
    for (const torus of this.tori) {
      if (torus.transientId === hitid) {
        this.selectedLadder = torus; // static member used in MountPropertylist.tsx
        selectedLadder = true;
        break;
      }
    }
    // // --Hilit all pipes //Disabled for now, will be used later
    // UiFramework.getIModelConnection()!!.selectionSet.emptyAll();
    // for (let t = 0 ; t < this.pipes.length; t++) {
    //   UiFramework.getIModelConnection()!!.selectionSet.add(this.pipes[t].transientId);
    // }
    // Needed for onDecorationButtonEvent() to execute;

    if (selectedLadder && hitid.length !== 0) {
      return true;
    }
    return false;
  }

  //----------------------------------------------------------------------

  /**
   * update pipe position
   * @param _name name of pipe
   * @param _changePosVector change vector
   * @returns success if true
   */
  public updateCylinderPosition(_name: string, _changePosVector: Vector3d, selection: string): boolean {
    const jsonIndex = this.pipes.findIndex((e) => e.uid.includes("_editMid"));
    if (jsonIndex === -1) return false;

    const decorator = IModelApp.viewManager.decorators.filter(e => e.constructor.name.includes("LadderDecorator"))[0] as LadderDecorator;
    const thePipe = decorator.pipes[jsonIndex];

    //deleting existing edit member
    let i = decorator.pipes.findIndex((e) => e.uid.includes("_edit"));
    if (i > -1) {
      const prevEditingPipe = decorator.pipes[i];
      //Deleting old editing pipe and add it too
      do {
        i = decorator.pipes.findIndex((e) => e.uid.includes("_edit"));
        if (i !== -1) {
          decorator.nameIdMap.delete(decorator.nameIdMap.get(decorator.pipes[i].transientId) as string);
          decorator.pipes.splice(i, 1);
        }
      } while (i !== -1);
    }


    const allPipes = decorator.pipes;
    const allNameIdMaps = decorator.nameIdMap;
    if (thePipe) {
      if (selection === "Whole") {
        thePipe.startPos.x += _changePosVector.x;
        thePipe.startPos.y += _changePosVector.y;
        thePipe.startPos.z += _changePosVector.z;
        thePipe.endPos.x   += _changePosVector.x;
        thePipe.endPos.y   += _changePosVector.y;
        thePipe.endPos.z   += _changePosVector.z;
      } else if (selection === "Start") {
        thePipe.startPos.x += _changePosVector.x;
        thePipe.startPos.y += _changePosVector.y;
        thePipe.startPos.z += _changePosVector.z;
      } else if (selection === "End") {
        thePipe.endPos.x += _changePosVector.x;
        thePipe.endPos.y += _changePosVector.y;
        thePipe.endPos.z += _changePosVector.z;
      }
      decorator.terminate();
      decorator.pipes = allPipes;
      decorator.nameIdMap = allNameIdMaps;
      decorator.addPipe(thePipe.startPos, thePipe.endPos, thePipe.thickness, thePipe.fillColor, thePipe.name.split("_edit")[0].split("@")[0], thePipe);
    }
    IModelApp.viewManager.invalidateDecorationsAllViews();
    IModelApp.viewManager.selectedView?.invalidateCachedDecorations(decorator);
    // UiFramework.getIModelConnection()!!.selectionSet.emptyAll();
    // const id = this.nameIdMap.get(`ladderGeom#${_name}`)
    // if(id)UiFramework.getIModelConnection()!.selectionSet.add(id as string);
    return false;
  }
  /**
   * Updtae Cylinder Radius
   * @param _name name of Cylinder
   * @param _change increase cylinder radius with val
   * @returns true if successful
   */
  public updateCylinderRadius(_name: string, _change: number): boolean {
    const jsonIndex = this.pipes.findIndex((e) => e.uid.includes(_name));
    if (jsonIndex === -1) return false;
    const decorator = IModelApp.viewManager.decorators.filter(e => e.constructor.name.includes("LadderDecorator"))[0] as LadderDecorator;
    const thePipe = decorator.pipes[jsonIndex];
    decorator.pipes.splice(jsonIndex, 1);
    const allPipes = decorator.pipes;
    const allNameIdMaps = decorator.nameIdMap;

    let i = decorator.pipes.findIndex((e) => e.uid.includes("_edit"));
    if (i > -1) {
      const prevEditingPipe = decorator.pipes[i];
      //Deleting old editing pipe and add it too
      do {
        i = decorator.pipes.findIndex((e) => e.uid.includes("_edit"));
        if (i !== -1) {
          decorator.nameIdMap.delete(decorator.nameIdMap.get(decorator.pipes[i].transientId) as string);
          decorator.pipes.splice(i, 1);
        }
      } while (i !== -1);
    }

    if (thePipe) {
      const radius = (thePipe.thickness + _change <= 0.03 && _change < 0) ? 0.03 : thePipe.thickness + _change;
      decorator.terminate();
      decorator.pipes = allPipes;
      decorator.nameIdMap = allNameIdMaps;
      const name = thePipe.name.split("_edit")[0].split("@")[0];
      decorator.addPipe(thePipe.startPos, thePipe.endPos, radius, thePipe.fillColor, name, thePipe);
    }
    IModelApp.viewManager.invalidateDecorationsAllViews();
    IModelApp.viewManager.selectedView?.invalidateCachedDecorations(decorator);
    (IModelApp as any).listCallback(ListEnum.Mount);
    // UiFramework.getIModelConnection()!!.selectionSet.emptyAll();
    // const id = this.nameIdMap.get(_name)
    // if(id)UiFramework.getIModelConnection()!!.selectionSet.add(id as string);
    return false;
  }
  /**
   * Update Cylinder Thickness
   * @param _name cylinder name
   * @param _change change value from Widget
   * @returns true or false depending on success or failure
   */
  public updateCylinderThickness(_name: string, _change: number): boolean {
    const jsonIndex = this.pipes.findIndex((e) => e.uid.includes(_name));
    if (jsonIndex === -1) return false;
    const decorator = IModelApp.viewManager.decorators.filter(e => e.constructor.name.includes("LadderDecorator"))[0] as LadderDecorator;
    const thePipe = decorator.pipes[jsonIndex];

    let i = decorator.pipes.findIndex((e) => e.uid.includes("_edit"));
    if (i > -1) {
      const prevEditingPipe = decorator.pipes[i];
      //Deleting old editing pipe and add it too
      do {
        i = decorator.pipes.findIndex((e) => e.uid.includes("_edit"));
        if (i !== -1) {
          decorator.nameIdMap.delete(decorator.nameIdMap.get(decorator.pipes[i].transientId) as string);
          decorator.pipes.splice(i, 1);
        }
      } while (i !== -1);
    }

    const allPipes = decorator.pipes;
    const allNameIdMaps = decorator.nameIdMap;
    if (thePipe) {
      const fstPt = thePipe.startPos.interpolate(-_change / 2, thePipe.endPos);
      const secPt = thePipe.startPos.interpolate(1 + _change / 2, thePipe.endPos);

      decorator.terminate();
      decorator.pipes = allPipes;
      decorator.nameIdMap = allNameIdMaps;
      const name = thePipe.name.split("_edit")[0].split("@")[0];
      decorator.addPipe(fstPt, secPt, thePipe.thickness, thePipe.fillColor, name, thePipe);
    }
    IModelApp.viewManager.invalidateDecorationsAllViews();
    IModelApp.viewManager.selectedView?.invalidateCachedDecorations(decorator);
    // (IModelApp as any).listCallback(ListEnum.Mount);
    // UiFramework.getIModelConnection()!!.selectionSet.emptyAll();
    // const id = this.nameIdMap.get(_name)
    // if(id)UiFramework.getIModelConnection()!!.selectionSet.add(id as string);
    return false;

    }
    public updateCylinderThicknessOfAnotherLadderPipe(_name: string, _change: number): boolean {
      const jsonIndex = this.pipes.findIndex((e) => e.uid.includes(_name));
      if (jsonIndex === -1) return false;
      const decorator = IModelApp.viewManager.decorators.filter(e => e.constructor.name.includes("LadderDecorator"))[0] as LadderDecorator;
      const thePipe = decorator.pipes[jsonIndex];
  
      let i = decorator.pipes.findIndex((e) => e.uid.includes(_name));
      if (i > -1) {
        const prevEditingPipe = decorator.pipes[i];
        //Deleting old editing pipe and add it too
        do {
          i = decorator.pipes.findIndex((e) => e.uid.includes(_name));
          if (i !== -1) {
            decorator.nameIdMap.delete(decorator.nameIdMap.get(decorator.pipes[i].transientId) as string);
            decorator.pipes.splice(i, 1);
          }
        } while (i !== -1);
      }
  
      const allPipes = decorator.pipes;
      const allNameIdMaps = decorator.nameIdMap;
      if (thePipe) {
        const fstPt = thePipe.startPos.interpolate(-_change / 2, thePipe.endPos);
        const secPt = thePipe.startPos.interpolate(1 + _change / 2, thePipe.endPos);
  
        decorator.terminate();
        decorator.pipes = allPipes;
        decorator.nameIdMap = allNameIdMaps;
        const name = thePipe.name.split("_edit")[0].split("@")[0];
                decorator.addPipe(fstPt, secPt, thePipe.thickness, thePipe.fillColor, name, thePipe);
      }
      IModelApp.viewManager.invalidateDecorationsAllViews();
      IModelApp.viewManager.selectedView?.invalidateCachedDecorations(decorator);
      // (IModelApp as any).listCallback(ListEnum.Mount);
      // UiFramework.getIModelConnection()!!.selectionSet.emptyAll();
      // const id = this.nameIdMap.get(_name)
      // if(id)UiFramework.getIModelConnection()!!.selectionSet.add(id as string);
      return false;
  
    }
  // -------------------------------------------------------
}
