// import { IModelApp, NotifyMessageDetails, OutputMessagePriority, OutputMessageType } from "@bentley/imodeljs-frontend";

import { AccessToken } from "@itwin/core-bentley";
import { ConfigManager } from "../../config/ConfigManager";
import { store } from "../../store/rootReducer";

export interface feedlineDataStructure {
    clusterId: number,
    width: number,
    height: number,
    startCenter: {
      x: number,
      y: number,
      z: number
},
    endCenter: {
      x: number,
      y: number,
      z: number
},    orientation: number,
    segmentPoints: 
      {
        x: number,
        y: number,
        z: number
      }[] | [] | null
  }

export default class FeederLineClient {
    constructor() { }
    /*
     * 
    */
    public static async getFeedLineData(): Promise<any> {
        const token: AccessToken = store.getState().auth.accessTokenState.accessToken!;
        const projectId = ConfigManager.projectId;
        const url: string = `${ConfigManager.openToweriQUrl}/v1.0/${projectId}/Site/Feedline/Cluster`;
        return fetch(url, {
            method: "get",
            headers: {
                Authorization: token,
            },
        }).then(async (response: Response) => {
            if (!response.ok) {
                return null;
            }
            return response.json();
        }).then((data: any) => {
            return data;
        });
    }

    public static async putFeedLineData(feedlineData: feedlineDataStructure): Promise<any> {
        const token: AccessToken = store.getState().auth.accessTokenState.accessToken!;
        const projectId = ConfigManager.projectId;
        const url: string = `${ConfigManager.openToweriQUrl}/v1.0/${projectId}/Site/Feedline/Cluster/${feedlineData.clusterId}`;
        return fetch(url, {
          method: "put",
          headers: {
            Authorization: token,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(feedlineData),
        }).then(async (res: Response) => {
          if (!res.ok) {
            return null;
          }
          return "Successful";
        }).then((data: any) => {
          return data;
        });
    }

    public static async postFeedLineData(feedlineData: feedlineDataStructure): Promise<any> {
        const token: AccessToken = store.getState().auth.accessTokenState.accessToken!;
        const projectId = ConfigManager.projectId;
        const url: string = `${ConfigManager.openToweriQUrl}/v1.0/${projectId}/Site/Feedline/Cluster`;
        return fetch(url, {
          method: "post",
          headers: {
            Authorization: token,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(feedlineData),
        }).then(async (res: Response) => {
          if (!res.ok) {
            return null;
          }
          return "Successful";
        }).then((data: any) => {
          return data;
        });
    }


    public static async deleteFeedLineData(feedlineData: feedlineDataStructure[]): Promise<any> {
        const token: AccessToken = store.getState().auth.accessTokenState.accessToken!;
        const projectId = ConfigManager.projectId;
        const url: string = `${ConfigManager.openToweriQUrl}/v1.0/${projectId}/Site/Feedline/Clusters`;
        return fetch(url, {
          method: "post",
          headers: {
            Authorization: token,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(feedlineData),
        }).then(async (res: Response) => {
          if (!res.ok) {
            return null;
          }
          return "Successful";
        }).then((data: any) => {
          return data;
        });
    }

    //------------------------------------------------------------------
    // Get tower Structure data from database
    //------------------------------------------------------------------
    public static async getTowerStructureJson(token: string): Promise<any> {
        const projectId = ConfigManager.projectId;
        const url = `${ConfigManager.openToweriQUrl}/v1.0/${projectId}/Site/Structure`;
        return fetch(url, {
            method: "get",
            headers: {
                Authorization: token,
            },
        }).then(async (response: Response) => {
            if (!response.ok) {
                return null;
            }
            return response.json();
        }).then((data: any) => {
            return data;
        });
    }
}
