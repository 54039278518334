import { RenderMode } from "@itwin/core-common";
import { IModelApp } from "@itwin/core-frontend";
import { SyncUiEventDispatcher, UiFramework } from "@itwin/appui-react";
import { AddBoundingBoxTool } from "../../tools/AddBoundingBoxTool";
import { CloneBoundingBoxTool } from "../../tools/CloneBoundingBoxTool";
import { OutlineEquipmentTool } from "../../tools/OutlineEquipmentTool";
import DefectsClient from "../../../api/defects";
import { store } from "../../../../store/rootReducer";
import { MLDefectDecorator } from "../../../tools/decorators/MLDefectDecorator";
import { PinsDecorator } from "../../../tools/decorators/PinsDecorator";
import { DefectsTable } from "../../Tables/DefectsTable";
import { TableEnum } from "../../tools/getDefectsToolsMenuItems";

export default class ToolActionHandler {
  static defectDecorator: any;
  static pinsDecorator: any;
  constructor() {

  }
  public static highlightEquipment() {

    const vp = IModelApp.viewManager.selectedView;
    if (vp) {
      // const viewFlags = vp.viewFlags.clone();
      // viewFlags.renderMode = RenderMode.SmoothShade;
      // vp.viewFlags = viewFlags;
      vp.viewFlags = vp.viewFlags.copy({renderMode: RenderMode.SmoothShade});
    }

    IModelApp.tools.run(OutlineEquipmentTool.toolId);
  }

  public static async detectDefects() {
    if (this.defectDecorator) {
      SyncUiEventDispatcher.dispatchSyncUiEvent("defecttoolunselected");
      this.defectDecorator();
      this.defectDecorator = undefined;
    }

    else {
      const defects = await DefectsTable.getData(UiFramework.getIModelConnection()!);
      const decorator = new MLDefectDecorator(defects);
      this.defectDecorator = IModelApp.viewManager.addDecorator(decorator);
    }
    IModelApp.viewManager.selectedView?.invalidateDecorations();
  }
  public static async detectPins() {
    if (this.pinsDecorator) {
      SyncUiEventDispatcher.dispatchSyncUiEvent("pin-unselected");
      this.pinsDecorator();
      this.pinsDecorator = undefined;
    } else {
      const baseAlt = await DefectsClient.getBaseAltitude(store.getState().auth.accessTokenStatePrivateAPI.accessToken!);
      const defects = await DefectsTable.getPinsData(UiFramework.getIModelConnection()!);
      const decorator = new PinsDecorator(defects, baseAlt);
      this.pinsDecorator = IModelApp.viewManager.addDecorator(decorator);
    }
  }

  public static async toggleTable(tableEnum: TableEnum) {
    let table = tableEnum;
    // if (MainPage.isiModelExists) {
    //   if (SampleToolWidget.openedTable === tableEnum && (IModelApp as any).tableCallback) {
    //     (IModelApp as any).tableCallback(SampleToolWidget.lastOpenedTable!);
    //     return;
    //   }
    //   SampleToolWidget.lastOpenedTable = SampleToolWidget.openedTable;
    //   if ((IModelApp as any).tableCallback) {
    //     (IModelApp as any).tableCallback(tableEnum);
    //   }
    // } else {
    //   if (SampleToolWidget.openedTable === tableEnum) {
    //     SampleToolWidget.openedTable = undefined;
    //     SampleFrontstage.closeTableLayout();
    //     return;
    //   }
    //   SampleToolWidget.openedTable = tableEnum;
    //   SampleFrontstage.openTableLayout();
    // }
  }

  public static highlightEquipmentManually() {
    const vp = IModelApp.viewManager.selectedView;
    IModelApp.tools.run(AddBoundingBoxTool.toolId);
  }

  public static placeAntenna() {
    // SampleToolWidget.setEquipmentType(EquipmentType.Antenna);
    IModelApp.tools.run(AddBoundingBoxTool.toolId);
  }

  public static placeRRU() {
    // SampleToolWidget.setEquipmentType(EquipmentType.RRU);
    IModelApp.tools.run(AddBoundingBoxTool.toolId);
  }

  public static placeMicroWave() {
    // SampleToolWidget.setEquipmentType(EquipmentType.Microwave);
    IModelApp.tools.run(AddBoundingBoxTool.toolId);
  }

  public static highlightEquipmentByCloning() {
    const vp = IModelApp.viewManager.selectedView;
    IModelApp.tools.run(CloneBoundingBoxTool.toolId);
  }

  public static async deleteMarkedEquipment() {
    // await SampleToolWidget.deleteBox();
  }
}