import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
// import { NVRowsForPropEditModal} from '../components/Lists/PropertyEditModal'
import { getValueInRequiredUnit } from '../components/Tables/BaseTable'
import { ReduceDecimalNumberToTwoPlaces } from '../components/Lists/EquipmentList'
// import { UiFramework} from '@itwin/appui-react'
import { IModelApp} from '@itwin/core-frontend'
import { pecGridStructure, pecInputTagTypes, pecDataValueTypes, pecStrucType, PropertyEditorComponent, pecComponentProps, pecRows, pecColumn } from '../components/PropertyEditorComponent'
import { RootState } from '../../store/States'
import { unitSystemType } from '../../store/redux-types'
import { ConfigManager } from '../../config/ConfigManager'
import { AppSettings } from '../../config/AppSettings'
import { HighlightMountTool } from '../components/tools/HighlightMountTool'
import { mountingsSummaryData } from '../../store/detectedData/apiDataTypes'
import { resetObjectIds } from '../components/HorizontalToolbarItems'
import { MountDecorator } from './decorators/MountDecorator'
import { OutlineShapeTool } from '../components/tools/OutlineShapeTool'
import { ShapeDecorator } from './decorators/ShapeDecorator'
// interface OperatorInterface { showActionButton: boolean, showModal: boolean, values: NVRowsForPropEditModal[], existingMappings: any[], disabledToolTip: string | null }
interface FilterDataInt { mountName: string, highlighted: boolean, show: boolean };
export interface OperatorFilterDataInt { filterData: FilterDataInt[], selectedMounts: string[], selectedEquipment: string[] }


export const OperatorMountFilter = (props) => {
    const [opElEditData, setOpElEditData] = useState(props.opElDialogStruc);

    useEffect(()=>{
        IModelApp.tools.run(HighlightMountTool.toolId, props.mountsData, true, ["All"])        
        IModelApp.tools.run(OutlineShapeTool.toolId, ["All"], true);
    },[])

    const onPropertyModalChange = async (evt: any) => {
        evt.persist();
        const id = evt.target.id;
        const idSplit = id.split("-");
        const newRows = [...opElEditData.rows];

        switch (idSplit[0]) {
            case "checkbox": {
                newRows[idSplit[1]].columns[2].value = !newRows[idSplit[1]].columns[2].value;

                let selectedMount:string[] = [];
                let selectedPlats:string[] = [];

                newRows.forEach(row => {
                    if(row.columns.length > 1 && row.columns[2].value == true){
                        for (let index = 0; index < props.mountingsSummaryData.length; index++) {
                            if(props.mountingsSummaryData[index].elevation === parseFloat(row.columns[0].name)){
                                let mountName = `${props.mountingsSummaryData[index].name[0].toUpperCase() + props.mountingsSummaryData[index].name.substr(1).replace(/[^a-z]/g, "")}${parseInt(props.mountingsSummaryData[index].name.replace(/[^0-9]/g, ""))}`
                                selectedMount.push(mountName)
                                selectedPlats.push(props.mountingsSummaryData[index].name)
                            }
                        }
                        }
                    }
                )

                const retVal = await IModelApp.tools.run(HighlightMountTool.toolId, props.mountsData, true, selectedMount);
                if(retVal){
                    let md = IModelApp.viewManager.decorators.filter(e=>e.constructor.name.includes("MountDecorator"))[0] as MountDecorator;
                    if(md)resetObjectIds(md);
                }                    
                const retValEquipment = await IModelApp.tools.run(OutlineShapeTool.toolId, selectedPlats.length == 0 ? ["All"] : selectedPlats);
                if(retValEquipment){
                    let sd = IModelApp.viewManager.decorators.filter(e=>e.constructor.name.includes("ShapeDecorator"))[0] as ShapeDecorator;
                    if(sd)resetObjectIds(sd);
                }                    
            }
            break;
            default:
                break;
        }
        setOpElEditData({ ...opElEditData, rows: newRows });
    }

    const onModalBtnClick = async (evt: any) => {
        evt.persist();
        if (evt.target.name === "Close") {
            props.onClose();
        }
    }

    const modalBtns = [
        { name: "Close", value: "Close", class: "dialogBox-cancel-btn", disable: false, onInteract: onModalBtnClick }
    ];
    const stickyHeading = [
        {name: `Elevation ${props.unitSystem == unitSystemType.IMPERIAL ? "(Feet)" : "(Meters)"}*`, value: `Elevation ${props.unitSystem == unitSystemType.IMPERIAL ? "(Feet)" : "(Meters)"}`, classChild: "operator-elevation-heading-color float-child", classParent: "col-5"},
        {name: "Operator", value: "Operator", classChild: "operator-elevation-heading-color float-child", classParent: "col-5"},
        {name: "Show", value: "Show", classChild: "operator-elevation-heading-color float-child", classParent: "col-2"},
    ];


    const onFieldUpdate = (event) => {
        let currentEvent = event;
    }


    let opElEditorStructure: pecComponentProps = {
        structureType: pecStrucType.STRUCTURE,
        title: {
            label: "Operator Mount Filtering",
        },
        grid: opElEditData,
        gridButtons: modalBtns,
        gridStickyHeading: stickyHeading,
        eventHandlers: {
            onChange: onPropertyModalChange,
            onUpdate: onFieldUpdate,
            onClick: onModalBtnClick
        }
    }



    return (
        <PropertyEditorComponent {...opElEditorStructure} />
    )
}


const mapStateToProps = (state: RootState) => {
    const mountingsSummaryData = state.detectedData.mountingsSummaryData;
    let uniqueMounts: mountingsSummaryData[] = [mountingsSummaryData[0]];
      for (let index = 1; index < mountingsSummaryData.length; index++) {
        const element = mountingsSummaryData[index];
        if(element.elevation!=mountingsSummaryData[index-1].elevation){
          uniqueMounts.push(element);
        }
      }

    const retVal = {
        opElDialogStruc: {},
        // operators: state.detectedData.operatorsData,
        mountsData: state.detectedData.mountingsData,
        // siteCoordinates: state.detectedData.siteCoordinate,
        unitSystem: AppSettings.defaultDisplaySettings.unitSystem,
        // existingMappings: state.dtvState.applicationState.elevationOperatorsMapping,
        mountingsSummaryData
    };


    // let mappings = retVal.existingMappings, addMapping = false;
    // if (!mappings) addMapping = true;
    // const values: NVRowsForPropEditModal[] = [];
    // const opList = retVal.operators.map((e: any) => e.displayName);

    // const theElevations = retVal.existingMappings.length ? retVal.existingMappings : retVal.mountsData
    const convFactor = retVal.unitSystem == unitSystemType.IMPERIAL ? 3.2808399 : 1;
    let rows: pecRows[] = [];

    const towerdata = state.detectedData.towerStructureData;
    const concreteHeight = ConfigManager.AGL ? parseFloat(Object.entries(towerdata).filter((f) => f[0] === "concrete_height")[0][1]) * convFactor : 0;

    // if (retVal.existingMappings.length) {
    //     rows = retVal.existingMappings.map(e => {
    //         const mountName = `${e.mountName[0].toUpperCase() + e.mountName.substr(1).replace(/[^a-z]/g, "")} ${parseInt(e.mountName.replace(/[^0-9]/g, ""))}`;
    //         const elevationConverted = getValueInRequiredUnit(ReduceDecimalNumberToTwoPlaces(parseFloat(e.elevation)));
    //         const elevation = e.elevation;
    //         const columns: pecColumn[] = [
    //             {
    //                 size: 5,
    //                 type: pecInputTagTypes.LABEL,
    //                 customClass: "",
    //                 value: `${mountName} - ${(parseFloat(elevationConverted) + concreteHeight).toFixed(2)}`,
    //                 disabled: false,
    //                 name: `${mountName} - ${elevation}`,
    //                 fieldType: pecDataValueTypes.INTEGER,
    //                 modified: false,
    //                 styles: { parentClass: "", childClass: "" }
    //             },
    //             {
    //                 size: 4,
    //                 type: pecInputTagTypes.LABEL,
    //                 customClass: "",
    //                 value: e.operator ? (e.operator === "Select operator" ? "-" : e.operator) : "-",
    //                 disabled: false,
    //                 name: "operatorValue",
    //                 fieldType: pecDataValueTypes.INTEGER,
    //                 // data: optionsData,
    //                 modified: false,
    //                 styles: { parentClass: "", childClass: "" }
    //             },
    //             {
    //                 size: 2,
    //                 type: pecInputTagTypes.CHECKBOX,
    //                 customClass: "",
    //                 value: false,
    //                 disabled: false,
    //                 name: "Highlight",
    //                 fieldType: pecDataValueTypes.BOOLEAN,
    //                 // data: optionsData,
    //                 modified: false,
    //                 styles: { parentClass: "", childClass: "" }
    //             }
    //         ];

    //         return {
    //             columns
    //         }

    //     });

    // } else if (retVal.mountsData.mounts) {
    //     Object.entries(retVal.mountsData.mounts).sort().forEach((e: any) => {
    //         const mountName = `${e[0][0].toUpperCase() + e[0].substr(1).replace(/[^a-z]/g, "")} ${parseInt(e[0].replace(/[^0-9]/g, ""))}`;
    //         const elevation = getValueInRequiredUnit(ReduceDecimalNumberToTwoPlaces((e[1].utm_altitude - retVal.siteCoordinates.utm.z)));
    //         if (addMapping) mappings.push({ elevation, operator: "", mountName: e[0] });
    //         const columns: pecColumn[] = [
    //             {
    //                 size: 5,
    //                 type: pecInputTagTypes.LABEL,
    //                 customClass: "",
    //                 value: `${mountName} - ${(parseFloat(elevation) + concreteHeight).toFixed(2)}`,
    //                 disabled: false,
    //                 name: `${mountName} - ${elevation}`,
    //                 fieldType: pecDataValueTypes.INTEGER,
    //                 modified: false,
    //                 styles: { parentClass: "", childClass: "" }
    //             },
    //             {
    //                 size: 4,
    //                 type: pecInputTagTypes.LABEL,
    //                 customClass: "",
    //                 value: e.operator ? (e.operator === "Select operator" ? "-" : e.operator) : "-",
    //                 disabled: false,
    //                 name: "operatorValue",
    //                 fieldType: pecDataValueTypes.INTEGER,
    //                 // data: optionsData,
    //                 modified: false,
    //                 styles: { parentClass: "", childClass: "" }
    //             },
    //             {
    //                 size: 2,
    //                 type: pecInputTagTypes.CHECKBOX,
    //                 customClass: "",
    //                 value: false,
    //                 disabled: false,
    //                 name: "Highlight",
    //                 fieldType: pecDataValueTypes.BOOLEAN,
    //                 // data: optionsData,
    //                 modified: false,
    //                 styles: { parentClass: "", childClass: "" }
    //             }
    //         ]

    //         rows.push({
    //             columns
    //         });
    //     });
    // }

    
    rows = uniqueMounts.map(e => {
        const elevationConverted = getValueInRequiredUnit(ReduceDecimalNumberToTwoPlaces(parseFloat(e.elevation.toString())));
        const elevation = e.elevation;
        const columns: pecColumn[] = [
            {
                size: 5,
                type: pecInputTagTypes.LABEL,
                customClass: "",
                value: (parseFloat(elevationConverted) + concreteHeight).toFixed(2),
                disabled: false,
                name: elevation.toString(),
                fieldType: pecDataValueTypes.INTEGER,
                modified: false,
                styles: { parentClass: "operator-filter-label-parent", childClass: "" }
            },
            {
                size: 4,
                type: pecInputTagTypes.LABEL,
                customClass: "",
                value: e.operator ? (e.operator === "Select operator" ? "-" : e.operator) : "-",
                disabled: false,
                name: "operatorValue",
                fieldType: pecDataValueTypes.INTEGER,
                modified: false,
                styles: { parentClass: "", childClass: "" }
            },
            {
                size: 2,
                type: pecInputTagTypes.CHECKBOX,
                customClass: "",
                value: true,
                disabled: false,
                name: "Highlight",
                fieldType: pecDataValueTypes.BOOLEAN,
                modified: false,
                styles: { parentClass: "", childClass: "" }
            }
        ];

        return {
            columns
        }

    });


    // const unitSuffix = retVal.unitSystem == unitSystemType.IMPERIAL ? "(Feet)" : "(Meters)";

    // rows.unshift({
    //     columns: [
    //         {
    //             size: 5,
    //             type: pecInputTagTypes.HEADING,
    //             customClass: "",
    //             value: `Elevation ${retVal.unitSystem}`,
    //             disabled: false,
    //             name: `Elevation ${unitSuffix} *`,
    //             fieldType: pecDataValueTypes.INTEGER,
    //             modified: false,
    //             styles: { parentClass: "operator-filter-heading", childClass: "operator-elevation-heading-color" }
    //         },
    //         {
    //             size: 4,
    //             type: pecInputTagTypes.HEADING,
    //             customClass: "",
    //             value: "Operator",
    //             disabled: false,
    //             name: "Operator",
    //             fieldType: pecDataValueTypes.INTEGER,
    //             //   data: optionsData,
    //             modified: false,
    //             styles: { parentClass: "operator-filter-heading", childClass: "operator-elevation-heading-color" }
    //         },
    //         {
    //             size: 2,
    //             type: pecInputTagTypes.HEADING,
    //             customClass: "",
    //             value: "Show",
    //             disabled: false,
    //             name: "Show",
    //             fieldType: pecDataValueTypes.INTEGER,
    //             // data: optionsData,
    //             modified: false,
    //             styles: { parentClass: "operator-filter-heading", childClass: "operator-elevation-heading-color" }
    //         }
    //     ]

    // })

    rows.unshift({
        columns: [
            {
                size: 12,
                type: pecInputTagTypes.LABEL,
                customClass: "",
                value: ConfigManager.AGL ? "*: Above ground level (AGL) is enabled and applied." : "*: Above ground level (AGL) is disabled.",
                disabled: false,
                name: "agl",
                fieldType: pecDataValueTypes.INTEGER,
                modified: false,
                styles: { parentClass: "", childClass: "" },
                data: "AGL on left of Button"
            }
        ]
    })


    const opElDialogStruc: pecGridStructure = {
        rows
    }
    retVal.opElDialogStruc = opElDialogStruc;
    return retVal;
}


const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(OperatorMountFilter)