import { ApolloClient, OperationVariables, InMemoryCache, ApolloLink, DocumentNode, HttpLink } from "@apollo/client";
import { RootState } from "../../store/States";
import { store } from "../../store/rootReducer";
import { AccessToken } from "@itwin/core-bentley";
import { ConfigManager } from "../../config/ConfigManager";
import { Logger } from "../../logging/logging";
// import { ClientRequestContext } from "@itwin/core-bentley";

let accessToken: AccessToken;
const listener = () => {
    setToken(store.getState());
}

function setToken(state: RootState) {
    accessToken = state.auth.accessTokenStatePrivateAPI.accessToken!;
}

store.subscribe(listener);

 const getTowerSightBFFUrl=()=> {
    let towerSightBFFUrl: URL;
    //     if (
    //         window.location.origin.search("dev-") > 0
    //         || window.location.origin.search("localhost") > 0
    //     )
    //       towerSightBFFUrl = "https://dev-towersightbff.bentley.com";
    //     else if (
    //         window.location.origin.search("qa-") > 0 ||
    //         window.location.origin.search("perf-") > 0
    //     )
    //       towerSightBFFUrl = "https://qa-towersightbff.bentley.com";
    //       else towerSightBFFUrl = "https://towersightbff.bentley.com";
          
    // towerSightBFFUrl = "https://qa-towersightbff.bentley.com";
    towerSightBFFUrl = new URL(`https://${ConfigManager.urlPrefixFromUrl()}towersightbff.bentley.com`);
    return towerSightBFFUrl.origin;
}


export const graphqlClient = new ApolloClient({
    link: new ApolloLink((operation, forward) => {
    operation.setContext({
      headers: {
        // "Authorization": App.accessToken!.toTokenString(),
        "Authorization": accessToken!,
        // "x-correlation-id": ClientRequestContext.current.activityId,
        // "x-correlation-id": App.accessToken.getUserInfo,
        "ProjectId": ConfigManager.projectId
      }
    });
    return forward(operation);
  }).concat(
    new HttpLink({
      uri: getTowerSightBFFUrl() + "/graphql"
    })
  ),
  cache: new InMemoryCache(),  
});

export async function ExecuteGraphQLQuery(query: DocumentNode, queryName: string, variables?: OperationVariables): Promise<any> {
    var data: any;
    
    await graphqlClient.query({ query: query, variables: variables, fetchPolicy: "no-cache" })
        .then((response) => {
            if (response.errors) {
                Logger.error(
                    "Error calling {queryName} GraphQL api error: {error}", {
                        queryName: queryName,
                        error: response.errors
                    });
                throw response;
            }
            data =  response.data;
        })
    return data;
}

export async function ExecuteGraphQLMutation(mutation: DocumentNode, mutationName: string, variables?: OperationVariables): Promise<any> {
    var data: any;
    await graphqlClient
        .mutate({
            mutation: mutation,
            variables: variables,
            fetchPolicy: "no-cache"
        }).then((response) => {
            if (response.errors) {
                Logger.error(
                    "Error calling {mutationName} GraphQL api error: {error}", {
                    mutationName: mutationName,
                    error: response.errors
                });
                throw response;
            }
            data = response.data;
        })
    return data;
}

