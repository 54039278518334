import { Id64String } from "@itwin/core-bentley";
import { BeButtonEvent, EventHandled, IModelApp, NotifyMessageDetails, OutputMessagePriority, OutputMessageType, PrimitiveTool, Viewport } from "@itwin/core-frontend";
import { WidgetState } from "@itwin/appui-react";
import { StagePanelLocation, SyncUiEventDispatcher } from "@itwin/appui-react";
import { EquipmentInformation, EquipmentsTable } from "../Tables/EquipmentsTable";
import { editExecution } from "../../components/HorizontalToolbarItems";
import { selectionObjective } from "../../../store/redux-types";
import { store } from "../../../store/rootReducer";
import { EquipmentPosition } from "../../tools/decorators/EditableShapeDecorator";
import { EquipmentShapeDecorator } from "../../tools/decorators/EquipmentShapeDecorator";
import EquipmentClient from "../../api/equipment";

// tslint:disable:naming-convention
export class CloneBoundingBoxTool extends PrimitiveTool {
    public static override toolId = "cloneBoundingBox";
    public static override iconSpec = "icon-clipboard-copy-2";
    private equipDecorator: EquipmentShapeDecorator | undefined;
    private cloningShapeName: Id64String | undefined;
    private cloningShapePosData: EquipmentPosition | undefined;
    private cloningShapeInfoData: any | undefined;

    public onRestartTool(): Promise<void> {
        const tool = new CloneBoundingBoxTool();
        if (!tool.run()) {
            this.exitTool();
        }
        return Promise.resolve();
    }

    public override isCompatibleViewport(_vp: Viewport | undefined, _isSelectedViewChange: boolean): boolean {
        return true;
    }

    public override run(): Promise<boolean> {
        const { toolAdmin, viewManager } = IModelApp;

        if (!this.isCompatibleViewport(viewManager.selectedView, false) || !toolAdmin.onInstallTool(this)) {
            return Promise.resolve(false);
        }

        this.equipDecorator = IModelApp.viewManager.decorators.filter((e) => e.constructor.name.includes("EquipmentShapeDecorator"))[0] as EquipmentShapeDecorator;
        if (!this.equipDecorator)
            return Promise.resolve(false);

        // this.cloningShapeName = SampleToolWidget.selectedBoxName;
        const detData = store.getState().detectedData;
        this.cloningShapeName = detData.selectedObjectInformation.objectProperties.selectedObjectNST.name;
        if (!this.cloningShapeName)
            return Promise.resolve(false);

        // this.cloningShapePosData = SampleToolWidget.equipNamePositionMap.get(this.cloningShapeName);
        this.cloningShapePosData = EquipmentsTable.equipNamePositionMap.get(this.cloningShapeName);
        // this.cloningShapePosData = detData.equipmentDataMaps.equipNamePositionMap.get(this.cloningShapeName);
        // this.cloningShapeInfoData = SampleToolWidget.selectedBoxInformation;
        // this.cloningShapeInfoData = detData.equipmentDataMaps.equipNameInfoMap.get(this.cloningShapeName);
        this.cloningShapeInfoData = EquipmentsTable.equipNameInfoMap.get(this.cloningShapeName);

        toolAdmin.startPrimitiveTool(this);
        toolAdmin.onPostInstallTool(this);
        return Promise.resolve(true);
    }

    public override async onDataButtonDown(_ev: BeButtonEvent): Promise<EventHandled> {
        if (undefined !== this.equipDecorator && undefined !== this.cloningShapePosData) {
            const clonedPos = await this.equipDecorator.addClonedBox(this.cloningShapePosData, _ev.point, this.cloningShapeName!);
            const prevInfo = this.cloningShapeInfoData;
            // const tokenString = App.accessToken!;
            if (prevInfo && clonedPos) {
                // Create new Equipment API Call
                const newNameInfo: EquipmentInformation = {
                    name: clonedPos!.Equipment_Name,
                    operator: prevInfo.operator,
                    elevationHeight: prevInfo.elevationHeight,
                    // height: 0,
                    height: prevInfo.height,
                    width: prevInfo.width,
                    depth: prevInfo.depth,
                    type: prevInfo.type,
                    brand: prevInfo.brand,
                    manufacturer: prevInfo.manufacturer,
                    model: prevInfo.model,
                    dimensions: prevInfo.dimensions,
                    area: 0,
                    bandTechnology: prevInfo.bandTechnology,
                    reference: prevInfo.reference,
                    yaw: prevInfo.yaw === "" ? 0 : prevInfo.yaw || prevInfo.azimuth,
                    pitch: prevInfo.pitch === "" || prevInfo.pitch === undefined ? 0 : prevInfo.pitch,
                    className: "",
                    id: "",
                    imageList: "",
                    azimuth: 0,
                    tilt: prevInfo.tilt === "" ? 0 : prevInfo.tilt,
                    roll: prevInfo.roll === "" ? 0 : prevInfo.roll,
                    xPosition: clonedPos.x_position,
                    yPosition: clonedPos.y_position,
                    zPosition: clonedPos.z_position,
                    };

                // SampleToolWidget.equipNameInfoMap.set(clonedPos!.Equipment_Name, newNameInfo);

                // const existingMaps = store.getState().detectedData.equipmentDataMaps;
                // const newEquipInfoMap = new Map(existingMaps.equipNameInfoMap);
                // newEquipInfoMap.set(clonedPos!.Equipment_Name, newNameInfo);
                // const newEquipInfoMaps: equipmentDataMaps = {...existingMaps, equipNameInfoMap: newEquipInfoMap};
                // store.dispatch(setEquipmentDataMaps(newEquipInfoMaps));
                EquipmentsTable.equipNameInfoMap.set(clonedPos!.Equipment_Name, newNameInfo);
    
                // SampleToolWidget.selectedBoxName = clonedPos!.Equipment_Name;
                // SampleToolWidget.selectedBoxInformation = clonedPos!.Equipment_Name;
                const privateToken = store.getState().auth.accessTokenStatePrivateAPI.accessToken;
                // Make API call with box information
                const infoRes = await EquipmentClient.postEquipmentJson(privateToken, newNameInfo);
                // const infoRes = true;

                if (!infoRes) {
                    IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Error,
                        "Error occured: cloned box not saved.", "", OutputMessageType.Toast));
                } else {
                    const id = this.equipDecorator.nameIdMap.get(newNameInfo!.name);
                    EquipmentsTable.equipmentData.push({
                        className: "Equipment",
                        id: id!,
                        name: newNameInfo!.name,
                        bandTechnology: newNameInfo.bandTechnology,
                        elevationHeight: newNameInfo!.elevationHeight,
                        height: clonedPos!.Height,
                        brand: newNameInfo.brand,
                        manufacturer: newNameInfo.manufacturer,
                        model: newNameInfo.model,
                        pitch: newNameInfo!.pitch,
                        type: newNameInfo!.type,
                        yaw: clonedPos!.Azimuth,
                        azimuth: clonedPos!.Azimuth,
                        width: clonedPos!.Width,
                        depth: clonedPos!.Thicness,
                        imageList: "",
                        roll: clonedPos.Roll,
                        tilt: clonedPos.Tilt,
                        xPosition: clonedPos.x_position,
                        yPosition: clonedPos.y_position,
                        zPosition: clonedPos.z_position,
                    });
                    // Create succeeded, now call POST for Equipment Position API
                    const posRes = await EquipmentClient.postEquipmentPositionJson(privateToken, clonedPos!);
                    if (!posRes) {
                        IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Error,
                            "Error occured: Cloned equipment position not saved.", "", OutputMessageType.Toast));
                    } else {
                        // if (MainPage.workFlow !== "" && (IModelApp as any).refreshCallback) {
                        //     (IModelApp as any).refreshCallback();
                        // }
                        SyncUiEventDispatcher.dispatchSyncUiEvent("equipmentselected");
                        IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Info,
                            "Success: Cloned equipment saved.", "", OutputMessageType.Toast));
                    }
                }
            }
            editExecution(selectionObjective.Add, false);
        }
        return EventHandled.No;
    }

    public override async onResetButtonDown(_ev: BeButtonEvent): Promise<EventHandled> {
        editExecution(selectionObjective.Add);
        this.exitTool();
        return EventHandled.No;
    }

    public override onPostInstall(): Promise<void> {
        super.onPostInstall();

        // Enable AccuSnap so that boxes can be created by snapping on existing model
        IModelApp.accuSnap.enableSnap(true);
        return Promise.resolve();
    }
}
