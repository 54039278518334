import { BeButtonEvent, Decorator, EventHandled, IModelApp, NotifyMessageDetails, OutputMessagePriority, OutputMessageType, PrimitiveTool, Viewport } from "@itwin/core-frontend";
// import App from "../App";
import { Point3d } from "@itwin/core-geometry";
import mountClient from "../../api/mountClient";
import { UiFramework } from "@itwin/appui-react";
import { Logger } from "../../api/logging";
import { store } from "../../../store/rootReducer";
import { DecoratorHelper } from "../../tools/decorators/DecoratorHelper";
import { MountDecorator } from "../../tools/decorators/MountDecorator";

export class PointSelectionTool extends PrimitiveTool {
    public static override toolId = "PointSelectionTool";
    // public static override iconSpec = "icon-edit-all";
    startPt: Point3d | undefined;
    endPt: Point3d | undefined;
    drawing: boolean = false;
    decorator: any;
    towerPole: any;
    public selection: any;
    public enableMovement: boolean = false;
    public callBack: any;
    public rest: any[] = [];
    
    
    public static exitEquipDecorator: any | undefined;

    public onRestartTool(): Promise<void> {
        const tool = new PointSelectionTool();
        if (!tool.run(this.selection))
            this.exitTool();
        return Promise.resolve();
    }

    public override isCompatibleViewport(_vp: Viewport | undefined, _isSelectedViewChange: boolean): boolean {
        return true;
    }

    public override run(callBack : Function | undefined = undefined, enableMovement: boolean = this.enableMovement, ...rest: any[]): Promise<boolean> {
        super.run();
        // this.selection = selection;
        this.callBack = callBack;
        this.rest=rest;

        this.enableMovement = enableMovement;
        const { toolAdmin, viewManager } = IModelApp;
        // this.decorator=IModelApp.viewManager.decorators.filter(e=>e.constructor.name.includes("MountDecorator"))[0] as MountDecorator;
        // this.decorator=callBack;
        
        // if(!this.decorator){
        //     this.decorator = new MountDecorator();
        //     IModelApp.viewManager.addDecorator(this.decorator);
        // }

        if (!this.isCompatibleViewport(viewManager.selectedView, false) || !toolAdmin.onInstallTool(this)) {
            return Promise.resolve(false);
        }
        
        toolAdmin.startPrimitiveTool(this);
        toolAdmin.onPostInstallTool(this);
        return Promise.resolve(true);
    }

    public override async onMouseMotion(_ev: BeButtonEvent): Promise<void> {
        this.endPt=_ev.point;
        if(this.enableMovement)this.callBack(_ev);
        // const _name = this.selection.uid.split("_edit");
        
        // let ji = this.decorator.pipes.findIndex((e) => e.uid.includes(_name[0]));
        // const thePipe = this.decorator.pipes[ji];

        // if(thePipe){

        //     let fstPt: Point3d, secPt: Point3d;
        //     if(_name[1]==="Front"){
        //         fstPt = _ev.point;
        //         secPt = thePipe.endPos;
        //     } else if(_name[1]==="End"){
        //         fstPt = thePipe.startPos;
        //         secPt = _ev.point;
        //     } else if(_name[1]==="Mid"){
        //         this.exitTool();
        //         return;
        //     }

        //     do{
        //         ji = this.decorator.pipes.findIndex((e) => e.uid.includes(_name[0]));
        //         if(ji!==-1){
        //             this.decorator.nameIdMap.delete(this.decorator.pipes[ji].transientId);
        //             this.decorator.pipes.splice(ji, 1);
        //         }
        //     } while(ji !== -1)

        //     this.decorator.addPipe(fstPt!, secPt!, thePipe.thickness, thePipe.fillColor, _name[0].split("@")[0], thePipe.modelData);
        //     const allPipes = this.decorator.pipes;
        //     const allNameIdMaps = this.decorator.nameIdMap;
        //     this.decorator.terminate();
        //     this.decorator.pipes = allPipes;
        //     this.decorator.nameIdMap = allNameIdMaps;
        //     IModelApp.viewManager.invalidateDecorationsAllViews();
        //     IModelApp.viewManager.selectedView?.invalidateCachedDecorations(this.decorator);
        // }
    }

    public override async onMouseStartDrag(_ev: BeButtonEvent): Promise<EventHandled> {
        this.drawing=false;
        this.startPt=_ev.point;
        this.exitTool();
        return EventHandled.Yes;
    }

    public override async onMouseEndDrag(_ev: BeButtonEvent): Promise<EventHandled> {
        this.endPt=_ev.point;
        this.exitTool();
        return EventHandled.Yes;
    }

    public override async onDataButtonUp(_ev: BeButtonEvent): Promise<EventHandled> {
        this.endPt=_ev.point;
        this.drawing=true;

        this.callBack(_ev);
        if(this.rest && this.rest.length)this.rest[0](true);
        // const _name = this.selection.uid.split("_edit");
        
        // let ji = this.decorator.pipes.findIndex((e:any) => e.uid.includes(_name[0]));
        // const thePipe = this.decorator.pipes[ji];

        // if(thePipe){
        //     try {
        //         let fstPt: Point3d, secPt: Point3d;
        //         if(_name[1]==="Front"){
        //             fstPt = _ev.point;
        //             secPt = thePipe.endPos;
        //         } else if(_name[1]==="End"){
        //             fstPt = thePipe.startPos;
        //             secPt = _ev.point;
        //         } else if(_name[1]==="Mid"){
        //             this.exitTool();
        //             return EventHandled.Yes;
        //         }

        //         do{
        //             ji = this.decorator.pipes.findIndex((e:any) => e.uid.includes(_name[0]));
        //             if(ji!==-1){
        //                 this.decorator.nameIdMap.delete(this.decorator.pipes[ji].transientId);
        //                 this.decorator.pipes.splice(ji, 1);
        //             }
        //         } while(ji !== -1)

        //         const name = _name[0].split("@")[0];
        //         this.decorator.addPipe(fstPt!, secPt!, thePipe.thickness, thePipe.fillColor, name, thePipe.modelData);

        //         const allPipes = this.decorator.pipes;
        //         const allNameIdMaps = this.decorator.nameIdMap;
        //         this.decorator.terminate();
        //         this.decorator.pipes = allPipes;
        //         this.decorator.nameIdMap = allNameIdMaps;
        //         IModelApp.viewManager.invalidateDecorationsAllViews();
        //         IModelApp.viewManager.selectedView?.invalidateCachedDecorations(this.decorator);
                

        //         const token = store.getState().auth.accessTokenStatePrivateAPI.accessToken!;
        //         const selectedMount = this.decorator.selectedMount;

        //         const iModel = UiFramework.getIModelConnection()!;
        //         const stPtCart = iModel!.spatialToCartographicFromEcef(fstPt!);
        //         const stPtUtm = DecoratorHelper.convertWGS2UTM(stPtCart);
        //         const endPtCart = iModel!.spatialToCartographicFromEcef(secPt!);
        //         const endPtUtm = DecoratorHelper.convertWGS2UTM(endPtCart);
        
        //         const data = {
        //         "mountId": selectedMount.modelData.parentMount,
        //         "faceId": selectedMount.modelData.mountFace,
        //         "mountPipeType": "horizont_pipe",
        //         "mountType": selectedMount.modelData.parentMountType,
        //         "mountPipeId": selectedMount.modelData.name,
        //         "utm": [
        //             [stPtUtm[0], stPtUtm[1], stPtCart.height],
        //             [endPtUtm[0], endPtUtm[1], endPtCart.height]
        //         ]
        //         }
        //         /** 
        //          * Task 1385568: Disable the Mount Edit workflows temporarily until the mount detection logic is fixed.
        //         mountClient.putMountJson(token, data).then(e=>{
        //             if(e==="Success."){
        //                 IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Info, "Mount pipe saved successfully.", "", OutputMessageType.Toast));
        //             } else {
        //                 IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Error,`Error occured: Mount edit changes not saved.`, "", OutputMessageType.Toast)); 
        //             }
        //         })
        //         */                
        //     } catch (error) {
        //        Logger.error('An error occured while performing & saving edits on Mount pipe. ', error);
        //     }
        // }

        this.exitTool();
        return EventHandled.Yes;
    }

    public async onResetButtonDown(_ev: BeButtonEvent): Promise<EventHandled> {
        if(this.rest && this.rest.length)this.rest[0](false);
        await this.exitTool();
        return EventHandled.Yes;
      }
    
    public override onPostInstall(): Promise<void> {
        super.onPostInstall();

        // Enable AccuSnap so that boxes can be created by snapping on existing model
        IModelApp.accuSnap.enableSnap(true);
        return Promise.resolve()
    }
}
