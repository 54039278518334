import { IModelApp, NotifyMessageDetails, OutputMessagePriority, OutputMessageType } from "@itwin/core-frontend"
import { pecColumn, pecInputTagTypes, pecDataValueTypes, pecRows, pecGridStructure, pecComponentProps, pecStrucType, pecTableButton, PropertyEditorComponent } from "../components/PropertyEditorComponent"

import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Vector3d } from "@itwin/core-geometry"
import { UiFramework } from "@itwin/appui-react"
import mountClient from "../api/mountClient"
import React from "react"
import { DTVActions } from "../../store/Actions"
import { RootState } from "../../store/States"
import { store } from "../../store/rootReducer"
import { DecoratorHelper } from "./decorators/DecoratorHelper"
import { MountDecorator } from "./decorators/MountDecorator"
import { editOnClose } from "../components/HorizontalToolbarItems"
import EquipmentClient from "../api/equipment"
import { setMountingsData } from "../../store/detectedData/apiDataActionTypes"
export const enum RadioSection {
    Start = "Start",
    Whole = "Whole",
    End = "End",
  }
  let runLoop:any;

const DetectedMountEditHandler = (props) => {
  const [mountEditData, setMountEditData] = useState(props.detMountMenuStruc);
  const [selection, setSelection] = useState(props.editSection);
  const [saveButton, setSaveButton] = useState<{disabled: boolean, text: string}>({disabled: true, text: "Save"});
  // const [runLoop, setRunLoop] = useState<any>();
  
  const move = 0.01;
  const radiusMove = 0.02;

  useEffect(() => {
    // return () => {}
  }, [])
  

  const saveMount = async () => {
    const mountDec=IModelApp.viewManager.decorators.filter(e=>e.constructor.name.includes("MountDecorator"))[0] as MountDecorator;
    mountDec.previousSelection = mountDec.selectedMount;    
    const tokenString = store.getState().auth.accessTokenStatePrivateAPI.accessToken!;
    const selectedMount = mountDec.selectedMount;
    const iModel = UiFramework.getIModelConnection()!;
    // const stPtCart = iModel!.spatialToCartographicFromEcef(selectedMount.modelData.startPoint);
    const stPtCart = iModel!.spatialToCartographicFromEcef(selectedMount.startPos);
    const stPtUtm = DecoratorHelper.convertWGS2UTM(stPtCart);
    // const endPtCart = iModel!.spatialToCartographicFromEcef(selectedMount.modelData.endPoint);
    const endPtCart = iModel!.spatialToCartographicFromEcef(selectedMount.endPos);
    const endPtUtm = DecoratorHelper.convertWGS2UTM(endPtCart);

    const type = selectedMount.modelData.orientation === "Horizontal" ? "horizont_pipe" : "vertical_pipe";

    const updateData = {
      "mountId": selectedMount.modelData.parentMount,
      "faceId": selectedMount.modelData.mountFace,
      "mountPipeType": type,
      "mountType": selectedMount.modelData.parentMountType,
      "mountPipeId": selectedMount.modelData.name,
      "utm": [
          [stPtUtm[0], stPtUtm[1], stPtCart.height],
          [endPtUtm[0], endPtUtm[1], endPtCart.height]
      ]
    }
    setSaveButton({disabled: true, text: "Saving..."});
    /** 
     * Task 1385568: Disable the Mount Edit workflows temporarily until the mount detection logic is fixed.
      await mountClient.putMountJson(tokenString, updateData).then(async e=>{
          if(e==="Success."){
            const mountingsData = await EquipmentClient.getMountingsDataJson(tokenString);      // Storing mountings data, for elevation operators mapping.
            store.dispatch(setMountingsData(mountingsData));

            IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Info, "Mount pipe saved successfully.", "", OutputMessageType.Toast));
          } else {
            IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Error,`Error occured: Mount edit changes not saved.`, "", OutputMessageType.Toast)); 
          }
      });
     * **/
    return true;
}

  const onModalBtnClick = async (event) => {
    const id = event.target.id;
    switch (id) {
      case "Save#1":
        await saveMount();
        editOnClose("MountDecorator", true)
        UiFramework.dialogs.modeless.close("Edit Dialog")
        props.onClose("MountDecorator", false);
        store.dispatch(DTVActions.setEditModeFlag(false));
        break;
      case "Cancel#2":
        UiFramework.dialogs.modeless.close("Edit Dialog");
        props.onClose("MountDecorator", true);
        store.dispatch(DTVActions.setEditModeFlag(false));
        break;
    }
  }
  
  const onFieldUpdate = (event) => {
    let currentEvent= event;
  }
  
  const onFieldChange = (event, value: string) => {
    const temp: pecGridStructure = {...mountEditData};
    const eventType = event.constructor.name;


    if(event.target.name == "radio-buttons-group"){
      // let temp = value == RadioSection.Start ? RadioSection.Start : value == RadioSection.Whole ? RadioSection.Whole : RadioSection.End;
      setSelection(value == RadioSection.Start ? RadioSection.Start : value == RadioSection.Whole ? RadioSection.Whole : RadioSection.End)
      return;
    }

    if((eventType == "SyntheticBaseEvent") || (typeof event === 'object' && event !== null)){
      const {id: theId, name: theName, value: theValue} = event.target;
      const [inputType, rowIndex, columnIndex] = theId.split("@")[0].split('-');

      
      switch (inputType) {
        case pecInputTagTypes.SELECT:
          temp.rows[rowIndex].columns[columnIndex].value = temp.rows[rowIndex].columns[columnIndex].data![theValue];
          break;
        case pecInputTagTypes.CHECKBOX:
          temp.rows[rowIndex].columns[columnIndex].value = !temp.rows[rowIndex].columns[columnIndex].value;
          break;
        case pecInputTagTypes.RADIO_GROUP:
          break;
        case pecInputTagTypes.RADIO_BUTTON:
          const otherRadiosIndex = temp.rows[rowIndex].columns[columnIndex].data.allOtherRadiosIndex;
          otherRadiosIndex.forEach((e:string)=>{
            const rowCol = e.split('-');
            temp.rows[rowCol[0]].columns[rowCol[1]].data.matchValue = temp.rows[rowIndex].columns[columnIndex].value;
          })
          // temp.rows[rowIndex].columns[columnIndex].value = temp.rows[rowIndex].columns[columnIndex].value;
          break;
      
        default:
          break;
      }
      
      
    } else {    
      switch (eventType) {
      case "Number":
        
      break;
      
      default:
        break;
      }

    }


    // setMountEditData(temp);
  }
  
  const onMouseDown = (event) => {
    const target = event.target;
    enum editMode {
      Position = "Positional Changes",
      Structure = "Structural Changes",
    }
    const [elementDetail, name] = target.id.split('@');
    const decorator = IModelApp.viewManager.decorators.filter(e=>e.constructor.name.includes("MountDecorator"))[0] as MountDecorator;
    let objectEditMode:editMode=editMode.Position, movementVector: Vector3d = new Vector3d(), strucMultFactor = 1;
    switch (name) {
      case "moveLeft":
        movementVector = new Vector3d(move, 0, 0);        
        break;
      case "moveRight":
        movementVector = new Vector3d(-move, 0, 0);        
        break;
      case "moveFront":
        movementVector = new Vector3d(0, move, 0);        
        break;
      case "moveBack":
        movementVector = new Vector3d(0, -move, 0);        
        break;
      case "moveUp":
        movementVector = new Vector3d(0, 0, move);        
        break;
      case "moveDown":
        movementVector = new Vector3d(0, 0, -move);        
        break;    
      case "increaseLength":
        objectEditMode = editMode.Structure;
        break;    
      case "decreaseLength":
        objectEditMode = editMode.Structure;
        strucMultFactor = -1;
        break;    
    }

    if ( decorator && decorator.selectedMount && objectEditMode == editMode.Position ){
      // setSaveButton({...saveButton, disabled: false}); Task 1385568: Disable the Mount Edit workflows temporarily until the mount detection logic is fixed.
      runLoop=setInterval(()=>decorator.updateCylinderPosition(decorator.selectedMount.uid.split("_edit")[0], movementVector, selection), 50);
      setTimeout(() => clearInterval(runLoop), 10000);
    } else if(objectEditMode == editMode.Structure){
      // setSaveButton({...saveButton, disabled: false}); Task 1385568: Disable the Mount Edit workflows temporarily until the mount detection logic is fixed.
      runLoop=setInterval(()=>decorator.updateCylinderThickness(decorator.selectedMount.uid.split("_edit")[0], move*strucMultFactor), 50);
      setTimeout(() => clearInterval(runLoop), 10000);
    }

  }
  
  const onMouseUp = () => {
    clearInterval(runLoop);        
  }
  
  const onFieldClick = (event) => {
    const target = event.target;
    const [elementDetail, name] = target.id.split('@');
    const decorator = IModelApp.viewManager.decorators.filter(e=>e.constructor.name.includes("MountDecorator"))[0] as MountDecorator;
    let movementVector: Vector3d = new Vector3d();
    switch (name) {
      case "moveLeft":
        movementVector = new Vector3d(move, 0, 0);        
        break;
      case "moveRight":
        movementVector = new Vector3d(-move, 0, 0);        
        break;
      case "moveFront":
        movementVector = new Vector3d(0, move, 0);        
        break;
      case "moveBack":
        movementVector = new Vector3d(0, -move, 0);        
        break;
      case "moveUp":
        movementVector = new Vector3d(0, 0, move);        
        break;
      case "moveDown":
        movementVector = new Vector3d(0, 0, -move);        
        break;
      }

      if ( decorator && decorator.selectedMount) {
          // setSaveButton({...saveButton, disabled: false}); Task 1385568: Disable the Mount Edit workflows temporarily until the mount detection logic is fixed.
          decorator.updateCylinderPosition(decorator.selectedMount.uid.split("_edit")[0], movementVector, selection);
      }

  }
  
  
  let modalBtns: pecTableButton[] = [
    {name: saveButton.text, value: saveButton.text, class: "dialogBox-save-btn", disable: saveButton.disabled, onInteract: onModalBtnClick},
    {name: "Cancel", value: "Cancel", class: "dialogBox-cancel-btn", disable: false, onInteract: onModalBtnClick},
  ];
  
  
  let mountEditorStructure: pecComponentProps = {
    structureType: pecStrucType.STRUCTURE,
    title: {
      label: "Mount Edit",
      // styles: undefined
    },
    grid: mountEditData,
    gridButtons: modalBtns,
    eventHandlers: {
      onChange: onFieldChange,
      onUpdate: onFieldUpdate,
      onClick: onFieldClick,
      onMouseDown: onMouseDown,
      onMouseUp: onMouseUp,
    }
  }


  return (
    <PropertyEditorComponent {...mountEditorStructure} />
  )
}

const mapStateToProps = (_state: RootState) => {
  const editSection = RadioSection.Whole;
  let mountingsData = store.getState().detectedData.mountingsData;// await getMountingsDataJson();
  const selMountName = _state.detectedData.selectedObjectInformation.objectProperties.selectedObjectNST.name.split('@')[3];
  const mountDec = IModelApp.viewManager.decorators.filter(e=>e.constructor.name.includes("MountDecorator"))[0] as MountDecorator;
  const selMount = mountDec.selectedMount;
  const {manufacturer="", model=""} = mountingsData.mounts[selMountName];
  const detMountMenuStruc: pecGridStructure = {
    rows: [

    ]
  }


  // const detMountMenuStruc: pecGridStructure ={
  //   rows: [
  //       {
  //       columns: [
  //         {
  //           size: 12,
  //           type: pecInputTagTypes.RADIO_GROUP,
  //           customClass: "",
  //           value: "Section to edit",
  //           disabled: false,
  //           name: "partSelection-1-1",
  //           fieldType: pecDataValueTypes.INTEGER,
  //           modified: false,
  //           styles: {parentClass: "", childClass: ""},
  //           data: {matchValue: editSection, otherRadios: [
  //             {label: RadioSection.Start},
  //             {label: RadioSection.Whole},
  //             {label: RadioSection.End},
  //           ]}
  //         },
  //       ]
        
  //       },
  //       {
  //         columns: [
  //           {
  //             size: 8,
  //             type: pecInputTagTypes.LABEL,
  //             customClass: "",
  //             value: "Move Left or Right",
  //             disabled: false,
  //             name: "mvLorR",
  //             fieldType: pecDataValueTypes.INTEGER,
  //             modified: false,
  //             styles: {parentClass: "mb-2", childClass: ""}
  //           },
  //           {
  //             size: 2,
  //             type: pecInputTagTypes.BUTTON,
  //             customClass: "",
  //             value: "L",
  //             disabled: false,
  //             name: "moveLeft",
  //             fieldType: pecDataValueTypes.INTEGER,
  //             modified: false,
  //             styles: {parentClass: "mb-2", childClass: "number-input-class"}
  //           },      
  //           {
  //             size: 2,
  //             type: pecInputTagTypes.BUTTON,
  //             customClass: "",
  //             value: "R",
  //             disabled: false,
  //             name: "moveRight",
  //             fieldType: pecDataValueTypes.INTEGER,
  //             modified: false,
  //             styles: {parentClass: "mb-2", childClass: "number-input-class"}
  //           },      
  //         ]
          
  //       },
  //       {
  //           columns: [
  //             {
  //               size: 8,
  //               type: pecInputTagTypes.LABEL,
  //               customClass: "",
  //               value: "Move Front or Back",
  //               disabled: false,
  //               name: "mvForB",
  //               fieldType: pecDataValueTypes.INTEGER,
  //               modified: false,
  //               styles: {parentClass: "mb-2", childClass: ""}
  //             },
  //             {
  //               size: 2,
  //               type: pecInputTagTypes.BUTTON,
  //               customClass: "",
  //               value: "F",
  //               disabled: false,
  //               name: "moveFront",
  //               fieldType: pecDataValueTypes.INTEGER,
  //               modified: false,
  //               styles: {parentClass: "mb-2", childClass: "number-input-class"}
  //             },      
  //             {
  //               size: 2,
  //               type: pecInputTagTypes.BUTTON,
  //               customClass: "",
  //               value: "B",
  //               disabled: false,
  //               name: "moveBack",
  //               fieldType: pecDataValueTypes.INTEGER,
  //               modified: false,
  //               styles: {parentClass: "mb-2", childClass: "number-input-class"}
  //             },      
  //           ]
            
  //       },
  //       {
  //           columns: [
  //             {
  //               size: 8,
  //               type: pecInputTagTypes.LABEL,
  //               customClass: "",
  //               value: "Move Up or Down",
  //               disabled: false,
  //               name: "mvUorD",
  //               fieldType: pecDataValueTypes.INTEGER,
  //               modified: false,
  //               styles: {parentClass: "mb-2", childClass: ""}
  //             },
  //             {
  //               size: 2,
  //               type: pecInputTagTypes.BUTTON,
  //               customClass: "",
  //               value: "U",
  //               disabled: false,
  //               name: "moveUp",
  //               fieldType: pecDataValueTypes.INTEGER,
  //               modified: false,
  //               styles: {parentClass: "mb-2", childClass: "number-input-class"}
  //             },      
  //             {
  //               size: 2,
  //               type: pecInputTagTypes.BUTTON,
  //               customClass: "",
  //               value: "D",
  //               disabled: false,
  //               name: "moveDown",
  //               fieldType: pecDataValueTypes.INTEGER,
  //               modified: false,
  //               styles: {parentClass: "mb-2", childClass: "number-input-class"}
  //             },      
  //           ]
            
  //       },
  //       {
  //           columns: [
  //             {
  //               size: 8,
  //               type: pecInputTagTypes.LABEL,
  //               customClass: "",
  //               value: "Length",
  //               disabled: false,
  //               name: "Length",
  //               fieldType: pecDataValueTypes.INTEGER,
  //               modified: false,
  //               styles: {parentClass: "mb-2", childClass: ""}
  //             },
  //             {
  //               size: 2,
  //               type: pecInputTagTypes.BUTTON,
  //               customClass: "",
  //               value: "+",
  //               disabled: false,
  //               name: "increaseLength",
  //               fieldType: pecDataValueTypes.INTEGER,
  //               modified: false,
  //               styles: {parentClass: "mb-2", childClass: "number-input-class"}
  //             },      
  //             {
  //               size: 2,
  //               type: pecInputTagTypes.BUTTON,
  //               customClass: "",
  //               value: "-",
  //               disabled: false,
  //               name: "decreaseLength",
  //               fieldType: pecDataValueTypes.INTEGER,
  //               modified: false,
  //               styles: {parentClass: "mb-2", childClass: "number-input-class"}
  //             },      
  //           ]
            
  //       },
  //   ]
  // }

  const retVal = {
    detMountMenuStruc,
    editSection,
  };
  return retVal;
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(DetectedMountEditHandler);