// import { reject } from "q";
// import App from "../components/App";
import { reject } from "lodash";
import { AccessToken } from "@itwin/core-bentley";
import { ConfigManager } from "../../config/ConfigManager";
import { store } from "../../store/rootReducer";
import { RootState } from "../../store/States";

let projectId: string, openToweriQUrl: string, privateAccessToken: AccessToken;
const listener = () => {
    setProjectIdUrl(store.getState());
}

function setProjectIdUrl(state: RootState) {
    projectId = ConfigManager.projectId!;
    openToweriQUrl = ConfigManager.openToweriQUrl!;
    privateAccessToken = state.auth.accessTokenStatePrivateAPI.accessToken!;
}

store.subscribe(listener);

export default class TrackTelemetryApiClient {
  constructor() {}

  public static async trackTelemetry(eventTracked: string) {
    const contextId = ConfigManager.projectId!;
    const path = decodeURIComponent(window.location.origin);
    const token: string = privateAccessToken!;
    const trackTelemetryUrl = path + "/trackTelemetry?contextId=" + contextId;
    fetch(trackTelemetryUrl, {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify({
        eventTracked,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          reject(response);
        }
        return response.json;
      })
      .catch((err) => {
        reject(err);
      });
  }
}
