import { ActionButton, ConditionalBooleanValue, ConditionalStringValue, ToolbarItemUtilities } from "@itwin/appui-abstract";
import { RenderMode } from "@itwin/core-common";
import { IModelApp, NotifyMessageDetails, OutputMessagePriority, OutputMessageType } from "@itwin/core-frontend";
// import { CustomToolbarProvider } from "../CustomToolbarProvider";
import EquipmentClient from "../api/equipment";
// import { SampleToolWidget } from "../app-ui/frontstages/SampleToolWidget";
import { OutlineFeedlineTool } from "../components/tools/OutlineFeedlineTool";
import { SyncUiEventDispatcher, UiFramework, WidgetState } from "@itwin/appui-react";
import { getEquipment } from "./GetDetectedObjectsData";
import { EquipmentsTable } from "../components/Tables/EquipmentsTable";
import { DTVActions } from "../../store/Actions";
import { RootState } from "../../store/States";
import { setFeedLineData, setSelectedObjectStructure, setSiteCoordinate, setTowerData } from "../../store/detectedData/apiDataActionTypes";
import { SyncUiEventIds } from "../../store/redux-types";
import { store } from "../../store/rootReducer";
import { EquipmentPosition } from "./decorators/EditableShapeDecorator";
import { ObjectCategoryTypes, initialPropertyListObjectState } from "../../store/detectedData/apiDataTypes";
import FeederLineClient from "../api/feederLinesClient";
import { editOnClose } from "../components/HorizontalToolbarItems";

// export const HighlightFeeders = (props) => {
//   return (
//     <div>HighlightFeeders</div>
//   )
// }

function select(state: RootState, dataKey: string) {
    return state.detectedData[dataKey];
}

export const HighlightFeeders = () => {

    // return ToolbarItemUtilities.createActionButton ({id: "string", itemPriority: 10, icon: "string", label: "string", execute: () => void, overrides?: Partial<ActionButton> | undefined}) => ActionButton;
    return ToolbarItemUtilities.createActionButton(
        "icon-3d", 
        110, 
        "icon-3d", 
        "Highlight Feeders",
        async () => await executeHighlightFeeders(),
        {
            isActive: true, 
        }
    );

};

export const executeHighlightFeeders = async () => {
        const siteCoordinates = select(store.getState(), "siteCoordinate");
        let feedlineData = select(store.getState(), "feedLinesData");
    const currentState = store.getState();
    const highlightStates = currentState.dtvState.applicationState.highlightStates;
    const highlight: boolean = highlightStates.feeders;
    if(currentState.dtvState.applicationState.projectStatus.isInsightAvailable){
        store.dispatch(DTVActions.updateHighlightState({...highlightStates, feeders: !highlight}))
        if(highlightStates.feeders){
            SyncUiEventDispatcher.dispatchSyncUiEvent(SyncUiEventIds.RM_Feedline_UnHighlighted);
            if(currentState.detectedData.selectedObjectInformation.objectProperties.objectCategoryType == ObjectCategoryTypes.FeedLine_RM){
                store.dispatch(setSelectedObjectStructure(initialPropertyListObjectState));
                editOnClose("feedlineEditorStructure", true);
            }
        } else SyncUiEventDispatcher.dispatchSyncUiEvent(SyncUiEventIds.RM_Feedline_Highlighted);       
    }            

    // this.siteCoord;
    if (!siteCoordinates) {
        const siteCoord = await EquipmentClient.setSiteCoordinates(currentState.auth.accessTokenStatePrivateAPI.accessToken /*accessTokenPrivate is a string now*/);     // Storing site coordinate, for if equipments are edited & require update relative to site <coordinates className="" />
        store.dispatch(setSiteCoordinate(siteCoord));
    }

    const vp = IModelApp.viewManager.selectedView;
    if (vp) {
        const viewFlags = vp.viewFlags.copy({renderMode: RenderMode.SmoothShade});
        vp.viewFlags = viewFlags;
    }
    if(store.getState().dtvState.featureControls.restrictCrudOperations && !store.getState().dtvState.applicationState.isLoggedInUserAdmin)
        SyncUiEventDispatcher.dispatchSyncUiEvent(SyncUiEventIds.Not_Show_All_Detected_Objects);    
    let toolRetVal: boolean = false;
    if(!feedlineData){
        const feedLinesData = await FeederLineClient.getFeedLineData();
        if(feedLinesData) store.dispatch(setFeedLineData(feedLinesData));
        feedlineData = feedLinesData;
    }

    setTimeout(async () => {
        await IModelApp.tools.run(OutlineFeedlineTool.toolId, feedlineData, highlight);

    }, 0);


 }

// const mapStateToProps = (state) => ({})

// const mapDispatchToProps = {}

// export default connect(mapStateToProps, mapDispatchToProps)(HighlightFeeders)

store.subscribe(HighlightFeeders);