import { IModelApp } from "@itwin/core-frontend";
import { AppSettings } from "../../config/AppSettings";
import { ConfigManager } from "../../config/ConfigManager";
import { unitSystemType } from "../../store/redux-types";
import { store } from "../../store/rootReducer";
import { LadderDecorator } from "../tools/decorators/LadderDecorator";
import { ReduceDecimalNumberToTwoPlaces } from "./Lists/EquipmentList";
import { getUnit } from "./Tables/BaseTable";
import { UiFramework } from "@itwin/appui-react";
import { DecoratorHelper } from "../tools/decorators/DecoratorHelper";

export const getLadder = async() =>{
    let {topElevation,bottomElevation,elevation} = getLadderData()
    const towerdata = store.getState().detectedData.towerStructureData;
    let concreteHeight = 0
    if(towerdata && towerdata.hasOwnProperty("concrete_height"))concreteHeight = ConfigManager.AGL ? Object.entries(towerdata).filter((f) => f[0] === "concrete_height")[0][1] : 0;
    
    let nameValuePair: {name: string, value: string}[] | any  = [];
    nameValuePair.push({name: "Length "+getUnit(), value: AppSettings.defaultDisplaySettings.unitSystem == unitSystemType.IMPERIAL?ReduceDecimalNumberToTwoPlaces(Math.abs((bottomElevation-topElevation))*3.28084):ReduceDecimalNumberToTwoPlaces(Math.abs(bottomElevation-topElevation))});    
    nameValuePair.push({name: "Top Elevation * "+getUnit(), value:AppSettings.defaultDisplaySettings.unitSystem == unitSystemType.IMPERIAL?ReduceDecimalNumberToTwoPlaces((bottomElevation+concreteHeight)*3.28084):ReduceDecimalNumberToTwoPlaces(bottomElevation+concreteHeight)});    
    nameValuePair.push({name: "Bottom Elevation * "+getUnit(), value:AppSettings.defaultDisplaySettings.unitSystem == unitSystemType.IMPERIAL? ReduceDecimalNumberToTwoPlaces((topElevation+concreteHeight)*3.28084): ReduceDecimalNumberToTwoPlaces(topElevation+concreteHeight)});    
    nameValuePair.push({name: "Centerline Elevation * " +getUnit(), value:AppSettings.defaultDisplaySettings.unitSystem == unitSystemType.IMPERIAL?ReduceDecimalNumberToTwoPlaces((elevation+concreteHeight)*3.28084):ReduceDecimalNumberToTwoPlaces(elevation+concreteHeight) });    
    return nameValuePair
}

const getLadderData = () => {
    const ladderDec = IModelApp.viewManager.decorators.filter(e=>e.constructor.name.includes("LadderDecorator"))[0] as LadderDecorator;
        let selectedLadder = ladderDec.selectedLadder.modelData.name.split(["_"])
    let ladderData = LadderDecorator.ladderData.filter(lad=>lad.ladderId ==  selectedLadder[0])
    let startPoint = selectedLadder[1]== "FIRST"?ladderData[0].coordinates[0]:ladderData[0].coordinates[2]
    let endPoint = selectedLadder[1]== "FIRST"?ladderData[0].coordinates[1]:ladderData[0].coordinates[3]
    let {topElevation,bottomElevation,elevation} = getPropertiesCalculation(startPoint,endPoint,ladderDec.selectedLadder.modelData)
    return {topElevation,bottomElevation,elevation}
  //  }

}

export const getPropertiesCalculation = (statrtPoint,endPoint,objInfo) => {
      let zTopPoints = [0];
    let zBottomPoints = [0];
    let utms = [0,0]
    if(statrtPoint && endPoint){
      zTopPoints=[statrtPoint.z];
      zBottomPoints=[endPoint.z]
      utms=[endPoint.z,statrtPoint.z]
    }else{
      if(!objInfo.hasOwnProperty("startPoint")){ 
        zTopPoints=[objInfo.startPos.z];
        zBottomPoints=[objInfo.endPos.z]
        utms=[objInfo.endPos.z,objInfo.startPos.z]
      }
     
    }
    const topAltitude = (zTopPoints.reduce((sum: number, e: number) => sum + e, 0) / zTopPoints.length);
    const bottomAltitude = (zBottomPoints.reduce((sum: number, e: number) => sum + e, 0) / zBottomPoints.length);
    const topElevation = topAltitude - store.getState().detectedData.siteCoordinate.utm.z;
    const bottomElevation = bottomAltitude - store.getState().detectedData.siteCoordinate.utm.z;
    const altitude = (utms.reduce((sum: number, e: number) => sum + e, 0) / utms.length);
    const elevation = altitude - store.getState().detectedData.siteCoordinate.utm.z;
    return{topElevation,bottomElevation,elevation}
  }

  
  export const  handleLocalToUtm = (data) => {
    const iModel = UiFramework.getIModelConnection()!;
    const stPtCart = iModel!.spatialToCartographicFromEcef(data);
    const stPtUtm = DecoratorHelper.convertWGS2UTM(stPtCart);
    
 let copyUtm = {
     "x": stPtUtm[0],
     "y": stPtUtm[1],
     "z": stPtCart.height
 }
 
 return copyUtm;
 }
