import { Point2d, Point3d } from "@itwin/core-geometry";
import { BeButton, BeButtonEvent, DecorateContext, Decorator, IModelApp, Marker } from "@itwin/core-frontend";
import { StagePanelLocation, SyncUiEventDispatcher, UiFramework, WidgetState } from "@itwin/appui-react";
// import App from "../../components/App";

export class PinsDecorator implements Decorator {
    public useCachedDecorations?: true | undefined = true;

    // tslint:disable:naming-convention
    private pinsData: any;
    private baseAlt: any;
    constructor(_pinsData: any, _baseAlt: any) {
        this.pinsData = _pinsData;
        this.baseAlt = _baseAlt;
    }
    public static latitudeToDefectJsonMap = new Map<string, any>();
    private static _markers: Marker[] | undefined;
    // private static _context: DecorateContext | undefined;
    public static selectedMarkerJson: any | undefined;
    public static selectedMarker: Marker | undefined;

    public decorate(context: DecorateContext): void {
        // PinsDecorator._context = context;
        if (!PinsDecorator._markers) {
            this.loadMarkers();
        }

        PinsDecorator._markers?.forEach((marker) => {
            marker.addDecoration(context);
        });
    }

    public static setMarkerSelectedImg(altitudeOfMarker: any) {
        PinsDecorator._markers?.forEach((marker) => {
            marker.setImageUrl("image/location.SVG");
            IModelApp.viewManager.selectedView?.invalidateDecorations();
        });

        PinsDecorator._markers?.forEach((marker) => {
            if (marker.worldLocation.z === altitudeOfMarker) {
                marker.setImageUrl("image/location-coloured.SVG");
                this.selectedMarker = marker;
                IModelApp.viewManager.selectedView?.invalidateDecorations();
            }
        });
    }

    public async loadMarkers(): Promise<void> {
        const json = this.pinsData;
        if (!json) {
            return;
        }
        const pins = json;
        for (const pin of pins) {
            const ecefX = Number(pin.pinInformation[0]);
            const ecefY = Number(pin.pinInformation[1]);
            const ecefZ = Number(pin.pinInformation[2]);
            const iModel = UiFramework.getIModelConnection()!;
            const oldCentroidPtInGlobal = new Point3d(ecefX, ecefY, ecefZ);
            const spatialPoint = iModel.ecefToSpatial(oldCentroidPtInGlobal);
            spatialPoint.z = spatialPoint.z + Number(this.baseAlt[0]);
            const marker = new Marker(spatialPoint!, new Point2d(48, 48));
            marker.visible = true;

            marker.setImageUrl("image/location.SVG");
            marker.onMouseLeave = () => {
                marker.label = "";
                marker.imageSize = {x: 32, y: 32};
            };
            marker.onMouseEnter = () => {
                marker.label = `${pin.criticality} : ${pin.defect}`;
                marker.labelBaseline = "bottom";
                marker.labelOffset = {x: 0, y: 20};
                marker.imageSize = {x: 42, y: 42};
                return true;
            };
            marker.size = new Point2d(32, 32);
            marker.onMouseButton = (_ev: BeButtonEvent) => {
                if (_ev.button === BeButton.Data) {
                    PinsDecorator.selectedMarkerJson = pin;
                    PinsDecorator.selectedMarker = marker;
                    PinsDecorator.setMarkerSelectedImg(spatialPoint.z);
                    // show defect widget
                    // FrontstageManager.activeFrontstageDef?.getStagePanelDef(StagePanelLocation.Right)?.
                    //     findWidgetDef("PropertyListWidget")?.setWidgetState(WidgetState.Open);
                    SyncUiEventDispatcher.dispatchSyncUiEvent("pin-selected");
                } else if (_ev.button === BeButton.Reset) {
                    PinsDecorator.selectedMarkerJson = undefined;
                    PinsDecorator.selectedMarker = undefined;

                    // FrontstageManager.activeFrontstageDef?.getStagePanelDef(StagePanelLocation.Right)?.
                    //     findWidgetDef("PropertyListWidget")?.setWidgetState(WidgetState.Hidden);
                    SyncUiEventDispatcher.dispatchSyncUiEvent("pin-unselected");
                } else {
                    return false;
                }

                return true;
            };
            if (!PinsDecorator._markers) {
                PinsDecorator._markers = [];
            }
            PinsDecorator._markers.push(marker);
        }
    }
}
