import { BeButtonEvent, EventHandled, IModelApp, NotifyMessageDetails, OutputMessagePriority, OutputMessageType, PrimitiveTool, ScreenViewport, Viewport } from "@itwin/core-frontend";
// import {  } from "@itwin/appui-abstract";
import { StagePanelLocation, UiFramework, WidgetState } from "@itwin/appui-react";
// import { SampleToolWidget } from "../../app-ui/frontstages/SampleToolWidget";
// import App from "../App";
import { Logger} from "@itwin/core-bentley";
import { MountDecorator } from "../../tools/decorators/MountDecorator";
import { store } from "../../../store/rootReducer";

// tslint:disable:naming-convention
export class HighlightMountTool extends PrimitiveTool {
    public static override toolId = "HighlightMountTool";
    public static override iconSpec = "icon-spatial-view-apply";

    public override isCompatibleViewport(_vp: Viewport | undefined, _isSelectedViewChange: boolean): boolean {
        return true;
    }

    public override run(mountData: any, isLoaded: boolean, selectedElevations: string[] = ["All"], zoom: boolean = true): Promise<boolean> {
        super.run()
        const { toolAdmin, viewManager } = IModelApp;
        if (!this.isCompatibleViewport(viewManager.selectedView, false) || !toolAdmin.onInstallTool(this)) {
            return Promise.resolve(false);
        }
        toolAdmin.startPrimitiveTool(this);
        const vp = IModelApp.viewManager.selectedView as ScreenViewport;
        // SampleToolWidget.setModelIndexValues(vp);
        if (isLoaded) {
            if (!Object.entries(mountData.mounts).length) {
                Logger.logWarning("Warning", "HiglightMountTool Data not avilable");
                if(!store.getState().dtvState.applicationState.highlightStates.highlightAll)IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Error, "Information missing, cannot highlight mount.", "", OutputMessageType.Toast));
                return Promise.resolve(false);
            } else {
                if(!store.getState().dtvState.applicationState.highlightStates.highlightAll) Logger.logWarning("Message", "HiglightMountTool data is avilable");
            }
            let found = true;
            let md = IModelApp.viewManager.decorators.filter(e=>e.constructor.name.includes("MountDecorator"))[0] as MountDecorator;
            if(md === undefined){
                if (undefined !== vp && zoom) {    
                    // This approach doesn't work well with camera turned on
                    // vp.zoom(undefined, 0.3, { animateFrustumChange: true });
                }    
                found = false;
                md = new MountDecorator();
                IModelApp.viewManager.addDecorator(md);
            }
            // if(selectedElevations != "All")md.clearGeometry(mountData, selectedElevations);
            const retVal: any = md.useDataAndLoadPipes(mountData, selectedElevations);
                    // IModelApp.viewManager.invalidateDecorationsAllViews();
                    // IModelApp.viewManager.selectedView?.invalidateCachedDecorations(md);
            if (retVal === true) {
                if(found){
                    const allPipes = md.pipes;
                    const tori = md.tori;
                    md.terminate();
                    md.pipes=allPipes;
                    md.tori=tori;
                    IModelApp.viewManager.invalidateDecorationsAllViews();
                    IModelApp.viewManager.selectedView?.invalidateCachedDecorations(md);
                    if(selectedElevations[0] == "All" && !store.getState().dtvState.applicationState.highlightStates.highlightAll)IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Info, "Mounts are successfully loaded.", "", OutputMessageType.Toast));
                }
            } else if(retVal === false){
                IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Info, "Error Loading Mount", "", OutputMessageType.Toast));
            }
        } else {
            for (const dec of IModelApp.viewManager.decorators) {
                const n = dec.constructor.name;
                if (n.includes("MountDecorator")) {
                (dec as MountDecorator).terminate();
                IModelApp.viewManager.dropDecorator(dec);
                // IModelApp.viewManager.decorators.splice(i, 1);
                }
            }
            const isBlankConnection: boolean | undefined = UiFramework.getIModelConnection()?.isBlank;
            if (isBlankConnection) {
                // FrontstageManager.activeFrontstageDef?.getStagePanelDef(StagePanelLocation.Right)?.
                // findWidgetDef("PropertyListWidget")?.setWidgetState(WidgetState.Hidden);
            } else if ((IModelApp as any).listCallback) {
                // (IModelApp as any).listCallback(SampleToolWidget.currentList);
            }
        }
        // IModelApp.viewManager.selectedView?.invalidateDecorations();
            this.exitTool();
        return Promise.resolve(true);
    }

    public onResetButtonUp(_ev: BeButtonEvent): Promise<EventHandled> {
        this.exitTool();
        return Promise.resolve(EventHandled.Yes);
    }

    public onRestartTool(): Promise<void> {
        return Promise.resolve();
    }
}
