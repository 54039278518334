import { Angle, Arc3d, AxisIndex, Box, Cone, CurveChainWithDistanceIndex, GeometryQuery, IndexedPolyface, IndexedPolyfaceVisitor, LineSegment3d, LineString3d, LinearSweep, Loop, Matrix3d, Path, Point3d, PolyfaceBuilder, Range3d, SolidPrimitive, Transform, TransformProps, Vector3d, YawPitchRollAngles } from "@itwin/core-geometry";
import { Cartographic, ColorByName, ColorDef, LinePixels } from "@itwin/core-common";
import { BeButtonEvent, DecorateContext, Decorator, EventHandled, GraphicBuilder, GraphicType, HitDetail, IModelApp, RenderGraphic, ScreenViewport, SelectionTool } from "@itwin/core-frontend";
// import App from "../../components/App";
import { Id64String } from "@itwin/core-bentley";
// import { EquipmentType, SampleToolWidget } from "../frontstages/SampleToolWidget";
import { StagePanelLocation, SyncUiEventDispatcher, UiFramework, WidgetState } from "@itwin/appui-react";
import { IndividualShapeDecorator } from "./IndividualShapeDecorator";
// import { EquipmentData } from "./EditableShapeDecorator";
import { ModifyHandleDecoration } from "../../components/tools/modification/ModifyHandleDecoration";
import { DecoratorHelper } from "./DecoratorHelper";
import { EquipmentsTable, equipmentModel } from "../../components/Tables/EquipmentsTable";
import * as egm96 from "egm96-universal";
import { ConfigManager } from "../../../config/ConfigManager";
import { RootState } from "../../../store/States";
import { EquipmentDataStructure, objectProperties } from "../../../store/detectedData/apiDataTypes";
import { EquipmentType, SyncUiEventIds, unitSystemType } from "../../../store/redux-types";
import { store } from "../../../store/rootReducer";
import EquipmentClient from "../../api/equipment";
import { setEquipmentData } from "../../../store/detectedData/apiDataActionTypes";
import { AppSettings } from "../../../config/AppSettings";
const iModelConnection = UiFramework.getIModelConnection();
export const select = (state: RootState, dataKey: string) => {
  return state.dtvState.featureControls[dataKey];
}

let editModeActive: boolean = false, iTwinDisplayFrontFace: boolean = false, iTwinShowEquipmentShape: boolean = false, all3DObjectsMap: {}, selectedObjectInfo: objectProperties, siteCoordinates;
const listener = () => {
    setCurrentState(store.getState());
}

export interface EquipmentData {
    Azimuth: number;
    Equipment_Name: string;
    Equipment_Type: string;
    Height: number;
    Width: number;
    Thickness: number;
    Roll: number;
    Tilt: number;
    DownTilt: number;
    x_position: number;
    y_position: number;
    z_position: number;
    Model: string;
    Manufacturer: string;
    Elevation_Height: number;
    DisplayName: string;
    equipSpecs?: any;     // {} Optional property to add any equipment/geometry specific details 
    UserDriven?: boolean;
    Face?: string;
    geometryEntry: GeometryEntry;
    shapeId?: number;
    isActive?: boolean;
    mount?: string;
}

function setCurrentState(state: RootState) {
    siteCoordinates = state.detectedData.siteCoordinate;
    editModeActive = state.dtvState.applicationState.isEditModeActive;
    iTwinDisplayFrontFace = state.dtvState.featureControls.iTwinDisplayFrontFace;
    iTwinShowEquipmentShape = state.dtvState.featureControls.showEquipmentShape;
    // equipDataMaps = state.detectedData.equipmentDataMaps;
    // tempEquipMap = equipDataMaps.tempEquipMap;
    all3DObjectsMap = state.detectedData.built3DObjectIdMaps;
}

store.subscribe(listener);

export interface CustomGeometryQuery {
    geometry: GeometryQuery;
    color: ColorDef;
    fill: boolean;
    fillColor: ColorDef;
    lineThickness: number;
    edges: boolean;
    capped: boolean;
    linePixels: LinePixels;
    transientId: Id64String;
    name: string|number;
    type: string;
    modelData: EquipmentData|undefined;
  }
//   export interface GeometryEntry {
//     geometry: any;
//     name: string;
//     modelData?: EquipmentData;
//   }
  
// export interface GeometryEntry {
//   cylinder: Cone;
//   name: string;
//   color: ColorDef;
// }

export interface GeometryEntry {
  geometry: Cone | Box | LinearSweep | Loop | null;
  extraEntries?: any[];
  name: string;
  // modelData?: EquipmentData;
  color?: ColorDef;
}

export enum ShapeType {
  Box,
  Cylinder,
}
export enum CreateGeometryMode {
  New,
  Edit,
  DrawBox,
  DrawFeedlineBox
}
export interface selectedEquipmentShape {transId: Id64String, displayName: string, theEquipment: EquipmentData|null, isModified: boolean, creating: boolean, created: boolean, cloning: boolean};
// tslint:disable:naming-convention
// tslint:disable:no-shadowed-variable
export class ShapeDecorator implements Decorator {
  public useCachedDecorations: true | undefined = true;
  public decoratorName = "ShapeDecorator";

  public shapes: CustomGeometryQuery[] = [];
  public loadedShapes: boolean = false;
  public badEquipment: EquipmentData[] = [];

  public boxes: GeometryEntry[] = [];
  private drawnBoxes: GeometryEntry[] = [];
  private antennaBoxes: GeometryEntry[] = [];
  private defectBoxes: GeometryEntry[] = [];
  private rruBoxes: GeometryEntry[] = [];
  public static equipmentName: string | undefined;
  public static sourceId: any;
  private microWaveCylinders: GeometryEntry[] = [];
  private drawnCylinders: GeometryEntry[] = [];
  private defectBoxInfo: any = [];
  public currJson: EquipmentData[] = [];
  public nameIdMap: Map<string, Id64String> = new Map<string, Id64String>();
  public objectIdMap: Map<Id64String, string> = new Map<string, Id64String>();
  private fill: boolean = true;
  private color: ColorDef = ColorDef.blue;
  private lineThickness: number = 1;
  private edges: boolean = false;
  private capped: boolean = true;
  private linePixels = LinePixels.Solid;
  public equipColors = {
    antenna: {bodyCol: ColorDef.from(71, 54, 165), colorName: ColorByName.royalBlue, faceCol: ColorDef.from(22, 28, 86, 1)},
    microwave: {bodyCol: ColorDef.from(5, 165, 120), colorName: ColorByName.limeGreen, faceCol: ColorDef.from(13, 96, 6, 1)},
    rru: {bodyCol: ColorDef.from(180, 86, 94), colorName: ColorByName.orangeRed, faceCol: ColorDef.from(99, 16, 20, 1)},
  }
  public showFrontFace: boolean = iTwinDisplayFrontFace;
  public showEquipmentShape: boolean = iTwinShowEquipmentShape;
  private static readonly defaultSelectionState: selectedEquipmentShape = {transId: "", displayName: "", theEquipment: null, isModified: false, creating: false, created: false, cloning: false};
  public static selectedEquipment: selectedEquipmentShape = this.defaultSelectionState;
  public resetSelectedEquipment = () => ShapeDecorator.selectedEquipment = ShapeDecorator.defaultSelectionState;
  
  public decorate(context: DecorateContext): void {
    // this.antennaBoxes.forEach((entry) => {
    //   if (!this.drawnBoxes.includes(entry)) {
    //     const builder = PolyfaceBuilder.create();
    //     builder.addBox(entry.box);
    //     const polyface = builder.claimPolyface(false);
    //     this.addGeometry(polyface, this.equipColors.antenna.bodyCol, entry.name);
    //     this.drawnBoxes.push(entry);
    //   }
    // });
    // this.rruBoxes.forEach((entry) => {
    //   if (!this.drawnBoxes.includes(entry)) {
    //     const builder = PolyfaceBuilder.create();
    //     builder.addBox(entry.box);
    //     const polyface = builder.claimPolyface(false);
    //     this.addGeometry(polyface, this.equipColors.rru.bodyCol, entry.name);
    //     this.drawnBoxes.push(entry);
    //   }
    // });
    // this.defectBoxes.forEach((entry) => {
    //     if (!this.drawnBoxes.includes(entry)) {
    //         const builder = PolyfaceBuilder.create();
    //         builder.addBox(entry.box);
    //         const polyface = builder.claimPolyface(false);
    //         const color = ColorDef.fromString("rgb(255, 115, 0)");
    //         this.addGeometry(polyface, color, entry.name);
    //         this.drawnBoxes.push(entry);
    //     }
    // });
    // this.microWaveCylinders.forEach((entry) => {
    //   if (!this.drawnCylinders.includes(entry)) {
    //     const builder = PolyfaceBuilder.create();
    //     builder.addCone(entry.cylinder);
    //     const polyface = builder.claimPolyface(false);
    //     this.addGeometry(polyface, entry.color, entry.name, true);
    //     this.drawnCylinders.push(entry);
    //   }
    // });

    this.createGraphics(context);
  }

  public convertDetectionDataToDecoratorData = (detectedEquipData): EquipmentData => {
    const decEquipData: EquipmentData = {
      Azimuth: detectedEquipData.azimuth,
      Equipment_Name: detectedEquipData.name,
      Equipment_Type: detectedEquipData.type,
      Height: detectedEquipData.height,
      Width: detectedEquipData.width,
      Thickness: detectedEquipData.depth,
      Roll: detectedEquipData.roll,
      isActive: detectedEquipData.isActive,
      Tilt: detectedEquipData.tilt,
      DownTilt: -1*detectedEquipData.tilt,
      x_position: detectedEquipData.xPosition,
      Elevation_Height: detectedEquipData.elevationHeight,
      y_position: detectedEquipData.yPosition,
      z_position: detectedEquipData.zPosition,
      Model: detectedEquipData.model,
      Manufacturer: detectedEquipData.manufacturer,
      DisplayName: detectedEquipData.displayName,
      geometryEntry: {name: "", geometry: null}
    }
    return decEquipData;
  }

  /** Return true if supplied Id represents a pickable decoration created by this decorator. */
  public testDecorationHit(_id: string): boolean {
    SyncUiEventDispatcher.onSyncUiEvent.addListener((args) => {
      if (args.eventIds.has(SyncUiEventIds.RM_Equipment_UnSelected) || args.eventIds.has(SyncUiEventIds.Nothing_Selected)){
        // ShapeDecorator.selectedEquipment = this.defaultSelectionState;
      }
      if (args.eventIds.has(SyncUiEventIds.RM_Equipment_Selected)){
        const oid = this.objectIdMap.get(_id);
        const dn = oid!.split('#')[1];
        const dnSplit = dn.split('_');
        let faceName = `${dnSplit[1]}_face`;
        switch (dnSplit[0]) {
          case "Antenna":
            faceName = `ANT_${faceName}`;
            break;
          case "RRU":
            faceName = `RRU_${faceName}`;
            break;
          case "Micro_Wave":
            faceName = `MW_${faceName}`;
            break;
        
          default:
            break;
        }

        const theEquip = this.shapes.find(e=>e.modelData?.DisplayName == dn);
        const nid = this.nameIdMap.get(dn);
        const fnid = this.nameIdMap.get(faceName);
        iModelConnection?.selectionSet.emptyAll();
        iModelConnection?.selectionSet.add(fnid as Id64String);
        iModelConnection?.selectionSet.add(nid as Id64String);
  
        // this.nameIdMap.get()
      }
    });
    return Array.from(this.nameIdMap.values()).includes(_id);
  }

  /** Return localized tooltip message for the decoration identified by HitDetail.sourceId. */
  public async getDecorationToolTip(_hit: HitDetail): Promise<HTMLElement | string> {
    // const sourceId = _hit.sourceId;
    // const name = Array.from(this.nameIdMap.keys()).find((key) => this.nameIdMap.get(key) === sourceId);
    // if (!name) {
    //   return "Equipment bounding box";
    // }
    // return name;
    const sourceId = _hit.sourceId;
    let name = Array.from(this.nameIdMap.keys()).find((key) => this.nameIdMap.get(key) === sourceId);
    if(name?.match(/face/ig)){
      const equipTransientId = this.objectIdMap.get(name);
      name = this.faceToEquipName(name).name;
    
    }
    let equip: any = this.shapes.find((e)=>e.modelData?.DisplayName == name)?.modelData;
    if (!name)return "Equipment bounding box";
    return equip.DisplayName!;
  }

  public async onDecorationButtonEvent(hit: HitDetail, _ev: BeButtonEvent): Promise<EventHandled> {
    let sourceId = hit.sourceId;
    // SampleToolWidget.selectedList = ListEnum.Equipment;
    ShapeDecorator.sourceId = sourceId;
    let name = Array.from(this.nameIdMap.keys()).find((key) => this.nameIdMap.get(key) === sourceId);

    if(name!.includes('face')){
      const retVal = this.faceToEquipSelect(name!);
      name = retVal.name;
      sourceId = retVal.id;
      const iModelConnection = UiFramework.getIModelConnection();
      const equipId = this.nameIdMap.get(name);
      const equipId2 = this.objectIdMap.get(name);
      iModelConnection?.selectionSet.emptyAll();
      iModelConnection?.selectionSet.add(sourceId as Id64String);
      iModelConnection?.selectionSet.add(equipId as Id64String);
      ShapeDecorator.selectedEquipment = {...ShapeDecorator.selectedEquipment, transId: equipId!, displayName: retVal.name, theEquipment: JSON.parse(JSON.stringify(this.shapes.find(e=>e.modelData?.DisplayName==retVal.name)?.modelData!)), isModified: false}
    } else ShapeDecorator.selectedEquipment = {...ShapeDecorator.selectedEquipment, transId: sourceId!, displayName: name!, theEquipment: JSON.parse(JSON.stringify(this.shapes.find(e=>e.modelData?.DisplayName==name)?.modelData!)), isModified: false}
    return EventHandled.No;
  }

public createGeometry = (equipmentJson: EquipmentData, mode: CreateGeometryMode = CreateGeometryMode.DrawBox, _opacity: number = store.getState().detectedData.objectOpacityState.equipment.value, _scaleFactor: Vector3d = new Vector3d(1, 1, 1)) => {
    let theGeometry, edges: boolean = this.edges, capped: boolean = this.capped, color = this.equipColors.antenna.bodyCol, builder, polyface, extraGeometries=[];
    // if(equipmentJson.isActive == false)_opacity = 0.9 * _opacity;
    const equipPolygons = EquipmentsTable.equipPolygons;
    const allEquipmentManModels = EquipmentsTable.formattedEquipmentManModels.manModels;
    const inactivePanelCol = [73, 104, 154];
    const inactiveRCCol = [130, 93, 93];
    const inactiveRRUCol = [112, 55, 55];
    const inactiveMWCol = [81, 102, 78];
    const inactiveColor = ColorByName.magenta;
    // if(equipmentJson.Equipment_Type == EquipmentType.Antenna){
    //   const inchDims = {height: equipmentJson.Height*39.3701, width: equipmentJson.Width*39.3701, depth: equipmentJson.Thickness*39.3701};
    //   const closerPolyShapes = equipPolygons.filter(e=>{
    //       if(Math.abs(e.width-inchDims.width) < 2 && Math.abs(e.depth-inchDims.depth) < 2 && ((e.type == "PANEL" && equipmentJson.Equipment_Type == EquipmentType.Antenna)))return e;
    //   });
      const equipPoly = equipPolygons.find(ep=>ep.manufacturer == equipmentJson.Manufacturer && ep.model == equipmentJson.Model);
      if(equipPoly)equipmentJson.shapeId=equipPoly.polygonPointId;
      // if(closerPolyShapes.length){
      //     // console.log('selectedShape for equipment: ', equipmentJson.DisplayName, closerPolyShapes[0], closerPolyShapes);
      //     equipmentJson.shapeId=closerPolyShapes[0].polygonPointId;
      // }
    // }
      
    if(equipmentJson.Manufacturer != "UPT:NewEquip" && equipmentJson.Model != "UPT:NewEquip-001"){
      const theManufacturer = allEquipmentManModels.find(e=>e.manufacturer == equipmentJson.Manufacturer);
      // const modelInfo: equipmentModel = theManufacturer?.model.find(e=>e.name == equipmentJson.Model)!;
      const modelIndex: number = theManufacturer?.models.findIndex(e=>e.name == equipmentJson.Model)!;
      const modelInfo = theManufacturer?.models[modelIndex];
      if(modelInfo){
        const modelHeight = modelInfo.specification.height/39.3701;
        const modelWidth = modelInfo.specification.width/39.3701;
        const modelDepth = modelInfo.specification.depth/39.3701;
        if(equipmentJson.shapeId){
          equipmentJson.equipSpecs = {
            ...equipmentJson.equipSpecs, 
            heightDiff: modelHeight-equipmentJson.Height,
            widthDiff: modelWidth-equipmentJson.Width,
            depthDiff: modelDepth-equipmentJson.Thickness,
          }
        }
        equipmentJson.Height = modelHeight;
        equipmentJson.Width = modelWidth;
        equipmentJson.Thickness = modelDepth;
      }
    }

    if(mode == CreateGeometryMode.DrawBox){
    //   const theGeometry = this.constructBoxGeometry(equipmentJson!);

        // theGeometry = this.constructBoxGeometry(equipmentJson!);
      switch (equipmentJson.Equipment_Type) {
        case EquipmentType.Antenna:
        case EquipmentType.RRU:
          // This if condition is added to temporarily draw SQUIDs based on their name and not the type as SQUID until the detection supports SQUID.
          if(equipmentJson.Equipment_Name.match(/SQUID/ig)){
            theGeometry = this.drawSQUID(equipmentJson);
            extraGeometries = theGeometry.extraEntries;
            theGeometry = theGeometry.geometry;  
          } else theGeometry = this.constructBoxGeometry(equipmentJson);
          break;
        case EquipmentType.Microwave:
          theGeometry = this.drawCylinder(equipmentJson);
          if(theGeometry.extraEntries)extraGeometries = theGeometry.extraEntries;
          theGeometry = theGeometry.geometry;
          break;
        case EquipmentType.SQUID:
          theGeometry = this.drawSQUID(equipmentJson);
          extraGeometries = theGeometry.extraEntries;
          theGeometry = theGeometry.geometry;
          break;
      
        default:
          break;
      }
      // theGeometry = equipmentJson.Equipment_Type == EquipmentType.Microwave || equipmentJson.Equipment_Type == EquipmentType.SQUID ? this.drawCylinder(equipmentJson)?.geometry : this.constructBoxGeometry(equipmentJson);

      // const entry: GeometryEntry = {
      //   geometry: theGeometry,
      //   name: equipmentJson.DisplayName,
      //   // modelData: json,
      // };
      // equipmentJson.geometryEntry = entry;

      builder = PolyfaceBuilder.create();
      let theColor;
      switch (equipmentJson.Equipment_Type) {
        case EquipmentType.Antenna:
            // theColor = equipmentJson.isActive == true ? ColorByName.royalBlue : ColorDef.from(inactivePanelCol[0], inactivePanelCol[1], inactivePanelCol[2]).tbgr;
            theColor = equipmentJson.isActive == true ? ColorByName.royalBlue : inactiveColor;
            break;
        case EquipmentType.RRU:
            theColor = equipmentJson.isActive == true ? ColorByName.red : inactiveColor;
            // This if condition is added to temporarily draw SQUIDs based on their name and not the type as SQUID until the detection supports SQUID.
            if(equipmentJson.Equipment_Name.match(/SQUID/ig))theColor = equipmentJson.isActive == true  ? ColorByName.orangeRed : inactiveColor;
            break;
        case EquipmentType.SQUID:
            theColor = equipmentJson.isActive == true  ? ColorByName.orangeRed : inactiveColor;
            break;
        case EquipmentType.Microwave:
          theColor = equipmentJson.isActive == true ? ColorByName.seaGreen : inactiveColor;
          break;
        default:
            theColor = ColorByName.aqua;
            break;
        }
            
      if(equipmentJson.shapeId){
          builder.addGeometryQuery(theGeometry);

        //   builder.addBox(theGeometry);
          polyface = builder.claimPolyface(false);
          color = ColorDef.fromTbgr(ColorDef.withTransparency(ColorDef.create(theColor).tbgr,  255-Math.round(255*_opacity)))
      } else{

        // if(equipmentJson.Equipment_Type == EquipmentType.Microwave || equipmentJson.Equipment_Type == EquipmentType.SQUID)builder.addCone(theGeometry); else builder.addBox(theGeometry);
        if(equipmentJson.Equipment_Type == EquipmentType.Microwave || equipmentJson.Equipment_Type == EquipmentType.SQUID || equipmentJson.Equipment_Name.match(/SQUID/ig))builder.addCone(theGeometry); else builder.addBox(theGeometry);
          polyface = builder.claimPolyface(false);
          color = ColorDef.fromTbgr(ColorDef.withTransparency(ColorDef.create(theColor).tbgr,  255-Math.round(255*_opacity)))
      }
      const entry: GeometryEntry = {
        geometry: theGeometry,
        name: equipmentJson.DisplayName,
        // modelData: equipmentJson,
      };
      equipmentJson.geometryEntry = entry;

    } else if(mode == CreateGeometryMode.New){
      equipmentJson.Elevation_Height = equipmentJson.z_position-siteCoordinates.utm.z;
      // theGeometry = this.constructBoxGeometry(equipmentJson!, scaleFactor);
      // theGeometry = equipmentJson.Equipment_Type == EquipmentType.Microwave ? this.drawCylinder(equipmentJson)?.geometry : equipmentJson.Equipment_Type == EquipmentType.SQUID ? this.drawSQUID(equipmentJson) : this.constructBoxGeometry(equipmentJson);
      switch (equipmentJson.Equipment_Type) {
        case EquipmentType.Antenna:
        case EquipmentType.RRU:
          // This if condition is added to temporarily draw SQUIDs based on their name and not the type as SQUID until the detection supports SQUID.
          if(equipmentJson.Equipment_Name.match(/SQUID/ig)){
            theGeometry = this.drawSQUID(equipmentJson);
            extraGeometries = theGeometry.extraEntries;
            theGeometry = theGeometry.geometry;  
          } else theGeometry = this.constructBoxGeometry(equipmentJson);
          break;
        case EquipmentType.Microwave:
          theGeometry = this.drawCylinder(equipmentJson);
          if(theGeometry.extraEntries)extraGeometries = theGeometry.extraEntries;
          theGeometry = theGeometry.geometry;
          break;
        case EquipmentType.SQUID:
          theGeometry = this.drawSQUID(equipmentJson);
          extraGeometries = theGeometry.extraEntries;
          theGeometry = theGeometry.geometry;
          break;
      
        default:
          break;
      }
      // theGeometry = equipmentJson.Equipment_Type == EquipmentType.Microwave || equipmentJson.Equipment_Type == EquipmentType.SQUID ? this.drawCylinder(equipmentJson)?.geometry : this.constructBoxGeometry(equipmentJson, _scaleFactor);

      let theColor;
      switch (equipmentJson.Equipment_Type) {
        case EquipmentType.Antenna:
          // theColor = equipmentJson.isActive == true  ? ColorByName.royalBlue : ColorDef.from(inactivePanelCol[0], inactivePanelCol[1], inactivePanelCol[2]).tbgr;
          theColor = equipmentJson.isActive == true  ? ColorByName.royalBlue : inactiveColor;
          break;
        case EquipmentType.RRU:
          // theColor = equipmentJson.isActive == true  ? ColorByName.red :  ColorDef.from(inactiveRRUCol[0], inactiveRRUCol[1], inactiveRRUCol[2]).tbgr;
          theColor = equipmentJson.isActive == true  ? ColorByName.red :  inactiveColor;
          // This if condition is added to temporarily draw SQUIDs based on their name and not the type as SQUID until the detection supports SQUID.
          if(equipmentJson.Equipment_Name.match(/SQUID/ig))theColor = equipmentJson.isActive == true  ? ColorByName.orangeRed : inactiveColor;
          break;
        case EquipmentType.SQUID:
          // theColor = equipmentJson.isActive == true  ? ColorByName.orangeRed : ColorDef.from(inactiveRCCol[0], inactiveRCCol[1], inactiveRCCol[2]).tbgr;
          theColor = equipmentJson.isActive == true  ? ColorByName.orangeRed : inactiveColor;
            break;
        case EquipmentType.Microwave:
          // theColor = equipmentJson.isActive == true  ? ColorByName.seaGreen :  ColorDef.from(inactiveMWCol[0], inactiveMWCol[1], inactiveMWCol[2]).tbgr;
          theColor = equipmentJson.isActive == true  ? ColorByName.seaGreen :  inactiveColor;
            break;
        default:
            theColor = ColorByName.aqua;
            break;
      }

      // equipmentJson.geometryEntry!.geometry = theGeometry;
      builder = PolyfaceBuilder.create();
      if(equipmentJson.shapeId){
          builder.addGeometryQuery(theGeometry);

        //   builder.addBox(theGeometry);
          polyface = builder.claimPolyface(false);
          // color = ColorDef.fromTbgr(ColorDef.withTransparency(ColorDef.create(this.equipColors.antenna.colorName).tbgr,  255-Math.round(255*_opacity)))
          color = ColorDef.fromTbgr(ColorDef.withTransparency(ColorDef.create(theColor).tbgr,  255-Math.round(255*_opacity)))
      } else{

        // if(equipmentJson.Equipment_Type == EquipmentType.Microwave || equipmentJson.Equipment_Type == EquipmentType.SQUID)builder.addCone(theGeometry); else builder.addBox(theGeometry);
        if(equipmentJson.Equipment_Type == EquipmentType.Microwave || equipmentJson.Equipment_Type == EquipmentType.SQUID || equipmentJson.Equipment_Name.match(/SQUID/ig))builder.addCone(theGeometry); else builder.addBox(theGeometry);
        polyface = builder.claimPolyface(false);
      }
      const entry: GeometryEntry = {
        geometry: theGeometry,
        name: equipmentJson.DisplayName,
        // modelData: equipmentJson,
      };
      equipmentJson.geometryEntry = entry;

      this.saveIntoLocalObj(equipmentJson, mode);
      // color = ColorDef.fromTbgr(ColorDef.withTransparency(ColorDef.create(this.equipColors.antenna.colorName).tbgr,  255-Math.round(255*_opacity)))
      color = ColorDef.fromTbgr(ColorDef.withTransparency(ColorDef.create(theColor).tbgr,  255-Math.round(255*_opacity)))
    } 
    
    if(mode != CreateGeometryMode.Edit){
      this.addGeometry(polyface, color, equipmentJson.DisplayName!, edges, capped, equipmentJson!);
      const builder = PolyfaceBuilder.create();
      if(extraGeometries.length)extraGeometries.forEach((eg: GeometryEntry)=>{
        if(equipmentJson.Equipment_Type == EquipmentType.SQUID){
          color = ColorDef.fromTbgr(ColorDef.withTransparency(ColorDef.create(equipmentJson.isActive == true  ? ColorByName.orangeRed : inactiveColor).tbgr,  255-Math.round(255*_opacity)));
        } else {
          color = ColorDef.fromTbgr(ColorDef.withTransparency(ColorDef.create(equipmentJson.isActive == true  ? ColorDef.create(this.equipColors.microwave.bodyCol.tbgr).tbgr : inactiveColor).tbgr,  255-Math.round(255*_opacity)));
          color = ColorDef.fromTbgr(ColorDef.withTransparency(ColorDef.create(eg.color?.tbgr).tbgr,  255-Math.round(255*_opacity)));
        }
          builder.addGeometryQuery(eg.geometry!);
          polyface = builder.claimPolyface(false);
         
          const newEJ: EquipmentData = JSON.parse(JSON.stringify(equipmentJson));
          newEJ.DisplayName = eg.name;
          this.addGeometry(polyface!, color, newEJ.DisplayName!, edges, capped, newEJ!);
        });
        if(!equipmentJson.shapeId || !iTwinShowEquipmentShape){
            const entry: GeometryEntry = {
              geometry: theGeometry,
              name: equipmentJson.DisplayName,
            };
            // if(this.showFrontFace)equipmentJson.Equipment_Type == EquipmentType.Microwave ? this.drawMWCylFace(entry, equipmentJson, _opacity) : equipmentJson.Equipment_Type.match(/Antenna|RRU/) && !equipmentJson.Equipment_Name.match(/SQUID/ig) ? this.drawAntRruFace(entry, equipmentJson, _opacity) : null;
            if(this.showFrontFace)equipmentJson.Equipment_Type == EquipmentType.Microwave ? null : equipmentJson.Equipment_Type.match(/Antenna|RRU/) && !equipmentJson.Equipment_Name.match(/SQUID/ig) ? this.drawAntRruFace(entry, equipmentJson, _opacity) : null;
        }
    }
  }




  public async loadShapes(EquipmentData: EquipmentDataStructure[] = [], _selectedEquipment: string[] = ["All"]) {
    // const selectedEquipment = SampleToolWidget.sampleToolWidget.state.operatorFilterData.selectedEquipment.length ? SampleToolWidget.sampleToolWidget.state.operatorFilterData.selectedEquipment: ["All"];;
    // const selectedEquipment = ["All"];;
    this.defectBoxes = [];
    this.boxes = [];
    this.shapes = [];
    this.drawnBoxes = [];
    this.antennaBoxes = [];
    this.rruBoxes = [];
    this.drawnCylinders = [];
    this.loadedShapes = false;
    this.nameIdMap = new Map<string, Id64String>();
    IModelApp.viewManager.selectedView?.invalidateDecorations();
    IModelApp.viewManager.selectedView?.invalidateCachedDecorations(this);
    const towerdata = store.getState().detectedData.towerStructureData;
    const concreteHeight = ConfigManager.AGL ? Object.entries(towerdata).filter((f) => f[0] === "concrete_height")[0][1] : 0;

    // const equipNamePositionMap: Map<string, any> = new Map(equipDataMaps.equipNamePositionMap);
    if (EquipmentsTable.equipNamePositionMap.size === 0) {
      IModelApp.viewManager.selectedView?.invalidateDecorations();
    }
    // data = data.length ? await FeederLineClient.getequipmentData(store.getState().auth.accessTokenStatePrivateAPI.accessToken!) : data;//Temporary example data.
    const iModel = UiFramework.getIModelConnection()!;
  
      const storedED = EquipmentData.length ? EquipmentData : store.getState().detectedData.equipmentData;
      let data: EquipmentDataStructure[];
      if(storedED.length === 0){
        data = await EquipmentClient.getAllEquipmentJson(store.getState().auth.accessTokenStatePrivateAPI.accessToken, "v1.1");
        if(data) store.dispatch(setEquipmentData(data));
        else return false;
      } else data = storedED;

      // const localBaseCenter = new Point3d(mp.x, mp.y, -0.5 * theHeight);
      let flm: EquipmentData[] = [];
      const o = iModel.spatialToCartographicFromEcef(iModel.projectExtents.high!);
      const maps = new Map();
      data.map((e)=>{
        const temp: EquipmentData = {
          Azimuth: 0,
          Equipment_Name: "",
          Equipment_Type: "",
          Height: 0,
          Width: 0,
          Thickness: 0,
          Roll: 0,
          Tilt: 0,
          DownTilt: 0,
          x_position: 0,
          y_position: 0,
          z_position: 0,
          Elevation_Height: 0,
          Model: "",
          Manufacturer: "",
          DisplayName: "",
          geometryEntry: {name: "", geometry: null}
        };

        temp.Azimuth = e.azimuth;
        temp.Equipment_Name = e.name;
        temp.Equipment_Type = e.type;
        temp.Height = e.height;
        temp.Elevation_Height = concreteHeight + e.elevationHeight;
        if (e.manufacturer !== "")temp.Manufacturer = e.manufacturer;
        if (e.model !== "")temp.Model = e.model;

        temp.Roll = e.roll;
        temp.Thickness = e.depth;
        temp.Tilt = e.tilt;
        temp.DownTilt = -1*e.tilt;
        temp.Width = e.width;
        temp.DisplayName = e.displayName == undefined || e.displayName == null ? e.name : e.displayName;
        temp.x_position = e.xPosition;
        temp.y_position = e.yPosition;
        temp.z_position = e.zPosition;
        temp.isActive = e.isActive;
        temp.mount = e.plat;

        if(e.name!="Tower" && e.xPosition != null && e.yPosition != null && e.zPosition != null && e.height != null && e.width != null && e.depth != null)maps.set(e.name, temp);

        //   flm.push(...this.getJson(e, iModel, o, flm.length));
        })

    maps.forEach((json: EquipmentData)=> {
      if (!json) {
        return;
      }
    //   let entry: any;
      if(_selectedEquipment[0] == "All" || _selectedEquipment.indexOf(json.mount!) != -1){
        //   entry = this.drawBox(json);

        // let theGeom = json.Equipment_Type == "Micro_Wave" ? this.drawCylinder(json)?.geometry! : this.constructBoxGeometry(json)!;

        // const entry: GeometryEntry = {
        //   geometry: theGeom,
        //   name: json.Equipment_Name?.toString(),
        //   // modelData: json,
        // };
        if(json.x_position == null || json.y_position == null || json.z_position == null){
          this.badEquipment.push(json);
        } else this.saveIntoLocalObj({...json});
        // tem.push(retVal?.jsonObj!);
        // tempAllIdMaps = new Map(retVal?.objectIdMap);
      }
    })
    // store.dispatch(setEquipmentDataMaps({...equipDataMaps, tempEquipMap: tem}));
    // store.dispatch(addToBuilt3DObjectIdsMap(new Map(tempAllIdMaps)));
    this.loadedShapes = true;
    IModelApp.viewManager.selectedView?.invalidateCachedDecorations(this);
    return this.loadedShapes;
  }

  public resetObjectIds() {
    this.objectIdMap = new Map<string, Id64String>();
    this.shapes.forEach(s=>{if(!s.name.toString().match(/face|_RCBot|_RCTop|MWMid/))this.objectIdMap.set(s.transientId, `equipGeom#${s.modelData?.DisplayName}`)});
    return this.objectIdMap;
  }

  /**
   * Save Entry into local object
   * @param json JSON object of Equipment Position Information
   * @param entry Box/Cone object
   * @returns void
   */
  private saveIntoLocalObj(json: EquipmentData, mode: CreateGeometryMode = CreateGeometryMode.DrawBox) {
    // const index = this.currJson.findIndex((i) => i.Equipment_Name === json.Equipment_Name);
    // // const index = tempEquipMap.findIndex((i) => i.Equipment_Name === json.Equipment_Name);
    // if (index === -1) {
    //   // if (!json.Equipment_Name.match(/Micro_Wave/i)) {
        // this.boxes.push(_entry);
    //   // }
    //   this.currJson.push(json);
    //   // tem.push(json);
    // }
    const vp = IModelApp.viewManager.selectedView;``
    if (vp === undefined) return;
    const nextId = vp.iModel.transientIds.getNext();
    this.objectIdMap.set(nextId, `equipGeom#${json.DisplayName}`);
    this.nameIdMap.set(`${json.DisplayName}`, nextId);
    if(mode == CreateGeometryMode.DrawBox)this.createGeometry(json)    
    return {jsonObj: json, objectIdMap: this.objectIdMap}
  }

  /**
   * Draw Boxes with Position Information
   * @param equipJson EquipPosition information
   */
  private drawBox(equipJson: EquipmentData): GeometryEntry {
    const box = this.constructBoxGeometry(equipJson);
    const entry: GeometryEntry = {
      geometry: box,
      name: equipJson.DisplayName,
    };
    // Match by "type" instead of name
    if (equipJson.Equipment_Name.match(/Antenna/i)) {
      this.antennaBoxes.push(entry);
    } else if (equipJson.Equipment_Name.match(/RRU/i)) {
      this.rruBoxes.push(entry);
    }
    //Drawing equipment's face
    // if(this.showFrontFace)this.drawAntRruFace(entry, equipJson);
    return entry;
  }

  public faceToEquipName(faceName: string){
    let name: string = "", type: EquipmentType | null = null;
    if(faceName.includes("ANT")){
      name=`Antenna_${faceName.split('_')[1]}`;
      type = EquipmentType.Antenna;
    } else if(faceName.includes('MW')){
      name=`Micro_Wave_${faceName.split('_')[1]}`;
      type = EquipmentType.Microwave;
    } else if(faceName.includes('RRU')){
      name=`RRU_${faceName.split('_')[1]}`;
      type = EquipmentType.RRU;
    }
    return {name, type}
  }

  
  public faceToEquipSelect(faceName: string){
    const antId=this!.nameIdMap.get(faceName!);
    // App.iModelConnection!.selectionSet.emptyAll();
    // App.iModelConnection!.selectionSet.add(antId as string);

    const {name, type} = this.faceToEquipName(faceName);
    // SampleToolWidget.setEquipmentType(type!);

    // SampleToolWidget.selectedBoxName=name;
    ShapeDecorator.sourceId=antId;
    // SampleToolWidget.selectedBoxInformation = SampleToolWidget.equipNameInfoMap.get(name);
    IndividualShapeDecorator.selectedEquipName = name!;
    
    return {name: name, id: antId as string};
}
  
  // public faceToEquipSelect(name: string){
  //   // const equip3DMaps = store.getState().detectedData
  //   const iModelC = UiFramework.getIModelConnection();
  //   const sourceId = store.getState().detectedData["equipmentDataObjectIdMaps"].objectIdMap.get(name!);
  //   if(name.includes("ANT")){
  //     name=`Antenna_${name.split('_')[1]}`;
  //     // SampleToolWidget.setEquipmentType(EquipmentType.Antenna);
  //   } else if(name.includes('MW')){
  //     name=`Micro_Wave_${name.split('_')[1]}`;
  //     // SampleToolWidget.setEquipmentType(EquipmentType.Microwave);
  //   } else if(name.includes('RRU')){
  //     name=`RRU_${name.split('_')[1]}`;
  //     // SampleToolWidget.setEquipmentType(EquipmentType.RRU);
  //   }
  //   const antId=store.getState().detectedData["equipmentDataObjectIdMaps"].objectIdMap.get(name!);
  //   // SampleToolWidget.selectedBoxName=name;
  //   ShapeDecorator.sourceId=antId;
  //   // SampleToolWidget.selectedBoxInformation = SampleToolWidget.equipNameInfoMap.get(name);
  //   IndividualShapeDecorator.selectedEquipName = name!;
    
  //   return {name: name, id: antId as string};
  // }

  /**
   * Draw Cylinders
   * @param equipJson Equipment Position Information
   * @returns Cone object
   */
  private drawCylinder(equipJson: EquipmentData): GeometryEntry | undefined {
    const newEquipJson: EquipmentData = JSON.parse(JSON.stringify(equipJson));
    newEquipJson.equipSpecs={frontRad:1, backRad:1}
    
    let factors = {backPtFact: 0.5, frontPtFact: 0.5, frontRadFact: 0.5, backRadFact: 0.5};
    if(this.showFrontFace){
    //   factors = {backPtFact: 1.05, frontPtFact: 0.725, frontRadFact: 0.51, backRadFact: 0.52};
      factors = {backPtFact: 0.5, frontPtFact: 0.405, frontRadFact: 0.2, backRadFact: 0.51};
    }

    let localPoint = new Point3d(0, 0, 0);
    let backPt = localPoint.plusScaled(Vector3d.unitY(), -factors.backPtFact * newEquipJson.Thickness);
    let frontPt = localPoint.plusScaled(Vector3d.unitY(), factors.frontPtFact * newEquipJson.Thickness);
    let frontRad = (newEquipJson.equipSpecs.frontRad == undefined ? newEquipJson.Width : newEquipJson.equipSpecs.frontRad*newEquipJson.Width) * factors.frontRadFact;
    let backRad = (newEquipJson.equipSpecs.backRad == undefined ? newEquipJson.Width : newEquipJson.equipSpecs.backRad*newEquipJson.Width) * factors.backRadFact;

    // const cylinder = this.constructConeGeometry(newEquipJson, new Vector3d(0, 0, 0), backPt, frontPt, frontRad, backRad);
    const hdRatio = equipJson.Width/equipJson.Thickness;
    let entry: GeometryEntry;
    // if(equipJson.Width > 0.6){
    // if(hdRatio >= 1.3){
    //   // if(equipJson.Width > 1 && equipJson.Width < 1.2){
    //   if(hdRatio >= 1.3 && hdRatio <= 1.8 && equipJson.Width < 1.7){
    //     factors = {backPtFact: -0.5, frontPtFact: -0.02, frontRadFact: 0.1, backRadFact: 0.2};
    //   } else {
    //     factors = {backPtFact: -0.5, frontPtFact: -0.2, frontRadFact: 0.2, backRadFact: 0.5};
    //   }
    //   backPt = localPoint.plusScaled(Vector3d.unitY(), factors.backPtFact * newEquipJson.Thickness);
    //   frontPt = localPoint.plusScaled(Vector3d.unitY(), factors.frontPtFact * newEquipJson.Thickness);
    //   frontRad = (newEquipJson.equipSpecs.frontRad == undefined ? newEquipJson.Width : newEquipJson.equipSpecs.frontRad*newEquipJson.Width) * factors.frontRadFact;
    //   backRad = (newEquipJson.equipSpecs.backRad == undefined ? newEquipJson.Width : newEquipJson.equipSpecs.backRad*newEquipJson.Width) * factors.backRadFact;
    //   const topCylinder = this.constructConeGeometry(newEquipJson, new Vector3d(0, 0, 0), backPt, frontPt, frontRad, backRad);
    //   // const topEntry: GeometryEntry = {
    //   //   name: newEquipJson.Equipment_Name+"_RCTop",
    //   //   geometry: topCylinder!,
    //   //   color: this.equipColors.microwave.bodyCol,
    //   // };

    //   // if(equipJson.Width > 1 && equipJson.Width < 1.2){
    //   if(hdRatio >= 1.3 && hdRatio <= 1.8 && equipJson.Width < 1.7){
    //     factors = {backPtFact: -0.02, frontPtFact: 0.35, frontRadFact: 0.2, backRadFact: 0.5};
    //   } else {
    //     factors = {backPtFact: -0.2, frontPtFact: 0.45, frontRadFact: 0.5, backRadFact: 0.5};
    //   }
      
    //   backPt = localPoint.plusScaled(Vector3d.unitY(), factors.backPtFact * newEquipJson.Thickness);
    //   frontPt = localPoint.plusScaled(Vector3d.unitY(), factors.frontPtFact * newEquipJson.Thickness);
    //   frontRad = (newEquipJson.equipSpecs.frontRad == undefined ? newEquipJson.Width : newEquipJson.equipSpecs.frontRad*newEquipJson.Width) * factors.frontRadFact;
    //   backRad = (newEquipJson.equipSpecs.backRad == undefined ? newEquipJson.Width : newEquipJson.equipSpecs.backRad*newEquipJson.Width) * factors.backRadFact;
    //   const botCylinder = this.constructConeGeometry(newEquipJson, new Vector3d(0, 0, 0), backPt, frontPt, frontRad, backRad, !this.showFrontFace);
    //   const botEntry: GeometryEntry = {
    //     name: newEquipJson.DisplayName+"_MWMid",
    //     geometry: botCylinder!,
    //     // color: ColorDef.create(ColorByName.red),
    //     color: equipJson.isActive ? this.equipColors.microwave.bodyCol : ColorDef.create(ColorByName.magenta),
    //   };
      
    //   // if(equipJson.Width > 1 && equipJson.Width < 1.2){
    //   if(hdRatio >= 1.3 && hdRatio <= 1.8 && equipJson.Width < 1.7){
    //     factors = {backPtFact: 0.35, frontPtFact: 0.5, frontRadFact: 0.5, backRadFact: 0.51};
    //   } else {
    //     factors = {backPtFact: 0.45, frontPtFact: 0.5, frontRadFact: 0.5, backRadFact: 0.51};
    //   }      
    //   backPt = localPoint.plusScaled(Vector3d.unitY(), factors.backPtFact * newEquipJson.Thickness);
    //   frontPt = localPoint.plusScaled(Vector3d.unitY(), factors.frontPtFact * newEquipJson.Thickness);
    //   frontRad = (newEquipJson.equipSpecs.frontRad == undefined ? newEquipJson.Width : newEquipJson.equipSpecs.frontRad*newEquipJson.Width) * factors.frontRadFact;
    //   backRad = (newEquipJson.equipSpecs.backRad == undefined ? newEquipJson.Width : newEquipJson.equipSpecs.backRad*newEquipJson.Width) * factors.backRadFact;
    //   const faceCylinder = this.constructConeGeometry(newEquipJson, new Vector3d(0, 0, 0), backPt, frontPt, frontRad, backRad);
    //   const faceEntry: GeometryEntry = {
    //     name: newEquipJson.DisplayName+"_MW_face",
    //     geometry: faceCylinder!,
    //     color: equipJson.isActive ? this.equipColors.microwave.faceCol : ColorDef.create(ColorByName.magenta),
    //   };

    //   return  {
    //     name: newEquipJson.Equipment_Name,
    //     geometry: topCylinder!,
    //     extraEntries: [botEntry, faceEntry],
    //     color: this.equipColors.microwave.bodyCol,
    //   };

    // } else {
      // if (cylinder) {
        factors = {backPtFact: 0.5, frontPtFact: 0.405, frontRadFact: 0.5, backRadFact: 0.50};
  
        localPoint = new Point3d(0, 0, 0);
        backPt = localPoint.plusScaled(Vector3d.unitY(), -factors.backPtFact * newEquipJson.Thickness);
        frontPt = localPoint.plusScaled(Vector3d.unitY(), factors.frontPtFact * newEquipJson.Thickness);
        frontRad = (newEquipJson.equipSpecs.frontRad == undefined ? newEquipJson.Width : newEquipJson.equipSpecs.frontRad*newEquipJson.Width) * factors.frontRadFact;
        backRad = (newEquipJson.equipSpecs.backRad == undefined ? newEquipJson.Width : newEquipJson.equipSpecs.backRad*newEquipJson.Width) * factors.backRadFact;
  
  
        const cylinder = this.constructConeGeometry(newEquipJson, new Vector3d(0, 0, 0), backPt, frontPt, frontRad, backRad);

        factors = {backPtFact: 0.405, frontPtFact: 0.5, frontRadFact: 0.5, backRadFact: 0.51};
      
        backPt = localPoint.plusScaled(Vector3d.unitY(), factors.backPtFact * newEquipJson.Thickness);
        frontPt = localPoint.plusScaled(Vector3d.unitY(), factors.frontPtFact * newEquipJson.Thickness);
        frontRad = (newEquipJson.equipSpecs.frontRad == undefined ? newEquipJson.Width : newEquipJson.equipSpecs.frontRad*newEquipJson.Width) * factors.frontRadFact;
        backRad = (newEquipJson.equipSpecs.backRad == undefined ? newEquipJson.Width : newEquipJson.equipSpecs.backRad*newEquipJson.Width) * factors.backRadFact;
        const faceCylinder = this.constructConeGeometry(newEquipJson, new Vector3d(0, 0, 0), backPt, frontPt, frontRad, backRad);
        const faceEntry: GeometryEntry = {
          name: newEquipJson.DisplayName+"_MW_face",
          geometry: faceCylinder!,
          color: this.equipColors.microwave.faceCol,
        };
  
        entry = {
          name: newEquipJson.Equipment_Name,
          extraEntries: [faceEntry],
          geometry: cylinder!,
          color: this.equipColors.microwave.bodyCol,
        };
        // this.microWaveCylinders.push(entry);
        // if(this.showFrontFace)this.drawMWCylFac5e(entry, newEquipJson);
        return entry;
      // }
    // }

    return undefined;
  }

  /**
   * Draw Cylinders
   * @param equipJson Equipment Position Information
   * @returns Cone object
   */
  private drawSQUID(equipJson: EquipmentData): GeometryEntry | undefined {
    const newEquipJson: EquipmentData = JSON.parse(JSON.stringify(equipJson));
    newEquipJson.equipSpecs={frontRad:1, backRad:1}
    
    let factors = {backPtFact: 0.5, frontPtFact: 0.5, frontRadFact: 0.5, backRadFact: 0.5};
    if(this.showFrontFace){
    //   factors = {backPtFact: 1.05, frontPtFact: 0.725, frontRadFact: 0.51, backRadFact: 0.52};
      factors = {backPtFact: 0.5, frontPtFact: 0.405, frontRadFact: 0.25, backRadFact: 0.51};
    }

    const localPoint = new Point3d(0, 0, 0);
    let backPt, frontPt, frontRad, backRad; 

    const backPtFactor = -0.46, frontPtFactor = 0.38;
    // if(newEquipJson.Equipment_Type == EquipmentType.SQUID){
    if(newEquipJson.Equipment_Type == EquipmentType.SQUID || newEquipJson.Equipment_Type == EquipmentType.RRU){
      backPt = localPoint.plusScaled(Vector3d.unitY(), backPtFactor * newEquipJson.Height);
      frontPt = localPoint.plusScaled(Vector3d.unitY(), frontPtFactor * newEquipJson.Height);
      factors = {backPtFact: 0.5, frontPtFact: 0.405, frontRadFact: 0.50, backRadFact: 0.55};
      frontRad = newEquipJson.Width * factors.frontRadFact;
      backRad = newEquipJson.Width * factors.backRadFact;
    }

    let theGeometry, edges: boolean = this.edges, capped: boolean = this.capped, color = this.equipColors.rru.bodyCol, builder, polyface, _opacity: number = store.getState().detectedData.objectOpacityState.equipment.value;
    color = ColorDef.fromTbgr(ColorDef.withTransparency(ColorDef.create(ColorByName.green).tbgr,  255-Math.round(255*_opacity)))
    
    const backPtTop = localPoint.plusScaled(Vector3d.unitY(), backPtFactor * newEquipJson.Height);
    const frontPtTop = localPoint.plusScaled(Vector3d.unitY(), -0.49 * newEquipJson.Height);
    const backPtTop1 = localPoint.plusScaled(Vector3d.unitY(), -0.49 * newEquipJson.Height);
    const frontPtTop1 = localPoint.plusScaled(Vector3d.unitY(), -0.5125 * newEquipJson.Height);
    const backPtTop2 = localPoint.plusScaled(Vector3d.unitY(), -0.5125 * newEquipJson.Height);
    const frontPtTop2 = localPoint.plusScaled(Vector3d.unitY(), -0.525 * newEquipJson.Height);
    
    const cylinder = this.constructConeGeometry(newEquipJson, new Vector3d(0, 0, 0), backPt, frontPt, frontRad, backRad, false);
    const cylinderTop = this.constructConeGeometry(newEquipJson, new Vector3d(0, 0, 0), backPtTop, frontPtTop, frontRad, backRad*0.85, false);
    const cylinderTop2 = this.constructConeGeometry(newEquipJson, new Vector3d(0, 0, 0), backPtTop1, frontPtTop1, backRad*0.85, backRad*0.70, false);
    const cylinderTop3 = this.constructConeGeometry(newEquipJson, new Vector3d(0, 0, 0), backPtTop2, frontPtTop2, backRad*0.70, backRad*0.5, true);


    const circle = Arc3d.createXY(cylinderTop2.getCenterB(),  backRad*0.5);
    let loop = Loop.create(circle.clone());
    let azMatrix, trans;

    azMatrix = Matrix3d.createRotationAroundAxisIndex(AxisIndex.X, Angle.createDegrees(-1*newEquipJson.DownTilt));
    trans = Transform.createFixedPointAndMatrix(cylinderTop2.getCenterB(), azMatrix);
    // // Apply to the box
    loop?.tryTransformInPlace(trans);

    azMatrix = Matrix3d.createRotationAroundAxisIndex(AxisIndex.Z, Angle.createDegrees(1*newEquipJson.Azimuth));
    trans = Transform.createFixedPointAndMatrix(cylinderTop2.getCenterB(), azMatrix);
    // // Apply to the box
    loop?.tryTransformInPlace(trans);

    const backPtBot = localPoint.plusScaled(Vector3d.unitY(), frontPtFactor * newEquipJson.Height);
    const frontPtBot = localPoint.plusScaled(Vector3d.unitY(), 0.50 * newEquipJson.Height);
    
    const cylinderBot = this.constructConeGeometry(newEquipJson, new Vector3d(0, 0, 0), backPtBot, frontPtBot, frontRad*1.25, backRad*1.2, true);

    const topEntry: GeometryEntry = {
      name: newEquipJson.DisplayName+"_RCTop",
      geometry: cylinderTop!,
      color: equipJson.isActive ? ColorDef.create(ColorByName.orangeRed) : ColorDef.create(ColorByName.magenta),
    };
    const topEntry2: GeometryEntry = {
      name: newEquipJson.DisplayName+"_RCTop2",
      geometry: cylinderTop2!,
      color: equipJson.isActive ? ColorDef.create(ColorByName.orangeRed) : ColorDef.create(ColorByName.magenta),
    };

    const topEntry3: GeometryEntry = {
      name: newEquipJson.DisplayName+"_RCTop3",
      // geometry: loop!,
      geometry: cylinderTop3!,
      color: equipJson.isActive ? ColorDef.create(ColorByName.orangeRed) : ColorDef.create(ColorByName.magenta),
    };

    const botEntry: GeometryEntry = {
      name: newEquipJson.DisplayName+"_RCBot",
      geometry: cylinderBot!,
      color: equipJson.isActive ? ColorDef.create(ColorByName.orangeRed) : ColorDef.create(ColorByName.magenta),
    };


    if (cylinder) {
      const entry: GeometryEntry = {
        name: newEquipJson.Equipment_Name,
        geometry: cylinder!,
        extraEntries: [topEntry, topEntry2, topEntry3, botEntry],
        color: this.equipColors.microwave.bodyCol,
      };
      return entry;
    }
    return undefined;
  }
  /**
   * find Equipment Position
   * @param name Equipment Name
   * @returns Returns Equipment Position
   */
  public getBoxJsonByName(name: string): EquipmentData | undefined {
    return this.currJson.find((json) => json.Equipment_Name === name);
  }
  /**
   * Create new Box
   * @param pt Centroid Point of Equipment
   * @returns new Equipment
   */

  public getEquipmentDimensions = (equipmentType: EquipmentType) => {
    let Height: number = 0, Width: number = 0, Thickness: number = 0;
    const convFactor = AppSettings.defaultDisplaySettings.unitSystem == unitSystemType.METRIC ? 1 : 39.37008;
    if (equipmentType === EquipmentType.Antenna) {
      Height = 2*convFactor;
      Width = 0.50*convFactor;
      Thickness = 0.25*convFactor;
    }
    else if (equipmentType === EquipmentType.RRU) {
      Height = 0.563*convFactor;
      Width = 0.32*convFactor;
      Thickness = 0.163*convFactor;
    }
    else if (equipmentType === EquipmentType.Microwave) {
      Height = 0.858*convFactor;
      Width = 0.83*convFactor;
      Thickness = 0.575*convFactor;
    }
    else if (equipmentType === EquipmentType.SQUID) {
      Height = 0.5*convFactor;
      Width = 0.25*convFactor;
      Thickness = 0.25*convFactor;
    }
    return {Height, Width, Thickness};
  }

  public createNewEquipment = (Equipment_Type: EquipmentType) => {
    const Equipment_Name = this.getEquipmentName(Equipment_Type);
    const {Height, Width, Thickness} = this.getEquipmentDimensions(Equipment_Type);
    const ED: EquipmentData = {
      Azimuth: 0,
      Equipment_Name,
      Equipment_Type,
      isActive: true,
      Height,
      Width,
      Thickness,
      Roll: 0,
      Tilt: 0,
      DownTilt: 0,
      x_position: 0,
      y_position: 0,
      z_position: 0,
      Elevation_Height: 0,
      Model: "",
      Manufacturer: "",
      DisplayName: Equipment_Name,
      geometryEntry: {
        geometry: null,
        name: ""
      }
    };
    return ED;
  }
  /**
   * find equipment name
   * @param equipmentType equipment type
   * @returns name of equipment
   */
  private getEquipmentName(equipmentType: string | undefined) {
    const equips = EquipmentsTable.equipmentData.filter((i) => i.type === equipmentType);
    const index = equips.length == 0 ? 1 : equips.length;
    return this.recursiveFindName(equipmentType!, index);
  }
  /**
   * find name with recursve func
   * @param equipmentType type of equipment
   * @param index no of existing element
   * @returns name of new equipment
   */
  private recursiveFindName(equipmentType: string, index: number): string {
    const name = equipmentType + "_" + index;
    const exists = EquipmentsTable.equipmentData.findIndex((i) => (i.displayName === name || i.name == name) );
    if (exists === -1) {
      return name;
    } else {
      return this.recursiveFindName(equipmentType, index + 1);
    }
  }
  /**
   * add clone box
   * @param _equipData Position information of equipment
   * @param pt Global Point
   * @param _prevName old name of equipment
   * @returns true if successful
   */

  public deleteBoxByName(_name: Id64String): boolean {
    if (!Array.from(this.nameIdMap.keys()).includes(_name)) {
      return false;
    }
    // Clear any edit arrows
    // ModifyHandleDecoration.clear();
    this.deleteCurrentShape(_name);
    // this.boxes = this.boxes.filter((e) => e.name !== _name);
    // this.shapes = this.shapes.filter((e) => e.name !== _name);
    // this.currJson = this.currJson.filter((e) => e.Equipment_Name !== _name);

    // const id = this.nameIdMap.get(_name);
    // if (id)
    //   UiFramework.getIModelConnection()!.selectionSet.remove(id);
    // this.nameIdMap.delete(_name);

    // this.drawnBoxes = this.drawnBoxes.filter((e) => e.name !== _name);
    // this.antennaBoxes = this.antennaBoxes.filter((entry) => entry.name !== _name);
    // this.rruBoxes = this.rruBoxes.filter((entry) => entry.name !== _name);
    // this.microWaveCylinders = this.microWaveCylinders.filter((entry) => entry.name !== _name);
    
    //Deleting faces from the equipment geometries
    // let faceName = "";
    // if(_name.includes('Micro')){
    //   faceName=`MW_${_name.split('_')[2]}_face`;
    //   this.drawnCylinders = this.drawnCylinders.filter((e) => e.name !== _name || e.name !== faceName);
    //   this.shapes = this.shapes.filter((e) => e.name !== faceName);
    // } else { // For Antennas and RRUs
    //   faceName = _name.substring(0, 3).toUpperCase()+`_${_name.split('_')[1]}_face`;
    //   // this.drawnBoxes = this.drawnBoxes.filter((e) => e.name !== _name || e.name !== faceName);
    //   this.shapes = this.shapes.filter((e) => e.name !== faceName);
    // }
    // const equipNameInfoMap = new Map(store.getState().detectedData.equipmentDataMaps.equipNameInfoMap);
    // const equipNamePositionMap = new Map(store.getState().detectedData.equipmentDataMaps.equipNamePositionMap);
    
    EquipmentsTable.equipNameInfoMap.delete(_name);
    EquipmentsTable.equipNamePositionMap.delete(_name);

    IModelApp.viewManager.selectedView?.invalidateCachedDecorations(this);
    return true;
  }



  public updateBox(newBox: Box, index: number, isHeightChange: boolean = true, updateJson: boolean = true, newJson: EquipmentData | null = null) {
    if (newBox) {
      // const oldBox = this.boxes[index].box.clone();
      const modelData = this.shapes[index].modelData!;
      modelData.geometryEntry!.geometry = newBox as Box;
      const boxName = this.shapes[index].modelData!.DisplayName;
      
      // const antIndex = this.antennaBoxes.findIndex((i) => i.name === boxName);
      // if (antIndex !== -1)this.antennaBoxes[antIndex].geometry = newBox;
      
      // const rruIndex = this.rruBoxes.findIndex((i) => i.name === boxName);
      // if (rruIndex !== -1)this.rruBoxes[rruIndex].geometry = newBox;

      // const boxIndex = this.drawnBoxes.findIndex((i) => i.name === boxName);
      // if (boxIndex !== -1)this.drawnBoxes.splice(boxIndex, 1);

      let shapeIndex = this.shapes.findIndex((shape) => shape.modelData!.DisplayName === boxName);
      const equipmentJson = this.shapes[shapeIndex];
      let shapeDN = equipmentJson.modelData!.DisplayName;
      if (shapeIndex !== -1){
        if(shapeIndex >= 0){
          const theKey =this.objectIdMap.get(this.shapes[shapeIndex].transientId);
          this.objectIdMap.delete(this.shapes[shapeIndex].transientId);
          this.nameIdMap.delete(shapeDN);
          this.shapes.splice(shapeIndex, 1);
        }
      }

      if (this.showFrontFace) {
        // const drawIndex = this.drawnCylinders.findIndex((e) => e.name === _name);
        // if(drawIndex >= 0)this.drawnCylinders.splice(drawIndex, 1);
        
        const dn = equipmentJson.modelData!.DisplayName;
        const dnSplit = dn.split('_');
        let faceName = equipmentJson.modelData?.Equipment_Type == "Micro_Wave" ? `${dnSplit[2]}_face` : `${dnSplit[1]}_face`;
        switch (equipmentJson.modelData?.Equipment_Type) {
          case "Antenna":
            faceName = `ANT_${faceName}`;
            break;
          case "RRU":
            faceName = `RRU_${faceName}`;
            break;
          case "Micro_Wave":
            faceName = `MW_${faceName}`;
            break;
        
          default:
            break;
        }
  
        shapeIndex = this.shapes.findIndex((shape) => shape.modelData?.DisplayName === faceName);
        if(shapeIndex >= 0){
          // this.shapes.splice(shapeIndex, 1);
          const theKey =this.objectIdMap.get(this.shapes[shapeIndex].transientId);
          this.objectIdMap.delete(this.shapes[shapeIndex].transientId);
          this.nameIdMap.delete(faceName);
          this.shapes.splice(shapeIndex, 1);
  
        }
      }
        

      const entry: GeometryEntry = {
        geometry: newBox,
        name: boxName!,
        // modelData: equipmentJson,
      };
      
      if (updateJson) {
        // update currJson list
        const jsonIndex = this.shapes.findIndex((json) => json.modelData!.DisplayName === boxName);
        const json = {...this.shapes[jsonIndex].modelData!};
        const centroid = new Point3d(json.x_position, json.y_position, json.z_position);
        const newBoxJson: EquipmentData = {
          Azimuth: json.Azimuth,
          Equipment_Name: json.Equipment_Name,
          Equipment_Type: json.Equipment_Type,
          DisplayName: json.DisplayName,
          Height: !isHeightChange ? json.Height : newBox.getTopOrigin().distance(newBox.getBaseOrigin()),
          Thickness: newBox.getBaseY(),
          Tilt: json.Tilt,
          DownTilt: -1*json.Tilt,
          Width: newBox.getBaseX(),
          x_position: centroid.x,
          y_position: centroid.y,
          z_position: centroid.z,
          Elevation_Height: json.Elevation_Height,
          Roll: json.Roll,
          Manufacturer: json.Manufacturer,
          Model: json.Model,
          geometryEntry: entry
        };
        // const equipNamePositionMap = new Map(store.getState().detectedData.equipmentDataMaps.equipNamePositionMap);
        
        // equipNamePositionMap.set(json.Equipment_Name, newBoxJson);
        // Drawing the equipment face
        if(this.showFrontFace){
          const newBoxEntry: GeometryEntry = {geometry: newBox,name: json.Equipment_Name};
          // this.drawAntRruFace(newBoxEntry, newBoxJson);
        }
        this.shapes[jsonIndex].modelData = newBoxJson;
      } else if(newJson != null){
        const jsonIndex = this.shapes.findIndex((json) => json.modelData!.DisplayName === boxName);
        const json = {...newJson};
        const centroid = new Point3d(json.x_position, json.y_position, json.z_position);
        const newBoxJson: EquipmentData = {
          Azimuth: json.Azimuth,
          Equipment_Name: json.Equipment_Name,
          Equipment_Type: json.Equipment_Type,
          DisplayName: json.DisplayName,
          Height: !isHeightChange ? json.Height : newBox.getTopOrigin().distance(newBox.getBaseOrigin()),
          Thickness: newBox.getBaseY(),
          Tilt: json.Tilt,
          DownTilt: -1*json.Tilt,
          Width: newBox.getBaseX(),
          x_position: centroid.x,
          y_position: centroid.y,
          z_position: centroid.z,
          Elevation_Height: json.Elevation_Height,
          Roll: json.Roll,
          Manufacturer: json.Manufacturer,
          Model: json.Model,
          geometryEntry: entry
        };
        // const equipNamePositionMap = new Map(store.getState().detectedData.equipmentDataMaps.equipNamePositionMap);
        
        // equipNamePositionMap.set(json.Equipment_Name, newBoxJson);
        // Drawing the equipment face
        if(this.showFrontFace){
          const newBoxEntry: GeometryEntry = {geometry: newBox,name: json.Equipment_Name};
          // this.drawAntRruFace(newBoxEntry, newBoxJson);
        }
        this.shapes[jsonIndex].modelData = newBoxJson;

      }
    }
    IModelApp.viewManager.selectedView?.invalidateDecorations();
    IModelApp.viewManager.selectedView?.invalidateCachedDecorations(this);
  }

  public updateBoxPositionByName(_name: string, _changeVector: Vector3d): boolean {
    // const index = this.boxes.findIndex((entry) => entry.name === _name);
    // if (index === -1) return false;
    const equipmentJson = this.shapes.find((json) => json.modelData?.DisplayName === _name)?.modelData!;
    // const equipmentJson = {...this.shapes[jsonIndex]};

    // const equipmentJson: EquipmentData = {...this.currJson.find((i) => i.Equipment_Name === _name)!};
    //Making changes to the position property of the equipment position object
    equipmentJson!.x_position+=_changeVector.x;
    equipmentJson!.y_position+=_changeVector.y;
    equipmentJson!.z_position+=_changeVector.z;
    equipmentJson!.Elevation_Height = equipmentJson.z_position - siteCoordinates.utm.z;

    // let shapeIndex = this.shapes.findIndex((shape) => shape.modelData!.DisplayName === _name);
    // // const equipmentJson = this.shapes[shapeIndex];
    // let shapeDN = equipmentJson!.DisplayName;
    // if (shapeIndex !== -1){
    //   const theKey =this.objectIdMap.get(this.shapes[shapeIndex].transientId);
    //   this.objectIdMap.delete(this.shapes[shapeIndex].transientId);
    //   this.nameIdMap.delete(shapeDN);
    //   this.shapes.splice(shapeIndex, 1);
    // }

    // if (this.showFrontFace) {
    //   // const drawIndex = this.drawnCylinders.findIndex((e) => e.name === _name);
    //   // if(drawIndex >= 0)this.drawnCylinders.splice(drawIndex, 1);
      
    //   const dn = equipmentJson!.DisplayName;
    //   const dnSplit = dn.split('_');
    //   let faceName = equipmentJson.Equipment_Type == "Micro_Wave" ? `${dnSplit[2]}_face` : `${dnSplit[1]}_face`;
    //   switch (equipmentJson.Equipment_Type) {
    //     case "Antenna":
    //       faceName = `ANT_${faceName}`;
    //       break;
    //     case "RRU":
    //       faceName = `RRU_${faceName}`;
    //       break;
    //     case "Micro_Wave":
    //       faceName = `MW_${faceName}`;
    //       break;
      
    //     default:
    //       break;
    //   }

    //   shapeIndex = this.shapes.findIndex((shape) => shape.modelData?.DisplayName === faceName);
    //   if(shapeIndex >= 0){
    //     // this.shapes.splice(shapeIndex, 1);
    //     const theKey =this.objectIdMap.get(this.shapes[shapeIndex].transientId);
    //     this.objectIdMap.delete(this.shapes[shapeIndex].transientId);
    //     this.nameIdMap.delete(faceName);
    //     this.shapes.splice(shapeIndex, 1);
    //   }
    // }
    this.deleteCurrentShape(equipmentJson.DisplayName)
    this.createGeometry(equipmentJson!, CreateGeometryMode.New);

    IModelApp.viewManager.selectedView?.invalidateDecorations();
    IModelApp.viewManager.selectedView?.invalidateCachedDecorations(this);

    // const box = this.constructBoxGeometry(equipmentJson!, _changeVector) as Box;
    // this.updateBox(box!, index);
    // this.currJson[jsonIndex] = equipmentJson;
    
    const shapeIndex = this.shapes.findIndex((shape) => shape.modelData!.DisplayName === _name);
    if(ModifyHandleDecoration._decorator && shapeIndex !== -1){
      // ModifyHandleDecoration.clear();
      // ModifyHandleDecoration.create(IModelApp.viewManager.selectedView as ScreenViewport, this.shapes[shapeIndex].transientId, equipmentJson.DisplayName!, shapeIndex, this);
    }      
    if (ModifyHandleDecoration._decorator && this.shapes[shapeIndex].modelData?.shapeId == null) {
      ModifyHandleDecoration._decorator._shape = this.shapes[shapeIndex].modelData?.geometryEntry?.geometry;
      ModifyHandleDecoration._decorator._shapeIndex = shapeIndex;
      ModifyHandleDecoration._decorator._shapeName = equipmentJson.DisplayName;
      ModifyHandleDecoration._decorator.createClipShapeControls();
    }
    return true;
  }

  public async deleteCurrentShape(shapeDisplayName: string): Promise<boolean> {
    let shapeIndex = this.shapes.findIndex((shape) => shape.modelData!.DisplayName === shapeDisplayName);
    const equipmentJson = this.shapes[shapeIndex];
    let shapeDN = "";

    if (shapeIndex !== -1){
      shapeDN = equipmentJson.modelData!.DisplayName;
      // this.objectIdMap.get(this.shapes[shapeIndex].transientId);
      this.objectIdMap.delete(this.shapes[shapeIndex].transientId);
      this.nameIdMap.delete(shapeDN);
      this.shapes.splice(shapeIndex, 1);
    } else return false;
    
    if(equipmentJson.modelData?.Equipment_Type.match(/SQUID|Micro_Wave/)){
      const newShapes = this.shapes.filter((shape) => !shape.modelData!.DisplayName.match(`${shapeDisplayName}_`));
      // if (shapeIndex !== -1)this.shapes.splice(shapeIndex, 1);
      if (newShapes.length)this.shapes = newShapes;
      // shapeIndex = this.shapes.findIndex((shape) => shape.modelData!.DisplayName.match(`${shapeDisplayName}_RCBot`));
      // if (shapeIndex !== -1)this.shapes.splice(shapeIndex, 1);
    }
    
    if (this.showFrontFace) {
      const dn = equipmentJson.modelData!.DisplayName;
      const dnSplit = dn.split('_');
      let faceName = equipmentJson.modelData?.Equipment_Type == "Micro_Wave" ? `${dnSplit[2]}_face` : `${dnSplit[1]}_face`;
      switch (equipmentJson.modelData?.Equipment_Type) {
        case "Antenna":
          faceName = `ANT_${faceName}`;
          break;
        case "RRU":
          faceName = `RRU_${faceName}`;
          break;
        case "Micro_Wave":
          faceName = `MW_${faceName}`;
          break;
        default:
          break;
      }
              
      shapeIndex = this.shapes.findIndex((shape) => shape.modelData?.DisplayName === faceName);

      if(shapeIndex >= 0){
        // this.shapes.splice(shapeIndex, 1);
        const theKey =this.objectIdMap.get(this.shapes[shapeIndex].transientId);
        this.objectIdMap.delete(this.shapes[shapeIndex].transientId);
        this.nameIdMap.delete(faceName);
        this.shapes.splice(shapeIndex, 1);
      }
    }
    IModelApp.viewManager.selectedView?.invalidateDecorations();
    IModelApp.viewManager.selectedView?.invalidateCachedDecorations(this);
    return true;
  }

  public async updateBoxRotation(_name: string, newYprAngle: YawPitchRollAngles): Promise<boolean> {
    const index = this.boxes.findIndex((entry) => entry.name === _name);
    const equipmentJson: EquipmentData = {...this.shapes.find((i) => i.modelData?.DisplayName === _name)!}.modelData!;
    // const equipmentJson2 = {...equipDataMaps.equipNameInfoMap?.get(_name)};
    equipmentJson!.Roll = newYprAngle.roll.degrees;
    equipmentJson!.Azimuth = newYprAngle.yaw.degrees;
    equipmentJson!.DownTilt = newYprAngle.pitch.degrees;
    // const newBox = this.constructBoxGeometry(equipmentJson!) as Box;
    // let shapeIndex = this.shapes.findIndex((shape) => shape.modelData?.DisplayName === _name);
    // if (shapeIndex !== -1)this.shapes.splice(shapeIndex, 1);
    // if(this.showFrontFace){
    //   const dn = equipmentJson.DisplayName;
    //   const dnSplit = dn.split('_');
    //   let faceName = `${dnSplit[1]}_face`;
    //   switch (dnSplit[0]) {
    //     case "Antenna":
    //       faceName = `ANT_${faceName}`;
    //       break;
    //     case "RRU":
    //       faceName = `RRU_${faceName}`;
    //       break;
    //     case "Micro_Wave":
    //       faceName = `MW_${faceName}`;
    //       break;
      
    //     default:
    //       break;
    //   }

    //   shapeIndex = this.shapes.findIndex((shape) => shape.modelData?.DisplayName === faceName);
    //   if (shapeIndex !== -1)this.shapes.splice(shapeIndex, 1);
    // }
    
    this.deleteCurrentShape(equipmentJson.DisplayName);
    this.createGeometry(equipmentJson!, CreateGeometryMode.New);
    IModelApp.viewManager.selectedView?.invalidateDecorations();
    IModelApp.viewManager.selectedView?.invalidateCachedDecorations(this);
    // const newBox = this.constructBoxGeometry(equipmentJson!) as Box;

    // this.updateBox(newBox!, index, true, true, equipmentJson);
    const shapeIndex = this.shapes.findIndex((shape) => shape.modelData!.DisplayName === _name);
    if(ModifyHandleDecoration._decorator && shapeIndex !== -1){
      // ModifyHandleDecoration.clear();
      // ModifyHandleDecoration.create(IModelApp.viewManager.selectedView as ScreenViewport, this.shapes[shapeIndex].transientId, equipmentJson.DisplayName!, shapeIndex, this);
    }      
    if (ModifyHandleDecoration._decorator && this.shapes[shapeIndex].modelData?.shapeId == null) {
      ModifyHandleDecoration._decorator._shape = this.shapes[shapeIndex].modelData?.geometryEntry?.geometry;
      ModifyHandleDecoration._decorator._shapeIndex = shapeIndex;
      ModifyHandleDecoration._decorator._shapeName = equipmentJson.DisplayName;
      ModifyHandleDecoration._decorator.createClipShapeControls();
    }
    return true;
  }


  /**
   * update geometry scaling
   * @param _name name of geometry
   * @param _scaleVector scale vector
   * @returns success if true
   */
  public scaleGeometry(_name: string, _scaleVector: Vector3d): void {
    const equipmentJson: EquipmentData = {...this.shapes.find((i) => i.modelData?.Equipment_Name === _name)!}.modelData!;
    const shapeIndex = this.shapes.findIndex((shape) => shape.modelData?.Equipment_Name === _name);
    if (shapeIndex !== -1)this.shapes.splice(shapeIndex, 1);
    if(this.showFrontFace){
        const shapeIndex = this.shapes.findIndex((shape) => shape.modelData?.Equipment_Name === `ANT_${_name.split('_')[1]}_face`);
        if (shapeIndex !== -1)this.shapes.splice(shapeIndex, 1);
    }
    
    if(equipmentJson){
      this.createGeometry(equipmentJson!, CreateGeometryMode.New, store.getState().detectedData.objectOpacityState.equipment.value, _scaleVector);
      IModelApp.viewManager.selectedView?.invalidateDecorations();
      IModelApp.viewManager.selectedView?.invalidateCachedDecorations(this);
    }
    
  }


  /**
   * update microwave position
   * @param _name name of Microwave
   * @param _changePosVector change vector
   * @returns success if true
   */
  public updateCylinderPosition(_name: string, _changePosVector: Vector3d): boolean {
    const jsonIndex = this.shapes.findIndex((e) => e.modelData!.DisplayName === _name);
    if (jsonIndex === -1) return false;

    const equipJson = {...this.shapes[jsonIndex].modelData!};
    //Making changes to the position property of the equipment position object
    equipJson.x_position+=_changePosVector.x;
    equipJson.y_position+=_changePosVector.y;
    equipJson.z_position+=_changePosVector.z;
    this.shapes[jsonIndex].modelData = equipJson;
    return this.updateCylinder(_name, equipJson, _changePosVector);
  }
  /**
   * Update Cylinder Rotation
   * @param _name name of cylinder
   * @param newVal new angle value
   * @param rotationVal enum val (Azimuth: 0, Tilt : 1, Roll : 2)
   * @returns true if rotation is successful
   */
  public updateCylinderRotation(_name: string, yprVal: YawPitchRollAngles): boolean {
    const equipIndex = this.shapes.findIndex((e) => e.modelData!.DisplayName === _name);
    if (equipIndex === -1) return false;

    const equipJson = {...this.shapes[equipIndex].modelData!};
    equipJson.Azimuth = yprVal.yaw.degrees;
    equipJson.DownTilt = yprVal.pitch.degrees;
    equipJson.Roll = yprVal.roll.degrees;

    return this.updateCylinder(_name, equipJson);
  }
  /**
   * Updtae Cylinder Radius
   * @param _name name of Cylinder
   * @param _change increase cylinder radius with val
   * @returns true if successful
   */
  public updateCylinderRadius(_name: string, _change: number): boolean {
    const jsonIndex = this.currJson.findIndex((e) => e.Equipment_Name === _name);
    if (jsonIndex === -1) return false;

    const equipJson = {...this.currJson[jsonIndex]};
    equipJson.Width = (equipJson.Width + _change);
    this.currJson[jsonIndex] = equipJson;
    return this.updateCylinder(_name, equipJson);
  }
  /**
   * Update Cylinder Thickness
   * @param _name cylinder name
   * @param _change change value from Widget
   * @returns true or false depending on success or failure
   */
  public updateCylinderThickness(_name: string, _change: number): boolean {
    const jsonIndex = this.currJson.findIndex((e) => e.Equipment_Name === _name);
    if (jsonIndex === -1) return false;

    const equipJson = {...this.currJson[jsonIndex]};
    equipJson.Thickness = (equipJson.Thickness + _change);
    this.currJson[jsonIndex] = equipJson;

    return this.updateCylinder(_name, equipJson);
  }
  /**
   * update microwave
   * @param _name name of microwave
   * @param equipJson equipment position information
   * @returns true if successful
   */
  public updateCylinder(_name: string, equipJson: EquipmentData, changeVal: Vector3d = new Vector3d(0, 0, 0)): boolean {
    let factors = {backPtFact: 0.5, frontPtFact: 0.5, frontRadFact: 0.5, backRadFact: 0.5};
    if(this.showFrontFace){
      // factors = {backPtFact: 0.75, frontPtFact: 0.18, frontRadFact: 0.2, backRadFact: 0.51};
    }

    const localPoint = new Point3d(0, 0, 0);
    const frontPt = localPoint.plusScaled(Vector3d.unitY(), factors.frontPtFact * equipJson.Thickness);
    const backPt = localPoint.plusScaled(Vector3d.unitY(), -factors.backPtFact * equipJson.Thickness);
    const frontRad = (equipJson.equipSpecs?.frontRad == undefined ? equipJson.Width : equipJson.equipSpecs.frontRad*equipJson.Width) * factors.frontRadFact;
    const backRad = (equipJson.equipSpecs?.backRad == undefined ? equipJson.Width : equipJson.equipSpecs.backRad*equipJson.Width) * factors.backRadFact;
    equipJson.equipSpecs = {changeVal, frontPt, backPt, frontRad, backRad};

    // const newCylinder = this.constructConeGeometry(equipJson, changeVal, backPt, frontPt, frontRad, backRad);
    this.deleteCurrentShape(_name);

    // // const newCylinder = this.constructConeGeometry(equipJson, changeVal);
    //   let shapeIndex = this.shapes.findIndex((shape) => shape.modelData!.DisplayName === _name);
    //   const equipmentJson = this.shapes[shapeIndex];
    //   let shapeDN = equipmentJson.modelData!.DisplayName;
    //   if(shapeIndex >= 0){
    //     const theKey = this.objectIdMap.get(this.shapes[shapeIndex].transientId);
    //     this.objectIdMap.delete(this.shapes[shapeIndex].transientId);
    //     this.nameIdMap.delete(shapeDN);
    //     this.shapes.splice(shapeIndex, 1);
    //   }

    // if (this.showFrontFace) {
    //   // const drawIndex = this.drawnCylinders.findIndex((e) => e.name === _name);
    //   // if(drawIndex >= 0)this.drawnCylinders.splice(drawIndex, 1);
      
    //   const dn = equipmentJson.modelData!.DisplayName;
    //   const dnSplit = dn.split('_');
    //   let faceName = equipmentJson.modelData?.Equipment_Type == "Micro_Wave" ? `${dnSplit[2]}_face` : `${dnSplit[1]}_face`;
    //   switch (equipmentJson.modelData?.Equipment_Type) {
    //     case "Antenna":
    //       faceName = `ANT_${faceName}`;
    //       break;
    //     case "RRU":
    //       faceName = `RRU_${faceName}`;
    //       break;
    //     case "Micro_Wave":
    //       faceName = `MW_${faceName}`;
    //       break;
      
    //     default:
    //       break;
    //   }

    //   shapeIndex = this.shapes.findIndex((shape) => shape.modelData?.DisplayName === faceName);
    //   if(shapeIndex >= 0){
    //     // this.shapes.splice(shapeIndex, 1);
    //     const theKey =this.objectIdMap.get(this.shapes[shapeIndex].transientId);
    //     this.objectIdMap.delete(this.shapes[shapeIndex].transientId);
    //     this.nameIdMap.delete(`MW_${shapeDN.split('_')[2]}_face`);
    //     this.shapes.splice(shapeIndex, 1);

    //   }
    // }
    this.createGeometry(equipJson, CreateGeometryMode.New);
    IModelApp.viewManager.selectedView?.invalidateCachedDecorations(this);
    // const equipNamePositionMap = new Map(store.getState().detectedData.equipmentDataMaps.equipNamePositionMap);
    // let obj: any = EquipmentsTable.equipNamePositionMap.get(_name);
    // if(this.showFrontFace)this.drawMWCylFace(newEntry, equipJson);
    // obj = equipJson;
    return true;
    // return false;
  }





  /**
   * Update Cylinder Thickness
   * @param _name cylinder name
   * @param _change change value from Widget
   * @returns true or false depending on success or failure
   */

  private drawMWCylFace(entry: GeometryEntry, equipFace: EquipmentData, opacity): void {
    // if(equipFace.isActive == false)opacity = 0.7 * opacity;
    equipFace=JSON.parse(JSON.stringify(equipFace));  //Creating new object deleting reference
    const faceName = `MW_${entry.name.split('_')[2]}_face`;
    const inactiveColor = ColorByName.darkMagenta;
    // const drawIndex = this.drawnCylinders.findIndex((e) => e.name === faceName);
    // if(drawIndex >= 0)this.drawnCylinders.splice(drawIndex, 1);

    // const shapeIndex = this.shapes.findIndex((shape) => shape.name === faceName);
    // if(shapeIndex >= 0)this.shapes.splice(shapeIndex, 1);

    let vec = new Vector3d();
    equipFace.Equipment_Name=faceName;
    // equipFace.Thickness=.092;
    equipFace.Thickness=.05*equipFace.Thickness;
    equipFace.equipSpecs={frontRad:1, backRad:1};

    let factors = {backPtFact: 0.05, frontPtFact: 0.5, frontRadFact: 0.5, backRadFact: 0.5};
    if(this.showFrontFace){
      factors = {backPtFact: 1.05, frontPtFact: 0.725, frontRadFact: 0.51, backRadFact: 0.52};
    }

    const localPoint = new Point3d(0, 0, 0);
    const frontPt = localPoint.plusScaled(Vector3d.unitY(), factors.frontPtFact * equipFace.Thickness);
    const backPt = localPoint.plusScaled(Vector3d.unitY(), -factors.backPtFact * equipFace.Thickness);
    const frontRad = (equipFace.equipSpecs.frontRad == undefined ? equipFace.Height : equipFace.equipSpecs.frontRad*equipFace.Height) * factors.frontRadFact;
    const backRad = (equipFace.equipSpecs.backRad == undefined ? equipFace.Height : equipFace.equipSpecs.backRad*equipFace.Height) * factors.backRadFact;


    const face = this.constructConeGeometry(equipFace, new Vector3d(0, 0, 0), backPt, frontPt, frontRad, backRad);


    // const face = this.constructConeGeometry(equipFace);
    const geomEntry = entry.geometry as Cone;
    const centA = geomEntry.getCenterA();
    const centB = geomEntry.getCenterB(); 

    vec.setStartEnd(centA, centB);
    // vec.scaleToLength(((centA.distance(centB)/2)+(equipFace.Thickness/2)), vec);
    vec.scaleToLength(((centA.distance(centB)/2)), vec);
    const trf=Transform.createTranslation(vec);
    face.tryTransformInPlace(trf);

    const builder = PolyfaceBuilder.create();
    builder.addCone(face);
    const polyface = builder.claimPolyface(false);
    const theColor = equipFace.isActive == true ? this.equipColors.microwave.faceCol.tbgr : inactiveColor;
    const color = ColorDef.fromTbgr(ColorDef.withTransparency(ColorDef.create(theColor).tbgr,  255-Math.round(255*opacity)))
    const faceEntry = {
      name: faceName,
      geometry: face!,
      color: color, //Hot pink
    };
    // const nextId = IModelApp.viewManager.selectedView!.iModel.transientIds.getNext();
    // this.objectIdMap.set(nextId, `equipFaceGeom#${faceName}`);
    // this.nameIdMap.set(`${faceName}`, nextId);
    this.addGeometry(polyface, color, faceName, false, true, {geometryEntry: faceEntry, DisplayName: faceName});
    // this.drawnCylinders.push(faceEntry);
    IModelApp.viewManager.selectedView?.invalidateCachedDecorations(this);
  }

  public drawAntRruFace(entry: GeometryEntry, equipFace: EquipmentData, opacity): void {
    equipFace=JSON.parse(JSON.stringify(equipFace));
    // if(equipFace.isActive == false)opacity = 0.9 * opacity;
    const inactiveRRUCol = [140, 21, 21];
    const inactivePanelCol = [23, 60, 120];
    const inactiveFaceColor = ColorByName.darkMagenta;

    let faceName = entry.name.substring(0, 3).toUpperCase();
    faceName+=`_${entry.name.split('_')[1]}_face`;

    // const drawIndex = this.drawnBoxes.findIndex((e) => e.name === faceName);
    // if(drawIndex >= 0)this.drawnBoxes.splice(drawIndex, 1);

    // const shapeIndex = this.shapes.findIndex((shape) => shape.name === faceName);
    // if(shapeIndex >= 0)this.shapes.splice(shapeIndex, 1);

    equipFace.Equipment_Name=faceName;
    const thick=equipFace.Thickness;
    equipFace.Thickness=.01;

    const face = this.constructBoxGeometry(equipFace) as Box;
    const geomEntry = entry.geometry as Box;
    let vec = geomEntry.getVectorY();
    vec.scaleToLength(((thick/2)+(equipFace.Thickness/2)), vec);
    const trf=Transform.createTranslation(vec);
    face.tryTransformInPlace(trf);

    const builder = PolyfaceBuilder.create();
    builder.addBox(face);
    let theColor;
    switch (equipFace.isActive) {
      case false:
        // theColor = equipFace.Equipment_Name.match(/Ant/ig) ? ColorDef.from(inactivePanelCol[0], inactivePanelCol[1], inactivePanelCol[2]).tbgr :  ColorDef.from(inactiveRRUCol[0], inactiveRRUCol[1], inactiveRRUCol[2]).tbgr        
        theColor = equipFace.Equipment_Name.match(/Ant/ig) ? inactiveFaceColor : inactiveFaceColor        
        break;
      case true:
        theColor = equipFace.Equipment_Name.match(/Ant/ig) ? this.equipColors.antenna.faceCol.tbgr : this.equipColors.rru.faceCol.tbgr;
        break;
    
      default:
        break;
    }

    const polyface = builder.claimPolyface(false);
    const color = ColorDef.fromTbgr(ColorDef.withTransparency(ColorDef.create(theColor).tbgr,  255-Math.round(255*opacity)));
    const faceEntry = {
      name: faceName,
      geometry: face!,
      color: color
    };
    // const nextId = IModelApp.viewManager.selectedView!.iModel.transientIds.getNext();
    // this.objectIdMap.set(nextId, `equipFaceGeom#${faceName}`);
    // this.nameIdMap.set(`${faceName}`, nextId);

    this.addGeometry(polyface, color, faceName, false, true, {geometryEntry: faceEntry, DisplayName: faceName});
    // this.drawnBoxes.push(faceEntry);
    IModelApp.viewManager.selectedView?.invalidateCachedDecorations(this);
  }


  /**
   * Create Cone object and draw Microwave
   * @param equipJson equipment json data
   * @param shiftPos change position vector
   * @returns Cone object
   */
  public constructConeGeometry(equipJson: EquipmentData, shiftPos: Vector3d = new Vector3d(0, 0, 0), _backPoint: Point3d, _frontPoint: Point3d, _frontRadius: number, _backRadius: number, _capped: boolean = true): Cone {
    // =====================================
    // Step 1 - Build the Cone in local coordinate system
    // Equipment Thickness represents Height of the cylinder and Equipment Height represents Diameter of the Cylinder
    // =====================================
    const localPoint = new Point3d(0, 0, 0);

    // const backPt = localPoint.plusScaled(Vector3d.unitY(), -0.5 * equipJson.Thickness);
    // const frontPt = localPoint.plusScaled(Vector3d.unitY(), 0.3 * equipJson.Thickness);
    // const frontRad = equipJson.equipSpecs.frontRad == undefined ? equipJson.Height : equipJson.equipSpecs.frontRad*equipJson.Height;
    // const backRad = equipJson.equipSpecs.backRad == undefined ? equipJson.Height : equipJson.equipSpecs.backRad*equipJson.Height;

    const cylinder = Cone.createAxisPoints(_backPoint, _frontPoint, _frontRadius, _backRadius, _capped);
    // const cylinder = Cone.createAxisPoints(backPt, frontPt, frontRad, backRad, true);
    // Always start by building a brand new cone.... do not untransform the old box
    // =====================================
    // Step 2 - Apply Transform (local coordinate system > Global coordinate system)
    // =====================================
    // Pass the new equipment position json object
    return this.applyTransformFromLocaltoGlobal(cylinder!, localPoint, JSON.parse(JSON.stringify(equipJson)), shiftPos)  as Cone;
  }

  public constructBoxGeometry(equipJson: EquipmentData, scaleFactor: Vector3d = new Vector3d(1, 1, 1), shiftPos: Vector3d = new Vector3d(0, 0, 0)): Box|LinearSweep {
    const localCentroid = new Point3d(0, 0, 0);
    // =====================================
    // Step 1 - Build the box in local coordinate system
    // =====================================
    let geometry: any = this.createBoxInLocalCoordinate(localCentroid, equipJson.Height, equipJson.Width, equipJson.Thickness)! as Box;
        
    // if(equipJson.shapeId && iTwinShowEquipmentShape) {
    if((equipJson.Manufacturer != "UPT:NewEquip" && equipJson.Model != "UPT:NewEquip-001") && (equipJson.Manufacturer != null && equipJson.Model != null) && iTwinShowEquipmentShape){

        const equipPolygons = EquipmentsTable.equipPolygons;
        const theShape = equipPolygons.find(e=>e.manufacturer == equipJson.Manufacturer && e.model == equipJson.Model);
        // console.log('EquipmentsTable.allPolygonPoints: ', EquipmentsTable.allPolygonPoints, theShape);
        if(theShape){
          let hScale: number = 1, wScale: number = 1, dScale: number = 1;
          if(equipJson.equipSpecs.heightDiff){
            hScale = equipJson.equipSpecs.heightDiff*equipJson.Height/100;
            wScale = equipJson.equipSpecs.widthDiff*equipJson.Width/100;
            dScale = equipJson.equipSpecs.depthDiff*equipJson.Thickness/100;
          }
          
          let theCoords = theShape.scaledPolygonPoint.topProfile;
          const lineString = Loop.createPolygon(theCoords.map(ap=>new Point3d(ap.x, ap.y, 0)));        
          const ls = LinearSweep.create(lineString, Vector3d.create(0, 0, equipJson.Height), true/* capped */)! as LinearSweep;
          ls?.tryTransformInPlace(Transform.createFixedPointAndMatrix(Point3d.create(0, 0, 0), Matrix3d.createScale(scaleFactor.x, scaleFactor.y, scaleFactor.z)));
                  
          const azMatrix = Matrix3d.createRotationAroundAxisIndex(AxisIndex.Z, Angle.createDegrees(90));
          const trans = Transform.createFixedPointAndMatrix(ls.cloneSweepVector(), azMatrix);
          let newCentroidPtInGlobal = trans.origin;
          newCentroidPtInGlobal = trans.multiplyPoint3d(newCentroidPtInGlobal);
          // Apply to the ls
          ls?.tryTransformInPlace(trans);
          
          shiftPos.z-=equipJson.Height/2;
          const boxTransform = this.applyTransformFromLocaltoGlobal(ls!, localCentroid, JSON.parse(JSON.stringify(equipJson)), shiftPos) as LinearSweep;
          return boxTransform;
        }
    }
    const boxTransform = this.applyTransformFromLocaltoGlobal(geometry!, localCentroid, JSON.parse(JSON.stringify(equipJson)), shiftPos) as Box;
    boxTransform?.tryTransformInPlace(Transform.createFixedPointAndMatrix(Point3d.create(0, 0, 0), Matrix3d.createScale(scaleFactor.x, scaleFactor.y, scaleFactor.z)));
    return boxTransform;
  }
  /**
   * Build the box in local coordinate system
   * @localCentroid local centroid
   * @param height equipment height
   * @param width equipment width
   * @param thickness equipment thickness
   * @param centroidPt centroid point
   * @returns create a box
   */
  private createBoxInLocalCoordinate(localCentroid: Point3d, height: number, width: number, thickness: number) {
    const localBaseCenter = new Point3d(localCentroid.x, localCentroid.y, -0.5 * height);
    const localBaseOrigin = localBaseCenter.clone();
    localBaseOrigin.x = localBaseOrigin.x - 0.5 * width;
    localBaseOrigin.y = localBaseOrigin.y - 0.5 * thickness;

    const localTopOrigin = localBaseOrigin.clone();
    localTopOrigin.z = localTopOrigin.z + height;

    const box = Box.createDgnBoxWithAxes(localBaseOrigin, Transform.identity.matrix, localTopOrigin, width, thickness, width, thickness, true);
    return box;
  }
  /**
   * create transform from local to global
   * @param boxOrConeInLocal box or cone in local coordinate
   * @param centroidPtInLocal centroid in local coordinate
   * @param equipJson Equipment Object
   * @returns Transformed Box or Cone
   */
  private applyTransformFromLocaltoGlobal(boxOrConeInLocal: SolidPrimitive| Loop, centroidPtInLocal: Point3d, equipJson: EquipmentData, shiftPosIn: Vector3d): SolidPrimitive|Loop {
    let oldCentroidPtInGlobal = new Point3d(equipJson.x_position, equipJson.y_position, equipJson.z_position);
    // =====================================
    // Step 0 - Calculate Centroid with Height Factor
    // =====================================
    const iModel = iModelConnection! || UiFramework.getIModelConnection();

    // Use MSL variable to store values for solve problem of OLD CONTEXT CAPTURE generated model
    let msl: number = 0;
    const cart = iModel.spatialToCartographicFromEcef(iModel.projectExtents.high!);
    oldCentroidPtInGlobal = DecoratorHelper.ExtractSpatialXYZ(cart, oldCentroidPtInGlobal.x, oldCentroidPtInGlobal.y, oldCentroidPtInGlobal.z, iModel);
    if (ConfigManager.RealityDataVersion && ConfigManager.RealityDataVersion === "OLD") {
      msl = egm96.meanSeaLevel(cart.latitudeDegrees, cart.longitudeDegrees);
      oldCentroidPtInGlobal.z = oldCentroidPtInGlobal.z - msl;
    }

    // }
    // =====================================
    // Step 1 - Apply Tilt about the X-axis (Local Coordinate)
    // =====================================
    // const equipTilt = equipJson.Equipment_Type == EquipmentType.SQUID ? equipJson.DownTilt+90 : equipJson.DownTilt;
    const equipTilt = equipJson.Equipment_Type == EquipmentType.SQUID || equipJson.Equipment_Name.match(/SQUID/ig) ? equipJson.DownTilt+90 : equipJson.DownTilt;
    const tiltMatrix = Matrix3d.createRotationAroundAxisIndex(AxisIndex.X, Angle.createDegrees(-1*equipTilt));
    let trans = Transform.createFixedPointAndMatrix(centroidPtInLocal, tiltMatrix);
    // Apply to the box
    boxOrConeInLocal?.tryTransformInPlace(trans);
    let newCentroidPtInGlobal = trans.multiplyPoint3d(centroidPtInLocal.clone());
    // Apply the same transform to the box's or cone's imaginary y-vector
    let xYVec = new Vector3d(0, 1, 0);
    xYVec = trans.multiplyVector(xYVec);
    let shiftPos = trans.multiplyVector(shiftPosIn.clone());

    // =====================================
    // Step 2 - Apply -Roll about the Y-axis (Local Coordinate)
    // =====================================
    const rollMatrix = Matrix3d.createRotationAroundVector(xYVec, Angle.createDegrees(-equipJson.Roll));
    trans = Transform.createFixedPointAndMatrix(centroidPtInLocal, rollMatrix!);
    newCentroidPtInGlobal = trans.multiplyPoint3d(newCentroidPtInGlobal);
    // Apply to the box
    boxOrConeInLocal?.tryTransformInPlace(trans);
    shiftPos = trans.multiplyVector(shiftPos);

    // =====================================
    // Step 3 - Apply -Azimuth about the Z-axis (Local Coordinate)
    // =====================================
    const azMatrix = Matrix3d.createRotationAroundAxisIndex(AxisIndex.Z, Angle.createDegrees(-equipJson.Azimuth));
    trans = Transform.createFixedPointAndMatrix(centroidPtInLocal, azMatrix);
    newCentroidPtInGlobal = trans.multiplyPoint3d(newCentroidPtInGlobal);
    // Apply to the box
    boxOrConeInLocal?.tryTransformInPlace(trans);
    shiftPos = trans.multiplyVector(shiftPos);

    // =====================================
    // Step 4 - apply centroid transform (Global Coordinate)
    // =====================================
    shiftPos = shiftPos.plus(oldCentroidPtInGlobal);
    const translation = Transform.createTranslation(shiftPos);
    // Apply to the box
    boxOrConeInLocal?.tryTransformInPlace(translation);
    // multiply local centroid point
    newCentroidPtInGlobal = translation.multiplyPoint3d(newCentroidPtInGlobal);
    // =====================================
    // Step 5 - Save value in UTM
    // =====================================
    if (!iModel.isBlank) {
      const cart = iModel!.spatialToCartographicFromEcef(newCentroidPtInGlobal);
      const m = DecoratorHelper.convertWGS2UTM(cart);
      equipJson.x_position = newCentroidPtInGlobal.x = m[0];
      equipJson.y_position = newCentroidPtInGlobal.y = m[1];
      equipJson.z_position = newCentroidPtInGlobal.z = cart.height;
    } else {
      const htBuffer = iModel.spatialToCartographicFromEcef(newCentroidPtInGlobal).height;
      const correctZ = (htBuffer - newCentroidPtInGlobal.z) + newCentroidPtInGlobal.z;
      equipJson.x_position = newCentroidPtInGlobal.x;
      equipJson.y_position = newCentroidPtInGlobal.y;
      equipJson.z_position = newCentroidPtInGlobal.z = correctZ + msl;
    }
    return boxOrConeInLocal;
  }

  public createGraphics(context: DecorateContext): RenderGraphic | undefined {
    // Get next available Id to represent decoration for its life span.

    this.shapes.forEach((styledGeometry) => {
      let transientId: Id64String;
      if (styledGeometry.transientId === "") {
        transientId = context.viewport.iModel.transientIds.next;
      } else {
        transientId = styledGeometry.transientId;
      }

      const builder = context.createGraphicBuilder(GraphicType.Scene, undefined, transientId);
      // builder.wantNormals = true;
      const geometry = styledGeometry.geometry;
      builder.setSymbology(styledGeometry.color, styledGeometry.fillColor, styledGeometry.lineThickness, styledGeometry.linePixels);
      this.createGraphicsForGeometry(geometry, styledGeometry.edges, builder);

      context.addDecorationFromBuilder(builder);
    });

    // const builder = context.createGraphicBuilder(GraphicType.Scene, undefined, iModelConnection?.transientIds.next);
    // builder.wantNormals = true;

    return undefined;
  }

  private createGraphicsForGeometry(geometry: GeometryQuery, wantEdges: boolean, builder: GraphicBuilder) {
    if (geometry instanceof LineString3d) {
      builder.addLineString(geometry.points);
    } else if (geometry instanceof Loop) {
      builder.addLoop(geometry);
      if (wantEdges) {
        // Since decorators don't natively support visual edges,
        // We draw them manually as lines along each loop edge/arc
        // builder.setSymbology(ColorDef.black, ColorDef.black, 2);
        const curves = geometry.children;
        curves.forEach((value) => {
          if (value instanceof LineString3d) {
            let edges = value.points;
            const endPoint = value.pointAt(0);
            if (endPoint) {
              edges = edges.concat([endPoint]);
            }
            builder.addLineString(edges);
          } else if (value instanceof Arc3d) {
            builder.addArc(value, false, false);
          }
        });
      }
    } else if (geometry instanceof Path) {
      builder.addPath(geometry);
    } else if (geometry instanceof IndexedPolyface) {
      builder.addPolyface(geometry, true);
      if (wantEdges) {
        // Since decorators don't natively support visual edges,
        // We draw them manually as lines along each facet edge
        builder.setSymbology(ColorDef.black, ColorDef.black, 2);
        const visitor = IndexedPolyfaceVisitor.create(geometry, 1);
        let flag = true;
        while (flag) {
          const numIndices = visitor.pointCount;
          for (let i = 0; i < numIndices - 1; i++) {
            const point1 = visitor.getPoint(i);
            const point2 = visitor.getPoint(i + 1);
            if (point1 && point2) {
              builder.addLineString([point1, point2]);
            }
          }
          flag = visitor.moveToNextFacet();
        }
      }
    } else if (geometry instanceof LineSegment3d) {
      const pointA = geometry.point0Ref;
      const pointB = geometry.point1Ref;
      const lineString = [pointA, pointB];
      builder.addLineString(lineString);
    } else if (geometry instanceof Arc3d) {
      builder.addArc(geometry, false, false);
    } else if (geometry instanceof CurveChainWithDistanceIndex) {
      this.createGraphicsForGeometry(geometry.path, wantEdges, builder);
    }
  }

  public addGeometry(geometry: GeometryQuery, _fillColor: ColorDef, _name: string, _edges = this.edges, _capped: boolean = this.capped, _modelData: EquipmentData|any = undefined) {
    let transId: string|undefined = this.nameIdMap.get(_modelData.DisplayName.toString()) !== undefined ? this.nameIdMap.get(_modelData.DisplayName.toString())! : "";
    if((_modelData.Equipment_Type == EquipmentType.SQUID || _modelData.DisplayName.match(/SQUID/ig)) && _name.match(/_RC/))transId=this.shapes.find(e=>e.modelData?.DisplayName==_name.split('_RC')[0])?.transientId;
    if((_modelData.Equipment_Type == EquipmentType.Microwave || _modelData.DisplayName.match(/micro_wave/ig)) && _name.match(/_MW/))transId=this.shapes.find(e=>e.modelData?.DisplayName==_name.split('_MW')[0])?.transientId;
    if(_name.match(/_face/ig)){
      const nameSplit = _name.split('_');
      switch (nameSplit[0]) {
        case "ANT":
          transId=this.shapes.find(e=>e.modelData?.DisplayName==`Antenna_${nameSplit[1]}`)?.transientId;
          break;
        case "RRU":
          transId=this.shapes.find(e=>e.modelData?.DisplayName==`RRU_${nameSplit[1]}`)?.transientId;
          break;
        case "MW":
          transId=this.shapes.find(e=>e.modelData?.DisplayName==`Micro_Wave_${nameSplit[1]}`)?.transientId;
          break;
      
        default:
          break;
      }
    }
    const styledGeometry: CustomGeometryQuery = ({
        geometry,
        color: this.color,
        fill: this.fill,
        fillColor: _fillColor,
        lineThickness: this.lineThickness,
        edges: _edges,
        capped: _capped,
        modelData: _modelData,
        linePixels: this.linePixels,
        transientId: transId!,
        name: _name,
        type: _name.match(/Micro_Wave/i) == null ? _name.toString().split('_')[0] : "Micro_Wave",
      });
    this.shapes.push(styledGeometry);
  }
}
