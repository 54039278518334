import "./FrontstageWidgets.scss";
import "./Lists/List.scss";

import React from 'react'
import { PropertyRecord } from "@itwin/appui-abstract";
import { UiFramework, WidgetDef } from "@itwin/appui-react";
import { IModelApp } from "@itwin/core-frontend";
import { connect } from 'react-redux'
import { store } from "../../store/rootReducer";
import { ConfigManager } from "../../config/ConfigManager";
import { AddMountPipeTool } from "./tools/AddMountPipeTool";
import { clickType } from "../../store/detectedData/apiDataTypes";
import { MountDecorator } from "../tools/decorators/MountDecorator";
import { ReduceDecimalNumberToTwoPlaces } from "./Lists/EquipmentList";
import { getValueInRequiredUnit, getUnit } from "./Tables/BaseTable";
import { AppSettings } from "../../config/AppSettings";
import { unitSystemType } from "../../store/redux-types";

export const useWidgetDef = (widgetId: string): WidgetDef | undefined => {
    const frontstageDef = UiFramework.frontstages.activeFrontstageDef;
    return frontstageDef?.findWidgetDef(widgetId);
  };
  
export const GetMountProperties = () => {

    return (
        <>
        </>
    );
}

// GetMountProperties.propTypes = {
//   second: PropTypes.third
// }

export const createSupportingMember = async (event) =>{
    const allMountData = await getMountData();
    event.persist();
    IModelApp.tools.run(AddMountPipeTool.toolId, event.target.previousSibling.value, allMountData)
}

const mapStateToProps = () => {

}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(GetMountProperties);

export const getMountKeyValue = async (clicked: clickType) => {
    const towerdata = store.getState().detectedData.towerStructureData;
    const concreteHeight = ConfigManager.AGL ? Object.entries(towerdata).filter((f) => f[0] === "concrete_height")[0][1] : 0;
    const dataProvider: PropertyRecord[] = [];
    const nameValuePair: {name: string, value: string}[] = [];

    const mountDec = IModelApp.viewManager.decorators.filter(e=>e.constructor.name.includes("MountDecorator"))[0] as MountDecorator;
    if(!mountDec)return;
    
    const data = mountDec.selectedMount;

    const allMountData = await getMountData();
    let centerlineElevation =  AppSettings.defaultDisplaySettings.unitSystem == unitSystemType.METRIC?(allMountData.elevation + concreteHeight).toFixed(2): ((allMountData.elevation + concreteHeight)*3.28084).toFixed(2)
    if(clicked == clickType.DOUBLE){

        // const opDet = SampleToolWidget.sampleToolWidget.state.showEOMapModal.existingMapping.filter(e=>e.mountName == allMountData.name);
        const opDet = [{operator: "Select operator"}];
        const parMountName = `${allMountData.name[0].toUpperCase()+allMountData.name.substr(1).replace(/[^a-z]/g, '')} ${parseInt(allMountData.name.replace(/[^0-9]/g, ''))}`;

        nameValuePair.push({name: "Name", value: parMountName});
        nameValuePair.push({name: "Sector", value: data.modelData.sector});
        
        if(allMountData.type.match(/platform/i)){
            nameValuePair.push({name: "No. of Faces", value: allMountData.noOfFaces});
        } else {
            nameValuePair.push({name: "Face Name", value: allMountData.faceName.replace(ConfigManager.PasCaseToSpSepRegExp, '$1$4 $2$3$5')});
        }
        
        nameValuePair.push({name: "Type", value: allMountData.type});
        let elevation =  AppSettings.defaultDisplaySettings.unitSystem == unitSystemType.METRIC?(allMountData.elevation + concreteHeight).toFixed(2): ((allMountData.elevation + concreteHeight)*3.28084).toFixed(2)

        const theHeight = allMountData.type.match(/platform/ig) ? allMountData.height : allMountData.parentMount.FACEs[allMountData.faceName].height;
        nameValuePair.push({name: `Height ${getUnit()}`, value: getValueInRequiredUnit(ReduceDecimalNumberToTwoPlaces(theHeight))});
        
        nameValuePair.push({name: `Width ${getUnit()}`, value:getValueInRequiredUnit(ReduceDecimalNumberToTwoPlaces(data.modelData.length)) });
        
        nameValuePair.push({name: `Azimuth (°)`, value: ReduceDecimalNumberToTwoPlaces(parseFloat(data.modelData.azimuth)).toString()});
        
        if(allMountData.diameter){
            nameValuePair.push({name: `Diameter  ${getUnit()}`, value: getValueInRequiredUnit(ReduceDecimalNumberToTwoPlaces(allMountData.diameter))});
        }
        
        nameValuePair.push({name: "Operator", value: opDet.length && opDet[0].operator != "Select operator" ? opDet[0].operator: ""});     

        nameValuePair.push({name: `Mount Centerline Elevation ${getUnit()} *`, value: elevation});
        
    } else {

        const mountData = data.modelData;

        if(mountData?.orientation == "Vertical"){
            nameValuePair.push({name: "Name", value: mountData.name});
            
            if(mountData.parentMountType == "Standoff")nameValuePair.push({name: "Sector", value: mountData.sector == undefined || mountData.sector == null ? "" : mountData.sector});
            nameValuePair.push({name: "Mount Face", value: mountData.mountFace === undefined? "":mountData.mountFace.replace(ConfigManager.PasCaseToSpSepRegExp, '$1$4 $2$3$5')});
            let length =  AppSettings.defaultDisplaySettings.unitSystem == unitSystemType.METRIC?parseFloat(mountData.length).toFixed(2): (parseFloat(mountData.length)*3.28084).toFixed(2)

            nameValuePair.push({name: `Length ${getUnit()}`, value:length});
            let elevation =  AppSettings.defaultDisplaySettings.unitSystem == unitSystemType.METRIC?(mountData.elevation + concreteHeight).toFixed(2): ((mountData.elevation + concreteHeight)*3.28084).toFixed(2)
            let lateralOffset =  AppSettings.defaultDisplaySettings.unitSystem == unitSystemType.METRIC?parseFloat(mountData.lateralOffset).toFixed(2): (parseFloat(mountData.lateralOffset)*3.28084).toFixed(2)
            let verticalOffset =  AppSettings.defaultDisplaySettings.unitSystem == unitSystemType.METRIC?parseFloat(mountData.verticalOffset).toFixed(2): (parseFloat(mountData.verticalOffset)*3.28084).toFixed(2)

            nameValuePair.push({name: `Lateral Offset ${getUnit()}`, value: lateralOffset});
            nameValuePair.push({name: `Vertical Offset ${getUnit()}`, value: verticalOffset});
            
            nameValuePair.push({name: "Tilt", value: mountData.tilt > 175 && mountData.tilt < 182 ? ReduceDecimalNumberToTwoPlaces(180-mountData.tilt).toString() : mountData.tilt });
            
            nameValuePair.push({name: `Width ${getUnit()}`, value:getValueInRequiredUnit(ReduceDecimalNumberToTwoPlaces(data.modelData.length)) });
            
            nameValuePair.push({name: `Azimuth (°)`, value: ReduceDecimalNumberToTwoPlaces(parseFloat(data.modelData.azimuth)).toString()});
            nameValuePair.push({name: "Empty/Occupied", value: mountData.vacancy === "Vacant" ? "Empty": "Occupied"});
            if(mountData.csEquipNames != ""){
                nameValuePair.push({name: "Occupied Equipment", value: mountData.csEquipNames});
            }

            nameValuePair.push({name: `Pipe Elevation ${getUnit()} *`, value: elevation});
            nameValuePair.push({name: `Mount Centerline Elevation ${getUnit()} *`, value: centerlineElevation});
        } else if(mountData?.orientation == "Horizontal"){
            nameValuePair.push({name: "Name", value: mountData.name});
            
            nameValuePair.push({name: "Mount Face", value: mountData.mountFace === undefined? "":mountData.mountFace.replace(ConfigManager.PasCaseToSpSepRegExp, '$1$4 $2$3$5')});
            
            nameValuePair.push({name: `Width ${getUnit()}`, value: getValueInRequiredUnit(mountData.length)});
            let elevation =  AppSettings.defaultDisplaySettings.unitSystem == unitSystemType.METRIC?(mountData.elevation + concreteHeight).toFixed(2): ((mountData.elevation + concreteHeight)*3.28084).toFixed(2)

            nameValuePair.push({name: `Azimuth (°)`, value: ReduceDecimalNumberToTwoPlaces(parseFloat(data.modelData.azimuth)).toString()});
            const horizontalPipeIndex = mountData.index.split(' of ');

            nameValuePair.push({name: `${parseInt(horizontalPipeIndex[0])+1 == parseInt(horizontalPipeIndex[1]) ? `Mount Top ` : (parseInt(horizontalPipeIndex[0]) == 0 && parseInt(horizontalPipeIndex[1]) > 1) ? `Mount Bottom ` : `` }Elevation ${getUnit()} *`, value: elevation});
            nameValuePair.push({name: `Mount Centerline Elevation ${getUnit()} *`, value: centerlineElevation});
            // nameValuePair.push({name: `Horizontal Index ${getUnit()} *`, value: mountData.index.toString()});
        } else if(mountData?.orientation == "Circular"){
            let elevation =  AppSettings.defaultDisplaySettings.unitSystem == unitSystemType.METRIC?(mountData.altitude + concreteHeight).toFixed(2): ((mountData.altitude + concreteHeight)*3.28084).toFixed(2)

            nameValuePair.push({name: `Elevation ${getUnit()} *`, value: ReduceDecimalNumberToTwoPlaces(elevation).toString()});
            nameValuePair.push({name: `Mount Centerline Elevation ${getUnit()} *`, value: centerlineElevation});

            nameValuePair.push({name: `Diameter ${getUnit()}`, value: ReduceDecimalNumberToTwoPlaces(getValueInRequiredUnit(mountData.diameter)).toString()});
        }

    }
    // nameValuePair.push({name: "Vertical Offset", value: ReduceDecimalNumberToTwoPlaces(record.verticalOffset).toString()});
    if(allMountData.parentMount.hasOwnProperty("manufacturer")){
        nameValuePair.push({name: "Manufacturer", value: allMountData.parentMount.manufacturer});
    }else    nameValuePair.push({name: "Manufacturer", value: ""});

    if(allMountData.parentMount.hasOwnProperty("model")){
        nameValuePair.push({name: "Model", value: allMountData.parentMount.model});
    }else    nameValuePair.push({name: "Model", value: ""});


    // let propListObj: selectedObjectInformation = initialPropertyListObjectState;
    // propListObj = {...propListObj, heading: "Mount Information", propertyList: init_RM_TowerPropListState };

    // return dataProvider;
    // store.dispatch(getPropertyListStructure(`abc`));
    return nameValuePair;
}

const getMountData = async () => {
    const mountDec = IModelApp.viewManager.decorators.filter(e=>e.constructor.name.includes("MountDecorator"))[0] as MountDecorator;
    if(!mountDec.selectedMount)return null; 
    const selMount = mountDec.selectedMount;
    const currState = store.getState();
    if(selMount?.name.includes('temp'))return null;   //Returning null if the pipe is temperory and not yet added to the database with the properties required.   
    const selParMount = MountDecorator.mountData.filter((e) => e[0] === selMount.modelData.parentMount)[0];
    const elevation = (currState.detectedData.siteCoordinate) ? ReduceDecimalNumberToTwoPlaces(selParMount[1].utm_altitude - currState.detectedData.siteCoordinate.utm.z) : "";
    const fullMountData: any = {
        name: selParMount[0],
        utm_altitude: selParMount[1].utm_altitude,
        noOfFaces: Object.entries(selParMount[1].FACEs).length.toString(),
        type: selParMount[1].type,
        faceName: selMount.modelData.mountFace,
        elevation: elevation,
        height: selParMount[1].height,
        parentMount: selParMount[1]
    }
    if(selMount.modelData.parentMountType.match(/circular/i))fullMountData.diameter = selMount.modelData.diameter;
    return fullMountData;
}

