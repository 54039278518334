// import { FeedlineType } from "../app-ui/frontstages/SampleToolWidget"
import { pecInputTagTypes, pecDataValueTypes, pecRows, pecGridStructure, pecComponentProps, pecStrucType, pecTableButton, PropertyEditorComponent, numericValue, pecOptionData } from "../components/PropertyEditorComponent"

import React, { ReactNode, useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { BeButtonEvent, IModelApp, NotifyMessageDetails, OutputMessagePriority, OutputMessageType } from "@itwin/core-frontend"
import { SyncUiEventDispatcher, UiFramework } from "@itwin/appui-react"
// import FeedlineClient from "../api/feedline"
import { Point3d, Vector3d, YawPitchRollAngles } from "@itwin/core-geometry"
import { RootState } from "../../store/States"
import { store } from "../../store/rootReducer"
import { CreateGeometryMode, FeedlineData, FeedlineDecorator, ShapeType } from "./decorators/FeedlineDecorator"
import { FeedlineRotationWidget } from "./widgets/FeedlineRotationWidget"
import { RadioSection } from "./DetectedMountEditHandler"
import FeederLineClient, { feedlineDataStructure } from "../api/feederLinesClient"
import { DecoratorHelper } from "./decorators/DecoratorHelper"
import { setFeedLineData } from "../../store/detectedData/apiDataActionTypes"
import { OutlineFeedlineTool } from "../components/tools/OutlineFeedlineTool"
import { SyncUiEventIds } from "../../store/redux-types"
import { Id64String } from "@itwin/core-bentley"
import { resetObjectIds } from "../components/HorizontalToolbarItems"
import { FeedlineSectionType, ObjectCategoryTypes } from "../../store/detectedData/apiDataTypes"
import { PointSelectionTool } from "../components/tools/PointSelectionTool"
import { DTVActions } from "../../store/Actions"
// import { FeedlinesTable } from "../components/Tables/FeedlinesTable"
// import { fetchFeedlineData } from "./GetDetectedObjectsData"

export enum FeedlineRotationType {
  Azimuth = 0,
  Tilt = 1,
  Roll = 2,
}

export enum editMode {
  radial = "Radial",
  positional = "Positional",
  width = "Width",
  depth = "Depth",
  length = "Length",
  add_section = "Add_Section"
}

let runTimer: any;
export const getUnlinkedFeedline = (linkedFeedlines: FeedlineData[]) => {
  const unlinkedFeedlines: FeedlineData[] = [];
  linkedFeedlines.forEach(linkedFeedline=>{
    const unlinkedFeedline = JSON.parse(JSON.stringify(linkedFeedline));
    unlinkedFeedline.ActualPositions.base = Point3d.fromJSON(unlinkedFeedline.ActualPositions.base);
    unlinkedFeedline.ActualPositions.top = Point3d.fromJSON(unlinkedFeedline.ActualPositions.top);
    unlinkedFeedline.CenterPositions.localBase = Point3d.fromJSON(unlinkedFeedline.CenterPositions.localBase);
    unlinkedFeedline.CenterPositions.localTop = Point3d.fromJSON(unlinkedFeedline.CenterPositions.localTop);
    unlinkedFeedlines.push(unlinkedFeedline);
  })
  return unlinkedFeedlines!;
}


const DetectedFeedlineEditHandler = (props ) => {
  const [feedlineEditData, setFeedlineEditStructure] = useState<pecGridStructure>(props.feedlineMenuStruc);
  const [dataUpdated, setDataUpdated] = useState<boolean>(false);
  const [segmentAdded, setSegmentAdded] = useState<boolean>(false);
  const [selection, setSelection] = useState(props.editSection);
  const [selectedObjectInfo, setSelectedObjectInfo] = useState(null);
  const [selectedSegment, setSelectedSegment] = useState(null);
  const [saveButton, setSaveButton] = useState<{disabled: boolean, text: string}>({disabled: false, text: "Save"});
  // const [theSegments, setTheSegments] = useState<any>(undefined);
  const move = 0.02;
  const radiusMove = 0.02;
  
//   useEffect(() => {
//     setFeedlineEditStructure(getFieldStructure(props.selectedObject.selectedObjectNST.name).feedlineMenuStruc);
//     // return () => {}
//   }, [props.selectedObject.selectedObjectNST.name])
  

  const getFeedInEditMode = () => {
    let decorator = IModelApp.viewManager.decorators.filter((e) => e.constructor.name.includes("FeedlineDecorator"))[0] as FeedlineDecorator;
    if(decorator.untouchedFeedlineData == undefined){
        
        decorator.untouchedFeedlineData=getUnlinkedFeedline([props.feedlinePreviousState])[0];
        const theSegments = props.feedlinePreviousState.FeedlineType.type == FeedlineSectionType.Segmented ? decorator.shapes.filter(e=>e.name == props.feedlinePreviousState.Id.split('_')[0]) : [];

        decorator.untouchedFeedlineSegments=getUnlinkedFeedline(theSegments.map(e=>e.modelData!));
    }
  
    if(Object.entries(props.feedlinePreviousState).length){

        // const newShapes = decorator.shapes.filter(e=>!e.name.toString().match(props.feedlinePreviousState.Id!.split('_')[0]))

        const allFeeds = decorator.shapes.filter(e=>e.name == props.feedlinePreviousState.Id.split('_')[0]);
        decorator.createGeometry(props.feedlinePreviousState, CreateGeometryMode.Edit);
        if(props.feedlinePreviousState.FeedlineType.type == FeedlineSectionType.Segmented){
            allFeeds.forEach(e=>{
                let shapeIndex = decorator.shapes.findIndex(f=>f.modelData?.Id == e.modelData!.Id);
                if(shapeIndex != -1){
                    decorator.objectIdMap.delete(decorator.shapes[shapeIndex].transientId);   
                    decorator.shapes.splice(shapeIndex, 1);
                }
                // shapeIndex = decorator.shapes.findIndex(e=>e.modelData?.Id.match(props.feedlinePreviousState.Id.split('@')[0]));
            })
        } else {
            const shapeIndex = decorator.shapes.findIndex(e=>e.modelData?.Id==props.feedlinePreviousState.Id);
            if(shapeIndex != -1){
                decorator.objectIdMap.delete(decorator.shapes[shapeIndex].transientId);   
                decorator.shapes.splice(shapeIndex, 1);
            }
        }
        IModelApp.viewManager.invalidateDecorationsAllViews();
        IModelApp.viewManager.selectedView?.invalidateCachedDecorations(decorator);
    }

  }

  useEffect(() => {
    setSelectedObjectInfo(props.selectedObjectInfo);
    getFeedInEditMode();
      // return () => {}
  }, []);

  useEffect(() => {
    let decorator = IModelApp.viewManager.decorators.filter((e) => e.constructor.name.includes("FeedlineDecorator"))[0] as FeedlineDecorator;
    const temp: pecGridStructure = {...props.feedlineMenuStruc};

    if(segmentAdded){
      // temp.rows[6].columns[1].data.push({label: temp.rows[6].columns[1].data.length, value: temp.rows[6].columns[1].data.length});
      temp.rows[6].columns[1].disabled = false;
      temp.rows[8].columns[1].disabled = true;
    }

    setFeedlineEditStructure(temp);
    // const theShape = decorator.shapes.find(e=>e.modelData?.Id==decorator.selectedFeedline.modelId);
    // if(decorator.untouchedFeedlineData != undefined &&  store.getState().dtvState.applicationState.isEditModeActive){
    //   // if(storedFl.findIndex(e=>e.clusterId == parseInt(feedId)) != -1){
    //       if(decorator.untouchedFeedlineData!.FeedlineType.type == FeedlineSectionType.Segmented){
    //         decorator.untouchedFeedlineSegments.forEach(e=>decorator.createGeometry(e));
    //       } else decorator.createGeometry(decorator.untouchedFeedlineData!, CreateGeometryMode.New);
    //       const newShapes = decorator.shapes.filter(e=>!e.modelData?.Id.match(/start|middle|segment|end/));
    //       decorator.shapes = newShapes;
  
    //       // SyncUiEventDispatcher.dispatchImmediateSyncUiEvent(SyncUiEventIds.Nothing_Selected);
    //       // SyncUiEventDispatcher.dispatchImmediateSyncUiEvent(SyncUiEventIds.RM_Feedline_UnSelected);
    //   // } else {
    //   //   const shapeIndex = decorator.shapes.findIndex(e=>e.modelData?.Id==feedId);
    //   //   if(shapeIndex != -1)decorator.shapes.splice(shapeIndex, 1);
    //   // } 
    //   decorator.untouchedFeedlineData = theShape ? {...theShape.modelData} as FeedlineData : undefined;
      
    //   const theSegments = theShape?.modelData?.Id.match(/@/) ? decorator.shapes.filter(e=>e.name == theShape.modelData?.Id.split('_')[0]) : [];

    //   decorator.untouchedFeedlineSegments = theSegments.length ? getUnlinkedFeedline(theSegments.map(e=>e.modelData!)) : [];
    //   if(decorator.untouchedFeedlineData)decorator.createGeometry(decorator.untouchedFeedlineData!, CreateGeometryMode.Edit);

    //   // resetObjectIds(decorator);
    // }

  }, [props.feedlineMenuStruc]);

  
  const saveData = async (posData, modified): Promise<boolean> => {
    let retResp: boolean = false;
    if (posData && modified) {

        const decorator = IModelApp.viewManager.decorators.filter((e) => e.constructor.name.includes("FeedlineDecorator"))[0] as FeedlineDecorator;
        // const shapeName = props.selectedObjectInfo.objectProperties.selectedObjectNST.name != "" ? props.selectedObjectInfo.objectProperties.selectedObjectNST.name : decorator.selectedFeedline.modelId;
        // const shapeName = decorator.selectedFeedline.modelId;
        const start = decorator.shapes.find(e=>e.modelData?.Id.match(/start/))?.modelData!;
        const end = decorator.shapes.find(e=>e.modelData?.Id.match(/end/))?.modelData!;
        const segments = decorator.shapes.filter(e=>e.modelData?.Id.match(/segment/));
        const middles = decorator.shapes.filter(e=>e.modelData?.Id.match(/middle/));

        const iModel = UiFramework.getIModelConnection()!;
        const currentState = store.getState();

        const theToken = currentState.auth.accessTokenState.accessToken;
        const exisitngFLData = currentState.detectedData.feedLinesData

        if(!exisitngFLData.filter(efd=>efd.clusterId==parseInt(start?.Id?.split('_')[0])).length){
          const feedlineJson = decorator.getUneditedBoxJson()[0];
          // const newBase = feedlineJson.ActualPositions.base.clone();
          // newBase.x = newBase.x + 0.5 * feedlineJson.Width;
          // newBase.y = newBase.y + 0.5 * feedlineJson.Thickness;
        
          // const newTop = feedlineJson.ActualPositions.top.clone();
          // newTop.x = newTop.x + 0.5 * feedlineJson.Width;
          // newTop.y = newTop.y + 0.5 * feedlineJson.Thickness;

          // const stPtCart = iModel!.spatialToCartographicFromEcef(newBase!);
          // const endPtCart = iModel!.spatialToCartographicFromEcef(newTop!);
          // // const stPtCart = iModel!.spatialToCartographicFromEcef(feedlineJson.ActualPositions.base!);
          // // const endPtCart = iModel!.spatialToCartographicFromEcef(feedlineJson.ActualPositions.top!);
        
          // // const topEl = stPtCart.height;
          // // const botEl = endPtCart.height;
          // let stpt = DecoratorHelper.convertWGS2UTM(stPtCart);
          // let enpt = DecoratorHelper.convertWGS2UTM(endPtCart);


          const newBase = end.ActualPositions.base.clone();
          // const newBase = feedlineJson.ActualPositions.base.clone();
          newBase.x = newBase.x + 0.5 * feedlineJson.Width;
          newBase.y = newBase.y + 0.5 * feedlineJson.Thickness;
        
          // const newTop = feedlineJson.CenterPositions.localTop.clone();
          // const newTop = feedlineJson.ActualPositions.top.clone();
          const newTop = start.ActualPositions.top.clone();
          newTop.x = newTop.x + 0.5 * feedlineJson.Width;
          newTop.y = newTop.y + 0.5 * feedlineJson.Thickness;
          
          const stPtCart = iModel!.spatialToCartographicFromEcef(newBase!);
          const endPtCart = iModel!.spatialToCartographicFromEcef(newTop!);

          const segmentPoints: any[] = [];

          segments.forEach(s=>{
            const theCoord = s.modelData?.CenterPositions.localBase.interpolate(0.5, s.modelData.CenterPositions.localTop)!;
            theCoord.x = theCoord.x + 0.5 * feedlineJson.Width;
            theCoord.y = theCoord.y + 0.5 * feedlineJson.Thickness;

            // const newBase = s.modelData?.CenterPositions.localTop.clone()!;
            // newBase.x = newBase.x + 0.5 * feedlineJson.Width;
            // newBase.y = newBase.y + 0.5 * feedlineJson.Thickness;
              
            const theCartCoord = iModel!.spatialToCartographicFromEcef(theCoord!);
            let utmCoord = DecoratorHelper.convertWGS2UTM(theCartCoord);
            segmentPoints.push({x: utmCoord[0], y: utmCoord[1], z: theCartCoord.height});
          })

          let stpt = DecoratorHelper.convertWGS2UTM(stPtCart);
          let enpt = DecoratorHelper.convertWGS2UTM(endPtCart);


          const clusterId =+ feedlineJson.Id.split('_')[0];
       
          const updatedFeedlineData: feedlineDataStructure = {
              clusterId,
              width: feedlineJson.Width,
              height: feedlineJson.Thickness,
              startCenter: {
                  x: stpt[0],
                  y: stpt[1],
                  z: stPtCart.height
              },
              endCenter: {
                  x: enpt[0],
                  y: enpt[1],
                  z: endPtCart.height
              },
              orientation: feedlineJson.Azimuth,
              segmentPoints: segmentPoints.length ? segmentPoints : null
          }

          const result = await FeederLineClient.postFeedLineData(updatedFeedlineData)
          if(result == "Successful"){
              const feedLinesData = await FeederLineClient.getFeedLineData();
              if(feedLinesData) store.dispatch(setFeedLineData(feedLinesData));
              setTimeout(async () => {
                decorator.reload(feedLinesData);
                  // await IModelApp.tools.run(OutlineFeedlineTool.toolId);
                  // await IModelApp.tools.run(OutlineFeedlineTool.toolId);
                  resetObjectIds(decorator);
              }, 0);
              IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Info, "The feedline created successfully.", "", OutputMessageType.Toast));
          }
        } else {
          const clusterId =+ start.Id.split('_')[0];
          const feedlineJson = middles[0].modelData!;



          
          const newBase = start.CenterPositions.localBase.clone();
          // const newBase = end.ActualPositions.base.clone();
          newBase.x = newBase.x + 0.5 * feedlineJson.Width;
          newBase.y = newBase.y + 0.5 * feedlineJson.Thickness;
          const stPtCart = iModel!.spatialToCartographicFromEcef(newBase!);
        
          const newTop = end.CenterPositions.localTop.clone();
          // const newTop = feedlineJson.ActualPositions.top.clone();
          // const newTop = start.ActualPositions.top.clone();
          newTop.x = newTop.x + 0.5 * feedlineJson.Width;
          newTop.y = newTop.y + 0.5 * feedlineJson.Thickness;
          const endPtCart = iModel!.spatialToCartographicFromEcef(newTop!);
          
          const o = iModel.spatialToCartographicFromEcef(iModel.projectExtents.high!);
         


          // const newCTop = start.CenterPositions.localTop.clone();
          // newCTop.x = newCTop.x - 0.5 * feedlineJson.Width;
          // newCTop.y = newCTop.y - 0.5 * feedlineJson.Thickness;
          // const startCPtCart = iModel!.spatialToCartographicFromEcef(newCTop!);
          // let stCpt1 = DecoratorHelper.convertWGS2UTM(startCPtCart);
          // const startPt = DecoratorHelper.ExtractSpatialXYZ(o, stCpt1[0], stCpt1[1], startCPtCart.height, iModel);
    
          
          // const newCBase = start.CenterPositions.localBase.clone();
          // newCBase.x = newCBase.x - 0.5 * feedlineJson.Width;
          // newCBase.y = newCBase.y - 0.5 * feedlineJson.Thickness;
          // const endCPtCart = iModel!.spatialToCartographicFromEcef(newCBase!);
          // let enCpt2 = DecoratorHelper.convertWGS2UTM(endCPtCart);
          // const enCPt = DecoratorHelper.ExtractSpatialXYZ(o, enCpt2[0], enCpt2[1], endCPtCart.height, iModel);
          
          // const newECBase = end.CenterPositions.localBase.clone();
          // newECBase.x = newECBase.x - 0.5 * feedlineJson.Width;
          // newECBase.y = newECBase.y - 0.5 * feedlineJson.Thickness;
          // const endCBPtCart = iModel!.spatialToCartographicFromEcef(newECBase!);
          // let enCBpt2 = DecoratorHelper.convertWGS2UTM(endCBPtCart);
          
          
          // const newECTop = end.CenterPositions.localTop.clone();
          // newECTop.x = newECTop.x - 0.5 * feedlineJson.Width;
          // newECTop.y = newECTop.y - 0.5 * feedlineJson.Thickness;
          // const startECPtCart = iModel!.spatialToCartographicFromEcef(newECTop!);
          // let stECpt1 = DecoratorHelper.convertWGS2UTM(startECPtCart);
          


          // // const newBase = feedlineJson.CenterPositions.localBase.clone();
          // const newBase1 = start.ActualPositions.base.clone();
          // newBase1.x = newBase1.x + 0.5 * feedlineJson.Width;
          // newBase1.y = newBase1.y + 0.5 * feedlineJson.Thickness;
          // const stPtCart1 = iModel!.spatialToCartographicFromEcef(newBase1!);
          // let stpt1 = DecoratorHelper.convertWGS2UTM(stPtCart1);
        
          // // const newTop = feedlineJson.CenterPositions.localTop.clone();
          // // const newTop = feedlineJson.ActualPositions.top.clone();
          // const newTop1 = start.ActualPositions.top.clone();
          // newTop1.x = newTop1.x + 0.5 * feedlineJson.Width;
          // newTop1.y = newTop1.y + 0.5 * feedlineJson.Thickness;
          // const endPtCart1 = iModel!.spatialToCartographicFromEcef(newTop1!);
          // let enpt2 = DecoratorHelper.convertWGS2UTM(endPtCart1);
          
          // const newSCTop1 = start.CenterPositions.localTop.clone();
          // newSCTop1.x = newSCTop1.x + 0.5 * feedlineJson.Width;
          // newSCTop1.y = newSCTop1.y + 0.5 * feedlineJson.Thickness;
          // const startECPtCart1 = iModel!.spatialToCartographicFromEcef(newSCTop1!);
          // let stCpt3 = DecoratorHelper.convertWGS2UTM(startECPtCart1);
          



          // const newSCBase1 = start.CenterPositions.localBase.clone();
          // newSCBase1.x = newSCBase1.x + 0.5 * feedlineJson.Width;
          // newSCBase1.y = newSCBase1.y + 0.5 * feedlineJson.Thickness;
          // const startCPtCart1 = iModel!.spatialToCartographicFromEcef(newSCBase1!);
          // let stCt4 = DecoratorHelper.convertWGS2UTM(startCPtCart1);
          
          
          // const newCTop1 = end.CenterPositions.localTop.clone();
          // newCTop1.x = newCTop1.x + 0.5 * feedlineJson.Width;
          // newCTop1.y = newCTop1.y + 0.5 * feedlineJson.Thickness;
          // const endECPtCart1 = iModel!.spatialToCartographicFromEcef(newCTop1!);
          // let stpt3 = DecoratorHelper.convertWGS2UTM(endECPtCart1);


          




          // const newCBase1 = end.CenterPositions.localBase.clone();
          // newCBase1.x = newCBase1.x + 0.5 * feedlineJson.Width;
          // newCBase1.y = newCBase1.y + 0.5 * feedlineJson.Thickness;
          // const endCPtCart1 = iModel!.spatialToCartographicFromEcef(newCBase1!);
          // let enpt4 = DecoratorHelper.convertWGS2UTM(endCPtCart1);
          





          const segmentPoints: any[] = [];

          segments.forEach(s=>{
            const theCoord = s.modelData?.CenterPositions.localBase.interpolate(0.5, s.modelData.CenterPositions.localTop)!;
            theCoord.x = theCoord.x + 0.5 * feedlineJson.Width;
            theCoord.y = theCoord.y + 0.5 * feedlineJson.Thickness;

            // const newBase = s.modelData?.CenterPositions.localTop.clone()!;
            // newBase.x = newBase.x + 0.5 * feedlineJson.Width;
            // newBase.y = newBase.y + 0.5 * feedlineJson.Thickness;
              
            const theCartCoord = iModel!.spatialToCartographicFromEcef(theCoord!);
            let utmCoord = DecoratorHelper.convertWGS2UTM(theCartCoord);
            segmentPoints.push({x: utmCoord[0], y: utmCoord[1], z: theCartCoord.height});
          })


          // const stPtCart = iModel!.spatialToCartographicFromEcef(feedlineJson.ActualPositions.base!);
          // const endPtCart = iModel!.spatialToCartographicFromEcef(feedlineJson.ActualPositions.top!);
        
          // const topEl = stPtCart.height;
          // const botEl = endPtCart.height;
          let stpt = DecoratorHelper.convertWGS2UTM(stPtCart);
          let enpt = DecoratorHelper.convertWGS2UTM(endPtCart);
        
          const updatedFeedlineData: feedlineDataStructure = {
              clusterId,
              width: feedlineJson.Width,
              height: feedlineJson.Thickness,
              startCenter: {
                  // x: stpt3[0],
                  // y: stpt3[1],
                  x: enpt[0],
                  y: enpt[1],
                  z: endPtCart.height
              },
              endCenter: {
                  // x: stCt4[0],
                  // y: stCt4[1],
                  x: stpt[0],
                  y: stpt[1],
                  z: stPtCart.height
              },
              orientation: feedlineJson.Azimuth,
              segmentPoints: segmentPoints.length ? segmentPoints : null
          }

          const result = await FeederLineClient.putFeedLineData(updatedFeedlineData)
          if(result == "Successful"){
              const feedLinesData = await FeederLineClient.getFeedLineData();
              if(feedLinesData) store.dispatch(setFeedLineData(feedLinesData));
              // // setTimeout(async () => {
              // //     await IModelApp.tools.run(OutlineFeedlineTool.toolId);
              // //     await IModelApp.tools.run(OutlineFeedlineTool.toolId);
              // await decorator.reload(feedlineData);
              // }, 0);
              setTimeout(async () => {
                decorator.reload(feedLinesData);
                resetObjectIds(decorator);
                  // await IModelApp.tools.run(OutlineFeedlineTool.toolId);
                  // await IModelApp.tools.run(OutlineFeedlineTool.toolId);
                            // resetObjectIds(decorator);
              }, 0);
              IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Info, "The feedline updated successfully.", "", OutputMessageType.Toast));
              // const toolRetVal = await IModelApp.tools.run(OutlineFeedlineTool.toolId, feedlineData);
        
          }

        }

    //   const equipData={...FeedlinesTable.feedlineData.filter((e)=>{return e.name === posData.Feedline_Name})[0]};
    //   const theIndex=FeedlinesTable.feedlineData.findIndex((e)=>{return e.name === posData.Feedline_Name});

    //   const currentState = store.getState();
    //   const putData: any = {                                                                
    //       name: posData.Feedline_Name,
    //       displayName: equipData.displayName,
    //       manufacturer: posData.Manufacturer,
    //       model: posData.Model,
    //       type: equipData.type,
    //       operator: equipData.operator,
    //       height: posData.Height,
    //       elevationHeight: 0,
    //       width: posData.Width,
    //       depth: posData.Thicness,
    //       weight: equipData.weight,
    //       azimuth: posData.Azimuth,
    //       tilt: posData.Tilt,
    //       xPosition: posData.x_position,
    //       yPosition: posData.y_position,
    //       zPosition: posData.z_position,
    //       roll: posData.Roll,
    //       area: equipData.area,
    //       bandTechnology: equipData.bandTechnology,
    //       reference: equipData.reference,
    //   };
    //   putData.elevationHeight =  posData.Elevation_Height == undefined ? (posData.z_position-currentState.detectedData.siteCoordinate.utm.z): posData.Elevation_Height;
    //   const infoData = currentState.detectedData.selectedObjectInformation.objectProperties;
    //   if (infoData && posData && currentState.detectedData.siteCoordinate) {
    //       await FeedlineClient.putFeedlineJson(currentState.auth.accessTokenStatePrivateAPI.accessToken!, putData, "v1.1")
    //       // tslint:disable-next-line:no-shadowed-variable
    //       .then(async (res) => {
    //           if (!res) {
    //               IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Error,
    //                   `Error occured: ${name} edit changes not saved.`, "", OutputMessageType.Toast));
    //                   retResp = false;
    //           } else {
    //             equipData.name=posData.Feedline_Name;
    //             equipData.manufacturer=posData.Manufacturer;
    //             equipData.model=posData.Model;
    //             equipData.height=posData.Height;
    //             equipData.elevationHeight=putData.elevationHeight;
    //             equipData.width=posData.Width;
    //             equipData.depth=posData.Thicness;
    //             equipData.azimuth=posData.Azimuth;
    //             equipData.tilt=posData.Tilt;
    //             equipData.xPosition=posData.x_position;
    //             equipData.yPosition=posData.y_position;
    //             equipData.zPosition=posData.z_position;
    //             equipData.roll=posData.Roll;
    //             equipData.dimensions=`${posData.Height}x${posData.Width}x${posData.Thicness}`;

    //             // (IModelApp as any).listCallback(ListEnum.Feedline);y
    //             FeedlinesTable.feedlineData[theIndex] = equipData;
    //             IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Info,
    //                 `Success: ${equipData.displayName} changes saved.`, "", OutputMessageType.Toast));
    //                 retResp = true;
    //           }
    //       });
    //     } else {
    //       IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Error,
    //       `Error occured: ${equipData.displayName} edit changes not saved.`, "", OutputMessageType.Toast));
    //       retResp = false;
    //     }
      }
      return Promise.resolve(retResp);

  }

  const onModalBtnClick = async (event: any) => {
    const id = event.target.id;
    let decorator = IModelApp.viewManager.decorators.filter((e) => e.constructor.name.includes("FeedlineDecorator"))[0] as FeedlineDecorator;
    switch (id) {
      case "Save#1": {
        // const decorator = IModelApp.viewManager.decorators.filter(e=>e.constructor.name.includes("TowerStructureDecorator"))[0] as TowerStructureDecorator;
        // decorator.saveTower(props.selObjProps.selectedObjectNST.name);
        const theUpdatedJson = {...decorator?.shapes.find((e) => e.modelData?.Id === props.selectedObjectInfo.modelId)?.modelData};
        let temp: numericValue = {
          currentValue: 0,
          min: 0,
          max: 0,
          precision: 0,
          step: 0
        };
        temp = feedlineEditData.rows[3].columns[1].value as numericValue;
        theUpdatedJson.Azimuth=temp.currentValue;
        // temp = feedlineEditData.rows[4].columns[1].value as numericValue;
        // theUpdatedJson.Roll=temp.currentValue;
        // temp = feedlineEditData.rows[5].columns[1].value as numericValue;
        // theUpdatedJson.Tilt=-1*temp.currentValue;
        setSaveButton({disabled: true, text: "Saving..."});
        await saveData(theUpdatedJson, true);
        // fetchFeedlineData(props.privateToken)
        const iModelConnection = UiFramework.getIModelConnection();
        iModelConnection?.selectionSet.emptyAll();
        // iModelConnection?.selectionSet.add(id as Id64String);
        setSegmentAdded(false);
        props.onClose();

        // ModifyHandleDecoration.clear();
        break;
      }
      case "Cancel#2": {
        // let decorator = IModelApp.viewManager.decorators.filter((e) => e.constructor.name.includes("FeedlineDecorator"))[0] as FeedlineDecorator;
        // const newYpr = YawPitchRollAngles.createDegrees(decorator.untouchedFeedlineData.Azimuth, decorator.untouchedFeedlineData.Tilt, decorator.untouchedFeedlineData.Roll);
        // const theIndex=FeedlinesTable.feedlineData.findIndex((e)=>{return e.name === props.selectedObject.selectedObjectNST.name});
        // const theIndex=decorator.currJson.findIndex((e)=>{return e.Feedline_Name === props.selectedObject.selectedObjectNST.name});
        // if(props.selectedObjectInfo.objectProperties.selectedObjectNST.name == decorator.untouchedFeedlineData.Id){
        //   decorator.currJson[theIndex] = decorator.untouchedFeedlineData;
        //   if (props.selectedObject.selectedObjectNST.subType === FeedlineType.Antenna || props.selectedObject.selectedObjectNST.subType === FeedlineType.RRU) {
        //     // modDecorator._hasEditedBox = true;
        //     decorator.updateBoxRotation(props.selectedObject.selectedObjectNST.name, newYpr);
        //     const box = decorator.constructBoxGeometry(decorator.untouchedFeedlineData!, new Vector3d());
        //     const boxIndex = decorator.boxes.findIndex((entry) => entry.name === props.selectedObject.selectedObjectNST.name);
        //     decorator.updateBox(box!, boxIndex, false, false, decorator.untouchedFeedlineData);
        //   } else if (props.selectedObject.selectedObjectNST.subType === FeedlineType.Microwave) {
        //     // SampleToolWidget.isModifiedData = true;
        //     decorator.updateCylinderRotation(props.selectedObject.selectedObjectNST.name, newYpr);
        //     decorator.updateCylinder(props.selectedObject.selectedObjectNST.name, decorator.untouchedFeedlineData, new Vector3d());
        //   }
        const shapeIndex = decorator.shapes.findIndex(e=>e.modelData?.Id==decorator.untouchedFeedlineData!.Id);
        if(shapeIndex != -1)decorator.shapes.splice(shapeIndex, 1);
        const newShapes = decorator.shapes.filter(e=>!e.modelData?.Id.match(/start|middle|segment|end/));
        decorator.shapes = newShapes;
        setSegmentAdded(false);
        
        const storedFl = store.getState().detectedData.feedLinesData;
        // if(!decorator.untouchedFeedlineData!.Id.match(/CustomFeedline/)){
        // const feedId = props.selectedObjectInfo.objectProperties.objectCategoriesType == ObjectCategoryTypes.FeedLine_RM ? props.selectedObjectInfo.objectProperties.selectedObjectNST.name : decorator.selectedFeedline.modelId;
        const feedId = decorator.selectedFeedline.modelId;
        if(storedFl.findIndex(e=>e.clusterId == parseInt(feedId)) != -1){
            if(decorator.untouchedFeedlineData!.FeedlineType.type == FeedlineSectionType.Segmented){
              decorator.untouchedFeedlineSegments.forEach(e=>decorator.createGeometry(e));
            } else decorator.createGeometry(decorator.untouchedFeedlineData!, CreateGeometryMode.New);
            // SyncUiEventDispatcher.dispatchImmediateSyncUiEvent(SyncUiEventIds.Nothing_Selected);
            SyncUiEventDispatcher.dispatchImmediateSyncUiEvent(SyncUiEventIds.RM_Feedline_UnSelected);
        } else {
          const shapeIndex = decorator.shapes.findIndex(e=>e.modelData?.Id==feedId);
          if(shapeIndex != -1)decorator.shapes.splice(shapeIndex, 1);
        } 
        IModelApp.viewManager.invalidateDecorationsAllViews();
        IModelApp.viewManager.selectedView?.invalidateCachedDecorations(decorator);
        
        const iModelConnection = UiFramework.getIModelConnection();
        iModelConnection?.selectionSet.emptyAll();
        // iModelConnection?.selectionSet.add(id as Id64String);
        
        //   const temp: pecGridStructure = {...feedlineEditData};
        //   let tempVal: any = temp.rows[3].columns[1].value;
        //   tempVal.currentValue = decorator.untouchedFeedlineData.Azimuth;
        //   tempVal = temp.rows[4].columns[1].value;
        //   tempVal.currentValue = decorator.untouchedFeedlineData.Roll;
        //   tempVal = temp.rows[5].columns[1].value;
        //   tempVal.currentValue = decorator.untouchedFeedlineData.Tilt;
        //   setFeedlineEditStructure(temp);
        // }
        // props.onClose();
        // props.onClose(()=>{

        // });
        resetObjectIds(decorator);
        UiFramework.dialogs.modeless.close("Edit Dialog");
        store.dispatch(DTVActions.setEditModeFlag(false));
        break;
      }
    }
  }
  
  const onFieldUpdate = (event) => {
    let currentEvent= event;
  }
  
  const onFieldChange = async (event, inputId: string) => {
    const temp: pecGridStructure = {...feedlineEditData};
    const eventType = event!.constructor?.name;
    // return ;
    if((eventType == "SyntheticBaseEvent") || (typeof event === 'object' && event !== null && eventType != "Array")){
      const {id: theId, name: theName, value: theValue} = event.target;
      const [inputType, rowIndex, columnIndex] = theId.split("@")[0].split('-');

         if(event.target.name == "radio-buttons-group"){
           // let temp = value == RadioSection.Start ? RadioSection.Start : value == RadioSection.Whole ? RadioSection.Whole : RadioSection.End;
           setSelection(inputId == RadioSection.Start ? RadioSection.Start : inputId == RadioSection.Whole ? RadioSection.Whole : RadioSection.End)
           return;
         }
            
         switch (inputType) {
           case pecInputTagTypes.SELECT:
            //  temp.rows[rowIndex].columns[columnIndex].value = temp.rows[rowIndex].columns[columnIndex].data![theValue].value;
                temp.rows[rowIndex].columns[columnIndex].value = theValue;
                let newRadios = [];
                if(theValue != "None"){
                    newRadios = temp.rows[0].columns[0].data.otherRadios.map(e=>({label: e.label, disabled: true}));
                    temp.rows[4].columns[1].disabled = true;
                    temp.rows[4].columns[2].disabled = true;
                    temp.rows[5].columns[1].disabled = true;
                    temp.rows[5].columns[2].disabled = true;
                    temp.rows[7].columns[1].disabled = true;
                    temp.rows[8].columns[1].disabled = true;
                    setSelectedSegment(theValue)
                } else {
                    temp.rows[4].columns[1].disabled = false;
                    temp.rows[4].columns[2].disabled = false;
                    temp.rows[5].columns[1].disabled = false;
                    temp.rows[5].columns[2].disabled = false;
                    temp.rows[7].columns[1].disabled = false;
                    temp.rows[8].columns[1].disabled = false;
                    newRadios = temp.rows[0].columns[0].data.otherRadios.map(e=>({label: e.label, disabled: false}));
                    setSelectedSegment(null)
                }
                temp.rows[0].columns[0].data.otherRadios = newRadios;
             break;
           case pecInputTagTypes.SLIDER_INPUT:
             break;
           case pecInputTagTypes.CHECKBOX:
             temp.rows[rowIndex].columns[columnIndex].value = !temp.rows[rowIndex].columns[columnIndex].value;
             break;
         
           default:
             break;
         }
         
      
    } else {    
      const inputIdSplit = inputId.split("@");
      const [inputType, rowIndex, columnIndex] = inputIdSplit[0].split('-');
      switch (inputType) {
      case "sis":
        temp.rows[rowIndex].columns[columnIndex].value.currentValue = event[0];
        handleAngleChange(event[0]!, FeedlineRotationType[inputIdSplit[1]])
        break;
      case "sii":
        temp.rows[rowIndex].columns[columnIndex].value.currentValue = event;
        handleAngleChange(event!, FeedlineRotationType[inputIdSplit[1]])
      break;
      
      default:
        break;
      }

    }

    setFeedlineEditStructure(temp);
  }
  
  const handleAngleChange = async (value: number, rotationVal: FeedlineRotationType) => {
    const equipName = props.selectedObjectInfo.modelId;
    if (undefined === value) return;
    const fixedVal = Number(value.toFixed(2));
    if (rotationVal === 0) {
        // setYaw(fixedVal);
    } else if (rotationVal === 1) {
        // setPitch(fixedVal);
    } else {
        // setRoll(fixedVal);
    }
    let name = "";
    if (equipName.match(/Antenna/i)) {
        name = "Antenna";
    } else if (equipName.match(/Micro_Wave/i)) {
        name = "Microwave";
    } else if (equipName.match(/RRU/i)) {
        name = "RRU";
    }

    // setEditObjName(name);
    // if(currentObjType === "Feedline"){
        // if (equipName.match(/Micro_Wave/i)) { // cylinder
        //     await FeedlineRotationWidget.sliderChange(rotationVal == FeedlineRotationType.Tilt ? -1*value : value, rotationVal, ShapeType.Cylinder);
        // } else {
            
            await FeedlineRotationWidget.sliderChange(rotationVal == FeedlineRotationType.Tilt ? -1*value : value, rotationVal, ShapeType.Box);
        // }
    // } else {
    //     // await MountRotationWidget.sliderChange(value, rotationVal, ShapeType.Cylinder);
    // }
};


  
  const onFieldClick = (event, inputIds) => {
    let currentEvent= event;
    let inputId = inputIds
  }
  
  const onMouseUp = (event, inputIds) => {
    let currentEvent= event;
    let inputId = inputIds
    clearInterval(runTimer);
  }
  
  
  const onMouseDown = async (event) => {
    const temp: pecGridStructure = {...feedlineEditData};
    let decorator = IModelApp.viewManager.decorators.filter((e) => e.constructor.name.includes("FeedlineDecorator"))[0] as FeedlineDecorator;

    // const eventType = event!.constructor?.name;
    const {id: theId, name: theName, value: theValue} = event.target.tagName != "SPAN" ? event.target : event.target.parentElement;
    const idSplit = theId.split("@");
    if(idSplit[0].match(/sii/))return;
    // const [inputType, rowIndex, columnIndex] = idSplit[0].split('-');
    let movementVector: Vector3d = new Vector3d();
    let moveDirection: number = 0;
    let mode: editMode = editMode.positional;
    switch(idSplit[1]){
      case "moveLeft":
        movementVector = new Vector3d(move, 0, 0);        
      break;
      case "moveRight": 
        movementVector = new Vector3d(-move, 0, 0);        
      break;
      case "moveFront":
        movementVector = new Vector3d(0, move, 0);        
        break;
      case "moveBack": 
        movementVector = new Vector3d(0, -move, 0);        
      break;
      case "moveUp":
        movementVector = new Vector3d(0, 0, move);        
      break;
      case "moveDown": 
        movementVector = new Vector3d(0, 0, -move);        
      break;
      case "increaseThickness": 
        mode = editMode.depth;
        moveDirection = move;
        break;
      case "decreaseThickness": 
        mode = editMode.depth;
        moveDirection = -move;
      break;
      case "increaseWidth": 
        mode = editMode.width;
        moveDirection = move;
        break;
      case "decreaseWidth": 
        mode = editMode.width;
        moveDirection = -move;
      break;
      case "increaseLength": 
        mode = editMode.length;
        moveDirection = move;
        break;
      case "decreaseLength": 
        mode = editMode.length;
        moveDirection = -move;
      break;
      case "Pick": 
        mode = editMode.add_section;
      break;
    }

    const updateState = (completed: boolean) => {
        if(completed && decorator.segmentAdded){
            temp.rows[6].columns[1].data.push({label: temp.rows[6].columns[1].data.length, value: temp.rows[6].columns[1].data.length});
            temp.rows[6].columns[1].disabled = false;
            temp.rows[8].columns[1].disabled = true;
            setFeedlineEditStructure(temp);
            decorator.segmentAdded = false;
            setSegmentAdded(true);
            resetObjectIds(decorator);
        } else if(!decorator.segmentAdded){
            IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Info, "Not a valid location to add a segment.", "", OutputMessageType.Toast));            
        }
    }

    setDataUpdated(true);
    if(mode == editMode.positional){
      runTimer=setInterval(()=>{
        FeedlineRotationWidget.translate(movementVector.x, movementVector.y, movementVector.z, selection, selectedSegment)
        const start = decorator.shapes.find(s=>s.modelData?.Id.match(/start/))?.modelData;
        const end = decorator.shapes.find(s=>s.modelData?.Id.match(/end/))?.modelData;
        const allMiddles = decorator.shapes.filter(s=>s.modelData?.Id.match(/middle/));
  
        const allMiddleLens = allMiddles.map(e=>Math.round((e.modelData?.ActualPositions.base.distance(e.modelData.ActualPositions.top)!)*10)/10);
  
        const flLen = start?.ActualPositions.base.distance(end?.ActualPositions.top!);
        if(flLen == 0 || allMiddleLens.filter(e=>e==0).length){
            IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Info, "Can't move beyond the limit.", "", OutputMessageType.Toast));
            if(idSplit[1] == "moveUp")temp.rows[3].columns[1].disabled=true;
            else if(idSplit[1] == "moveDown")temp.rows[3].columns[2].disabled=true;
            setFeedlineEditStructure(temp);
            clearInterval(runTimer);
            resetObjectIds(decorator);
        } else {
            if(idSplit[1] == "moveUp")temp.rows[3].columns[1].disabled=false;
            else if(idSplit[1] == "moveDown")temp.rows[3].columns[2].disabled=false;
            setFeedlineEditStructure(temp);
        }  
      }, 50);
    } else if(mode == editMode.depth || mode == editMode.width || mode == editMode.length ){
      runTimer=setInterval(()=>FeedlineRotationWidget.updateBoxDimension(moveDirection, mode), 50);
    } else if(mode == editMode.add_section){
      void IModelApp.tools.run(PointSelectionTool.toolId, (ev: BeButtonEvent)=>{decorator.addSegment(decorator.untouchedFeedlineData!, ev)}, false, updateState);
    }
    setTimeout(() => {clearInterval(runTimer);resetObjectIds(decorator);}, 10000);
  }
  
  
  
  let modalBtns: pecTableButton[] = [
    {name: saveButton.text, value: saveButton.text, class: "dialogBox-save-btn", disable: saveButton.disabled, onInteract: onModalBtnClick},
    {name: "Cancel", value: "Cancel", class: "dialogBox-cancel-btn", disable: false, onInteract: onModalBtnClick},
  ];
  
  
  let feedlineEditorStructure: pecComponentProps = {
    structureType: pecStrucType.STRUCTURE,
    title: {
      label: "Feedline Edit",
      // styles: undefined
    },
    grid: feedlineEditData,
    gridButtons: modalBtns,
    eventHandlers: {
      onChange: onFieldChange,
      onUpdate: onFieldUpdate,
      onClick: onFieldClick,
      onMouseUp: onMouseUp,
      onMouseDown: onMouseDown,
    }
  }

  


  return (
    <PropertyEditorComponent {...feedlineEditorStructure} />
  )
}

const mapStateToProps = (state: RootState) => {
  const fd = IModelApp.viewManager.decorators.filter(e=>e.constructor.name.includes("FeedlineDecorator"))[0] as FeedlineDecorator;
  const {feedlineMenuStruc, feedlinePreviousState} = getFieldStructure(fd.selectedFeedline.modelId);
  const editSection = RadioSection.Whole;
  const retVal = {
    feedlineMenuStruc,
    editSection,
    feedlinePreviousState,
    selectedObjectInfo: fd.selectedFeedline,
    privateToken: state.auth.accessTokenStatePrivateAPI.accessToken
  };
  return retVal;
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(DetectedFeedlineEditHandler)


const getFieldStructure = (selEquipName: string) => {
  let decorator = IModelApp.viewManager.decorators.filter((e) => e.constructor.name.includes("FeedlineDecorator"))[0] as FeedlineDecorator;
  let segments = decorator.shapes.filter(e=>e.modelData?.Id.match(/segment/));
  let feedlinePreviousState = {...decorator.shapes.find((i) => i.modelData!.Id === selEquipName)?.modelData} as FeedlineData;
  if(segments.length)feedlinePreviousState = segments[0].modelData!;
  let {Azimuth = 0} = feedlinePreviousState || {};


  const editSection = RadioSection.Whole;
  const feedlineMenuStruc: pecGridStructure = {
    rows: [
        {
          columns: [
            {
                size: 12,
                type: pecInputTagTypes.RADIO_GROUP,
                customClass: "",
                value: "Section to edit",
                disabled: false,
                name: "partSelection-1-1",
                fieldType: pecDataValueTypes.INTEGER,
                modified: false,
                styles: {parentClass: "", childClass: ""},
                data: {matchValue: editSection, otherRadios: [
                  {label: RadioSection.Start},
                  {label: RadioSection.Whole},
                  {label: RadioSection.End},
                ]}
              },
            ],
        },
        {
          columns: [
            {
              size: 8,
              type: pecInputTagTypes.LABEL,
              customClass: "",
              value: "Move Left or Right",
              disabled: false,
              name: "mvLorR",
              fieldType: pecDataValueTypes.INTEGER,
              modified: false,
              styles: {parentClass: "mb-2", childClass: ""}
            },
            {
              size: 2,
              type: pecInputTagTypes.BUTTON,
              customClass: "",
              value: "L",
              disabled: false,
              name: "moveLeft",
              fieldType: pecDataValueTypes.INTEGER,
              modified: false,
              styles: {parentClass: "mb-2", childClass: "number-input-class"}
            },      
            {
              size: 2,
              type: pecInputTagTypes.BUTTON,
              customClass: "",
              value: "R",
              disabled: false,
              name: "moveRight",
              fieldType: pecDataValueTypes.INTEGER,
              modified: false,
              styles: {parentClass: "mb-2", childClass: "number-input-class"}
            },      
          ]
          
        },
        {
          columns: [
            {
              size: 8,
              type: pecInputTagTypes.LABEL,
              customClass: "",
              value: "Move Front or Back",
              disabled: false,
              name: "mvForB",
              fieldType: pecDataValueTypes.INTEGER,
              modified: false,
              styles: {parentClass: "mb-2", childClass: ""}
            },
            {
              size: 2,
              type: pecInputTagTypes.BUTTON,
              customClass: "",
              value: "F",
              disabled: false,
              name: "moveFront",
              fieldType: pecDataValueTypes.INTEGER,
              modified: false,
              styles: {parentClass: "mb-2", childClass: "number-input-class"}
            },      
            {
              size: 2,
              type: pecInputTagTypes.BUTTON,
              customClass: "",
              value: "B",
              disabled: false,
              name: "moveBack",
              fieldType: pecDataValueTypes.INTEGER,
              modified: false,
              styles: {parentClass: "mb-2", childClass: "number-input-class"}
            },      
          ]
          
        },
        {
          columns: [
            {
              size: 8,
              type: pecInputTagTypes.LABEL,
              customClass: "",
              value: "Move Up or Down",
              disabled: false,
              name: "mvUorD",
              fieldType: pecDataValueTypes.INTEGER,
              modified: false,
              styles: {parentClass: "mb-2", childClass: ""}
            },
            {
              size: 2,
              type: pecInputTagTypes.BUTTON,
              customClass: "",
              value: "U",
              disabled: false,
              name: "moveUp",
              fieldType: pecDataValueTypes.INTEGER,
              modified: false,
              styles: {parentClass: "mb-2", childClass: "number-input-class"}
            },      
            {
              size: 2,
              type: pecInputTagTypes.BUTTON,
              customClass: "",
              value: "D",
              disabled: false,
              name: "moveDown",
              fieldType: pecDataValueTypes.INTEGER,
              modified: false,
              styles: {parentClass: "mb-2", childClass: "number-input-class"}
            },      
          ]
        },
      ]
  }
    feedlineMenuStruc.rows.push(
      {
        columns: [
          {
            size: 8,
            type: pecInputTagTypes.LABEL,
            customClass: "",
            value: "Thickness",
            disabled: false,
            name: "mvLorR",
            fieldType: pecDataValueTypes.INTEGER,
            modified: false,
            styles: {parentClass: "mb-2", childClass: ""}
          },
          {
            size: 2,
            type: pecInputTagTypes.BUTTON,
            customClass: "",
            value: "+",
            disabled: false,
            name: "increaseThickness",
            fieldType: pecDataValueTypes.INTEGER,
            modified: false,
            styles: {parentClass: "mb-2", childClass: "number-input-class"}
          },      
          {
            size: 2,
            type: pecInputTagTypes.BUTTON,
            customClass: "",
            value: "-",
            disabled: false,
            name: "decreaseThickness",
            fieldType: pecDataValueTypes.INTEGER,
            modified: false,
            styles: {parentClass: "mb-2", childClass: "number-input-class"}
          },      
        ]
      },
      {
        columns: [
          {
            size: 8,
            type: pecInputTagTypes.LABEL,
            customClass: "",
            value: "Width",
            disabled: false,
            name: "mvLorR",
            fieldType: pecDataValueTypes.INTEGER,
            modified: false,
            styles: {parentClass: "mb-2", childClass: ""}
          },
          {
            size: 2,
            type: pecInputTagTypes.BUTTON,
            customClass: "",
            value: "+",
            disabled: false,
            name: "increaseWidth",
            fieldType: pecDataValueTypes.INTEGER,
            modified: false,
            styles: {parentClass: "mb-2", childClass: "number-input-class"}
          },      
          {
            size: 2,
            type: pecInputTagTypes.BUTTON,
            customClass: "",
            value: "-",
            disabled: false,
            name: "decreaseWidth",
            fieldType: pecDataValueTypes.INTEGER,
            modified: false,
            styles: {parentClass: "mb-2", childClass: "number-input-class"}
          },      
        ]
      },
    //   {
    //     columns: [
    //       {
    //         size: 8,
    //         type: pecInputTagTypes.LABEL,
    //         customClass: "",
    //         value: "Length",
    //         disabled: false,
    //         name: "mvLorR",
    //         fieldType: pecDataValueTypes.INTEGER,
    //         modified: false,
    //         styles: {parentClass: "mb-2", childClass: ""}
    //       },
    //       {
    //         size: 2,
    //         type: pecInputTagTypes.BUTTON,
    //         customClass: "",
    //         value: "+",
    //         disabled: false,
    //         name: "increaseLength",
    //         fieldType: pecDataValueTypes.INTEGER,
    //         modified: false,
    //         styles: {parentClass: "mb-2", childClass: "number-input-class"}
    //       },      
    //       {
    //         size: 2,
    //         type: pecInputTagTypes.BUTTON,
    //         customClass: "",
    //         value: "-",
    //         disabled: false,
    //         name: "decreaseLength",
    //         fieldType: pecDataValueTypes.INTEGER,
    //         modified: false,
    //         styles: {parentClass: "mb-2", childClass: "number-input-class"}
    //       },      
    //     ]
    //   },
    )
//   }

    let dataArray: pecOptionData[] = []
    if(segments.length){
        const segArray = Array.from(Array(segments.length).keys());
        dataArray = segArray.map(e=>({label: (++e).toString(), value: (e).toString()}));
    }
    dataArray.unshift({label: "None", value: "None"});
    // if(selEquipName.match(/Micro_Wave/gi)){
    feedlineMenuStruc.rows.push(
        {
            columns: [
                {
                size: 8,
                type: pecInputTagTypes.LABEL,
                customClass: "",
                value: "Select Segment",
                disabled: dataArray.length == 1,
                name: "selectSegment",
                fieldType: pecDataValueTypes.INTEGER,
                modified: false,
                styles: {parentClass: "mb-2", childClass: ""}
                },
                {
                size: 4,
                type: pecInputTagTypes.SELECT,
                customClass: "",
                value: "None",
                disabled: dataArray.length == 1,
                name: "selectSegment",
                fieldType: pecDataValueTypes.INTEGER,
                modified: false,
                styles: {parentClass: "mb-2", childClass: "number-input-class"},
                data: dataArray
                }  
            ]
        },
            
    );
    
    feedlineMenuStruc.rows.push(
      {
        columns: [
          {
            size: 3,
            type: pecInputTagTypes.LABEL,
            customClass: "",
            value: "Azimuth",
            disabled: false,
            name: "label-2",
            fieldType: pecDataValueTypes.INTEGER,
            modified: false,
            styles: {parentClass: "", childClass: ""}
          },
          {
            size: 9,
            type: pecInputTagTypes.SLIDER_INPUT,
            customClass: "",
            value: {currentValue: Azimuth, min: -180, max: 180, precision: 2, step: 0.5},
            disabled: false,
            name: "Azimuth",
            fieldType: pecDataValueTypes.INTEGER,
            modified: false,
            styles: {parentClass: "", childClass: JSON.stringify({"sliderClass":"slider-width","inputClass":"numericValue-width"})},
          },
        ]
      },

    )
    
    feedlineMenuStruc.rows.push(
        {
            columns: [
              {
                size: 8,
                type: pecInputTagTypes.LABEL,
                customClass: "",
                value: "Add a segment",
                disabled: false,
                name: "Pick a location",
                fieldType: pecDataValueTypes.INTEGER,
                modified: false,
                styles: {parentClass: "mb-2", childClass: ""}
              },
              {
                size: 4,
                type: pecInputTagTypes.BUTTON,
                customClass: "",
                value: "Pick location",
                disabled: false,
                name: "Pick",
                fieldType: pecDataValueTypes.INTEGER,
                modified: false,
                styles: {parentClass: "mb-2", childClass: ""}
              }
            ]
          },

    )
  return {feedlineMenuStruc, feedlinePreviousState: {...feedlinePreviousState}};
}