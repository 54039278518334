import { ToolbarItemUtilities } from "@itwin/appui-abstract";
import { IModelApp } from "@itwin/core-frontend";
import { HighlightFeedlineTool } from "../components/tools/HighlightFeedlineTool";
import { RootState } from "../../store/States";
import { store } from "../../store/rootReducer";
import { DTVActions } from "../../store/Actions";

function select(state: RootState, dataKey: string) {
    return state.detectedData[dataKey];
}
  
export const HighlightFeedline = () => {

    return ToolbarItemUtilities.createActionButton(
        "Highlight-Feedline", 
        110, 
        "icon-crop", 
        "Highlight Feedline",
        async () => executeHighlightFeedline()
    );






    // async () => {
    //     // TrackTelemetryApiClient.trackTelemetry("TOWER_ITWIN_HIGHLIGHT_EQUIPMENT");
    //     // if (!this._isInsiteSupported) {
    //     //   this.showNotSupportedNotification();
    //     //   return;
    //     // }
    //     // this.siteCoord;
    //     if (!siteCoordinates) {
    //         const siteCoord = await EquipmentClient.setSiteCoordinates(store.getState().auth.accessTokenStatePrivateAPI.accessToken /*accessTokenPrivate is a string now*/);     // Storing site coordinate, for if equipments are edited & require update relative to site <coordinates className="" />
    //         store.dispatch(setSiteCoordinate(siteCoord));
    //     }
    //     if (!SampleToolWidget.equipNamePositionMap || SampleToolWidget.equipNamePositionMap.size === 0) {
    //         IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Error,`No Equipment detected in this tower.`, "", OutputMessageType.Toast));
    //         return;
    //     }
    //     let towerStructure = select(store.getState(), "towerStructureData");
        
    //     if (!towerStructure) {
    //         towerStructure = await EquipmentClient.getTowerStructureJson(store.getState().auth.accessTokenStatePrivateAPI.accessToken /*accessTokenPrivate is a string now*/);   // Storing tower structure, for if equipments are edited they can be validated with the tower elevation values.
    //         store.dispatch(setTowerData(towerStructure));
    //     }

    //     const vp = IModelApp.viewManager.selectedView;
    //     if (vp) {
    //         const viewFlags = vp.viewFlags.copy({renderMode: RenderMode.SmoothShade});
    //         vp.viewFlags = viewFlags;
    //     }

    //     let toolRetVal: boolean = false;

    //     setTimeout(async () => {
    //         toolRetVal = await IModelApp.tools.run(OutlineEquipmentTool.toolId);
    //         // (currTools.filter(e=>e.id == "Select")[0] as ActionButton).execute();   //  Making the select tool enabled after equipment loading for abetter user experience. 
    //         if(toolRetVal){
    //             IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Info, "Equipment are successfully loaded.", "", OutputMessageType.Toast));
    //             // this.setState({operatorFilterData: {...this.state.operatorFilterData, selectedEquipment: ["All"]}});
    //         } else {
    //             // this.setState({operatorFilterData: {...this.state.operatorFilterData, selectedEquipment: []}});
    //         }
    //         // this.setState({highlightStates: {...this.state.highlightStates, equipment: toolRetVal}});
    //         // this._resetOperatorFilterWidget();
    //     }, 0);

    //     // tslint:disable-next-line:tslint-react-set-state-usage
    //     // if (this.state.isVisible) {
    //         //   this.setState({ isVisible: false });
    //         // }
    //         // if (!this.state.disableCloneEquipment) {
    //     //   this.setState({ disableCloneEquipment: true });
    //     // }
    //     // (currTools.filter(e=>e.id == "Select")[0] as ActionButton).execute();   //  Making the select tool enabled after equipment loading for abetter user experience. 
    //     // this.setState({ disableEditEquipment: !this.state.disableEditEquipment });
    // });
};
 
export const executeHighlightFeedline = async () => {

    // TrackTelemetryApiClient.trackTelemetry("TOWER_ITWIN_HIGHLIGHT_MOUNT");
    // if (!this._isInsiteSupported) {
    //   this.showNotSupportedNotification();
    //   return;
    // }
    // if (!siteCoordinates) {
    //     const siteCoord = await EquipmentClient.setSiteCoordinates(store.getState().auth.accessTokenStatePrivateAPI.accessToken /*accessTokenPrivate is a string now*/);     // Storing site coordinate, for if equipments are edited & require update relative to site <coordinates className="" />
    //     store.dispatch(setSiteCoordinate(siteCoord));
    // }

    // // let mountsData = select(store.getState(), "mountingsData");
    // let towerStructure = select(store.getState(), "towerStructureData");
    // let towerBracings = select(store.getState(), "towerBracingsData");
    
    // if (!towerStructure) {
    //     towerStructure = await EquipmentClient.getTowerStructureJson(store.getState().auth.accessTokenStatePrivateAPI.accessToken /*accessTokenPrivate is a string now*/);   // Storing tower structure, for if equipments are edited they can be validated with the tower elevation values.
    //     store.dispatch(setTowerData(towerStructure));
    // }

    // if (!towerBracings.length) {
    //     towerBracings = await BracingsClient.getBracingsData(store.getState().auth.accessTokenStatePrivateAPI.accessToken!);
    //     store.dispatch(setBracingsData(towerBracings));
    // }
    // if (!this._isInsiteSupported) {
    //     this.showNotSupportedNotification();
    //     return;
    // }
    // if (!SampleToolWidget.siteCoordinates) {
    //     SampleToolWidget.siteCoordinates = await EquipmentClient.setSiteCoordinates(App.accessToken.toTokenString());// Storing site coordinate, for if equipments are edited & require update relative to site <coordinates
    // }
    // const vp = IModelApp.viewManager.selectedView;
    // if (vp) {
    //     const viewFlags = vp.viewFlags.copy({renderMode: RenderMode.SmoothShade});
    //     vp.viewFlags = viewFlags;
    // }
    IModelApp.tools.run(HighlightFeedlineTool.toolId);
    const highlightStates = store.getState().dtvState.applicationState.highlightStates;

    store.dispatch(DTVActions.updateHighlightState({...highlightStates, feeders: !highlightStates.feeders}))

    // this.setState({highlightStates: {...this.state.highlightStates, grating: !this.state.highlightStates.grating}});

    // if (bracingsData && tdata) {
    //     if (tdata['nLegs'] >= 3) {//Only enable with sst+
    //         return true
    //     }
    //     else {
    //         return false
    //     }
    // }
    // else {
    //     return false;
    // }
        








}
store.subscribe(HighlightFeedline);