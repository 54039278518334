//------------------------------------------------------------------//
// Imports
//------------------------------------------------------------------//
// import { IModelApp, NotifyMessageDetails, OutputMessagePriority, OutputMessageType, PrimitiveTool, ScreenViewport, Viewport } from "@bentley/imodeljs-frontend";
// import { WidgetState } from "@bentley/ui-abstract";
// import { FrontstageManager, StagePanelLocation } from "@bentley/ui-framework";

import BracingsClient from "../../api/bracingsClient";
import { StagePanelLocation, UiFramework, WidgetState } from "@itwin/appui-react";
import { PrimitiveTool, Viewport, IModelApp, NotifyMessageDetails, OutputMessageType, OutputMessagePriority, ScreenViewport, EventHandled, BeButtonEvent } from "@itwin/core-frontend";
import { addToBuilt3DObjectIdsMap, setBracingsData, setBracingsData3DMaps } from "../../../store/detectedData/apiDataActionTypes";
import { store } from "../../../store/rootReducer";
import { BracingDecorator } from "../../tools/decorators/BracingDecorator";
import { Id64String } from "@itwin/core-bentley";
// import { ColorDef } from "@bentley/imodeljs-common";
//------------------------------------------------------------------//
// Class Highlight Bracings
//------------------------------------------------------------------//
export interface bracTrans {bracingId: number, transientId: Id64String };

export class HighlightBracingsTool extends PrimitiveTool {
    public static override toolId = "HighlightBracingsTool";
    public static override iconSpec = "icon-spatial-view-apply";
    public static data2send: any;
    private static newTestJasonData: any;
    private static fectchOTDNewBracings: any;
    //------------------------------------------------------------------//
    // Verify the viewport compatibility
    //------------------------------------------------------------------//
    public override isCompatibleViewport(_vp: Viewport | undefined, _isSelectedViewChange: boolean): boolean {
        return true;
    }
    //-------------------------------------------------------------------//
    // Help function to log information to the user
    //-------------------------------------------------------------------//
    private logToScreen(TYPE:any, TITLE:string, CONTENT:string=""){
        IModelApp.notifications.outputMessage(new NotifyMessageDetails(TYPE, TITLE, CONTENT, OutputMessageType.Toast));
    }
    //-------------------------------------------------------------------//
    // Get the data from the API's
    //-------------------------------------------------------------------//
    private async fetchData() {
        const storedBracingsData = store.getState().detectedData.towerBracingsData;
        const storedTowerStructure = store.getState().detectedData.towerStructureData;
        const bracingsData = storedBracingsData.length ? storedBracingsData : await BracingsClient.getBracingsData(store.getState().auth.accessTokenStatePrivateAPI.accessToken!);
        const towerStructure = Object.keys(storedTowerStructure).length ? storedTowerStructure : await BracingsClient.getTowerStructureJson(store.getState().auth.accessTokenStatePrivateAPI.accessToken!);
        if (bracingsData && towerStructure) {
            HighlightBracingsTool.data2send = {
                "tower-structure": towerStructure,
                "bracings": bracingsData
            };
        }
        else {
           if(!store.getState().dtvState.applicationState.highlightStates.highlightAll) this.logToScreen(OutputMessagePriority.Error, "Could not fetch the bracings data.")
        }
    }
    //-------------------------------------------------------------------//
    // create the pipes to make the bracings
    //-------------------------------------------------------------------//
    private async startTool() {   
        // fetch the data
        await this.fetchData();
        // search and get the decorator from the view manager
        let decorateCreated = true;
        let pipeDecor = IModelApp.viewManager.decorators.filter(e => e.constructor.name.includes("BracingDecorator"))[0] as BracingDecorator;

        if(pipeDecor === undefined){
            const vp = IModelApp.viewManager.selectedView;
            if (undefined !== vp) {    
                // This approach doesn't work well with camera turned on
                // vp.zoom(undefined, 0.3, { animateFrustumChange: true });
            }    
            // Create the decorator 
            decorateCreated = false;
            pipeDecor = new BracingDecorator();
            // add decorator to the view manager
            IModelApp.viewManager.addDecorator(pipeDecor);
        }
        //---------------------------------------------------------------------------------------------------
        /** 20:May:2023
         * New OTD Bracing Data Visualisation  Process
         **/
        const faceTransientIds: bracTrans[] = [];
        const planTransientIds: bracTrans[] = [];
        if(!BracingDecorator.faceOTDData.bracingData || !Object.keys(BracingDecorator.faceOTDData.otdData).length){
            let bracFaceInfoDat = BracingDecorator.getBracingFaceDataForOTD(HighlightBracingsTool.data2send["bracings"], HighlightBracingsTool.data2send["tower-structure"]);
            let faceModel = BracingDecorator.modelFromBracingFaceData(bracFaceInfoDat);
            let out = await BracingsClient.postBracingDetectorGeneratorData(store.getState().auth.accessTokenStatePrivateAPI.accessToken!, faceModel);
            BracingDecorator.faceOTDData.otdData = out.data;
            BracingDecorator.faceOTDData.bracingData = bracFaceInfoDat;
        }

        if(!BracingDecorator.faceOTDData.bracingData.length || !Object.keys(BracingDecorator.planOTDData.otdData).length){
            const {planBracings} = BracingDecorator.getBracingCoordinatesFromPattern(HighlightBracingsTool.data2send["bracings"], HighlightBracingsTool.data2send["tower-structure"]);
            if(planBracings.length){
                const out2 = await BracingsClient.postBracingDetectorGeneratorData(store.getState().auth.accessTokenStatePrivateAPI.accessToken!, BracingDecorator.planOTDData.bracingData);
                BracingDecorator.planOTDData.otdData = out2.data;
                BracingDecorator.planOTDData.bracingData = planBracings;

            }
        }
        // const theBracingsData = {Nodes: [out.Nodes, out2.Nodes], Members: [...out.Members, out2.Members]}
        //---------------------------------------------------------------------------------------------------
        HighlightBracingsTool.data2send["bracings"].forEach(e=>faceTransientIds.push({bracingId:e.bracingId, transientId: IModelApp.viewManager.selectedView!.iModel.transientIds.getNext()}));
        if (Object.keys(BracingDecorator.faceOTDData.otdData).length) {
            BracingDecorator.planOTDData.bracingData.forEach(e=>planTransientIds.push({bracingId:e.BracingId, transientId: IModelApp.viewManager.selectedView!.iModel.transientIds.getNext()}));
            pipeDecor.createPipesWithOTDData(BracingDecorator.faceOTDData.otdData, HighlightBracingsTool.data2send, BracingDecorator.faceOTDData.bracingData, faceTransientIds);
            if(Object.keys(BracingDecorator.planOTDData.otdData).length) pipeDecor.createPipesWithOTDData(BracingDecorator.planOTDData.otdData, HighlightBracingsTool.data2send, BracingDecorator.planOTDData.bracingData, planTransientIds);
            // create the pipes
            // const isDone: any = pipeDecor.createPipes(HighlightBracingsTool.data2send);

            // isDone.then(()=>{
                store.dispatch(addToBuilt3DObjectIdsMap(new Map(pipeDecor.objectIdMap)));
                store.dispatch(setBracingsData3DMaps(new Map(pipeDecor.nameIdMap)));
            // })
            ////
            // if (isDone === true &&  !store.getState().dtvState.applicationState.highlightStates.highlightAll) {
            //     this.logToScreen(OutputMessagePriority.Info, "Bracings are successfully loaded.")

            if (decorateCreated) {
                    // reload the pipes
                    const allPipes = pipeDecor.pipes;
                    pipeDecor.terminate();
                    pipeDecor.pipes = allPipes;
                    IModelApp.viewManager.invalidateDecorationsAllViews();
                    IModelApp.viewManager.selectedView?.invalidateCachedDecorations(pipeDecor);
                }
            // }
            // else if (isDone === false &&  !store.getState().dtvState.applicationState.highlightStates.highlightAll) {
            //     this.logToScreen(OutputMessagePriority.Error, "Error loading bracings.")
            // }
        }
        else {
           if( !store.getState().dtvState.applicationState.highlightStates.highlightAll) this.logToScreen(OutputMessagePriority.Error, "Error loading OTD bracings.")
        }
    }
    //------------------------------------------------------------------//
    // Highlight the bracings... run the Bracings Decorator
    // Check some cases to return some messagens like, data not found!!
    // input: toolData  {"tower-structure": towerStructureData, "bracings": bracingsData}
    // input: isLoaded  Boolean
    //------------------------------------------------------------------//
    public override run(isLoaded: boolean): Promise<boolean> {
        super.run();
        const { toolAdmin, viewManager } = IModelApp;
        // viewport not compatible 
        if (!this.isCompatibleViewport(viewManager.selectedView, false) || !toolAdmin.onInstallTool(this)) {
            return Promise.resolve(false);
        }
        //------------------------------------------------------------------//
        // Start a primitive tool and retrieves the viewport
        toolAdmin.startPrimitiveTool(this);
        const vp = IModelApp.viewManager.selectedView as ScreenViewport;
        // SampleToolWidget.setModelIndexValues(vp);
        // check if we load the tool
        if (isLoaded == true) {
            // create the bracings
            this.startTool();
        } 
        else {
            // terminate the decorator and remove it from the view manager
            for (const decor of IModelApp.viewManager.decorators) {
                const n = decor.constructor.name;
                if (n.includes("BracingDecorator")) {
                    UiFramework.frontstages.activeFrontstageDef?.findWidgetDef("BracingEditWidget")?.setWidgetState(WidgetState.Hidden);
                    (decor as BracingDecorator).terminate();
                    IModelApp.viewManager.dropDecorator(decor);
                }
            }
            // close the tool [same as Heighlight mount]
            const isBlankConnection: boolean | undefined = UiFramework.getIModelConnection()?.isBlank;
            if (isBlankConnection) {
                UiFramework.frontstages.activeFrontstageDef?.getStagePanelDef(StagePanelLocation.Right)?.
                findWidgetDef("PropertyListWidget")?.setWidgetState(WidgetState.Hidden);
            } 
            else if ((IModelApp as any).listCallback) {
                // (IModelApp as any).listCallback(SampleToolWidget.currentList);
            }
            this.exitTool();
        }
        IModelApp.viewManager.selectedView?.invalidateDecorations();
        this.exitTool();
        return Promise.resolve(true);
    }
    //------------------------------------------------------------------//
    // onRestartTool function needed by the class...
    //------------------------------------------------------------------//
    public override async onResetButtonUp(_ev: BeButtonEvent): Promise<EventHandled> {
        this.exitTool();

        return EventHandled.No;
    }

    public onRestartTool(): Promise<void> {return Promise.resolve()}
}
