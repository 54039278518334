import { UiFramework } from "@itwin/appui-react";
import { AccessToken } from "@itwin/core-bentley";
import { Logger } from "../api/logging";
import { AppSettings } from "../../config/AppSettings";
import { ConfigManager } from "../../config/ConfigManager";
import DatabaseApiClient from "../../services/graphql/database-api/database-api-client";
import { DTVActions } from "../../store/Actions";
import { unitSystemType } from "../../store/redux-types";
import { store } from "../../store/rootReducer";

let projectId: string, openToweriQUrl: string, privateAccessToken: AccessToken, towerId: string, checkForLicense: boolean, isInsiteSupported: boolean = false;
// const listener = () => {
//     // setProjectIdUrl(store.getState());
// }
// const select = (state: RootState, dataKey: string) => {
//     return state.dtvState.featureControls[dataKey];
// }

// store.subscribe(listener);

export const setUnitSystem = async (projectId: string) => {
    try {
      
      UiFramework.setColorTheme(AppSettings.defaultDisplaySettings.theme);
      let projectAttributes: { unitSystemId: number; } = {unitSystemId: 0};
      let id = projectId || ConfigManager.projectId;
      if (id) {
        projectAttributes = await DatabaseApiClient.getProjectAttributes(id!);
      }
      let unitSystem = AppSettings.defaultDisplaySettings.unitSystem;
      if (projectAttributes)unitSystem = projectAttributes.unitSystemId == unitSystemType.IMPERIAL ? unitSystemType.IMPERIAL : unitSystemType.METRIC;


      //Override with the session unit system fetched from query string if passed
      if(ConfigManager.sessionUnitSystem)unitSystem = ConfigManager.sessionUnitSystem == unitSystemType.IMPERIAL ? unitSystemType.IMPERIAL : ConfigManager.sessionUnitSystem == unitSystemType.METRIC ? unitSystemType.METRIC : unitSystem;
      AppSettings.defaultDisplaySettings.unitSystem = unitSystem;
      AppSettings.unitSystem = unitSystem;
      store.dispatch(DTVActions.setUnistSystem(projectAttributes.unitSystemId == unitSystemType.IMPERIAL ? unitSystemType.IMPERIAL : unitSystemType.METRIC))
      // await NineZoneSampleApp.setUnitSystem(unitSystem);
    } catch (e) {
      Logger.error("Unable to set Unit", e);
      // throw new CustomError("Unable to set Unit!");
    }
  }
