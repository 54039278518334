import { Box, Geometry, Point3d, Range1d, Transform, Vector3d } from "@itwin/core-geometry";
import { ColorDef, LinePixels } from "@itwin/core-common";
import { AccuDrawHintBuilder, BeButtonEvent, DecorateContext, EditManipulator, GraphicType, IModelApp, ViewClipTool, Viewport } from "@itwin/core-frontend";
import { ControlArrow, ModifyHandleDecoration } from "./ModifyHandleDecoration";
import { store } from "../../../../store/rootReducer";
import { CreateGeometryMode, CustomGeometryQuery, EquipmentData, GeometryEntry, ShapeDecorator } from "../../../tools/decorators/ShapeDecorator";
import { resetObjectIds } from "../../HorizontalToolbarItems";

export class ModifyTool extends EditManipulator.HandleTool {
    protected _anchorIndex: number;
    protected _ids: string[];
    protected _controls: ControlArrow[];
    protected _view: Viewport;
    protected _shape: Box;
    protected _restoreClip = true;
    protected _currentDistance: number = 0.0;
    protected _manipulator: ModifyHandleDecoration;
    protected _shapeJson: CustomGeometryQuery;
    protected _originalAnchorOrigin: Point3d;
    protected _startedMoving = false;
    protected _anchorFaceIndices = [];
    protected _boxIndex: number = 0;
    private _keyin = "modify bbox size";
    public override get keyin() {
        return this._keyin;
    }
    public override set keyin(value) {
        this._keyin = value;
    }
    private _description = "Resize Bounding Boxes using edit arrows and mouse control";
    public override get description() {
        return this._description;
    }
    public override set description(value) {
        this._description = value;
    }
    private _toolId = "ModifyTool";
    private _flyover = "Resize Bounding Box";
    public override get flyover() {
        return this._flyover;
    }
    public override set flyover(value) {
        this._flyover = value;
    }
    public override get toolId() {
        return this._toolId;
    }
    public override set toolId(value) {
        this._toolId = value;
    }
    public prevOffset: number | undefined;

    public constructor(manipulator: ModifyHandleDecoration, shape: Box, boxIndex: number, shapeJson: CustomGeometryQuery, vp: Viewport, hitId: string, ids: string[], controls: ControlArrow[]) {
        super(manipulator);
        this._manipulator = manipulator;
        this._anchorIndex = ids.indexOf(hitId);
        this._ids = ids;
        this._controls = controls;
        this._view = vp;
        this._shape = shape;
        this._shapeJson = shapeJson;
        this._originalAnchorOrigin = this._controls[this._anchorIndex].origin;
        this._boxIndex = boxIndex;
    }

    protected override init(): void {
        super.init();
        AccuDrawHintBuilder.deactivate();
    }

    protected accept(ev: BeButtonEvent): boolean {
        if (!this.updateBoundingBox(ev, true))
            return false;
        this._restoreClip = false;
        return true;
    }

    // public override onSuspend() {
    //     // tool is suspending
    //     // tslint:disable-next-line:no-console
    // }

    public override async onMouseMotion(_ev: BeButtonEvent): Promise<void> {
        if (!this.updateBoundingBox(_ev, false))
            return;
        this._view.invalidateDecorations();
    }

    protected getOffsetValue(ev: BeButtonEvent, _transformFromClip?: Transform): number {
        if (-1 === this._anchorIndex || undefined === ev.viewport || ev.viewport !== this._view)
            return 0;

        // NOTE: Use AccuDraw z instead of view z if AccuDraw is explicitly enabled...
        const anchorRay = ViewClipTool.getClipRayTransformed(this._originalAnchorOrigin, this._controls[this._anchorIndex].direction, Transform.createIdentity());
        // const projectedPt = EditManipulator.HandleUtils.projectPointToLineInView(ev.point, anchorRay.origin, anchorRay.direction, ev.viewport, true);
        const projectedPt = AccuDrawHintBuilder.projectPointToLineInView(ev.point, anchorRay.origin, anchorRay.direction, ev.viewport, true);
        if (undefined === projectedPt)
            return 0;

        const offsetVec = Vector3d.createStartEnd(anchorRay.origin, projectedPt);
        let offset = offsetVec.normalizeWithLength(offsetVec).mag;
        if (offset < Geometry.smallMetricDistance)
            return 0;
        if (offsetVec.dotProduct(anchorRay.direction) < 0.0)
            offset *= -1.0;

        this._currentDistance = offset;
        return offset;
    }

    protected updateBoundingBox(ev: BeButtonEvent, _isAccept: boolean): boolean {
        if ((undefined === this._shape || undefined === this._manipulator._shapeDecorator))
            return false;
        // get arrow direction
        const arrowDirection = this._controls[this._anchorIndex].direction;
        const shapeDecorator = this._manipulator._shapeDecorator;
        const equipmentJson = JSON.parse(JSON.stringify(this._shapeJson));
        // const equipmentJson = this._shapeJson;
        // get offset value from mouse click event
        const offsetDistInArrowDir = this.getOffsetValue(ev);
        // do not allow user to go beyond opposit side arrow
        if (offsetDistInArrowDir === 0) {
            return false;
        }
        // Compute the factors that have changed
        const offsetHalf = offsetDistInArrowDir * 0.5;
        // calculate new Arrow Position
        const newArrowPosition = this._originalAnchorOrigin.plusScaled(arrowDirection, offsetDistInArrowDir);
        const currCentroid = new Point3d(equipmentJson.modelData!.x_position, equipmentJson.modelData!.y_position, equipmentJson.modelData!.z_position);
        const newCentroid: Point3d = currCentroid.plusScaled(arrowDirection, offsetHalf);
        // calculate height width thickness
        let width = equipmentJson.modelData!.Width, thickness = equipmentJson.modelData!.Thickness, height = equipmentJson.modelData!.Height;

        if (this._anchorIndex === 0 || this._anchorIndex === 1)
            width += offsetDistInArrowDir;
        else if (this._anchorIndex === 2 || this._anchorIndex === 3)
            thickness += offsetDistInArrowDir;
        else
            height += offsetDistInArrowDir;

        if (width <= 0 || height <= 0 || thickness <= 0) {
            return false;
        }
        equipmentJson.modelData!.Height = height;
        equipmentJson.modelData!.Width = width;
        equipmentJson.modelData!.x_position = newCentroid.x;
        equipmentJson.modelData!.y_position = newCentroid.y;
        equipmentJson.modelData!.z_position = newCentroid.z;
        equipmentJson.modelData!.Thickness = thickness;
        equipmentJson.modelData!.Manufacturer = "UPT:NewEquip";
        equipmentJson.modelData!.Model = "UPT:NewEquip-001";
        // create new box
        let shapeIndex = shapeDecorator.shapes.findIndex((shape) => shape.modelData?.Equipment_Name === equipmentJson.modelData!.Equipment_Name);

        // const equipmentJson = this.shapes[shapeIndex];
        let shapeDN = equipmentJson.modelData?.DisplayName;
        // if (shapeIndex !== -1){
        //   if(shapeIndex >= 0){
        //     shapeDecorator.objectIdMap.get(shapeDecorator.shapes[shapeIndex].transientId);
        //     shapeDecorator.objectIdMap.delete(shapeDecorator.shapes[shapeIndex].transientId);
        //     shapeDecorator.nameIdMap.delete(shapeDN);
        //     shapeDecorator.shapes.splice(shapeIndex, 1);
        //   }
        // }
    
        // if(shapeDecorator.showFrontFace){
        // // if (shapeIndex !== -1){
        //     shapeDecorator.shapes.splice(shapeIndex, 1);
        //     const dn = equipmentJson.modelData!.DisplayName;
        //     const dnSplit = dn.split('_');
        //     let faceName = `${dnSplit[1]}_face`;
        //     switch (dnSplit[0]) {
        //       case "Antenna":
        //         faceName = `ANT_${faceName}`;
        //         break;
        //       case "RRU":
        //         faceName = `RRU_${faceName}`;
        //         break;
        //       case "Micro_Wave":
        //         faceName = `MW_${faceName}`;
        //         break;
            
        //       default:
        //         break;
        //     }
    
        //     shapeIndex = shapeDecorator.shapes.findIndex((shape) => shape.modelData?.DisplayName === faceName);
        //     shapeDecorator.shapes.splice(shapeIndex, 1);
        //     if(shapeIndex != -1){
        //         shapeDecorator.shapes.splice(shapeIndex, 1);
        //         const theKey =shapeDecorator.objectIdMap.get(shapeDecorator.shapes[shapeIndex].transientId);
        //         shapeDecorator.objectIdMap.delete(shapeDecorator.shapes[shapeIndex].transientId);
        //         shapeDecorator.nameIdMap.delete(faceName);
        //         shapeDecorator.shapes.splice(shapeIndex, 1);
        //     }
        // }
        if(shapeDN)shapeDecorator.deleteCurrentShape(shapeDN);
  
        shapeDecorator.createGeometry(equipmentJson.modelData!, CreateGeometryMode.New);
        if(!ShapeDecorator.selectedEquipment.creating)ShapeDecorator.selectedEquipment.isModified = true;
        this._controls[this._anchorIndex].origin = newArrowPosition;
        this._manipulator._hasEditedBox = true;
        IModelApp.viewManager.selectedView?.invalidateDecorations();
        IModelApp.viewManager.selectedView?.invalidateCachedDecorations(shapeDecorator);
        shapeIndex = shapeDecorator.shapes.findIndex((shape) => shape.modelData?.Equipment_Name === equipmentJson.modelData!.Equipment_Name);
        this._shape = shapeDecorator.shapes[shapeIndex].modelData?.geometryEntry?.geometry as Box;
        
        resetObjectIds(shapeDecorator);
        //Updating the face with the updated equipment dimension
        // if(App.iTwinFrontFaceDisplay){
        if(store.getState().dtvState.featureControls.iTwinDisplayFrontFace){
        }
        return true;
    }

    private static findIndicesOfMin(list: string | any[], count: number) {
        const indices: number[] = [];
        for (let i = 0; i < list.length; i++) {
            indices.push(i);
            if (indices.length > count) {
                indices.sort((a, b) => (list[a] - list[b]) );
                indices.pop();
            }
        }
        return indices;
    }

    private static includesAlmostEqual(list: Point3d[], pt: Point3d): boolean {
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < list.length; i++) {
            if (list[i].isAlmostEqual(pt)) {
                return true;
            }
        }
        return false;
    }

    private static indexOfAlmostEqual(list: Point3d[], pt: Point3d): number {
        for (let i = 0; i < list.length; i++) {
            if (list[i].isAlmostEqual(pt)) {
                return i;
            }
        }
        return -1;
    }

    protected drawViewClip(context: DecorateContext): void {
        this._shape = this._shape as Box;
        const shapeRange = this._shape.range();
        const clipExtents = Range1d.createXX(shapeRange.low.z, shapeRange.high.z);
        const color = EditManipulator.HandleUtils.adjustForBackgroundColor(ColorDef.white, context.viewport);

        // ViewClipTool.drawClipShape(context, clipShape, clipExtents, color, 1);

        this.drawAnchorOffset(context, color, 3);
    }

    protected drawAnchorOffset(context: DecorateContext, color: ColorDef, weight: number, transformFromClip?: Transform): void {
        if (-1 === this._anchorIndex || Math.abs(this._currentDistance) < Geometry.smallMetricDistance)
            return;
        const anchorRay = ViewClipTool.getClipRayTransformed(this._originalAnchorOrigin, this._controls[this._anchorIndex].direction, transformFromClip);
        anchorRay.direction.scaleToLength(this._currentDistance, anchorRay.direction);
        const pt1 = anchorRay.fractionToPoint(0.0);
        const pt2 = anchorRay.fractionToPoint(1.0);
        const builder = context.createGraphicBuilder(GraphicType.ViewOverlay);

        context.viewport.worldToView(pt1, pt1); pt1.z = 0.0;
        context.viewport.worldToView(pt2, pt2); pt2.z = 0.0;

        builder.setSymbology(color, ColorDef.black, weight, LinePixels.Code5);
        builder.addLineString([pt1, pt2]);
        builder.setSymbology(color, ColorDef.black, weight + 7);
        builder.addPointString([pt1, pt2]);

        context.addDecorationFromBuilder(builder);
    }

    public override decorate(context: DecorateContext): void {
        if (-1 === this._anchorIndex || context.viewport !== this._view) {
            return;
        }
        this.drawViewClip(context);
    }

}
