import { ColorDef } from "@itwin/core-common";
import { BeButtonEvent, EventHandled, IModelApp, NotifyMessageDetails, OutputMessagePriority, OutputMessageType, PrimitiveTool, ScreenViewport, ViewClipTool, Viewport } from "@itwin/core-frontend";
// import App from "../App";
import { ClipMaskXYZRangePlanes, ClipShape, ClipVector, Point3d, Range3d } from "@itwin/core-geometry";
import mountClient from "../../api/mountClient";
import EquipmentClient from "../../api/equipment";
import { UiFramework } from "@itwin/appui-react";
import { store } from "../../../store/rootReducer";
import { DecoratorHelper } from "../../tools/decorators/DecoratorHelper";
import { MountDecorator } from "../../tools/decorators/MountDecorator";

export class AddMountPipeTool extends PrimitiveTool {
    public static override toolId = "addMountCylinder";
    public static override iconSpec = "icon-edit-all";
    startPt: Point3d | undefined;
    endPt: Point3d | undefined;
    selection: string = "";
    decorator: any;
    color: ColorDef = ColorDef.blue;
    
    
    public static exitEquipDecorator: any | undefined;

    public onRestartTool(): Promise<void> {
        const tool = new AddMountPipeTool();
        if (!tool.run(this.selection))
            this.exitTool();
        return Promise.resolve();
    }

    public override isCompatibleViewport(_vp: Viewport | undefined, _isSelectedViewChange: boolean): boolean {
        return true;
    }

    public override run(selection: string, record: any = null): Promise<boolean> {
        super.run();
        const { toolAdmin, viewManager } = IModelApp;
        this.decorator=IModelApp.viewManager.decorators.filter(e=>e.constructor.name.includes("MountDecorator"))[0];
        this.selection = selection;
        if(!selection.length){
            IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Warning,`Select the type of supporting member to add.`, "", OutputMessageType.Toast)); 
            this.exitTool();
            return Promise.resolve(false);
        }


        const vp = IModelApp.viewManager.selectedView as ScreenViewport;
        const range = vp.view.computeFitRange();
        const bias = vp.backgroundMapSettings.groundBias;
        const adjustZ = store.getState().detectedData.siteCoordinate.utm.z - bias;

        let theFace;
        if(record.type.match(/Circular/i)){
            theFace = record.parentMount.FACEs[Object.keys(record.parentMount.FACEs)[0]];
        } else{
            theFace = record.parentMount.FACEs[record.faceName];
        }
        const width = theFace.width;
        const height = record.height || 1;
        const tempRange: Range3d = new Range3d(
                range.low.x.toString().split('.').length > 1 ? range.low.x : -(1.5*width)/2,
                range.low.y.toString().split('.').length > 1 ? range.low.y : -(1.5*width)/2,
                record.utm_altitude-adjustZ-1.5*height,
                range.high.x.toString().split('.').length > 1 ? range.high.x : (1.5*width)/2,
                range.high.y.toString().split('.').length > 1 ? range.high.y : (1.5*width)/2,
                record.utm_altitude-adjustZ+1.5*height
                );
        
        // Create a block for the ClipVector.
        const block: ClipShape = ClipShape.createBlock(tempRange, tempRange.isAlmostZeroZ ? ClipMaskXYZRangePlanes.XAndY : ClipMaskXYZRangePlanes.All, false, false);
        // Create the ClipVector
        const clip: ClipVector = ClipVector.createEmpty();
        // Add the block to the Clipvector and set it in the ScreenViewport.
        clip.appendReference(block);
        // Call enableClipVolume to ensure all clip flags are properly set
        // ViewClipTool.enableClipVolume(vp);
        // vp.view.setViewClip(clip);
        // ViewClipApi.addDecorators(vp);
    
        switch(selection){
            case "Tie-back": {
                this.color = ColorDef.white
                break;
            }
            case "Perp. to Wind": {
                this.color = ColorDef.black
                break;
            }
            case "Para. to Wind": {
                this.color = ColorDef.red
                break;
            }
        }

        if(!this.decorator){
            this.decorator = new MountDecorator();
            IModelApp.viewManager.addDecorator(this.decorator);
        }

        if (!this.isCompatibleViewport(viewManager.selectedView, false) || !toolAdmin.onInstallTool(this)) {
            return Promise.resolve(false);
        }
        
        toolAdmin.startPrimitiveTool(this);
        toolAdmin.onPostInstallTool(this);
        return Promise.resolve(true);
    }

    public override async onMouseMotion(_ev: BeButtonEvent): Promise<void> {
        this.endPt=_ev.point;
        if(this.startPt && this.endPt && this.decorator){
            const cA = this.startPt;
            const cB = this.endPt;
            for(let i=0; i<this.decorator.pipes.length; i++){
                if(this.decorator.pipes[i].name === "temp"){
                    this.decorator.pipes.splice(i, 1);
                }
            }
            // this.decorator.addPipe(cA, cB, 0.06, ColorDef.fromString("rgb(240,204,0)"), "temp", {mountFace:"tempMountPipe", parentMountType:"tempParentType", parentMount: "tempParentName"});
            this.decorator.addPipe(cA, cB, 0.06, this.color, "temp", {mountFace:"tempMountPipe", parentMountType:"tempParentType", parentMount: "tempParentName"});
            const allPipes = this.decorator.pipes;
            this.decorator.terminate();
            this.decorator.pipes = allPipes;
            IModelApp.viewManager.invalidateDecorationsAllViews();
            IModelApp.viewManager.selectedView?.invalidateCachedDecorations(this.decorator);
        }
    }

    public override async onMouseStartDrag(_ev: BeButtonEvent): Promise<EventHandled> {
        this.startPt=_ev.point;
        return EventHandled.No;
    }

    public override async onMouseEndDrag(_ev: BeButtonEvent): Promise<EventHandled> {
        this.endPt=_ev.point;
        for(let i=0; i<this.decorator.pipes.length; i++){
            if(this.decorator.pipes[i].name === "temp"){
                this.decorator.pipes.splice(i, 1);
            }
        }
        let length=0;
        if(this.startPt && this.endPt){
            const cA = this.startPt;
            const cB = this.endPt;
            length=cA.distance(cB);
            // this.decorator.addPipe(cA, cB, 0.06, ColorDef.fromString("rgb(240,204,0)"), "tempMount_"+this.decorator.pipes.length, {mountFace:"tempMountPipe", parentMountType:"tempParentType", parentMount: "tempParentName"});
            this.decorator.addPipe(cA, cB, 0.06, this.color, "tempMount_"+this.decorator.pipes.length, {mountFace:"tempMountPipe", parentMountType:"tempParentType", parentMount: "tempParentName"});
            const allPipes = this.decorator.pipes;
            this.decorator.terminate();
            this.decorator.pipes = allPipes;
            IModelApp.viewManager.invalidateDecorationsAllViews();
            IModelApp.viewManager.selectedView?.invalidateCachedDecorations(this.decorator);
            
            const token = store.getState().auth.accessTokenStatePrivateAPI.accessToken!;
            const selectedMount = this.decorator.selectedMount;

            const iModel = UiFramework.getIModelConnection()!;
            const stPtCart = iModel!.spatialToCartographicFromEcef(this.startPt);
            const stPtUtm = DecoratorHelper.convertWGS2UTM(stPtCart);
            const endPtCart = iModel!.spatialToCartographicFromEcef(this.endPt);
            const endPtUtm = DecoratorHelper.convertWGS2UTM(endPtCart);
      
            const data = {
              "mountId": selectedMount.modelData.parentMount,
              "faceId": selectedMount.modelData.mountFace,
              "mountPipeType": "horizont_pipe",
              "mountType": selectedMount.modelData.parentMountType,
              //"mountPipeId": `H-${selectedMount.modelData.mountFace}`,
              "mountPipeId": `Temp-pipe-${this.decorator.pipes.length}`,
              "utm": [
                  [stPtUtm[0], stPtUtm[1], stPtCart.height],
                  [endPtUtm[0], endPtUtm[1], endPtCart.height]
              ]
            }
            /** 
             * Task 1385568: Disable the Mount Edit workflows temporarily until the mount detection logic is fixed.
            mountClient.putMountJson(token, data).then(e=>{
                if(e==="Success."){
                    IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Info, "Mount pipe saved successfully.", "", OutputMessageType.Toast));
                    
                    const token = store.getState().auth.accessTokenStatePrivateAPI.accessToken!;
                    EquipmentClient.getMountingsDataJson(token).then((e)=>{

                        IModelApp.viewManager.dropDecorator(this.decorator);
                        const md = new MountDecorator();
                        md.useDataAndLoadPipes(e);
                        IModelApp.viewManager.addDecorator(md);

                    });
                                
                } else {
                    IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Error,`Error occured: Mount pipe is not created.`, "", OutputMessageType.Toast)); 
                }
            })
            */
    }
        this.exitTool();
        return EventHandled.Yes;
    }

    public override async onDataButtonDown(_ev: BeButtonEvent): Promise<EventHandled> {
        this.startPt=_ev.point;
        return EventHandled.Yes;
    }

    public override onPostInstall(): Promise<void> {
        super.onPostInstall();

        // Enable AccuSnap so that boxes can be created by snapping on existing model
        IModelApp.accuSnap.enableSnap(true);
        return Promise.resolve();
    }
}
