import {
  IssueResolutionActionTypes,
  IssueResolutionManager,
  CreateNewIssueTool
} from "@bentley/itwin-issue-resolution";
import { IModelApp } from "@itwin/core-frontend";
import { store } from "../../../store/rootReducer";
import { AppSettings } from "../../../config/AppSettings";
import { AccessToken } from "@itwin/core-bentley";
import {
SavedViewFeatureFlags, SavedViewsClient, SavedViewsInitializationOptions, SavedViewsManager,
SavedViewsUiItemsProvider,
SavedViewUtil,
} from "@bentley/itwin-saved-views";

export const IssueInitialize = () => {


  const options = {
  userId:  store.getState().auth.user?.profile.sub ??"",
  applicationName: AppSettings.appName,
  gprid:   AppSettings.gprid,
      // getstore(),
      store:   store, // not used by ui-snippet
      i18n: IModelApp.localization  as any,
      issueResolutionStateKeyInStore:undefined,
      featureFlags: {
        allowMarkerInSpace: true,
        fixUrl: true,
        showImport: false,
        // useMarkupPackage: true,
        disableStartMarkupIn2d: true,
        showOpenSourceIModel: true,
      },
      overrides: undefined,
      getUserInfo:undefined,
      getAccessToken: ()=> getAccessToken()
  }
 
  const getAccessToken = async() => {
      return  store.getState().auth.accessTokenStatePrivateAPI.accessToken as AccessToken;
  }
  SavedViewsManager.initialize(options.i18n,{userId:options.userId as any,store:options.store,applicationIds:[options.gprid]})
 
return IssueResolutionManager.initializeEx(options.userId,options.applicationName,options.gprid,options.store,options.i18n,options.issueResolutionStateKeyInStore,options.featureFlags,options.overrides,options.getUserInfo,options.getAccessToken);
}
