import { Id64String } from "@itwin/core-bentley";
import { IModelApp, IModelConnection, NotifyMessageDetails, OutputMessagePriority, OutputMessageType } from "@itwin/core-frontend";
import { PropertyRecord, PropertyValueFormat } from "@itwin/appui-abstract";
import "./Table.scss";
import EquipmentClient from "../../api/equipment";
// import App from "../App";
import { ReduceDecimalNumberToTwoPlaces } from "../Lists/EquipmentList";
import { UiFramework } from "@itwin/appui-react";
import { Logger } from "../../api/logging";
import { EquipmentPosition } from "../../tools/decorators/EditableShapeDecorator";
import { store } from "../../../store/rootReducer";

export interface EquipmentInformation {
    className: string;
    id: Id64String;

    // ID_Detailed_Equipment_Identification: number,
    height: number; // Adding height, width, depth & others as mandatory fields to allow the cloning of equipment with these properties intact
    elevationHeight: number; //Distance of the equipment from the base of the tower (& Not the sea level).
    width: number;
    depth: number;
    weight?: number;
    // Installed_Fuste_Platform_Arm: any,
    type: string;
    isActive?: boolean;
    brand?: string;
    model: string;
    faceName?: string;
    operator?: string;
    manufacturer?: string;
    dimensions?: string; //Not required if using new v1.1 API
    xPosition: number;
    yPosition: number;
    zPosition: number;
    // Posicao_Rru_outro: string,
    bandTechnology?: string;
    yaw: number;
    azimuth: number;
    tilt: number;
    pitch: number;
    roll: number;
    name: string;
    displayName?: string;
    lateralOffset?: number;
    standoffDistance?: number;
    verticalOffset?: number;
    imageList: string;
    area?: number;
    reference?: string;
}

export interface equipmentModel {name: string, type: string, specification: {height: number, width: number, depth: number, weight: number}}
export interface ManModels {manufacturer: string, equipmentTypes: string[], models: equipmentModel[]};

export class EquipmentsTable {
    public static equipmentData: EquipmentInformation[];
    public static equipNameInfoMap: Map<string, any> = new Map();
    public static equipManModelData: any;
    public static formattedEquipmentManModels: {manModels: ManModels[], allAvailableTypes: string[]};
    public static equipPolygons: any;
    public static allPolygonPoints: any;
    public static equipNamePositionMap: Map<string, EquipmentPosition> = new Map();
    public static getAntManModels = (antennaList): {manModels: ManModels[], allAvailableTypes: string[]} => {
        let manModels: ManModels[] = [], allAvailableTypes: string[] = [];
        antennaList.forEach(ep=>{
          const allTypeIndex = allAvailableTypes.findIndex(et=>et == ep.type);
          if(allTypeIndex == -1)allAvailableTypes.push(ep.type);

          const theIndex = manModels.findIndex(um=>um.manufacturer == ep.manufacturer);
          if(theIndex == -1)manModels.push({manufacturer: ep.manufacturer, equipmentTypes: [ep.type], models: [{name: ep.model, type: ep.type, specification: { height: ep.height, width: ep.width, depth: ep.depth, weight: ep.weight}}]});
          else {
            const typeIndex = manModels[theIndex].equipmentTypes.findIndex(et=>et == ep.type);
            if(typeIndex == -1)manModels[theIndex].equipmentTypes.push(ep.type);
            manModels[theIndex].models.push({name: ep.model, type: ep.type, specification: { height: ep.height, width: ep.width, depth: ep.depth, weight: ep.weight}});
          }
        });
        return {manModels, allAvailableTypes};
    };


    public static async getData(_imodel: IModelConnection) {
        try {
            const records: EquipmentInformation[] = [];
            if (EquipmentsTable.equipmentData !== undefined && EquipmentsTable.equipmentData.length > 0) {
                return EquipmentsTable.equipmentData;
            }
            const recordData = await EquipmentClient.getAllEquipmentJson(store.getState().auth.accessTokenStatePrivateAPI.accessToken!);
            // const recordData = EquipmentsTable.cleanKeyNames(data);
            if (!recordData) {
                return [];
            }
            for await (const record of recordData) {
                for (const property in record) {
                    if (!record[property]) {
                        record[property] = "";
                    } else if (record[property] === "UPT:NewEquip" || record[property] === "XXXXX" || record[property] === "XXX") {
                        record[property] = "";
                    }
                }
                const sp = record.dimensions.split("x");
                record.height = sp[0];
                record.width = sp[1];
                record.depth = sp[2];
                record.className = "Equipment";
                record.id = _imodel.transientIds.next;
                if (record.type !== "Tower")
                records.push(record);
            }
            EquipmentsTable.equipmentData = records;
            return records;
        } catch (e) {
            IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Error, "Error when fetching Equipments data", "", OutputMessageType.Toast));
            // tslint:disable-next-line:no-console
            Logger.error("Error while getting Equipments data",e);
        }

        return [];
    }
    public static async getSelectedEquipmentInfoJson(name: string) {
        const records = await EquipmentsTable.getData(UiFramework.getIModelConnection()!);
        for (const rec of records) {
            if (rec.displayName === name) {
                return rec;
            }
        }
        return null;
    }
}
